import React from 'react';
import Table from 'components/SharedComponents/Table';
import {
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  UserImg,
  UserImgContainer,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {
    TitleSection,
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {MdEdit} from 'react-icons/md';
  import {IoIosArrowForward, IoIosArrowDown} from 'react-icons/io';
  import Pagination from 'components/SharedComponents/Pagination';
  import {RiDeleteBinLine} from 'react-icons/ri';
  import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
  import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
const Clientview = ({
    clientData,
    userClient,
    userEmployee,
    userStaff,
    filterAdmin,
    filterSuperAdmin,
    headerClientColumns,
    handleExpansion,
    expandFields,
    clientpages,
    clientCurrentPage,
    toDeletePinning,
    update,
    deletePermission,
    handleEditClick,
    setQuerySort,
    loadingModalClientView,
    values,
    dropDownHandleSelectPagination,
}) => {
    return (
        <div>
            {clientData ? (
              <>  
              <ComponentDynamic loading={loadingModalClientView} >          
      <Table headerColumns={headerClientColumns} callBackQuerySort={setQuerySort} headerPadding={false}>
          <div>
            {clientData && clientData.map((clientPin, clientIndex) => {
              return (
                <div key={clientIndex}>
                  <Row bgColor className="mb-1">
                    <ColsGrouper className="d-flex">
                      <Col
                        max_width={EX_SMALL_COL}
                        className="d-flex justify-content-center align-content-center">
                        <CloseButtonWrapper
                          className="closeButtonWrapper"
                          onClick={() => {
                            handleExpansion(clientIndex, null);
                          }}>
                          {expandFields[clientIndex] && expandFields[clientIndex].open ? (
                            <IoIosArrowDown
                              style={{color: '#6200f9', fontWeight: 'bold'}}
                            />
                          ) : (
                            <IoIosArrowForward
                              style={{color: '#6200f9', fontWeight: 'bold'}}
                            />
                          )}
                        </CloseButtonWrapper>
                      </Col>
                      <Col max_width={LARGE_COL}>{clientPin.client}</Col>
                      <Col
                        max_width={SMALL_COL}
                        className='p-2'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                        {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on}</p>
                        )}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        className='p-2'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                        {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].run_name}</p>
                        )}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        className='p-2'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                        {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].requirement}</p>
                        )}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{ (clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].start_time)+"-"
                          +(clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].end_time)
                          }</p>
                        )}
                      </Col>
                    
                      <Col
                        max_width={SMALL_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) :clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].repeat}</p>
                        )}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) :clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].start_date}</p>
                        )}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].end_date}</p>
                        )}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) :clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].employeeName}</p>
                        )}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        className='p-2 pr-4'
                        Selected={
                          expandFields[clientIndex] &&  expandFields[clientIndex].open ? true : false
                        }>
                         {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                          <>Various</>
                        ) :clientPin && clientPin.pinned_data &&  clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                          <p>Various</p>
                        ) : (
                          <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].is_suspended?'Inactive':'Active'}</p>
                        )}
                      </Col>
                   
                    </ColsGrouper>
                  </Row>
                  <div>
                   
                    {expandFields[clientIndex] &&
                    expandFields[clientIndex].open ? (
                     clientPin && clientPin.pinned_data && clientPin.pinned_data.map(
                        (pinnedEmployee, employeeIndex) => {
                          return (
                            <div key={employeeIndex}>
                              <Row className="mb-1">
                                <ColsGrouper className="d-flex">
                                  <Col max_width={EX_SMALL_COL}></Col>
                                  <Col
                                    max_width={LARGE_COL}
                                    className="d-flex justify-content-center align-items-center">
                                            {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 &&
                                    <CloseButtonWrapper
                                      onClick={() => {
                                        handleExpansion(
                                          clientIndex,
                                          employeeIndex
                                        );
                                      }}
                                      >
                                       
                                      {expandFields[clientIndex] &&  expandFields[clientIndex][employeeIndex] &&
                                      expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                        <IoIosArrowDown
                                          style={{
                                            color: '#6200f9',
                                            fontWeight: 'bold',
                                          }}
                                        />
                                      ) : (
    
                                        <IoIosArrowForward
                                          style={{
                                            color: '#6200f9',
                                            fontWeight: 'bold',
                                          }}
                                        />
                                      )}
                                    </CloseButtonWrapper>
                        }
                                  </Col>
                                  
                                  <Col
                                    bgColor
                                    max_width={SMALL_COL}
                                    className="p-2">
                                    {pinnedEmployee.repeat_on}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={LARGE_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].run_name}
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={LARGE_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].requirement}
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={LARGE_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {(pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].start_time)+"-"
                                      +(pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].end_time)
                                      }
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={SMALL_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].repeat}
                                      </>
                                    )}
                                  </Col>
           
                                  <Col
                                    bgColor
                                    max_width={MEDIUM_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].start_date}
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={MEDIUM_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].end_date}
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={LARGE_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].employeeName}
                                      </>
                                    )}
                                  </Col>
                                  <Col
                                    bgColor
                                    max_width={MEDIUM_COL}
                                    className="p-2 pr-4"
                                      Selected={
                                      expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                       expandFields[clientIndex][employeeIndex] 
                                        .open
                                        ? true
                                        : false
                                    }>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                      <p>Various</p>
                                    ) : expandFields[clientIndex] &&
                                    expandFields[clientIndex][employeeIndex] &&
                                    expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                          <></>
                                      // <p>Empty</p>
                                    ) : (<>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].is_suspended?'Inactive':'Active'}
                                      </>
                                    )}
                                  </Col>
                                  {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length === 1 &&
                                  <>
                                      {/* {  ( update  ||  ( (userClient === 'true')  &&  ((userEmployee  === 'null' )  || (  userStaff  === 'true'  ) ) )  )  && ( */}
                                        { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) )    || ((userStaff == true)&&(update) ) )  && (
                                  <Col
                                    bgColor
                                    className="sm-hidden"
                                    Center
                                    Icon
                                    Purple>
                                    <IconWrapper
                                      onClick={() => {
                                        handleEditClick(pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_id,
                                          pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_day_id
                                          );
                                      }}>
                                      <MdEdit />
                                    </IconWrapper>
                                  </Col>
                                            )}
                                             {/* { (deletePermission &&  ( (userClient === 'true')  &&  ( (userEmployee  === 'null' )  && (  userStaff  === 'null'  )   ) ) )&&  ( */}
                                              { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) )    || ((userStaff == true)&&deletePermission ) )  && (
                                  <Col
                                    bgColor
                                    className="sm-hidden"
                                    Center
                                    Shrink
                                    NoFlexGrow>
                                    <IconWrapper
                                      onClick={() => {
                                        toDeletePinning(
                                          pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_id,
                                          pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_day_id
                                        );
                                      }}>
                                      <RiDeleteBinLine />
                                    </IconWrapper>
                                  </Col> 
                                    )} 
                                  </>
                        }
                                </ColsGrouper>
                              </Row>
                              <div>
                                {expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                expandFields[clientIndex][employeeIndex]
                                  .open ? (
                                    <>
                                    {pinnedEmployee && pinnedEmployee.repeat_on_data &&  pinnedEmployee.repeat_on_data.map(
                                      (pinnedVisit, visitIndex) => {
                                        return(
                                  <div key={visitIndex}>
                                          <Row className="mb-1">
                                            <ColsGrouper className="d-flex">
                                              <Col max_width={EX_SMALL_COL}></Col>
                                              <Col max_width={LARGE_COL}></Col>
                                              <Col max_width={SMALL_COL}></Col>
                                              <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={LARGE_COL}>
                                                {pinnedVisit.run_name}
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={LARGE_COL}>
                                                {pinnedVisit.requirement}
                                                </Col> 
                                              <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={LARGE_COL}>
                                                {pinnedVisit.start_time+"-"
                                                +pinnedVisit.end_time
                                                }
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={SMALL_COL}>
                                                {pinnedVisit.repeat}
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={MEDIUM_COL}>
                                                {pinnedVisit.start_date}
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={MEDIUM_COL}>
                                                {pinnedVisit.end_date}
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={LARGE_COL}>
                                                {pinnedVisit.employeeName}
                                                </Col> 
                                                <Col
                                                bgColor
                                                Center
                                                className="p-2"
                                                max_width={MEDIUM_COL}>
                                                {pinnedVisit.is_suspended?'Inactive':'Active'}
                                                </Col> 
                                      {/* { ( update || ( (userClient === 'true')  &&  ((userEmployee  === 'null' )  || (  userStaff  === 'true'  ) ) ) )&&  ( */}
                                        { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) )    || ((userStaff == true)&&(update) ) )  && (
                                             <Col
                                                bgColor
                                                className="sm-hidden"
                                                Center
                                                Icon
                                                Purple>
                                                <IconWrapper
                                                  onClick={() => {
                                                    handleEditClick(pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_id,
                                                      pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_day_id
                                                      );
                                                  }}>
                                                  <MdEdit />
                                                </IconWrapper>
                                              </Col>
                                                    )}
                                                        {/* {(deletePermission  || ( (userClient === 'true')  &&  ((userEmployee  === 'null' )  && (  userStaff  === 'null'  ) ) ) ) &&  ( */}
                                                          { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) )    || ((userStaff == true)&&(deletePermission) ) )  && (
                                              <Col
                                                bgColor
                                                className="sm-hidden"
                                                Center
                                                Shrink
                                                NoFlexGrow>
                                                <IconWrapper
                                                  onClick={() => {
                                                    toDeletePinning(
                                                      pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_id,
                                                      pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_day_id
                                                    );
                                                  }}>
                                                  <RiDeleteBinLine />
                                                </IconWrapper>
                                              </Col> 
                                             )} 
                                            </ColsGrouper>
                                          </Row>
                                        </div>
                                        )
                                            })
                                          }
                                     </>
                                 
                              
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
             <div className="mt-3">
{/* <Pagination totalPages={clientpages} currentPage={clientCurrentPage} /> */}
<Pagination totalPages={clientpages} currentPage={clientCurrentPage}   values={values}   number={PAGE_SIZE_RENDER}  dropDownHandleSelect={dropDownHandleSelectPagination}   dataLength={clientData.length} />
</div>
          </div>
        </Table>
        </ComponentDynamic>
        </>
      ) : (
       <></>
      )}
        </div>
    );
}

export default Clientview;
