import React, { useState, useEffect, useContext } from 'react';
import { EX_SMALL_COL4, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { headerColumns, headerColumnsSelector, headerColumnsSelectorByDefault } from './TableHeaders/PayrollDetailHeaderAbsence';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import { useForm } from 'hooks/FormHook';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { bulkActionPostPayrollDetails, getPayrollDetails, payDetailExport } from 'utils/api/finance'
import { AppContext, BranchItemsContext } from "context"
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import {
  Row,
  Col,
} from 'components/SharedComponents/Table/styles';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useModal } from 'hooks/ModalHook';
import AssignBulk from './Modal/AssignBulk';
import { useMemo } from 'react';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';
import { KeyPress } from 'utils/constants/regularExpressions';
import CustomInputLabelTop from 'components/SharedComponents/CustomInput';

const PayrollDetailsAbsence = (props) => {
  const [detailData, setDetailData] = useState([])
  const { values, setValues, useInput, errors, isValid, setCheckRequires } = useForm({});
  const [pages, setPages] = useState(0);
  const [visitStatusFilter, setVisitStatusFilter] = useState([])
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([])
  const [EvvConfirmationFilter, setEvvConfirmationFilter] = useState([]);
  const [FunderRateCardFilter, setFunderRateCardFilter] = useState([]);
  const [VisitBillConfirmationFilter, setVisitBillConfirmationFilter] = useState([]);
  const [TravelModeFilter, setTravelModeFilter] = useState([]);
  const [TravelCodeFilter, setTravelCodeFilter] = useState([]);
  const [employeeStatusFilter, setEmployeeStatusFilter] = useState([])
  const [VisitTravelBillConfirmationFilter, setVisitTravelBillConfirmationFilter] = useState([]);
  const [mileageAmount, setMileageAmount] = useState(0);
  const [travelAmount, setTravelAmount] = useState(0);
  const [actualDuration, setActualDuration] = useState(0);
  const [travelTime, setTravelTime] = useState(0);
  const [visitAmount, setVisitAmount] = useState(0);
  const [visitDuration, setVisitDuration] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalExport, setLoadingModalExport] = useState(false);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const [billRate, setBillRate] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);
  const [bulkActionKeys, setBulkActionKeys] = useState({})
  const [travelCodeOptions, setTravelCodeOptions] = useState([]);
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])
  const [payPatternFilter, setPayPatternFilter] = useState([])
  const [payRatesOptions, setPayRatesOptions] = useState([])

  const headerSelectedData = headerColumns.map((val) => (val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumns.filter((val) => val.columnSelected));

  const { setValue: setModalBulkConfirmation, bind: bindModalBulkConfirmation } = useModal(false);
  const toast = useRef()
  const {
    setValue: setActionPopup,
    value: ActionPopup,
    fullBind: fullBindActionPopup,
  } = useModal(false);

  const { contextChoices: choices } = useContext(AppContext)
  const { branchData } = useContext(BranchItemsContext);
  const history = useHistory();

  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
  values.limitperpage,
  props.location.search,
  values.ordering
  ]);

  useEffect(() => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/finance/payroll/details',
      search: stringified,
    });
  }, [])
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])

  const travelOptions = () => {
    if (choices.travel_type) {
      let temp = choices.travel_type.map((val) => ({ id: val.name, name: val.name }))
      return temp
    } else {
      return []
    }
  };

  const travel_opt = useMemo(() => travelOptions(), [choices.travel_type]);


  useEffect(() => {
    if (choices.employee_listing) {
      setEmployees(choices && choices.employee_listing && choices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name }
      }));
    }
    if (choices.rate_card_list_by_type) {
      setBillRate(choices.rate_card_list_by_type['Bill Rates'])
      setPayRate(choices.rate_card_list_by_type['Pay Rates'])
      setTravelRate(choices.rate_card_list_by_type['Travel Rates'])
    }
    if (choices.travelCode) {
      let code_data = choices.travelCode.map((item) => ({ id: item, name: item }))
      setTravelCodeOptions(code_data)
    }
    if (choices && choices?.rate_card_list_by_type && choices.rate_card_list_by_type['Absence Rates']) {
      const temp = [{ id: 'none', name: 'None' }, ...choices.rate_card_list_by_type['Absence Rates']]
      setPayRatesOptions(temp || [])
    }
  }, [choices])

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };

  const handleClick = (search) => {
    let pageToSearch = {}
    if (search) {
      pageToSearch = { offset: 0 }
      setCurrentPage(1);
    } else {
      pageToSearch = getPageToSearchParam(props.location.search);
    }
    if (!values.date_from || !values.date_to) {
      return
    }
    else {
      setLoadingModal(true)
      getPayrollDetails({ ...values, ...pageToSearch, ...branchData }, { is_absence: true })
        .then(res => {
          if (res) {
            setLoadingModal(false);
          }
          // let resD = res.results.filter((item)=>item.pay_type === 'On Call')
          setDetailData(res.results)
          setMileageAmount(res.total_mileage_amount?.toFixed(2) || 0)
          setTravelAmount(res.total_travel_amount?.toFixed(2) || 0)
          setActualDuration(res.total_actual_duration ? (res.total_actual_duration / 60)?.toFixed(2) : 0)
          setTravelTime(res.total_travel_duration ? (res.total_travel_duration / 3600)?.toFixed(2) : 0)
          setVisitAmount(res.total_visit_amount?.toFixed(2) || 0)
          setVisitDuration(res.total_schedule_duration ? (res.total_schedule_duration / 3600)?.toFixed(2) : 0)
          setPages(Math.ceil(res.count / values.limitperpage));
        })
    }
  }
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleExport = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    setLoadingModalExport(true)
    payDetailExport({ ...values, ...pageToSearch, ...branchData }, { is_absence: true }, { column_selector: runTableOptionData }).then(response => {
      setLoadingModalExport(false);
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `pay_details${values.date_from}_${values.date_to}`;
        a.click();
      })
    }
    )

  }
  const dropDownHandleSelect = (item) => {
    setValues({ ...values, ...item })
  }

  const handleSelectChange = (e, visit) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.visit_shift_id])
    } else {
      let index = checkedRows.indexOf(visit.visit_shift_id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      detailData.map((visit) => {
        if (!visitExists(visit.visit_shift_id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      detailData.map((visit) => {
        checkedRows.push(visit.visit_shift_id)
      })
    } else {
      detailData.map((visit) => {
        checkedRows.map((item, index) => {
          if (item === visit.visit_shift_id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }

  const handleSubmit = () => {
    let body = {
      visit_list: selectedElId?[selectedElId]:checkedRows,
    }
    bulkActionPostPayrollDetails(body, bulkActionKeys.apiKey)
      .then(res => {
        if (res.status) {
          setCheckedRows([])
          clearSelectedElId();
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          handleClick()

        }
      })
      .catch(err => {
      })



  }
  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };


  const {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  } = useContextMenu()
  const payrollAbsenceMenu = [
    {
      name: "Finance Shift Actual Manual Pay Amount",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: 'shift_actual_manual_pay_amount', title: 'Finance Shift Actual Manual Pay Amount' })
      }
    },
    {
      name: "Finance Shift Schedule Manual Pay Amount",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: 'shift_schedule_manual_pay_amount', title: 'Finance Shift Schedule Manual Pay Amount' })
      }
    },
    {
      name: "Finance Shift Pay Rate Card",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: 'shift_pay_rate_card', title: 'Finance Shift Pay Rate Card', rate_card: "pay" })
      }
    },
    {
      name: "Finance Shift Pay Confirmation",
      onClick: () => {
        setModalBulkConfirmation(true);
        setBulkActionKeys({ apiKey: 'shift_pay_confirmation', title: ' Finance Shift Pay Confirmation' })
      }
    },
    {
      name: "Recalculate Absence Pay",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: "reevaluate/absence/pay", title: 'Recalculate Absence Pay', recalculate_pay : true})
    }
  }
  ]

  const handleMinMaxChange = (e, key) => {
    values[e.target.name] = e.target.value;
    setValues({ ...values })
}

  return (
    <div>
      <ContextMenu menuItems={payrollAbsenceMenu} />
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'} style={{ flexWrap: 'wrap' }}>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__id__in: items });
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={(items) => setEmployeeFilter([...items])}
                    placeHolder={'Employee name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.employee_status ? choices.employee_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_status: items });
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={(items) => setEmployeeStatusFilter([...items])}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.absence_status ? choices.absence_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit_status: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={(items) => setVisitStatusFilter([...items])}
                    placeHolder={'Absence Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.service_category ? choices.service_category : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__service_category__id__in: items });
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={(items) => setServiceCategoryFilter([...items])}
                    placeHolder={'Service Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer> */}
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__client_service_visit__client_service__service_funders__category__id__in: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                    placeHolder={'Funder Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.branch || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__job_detail__branch__id: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={items =>
                      setBranchFilter([...items])
                    }
                    placeHolder={'Employee Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.branch || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ shift_branches: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={items =>
                      setBranchFilter([...items])
                    }
                    placeHolder={'Shift Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={
                      [{ id: 'CONFIRMED', name: 'CONFIRMED' },
                      { id: 'NOT CONFIRMED', name: 'NOT CONFIRMED' },
                      { id: 'AUTO CONFIRMED', name: 'AUTO CONFIRMED' }]
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        evv_confirmation_status__in: items,
                      });
                    }}
                    itemsSelected={EvvConfirmationFilter}
                    setSelectedFilter={items =>
                      setEvvConfirmationFilter([...items])
                    }
                    placeHolder={'EVV Confirmation Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={
                      payRatesOptions
                      // choices.rate_card_list_by_type ? ([{id:'none', name: 'None'}, ...choices.rate_card_list_by_type['Pay Rates']] || []) : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        visit__schedule_pay_rate_card__id__in: items,
                      });
                    }}
                    itemsSelected={FunderRateCardFilter}
                    setSelectedFilter={items =>
                      setFunderRateCardFilter([...items])
                    }
                    placeHolder={'Employee Rate Card'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={
                      [{ id: 'CONFIRMED', name: 'CONFIRMED' },
                      { id: 'NOT CONFIRMED', name: 'NOT CONFIRMED' }]
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        visit__pay_confirmation__in: items,
                      });
                    }}
                    itemsSelected={VisitBillConfirmationFilter}
                    setSelectedFilter={items =>
                      setVisitBillConfirmationFilter([...items])
                    }
                    placeHolder={'Absence Pay Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={
                      travel_opt ? travel_opt : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        travel_mode__in: items,
                      });
                    }}
                    itemsSelected={TravelModeFilter}
                    setSelectedFilter={items =>
                      setTravelModeFilter([...items])
                    }
                    placeHolder={'Travel Mode'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={travelCodeOptions || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        travel_code__in: items,
                      });
                    }}
                    itemsSelected={TravelCodeFilter}
                    setSelectedFilter={items =>
                      setTravelCodeFilter([...items])
                    }
                    placeHolder={'Travel Code'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                {/* <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={
                      [{ id: 'CONFIRMED', name: 'CONFIRMED' },
                      { id: 'NOT CONFIRMED', name: 'NOT CONFIRMED' }]
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        travel_pay_confirmation__in: items,
                      });
                    }}
                    itemsSelected={VisitTravelBillConfirmationFilter}
                    setSelectedFilter={items =>
                      setVisitTravelBillConfirmationFilter([...items])
                    }
                    placeHolder={'Visit Travel Pay Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer className="ml-1 mr-1 mb-2 ">
                  <NoSelectAllDropdownFilter
                    items={choices.Billing_patterns ? choices.Billing_patterns : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ pay_pattern: items });
                    }}
                    itemsSelected={payPatternFilter}
                    setSelectedFilter={(items) => setPayPatternFilter([...items])}
                    placeHolder={'Pay Pattern'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                 
                {/* Added Max min filter */}
                  <DropdownTypesContainer style={{minWidth:"11rem"}} className="ml-1 mr-1 mb-2">
                    <CustomInputLabelTop
                      name={`schedule_pay_amount__gte`}
                      type="number"
                      label={'Schedule Pay Amount Min'}
                      id={`schedule_pay_amount__gte`}
                      value={values.schedule_pay_amount__gte}
                      onChange={handleMinMaxChange}
                      onKeyPress={KeyPress.NON_ZERO_INTEGER}
                    />
                  </DropdownTypesContainer>
                  {/* Added Max min filter */}
                 <DropdownTypesContainer style={{minWidth:"11rem"}} className="ml-1 mr-1 mb-2">
                    <CustomInputLabelTop
                      width={"11rem"}
                      name={`schedule_pay_amount__lte`}
                      type="number"
                      label={'Schedule Pay Amount Max'}
                      id={`schedule_pay_amount__lte`}
                      value={values.schedule_pay_amount__lte}
                      onChange={handleMinMaxChange}
                      onKeyPress={KeyPress.NON_ZERO_INTEGER}
                    />
                  </DropdownTypesContainer>
                <PrimaryButtonForm className="ml-1 mr-1 mb-2 " onClick={() => handleClick(true)} disabled={loadingModalExport || loadingModal}>
                  <div className='d-flex'>
                    <span className={'ml-2 mr-2 font-weight-bold'}>Search</span>
                    {/* {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )} */}
                  </div>
                </PrimaryButtonForm>
                {detailData.length > 0 &&
                  <PrimaryButtonForm type="button"
                  className="ml-1 mr-1 mb-2 "
                    onClick={handleExport} disabled={loadingModalExport || loadingModal}>
                    <span className={'ml-2 mr-2 font-weight-bold'}>Export</span>
                    {!loadingModalExport ? (
                      null
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                }
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      <ComponentDynamic loading={loadingModal} >
        {
          detailData.length > 0 &&
          <>
            <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-end">
              <DropdownTypesContainer className="ml-1 mr-1">
                <NoSelectAllDropdownColumnSelector
                  items={headerColumns ? headerColumns.filter((item) => !item.removeCusCol) : []}
                  setItemsSelected={items => {
                    dropDownHandleSelectTable({ tableDropdown: items });
                  }}
                  itemsSelected={dropdownArraySelected}
                  setSelectedFilter={(item) => setDropdownArraySelected([...item])}
                  placeHolder={'Column'}
                  isObject
                  allOption

                />
              </DropdownTypesContainer>
              {(checkedRows.length > 0) &&
                <div class="dropdown mr-3">
                  <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className={'mr-2'}>Action</span>
                  </PrimaryButtonForm>
                  <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton">

                    {/* <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'shift_actual_manual_pay_amount', title: 'Finance Shift Actual Manual Pay Amount' })
                      }
                      }>
                      Finance Absence Actual Manual Pay Amount
                    </a> */}
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'shift_schedule_manual_pay_amount', title: 'Finance Shift Schedule Manual Pay Amount' })
                      }
                      }>
                      Finance Absence Schedule Manual Pay Amount
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'shift_pay_rate_card', title: 'Finance Shift Pay Rate Card', rate_card: "pay" })
                      }
                      }>
                      Finance Absence Pay Rate Card
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setModalBulkConfirmation(true);
                        setBulkActionKeys({ apiKey: 'shift_pay_confirmation', title: ' Finance Shift Pay Confirmation' })
                      }
                      }>
                      Finance Absence Pay Confirmation
                    </a>
                    <a class="dropdown-item"
                        onClick={() => {
                          setActionPopup(true);
                          setBulkActionKeys({ apiKey: "reevaluate/absence/pay", title: 'Recalculate Absence Pay', recalculate_pay : true})
                        }
                        }>
                        Recalculate Absence Pay
                      </a>
                    
                  </div>
                </div>
              }
              <div className="data-container">
                <div className="mr-2">
                  <p>{'Sch Hrs: ' + visitDuration}</p>
                </div>
                {/* <div className="mr-2">
                  <p>{'Actual Hrs: ' + actualDuration} </p>
                </div> */}
                <div className="mr-2">
                  <p>{'Visit Pay: ' + visitAmount}</p>
                </div>
                {/* <div className="mr-2">
                  <p>{'Travel Time: ' + travelTime} </p>
                </div> */}
                {/* <div className="mr-2">
                  <p>{'Travel Pay: ' + travelAmount} </p>
                </div> */}
                {/* <p>{'Mileage Amt: ' + mileageAmount} </p> */}
              </div>
            </DataContainer>
            <div style={{ overflowX: 'scroll', width: '100%' }}>
              <div style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                <Table
                  headerColumns={headerColumns}
                  headerPadding={false}
                  noEditOption={true}
                  noNeed={false}
                  fontSize="0.75rem"
                  values={runTableOptionData}
                  handleAllCheck={handleAllCheck}
                  handleAllSelectChange={handleAllSelectChange}
                  callBackQuerySort={setQuerySort}>
                  {detailData.map((det, index) => {
                    return (
                      <Row bgColor Shadow
                        onContextMenu={(e)=>{
                          e.preventDefault();
                          displayMenu(e, det.id, {x:0, y:0});
                        }}
                      >
                        <Col
                          max_width={EX_SMALL_COL4}
                          Shrink={false}
                          NoFlexGrow={true}
                          reducePadding
                          Capitalize
                          Center
                        >
                          <input class="form-check-input" type="checkbox"
                            checked={checkedRows.includes(det.visit_shift_id)}
                            id="flexCheckDefault"
                            onChange={(e) => { handleSelectChange(e, det) }}
                          />
                      
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("branch_name")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.employee__job_detail__branch__name}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("shift_branch")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.shift_branch}
                        </Col>
                        
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("employee_name")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.employee_name}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("shift_type")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.shift_type}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("pay_type")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.pay_type}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("absence_type")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.absence_type}
                        </Col>
                        
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("start_date")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.schedule_start_date}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("end_date")) && 'd-none'}

                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.schedule_end_date}
                        </Col>

                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("schedule_start_date")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.schedule_start_time ? det.schedule_start_time.slice(0,5) : ''}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("schedule_end_date")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.schedule_end_time ? det.schedule_end_time.slice(0,5) : ''}
                        </Col>

                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("schedule_duration_hrs")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.schedule_duration && det.schedule_duration}
                        </Col>
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("actual_start_time")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.actual_visit_start_time ? det.actual_visit_start_time.split('.')[0] : ''}
                        </Col> */}
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("actual_end_time")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.actual_visit_end_time ? det.actual_visit_end_time.split('.')[0] : ''}
                        </Col> */}
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("actual_duration_hrs")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.actual_visit_duration_hrs && det.actual_visit_duration_hrs?.toFixed(2)}
                        </Col> */}
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("shift_status")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.absence_status}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("shift_id")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.visit_shift_id}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("pay_pattern")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.pay_pattern}
                        </Col>
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("esv_shift_confirmation")) && 'd-none'}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                        >
                          {det.esv_pay_confirmation}
                        </Col> */}
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("employee_rate_card")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.employee_pay_rate_card}
                        </Col>
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("schedule_pay_rate")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.schedule_pay_rate_per_hour}
                        </Col>
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("actual_pay_rate")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.actual_pay_rate_per_hour}
                        </Col> */}
                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("schedule_pay_amount")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.schedule_pay_amount}
                        </Col>
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("actual_pay_amount")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.actual_pay_rate_amount}
                        </Col> */}

                        <Col
                          className={!(runTableOptionData && runTableOptionData.includes("shift_pay_confirmation")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.shift_pay_confirmation ? 'Confirmed' : 'Non Confirmed'}
                        </Col>
                        {/* <Col
                          className={!(runTableOptionData && runTableOptionData.includes("cancelled_pay_percentage")) && 'd-none'}
                          max_width={LARGE_COL}
                        >
                          {det.visit__staff_to_be_billed}
                        </Col> */}
                      </Row>
                    )
                  })}
                  <div className="mt-3">
                    <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={detailData.length} />
                  </div>
                </Table>
              </div>
            </div>
          </>
        }
      </ComponentDynamic>
      {
        ActionPopup &&
        <AssignBulk
          fullBind={()=>encloseModalBind(fullBindActionPopup)}
          checkedRows={selectedElId?[selectedElId]:checkedRows}
          bulkActionKeys={bulkActionKeys}
          handleClick={handleClick}
          setActionPopup={setActionPopup}
          rateCardOptions={choices && choices.rate_card_list_by_type && choices.rate_card_list_by_type['Absence Rates']}
          choices={choices}
          setCheckedRows={setCheckedRows}
          is_shift={true}
          clearSelectedElId={clearSelectedElId}
        />
      }

      <ConfirmDecision
        type="confirm"
        title="Confirm"
        body={
          `Are you sure you want to ${bulkActionKeys.title} ? `
        }
        onOk={() => {
          handleSubmit()
          setModalBulkConfirmation(false)
        }
        }
        onNo={
          () => {
            clearSelectedElId()
            setModalBulkConfirmation(false)
          }
        }
        {...encloseModalBind(bindModalBulkConfirmation)}
      />
    </div>
  );
}

export default PayrollDetailsAbsence;
