import React, { useState, useEffect, Fragment, useRef } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router';
import {
    setEmployeeUploadFile,
    getUploadedFiles,
    deleteUploadedFile,
    getUploadedFilesDownload
} from 'utils/api/EmployeeApi';
import { DOWNLOAD } from 'utils/constants/icons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
    ErrorMessage,
    NoPermissionContainer,
    TitleSection,
} from './styles';
import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import { LARGE_COL } from 'shared/styles/constants/columns';
import {
    Row,
    Col,
    IconWrapper,
    DayPill,
    ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';

export const headerColumns = [
    {
        label: 'FileName',
        name: 'file_name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },

    {
        label: 'Description',
        name: 'description',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Uploaded',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },

];

const Files = ({
    empId,
    selectedId,
    createPermissionEmployeeFile,
    updatePermissionEmployeeFile,
    readPermissionEmployeeFile,
    deletePermissionEmployeeFile
}) => {
    const [modalLoading, setModalLoading] = useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [UploadTeamFile, setUploadTeamFile] = useState('No File Chosen');
    const [toggleUploadFile, setToggleUploadFile] = useState(false)
    const [employeeUploadedFiles, setEmployeeUploadedFiles] = useState([])
    const [idSelected, setIdSelected] = useState('');
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
        false,
    );
    const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
        useForm({});
    const history = useHistory()
    const location = useLocation()

    const uploadFileTeam = useRef(null);

    useEffect(() => {
        if (selectedId) {
            loadEmpNotes()
        }
        else if (empId) {
            setModalLoading(false)
        }

    }, [])

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleChange = async (event, key, keyName) => {
        const fileUploaded = event.target.files[0];
        const fileResult = await pickFile(event)
        const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
        setValues({ ...result });
        if (key === 'upload_file') {
            setUploadTeamFile(fileUploaded.name)
            setToggleUploadFile(true)
        }
    }

    const handleUpload = () => {
        uploadFileTeam.current.click();
    };

    const loadEmpNotes = async () => {
        let id = selectedId ? selectedId : empId;
        try {
            const response = await getUploadedFiles(id)
            if (response) {
                setEmployeeUploadedFiles(response.results);
            }
            setModalLoading(false)
        } catch {
            setModalLoading(false)
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (loadingSave) {
            return;
        }

        if (values && values.upload_file && values.upload_file.upload_file_for_employee) {
            setLoadingSave(true);
            setCheckRequires(false);
            setShowError(false);
        } else {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(`Please choose a file `);
            return;
        }



        let body = {
            employee: location.pathname.includes('/team/add') ? empId : selectedId,
            description: values.Upload_file_description,
            file_name: values.upload_file.upload_file_for_employee,
            name: UploadTeamFile
        };

        setEmployeeUploadFile(body)
            .then(response => {
                if (response) {
                    setLoadingSave(false);
                    setValues({});
                    setUploadTeamFile('No File Chosen')
                    setToggleUploadFile(false)
                    loadEmpNotes();
                }
            })
            .catch((error) => {
                setLoadingSave(false);
            });
    };

    const toDeleteShift = id => {
        setModalDeleteValue(true);
        setIdSelected(id);
    };

    const thandleDeleteRegularShift = async () => {
        await deleteUploadedFile(idSelected);
        setModalDeleteValue(false);
        loadEmpNotes();
        setIdSelected('');
    };

    // const handleDownload = (id) => {
    //     let fileName = "file_" + moment().format("YYYY-MM-DD")
    //     getUploadedFilesDownload(id)
    //         .then((response) => {
    //             debugger
    //            window.location.href(response.url);
    //             response.blob().then(blob => {
    //                 let url = window.URL.createObjectURL(blob);
    //                 let a = document.createElement("a");
    //                 a.href = url;
    //                 a.download = fileName;
    //                 a.click();
    //             })
    //         })
    //         .catch(() => {
    //         })

    // }


const Upload_file_description_hook = useInput('Upload_file_description')

    return (
        <div>
       
            <ComponentDynamic loading={modalLoading}>
            {(selectedId ? readPermissionEmployeeFile : createPermissionEmployeeFile) ?
                <>
                <TitleSection>Upload a file</TitleSection>
                <div className="d-flex align-items-center py-3">
                    <div style={{ width: '50%' }} className='mr-3'>
                        <InputLabelTop
                            type="text"
                            id="emp_desc"
                            label={'Full description'}
                            {...Upload_file_description_hook}
                            maxlength={50}
                        />
                    </div>
                    <div style={{ width: '50%' }}>
                        <input type="file"
                            style={{ display: 'none' }}
                            ref={uploadFileTeam}
                            onChange={(e) => handleChange(e, 'upload_file', 'upload_file_for_employee')}
                        />
                        <PrimaryButtonForm
                            minWidth="2rem"
                            onClick={() => handleUpload()}
                            className={'mr-2'}
                        >
                            <span className={''}>{'Choose File'}</span>

                        </PrimaryButtonForm>
                        <a href={values.upload_file && values.upload_file.upload_file_for_employee} style={{
                            pointerEvents: UploadTeamFile === 'No File Chosen' ? "none"
                                : toggleUploadFile ? "none" : "", color: "#696969", fontStyle: "italic"
                        }}
                            target="_blank" rel="noopener noreferrer"
                            classname={'ml-1 mt-2'}
                        >
                            {UploadTeamFile}
                        </a>
                    </div>

                </div>
                <div
                    className={
                        'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                    }>
                    <div className={'mb-3'}>
                        {showError ? (
                            <ErrorMessage>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                    </div>
                    <div
                        className={
                            'd-flex justify-content-center justify-content-md-end'
                        }>

                        <div className={'ml-0 mr-2'}>
                            <PrimaryButtonForm
                                bgcolor="PURPLE_GRAY_500"
                                minWidth="6rem"
                                className={'mr-2'}
                                onClick={() => {
                                    history.push('/team')
                                }}>
                                <span className={'mr-2'}>{'Cancel'}</span>
                            </PrimaryButtonForm>

                            {(selectedId ? updatePermissionEmployeeFile : true) && (
                            <PrimaryButtonForm
                                bgcolor="PURPLE_GRAY_500"
                                minWidth="6rem"
                                onClick={handleSubmit}>
                                <span className={'mr-2'}>Upload</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                            )}

                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    {(employeeUploadedFiles.length > 0) && <Table
                        headerColumns={headerColumns}
                        headerPadding={false}
                        noEditOption={false}
                         fontSize="0.75rem"
                        callBackQuerySort={setQuerySort}>
                        <div>
                            {employeeUploadedFiles && employeeUploadedFiles.map((shift, index) => {

                                return (
                                    <div key={index}>
                                        <Row bgColor Shadow>
                                            <ColsGrouper
                                                className="d-flex"
                                            >
                                                <Col
                                                    max_width={LARGE_COL}
                                                    Shrink
                                                    reducePadding
                                                    Capitalize
                                                    Center>
                                                    {shift.name}
                                                </Col>
                                                <Col
                                                    max_width={LARGE_COL}
                                                    Shrink
                                                    reducePadding
                                                    Capitalize
                                                    Center>
                                                    {shift.description === 'undefined' ? '' : shift.description}
                                                </Col>

                                                <Col
                                                    max_width={LARGE_COL}
                                                    Shrink
                                                    reducePadding
                                                    Capitalize
                                                    Center
                                                >
                                                    Uploaded at {moment(shift.created_at).format('hh:mm A DD-MM-YYYY')}
                                                </Col>
                                                </ColsGrouper>
                                                <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                                <IconWrapper
                                                className='mr-3'
                                                        onClick={() => {
                                                            window.open(shift.file_name, '_blank');
                                                        }}>
                                                        {DOWNLOAD}
                                                    </IconWrapper>
                                                    {deletePermissionEmployeeFile && <IconWrapper 
                                                    className='mr-3'
                                                    onClick={() => {
                                                        toDeleteShift(shift.id);
                                                    }}
                                                    >
                                                        <RiDeleteBinLine />
                                                    </IconWrapper>}
                                                </Col>
                                                
                                            
                                        </Row>
                                        <div className="mb-1" />
                                    </div>
                                );
                            })}

                        </div>


                    </Table>}
                    <div className='mod_dec'>
                        <ModalDecision
                            type="delete"
                            title="Warning"
                            width={"50%"}
                            max_width={'fit-Content'}
                            body={
                                'Are you sure you want to delete this file?'
                            }
                            onOk={thandleDeleteRegularShift}
                            onCancel={() => {
                                setIdSelected('');
                            }}
                            okTitle={'DELETE'}
                            {...bindModalDelete}
                        />
                    </div>
                </div>
                </>
                 :
                 <NoPermissionContainer>
                   You don't have permission to {selectedId ? 'read' : 'create'} the information.
                 </NoPermissionContainer>
            }
            </ComponentDynamic>
            
        </div>
    );
}

export default Files;
