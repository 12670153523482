import React, { Fragment, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeLinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CALENDAR_DAILY, CALENDAR_MONTHLY, CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import $ from 'jquery';
import {
  ButtonsContainer,
  CalendarContainer,
  CalendarContainer1,
  NextPreviousContainer,
  ProgressBarContainer,
  ProgressContainer,
  ProgressOtherContainer,
  StaticArrowUpDown,
} from './styles';
import moment from 'moment';
import CustomProgressBar from 'components/SharedComponents/CustomProgressBar';
import ProgressInformation from 'components/SharedComponents/ProgressInformation';
import { CloseButtonWrapper, DropdownTypesContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from '../DateSelector';
import { DropDownContainer, ItemContainer, LeftContainer, MainContainer, PublishButton, RightContainer } from '../ProgressInformation/styles';
import { DOWNLOAD, TIMES } from 'utils/constants/icons';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { PURPLE } from 'shared/styles/constants/colors';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const OPTAPLANNER_STATUS = {
  SOLVING: 'SOLVING',
  TERMINATED: 'TERMINATED',
};

const TimelineMedicationCalendar = ({
  resources = [],
  events = [],
  resourceColoumn = [],
  medCalendar = false,
  setShiftType,
  shiftType,
  values,
  setValues,
  eventClickHandler = () => { },
  resourceAreaHeaderContent,
  showProgressSection = false,
  editMode,
  timeZone,
  useInput,
  handleDownlaod,
  downloadDisable,
  contextChoices,
  getViewType = () => {},
  decreaseWidth,
  setDecreaseWidth,
  rightClickHandler = () => {},
  removeRightClickHandler = () => {},
}) => {

  console.log(resources, 336955)
  const calendarRef = React.createRef();

  const [eventsValue, setEventsValue] = useState([]);
  const [recourseValue, setRecourseValue] = useState();
  const [colorState, setColorState] = useState(false);


  useEffect(() => {
    setEventsValue(events);
  }, [events])

  useEffect(() => {
    setRecourseValue(recourseValue);
    if (!calendarRef.current) {
      return;
    }
    let calendarApi = calendarRef.current.getApi();
    const view = calendarApi.view;
    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    // if(rotaShift){
    //   setShiftType(view.type);
    // }else{
    calendarApi.changeView(shiftType);
    setShiftType(shiftType);
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));

  }

  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  useEffect(() => {
    if (values.choose_date) {
      if (shiftType === CALENDAR_DAILY) {
        console.log(values.choose_date,"values choose_date")
        calendarRef.current.getApi().gotoDate(values.choose_date);
        values.start_date = moment(values.choose_date).local().toDate();
        values.end_date = moment(values.choose_date).add(1, 'days').local().toDate();
        setValues({ ...values });
      } else if(shiftType === CALENDAR_WEEKLY){
        console.log(values.choose_date,"values choose_date")
        calendarRef.current.getApi().gotoDate(values.choose_date);
        const c_date = new Date(values.choose_date)
        const str = startOfWeek(c_date);
        values.start_date = moment(str).local().toDate();
        values.end_date = moment(str).add(7, 'days').local().toDate();
        setValues({ ...values });
      }else {
        console.log(values.choose_date,"values choose_date")
        calendarRef.current.getApi().gotoDate(values.choose_date);
        const c_date = new Date(values.choose_date)
        const str = startOfMonth(c_date).toString();
        values.start_date = moment(str).local().toDate();
        values.end_date = moment(str).add(30, 'days').local().toDate();
        setValues({ ...values });
      }
    }
  }, [values.choose_date, shiftType])

  useEffect(() => {
    let _events = [...events];
    _events.forEach(e => {
      // e.start = moment(e.start).format()
      // e.end = moment(e.end).format()

      if (e && e.title && typeof e.title === 'object') {
        if (e.title?.length === 0) {
          e.title = '';
        } else e.title = e.title.find(title => title !== undefined);
      }
    });
    setEventsValue([..._events]);
    let index = 0;
    let resourceElement = null;
    let dataElement = null;
    document.querySelectorAll(".App .fc-scroller-harness-liquid").forEach(element => {
      if (index == 0) {
        resourceElement = element;
      } else {
        dataElement = element;
      }
      index++;
    });

    let headerData = null;
    index = 0;
    document.querySelectorAll(".App .fc-scroller").forEach(element => {
      if (index == 1)
        headerData = element;


      index++;
    });
    $(dataElement).scrollLeft($(headerData).scrollLeft());

    if (dataElement != null && resourceElement != null && headerData != null) {
      $(dataElement).scroll(function () {
        $(resourceElement).scrollTop($(dataElement).scrollTop());
        $(headerData).scrollLeft($(dataElement).scrollLeft());
      });
      $(resourceElement).attr("style", "overflow:hidden !important; overflow-x: scroll !important;");
    }




  }, [events]);

  const handleDateClick = arg => {
    // console.log('handleDateClick -> arg', arg);
    // console.log('handleDateClick -> arg.dateStr', arg.dateStr);
  };

  const handleEventClick = arg => {
    if (arg.event._def.extendedProps.openModal) {
      return
    }
    eventClickHandler({
      id: arg.event.id,
      groupId: arg.event.groupId,
      extendedProps: arg.event.extendedProps,
    });

  };
  const showToday = () => {
    // setLoading(true)
    if (!calendarRef.current) {
      return '';
    }
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    console.log(values,"values date pick")
    setValues({ ...values });
    // getOptaplannerStatus();
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6E6BFA"
    },
  }));
  const getCalendarTitle = () => {
    if (!calendarRef.current) {
      return '';
    }
    let calendarApi = calendarRef.current.getApi();
    console.log(calendarApi, "441555")
    const view = calendarApi.view;
    return view.title;
  };

  const dailyView = () => {
    // setLoading(true)
    let calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(CALENDAR_DAILY);
    setShiftType(CALENDAR_DAILY);

    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    console.log(values,"values deily")
    setValues({ ...values });
    
    getViewType("daily")
    // if(!rotaShift){
    //   setAllowSchedulerCalls(false)
    // }
    // getOptaplannerStatus();
  };

  const weeklyView = () => {
    // setLoading(true)
    let calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(CALENDAR_WEEKLY);
    setShiftType(CALENDAR_WEEKLY);

    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    console.log(values,"values weekly")
    setValues({ ...values });
    getViewType("weekly")
    // if(!rotaShift){
    //   setAllowSchedulerCalls(false)
    // }

    // getOptaplannerStatus();
  };
  const monthView = () => {
    // setLoading(true)
    let calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(CALENDAR_MONTHLY);
    setShiftType(CALENDAR_MONTHLY);

    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    console.log(values,"values monthaly")
    setValues({ ...values });
    getViewType("month")
    // if(!rotaShift){
    //   setAllowSchedulerCalls(false)
    // }

    // getOptaplannerStatus();
  };



  const previousChange = () => {
    // setLoading(true)
    let calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    // getOptaplannerStatus();
  };

  const nextChange = () => {
    // setLoading(true)
    let calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    // getOptaplannerStatus();
  };

  const viewClassNames = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const getStatus = (status) => {
    switch (status) {
      case 'Fully Taken':
        return 'FT '
      case 'Not Needed':
        return 'NN '
      case 'Not Observed':
        return 'NO '
      case 'Not Reported':
        return 'NR '
      case 'Not Taken':
        return 'NT '
      case 'Self Administered':
        return 'SA '
      case 'Family Administered':
        return 'FA '
      case 'No Visit':
        return 'NV '
      case 'Partially Taken':
        return 'PT '
      default:
        return '';
    }
  }

  // const handleVisitChange = e => {
  //   console.log(e, "478555")
  //   let visit = {
  //     end_date: moment(e.event._instance.range.end),
  //     id: e.event._def.publicId,
  //     employee: null,
  //     start_date: moment(e.event._instance.range.start),
  //   };

  //   setChangeVisit(e);
  //   setChangeVisitDetails(visit);
  // };
  console.log('context', contextChoices.medication_status)
  const choose_date_hook = useInput('choose_date')

  const handleExpandCalendar = () => {
    setDecreaseWidth(!decreaseWidth);
    // setTimeout(() => {
    //   titleRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    // }, 1000);
    let dataElement1 = null;
    if (decreaseWidth) {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:none !important; margin-top: -25px; margin-left: 10px'
        );
      }
    } else {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:block !important; margin-top: -25px; margin-left: 10px; margin-bottom: 1.5rem'
        );
      }
    }
  };

  return (
    <Fragment>
      {
        decreaseWidth && (
          <ButtonsContainer
          className='mt-5'
            selected={shiftType}>
            <div className='d-flex'>
              <div className={'dailyView'}>
                <button className={'daily mr-2'} onClick={dailyView}>
                  Daily
                </button>
                <button className={'weekly'} onClick={weeklyView}>
                  Weekly
                </button>
                <button className={'monthly'} onClick={monthView}>
                  Monthly
                </button>
    
              </div>
              <DropdownTypesContainer className="ml-1 mr-1 d-flex">
                <div>
                  <DateSelector
                    typeFilter={true}
                    labelTop="Select Date"
                    value={values.choose_date ? values.choose_date : ''}
                    {...choose_date_hook}
                  />
                </div>
              </DropdownTypesContainer>
               <PublishButton className='ml-2' disable={downloadDisable} onClick={!downloadDisable && handleDownlaod}>
                  {DOWNLOAD}
               </PublishButton>
            </div>
           
    
           
            {showProgressSection ? (
              <ProgressOtherContainer>
                 <NextPreviousContainer
              className={'nextPrevious'}
              progress={showProgressSection}>
              <div className={'d-flex align-items-center'}>
                <div className={'arrow'}>
                  <MdKeyboardArrowLeft onClick={previousChange} />
                </div>
                <div className={'arrow'}>
                  <MdKeyboardArrowRight onClick={nextChange} />
                </div>
                <PrimaryButtonForm onClick={showToday}>
                  <span>Today</span>
                </PrimaryButtonForm>
              </div>
            </NextPreviousContainer>
                  <MainContainer style={{position:'absolute', right: 0, top:'60px'}}>
                    <LeftContainer onClick={() => setColorState(!colorState)}>
                      {colorState ? <FiChevronsUp /> : <FiChevronsDown />}
                      <label>Color</label>
                    </LeftContainer>
                    {colorState &&
                      <DropDownContainer style={{ display: "block", zIndex: 2 , width: 'max-content'}}>
                        {contextChoices && contextChoices.medication_status &&
                          contextChoices.medication_status.map((item) => (
                            <>
                              <ItemContainer style={{ flexDirection: 'row' }}>
                                <div style={{ width: '10px', height: '10px', backgroundColor: `${item.color_code || ''}`, marginRight: '8px', alignSelf: 'center' }}></div>
                                <label>{item.name}</label>
                                <label>{getStatus(item.name) ? ` (${getStatus(item.name)})` : ''}</label>
                              </ItemContainer>
                            </>
                          ))
                        }
                      </DropDownContainer>
                    }
                  </MainContainer>
              </ProgressOtherContainer>
            ) : (
              <></>
            )}
          </ButtonsContainer>
        )
      }
      {/* <h1>{getCalendarTitle()}</h1> */}
      <StaticArrowUpDown decreaseWidth={decreaseWidth} onClick={handleExpandCalendar}>
        <div>
          {decreaseWidth ? (
            <IoIosArrowUp size={18} color={PURPLE} />
          ) : (
            <IoIosArrowDown size={18} color={PURPLE} />
          )}
        </div>
      </StaticArrowUpDown>
      <CalendarContainer
        className="App "
        progress={showProgressSection}
        reduceColumns={shiftType === CALENDAR_WEEKLY}
        // view={CALENDAR_DAILY}
        height={decreaseWidth? "calc(100vh - 23rem)":"calc(100vh - 8rem)"}
        minHeight={decreaseWidth? "calc(100vh - 23rem)":"calc(100vh - 8rem)"}
        overflow={decreaseWidth?"visible":"hidden"}
        id="full-calendar-container"
      >
        <FullCalendar
          eventDidMount={rightClickHandler}
          eventWillUnmount={removeRightClickHandler}
          schedulerLicenseKey={'0797593398-fcs-1672351783'}
          scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
          ref={calendarRef}
          plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          // selectable={true}
          firstDay={1}
          // expandRows={true}
          height="100%"
          headerToolbar={{
            left: 'title',
            center: '',
            end: '',
          }}
          titleFormat= { // will produce something like "Tuesday, September 18, 2018"
            (shiftType===CALENDAR_DAILY) ? { month: 'short',
            year: 'numeric',
            day: 'numeric',
            weekday: 'short'} : { month: 'short',
            year: 'numeric',
            day: 'numeric',}
          }
          views={{
            resourceTimelineDay: {
              type: 'resourceTimelineDay',
              buttonText: 'Daily',
              slotDuration: '00:05:00',
              eventMinWidth: 1,
              slotMinWidth: 10
            },


            resourceTimelineWeekDays: {
              type: 'resourceTimelineWeek',
              duration: { weeks: 1 },
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { month: 'long', year: 'numeric' }, // top level of text
                {
                  day: 'numeric',
                  weekday: 'short',
                  omitCommas: true,
                },
              ],
              buttonText: 'Weekly',
            },

            resourceTimelineMonth: {
              type: 'resourceTimelineMonth',
              duration: { months: 1 },
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { month: 'long', year: 'numeric' }, // top level of text
                {
                  day: 'numeric',
                  omitCommas: true,
                },
              ],
              buttonText: 'Monthly',
              // eventMinWidth: 1,
              slotMinWidth: 70
            },
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            // timeZoneName: 'short'
          }}
          nextDayThreshold={'06:00'}
          initialView={CALENDAR_DAILY}
          //filterResourcesWithEvents={true}
          resourceOrder="title employee"
          resourceAreaHeaderContent={resourceAreaHeaderContent}
          resourceAreaWidth={medCalendar ? '20rem' : '18rem'}
          resourceAreaColumns={resourceColoumn}
          resources={[...resources]}
          events={[...events]}
          themeSystem="bootstrap"
          viewClassNames={viewClassNames}
          lazyFetching={false}
          editable={editMode ? true : false}
          // eventOrder={'start,orderEvent,allDay,title'}
          // eventChange={event => handleVisitChange(event)}
          //eventLeave={event => handleVisitChange(event)}
          timeZone={timeZone ? timeZone : 'none'}

          eventContent={function (args, createElement) {
console.log(args,"args")
            if (args.event._def.extendedProps.icon) {
              const icon = args.event._def.extendedProps.icon;
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className='d-flex'>
                      <div>
                        <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                        <span>{args.event._def.extendedProps.gender ?
                          (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                        }</span>
                        <span>{args.event._def.extendedProps.priority_icon}</span>
                        <span>{icon}</span>
                        {args.event._def.extendedProps.isRegular && <span>
                          R
                        </span>}
                        <span>{(args.event._def.title).substring(0, 15)}</span>
                      </div>
                      <div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else if (args.event._def.extendedProps.typeVisit) {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm")
                      }</span>
                      <span>{args.event._def.title}</span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                        {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                        {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                        {args.event._def.extendedProps.visit_type}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                        {args.event._def.extendedProps.address}</div>
                    </div>
                    <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div>
                  </>
                  }
                    arrow
                  >
                    <div className='d-flex'>
                      <div>
                        <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                        <span>{args.event._def.extendedProps.gender ?
                          (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                        }</span>
                        <span>{args.event._def.extendedProps.priority_icon}</span>
                        {args.event._def.extendedProps.isRegular && <span>
                          R
                        </span>}
                        <span>{(args.event._def.title)}</span>
                      </div>
                      <div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>

                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              )
            }
            else {
              return (
                <>
                  <BootstrapTooltip title={<>
                    <div>
                      {/* <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm")
                      }</span> */}
                      {/* <span>{args.event._def.title}</span> */}
                    </div>
                    {args.event._def.extendedProps.medication_dose_time && args.event._def.extendedProps.medication_dose_time &&
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Planned Time: '}</span>
                          {args.event._def.extendedProps.medication_dose_time && args.event._def.extendedProps.medication_dose_time}</div>
                      </div>}
                    {args.event._def.extendedProps.actual_medication_dose_time && args.event._def.extendedProps.actual_medication_dose_time &&
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Actual Time: '}</span>
                          {args.event._def.extendedProps.actual_medication_dose_time && args.event._def.extendedProps.actual_medication_dose_time}</div>
                      </div>}
                    {args.event._def.extendedProps.medication_status_name &&
                      <div style={{ display: "flex" }}>
                        <div ><span style={{ fontWeight: "bold" }}>{'Medication Status: '}</span>
                          {args.event._def.extendedProps.medication_status_name}</div>
                      </div>}
                    {/* <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Reason: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div> */}
                    {args.event._def.extendedProps.review_comment &&
                      <div style={{ display: "flex" }}>
                        <div><span style={{ fontWeight: "bold" }}>{'Feedback: '}</span>
                          {args.event._def.extendedProps.review_comment}</div>
                      </div>}
                    {args.event._def.extendedProps.employee_name && <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Attent By: '}</span>
                        {args.event._def.extendedProps.employee_name}</div>
                    </div>}

                   <div>
                      <div><span style={{ fontWeight: "bold" }}>{args.event._def.extendedProps.linkedWithVisit ? 'Linked To Visit' : 'Not Linked To Visit'}</span>
                        </div>
                    </div>
                    
                    {/* <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Last Administered TIme: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div> */}
                  </>
                  }
                    arrow
                  >
                    <div className='d-flex justify-content-around w-100'>
                      <div className='w-100'>
                        {/* <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                      <span>{args.event._def.extendedProps.gender ?
                        (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                      }</span>
                      <span>{args.event._def.extendedProps.priority_icon}</span> */}
                        {args.event._def.extendedProps.medication_status_name && 
                        <span>
                          {getStatus(args.event._def.extendedProps.medication_status_name)}
                        </span>}
                        <span>{(args.event._def.title)}</span>
                      </div>
                      <div style={{width: "fit-content"}}>
                      <span style={{marginRight: "2px" }}>{args.event._def.extendedProps.linkedWithVisit ? 'V' : 'NV'}</span>
                      </div>
                      {/* <div>
                      <span>{args.event._def.extendedProps.assigned_by}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>

                      </div>  */}

                    </div>

                  </BootstrapTooltip>
                </>
              )
            }

          }

          }
        />
      </CalendarContainer>
    </Fragment>
  );
};

export default TimelineMedicationCalendar;
