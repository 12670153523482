import { apiURL, apiURLReplica } from '../env';
import { deleteFetch, deleteFetch2, get, post } from './Api';
import { methods } from 'utils/constants/api';
import queryString from 'query-string';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { FILTER_NONE_ID } from 'utils/constants/rotaShift';
import { dateToLocalStringDate } from '../../shared/methods';
import { getTenant } from '../localStorage/token';
import { isArray } from 'lodash';
import moment from 'moment';

const { POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};
const getBaseUrlMicroServices2 = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v2`;
};

export const setSplitAvailability = async values => {
  console.log(values, "UUUUU")
  if (!values.employee) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/shifts/split-shift-availability/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setAvailability = async values => {

  if (!values.employee) {
    return;
  }
  const baseUrl = getBaseUrl2();

  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/shifts/shifts/${values.id}/`
    : `${baseUrl}/shifts/shifts/`;

  const body = {
    ...values,
  };
  if (body.edit) {
    delete body.edit
  }

  return post(url, body, method);
};

export const setEditBulk = async values => {

  const baseUrl = getBaseUrl2();

  const method = PATCH;
  const url = `${baseUrl}/shifts/shifts/bulk_edit/`

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setUnassignedVisitOnRota = async values => {

  const baseUrl = getBaseUrl2();

  const method = PATCH;
  const url = `${baseUrl}/shifts/shifts/unassigned_visits/`

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setAvailabilityDetails = async (values, availability_Id) => {

  if (!availability_Id) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/shifts/availabilities/${availability_Id}/details/${values.id}`
    : `${baseUrl}/shifts/availabilities/${availability_Id}/details/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const seteEditEmployeeDetails = async (values, availability_Id, detail_id) => {

  if (!availability_Id) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/shifts/shifts/assign_employee_details/?availabilities=${availability_Id}&details=${detail_id}`

  const body = {
    ...values,
  };

  return post(url, body, method);
};

// export const getAvailabilities = shiftAvailabilityId => {
//   const baseUrl = getBaseUrl();
//   const url = `${baseUrl}/shifts/availabilities/${shiftAvailabilityId}/`;

//   return get(url);
// };
export const getUnassignedList = ({ employee, start_date, end_date, start, end }) => {

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/split-shift-availability/visit_unassigned_list/?employee=${employee}&start_date=${start_date} ${start}:00&end_date=${end_date} ${end}:00`;

  return get(url);
};

export const getAvailabilities = (shiftAvailabilityId, id) => {
  const baseUrl = getBaseUrl2();
  // const url = `${baseUrl}/shifts/availabilities/${shiftAvailabilityId}/details/${id}/`;
  const url = `${baseUrl}/shifts/shifts/${shiftAvailabilityId}/`;

  return get(url);
};

export const deleteAvailabilities = async (shiftAvailabilityId, force) => {
  const baseUrl = getBaseUrl2();
  let string = '';
  if (force) {
    string = '?force=true'
  }
  const url = `${baseUrl}/shifts/shifts/${shiftAvailabilityId}/${string}`;

  return deleteFetch2(url);
};

export const deleteAvailabilitiesDetails = async (
  shiftAvailabilityId,
  detailId
) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/availabilities/${shiftAvailabilityId}/details/${detailId}/`;

  return deleteFetch(url);
};

export const getShiftPatterns = values => {
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }
  if (values.unlimited) {
    toParse.limit = 500;
  } else {
    toParse.limit = PAGE_SIZE_RENDER;
  }
  stringified = queryString.stringify(toParse) || '';
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/patterns/?${stringified}`;

  return get(url);
};

export const getRotaShifts = (values, exclude, page) => {

  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl2();

  if (values.shift) {
    toParse['shift_type__id__in'] = values.shift;
  }
  if (values.availability !== '') {
    if (values.availability === FILTER_NONE_ID) {
      toParse['availability'] = values.availability;
    } else {
      toParse.is_available = values.availability;
    }
  }
  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.shift_type_filter) {

    let runRouteFilter = values.shift_type_filter.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.shift_type_filter = strRunRouteFilter;
  }

  if (values.runroutesclient) {

    let runRouteFilter = values.runroutesclient.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runrouteclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {

    let runRouteFilter = values.runroutesteam.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runrouteteam = strRunRouteFilter;
  }

  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id)
    let strJobDepartmentFilter = jobDepartmentFilter.join(",")
    filterParse.jobDepartment = strJobDepartmentFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id)
    let strbranchFilter = branchFilter.join(",")
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id)
    let strheaderbranchFilter = branchFilter.join(",")
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.employees) {
    let employeeFilter = values.employees.map(data => data.id)
    if (employeeFilter.find(emp => emp == 'none')) {
      let strEmployeeFilter = employeeFilter.filter(emp => emp != "none").join(",")
      filterParse.employees_id = strEmployeeFilter;
      //filterParse.Unassigned=true
    } else {
      let strEmployeeFilter = employeeFilter.join(",")
      filterParse.employees_id = strEmployeeFilter;
    }
    // let employeesFilter=values.employees.map(data=>data.id)
    // let stremployeesFilter=employeesFilter.join(",")
    // filterParse.employees_id = stremployeesFilter;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  const new_start_date = new Date(moment(values.start_date).format('YYYY-MM-DD'));
  const new_end_date = new Date(moment(values.end_date).subtract(1, 'days').format('YYYY-MM-DD'));

  let datesRange = '';
  // start_date to utc and backend required format
  // datesRange =
  //   'start_date__gte=' + encodeURIComponent(new_start_date.getUTCString());
  datesRange =
    'start_date__gte=' + moment(new_start_date).format('YYYY-MM-DD') + ' ' + '00:00:00';

  // end_date to utc and backend required format and concat to start_date
  // datesRange =
  //   datesRange + '&end_date__lte=' + encodeURIComponent(new_end_date.getUTCString());
  datesRange =
    datesRange + '&end_date__lte=' + moment(new_end_date).format('YYYY-MM-DD') + ' ' + '23:59:59';

  // datesRange = encodeURIComponent(datesRange)

  // if stringified has value then concat with &


  stringified = queryString.stringify(toParse) || '';
  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }

  // if(filterParse.runroute){
  //   stringified+='&run_route='+filterParse.runroute
  // }


  if (filterParse.runrouteclient) {

    stringified += '&client_run_routes__id__in=' + filterParse.runrouteclient

  }

  if (filterParse.shift_type_filter) {
    stringified += '&shift_type__id__in=' + filterParse.shift_type_filter
  }

  if (filterParse.runrouteteam) {

    stringified += '&employee_run_routes__id__in=' + filterParse.runrouteteam

  }

  if (filterParse.jobDepartment) {
    stringified += '&employee__job_detail__job_category__id__in=' + filterParse.jobDepartment
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        stringified += '&employee__job_detail__branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        stringified += '&employee__job_detail__branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employees_id) {
    stringified += `${exclude.employee ? '&employee__id__exclude=' : '&employee__id__in='}` + filterParse.employees_id
  }
  let url = `${baseUrl}/shifts/shifts/?${stringified}`;
  // if (values.shiftType === CALENDAR_WEEKLY) {
  //   url = `${baseUrl}/scheduler/rota/weekly/?${stringified}`;
  // } else {
  //   url = `${baseUrl}/scheduler/rota/daily/?${stringified}`;
  // }

  return get(url);
};

export const getSchedulerInRotaShifts = (values, exclude, pageSize) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }
  if (values?.location) {
    let locationFilter = values?.location.map(data => data.id);
    let strLocationFilter = locationFilter.join(',');
    filterParse.locations = strLocationFilter;
  }
  if (values.service_category) {
    let serviceFilter = values.service_category.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.service_category = strServiceFilter;
  }
  if (values.service_category_type) {
    let serviceCategoryFilter = values.service_category_type.map(data => data.id);
    let strServiceCategoryFilter = serviceCategoryFilter.join(',');
    filterParse.service_category_type = strServiceCategoryFilter;
  }
  if (values.funder_name) {
    let funder_client_filter = values.funder_name.filter(data => data.is_client);
    let serviceFilter = funder_client_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_client_name = strServiceFilter;
  }
  if (values.funder_name) {
    let funder_filter = values.funder_name.filter(data => !data.is_client);
    let serviceFilter = funder_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_name = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id !== 'none');
    let serviceFilter = funder_cat_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_category = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id == 'none');
    if (funder_cat_filter.length > 0) {
      filterParse.null_funder_category = true;
    }
  }

  // if (values.runroutes) {
  //   let runRouteFilter=values.runroutes.map(data=>data.id)
  //   let strRunRouteFilter=runRouteFilter.join(",")
  //   filterParse.runroute = strRunRouteFilter;
  // }

  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteteam = strRunRouteFilter;
  }
  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id);
    let strJobDepartmentFilter = jobDepartmentFilter.join(',');
    filterParse.jobDepartment = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.visitStatus) {
    let visitStatusFilter = values.visitStatus.map(data => data.id);
    let strVisitStatusFilter = visitStatusFilter.join(',');
    filterParse.visitStatus = strVisitStatusFilter;
  }
  if (values.run_view_name) {
    let runNameFilter = values.run_view_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.run_view_name = strRunNameFilter;
  }

  if (values.availability !== '') {
    toParse.is_available = values.availability;
  }
  if (values.visit_publish) {
    filterParse.visit_publish = values.visit_publish;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = new Date(
    moment(values.start_date).format('YYYY-MM-DD')
  );
  const new_end_date = new Date(moment(values.end_date).format('YYYY-MM-DD'));
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format
  datesRange =
    'start_date=' + encodeURIComponent(new_start_date.getUTCString());

  // end_date to utc and backend required format and concat to start_date
  datesRange =
    datesRange + '&end_date=' + encodeURIComponent(new_end_date.getUTCString());

  conditionString += datesRange;
  if (filterParse.employees) {
    conditionString += `${exclude.employee ? '&exclude_employee=' : '&employees='}` + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += `${exclude.client ? '&exclude_client=' : '&clients='}` + filterParse.clients;
  }
  if (filterParse.locations) {
    conditionString += `${exclude.location ? '&exclude_location=' : '&locations='}` + filterParse.locations;
  }
  if (filterParse.service_category) {
    conditionString += '&service_category=' + filterParse.service_category;
  }
  if( filterParse.service_category_type){
    conditionString += '&service_category_type=' + filterParse.service_category_type;
  }
  if (filterParse.funder_client_name) {
    conditionString += '&funder_client_name=' + filterParse.funder_client_name;
  }
  if (filterParse.funder_name) {
    conditionString += '&funder_name=' + filterParse.funder_name;
  }
  if (filterParse.funder_category) {
    conditionString += '&funder_category=' + filterParse.funder_category;
  }
  if (filterParse.null_funder_category) {
    conditionString += '&null_funder_category=' + 'true';
  }
  const check_unassigned = exclude.employee ? true : filterParse.Unassigned;
  if (check_unassigned) {
    conditionString += '&unassigned=' + (exclude.employee ? !(filterParse.Unassigned ? true : false) : filterParse.Unassigned);
  }

  if (filterParse.runrouteclient) {
    conditionString += '&run_route_client=' + filterParse.runrouteclient;
  }

  if (filterParse.runrouteteam) {
    conditionString += '&run_route_employee=' + filterParse.runrouteteam;
  }

  if (filterParse.jobDepartment) {
    conditionString += '&job_category=' + filterParse.jobDepartment;
  }
  if (filterParse.visitStatus) {
    conditionString += '&visit_status=' + filterParse.visitStatus;
  }
  if (filterParse.run_view_name) {
    conditionString += '&run_view_name=' + filterParse.run_view_name;
  }
  if (filterParse.visit_publish) {
    conditionString += '&isPublished=' + ((filterParse.visit_publish === 'publish') ? 'true' : 'false');
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.headerbranch;
    }
  }
  conditionString += '&view=Web';
  let url = '';
  if (values.shiftType === CALENDAR_WEEKLY) {
    url = `${baseUrl}/scheduler/visits/weekly/?${conditionString}`;
  } else {
    url = `${baseUrl}/scheduler/visits/daily/?${conditionString}`;
  }

  return get(url);
};

export const getSchedulerRotaShifts = (values, exclude, page) => {

  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl2();

  if (values.shift) {
    toParse['shift_type__id'] = values.shift;
  }
  if (values.start_date) {
    toParse.start_date = moment(values.start_date).format('YYYY-MM-DD')
  }
  if (values.end_date) {
    toParse.end_date =  moment(values.end_date).format('YYYY-MM-DD')
  }

  stringified = queryString.stringify(toParse) || '';


  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.shift_type_filter) {
    let runRouteFilter = values.shift_type_filter.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.shift_type_filter = strRunRouteFilter;
  }

  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runrouteclient = strRunRouteFilter;
  }

  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    filterParse.runrouteteam = strRunRouteFilter;
  }

  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id)
    let strJobDepartmentFilter = jobDepartmentFilter.join(",")
    filterParse.jobDepartment = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id)
    let strbranchFilter = branchFilter.join(",")
    filterParse.branch = strbranchFilter;
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id)
    let strheaderbranchFilter = branchFilter.join(",")
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.employees) {
    let employeeFilter = values.employees.map(data => data.id)
    if (employeeFilter.find(emp => emp == 'none')) {
      let strEmployeeFilter = employeeFilter.filter(emp => emp != "none").join(",")
      filterParse.employees_id = strEmployeeFilter;
    } else {
      let strEmployeeFilter = employeeFilter.join(",")
      filterParse.employees_id = strEmployeeFilter;
    }
  }


 if (values.pay_type){
    let payTypeIds = values.pay_type.map(el => el.id).join(",")
    filterParse.pay_type_ids = payTypeIds;
  }

  //start_date= 2024-07-11&
  //end_date= 2024-07-11&
  //employee_ids= 90bb5325-e9b5-4808-ba08-ad0ba0bcb104&
  //employee_run_route_ids= ab19c3f4-1729-4af1-94ba-260c29b8cdf2,&
  //client_run_route_ids= ab19c3f4-1729-4af1-94ba-260c29b8cdf2,&
  //shift_type__id= 90bb5325-e9b5-4808-ba08-ad0ba0bcb104&
  //employee__job_detail__job_category__id=90bb5325-e9b5-4808-ba08-ad0ba0bcb104&
  //employee__job_detail__branch__id=90bb5325-e9b5-4808-ba08-ad0ba0bcb104
 //  pay_type_ids



  // if (filterParse.runrouteclient) {
  //   stringified += '&client_run_route_ids=' + filterParse.runrouteclient
  // }

  if (filterParse.runrouteteam) {
    stringified += '&employee_run_route_ids=' + filterParse.runrouteteam
  }

  //have doubt
  // if (filterParse.shift_type_filter) {
  //   stringified += '&shift_type__id=' + filterParse.shift_type_filter
  // }
  
//have doubt
  if (filterParse.pay_type_ids) {
    stringified += '&pay_type_ids=' + filterParse.pay_type_ids
  }

  if (filterParse.jobDepartment) {
    stringified += '&employee__job_detail__job_category__id=' + filterParse.jobDepartment
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        stringified += '&branch_ids=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch_ids=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        stringified += '&branch_ids=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch_ids=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employees_id) {
    stringified += `${exclude.employee ? '&employee__id__exclude=' : '&employee_ids='}` + filterParse.employees_id
  }
  let url = `${baseUrl}/shifts/scheduler-rota-shifts/?${stringified}`;

  return get(url);
};
// https://stage.myhelpa.com/org/bill-test-org-1/api/v2/shifts/scheduler-rota-shifts/?start_date=2024-07-16&end_date=2024-07-16

export const setShift = async values => {
  if (!values.employees) {
    return;
  }
  const baseUrl = getBaseUrl2();
  const method = POST;
  const url = `${baseUrl}/shifts/shifts/pattern/`;
  // values.id
  //   ? `${baseUrl}/shifts/shifts/pattern/${values.id}/`: 
  //   `${baseUrl}/shifts/shifts/pattern/`;

  // if (values?.start_date) {
  //   values.start_date = dateToLocalStringDate(new Date(values.start_date));
  // }

  // if (values?.end_date) {
  //   values.end_date = dateToLocalStringDate(new Date(values.end_date));
  // }

  const body = {
    ...values,
  };

  return post(url, body, method);
};

// export const getShift = shiftId => {
//   const baseUrl = getBaseUrl();
//   const url = `${baseUrl}/shifts/shifts/${shiftId}/`;

//   return get(url);
// };

export const getShift = (shiftId, id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/shifts/${shiftId}/shift-details/${id}/`;

  return get(url);
};

export const deleteShiftDetails = async (shiftId, detailId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/shifts/${shiftId}/shift-details/${detailId}/`;

  return deleteFetch(url);
};

export const deleteShift = async shiftId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/shifts/${shiftId}/`;

  return deleteFetch(url);
};

export const addRegularShift = async (values, Id) => {
  const baseUrl = getBaseUrl2();
  const method = (values.core && values.core.core_id) ? PATCH : POST;
  const url = (values.core && values.core.core_id)
    ? `${baseUrl}/shifts/regular-shift/${Id}/`
    : `${baseUrl}/shifts/regular-shift/`;

  const body = {
    ...values,
  };
  body.details.forEach((det, index) => {
    delete det.applicable_pay_rate_cards;
    if (det.type.type_id === '643bd5d6-031f-4249-8a34-43722ac9a0d1' || det.type.type_id === '267ebc3e-2224-4dde-b41b-f79cadabd873') {
      body.details[index].travel_mode = null
    }
    if (isArray(det.repeat_on)) {
      delete body.details[index].repeat_on
    }

  })

  return post(url, body, method);
};

export const addRegularShiftOverlap = async (values) => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/shifts/regular-shift/regular_shift_override/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setNewMultiShiftType = (values) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/regular-shift-type/create_multi/`;
  const body = {
    values
  };
  return post(url, values, method);



}

export const getRegularShift = async (values, pageSize) => {

  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (values.tempId) {
    toParse.employee_id = values.tempId;
  }

  if (pageSize) {
    toParse.limit = pageSize || PAGE_SIZE_RENDER;
  }
  stringified = queryString.stringify(toParse) || '';

  // const url = `${baseUrl}/shifts/regular-shift/?employee_id=${id}/?${stringified}`;

  const url = `${baseUrl}/shifts/regular-shift/?${stringified}`;

  return get(url);
};

export const getRegularShiftById = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/regular-shift/${id}/`;

  return get(url);
};

export const deleteRegularShift = (idSelected, is_deleted) => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/shifts/regular-shift/${idSelected}/`
  const body = {
    is_deleted: is_deleted

  };
  return post(url, body, method);

};

export const getTableData = (detailId, isShift) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  const url = isShift ?
    `${baseUrl}/shifts/shift-clock-count/get_shift_count/?detail=${detailId}&is_shift=${isShift}` :
    `${baseUrl}/shifts/shift-clock-count/get_shift_count/?detail=${detailId}`
  return get(url);
};

export const getAttachedRateCard = (values, mode = "") => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();

  toParse.branch = values.branch;
  toParse.employee_id = values.employeeId;
  toParse.pay_type = values.pay_type;
  toParse.populate_rate_card = true;
  toParse.start_date = values.start_date;
  if (values.end_date) {
    toParse.end_date = values.end_date;
  }


  stringified = queryString.stringify(toParse) || '';
  if (mode == "is_travel") {
    stringified += `&is_travel=${true}`
  }
  const url = `${baseUrl}/employees/employee/rate/card/datewise/?${stringified}`
  return get(url);
};


export const getAbsenceHours = (values) => {
  const baseUrl = getBaseUrlMicroServices2();

  let stringified = '';
  let toParse = {};

  toParse.branch = values.branch
  toParse.employee = values.employee
  toParse.start_date = values.start_date
  toParse.branch_specific = values.branch_specific || false
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/shifts/shifts/absenceHours/?${stringified}`;

  return get(url);
  // /shifts/shifts/absenceHours/?branch=f96a4c64-1d95-4861-8ec7-16ca23df6b40&employee=87752eac-aa65-45c9-99d1-cd9b34352d70&start_date=2024-05-16
}


export const recalculatebasenceHr = (shiftId) => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/${shiftId}/recalculateAbsenceHours/`;
  return get(url);
}
