import React, { useState, useEffect } from 'react';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import {
  AUTH,
  CLIENT,
  COMPANY,
  DASHBOARDS,
  BELLNOTIFICATIONTABLE,
  EVV,
  LOGIN,
  PAGE_404,
  ROTA_SHIFTS,
  SCHEDULER,
  SETTINGS,
  REPORT,
  SETTINGS_CLIENT,
  REPORT_CLIENT,
  REPORT_KPI,
  FINANCE,
  BILLING,
  PAYROLL,
  SUMMARY,
  SETTINGS_ROLES_AND_PERMISSIONS,
  TEAM_ROUTE_MAPPING,
  SETTINGS_ORGANIZATIONS,
  SETTINGS_SUBSCRIPTION,
  SETTINGS_TEAM,
  REPORT_TEAM,
  SETTINGS_CALENDAR,
  SHIFT_PATTERNS,
  TEAM,
  KPI,
  USERS,
  VISITS,
  REGULAR_VISIT,
  CANCELLED_VISITS,
  SETTINGS_ALGOSCORE,
  ALGO_SCORE_CLIENT,
  COMPANY_BRANCHES,
  //ABSENCE_HISTORY_LIST,
  CALENDAR_DATA,
  SETTINGS_RATECARD,
  SETTINGS_EVV,
  TRIAGE,
  SETTINGS_FINANCE,
  ALERTS_FEATURES,
  SETTINGS_ALERT, VISIT, VISIT_CARE_NOTES,
  SETTINGS_TRIAGE, SETTINGS_ESV, SETTINGS_EMAIL, ESV, MEDICATION, OFFER_VISITS, CLIENT_TASKS, FORM_BUILDER, FORM_BUILDER_ADD, SETTINGS_FORM_BUILDER, FORM_CATAGORY_ADD, DASHBOARD_CLIENT_KPI, DASHBOARD_VISIT_KPI, DASHBOARD_MEDICATION_KPI, HANDBACK_REQUEST, SETTINGS_BRADFORD, BRADFORD_ROUTE, SWAP_VISIT, VISIT_AUDIT, DASHBOARD_FINANCE_KPI, LINKED_USER, TEAM_SKILL_MATRIX, TEAM_COMPETENCY_MATRIX, BRANCH_SETTING, INVOICE, HR_TASKS, TEAM_HR_TASK_MATRIX, CLIENT_HR_TASK_MATRIX, NOTIFICATIONS_SETTING_URL, LOCATION, REPORT_SERVICE_CONTRACT_ROUTE, DASHBOARD_INSIGHTS,
  DASHBOARD_TEAM_KPI,
  CLIENT_ABOUT_ME_ROUTE,
  HEAVY_PRINT,
  SETTINGS_LOGIN_ROUTE,
  RISK_ASSESSMENT_ROUTE,
  ACCOUNT_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  DELETE_ACCOUNT_ROUTE,

} from 'utils/constants/routes';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import HomeComponent from 'components/DashboardComponents/HomeComponent';
import Team from 'components/DashboardComponents/Team';
import AddModal from 'components/DashboardComponents/Team/AddModal'
import AddModalClient from 'components/DashboardComponents/Client/AddModal'
import VisitCareNote from '../Client/AddModal/VisitCareNotes/VisitCareNote';
import DashBoard from 'components/DashboardComponents/Dashboard';
import Client from 'components/DashboardComponents/Client';
import Settings from 'components/DashboardComponents/Settings/SettingsCompany';
import SettingsEvv from 'components/DashboardComponents/Settings/SettingsEvv';
import SettingsEsv from '../Settings/SettingsEsv/SettingsEsv';
import SettingsTriage from 'components/DashboardComponents/Settings/SettingsAlert';
import Triage from '../Alerts';
import Branches from 'components/DashboardComponents/Settings/SettingsCompany/Branches';
import SettingsTeam from 'components/DashboardComponents/Settings/SettingsTeam';
import CalendarSettings from 'components/DashboardComponents/Settings/SettingsCalendar/Calendar';
import SettingsCalendar from 'components/DashboardComponents/Settings/SettingsCalendar';
import SettingsClient from 'components/DashboardComponents/Settings/SettingsClient';
import SettingsRolesAndPermissions from 'components/DashboardComponents/Settings/SettingsRolesAndPermissions';
import SettingOrganization from 'components/DashboardComponents/Settings/SettingOrganization';
import SettingSubscription from 'components/DashboardComponents/Settings/SettingSubscription';
import SettingsAlgoScore from 'components/DashboardComponents/Settings/SettingsAlgoScore';
import SettingsFinance from 'components/DashboardComponents/Settings/SettingsFinance'
import SettingsRateCard from 'components/DashboardComponents/Settings/SettingsRateCard';
import ClientVisits from 'components/DashboardComponents/ClientVisits';
import CancelledVisits from 'components/DashboardComponents/CancelledVisit';
import RotaShifts from 'components/DashboardComponents/RotaShifts';
import Scheduler from 'components/DashboardComponents/Scheduler';
import ShiftPatterns from 'components/DashboardComponents/ShiftPatterns';
import Evv from 'components/DashboardComponents/Evv';
import SettingsUsers from 'components/DashboardComponents/Settings/SettingUsers';
import TeamRouteMapping from 'components/DashboardComponents/TeamRouteMapping';
import AbsenceHistoryList from 'components/DashboardComponents/Team/AbsenceHistoryList'
import ClientPrePinning from 'components/DashboardComponents/ClientPrePinning';
import FinanceBilling from 'components/DashboardComponents/Finance/FinanceBilling'
import FinancePayroll from 'components/DashboardComponents/Finance/FinancePayroll'

import SettingsBradFord from '../Settings/SettingsBradFord/settingsBradFord';
// import Triage from 'components/DashboardComponents/Triage/';
import NotificationTable from 'components/DashboardComponents/Navbar/RealTimeNotification/NotificationTable';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { setEmployee, setClientId }
  from 'utils/localStorage/token';
import TAB_VISIT_CARE_NOTES_URL from "../Report/ReportClient/index"
import {
  getCategoryPermission,
  getEmail,
  getPermissions,
  getUserFirstName,
  getUserLastName,
  getUserName,
  setFilteredPermissions,
  getClient,
  getStaff,
  getSubSectionPermissions,
} from '../../../utils/localStorage/user';
import { logout } from '../../../utils/api/SessionApi';
import { getChoices, getChoicesMap } from 'utils/api/CoreApi';
import {
  ADMIN_EMAIL,
  CALENDER_PERMISSION,
  CLIENT_PERMISSION,
  CLIENT_SETTINGS,
  COMPANY_SETTINGS,
  LIST_OF_EMPLOYEES_SETTINGS,
  ROLES_AND_PERMISSIONS_SETTINGS,
  SETTINGS_PERMISSION,
  TEAM_PERMISSION,
  TEAM_SETTINGS,
  CALENDAR_SETTINGS,
  WEB_PERMISSION,
  WEB_PERMISSION_ALL,
  WEB_READ_PERMISSION,
  SETTINGS_ALGO_SCORE,
  LIST_OF_CLIENT_SETTINGS,

  CLIENT_VISITS,
  CLIENT_CANCELLED_VISITS,
  ROTA_SHIFTS_SETTINGS,
  SHIFT_PATTERN_SETTINGS,
  EVV_SETTINGS,
  SCHEDULER_SETTINGS,
  REGULAR_VISITS,
  TEAM_ROUTE_MAP,
  ABSENCE_LIST,
  BRANCH_SETTINGS,
  WEB_DELETE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION, TRIAGE_PERMISSION, CLIENT_OFFER_VISITS, CLIENT_HANDBACK, DASHBOARD_PER, NOTIFICATION_ICON,
  INSIGHTS

} from '../../../utils/constants/permissions';
import { getRolePermissions } from 'utils/api/CoreApi'
import { Toast } from 'primereact/toast';
import { getAdmin, getSuperAdmin } from "../../../utils/localStorage/token";
import RegularVisitModalRun from '../ClientPrePinning/AddPinModal/RegularVisitModalRun';
import ReportTeam from '../Report/ReportTeam';
import ReportClient from '../Report/ReportClient';
import ReportKpi from '../Report/ReportClient/ReportKpi';
import NewVisitCare from '../Report/ReportClient/ClientFilters/NewVisitCare';
import Esv from '../Esv';
import { useRef } from 'react';
import MedicationCalendar from '../MedicationCalendar';
import HeavyPrint from "./HeavyPrint"
import OfferVisits from '../OfferVisit';
import Formbuilder from '../FormBuilder';
import FormBuilderMainPage from '../FormBuilder/firstPage';
import SettingsFormBuilder from '../Settings/SettingsFormBuilder';
//import FormCategory from '../Settings/SettingsFormBuilder/DynamicCatagoryBuilder';
import TeamKpis from '../DashboardKPIs/TeamKpis';
import ClientKpis from '../DashboardKPIs/ClientKpis';
import VisitKpis from '../DashboardKPIs/VisitKpis';
import AbsenceHistoryTab from '../Team/AbsenceHistoryList/AddModal/AbsenceHistoryTab';
import AbsenceOverviewTab from '../Team/AbsenceHistoryList/AddModal/AbsenceOverviewTab';
import MedicationKpis from '../DashboardKPIs/MedicationKpis';
import SettingsFormBuilders from '../FormBuilder/showFormModal/SettingFormBuilders';
import HandBack from '../Client/HandBack';
import Spinner from 'components/SharedComponents/SpinnerUnstyled';
import { SchedulerContainer } from '../Scheduler/styles';
import SettingEvvList from '../Settings/SettingsEvv/SettingEvvList';
import Bradford from '../Team/Bradford';
import Planner from '../Scheduler/Planner';
import SwapVisit from '../Client/SwapVisit';
import VisitAudit from '../Settings/VisitAudit';
import SwapShift from '../Team/SwapShift';
import NotificationSettings from '../Navbar/RealTimeNotification/NotificationSettings';
import EmailHistory from '../Settings/EmailHistory';
import Expense from '../Client/Expense';
import Invoice from '../Invoice';
import SettingLinkedUser from '../SettingLinkedUser';
import TeamSkillMatrix from '../Team/SkillMatrix';
import TeamCompetencyMatrix from '../Team/CompetancyMatrix';
import TaskMatrix from '../Settings/SettingsHrTasks';
import { getHrTaskListApi } from 'utils/api/SettingsApi';
import { useCallback } from 'react';
import HRTaskMatrix from '../Team/HrTaskMatrix';
import ClientHrTaskMatrix from '../Client/HRTaskMatrix';
import Location from '../Location';
import AddLocationModal from '../Location/AddLocationModal';
import ServiceContract from '../Report/ReportClient/ClientFilters/ServiceContract';
import DashboardInsights from '../DashboasdInsights';
import SettingsLogin from '../Settings/SettingsLogin';
import AboutMe from '../Client/AboutMe';

import { ROLES_AND_PERMISSIONS } from "utils/constants/localStorage"
import AddAccountModal from '../Navbar/AccountsSetting/AddModal';



function Router(props) {
  const [rolValues, setRolValues] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [permissionsNotificationIcon, setPermissionsNotificationIcon] = useState(false);
  const [team, setTeam] = useState(false);
  const [triage, setTriage] = useState(false)
  const [client, setClient] = useState(false);
  const [calender, setCalender] = useState(false);
  const [settings, setSettings] = useState(false);
  const [teamSettings, setTeamSettings] = useState(false);
  const [calendarSettings, setCalendarSettings] = useState(false);
  const [companySettings, setCompanySettings] = useState(false);
  const [companyBranches, setCompanyBranches] = useState(false);
  const [contextChoices, setContextChoices] = useState({})
  const [contextChoicesFilter, setContextChoicesFilter] = useState({})
  const [contextChoicesMap, setContextChoicesMap] = useState({})
  const [contextPermissions, setContextPermissions] = useState([])
  const [contextCategoryPermissions, setContextCategoryPermissions] = useState([])
  const [contextSubSectionPermissions, setContextSubSectionPermissions] = useState([])
  const [contextSubscription, setContextSubscription] = useState({})
  const [contextRelativesClient, setContextRelativesClient] = useState([])
  const [contextOrganization, setContextOrganization] = useState([])
  const [branchFilter, setBranchFilter] = useState([])
  const [contextIsSuperAdmin, setContextIsSuperAdmin] = useState(null)
  const [contextIsAdmin, setContextIsAdmin] = useState(null)
  const [contextIsStaff, setContextIsStaff] = useState(null)
  const [branchData, setBranchData] = useState([]);
  const [headerFilterClearValue, setHeaderFilterClearValue] = useState();
  const [algoScoreSettings, setAlgoScoreSettings] = useState(false);
  const [rateCardSettings, setRateCardSettings] = useState(false);
  const [roleAndPermissionsSettings, setRoleAndPermissionsSettings] =
    useState(false);
  const [clientSettings, setClientSettings] = useState(false);
  const [usersSettings, setUsersSettings] = useState(false);
  const [listOfClients, setListOfClients] = useState(false);
  const [listOfEmployees, setListOfEmployees] = useState(false);
  const [clientVisits, setClientVisits] = useState(false);
  const [dashboardPermission, setDashboardPermission] = useState(false);

  const [cancelledVisits, setCancelledVisits] = useState(false);
  const [readPermissionHandback, setReadPermissionsHandback] = useState(false)
  const [offerVisits, setOfferVisits] = useState(false);
  const [rotaShift, setRotaShift] = useState(false);
  const [shiftPattern, setShiftPattern] = useState(false);

  const [regularVisit, setRegularVisit] = useState(false);
  const [schedulerPage, setSchedulerPage] = useState(false);
  const [evvs, setEvvs] = useState(false);
  const [esvs, setEsvs] = useState(false);
  const [teamRoute, setTeamRoute] = useState(false);
  const [absenceList, setAbsenceList] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [changeChoices, setChangeChoices] = useState(true)
  const [loadingChoices, setLoadingChoices] = useState(true)
  const [plannerPropsData, setplannerPropsData] = useState({ resources: [], events: [] })
  const [hrTask, setHrTask] = useState([])

  const toast = useRef();

  // roles and permissions 
  const [updateRolesAndPermissions, setUpdateRolesAndPermissions] = useState(false);
  const [createRolesAndPermissions, setCreateRolesAndPermissions] = useState(false);
  const [deleteRolesAndPermissions, setDeleteRolesAndPermissions] = useState(false);
  const [readRolesAndPermissions, setReadRolesAndPermissions] = useState(false);

  const userClient = getClient();

  const setplannerData = (res, events, planned_filter) => {
    setplannerPropsData({ resources: res, events: events, planned_filter: planned_filter })
  }


  const handleGetChoices = async (temp_arr) => {
    let choices_used = temp_arr.filter((val) => !contextChoices[val])
    if (choices_used.length > 0) {
      setLoadingChoices(true)
      let body = choices_used.join(',')
      try {
        const res = await getChoices(body)
          setLoadingChoices(false)
          setContextChoicesFilter({ ...contextChoicesFilter, ...res })
          setContextChoices(prev=>{return { ...prev, ...res }})
          if (res) {
            let choices_val = { ...res }
            let temp_obj = {}
            Object.keys(res).forEach((key, index) => {
              temp_obj[key] = {}
              if (Array.isArray(choices_val[key])) {
                choices_val && choices_val[key] && choices_val[key].forEach((val) => {
                  temp_obj[key][val.id] = val;
                })
              }
            })
            setContextChoicesMap({ ...contextChoicesMap, ...temp_obj })
          }
      } catch (error) {
        setLoadingChoices(false);
      }
   
        
    }
  }

  const handleChangeChoices = async (changedKey) => {
    let body = ''
    if (Array.isArray(changedKey)) {
      body = changedKey.join(',')
    } else {
      body = changedKey
    }

    try {
      const res = await getChoices(body)
  
      setLoadingChoices(false)
      if (branchFilter && (branchFilter.length > 0) && ((changedKey == 'branch') || (changedKey == null))) {

        let temp = [];

        const visitExists = (id) => {
          return branchFilter.some(function (el) {
            return el === id;
          });
        }
        res.branch.map((visit) => {
          if (visitExists(`${visit.id}`)) {
            temp.push(visit)
          }
        })
        setContextChoices({ ...res, branch: temp })
      } else {
        setContextChoices({ ...contextChoices, ...res })
      }
      return {...contextChoices, ...res}
    } catch (error) {
      console.error("Error: fetching choices",error)
    }
    // setChangeChoices(prevState=>!prevState)
  }

  const handleChangeChoicesMap = (changedKey) => {
    getChoicesMap(changedKey)
      .then(res => {
        setLoadingChoices(false)
        setContextChoicesMap({ ...contextChoicesMap, ...res })
      })
  }

  // useEffect(() => {
  //   handleGetChoices(["client_listing", "employee_listing",'branch'])
  // }, [])

  const handleChangePermissions = async () => {
    const role_perms_res = await getRolePermissions();
    setContextPermissions(role_perms_res.data?.role_permission || '')
    setContextCategoryPermissions(role_perms_res.data?.category_role_permission || '')
    setContextSubSectionPermissions(role_perms_res.data?.sub_section_role_permission || '')
    setEmployee(role_perms_res.employee_id)
    setClientId(role_perms_res.client_id)
    setContextIsAdmin(role_perms_res.is_admin)
    setContextIsSuperAdmin(role_perms_res.is_superuser)
    setContextIsStaff(role_perms_res.is_staff)
  }
  
  const [rolesAndPermissionsLoader, setRolesAndPermissionsLoader] = useState(true);
  const getRolesAndPermissions = async () => {
    setRolesAndPermissionsLoader(true);
    // let role_perms_res = {};
    // let localRolesAndPermissions = localStorage.getItem("roles-permissions");
    // if(process.env.NODE_ENV==="development" && localRolesAndPermissions) {
    //   role_perms_res = JSON.parse(localRolesAndPermissions);
    // } else {
    //   role_perms_res = await getRolePermissions();
    //   if(process.env.NODE_ENV==="development")
    //     localStorage.setItem("roles-permissions", JSON.stringify(role_perms_res))
    // }
    let role_perms_res = await getRolePermissions();

    console.log(role_perms_res, "rooooo")
    setContextPermissions(role_perms_res.data?.role_permission || '')
    setContextCategoryPermissions(role_perms_res && role_perms_res?.data?.category_role_permission || '')
    setContextSubSectionPermissions(role_perms_res.data?.sub_section_role_permission || '')
    setContextRelativesClient(role_perms_res.relatives_client || [])
    setContextOrganization(role_perms_res.data?.organizations || [])
    setEmployee(role_perms_res.employee_id)
    setClientId(role_perms_res.client_id)
    setContextIsAdmin(role_perms_res?.is_admin)
    setContextIsSuperAdmin(role_perms_res.is_superuser)
    setContextIsStaff(role_perms_res.is_staff)
    setBranchFilter(role_perms_res.employeeBranches || role_perms_res.clientBranches || [])
    let arr = {};
    role_perms_res.subscription_permission && role_perms_res.subscription_permission.forEach((cat) => {
      arr[cat.category_name] = {};
      arr[cat.category_name][WEB_PERMISSION] = cat?.[WEB_PERMISSION];
      if (cat.section && (cat.section.length > 0)) {
        cat.section.forEach((sec) => {
          arr[sec.section_name] = {};
          arr[sec.section_name][WEB_PERMISSION] = sec?.[WEB_PERMISSION];
          if (sec.sub_section && (sec.sub_section.length > 0)) {
            sec.sub_section.forEach((sub_sec) => {
              arr[sub_sec.sub_section_name] = {};
              arr[sub_sec.sub_section_name][WEB_PERMISSION] = sub_sec?.[WEB_PERMISSION];
            })
          }
        })
      }
    })
    setContextSubscription(arr);
    setRolesAndPermissionsLoader(false);
  }

  useEffect(() => {
    getRolesAndPermissions()
    return ()=>{
      localStorage.removeItem(ROLES_AND_PERMISSIONS)
    }
  }, [])

  // useEffect(() => {
  //   if (branchData.headerbranch && (branchData.headerbranch.length > 0)) {
  //     let tempemp = [];
  //     let tempclient = [];
  //     let temp = [];
  //     const visitExistsEmp = (id) => {
  //       return branchData.headerbranch.some(function (el) {
  //         return el.id === id;
  //       });
  //     }
  //     contextChoicesFilter.employee_listing.map((visit) => {
  //       if (visitExistsEmp(`${visit.branch}`)) {
  //         tempemp.push(visit)
  //       }
  //     })
  //     const visitExistsClient = (id) => {
  //       return branchData.headerbranch.some(function (el) {
  //         return el.id === id;
  //       });
  //     }
  //     contextChoicesFilter.client_listing.map((visit) => {
  //       if (visitExistsClient(`${visit.branch}`)) {
  //         tempclient.push(visit)
  //       }
  //     })

  //     const visitExists = (id) => {
  //       return branchFilter.some(function (el) {
  //         return el === id;
  //       });
  //     }
  //     contextChoicesFilter.branch.map((visit) => {
  //       if (visitExists(`${visit.id}`)) {
  //         temp.push(visit)
  //       }
  //     })
  //     if (contextIsAdmin) {
  //       setContextChoices({ ...contextChoices, employee_listing: tempemp, client_listing: tempclient })
  //     } else {
  //       setContextChoices({ ...contextChoices, employee_listing: tempemp, client_listing: tempclient, branch: temp })
  //     }

  //   } else {
  //     setContextChoices({ ...contextChoicesFilter })
  //   }


  // }, [branchData])

  // useEffect(() => {
  // getChoices()
  // .then(res => {
  //     setLoadingChoices(false)
  //     setContextChoicesFilter(res || [])
  //     setContextChoices(res || [])
  // })
  // getChoicesMap()
  //   .then(res => {
  //     setLoadingChoices(false)
  //     setContextChoicesMap({ ...contextChoicesMap, ...res })
  //   })
  // }, [])

  useEffect(() => {
    let role = [...contextCategoryPermissions];
    let settingsRole = [...contextPermissions];

    setFirstName(getUserFirstName());
    setLastName(getUserLastName());
    setEmail(getEmail());

    const rolePermissions = {};
    if (contextIsSuperAdmin || contextIsAdmin) {
      setTeam(true);
      setTriage(true)
      setClient(true);
      setSettings(true);
      setCalender(true);
      setDashboardPermission(true)
      setPermissionsNotificationIcon(true)
    } else {
      if (role.length !== 0) {
        role.forEach(role => {
          let name = role.section.name;
          rolePermissions[name] = {};
          rolePermissions[name][WEB_PERMISSION] = {};
          rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
            role[WEB_PERMISSION_ALL];
          rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
            role[WEB_READ_PERMISSION];
          rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
            role[SELF_WEB_READ_PERMISSION];
        });

        settingsRole.forEach(role => {
          if (role.section !== null) {
            let name = role.section.name;
            rolePermissions[name] = {};
            rolePermissions[name][WEB_PERMISSION] = {};
            rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
              role[WEB_PERMISSION_ALL];
            rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
              role[WEB_READ_PERMISSION];
            rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
              role[SELF_WEB_READ_PERMISSION];
          }
        });

        if (contextIsAdmin || contextIsSuperAdmin) {
          setAbsenceList(true);

        }

        setPermissions(prevState => ({ ...prevState, ...rolePermissions }));
        if (rolePermissions[INSIGHTS]) {
          setDashboardPermission(
            (rolePermissions[INSIGHTS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[INSIGHTS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        if (rolePermissions[TEAM_PERMISSION])
          setTeam(
            rolePermissions[TEAM_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[TEAM_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[TRIAGE_PERMISSION])
          setTriage(
            rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          )
        if (rolePermissions[CLIENT_PERMISSION])
          setClient(
            rolePermissions[CLIENT_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[CALENDER_PERMISSION])
          setCalender(
            rolePermissions[CALENDER_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CALENDER_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[SETTINGS_PERMISSION])
          setSettings(
            rolePermissions[SETTINGS_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[SETTINGS_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[NOTIFICATION_ICON])
          setPermissionsNotificationIcon(
            rolePermissions[NOTIFICATION_ICON][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[NOTIFICATION_ICON][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
      }

      if (settingsRole.length !== 0) {
        settingsRole.forEach(role => {
          if (role.section !== null) {
            let name = role.section.name;
            rolePermissions[name] = {};
            rolePermissions[name][WEB_PERMISSION] = {};
            rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
              role[WEB_PERMISSION_ALL];
            rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
              role[WEB_READ_PERMISSION];
            rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
              role[SELF_WEB_READ_PERMISSION];
          }
        });
        setPermissions(prevState => ({ ...prevState, ...rolePermissions }));

        if (rolePermissions[TEAM_SETTINGS])
          setTeamSettings(
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[CLIENT_SETTINGS])
          setClientSettings(
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        // if (rolePermissions[CALENDAR_SETTINGS])
        // setCalendarSettings(
        //   rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]);


        if (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS])
          setRoleAndPermissionsSettings(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        // if (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS])
        // setOrganizations(
        //   rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]
        // );

        if (rolePermissions[COMPANY_SETTINGS])
          setCompanySettings(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[SETTINGS_ALGO_SCORE])
          setAlgoScoreSettings(
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        //permission ket rate card 
        // if (rolePermissions[SETTINGS_RATE_CARD])
        // setRateCardSettings(
        //   rolePermissions[SETTINGS_RATE_CARD][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]
        // );

        // setUsersSettings(
        //   rolePermissions[USER_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION]
        // );
        if (rolePermissions[LIST_OF_EMPLOYEES_SETTINGS])
          setListOfEmployees(
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[TEAM_ROUTE_MAP])
          setTeamRoute(
            rolePermissions[TEAM_ROUTE_MAP][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[TEAM_ROUTE_MAP][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[ABSENCE_LIST])


          setAbsenceList(
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[LIST_OF_CLIENT_SETTINGS])

          setListOfClients(
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[TRIAGE])

          setTriage(
            rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
        if (rolePermissions[CLIENT_VISITS])
          setClientVisits(
            rolePermissions[CLIENT_VISITS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_VISITS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[CLIENT_CANCELLED_VISITS])
          setCancelledVisits(
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[CLIENT_HANDBACK])
          setReadPermissionsHandback(
            rolePermissions[CLIENT_HANDBACK][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_HANDBACK][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        if (rolePermissions[CLIENT_OFFER_VISITS]) {
          setOfferVisits(
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        }
        //implemented checks if user is client  rota page  will not be visible 
        if (rolePermissions[ROTA_SHIFTS_SETTINGS] && (userClient === 'null'))
          setRotaShift(
            rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );


        if (rolePermissions[SHIFT_PATTERN_SETTINGS])
          setShiftPattern(
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[EVV_SETTINGS])
          setEvvs(
            rolePermissions[EVV_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[EVV_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[EVV_SETTINGS])
          setEsvs(
            rolePermissions[EVV_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[EVV_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );


        if (rolePermissions[SCHEDULER_SETTINGS])
          setSchedulerPage(
            rolePermissions[SCHEDULER_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[SCHEDULER_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );

        if (rolePermissions[REGULAR_VISITS])
          setRegularVisit(
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        // new line added 
        //   if( (rolePermissions[LIST_OF_CLIENT_SETTINGS] &&  rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]) || (rolePermissions[CLIENT_VISITS] && rolePermissions[CLIENT_VISITS][WEB_PERMISSION][WEB_READ_PERMISSION])
        //   || (rolePermissions[CLIENT_CANCELLED_VISITS] &&  rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION ])
        //   )
        // setClient(true);
      }
    }


    setFilteredPermissions(rolePermissions);



  }, [rolValues, contextPermissions, contextCategoryPermissions, contextIsSuperAdmin, contextIsAdmin]);


  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin == true || contextIsSuperAdmin == true) {
      setCreateRolesAndPermissions(true);
      setDeleteRolesAndPermissions(true);
      setUpdateRolesAndPermissions(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === ROLES_AND_PERMISSIONS_SETTINGS) {
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS] = {};
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        if (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS]) {
          setCreateRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeleteRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdateRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
          );
        }
      }

    }

  }, [contextPermissions, contextIsSuperAdmin, contextIsAdmin])


  const history = useHistory();

  const signOutAndRedirect = async () => {
    await logout(getUserName(), getEmail());
    localStorage.clear();
    history.push(AUTH + LOGIN);
  };


  useEffect(() => {
    let roles = contextCategoryPermissions;

    if (roles) {
      setRolValues(roles);
    } else {
      signOutAndRedirect();
    }
  }, [contextCategoryPermissions]);

  useEffect(() => {
    let rolePermissions = {};
    const rolesPermissions = contextSubSectionPermissions;

    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {

        // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
        if (item.section !== null) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
          ] = item?.[SELF_WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      }
        // }
      );
    }

    //BranchSettings
    if (rolePermissions[BRANCH_SETTINGS]) {
      setCompanyBranches(
        rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
        rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
      );
    }
    if (rolePermissions[CALENDAR_SETTINGS])
      setCalendarSettings(
        rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
        rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSubSectionPermissions]);

  const getBranchItem = (items) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: props.location.pathname,
      search: stringified,
    });
    setBranchData(items)

  }

  const getFilterClearValue = (headerFilterClearAll) => {
    setHeaderFilterClearValue(headerFilterClearAll)
  }


  return (
    <AppContext.Provider value={{ handleChangeChoices, plannerPropsData, handleGetChoices, setplannerData, toast, contextSubscription, contextChoices, contextChoicesMap, contextPermissions, contextCategoryPermissions, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextRelativesClient, setContextChoices, setContextChoicesMap, loadingChoices }}>
      <Toast ref={toast} position="top-right" />
      {rolesAndPermissionsLoader ? <Spinner />:
        <>
          {/* <SchedulerContainer loading={loading}> */}
          <DashBoard
            getBranchItem={getBranchItem}
            getFilterClearValue={getFilterClearValue}
            contextIsAdmin={contextIsAdmin}
            contextIsSuperAdmin={contextIsSuperAdmin}
            contextIsStaff={contextIsStaff}
            contextPermissions={contextPermissions}
            contextCategoryPermissions={contextCategoryPermissions}
            contextSubSectionPermissions={contextSubSectionPermissions}
            contextSubscription={contextSubscription}
            permissionsNotificationIcon={permissionsNotificationIcon}
            contextOrganization={contextOrganization}
          >
            <BranchItemsContext.Provider value={{ branchData, headerFilterClearValue }}>
              <Switch>
                {/* <Route exact path={DASHBOARDS} component={HomeComponent} /> */}
                <Route exact path={DASHBOARDS}
                  //  component={DashboardInsights}
                  render={(props) => {
                    if (dashboardPermission) {
                      return (
                        <DashboardInsights {...props} dashboardPermission={dashboardPermission} handleChangeChoices={handleChangeChoices} />
                      )
                    }
                  }}
                />
                <Route exact path={DASHBOARD_TEAM_KPI} component={TeamKpis} />
                <Route exact path={DASHBOARD_CLIENT_KPI} component={ClientKpis} />
                <Route exact path={DASHBOARD_VISIT_KPI} component={VisitKpis} />
                <Route exact path={DASHBOARD_INSIGHTS} component={DashboardInsights} />
                <Route exact path={DASHBOARD_MEDICATION_KPI} component={MedicationKpis} />
                <Route exact path={BELLNOTIFICATIONTABLE} component={NotificationTable} />
                <Route exact path={NOTIFICATIONS_SETTING_URL} component={NotificationTable} />
                <Route exact path={TEAM} component={(listOfEmployees || contextIsAdmin || contextIsSuperAdmin) ? Team : ''} />
                <Route exact
                  path={`${TEAM}/add/personalDetails`}
                  render={(props) => (
                    <AddModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${TEAM}/add/personal`} component={AddModal} />
                <Route exact path={`${TEAM}/add/jobDetails`} component={AddModal} />
                <Route exact path={`${TEAM}/add/compliance`} component={AddModal} />
                {/* <Route exact path={`${TEAM}/add/employeeDetails`} component={AddModal} /> */}
                <Route exact
                  path={`${TEAM}/add/employeeDetails`}
                  render={(props) => (
                    <AddModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${TEAM}/add/skills`} component={AddModal} />
                <Route exact path={`${TEAM}/add/regualarShift`} component={AddModal} />
                <Route exact path={`${TEAM}/add/notes`} component={AddModal} />
                <Route exact path={`${TEAM}/add/files`} component={AddModal} />
                <Route exact path={`${TEAM}/add/risk-assessment`} component={AddModal} />
                <Route exact path={`${TEAM}/add/clientTask`} component={AddModal} />
                <Route exact path={`${TEAM}/add/triage`} component={AddModal} />
    
                <Route exact path={`${TEAM}/add/expenses`} component={AddModal} />
                <Route exact
                  path={`${TEAM}/edit/:id/personalDetails`}
                  render={(props) => (
                    <AddModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${TEAM}/edit/:id/jobDetails`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/compliance`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/rates`} component={AddModal} />
                
                {/* <Route exact path={`${TEAM}/edit/:id/employeeDetails`} component={AddModal} /> */}
                <Route exact
                  path={`${TEAM}/edit/:id/employeeDetails`}
                  render={(props) => (
                    <AddModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${TEAM}/edit/:id/careforum`}
                  render={(props) => (
                    <AddModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exacct path={`${TEAM}/edit/:id/skills`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/regualarShift`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/notes`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/files`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/clientTask`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/triage`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/average-hours`} component={AddModal} />
    
                <Route exact path={`${TEAM}/edit/:id/expenses`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/hr_task`} component={AddModal} />
                <Route exact path={`${TEAM}/edit/:id/care_notes`} component={AddModal} />
                <Route exact path={TEAM + TEAM_SKILL_MATRIX} component={TeamSkillMatrix} />
                <Route exact path={TEAM + TEAM_HR_TASK_MATRIX} component={HRTaskMatrix} />
                <Route exact path={TEAM + TEAM_COMPETENCY_MATRIX} component={TeamCompetencyMatrix} />
                {/* <Route exact path={CLIENT} component={(listOfClients || contextIsAdmin || contextIsSuperAdmin) ? Client : ''} /> */}
                <Route exact path={CLIENT}
                  // component={(listOfClients || contextIsAdmin || contextIsSuperAdmin) ? Client : ''}
                  render={(props) => (
                    (listOfClients || contextIsAdmin || contextIsSuperAdmin) ?
                      <Client {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                {/* <Route exact path={`${CLIENT}/add/personalDetails`} component={AddModalClient} /> */}
                <Route exact
                  path={`${CLIENT}/add/personalDetails`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${CLIENT}/add/clientPreferences`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/contacts`} component={AddModalClient} />
                <Route exact
                  path={`${CLIENT}/add/healthDetails`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                {/* <Route exact path={`${CLIENT}/add/healthDetails`} component={AddModalClient} /> */}
                <Route exact path={`${CLIENT}/add/serviceContract`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/notes`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/medicationDoses`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/files`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add${RISK_ASSESSMENT_ROUTE}`} component={AddModalClient} />

                <Route exact path={`${CLIENT}/add/careNotes`} component={AddModalClient} />
    
                <Route exact path={`${CLIENT}/add/clientTask`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/careforum`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/task`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/clientdiary`} component={AddModalClient} />
    
                <Route exact path={`${CLIENT}/add/visitCareNotes`} component={AddModalClient} />
    
    
                <Route exact path={`${CLIENT}/add/triage`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/add/clientvisits`} component={AddModalClient} />
    
    
                <Route exact path={`${CLIENT}/edit/:id/personalDetails`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${CLIENT}/edit/:id/clientPreferences`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/contacts`} component={AddModalClient} />
    
                {/* <Route exact path={`${CLIENT}/edit/:id/healthDetails`} component={AddModalClient} /> */}
                <Route exact
                  path={`${CLIENT}/edit/:id/healthDetails`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={`${CLIENT}/edit/:id/serviceContract`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/serviceContractAll`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/notes`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/careNotes`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/carePlans`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/bodyMap`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/expense`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/hrclienttask`} component={AddModalClient} />
    
                <Route exact
                  path={`${CLIENT}/edit/:id/visitCareNotes`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
    
                {/* <Route exact path={`${CLIENT}/edit/:id/visitCareNotes`} component={AddModalClient} /> */}
                <Route exact path={`${CLIENT}/edit/:id/medicationNotes`} component={AddModalClient} />
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/medicationDoses`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} handleChangeChoicesMap={handleChangeChoicesMap} />
                  )}
                />
                <Route exact path={`${CLIENT}/edit/:id/files`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id${RISK_ASSESSMENT_ROUTE}`} component={AddModalClient} />
                <Route exact path={`${CLIENT}/edit/:id/triage`} component={AddModalClient} />
    
                <Route exact path={`${CLIENT}/edit/:id/task`} component={AddModalClient} />
                {/* <Route exact path={`${CLIENT}/edit/:id/clientTask`} component={AddModalClient} handleChangeChoices={handleChangeChoices}/> */}
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/clientTask`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/clientdiary`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/clientvisits`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/careforum`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={`${CLIENT}/edit/:id/care-plans`}
                  render={(props) => (
                    <AddModalClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
              
                <Route exact path={CLIENT + CLIENT_HR_TASK_MATRIX} component={ClientHrTaskMatrix} />
                <Route exact path={`${CLIENT}/edit/:id${CLIENT_ABOUT_ME_ROUTE}`} component={AddModalClient} />
                
                <Route exact path={BRADFORD_ROUTE} component={Bradford} />
                <Route exact path={VISITS} component={(clientVisits || contextIsAdmin || contextIsSuperAdmin) ? ClientVisits : ''} />
                <Route exact path={`${REGULAR_VISIT}/add/run`} component={RegularVisitModalRun} />
                <Route exact path={HANDBACK_REQUEST} component={(readPermissionHandback || contextIsAdmin || contextIsSuperAdmin) ? HandBack : ""} />
                <Route exact path={SWAP_VISIT} component={(readPermissionHandback || contextIsAdmin || contextIsSuperAdmin) ? SwapVisit : ""} />
    
                <Route exact path={CANCELLED_VISITS} component={(cancelledVisits || contextIsAdmin || contextIsSuperAdmin) ? CancelledVisits : ''} />
                <Route exact path={OFFER_VISITS} component={(offerVisits || contextIsAdmin || contextIsSuperAdmin) ? OfferVisits : ''} />
                <Route exact
                  path={SCHEDULER}
                  render={(props) => (
                    (schedulerPage || contextIsAdmin || contextIsSuperAdmin) ?
                      <Scheduler {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route
                  exact
                  path={`${SCHEDULER}/planner/:id`}
                  render={(props) => (
                    <Planner {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={MEDICATION}
                  render={(props) => (
                    (schedulerPage || contextIsAdmin || contextIsSuperAdmin) ?
                      <MedicationCalendar {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route exact
                  path={HEAVY_PRINT}
                  render={()=><HeavyPrint />}
                />
                <Route exact path={ROTA_SHIFTS} component={(rotaShift || contextIsAdmin || contextIsSuperAdmin) ? RotaShifts : ''} />
                {/* <Route exact path={SCHEDULER} component={(schedulerPage || contextIsAdmin || contextIsSuperAdmin)?Scheduler:''} /> */}
                <Route exact path={SHIFT_PATTERNS} component={(shiftPattern || contextIsAdmin || contextIsSuperAdmin) ? ShiftPatterns : ''} />
                <Route path={FINANCE + BILLING} component={FinanceBilling} />
                <Route path={FINANCE + PAYROLL} component={FinancePayroll} />
                <Route exact
                  path={REGULAR_VISIT}
                  render={(props) => (
                    (regularVisit || contextIsAdmin || contextIsSuperAdmin) ?
                      <ClientPrePinning {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route
                  path={REPORT + REPORT_CLIENT}
                  render={(props) => (
                    <ReportClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
    
                <Route exact path={CANCELLED_VISITS} component={(cancelledVisits || contextIsAdmin || contextIsSuperAdmin) ? CancelledVisits : ''} />
                <Route exact path={OFFER_VISITS} component={(offerVisits || contextIsAdmin || contextIsSuperAdmin) ? OfferVisits : ''} />
    
                <Route exact
                  path={SCHEDULER}
                  render={(props) => (
                    (schedulerPage || contextIsAdmin || contextIsSuperAdmin) ?
                      <Scheduler {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route exact
                  path={MEDICATION}
                  render={(props) => (
                    (schedulerPage || contextIsAdmin || contextIsSuperAdmin) ?
                      <MedicationCalendar {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route exact path={ROTA_SHIFTS} component={(rotaShift || contextIsAdmin || contextIsSuperAdmin) ? RotaShifts : ''} />
                {/* <Route exact path={SCHEDULER} component={(schedulerPage || contextIsAdmin || contextIsSuperAdmin)?Scheduler:''} /> */}
                <Route exact path={SHIFT_PATTERNS} component={(shiftPattern || contextIsAdmin || contextIsSuperAdmin) ? ShiftPatterns : ''} />
                <Route exact path={VISIT_AUDIT} component={(shiftPattern || contextIsAdmin || contextIsSuperAdmin) ? VisitAudit : ''} />
    
                <Route path={FINANCE + BILLING} component={FinanceBilling} />
                <Route path={FINANCE + PAYROLL} component={FinancePayroll} />
                <Route exact
                  path={REGULAR_VISIT}
                  render={(props) => (
                    (regularVisit || contextIsAdmin || contextIsSuperAdmin) ?
                      <ClientPrePinning {...props} handleChangeChoices={handleChangeChoices} />
                      : ""
                  )}
                />
                <Route
                  path={REPORT + REPORT_CLIENT}
                  render={(props) => (
                    <ReportClient {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
    
                <Route
                  path={REPORT + REPORT_KPI}
                  render={(props) => (
                    <ReportKpi {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
    
                <Route
                  exact path={REPORT + REPORT_CLIENT + VISIT_CARE_NOTES}
                  render={(props) => (
                    <NewVisitCare {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
                <Route
                  exact path={REPORT + REPORT_CLIENT + CLIENT_TASKS}
                  render={(props) => (
                    <NewVisitCare {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
                <Route
                  exact path={REPORT + REPORT_CLIENT + REPORT_SERVICE_CONTRACT_ROUTE}
                  render={(props) => (
                    <ServiceContract {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
                <Route
                  path={REPORT + REPORT_TEAM}
                  render={(props) => (
                    <ReportTeam {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
                {/* <Route
              path={ALERTS_FEATURES}
              component={Alerts}
            /> */}
                <Route exact path={TRIAGE}
                  render={(props) => (
                    <Triage {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={INVOICE}
                  render={(props) => (
                    <Invoice {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
    
                <Route exact path={LINKED_USER}
                  render={(props) => (
                    <SettingLinkedUser {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact path={FORM_BUILDER}
                  render={(props) => (
                    <FormBuilderMainPage {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route path={FORM_BUILDER_ADD}
                  render={(props) => (
                    <Formbuilder {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route path={FORM_CATAGORY_ADD}
                  render={(props) => (
                    <Formbuilder {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={SETTINGS + SETTINGS_TEAM}
                  // component={teamSettings ? SettingsTeam : ''}
                  render={(props) => (
                    <SettingsTeam {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  exact
                  path={SETTINGS + HR_TASKS}
                  // component={teamSettings ? SettingsTeam : ''}
                  render={(props) => (
                    <TaskMatrix {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  path={SETTINGS + SETTINGS_CLIENT}
                  render={(props) => (
                    (clientSettings || contextIsAdmin || contextIsSuperAdmin) ?
                      <SettingsClient {...props} handleChangeChoices={handleChangeChoices} /> : ""
                  )}
    
                />
                <Route
                  path={SETTINGS + SETTINGS_CALENDAR}
                  component={SettingsCalendar}
                />
                <Route
                  path={SETTINGS + SETTINGS_FORM_BUILDER}
                  render={(props) => (
    
                    <SettingsFormBuilder {...props} handleChangeChoices={handleChangeChoices} />
                  )}
    
                />
                <Route
                  path={SETTINGS + SETTINGS_EVV}
                  component={SettingEvvList}
                />
    
                <Route path={SETTINGS + SETTINGS_ESV}
                  component={SettingsEsv} />
    
                <Route path={SETTINGS + SETTINGS_BRADFORD}
                  component={SettingsBradFord} />
                {/* <Route
              path={SETTINGS + SETTINGS_ALERT}
              component={SettingsAlert}
            /> */}
                <Route exact
                  path={SETTINGS + SETTINGS_EMAIL}
                  component={EmailHistory}
                // render={(props) => (<EmailHistory {...props} />)}
                />
                <Route
                  exact
                  path={SETTINGS + SETTINGS_TRIAGE}
                  render={(props) => (
                    <SettingsTriage {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route
                  path={SETTINGS + COMPANY}
                  render={(props) => (
                    <Settings {...props} handleChangeChoices={handleChangeChoices} />
    
                  )}
    
                />
                <Route
                  path={SETTINGS + SETTINGS_FINANCE}
                  component={SettingsFinance}
                />
                <Route
                  path={SETTINGS + SETTINGS_RATECARD}
                  component={SettingsRateCard}
                />
                <Route path={SETTINGS + USERS} component={SettingsUsers} />
                {/* <Route
              path={SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS}
              component={?SettingsRolesAndPermissions:''}
            /> */}
                <Route
                  path={SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS}
                  render={(props) => (
                    (contextIsAdmin || contextIsSuperAdmin || ((updateRolesAndPermissions || createRolesAndPermissions || deleteRolesAndPermissions || readRolesAndPermissions)))
                      ? <SettingsRolesAndPermissions {...props} handleChangePermissions={handleChangePermissions} /> : ''
                  )}
                />
                <Route
                  path={SETTINGS + SETTINGS_ORGANIZATIONS}
                  component={contextIsSuperAdmin ? SettingOrganization : ''}
                />
                <Route
                  path={SETTINGS + SETTINGS_SUBSCRIPTION}
                  component={contextIsSuperAdmin ? SettingSubscription : ''}
                />
                <Route
                  path={SETTINGS + SETTINGS_ALGOSCORE}
                  component={(algoScoreSettings || contextIsAdmin || contextIsSuperAdmin) ? SettingsAlgoScore : ''}
                />

                <Route
                  exact
                  path={SETTINGS + SETTINGS_LOGIN_ROUTE}
                  // component={teamSettings ? SettingsTeam : ''}
                  render={(props) => (
                    <SettingsLogin {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route path={EVV} component={(evvs || contextIsAdmin || contextIsSuperAdmin) ? Evv : ''} />
    
                <Route path={ESV} component={(esvs || contextIsAdmin || contextIsSuperAdmin) ? Esv : ''} />
    
                <Route exact path={TEAM_ROUTE_MAPPING} component={(teamRoute || contextIsAdmin || contextIsSuperAdmin) ? TeamRouteMapping : ''} />
    
                {/* <Route exact path={ABSENCE_HISTORY_LIST} component={AbsenceHistoryList} />   */}
                <Route exact path={'/absence-history-list'} component={(absenceList || contextIsAdmin || contextIsSuperAdmin) ? AbsenceHistoryList : ''} />
                <Route exact path={'/swap-shift'} component={(absenceList || contextIsAdmin || contextIsSuperAdmin) ? SwapShift : ''} />
    
                <Route exact path={'/absence-overview'} component={(absenceList || contextIsAdmin || contextIsSuperAdmin) ? AbsenceHistoryTab : ''} />
                {/* Location Routes */}
                <Route exact path={LOCATION} component={Location} />
                <Route exact
                  path={`${LOCATION}/add/location-details`}
                  render={(props) => (
                    <AddLocationModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${LOCATION}/edit/:id/location-details`}
                  render={(props) => (
                    <AddLocationModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${LOCATION}/add/contract`}
                  render={(props) => (
                    <AddLocationModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${LOCATION}/edit/:id/contract`}
                  render={(props) => (
                    <AddLocationModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${ACCOUNT_ROUTE + CHANGE_PASSWORD_ROUTE}`}
                  render={(props) => (
                    <AddAccountModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Route exact
                  path={`${ACCOUNT_ROUTE + DELETE_ACCOUNT_ROUTE}`}
                  render={(props) => (
                    <AddAccountModal {...props} handleChangeChoices={handleChangeChoices} />
                  )}
                />
                <Redirect
                  to={{
                    pathname: PAGE_404,
                    state: { referrer: '/' },
                  }}
                />
              </Switch>
            </BranchItemsContext.Provider>
          </DashBoard>
          {/* </SchedulerContainer> */}
        </>
      }
    </AppContext.Provider>
  );
}

export default withRouter(Router);