import React, { useEffect, useState, useRef, useContext } from 'react';
import Dropdown from 'components/SharedComponents/Dropdown';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { EVV, EVV_VISITS } from 'utils/constants/routes';
import { PrimaryButton } from 'shared/styles/buttons';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';

import {
  EX_SMALL_COL,
  EX_SMALL_COL4,
  LARGE_COL,
  SMALL_COL,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
} from 'shared/styles/constants/columns';
import { SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useLocation } from 'react-router-dom';
import {
  getAdmin,
  getSuperAdmin,
  getStaff,
} from '../../../../utils/localStorage/token';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { DOWNLOAD } from 'utils/constants/icons';
import { useHistory } from 'react-router-dom';
import { MdRemoveRedEye, MdPanoramaFishEye } from 'react-icons/md';
import { PURPLE, GRAY_300 } from 'shared/styles/constants/colors';
import NoSelectAllDropdownFilterTable from 'components/SharedComponents/NoSelectAllDropdownFilterTable';
import moment from 'moment';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  PublishButton,
} from 'shared/styles/constants/tagsStyles';
import { Row, Col, ColsGrouper } from 'components/SharedComponents/Table/styles';
import PropTypes from 'prop-types';
import { getEvvVisits, getEvvExport } from 'utils/api/EvvApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { AppContext } from 'context';
import { BranchItemsContext } from 'context';
import { useModal } from 'hooks/ModalHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import EvvBulkModal from './EvvBulkModal';
import InputNumber from 'components/SharedComponents/InputNumber'
import { getLocationList } from 'utils/api/SchedulerApi';

const columns_values = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    custum_column: true,
    removeCusCol: true,
    id: '',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: VERY_EX_SMALL_COL_CHECKBOX,
  },
  {
    label: 'Visit #',
    name: 'visit',
    status: 0,
    custum_column: true,
    id: 'Visit No.',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'Client Name',
    name: 'client__full_name',
    status: 1,
    custum_column: true,
    id: 'Client Name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Location Name',
    name: '',
    status: 1,
    custum_column: true,
    id: 'Location Name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Employee Name',
    name: 'employee__full_name',
    status: 0,
    custum_column: true,
    id: 'Employee Name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Lat Long',
    name: 'clientLatLong',
    status: 0,
    custum_column: true,
    id: 'Client Lat Long',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch start date',
    name: 'schedule_start_date',
    status: 0,
    custum_column: true,
    id: 'Scheduled Start Date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch end date',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'Scheduled End Date',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch start time',
    name: 'schedule_start_time',
    status: 1,
    custum_column: true,
    id: 'Scheduled Start Time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch end time',
    name: 'schedule_end_time',
    status: 0,
    custum_column: true,
    id: 'Scheduled End Time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Duration',
    name: 'duration',
    status: 0,
    custum_column: true,
    id: 'Scheduled duration',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock in',
    name: 'actual_start_time',
    status: 0,
    custum_column: true,
    id: 'Clocked Start Time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock out',
    name: 'actual_end_time',
    status: 0,
    custum_column: true,
    id: 'Clocked End Time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clocked Dur',
    name: 'actual_visit_duration',
    status: 0,
    custum_column: true,
    id: 'Clocked Duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock out Notes',
    name: 'clock_out_notes',
    status: 0,
    custum_column: true,
    id: 'clock_out_notes',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit status',
    name: 'visit_status',
    status: 0,
    custum_column: true,
    id: 'Visit Status',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Evv Visit Confrimation',
    name: 'evv_visit_confirmation',
    status: 0,
    custum_column: true,
    id: 'evv_visit_confirmation',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Clocked Visit Provision',
    name: 'clocked_visit_provision',
    status: 0,
    custum_column: true,
    id: 'Visit Provision',
    columnSelected: false,
    className: 'grow-mobile',
    custum_column: true,
    max_width: LARGE_COL,
  },
  {
    label: 'Time Distance Confirmation',
    name: 'time_distance_confirmation',
    status: 0,
    custum_column: true,
    id: 'Time Distance Confirmation',
    columnSelected: false,
    className: 'grow-mobile',
    custum_column: true,
    max_width: LARGE_COL,
  },
  {
    label: 'Scheduled & Clocked Duration Diff',
    name: 'sch_and_clock_duration',
    status: 0,
    custum_column: true,
    id: 'Schedule Durations vs Clocked Durations',
    columnSelected: false,
    className: 'grow-mobile',
    custum_column: true,
    max_width: LARGE_COL,
  },
  {
    label: 'EVV Comments',
    name: 'evv_comments',
    status: 0,
    custum_column: true,
    id: 'evv_comments',
    columnSelected: false,
    className: 'grow-mobile',
    custum_column: true,
    max_width: LARGE_COL,
  }
];

const EvvVisits = ({
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  choices,
  clients,
  employees,
  eventClickHandler,
  loadEvvs,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  runRouteData,
  branch,
  setFilterBranch,
  setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  choicesVisitStatus,
  setFiltersetVisitStatus,
  visitStatusFilter,
  visitStatus,
  setClientfilter,
  setEmloyeefilter,
  employeesFilter,
  clientsFilter,
  loadingModal,
  setEmployeeRunRoutefilter,
  employeeRunRoutesFilter,
  clearFilter,
  counts
}) => {
  const [visitProvisionFilter, setVisitProvisionFilter] = useState([]);
  const [visitConfirmationFilter, setVisitConfirmationFilter] = useState([]);
  const [timeDistanceFilter, setTimeDistanceFilter] = useState([]);

  const headerSelectedData = columns_values.map((val) => (val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(columns_values.filter((val) => val.columnSelected));

  const [value3, setValue3] = useState(25);

  //added  4 datas  of visit to dispalyed -usestate below
  const [numVisits, setNumVisits] = useState(0);
  const [sumVisitDuration, setSumVisitDuration] = useState(0);
  const [sumClockDuration, setSumClockDuration] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numOfLocations, setNumOfLocations] = useState(0);
  const [numEmployees, setNumEmployees] = useState(0);
  // const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadDisable, setDownloadDisable] = useState(false);
  const { contextChoices, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } =
    useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const history = useHistory();
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [bulkId, setBulkId] = useState([]);
  const mountedRef = useRef(true);
  const [pageTo, setPageTo] = useState();
  const [locations, setLocations] = useState([])
  let location = useLocation();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterStaff = contextIsStaff;
  const [obs, setObs] = useState();
  const [locationFilter, setLocationFilter] = useState([])
  // const [distanceList ,setDistanceList]={[{id:'V V V V',name:'V V V V'},{id:'V V V NV',name:'V V V NV'},{id:'V V NV V',name:'V V NV V'},{id:'V V NV NV',name:'V V NV NV'},{id:'V NV V V',name:'V NV V V'},{id:'V NV V V'},{id:'V NV NV V',name:'V NV NV V'},{id:'V NV NV NV',name:'V NV NV NV'},{id:'NV V V V',name:'NV V V V'},{id:'NV V V NV',name:'NV V V NV'},{id:'NV V NV V',name:'NV V NV V'},{id:'NV V NV NV',name:'NV V NV NV'},{id:'NV NV V V',name:'NV NV V V'},{id:'NV NV V NV',name:'NV NV V NV'},{id:'NV NV NV V',name:'NV NV NV V'},{id:'NV NV NV NV',name:'NV NV NV NV'}]}
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getListOfLocations = async () => {
    try {
      const res = await getLocationList()
      if (res?.status) {
        const data = res?.message?.map(item => ({ ...item, name: item.full_name }))
        setLocations(data || [])
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    clearFilter()
    // values.offset = 0;
    // values.limitperpage = PAGE_SIZE_RENDER;
    // setValues({ ...values });
    getListOfLocations()
  }, []);

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const loadData = () => {
    const obj_visits = {
      date_from: values.date_from,
      date_to: values.date_to,
      client_id: values.client_id,
      employee_id: values.employee_id,
      visit_status_id: values.visit_status,
      visit_priority_id: values.visit_priority_id,
      visit_no: values.visit_no,
      start_date: values.start_date,
      end_date: values.end_date,
      runroutes: values.runroutes,
      branch: values.branch,
      visitstatus: values.visitstatus,
    };
    setObs(obj_visits);
    const pageToSearch = getPageToSearchParam(location.search);
    setPageTo(pageToSearch);
    loadEvvs(
      { ...branchData, ...obj_visits, ...pageToSearch },
      EVV + EVV_VISITS,
      values.limitperpage
    );
  };


  useEffect(() => {
    if (location.search || values.ordering) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.ordering, values.limitperpage, location.search, branchData]);

  // useEffect(() => {
  //   const data = [];
  //   headerColumnsByDefault.map((item) => {
  //     data.push(item.id);
  //   })
  //   setrunTableOptionData(data)
  // }, [])

  // useEffect(() => {

  //   // displayData();

  //   const obj_visits = {
  //     date_from: values.date_from,
  //     date_to: values.date_to,
  //     client_id: values.client_id,
  //     employee_id: values.employee_id,
  //     visit_status_id: values.visit_status,
  //     visit_priority_id: values.visit_priority_id,
  //     visit_no: values.visit_no,
  //     start_date: values.start_date,
  //     end_date: values.end_date,
  //     runroutes: values.runroutes,
  //     branch: values.branch,
  //     visitstatus: values.visitstatus,
  //   };
  //   const pageToSearch = getPageToSearchParam(location.search);

  //   loadEvvs({ ...branchData, ...obj_visits, ...pageToSearch }, EVV + EVV_VISITS, values.limitperpage);

  // }, [location.search, values.ordering, branchData])

  const displayData = () => {
  
      setNumVisits(counts.no_of_visit > 0 ? Math.ceil(counts.no_of_visit) : 0);
      setSumVisitDuration(counts.visit_duration > 0 ?
        Math.round((counts.visit_duration + Number.EPSILON) * 100) / 100 : 0
      );
      setNumClients(counts.no_of_client > 0 ? Math.ceil(counts.no_of_client) : 0);
      setNumEmployees(counts.no_of_employee > 0 ? Math.ceil(counts.no_of_employee) : 0);
      setSumClockDuration( counts.clocked_duration > 0 ?
        Math.round((counts.clocked_duration + Number.EPSILON) * 100) / 100 : 0
      );
      setNumOfLocations(counts.no_of_locations > 0 ? Math.ceil(counts.no_of_locations) : 0)
  };
  
  useEffect(() => {
    displayData()
  }, [location.search, values.ordering, branchData, counts])

  const {
    setValue: setModalValue,
    value: ModalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);

  const handleDownload = () => {
    let fileName = 'Evv_' + values.date_from + '_' + values.date_to;
    setDownloadDisable(true);
    let column_data = runTableOptionData.filter(i => (i));
    getEvvExport(values.date_from, values.date_to, values, { filter_column: column_data })
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          setDownloadDisable(false);
        });
      })
      .catch(error => {
        setDownloadDisable(false);
      });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/evv/visits',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
  const handleCheck = visit => {
    let check = false;
    if (checkedRows) {
      checkedRows.map(item => {
        if (visit.id === item.id) {
          check = true;
        }
      });
    }
    return check;
  };
  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    setCheckedIdArr(visit.id);
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      checked.push(visit);
    } else {
      checked.map((item, index) => {
        if (item.id === visit.id) {
          checked.splice(index, 1);
        }
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.absence_ref));
    setBulkId(() => checked.map(item => item.id));
  };
  const handleAllSelectChange = valcheck => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      events.map(visit => {
        checked.push(visit);
      });
    } else {
      events.map(visit => {
        checked.map((item, index) => {
          if (item.id === visit.id) {
            checked.splice(index, 1);
          }
        });
      });
    }
    setCheckedRows(checked);
    setBulkId(() => checked.map(item => item.id));
    setCheckedId(() => checked.map(item => item.absence_ref));
  };
  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = id => {
      return checkedRows.some(function (el) {
        return el.id === id;
      });
    };
    if (checkedRows && checkedRows.length > 0) {
      events.map(visit => {
        if (!visitExists(visit.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }
    return checkAll;
  };
  const handleModals = () => {
    setModalValue(!ModalValue);
  };
  const setProvisionFilter = item => {
    setVisitProvisionFilter([...item]);
  };
  const setConfirmationFilter = item => {
    setVisitConfirmationFilter([...item]);
  };
  const setDistanceFilter = item => {
    setTimeDistanceFilter([...item]);
  };
  const handleSearch = () => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.set('page', 1);
    const newUrl = `${history.location.pathname}?${urlSearchParams.toString()}`;
    history.push(newUrl);
    displayData();
    loadData();
  };

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };

  const handleRangeChanges = (event) => {
    // debugger;
    const newMinValue = event.target.value ? parseInt(event.target.value) : null;
    //if (newMinValue >=0) {
    let currentValue = event.currentTarget.name

    switch (currentValue) {
      case 'clockedDurationDiff__gte':
        values.clockedDurationDiff__gte = newMinValue;
        setValues({ ...values })



        break;
      case 'clockedDurationDiff__lte':
        values.clockedDurationDiff__lte = newMinValue;
        setValues({ ...values })

        break;

      case 'clockedDurationPercent__gte':
        values.clockedDurationPercent__gte = newMinValue;
        setValues({ ...values })

        break;

      case 'clockedDurationPercent__lte':
        values.clockedDurationPercent__lte = newMinValue;
        setValues({ ...values })

        break;

      default:

        break;
      // }





      // values.partial = newMinValue
    }
  };

  const setLocationfilterFn = item => {
    setLocationFilter([...item]);
  };

  console.log('coloumn', runTableOptionData)
  console.log(values, "ooooo")
  const {displayMenu, selectedElId, encloseModalBind, clearSelectedElId} = useContextMenu()
  const evvVisitsMenu = [
    {
      name: "Evv Verification",
      onClick: handleModals
    }
  ]
  console.log(values,"values.visit_priority_id")
  return (
    <div>
      <ContextMenu menuItems={evvVisitsMenu} />
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup className={'d-flex mb-2'} style={{ flexWrap: 'wrap' }}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                {/* </FiltersLeftGroup>

              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}> */}
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={clients ? clients : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_id: items });
                    }}
                    itemsSelected={clientsFilter}
                    setSelectedFilter={setClientfilter}
                    placeHolder={'Client name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={locations ? locations : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ location: items });
                    }}
                    itemsSelected={locationFilter}
                    setSelectedFilter={setLocationfilterFn}
                    placeHolder={'Location name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_id: items });
                    }}
                    itemsSelected={employeesFilter}
                    setSelectedFilter={setEmloyeefilter}
                    placeHolder={'Employee name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                {/* </FiltersLeftGroup>

              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}> */}
                <DropdownTypesContainer className="ml-1 mr-2">
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Visit priority'}
                    options={
                      choices.visit_priority ? choices.visit_priority : []
                    }
                    selectedOption={values.visit_priority_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        visit_priority_id: value ? value : null,
                      })
                    }
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    typeFilter={true}
                    label={'Visit #'}
                    {...useInput('visit_no')}
                    onKeyPress={KeyPress.INTEGER}
                  />
                </DropdownTypesContainer>
                {/* </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}> */}
                <DropdownTypesContainer className="ml-1 mr-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client__clients_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Client Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__employee_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={employeeRunRoutesFilter}
                    setSelectedFilter={setEmployeeRunRoutefilter}
                    placeHolder={'Employee Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      { id: 'Under Provision', name: 'Under Provision' },
                      { id: 'Over Provision', name: 'Over Provision' },
                      { id: 'On Time Provision', name: 'On Time Provision' },
                      { id: 'Cancelled', name: 'Cancelled' }
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        clocked_visit_provision__in: items,
                      });
                    }}
                    itemsSelected={visitProvisionFilter}
                    setSelectedFilter={setProvisionFilter}
                    placeHolder={'Clocked Visit Provision'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      { id: 'CONFIRMED', name: 'CONFIRMED' },
                      { id: 'AUTO CONFIRMED', name: 'AUTO CONFIRMED' },
                      { id: 'NOT CONFIRMED', name: 'NOT CONFIRMED' },
                      { id: 'Cancelled', name: 'Cancelled' }
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        evv_confirmation_status__in: items,
                      });
                    }}
                    itemsSelected={visitConfirmationFilter}
                    setSelectedFilter={setConfirmationFilter}
                    placeHolder={'EVV Visit Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      { id: 'V V V V', name: 'V V V V' },
                      { id: 'V V V NV', name: 'V V V NV' },
                      { id: 'V V NV V', name: 'V V NV V' },
                      { id: 'V V NV NV', name: 'V V NV NV' },
                      { id: 'V NV V V', name: 'V NV V V' },
                      { id: 'V NV V V', name: 'V NV V V' },
                      { id: 'V NV NV V', name: 'V NV NV V' },
                      { id: 'V NV NV NV', name: 'V NV NV NV' },
                      { id: 'NV V V V', name: 'NV V V V' },
                      { id: 'NV V V NV', name: 'NV V V NV' },
                      { id: 'NV V NV V', name: 'NV V NV V' },
                      { id: 'NV V NV NV', name: 'NV V NV NV' },
                      { id: 'NV NV V V', name: 'NV NV V V' },
                      { id: 'NV NV V NV', name: 'NV NV V NV' },
                      { id: 'NV NV NV V', name: 'NV NV NV V' },
                      { id: 'NV NV NV NV', name: 'NV NV NV NV' },
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ timeDistance: items });
                    }}
                    itemsSelected={timeDistanceFilter}
                    setSelectedFilter={setDistanceFilter}
                    placeHolder={'Time Distance Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={visitStatus ? visitStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visitstatus: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={setFiltersetVisitStatus}
                    placeHolder={'Visit status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <div>
                  <label style={{ fontSize: '9px', marginLeft: '5px', marginBottom: 0, fontWeight: 500 }}>
                    Scheduled vs Clocked Duration (min)
                  </label>
                  <div className='d-flex'>
                    <InputNumber
                      name={'clockedDurationDiff__gte'}
                      id={'clockedDurationDiff__gte'}
                      type="number"
                      label={'Min'}

                      value={values.clockedDurationDiff__gte}
                      onChange={handleRangeChanges}
                    />

                    <InputNumber
                      name={'clockedDurationDiff__lte'}
                      id={'clockedDurationDiff__lte'}
                      type="number"
                      label={'Max'}

                      value={values.clockedDurationDiff__lte}
                      onChange={handleRangeChanges}
                    />
                  </div>
                </div>
                <div>
                  <label style={{ fontSize: '9px', marginLeft: '5px', marginBottom: 0, fontWeight: 500 }}>
                    Scheduled vs Clocked Duration (%)
                  </label>
                  <div className='d-flex'>
                    <InputNumber
                      name={'clockedDurationPercent__gte'}
                      id={'clockedDurationPercent__gte'}
                      type="number"
                      label={'Min'}

                      value={values.clockedDurationPercent__gte}
                      onChange={handleRangeChanges}
                    />

                    <InputNumber
                      name={'clockedDurationPercent__lte'}
                      id={'clockedDurationPercent__lte'}
                      type="number"
                      label={'Max'}

                      value={values.clockedDurationPercent__lte}
                      onChange={handleRangeChanges}
                    />
                  </div>
                </div>
                <PrimaryButton
                  type="button"
                  onClick={handleSearch}
                  style={{ marginLeft: '5px', marginTop: '25px' }}>
                  <span className={'ml-2 mr-2 font-weight-bold'}>
                    Search
                  </span>
                </PrimaryButton>
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      {/* visit data dispalyed below  */}
      <div>
        <DataContainer className=" ml-2 mt-2 mb-4 d-flex justify-content-start">
          <div className="d-flex align-items-center mr-2"></div>
          <div className="data-container">
            <div className="mr-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Visits: ' + numVisits}</p>
            </div>
          </div>

          <div className="d-flex align-items-center mr-2 pl-2"></div>
          <div className="data-container">
            <div className="mr-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Visit (hrs) : ' + sumVisitDuration}</p>
            </div>
          </div>

          <div className="d-flex align-items-center mr-2 pl-2"></div>
          <div className="data-container">
            <div className="mr-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Clocked (hrs) : ' + sumClockDuration}</p>
            </div>
          </div>

          <div className="d-flex align-items-center mr-2 pl-2"></div>
          <div className="data-container">
            <div className="mr-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Clients: ' + numClients}</p>
            </div>
          </div>

          <div className="data-container mr-2 ml-2">
            <div className="mr-2">
              <p>{'Locations: ' + numOfLocations}</p>
            </div>
          </div>

          <div className="d-flex align-items-center mr-2  pl-2"></div>
          <div className="data-container">
            <div className="mr-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Employees: ' + numEmployees}</p>
            </div>
          </div>
          <DropdownTypesContainer className="ml-1 mr-1">
            <NoSelectAllDropdownColumnSelector
              items={columns_values ? columns_values.filter((item) => !item.removeCusCol) : []}
              setItemsSelected={items => {
                dropDownHandleSelectTable({ tableDropdown: items });
              }}
              itemsSelected={dropdownArraySelected}
              setSelectedFilter={(item) => setDropdownArraySelected([...item])}
              placeHolder={'column'}
              isObject
              allOption

            />
          </DropdownTypesContainer>
          <div className=" ml-auto pl-2 mr-4">
            {checkedRows && checkedRows.length > 0 && (
              <div>
                {' '}
                <PrimaryButtonForm
                  minWidth="6rem"
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                // aria-expanded="false"
                >
                  <span className={'mr-2'}>Action</span>
                </PrimaryButtonForm>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      handleModals();
                    }}>
                    Evv Verification
                  </a>
                </div>
              </div>
            )}
          </div>

          <PublishButton
            disable={downloadDisable}
            onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
          </PublishButton>
        </DataContainer>
      </div>

      <div className={'mt-5'}>
        <>
          {' '}
          <ComponentDynamic loading={loadingModal}>
            {events.length > 0 && (
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <div style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                  <Table
                    headerColumns={columns_values}
                    headerPadding={true}
                    noNeed={false}
                    fontSize="0.75rem"
                    callBackQuerySort={setQuerySort}
                    handleAllCheck={handleAllCheck}
                    handleAllSelectChange={handleAllSelectChange}
                    values={runTableOptionData}
                  >
                    <div>
                      {events &&
                        events.map((evvVisit, index) => {
                          const dataOfSchedularDuration =
                            evvVisit.duration * 60;
                          const dataOfClockedDuration =
                            evvVisit.actual_clocked_duration * 60;
                          const schedularDuration = dataOfSchedularDuration
                            ? moment
                              .utc(
                                moment
                                  .duration(
                                    dataOfSchedularDuration,
                                    'seconds'
                                  )
                                  .asMilliseconds()
                              )
                              .format('HH:mm')
                            : ' ';

                          const clockedDuration = dataOfClockedDuration
                            ? moment
                              .utc(
                                moment
                                  .duration(
                                    dataOfClockedDuration,
                                    'seconds'
                                  )
                                  .asMilliseconds()
                              )
                              .format('HH:mm')
                            : ' ';

                          const dataOfActualDuration =
                            evvVisit.actual_visit_duration * 60;
                          // const actualDuration = dataOfActualDuration ? moment.utc(moment.duration(dataOfActualDuration, "seconds").asMilliseconds()).format("HH:mm")   : ' ';

                          // seconds = Number(dataOfActualDuration);
                          var d = Math.floor(
                            dataOfActualDuration / (3600 * 24)
                          );
                          var h = Math.floor(
                            (dataOfActualDuration % (3600 * 24)) / 3600
                          );
                          var m = Math.floor(
                            (dataOfActualDuration % 3600) / 60
                          );
                          // var s = Math.floor(dataOfActualDuration % 60);

                          var dDisplay = d > 0 ? d + (d == 1 ? 'd' : 'd') : '';
                          var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : '';
                          var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : '';
                          // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                          const actualDuration = dDisplay + hDisplay + mDisplay;

                          const actualStartTime =
                            evvVisit.actual_start_time?.split('T')[1]
                              ? moment(
                                evvVisit.actual_start_time?.split('T')[1],
                                'HH:mm:ss'
                              ).format('HH:mm')
                              : ' ';

                          const actualEndTime = evvVisit.actual_end_time?.split(
                            'T'
                          )[1]
                            ? moment(
                              evvVisit.actual_end_time?.split('T')[1],
                              'HH:mm:ss'
                            ).format('HH:mm')
                            : ' ';

                          const schedule_start_time =
                            evvVisit.schedule_start_time
                              ? moment(
                                evvVisit.schedule_start_time,
                                'HH:mm:ss'
                              ).format('HH:mm')
                              : ' ';

                          const schedule_end_time = evvVisit.schedule_end_time
                            ? moment(
                              evvVisit.schedule_end_time,
                              'HH:mm:ss'
                            ).format('HH:mm')
                            : ' ';

                          return (
                            <div  key={index} onContextMenu={(e)=>{
                              e.preventDefault();
                              displayMenu(e, evvVisit.id, {
                                x: 0, y:0
                              })
                            }}>
                              <Row className="mb-1" bgColor>
                                <ColsGrouper className="d-flex">
                                  <Col
                                    max_width={EX_SMALL_COL4}
                                    Shrink={false}
                                    NoFlexGrow={true}
                                    reducePadding
                                    Capitalize
                                    Center
                                  >
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={handleCheck(evvVisit)}
                                      id="flexCheckDefault"
                                      onChange={val => {
                                        handleSelectChange(
                                          val,
                                          evvVisit,
                                          index
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    max_width={SMALL_COL}
                                    Left
                                    className={!(runTableOptionData && runTableOptionData.includes("Visit No.")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit.visit_obj &&
                                      evvVisit.visit_obj.visit_number}
                                  </Col>


                                  <Col
                                    max_width={LARGE_COL}
                                    Left
                                    className={!(runTableOptionData && runTableOptionData.includes("Client Name")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit.client_obj &&
                                      evvVisit?.client_obj?.full_name}
                                  </Col>

                                  <Col
                                    max_width={LARGE_COL}
                                    Left
                                    className={!(runTableOptionData && runTableOptionData.includes("Location Name")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit.location_obj &&
                                      evvVisit.location_obj.full_name && evvVisit.location_obj.full_name}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Left
                                    className={!(runTableOptionData && runTableOptionData.includes("Employee Name")) && 'd-none'}
                                    overlap={true}>
                                    <div>
                                      {evvVisit.employee_obj &&
                                        evvVisit.employee_obj.full_name}
                                    </div>
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Left
                                    className={!(runTableOptionData && runTableOptionData.includes("Client Lat Long")) && 'd-none'}
                                    overlap={true}>
                                    <div>
                                      {evvVisit.clientLatLong}
                                    </div>
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Scheduled Start Date")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit.schedule_start_date}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Scheduled End Date")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit.schedule_end_date}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Scheduled Start Time")) && 'd-none'}
                                    overlap={true}>
                                    {schedule_start_time}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Scheduled End Time")) && 'd-none'}
                                    overlap={true}>
                                    {schedule_end_time}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Scheduled duration")) && 'd-none'}
                                    overlap={true}>
                                    {schedularDuration}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Clocked Start Time")) && 'd-none'}
                                    overlap={true}>
                                    {actualStartTime}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Clocked End Time")) && 'd-none'}
                                    overlap={true}>
                                    {actualEndTime}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Clocked Duration")) && 'd-none'}
                                    overlap={true}>
                                    {clockedDuration &&
                                      clockedDuration}
                                    {/* clock duration */}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("clock_out_notes")) && 'd-none'}
                                    overlap={true}>
                                    {evvVisit?.clock_out_notes?.length > 20 ? evvVisit?.clock_out_notes.slice(0, 20) + '...' : evvVisit?.clock_out_notes}
                                    {/* clock duration */}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Visit Status")) && 'd-none'}
                                    overlap={true}>
                                    {choicesVisitStatus?.find(
                                      w =>
                                        w.id ===
                                        evvVisit.visit_obj.visit_status
                                    ) &&
                                      choicesVisitStatus?.find(
                                        w =>
                                          w.id ===
                                          evvVisit.visit_obj.visit_status
                                      )?.name}
                                  </Col>

                                  <Col
                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("evv_visit_confirmation")) && 'd-none'}
                                    overlap={true}>
                                    {/* clock duration */}
                                    {evvVisit.evv_confirmation_status}
                                  </Col>
                                  <Col
                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Visit Provision")) && 'd-none'}
                                    overlap={true}>
                                    {/* clock duration */}
                                    {evvVisit.clocked_visit_provision}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Time Distance Confirmation")) && 'd-none'}
                                    overlap={true}>
                                    {/* clock duration */}
                                    {evvVisit.timeDistanceConfirmation}
                                  </Col>
                                  <Col

                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("Schedule Durations vs Clocked Durations")) && 'd-none'}
                                    overlap={true}>
                                    {/* clock duration */}
                                    {evvVisit.schedule_duration_clocked_duration_diff}
                                  </Col>
                                  <Col
                                    max_width={LARGE_COL}
                                    Center
                                    className={!(runTableOptionData && runTableOptionData.includes("evv_comments")) && 'd-none'}
                                    overlap={true}>
                                    {/* clock duration */}
                                    {evvVisit.evv_comments}
                                  </Col>
                                  <Col
                                    className="sm-hidden" Center Icon>
                                    {/* clock duration */}
                                    <MdRemoveRedEye
                                      onMouseEnter={'view'}
                                      size={'23'}
                                      color={PURPLE}
                                      onClick={() =>
                                        eventClickHandler(evvVisit)
                                      }
                                    />
                                  </Col>
                                </ColsGrouper>
                              </Row>
                            </div>
                          );
                        })}
                      <div className="mt-3">
                        <Pagination
                          totalPages={pages}
                          currentPage={currentPage}
                          values={values}
                          dropDownHandleSelect={dropDownHandleSelectPagination}
                          number={PAGE_SIZE_RENDER}
                          dataLength={events.length}
                        />
                      </div>
                    </div>
                    {!ModalValue ? null : (
                      <EvvBulkModal
                        setCheckedRows={setCheckedRows}
                        loadData={loadData}
                        bulkId={selectedElId?[selectedElId]:bulkId}
                        setTitle={setTitle}
                        fullBind={()=>encloseModalBind(fullBind)}
                        setModalValue={setModalValue}
                        clearSelectedElId={clearSelectedElId}
                      />
                    )}
                  </Table>
                </div>
              </div>
            )}
          </ComponentDynamic>
        </>
      </div>
    </div>
  );
};

EvvVisits.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvVisits;