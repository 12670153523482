import {
  EX_SMALL_COL,
    LARGE_COL,
    SMALL_COL,
    VERY_EX_SMALL_COL_CHECKBOX
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
//     {
//       label: 1,
//       checkbox: true,
//       name: 'checkbox',
//       status: 0,
//       custum_column: true,
//       removeCusCol: true,
//        id: 'checkbox',
//       columnSelected: true,
//       className: 'grow-mobile',
//       max_width: VERY_EX_SMALL_COL_CHECKBOX,
// },
{
  label: 'Branch Name',
  name: 'client__branch__name',
  status: 1,
  custum_column: true,
   id: 'branch_name',
  columnSelected: true,
  className: 'grow-mobile',
  max_width: LARGE_COL,
overlap: true
},
    {
      label: 'Client Name',
      name: 'client__full_name',
      status: 1,
      custum_column: true,
       id: 'client_name',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    overlap: true
    },
    {
      label: 'Employee Name',
      name: 'employee__full_name',
      status: 1,
      custum_column: true,
       id: 'employee_name',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Pay Adjustment Name',
      name: 'pay_adjustment__name',
      status: 1,
      custum_column: true,
       id: 'reimbursement',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    overlap: true
    },
      {
        label: 'Pay Adjustment Date',
        name: 'pay_adjustment_date',
        status: 1,
        custum_column: true,
         id: 'reimbursement_date',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Pay Adjustment Type',
        name: 'pay_adjustment_type__name',
        status: 1,
        custum_column: true,
        id: 'reimbursement_type',
          columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
        overlap: true
      },
      {
        label: 'Amount',
        name: 'amount',
        status: 1,
        custum_column: true,
         id: 'amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
  ];
  
  export const headerColumnsSelector = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
      name: 'Client Name',
      id: 'client_name',
    },
    {
      name: 'Employee Name',
      id: 'employee_name',
    },
    {
      name: 'Service Category',
      id: 'service_category',
    },
      {
        name: 'Start Date',
        id: 'start_date',
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
      name: 'Travel Mode',
      id: 'travel_mode',
    },
    {
      name: 'Postcode From',
      id: 'post_code_from',
    },
  {
    name: 'Postcode To',
    id: 'post_code_to',
  },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Travel Rate card',
      id: 'travel_rate_card',
    },
    {
      name: 'Sch Travel Distance (miles)',
      id: 'schedule_travel_distance_miles',
    },
    {
      name: 'Sch Mileage Rate',
      id: 'schedule_mileage_rate',
    },
    {
      name: 'Actual Travel Distance (miles)',
      id: 'actual_travel_distance_miles',
    },
    {
      name: 'Actual Mileage Rate',
      id: 'actual_mileage_rate',
    },
    {
      name: 'Sch Mileage Amt',
      id: 'schedule_mileage_amount',
    },
    {
      name: 'Actual Mileage Amt',
      id: 'actual_mileage_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'visit_bill_confirmation',
    },
    {
      name: 'Distance Bill Confirmation',
      id: 'distance_bill_confirmation',
    },
    
    
 
  ];
  export const headerColumnsSelectorByDefault = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
      name: 'Client Name',
      id: 'client_name',
    },
    {
      name: 'Employee Name',
      id: 'employee_name',
    },
    {
      name: 'Service Category',
      id: 'service_category',
    },
      {
        name: 'Start Date',
        id: 'start_date',
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
      name: 'Travel Mode',
      id: 'travel_mode',
    },
    {
      name: 'Postcode From',
      id: 'post_code_from',
    },
  {
    name: 'Postcode To',
    id: 'post_code_to',
  },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Travel Rate card',
      id: 'travel_rate_card',
    },
    {
      name: 'Sch Travel Distance (miles)',
      id: 'schedule_travel_distance_miles',
    },
    {
      name: 'Sch Mileage Rate',
      id: 'schedule_mileage_rate',
    },
    {
      name: 'Actual Travel Distance (miles)',
      id: 'actual_travel_distance_miles',
    },
    {
      name: 'Actual Mileage Rate',
      id: 'actual_mileage_rate',
    },
    {
      name: 'Sch Mileage Amt',
      id: 'schedule_mileage_amount',
    },
    {
      name: 'Actual Mileage Amt',
      id: 'actual_mileage_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'visit_bill_confirmation',
    },
    {
      name: 'Distance Bill Confirmation',
      id: 'distance_bill_confirmation',
    },
    
    
 
  ];