import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { HTML_NAMES } from '../constants'

export default function DropDownTableOptions({ json, multiple= false, minWidth = 400, contextChoices }) {
    // const [label, setLabel] = useState(json.dropdown.label)
    const [selected, setSelected] = useState(multiple && !Array.isArray(json.value) ? [] : json.value)
    const ddOptions = () => {
        if(!json) return [];
        if(json.manual_options)
            return json.manual_options.map(item=>{return {value: item.label, label: item.label}});
        else if (json.type !== HTML_NAMES.choices && json.options)
            return json.options.map(item=>{return {value: item.id, label: item.label}});
        else if (contextChoices && json.options && contextChoices[json.options])
            return contextChoices[json.options].map(item=>{return {value: item.id, label: item.name}});
        return []
    }
    return (
        <FormControl style={{ flex: 1 }} sx={{ minWidth: minWidth }}>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
             style={{height:'45px'}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selected}
                multiple={multiple}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                label={''}
                onChange={(event) => {
                    setSelected(event.target.value)
                }}
            >
                {
                    ddOptions().map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
