import { apiURL } from '../env';
import { get, post, deleteFetch } from './Api';
import { methods } from 'utils/constants/api';
import queryString from 'query-string';
import { PAGE_SIZE_RENDER } from '../constants/pagination';
import { getTenant, getToken } from '../localStorage/token';
import { getOrgname } from '../localStorage/user';
import { getSchemaName } from '../localStorage/token';
import { method } from 'lodash';

const { POST, PATCH, DELETE, PUT } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

export const getUsers = (values, pageSize) => {
  const baseUrl = getBaseUrl2();
  let organization_name = getSchemaName();
  let stringified = '';
  let toParse = {};
  let filterParse = {}

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  toParse.offset = values.offset;
  toParse.limit = pageSize;

  if (values.status !== undefined) {
    toParse.is_active = values.status;
  }
  if (values.role) {
    let ids = values.role.map(e => e.id)
    const idString = ids.join(",")
    filterParse.role = idString
  }

  if (values.type) {
    const ids = values.type.map(e => e.id);
    let idString = ids.join(",")
    filterParse.type = idString
  }

  stringified = queryString.stringify(toParse) || '';
  
  if (filterParse.role) {
    stringified += `&organization_user_role__role__id__in=${filterParse.role}`
  }
  if( filterParse.type){
    stringified += `&user_type__in=${filterParse.type}`
  }

  // const url = `${baseUrl}/users/?${stringified}`;

  const url = `${baseUrl}/users/?organization_name=${organization_name}&${stringified}`;
  return get(url);
};

export const getUser = id => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/users/${id}/`;
  return get(url);
};

export const getUsersTypes = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employment-type/`;
  return get(url);
};

export const patchUser = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/users/${values?.id || values}/`;
  const body = { ...values };

  if (values?.role_id || '') {
    body.role_id = values.role_id;
  }
  if (values.activeStatus) {
    delete body.id
    delete body.activeStatus
  }
  // if (values.isActive !== undefined) {
  //   body.is_active = values.isActive;
  // }

  // if (values.is_staff !== undefined) {
  //   body.is_staff = values.is_staff;
  // }
  delete body.is_staff
  return post(url, body, method);
};

export const resetUserPassword = email => {
  const baseUrl = getBaseUrl();
  const method = POST;

  const url = `${baseUrl}/users/forgot/`;

  const body = {
    email,
  };

  return post(url, body, method);
};
export const resetUserPin = email => {
  const baseUrl = getBaseUrl();
  const method = POST;

  const url = `${baseUrl}/users/mobilePasswordForgot/`;

  const body = {
    email,
  };

  return post(url, body, method);
};

export const deleteUser = id => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/users/${id}/`;
  const body = {
    is_deleted: true
  }
  return post(url, body, method);
};

export const getRoles = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/users/roles/?${stringified}`;
  return get(url);
};
export const getGroupTasks = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/group-task/?${stringified}`;
  return get(url);
};
export const getHistory = (values) => {

  let stringified = '';
  let filterParse = {};
  const baseUrl = getBaseUrl();

  if (values) {
    filterParse.values = values;
  }

  if (filterParse.values) {
    stringified += 'email=' + filterParse.values
  }
  // if (limitPerPage) {
  //   toParse.limit = limitPerPage;
  // }

  // stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/users/getRegisterDeviceData/?${stringified}`;

  return get(url);
};

export const getSubscription = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/common_core/subscription-type/?${stringified}`;
  return get(url);
};

export const deleteRole = async values => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/users/delete_role/?id=${values.id}`;

  return await deleteFetch(url)
};


export const deleteGroupTasks = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/group-task/${cond_id.id}/`;

  return deleteFetch(url);
};

export const deleteSubscription = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/common_core/subscription-type/${cond_id.id}/`;

  return deleteFetch(url);
};

export const setRole = values => {
  const method = values.role.id ? PATCH : POST;
  const baseUrl = getBaseUrl();
  const url = values.role.id
    ? `${baseUrl}/users/edit_role/`
    : `${baseUrl}/users/add_role/`;

  let roleValues = { ...values };
  if (values.role.id) {
    roleValues = {
      role_id: values.role.id,
      name: values.role.name,
      is_staff: values.role.is_staff,
    };
    if (values.role.user_type) {
      roleValues.user_type = values.role.user_type
    }
  }

  const body = {
    ...roleValues,
  };

  return post(url, body, method);
};

export const setGroupTasks = values => {

  console.log(values, "LLLLL")
  const baseUrl = getBaseUrl();
  const method = values.role.id ? PATCH : POST;
  const url = values.role.id
    ? `${baseUrl}/core/group-task/${values.role.id}/`
    : `${baseUrl}/core/group-task/`;

  const body =
  {
    name: values.role ? values.role.name : values.name
  }
    ;

  return post(url, body, method);
};

export const setSubscription = values => {
  const baseUrl = getBaseUrl();
  const method = values.role.id ? PATCH : POST;
  const url = values.role.id
    ? `${baseUrl}/common_core/subscription-type/${values.role.id}/`
    : `${baseUrl}/common_core/subscription-type/`;

  const body =
  {
    name: values.role.name,
    final_price: values.final_price
  }
    ;

  return post(url, body, method);
};


export const getSections = values => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/users/sections/`;
  return get(url);
};

export const getRolesAndPer = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/roles_and_permissions/roles-and-permission/get_roles/`;
  return get(url);
};

export const getRolesSub = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/roles_and_permissions/roles-and-permission/get_subscription_roles/`;
  return get(url);
};

export const getCareTask = () => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.limit = 1000;

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/care-task/?${stringified}`;

  return get(url);
};


export const getGroupTaskPermissions = Id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/group-permission-task/get_care_task_permission/?group=${Id}`;
  return get(url);
};

export const getSelectedPermissions = Id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/roles_and_permissions/roles-and-permission/get_roles_permission/?role_id=${Id}`;
  return get(url);
};

export const getSelectedSubscription = Id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/common_core/subscription-category/get_subscription_feature/?subscription_type=${Id}`;
  return get(url);
};

export const changeCategoryPermission = async values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/users/change_category_permission/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changeSectionPermission = async values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/users/change_section_permission/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changeSubsectionPermission = async values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/users/change_sub_section_permission/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changePermission = async (values, category_id) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/roles_and_permissions/roles-and-permission/roles_update/?category=${category_id}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changeGroupTaskPermission = async (values) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/group-permission-task/group_permission/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const changeOrdering = async (values) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/group-permission-task/care_task_permission_order/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changeOrderingDragDrop = async (values) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/group-permission-task/careTaskPermissionDragOrdering/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const changeSubscriptionPermission = async (values, cat_id) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/common_core/subscription-category/update_subscription/?subscription_type=${cat_id}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};


export const exportUsers = async (values) => {
  const baseUrl = getBaseUrl2();
  let organization_name = getSchemaName();
  let filterParse = {}
  let toParse = {};
  let stringified = '';
  if (values.status && values?.status) {
    toParse.is_active = values?.status;
  }
  if (values.role && values?.role) {
    let ids = values.role.map(e => e.id)
    const idString = ids.join(",")
    filterParse.role = idString
  }

  if (values.type && values?.type) {
    const ids = values.type.map(e => e.id);
    let idString = ids.join(",")
    filterParse.type = idString
  }
  stringified = queryString.stringify(toParse) || '';

  if (filterParse?.role) {
    stringified += `&organization_user_role__role__id__in=${filterParse.role}`
  }
  if (filterParse?.type) {
    stringified += `&user_type__in=${filterParse.type}`
  }
  stringified += `&organization_name=${organization_name}`
  stringified += `&limit=1000`
  stringified += `&offset=0`
  stringified += `&ordering=name`
  const url = `${baseUrl}/users/export_settings_users_details/?${stringified}`
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });
  return response;
}



export const resetLoginAattempts = id => {
  const baseUrl = getBaseUrl2();
  const method = PATCH
  const url = `${baseUrl}/users/${id}/reset_login_attempts/`;
  return post(url,{},method);;
};

export const resetPasswordExpiryDays = id => {
  const baseUrl = getBaseUrl2();
  const method = PATCH
  const url = `${baseUrl}/users/${id}/reset_password_expiry_date/`;
  return post(url,{},method);
};