import React, {useEffect, useState, useRef, useContext} from 'react';
import {useModal} from 'hooks/ModalHook';
import {useHistory} from 'react-router-dom';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {getAdmin, getSuperAdmin} from '../../../../utils/localStorage/token';
//import Funder from '../../Settings/SettingsClient/Funder'
import {
  getSubSectionPermissions,
  getStaff,
} from '../../../../utils/localStorage/user';
import {
  REPORT,
  CLIENT,
  CLIENT_DETAILS,
  PREFERRED_EMPLOYEES,
  HEALTH,
  ALLERGIES,
  AVOID_EMPLOYEES,
  SKILLS_TRAINING,
  COMPETENCIES,
  MEDICAL,
  IMPORTANT_CONTACTS,
  VISIT_CARE_NOTES,
  ROTA_EMAIL,
  CLIENT_KPI_DETAILS,
  CLIENT_WEAKLY_DETAILS,
  KPI,
  CLIENT_TASKS,
  REPORT_SERVICE_CONTRACT_ROUTE,
  REPORT_CLIENT,
} from 'utils/constants/routes';
import {
  ContainerTabStyled,
  Title,
  LinkTabStyled,
  LinkTab,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  REPORT_CLIENT_PD,
  REPORT_CLIENT_PREFERRED_EMPLOYEE,
  REPORT_CLIENT_AVOID_EMPLOYEE,
  REPORT_CLIENT_COMPETENCY,
  REPORT_CLIENT_SKILLS,
  REPORT_CLIENT_HEALTH,
  REPORT_CLIENT_MEDICAL,
  REPORT_CLIENT_ALLERGIES,
  REPORT_CLIENT_CARE_CONTACT,
  REPORT_CLIENT_ROTA_EMAIL,
  REPORT_VISIT_CARE_CONTACT,
  REPORT_TASKS,
  REPORT_CLIENT_TASK,
  REPORT_SERVICE_CONTRACT,
  REPORT_CLIENT_SERVICE_CONTRACT
} from '../../../../utils/constants/permissions';
import ClientFilters from './ClientFilters';
import _, {get} from 'lodash';
import {AppContext} from 'context';
import ClientRota from './ClientRota';
import NewVisitCare from './ClientFilters/NewVisitCare';
import DailyWeaklyFilter from './ClientFilters/DailyWeaklyFiter';

const TAB_CLIENT_DETAILS_URL = REPORT + CLIENT + CLIENT_DETAILS;

const TAB_HEALTH_URL = REPORT + CLIENT + HEALTH;
const TAB_ALLERGIES_URL = REPORT + CLIENT + ALLERGIES;
const TAB_PREFERRED_EMPLOYEES_URL = REPORT + CLIENT + PREFERRED_EMPLOYEES;

const TAB_AVOID_EMPLOYEES_URL = REPORT +CLIENT + AVOID_EMPLOYEES;
const TAB_MEDICAL_URL = REPORT + CLIENT + MEDICAL;
const TAB_PREFERRED_SKILLS_URL = REPORT + CLIENT + SKILLS_TRAINING;
const TAB_PREFERRED_COMPETENCIES_URL = REPORT + CLIENT + COMPETENCIES;
const TAB_IMPORTANT_CONTACTS_URL = REPORT + CLIENT + IMPORTANT_CONTACTS;

const TAB_VISIT_CARE_NOTES_URL = REPORT + CLIENT + VISIT_CARE_NOTES;
const TAB_ROTA_EMAIL_URL = REPORT + CLIENT + ROTA_EMAIL;
const TAB_CLIENT_TASKS_URL = REPORT + CLIENT + CLIENT_TASKS
const TAB_SERVICE_CONTRACT = REPORT + REPORT_CLIENT + REPORT_SERVICE_CONTRACT_ROUTE
const tabTeamArray = [
  {
    label: 'Client Details',
    route: TAB_CLIENT_DETAILS_URL,
    name: 'Personal_Details',
    permission: REPORT_CLIENT_PD,
  },

  {
    label: 'Preferred Employees',
    route: TAB_PREFERRED_EMPLOYEES_URL,
    name: 'Client_Preferred_Employee',
    permission: REPORT_CLIENT_PREFERRED_EMPLOYEE,
  },
  {
    label: 'Preferred Skills',
    route: TAB_PREFERRED_SKILLS_URL,
    name: 'Client_Skills',
    permission: REPORT_CLIENT_SKILLS,
  },
  {
    label: 'Preferred Competencies',
    route: TAB_PREFERRED_COMPETENCIES_URL,
    name: 'Client_Competency',
    permission: REPORT_CLIENT_COMPETENCY,
  },
  {
    label: 'Avoid Employees',
    route: TAB_AVOID_EMPLOYEES_URL,
    name: 'Client_Avoid_Employee',
    permission: REPORT_CLIENT_AVOID_EMPLOYEE,
  },
  {
    label: 'Health',
    route: TAB_HEALTH_URL,
    name: 'Client_Health',
    permission: REPORT_CLIENT_HEALTH,
  },
  {
    label: 'Medical',
    route: TAB_MEDICAL_URL,
    name: 'Client_Medical',
    permission: REPORT_CLIENT_MEDICAL,
  },
  {
    label: 'Allergies',
    route: TAB_ALLERGIES_URL,
    name: 'Client_Allergies',
    permission: REPORT_CLIENT_ALLERGIES,
  },
  {
    label: 'Important Contacts',
    route: TAB_IMPORTANT_CONTACTS_URL,
    name: 'Client_Care_Contact',
    permission: REPORT_CLIENT_CARE_CONTACT,
  },
  {
    label: 'Visit Care Notes',
    route: TAB_VISIT_CARE_NOTES_URL,
    name: 'Client_Care_Notes',
    permission: REPORT_VISIT_CARE_CONTACT,
  },
  {
    label: 'Tasks',
    route: TAB_CLIENT_TASKS_URL,
    name: 'ClientTaskReport',
    permission: REPORT_CLIENT_TASK,
  },
  {
    label: 'Service Contract',
    route: TAB_SERVICE_CONTRACT,
    name: 'ServiceContract',
    // permission: REPORT_SERVICE_CONTRACT,
    permission: REPORT_CLIENT_SERVICE_CONTRACT
  },
];

const ReportClient = props => {
  const [reportClientArray, setReportClientArray] = useState(tabTeamArray);
  const [teamTabs, setTeamTabs] = useState('client');
  const [clientRotaPermission, setClientRotaPermission] = useState(false);
  const [updateFunderSettings, setUpdatePermissionsFunderSettings] =
    useState(false);
  const [createFunderSettings, setCreatePermissionsFunderSettings] =
    useState(false);
  const [deletePermissionFunderSettings, setDeletePermissionsFunderSettings] =
    useState(false);
  const [readPermissionFunderSettings, setReadPermissionsFunderSettings] =
    useState(false);
  const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] =
    useState(false);
  const [createPrioritySettings, setCreatePermissionsPrioritySettings] =
    useState(false);
  const [
    deletePermissionPrioritySettings,
    setDeletePermissionsPrioritySettings,
  ] = useState(false);
  const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] =
    useState(false);
  const {
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  const [newData, setNewData] = useState('');
  let history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const userStaff = contextIsStaff;
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  const reviewTab = tab => {
    return tab === teamTabs;
  };
  useEffect(() => {
    let rolePermissions = {};
    const rolesPermissions = contextSubSectionPermissions;

    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    console.log(rolesPermissions, '552144');
    if (contextIsAdmin || contextIsSuperAdmin) {
      setReportClientArray(tabTeamArray);
      setClientRotaPermission(true);
    } else {
      if (!_.isEmpty(rolePermissions)) {
        let values1 = reportClientArray.filter(
          item =>
            rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_READ_PERMISSION
            ] && item
        );
        setClientRotaPermission(
          rolePermissions[REPORT_CLIENT_ROTA_EMAIL]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]
        );
        setReportClientArray(values1);
      }
    }
  }, [contextIsSuperAdmin, contextIsAdmin]);

  const tabClick = tab => {
    setNewData(tab);

    setTeamTabs(tab);
    if (tab === 'client') {
      history.push(TAB_CLIENT_DETAILS_URL);
    } else {
      history.push(REPORT + CLIENT);
    }
  };
console.log(teamTabs,"teamTabs")
  return (
    <div>
      <Title>Report Client</Title>
      <div className={'d-flex w-100 text-center mb-4'}>
        <LinkTabStyled active={reviewTab('client')}>
          <div onClick={() => tabClick('client')} className={'styledLink'}>
            <LinkTab active={reviewTab('client')}>Client</LinkTab>
          </div>
        </LinkTabStyled>
        {clientRotaPermission && (
          <LinkTabStyled active={reviewTab('client_email')}>
            <div
              onClick={() => tabClick('client_email')}
              className={'styledLink'}>
              <LinkTab active={reviewTab('client_email')}>
                Client Emails
              </LinkTab>
            </div>
          </LinkTabStyled>
        )}
   
      </div>
      <>
        {teamTabs === 'client' && (
          <div className="p-2 p-sm-3 p-md-4">
            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex w-100'}>
                {reportClientArray.map(item => {
                  return (
                    <LinkTabStyled active={reviewLocation(item.route)}>
                      <Link to={item.route} className={'styledLink'}>
                        <LinkTab active={reviewLocation(item.route)}>
                          {item.label}
                        </LinkTab>
                      </Link>
                    </LinkTabStyled>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
        )}
        <Switch>
          {teamTabs === 'client' &&
            reportClientArray.map(item => {
              return (
                <Route exact path={item.route}>
                  <ClientFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                  />
                </Route>
              );
            })}
        </Switch>

       
        {teamTabs === 'client_email' && (
          <ClientRota
            location={props.location}
            updateFunderSettings={updateFunderSettings}
            createFunderSettings={createFunderSettings}
            deletePermissionFunderSettings={deletePermissionFunderSettings}
            readPermissionFunderSettings={readPermissionFunderSettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
          />
        )}
      </>

      
      
    </div>
  );
};

export default ReportClient;
