import React, { useState, useEffect, Fragment } from 'react';
import DurationInput from 'components/SharedComponents/DurationInput';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import LineContainer from 'components/SharedComponents/LineContainer';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InvalidRange } from 'utils/constants/messages';
import {
    IconWrapper,
} from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    WeekContainer,
    WeekStyle,
    ButtonWeek,
    ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import { KeyPress } from 'utils/constants/regularExpressions';
import { ContainerResponsive } from '../../../DashboardComponents/Team/AddModal/styles';
import { getPayRates } from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
const Shift = ({
    useInput,
    nameSection,
    setValues,
    values,
    choices,
    requireDayOfWeek,
    shiftIndex,
    deleteShift, handleDropAbsenceType, idSelected, readOnly = false,
    setTimeError = () => { },
    timeError = [],
}) => {


    const [TravelData, setTravelData] = useState([])
    const [filterShiftType, setFilterShiftType] = useState([])
    const [travelRate, setTravelRate] = useState([]);
    const [shiftTypeName, setShiftTypeName] = useState('');
    const [shiftType, setShiftType] = useState([]);
    const [absence, setAbsence] = useState('')
    const [absenceType, setAbsenceType] = useState([]);

    const [isOverNight, setIsOverNight] = useState(false);

    const [payRate, setPayRate] = useState([])

    const [timeSelected, setTimeSelected] = useState('');
    const [duration, setDurationValue] = useState('');
    const [isFullDay, setIsFullDay] = useState(false);
    const [timeErrorMessage, setTimeErrorMessage] = useState('')
    const start_time_value = values[nameSection][shiftIndex].start;
    const end_time_value = values[nameSection][shiftIndex].end;
    const shift_type_value=values[nameSection][shiftIndex].shift_type




    useEffect(() => {
        getPayRates()
            .then(res => {
                setTravelData(res || [])
                setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates') || []);
            })
    }, [])
    useEffect(() => {
        getPayRates()
            .then(res => {

                setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
            })
    }, [])

    useEffect(() => {
        if (duration && typeof duration === 'string') {
            const res = duration
                .split(':')
                .reduce((acc, time) => 60 * acc + +time);

            setDurationValue(res);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);


    useEffect(() => {
        if (choices)
            setShiftType(choices.regular_shift_type);
    }, [choices])

    useEffect(() => {
        if (choices.absence_type && choices.absence_reason && values.absence_type) {
            let arr = choices.absence_reason.filter(val => val.absence_type === values.absence_type);
            setAbsenceType(arr);
        }
    }, [values.absence_type, choices])

    useEffect(() => {
        const reviewErrors = timeError;
        if (values[nameSection][shiftIndex].is_overnight) {
            const start_time = moment(start_time_value, 'h:mma')
            const end_time = moment(end_time_value, 'h:mma')
            if (!end_time.isBefore(start_time)) {
                reviewErrors[shiftIndex] = true;
                setTimeError([...reviewErrors]);
                setTimeErrorMessage(InvalidRange);
            } else {
                reviewErrors[shiftIndex] = false;
                setTimeError([...reviewErrors]);
                setTimeErrorMessage('');
            }
        } else {
            const start_time = moment(start_time_value, 'h:mma')
            const end_time = moment(end_time_value, 'h:mma')
            if (end_time.isBefore(start_time)) {
                reviewErrors[shiftIndex] = true;
                setTimeError([...reviewErrors]);
                setTimeErrorMessage(InvalidRange);
            } else {
                reviewErrors[shiftIndex] = false;
                setTimeError([...reviewErrors]);
                setTimeErrorMessage('');
            }
        }

    }, [start_time_value, end_time_value, values[nameSection][shiftIndex].is_overnight]);

    useEffect(() => {
        if (values[nameSection][shiftIndex].details && values[nameSection][shiftIndex].details.length >= 1) {
            values[nameSection][shiftIndex].details.forEach((detail, indexDetail) => {
                values[nameSection][shiftIndex].daysofWeek.forEach((dayWeek, indexWeek) => {
                    if (dayWeek.dayValue === detail.day_of_week) {
                        values[nameSection][shiftIndex].daysofWeek[indexWeek].selected = true;
                        values[nameSection][shiftIndex].details[indexDetail].selected = true;
                    }
                });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.details]);
    useEffect(() => {
        if (values[nameSection][shiftIndex].start && values[nameSection][shiftIndex].end) {
            let start = moment(values[nameSection][shiftIndex].start, 'HH:mm:ss a');
            let end = moment(values[nameSection][shiftIndex].end, 'HH:mm:ss a');
            if (end > start) {
                setDurationValue(end.diff(start, 'seconds'));

            } else {
                let add_date = end.add(1, 'days')
                setDurationValue(add_date.diff(start, 'seconds'));
            }

        } else {
            setDurationValue(0);
        }
        setValues({ ...values });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start_time_value, end_time_value]);
    const hourFormat = (num) => {
        var hours = Math.floor(num / 3600);
        var minutes = Math.floor((num - (hours * 3600)) / 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        return hours + ':' + minutes;
    }

    // useEffect(() => {
    //     if (values[nameSection][shiftIndex].start && values[nameSection][shiftIndex].end) {
    //         let start = moment(values[nameSection][shiftIndex].break_start_time, 'HH:mm:ss a');
    //         let end = moment(values[nameSection][shiftIndex].break_end_time, 'HH:mm:ss a');
    //         if (end > start) {
    //             setDurationValue(end.diff(start, 'seconds'));

    //         }

    //         else {
    //             let add_date = end.add(1, 'days')
    //             setDurationValue(add_date.diff(start, 'seconds'));
    //         }

    //     } else {
    //         setDurationValue(0);
    //     }
    //     setValues({ ...values });

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [values[nameSection][shiftIndex].break_start_time, values[nameSection][shiftIndex].break_end_time]);

    // useEffect(() => {

    //     values[nameSection][shiftIndex].is_full_day = false;
    //     values[nameSection][shiftIndex].is_overnight = false;
    //     values[nameSection][shiftIndex].break_start_time = null;
    //     values[nameSection][shiftIndex].break_end_time = null;
    //     values[nameSection][shiftIndex].repeat_on = ' ';
        
    //     setValues({ ...values });
    // }, [])


    const handleClick = timeSelected => {
        setTimeSelected(timeSelected);
    };

    const handelFullDay = () => {
        setIsFullDay(prevState => !prevState);
        if (!isFullDay) {
            values[nameSection][shiftIndex].start_time = '00:00';
            values[nameSection][shiftIndex].end_time = '23:59:59';
            values[nameSection][shiftIndex].is_overnight = false;
            setIsOverNight(false);
        }
        values[nameSection][shiftIndex].is_full_day = !isFullDay;
        setValues({ ...values });
    };

    const handelOverNight = () => {
        setIsOverNight(prevState => !prevState);
        if (!isOverNight) {
            values[nameSection][shiftIndex].is_full_day = false;
            setIsFullDay(false)
        }
        values[nameSection][shiftIndex].is_overnight = !isOverNight;
        setValues({ ...values });
    }

    const handleDropDown = (item, nameItem) => {
        values[nameSection][shiftIndex][nameItem] = item[nameItem];
        setValues({ ...values });

    };
    const dropDownHandleRate = (item, nameSection, index, list_key) => {
        values[nameSection][index][list_key] = item.rate;
        if (list_key == 'rate_card') {
            values[nameSection][index].over_time_rate = payRate.find(rateid => rateid.id == item.rate).over_time_rate;
            values[nameSection][index].rate_per_hour = payRate.find(rateid => rateid.id == item.rate).rate_per_hour;
        }
        setValues({ ...values })
    }
    const handleDropDownS = (item, name, nameItem) => {
        if (nameItem === 'shift_type') {
            values[nameSection][shiftIndex].regular_shift_pay_rate_id =""

        }
        values[nameSection][shiftIndex][nameItem] = item[nameItem];
        setValues({ ...values });
        setShiftTypeName(name);
    };
    const selectedDay = (day, indexWeek) => {
        values[nameSection][shiftIndex].daysofWeek[indexWeek].selected = !values[nameSection][shiftIndex].daysofWeek[indexWeek].selected;

        // setDaysOfWeek(daysOfWeek);
        setValues({ ...values })
        if (!values[nameSection][shiftIndex].details) {
            values[nameSection][shiftIndex].details = [];
        }

        values[nameSection][shiftIndex].details.forEach(detail => {
            if (detail.day_of_week - 1 === indexWeek) {
                detail.selected = values[nameSection][shiftIndex].daysofWeek[indexWeek].selected;
            }
        });

        const addNew = values[nameSection][shiftIndex].details.find(
            detail => detail.day_of_week === values[nameSection][shiftIndex].daysofWeek[indexWeek].dayValue
        );

        if (!addNew) {
            values[nameSection][shiftIndex].details.push({
                start: values.shiftValues[shiftIndex].start,
                end: values.shiftValues[shiftIndex].end,
                shift_type:values.shiftValues[shiftIndex].shift_type,
                travel_mode:values.shiftValues[shiftIndex].travel_mode,
                travel_time:values.shiftValues[shiftIndex].travel_time,
                shift_bill_rate:values.shiftValues[shiftIndex].shift_bill_rate,
                pay_rate_card:values.shiftValues[shiftIndex].pay_rate_card,

                selected: true,
                day_of_week: values[nameSection][shiftIndex].daysofWeek[indexWeek].dayValue,
                is_available: values.shiftValues.is_available
                    ? values.shiftValues.is_available
                    : false,
                id: '',

            });
        }

        setValues({ ...values });

    };


    const getPayRateOptions = (option) => {
        let arr = TravelData.filter(data => data.rate_card_type == option) || [];
        return arr;
    }

    const handleAbsenceType = (value, label) => {
        setAbsence(label);
        handleDropAbsenceType({ absence_type: value ? value : null })
    }


    const handleTravelTime = (e) => {
        values[nameSection][shiftIndex].travel_time = moment(e.target.value, "h:mm:ss A").format("HH:mm")
        setValues({ ...values })
    }

    // const handelFullDay = () => {
    // setIsFullDay(prevState => !prevState);
    // };
    const handleNotes = (e) => {
        values[nameSection][shiftIndex].absence_notes = e.target.value;
        setValues({ ...values });
    }
    const switchTravelModeValue = props => {
        let shift_type = shiftType && shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type) &&
            shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type).name
        switch (shift_type) {
            case 'Shift':
            case 'Availability':
            case 'Bank-Flexible':
                return (
                    <>
                        <div className='w-100 mr-2'>
                            <DropdownSearchable
                                required
                                {...props}
                                placeHolder={'Travel Mode'}
                                options={choices.travel_type}
                                selectedOption={
                                    values[nameSection][shiftIndex].travel_mode
                                        ? values[nameSection][shiftIndex].travel_mode
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { travel_mode: value ? value : null },
                                        'travel_mode'
                                    )
                                }
                            />
                        </div>
                        <div className='w-100'>
                            <TimeSelector
                                id="travel_time"
                                timeFormat={"HH:mm"}
                                dateFormat={"HH:mm"}
                                placeholderText={'hh:mm'}
                                locale={"sv-sv"}
                                timeCaption={'Duration'}
                                labelTop={' Extra Travel Time'}
                                value={values[nameSection][shiftIndex].travel_time}
                                onChange={handleTravelTime}
                            // {...useInput(`regularShifts.shifts[${shiftIndex}].travel_time`)}
                            />
                        </div>
                    </>
                );
            case 'Office - Training':
            case 'Office - Meetings':
            case 'Office - Administration':
            case 'Office - Supervision':
                return (
                    <>
                        <div className='w-100 mr-2'>
                            <DropdownSearchable
                                {...props}
                                placeHolder={'Travel Mode'}
                                options={choices.travel_type}
                                selectedOption={
                                    values[nameSection][shiftIndex].travel_mode
                                        ? values[nameSection][shiftIndex].travel_mode
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { travel_mode: value ? value : null },
                                        'travel_mode'
                                    )
                                }
                            />
                        </div>
                        <div className='w-100'
                        >
                            <TimeSelector
                                id="extra_travel_time"
                                timeFormat={"HH:mm"}
                                dateFormat={"HH:mm"}
                                placeholderText={'hh:mm'}
                                locale={"sv-sv"}
                                timeCaption={'Duration'}
                                labelTop={'Extra Travel Time'}
                                value={values[nameSection][shiftIndex].travel_time}
                                onChange={handleTravelTime}
                            // {...useInput(`regularShifts.shifts[${shiftIndex}].travel_time`)}
                            />
                        </div>
                    </>
                );
            case 'Unavailability':
                return <Fragment />;
            case 'Absence':
                return <Fragment />;
            default:
                return <Fragment />;
        }
    };
    const switchPayRateValue = props => {
        let shift_type = shiftType && shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type) &&
            shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type).name
        switch (shift_type) {
            case 'Shift':
                return <Fragment />;
            case 'Availability':
                return <Fragment />;
            case 'Bank-Flexible':
                return <Fragment />;
            case 'Office - Training':
            case 'Office - Meetings':
            case 'Office - Administration':
            case 'Office - Supervision':
                return (
                    <>
                        <FlexGrid3Item
                            className="pl-0 pr-1"
                        >
                            <DropdownSearchable
                                {...props}
                                placeHolder={'travel rate card'}
                                options={getPayRateOptions('Office')}
                                selectedOption={
                                    values[nameSection][shiftIndex].shift_bill_rate
                                        ? values[nameSection][shiftIndex].shift_bill_rate
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { shift_bill_rate: value ? value : null },
                                        'shift_bill_rate'
                                    )
                                }
                            />
                        </FlexGrid3Item>
                    </>
                );
            case 'Unavailability':
                return <Fragment />;
            case 'On Call':
                return (
                    <>
                        <FlexGrid3Item
                            className="pl-0 pr-1"
                        >
                            <DropdownSearchable
                                required
                                {...props}
                                placeHolder={'travel rate card'}
                                options={getPayRateOptions('On Call')}
                                selectedOption={
                                    values[nameSection][shiftIndex].shift_bill_rate
                                        ? values[nameSection][shiftIndex].shift_bill_rate
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { shift_bill_rate: value ? value : null },
                                        'shift_bill_rate'
                                    )
                                }
                            />
                        </FlexGrid3Item>

                    </>
                );
            case 'Absence':
                return (
                    <>
                        <FlexGrid3Item
                            className="pl-0 pr-1"
                        >
                            <DropdownSearchable
                                required
                                {...props}
                                placeHolder={'travel rate card'}
                                options={getPayRateOptions('Absence')}
                                selectedOption={
                                    values[nameSection][shiftIndex].shift_bill_rate
                                        ? values[nameSection][shiftIndex].shift_bill_rate
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { shift_bill_rate: value ? value : null },
                                        'shift_bill_rate'
                                    )
                                }
                            />
                        </FlexGrid3Item>

                    </>
                );
            default:
                return <Fragment />;
        }
    };

    const switchAbsenceValue = props => {
        let shift_type = shiftType && shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type) &&
            shiftType.find(shift => shift.id === values[nameSection][shiftIndex].shift_type).name
        switch (shift_type) {
            case 'Absence':
                return (
                    <>
                        <div>
                            <FlexGrid3Container className="mt-2">
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable

                                        disabled={readOnly}
                                        placeHolder={'Absence Type'}
                                        options={choices ? choices.absence_type : []}
                                        selectedOptionsProps={values.absence_type}
                                        selectedOption={values.absence_type}
                                        onChange={(value, label) =>
                                            handleAbsenceType(value, label)
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable

                                        disabled={readOnly || !values.absence_type}
                                        placeHolder={'Absence Reason'}
                                        options={absenceType ? absenceType : []}
                                        selectedOptionsProps={values.absence_reason}
                                        selectedOption={values.absence_reason}
                                        onChange={value =>
                                            handleDropDown({ absence_reason: value ? value : null })
                                        }
                                    />
                                </FlexGrid3Item>
                                {absence === "Sickness" || (values.absence_type &&
                                    choices.absence_type.find(type => type.id === values.absence_type) &&
                                    choices.absence_type.find(type => type.id === values.absence_type).name === 'Sickness'
                                )
                                    ?
                                    <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                        <DropdownSearchable

                                            disabled={readOnly}
                                            placeHolder={'Sickness Reason'}
                                            options={choices ? choices.sickness_reason : []}
                                            selectedOptionsProps={values.sickness_reason}
                                            selectedOption={values.sickness_reason}
                                            onChange={value =>
                                                handleDropDown({ sickness_reason: value ? value : null })
                                            }
                                        />
                                    </FlexGrid3Item>
                                    :
                                    null
                                }

                            </FlexGrid3Container>
                            <FlexGrid3Container className="mt-2">
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable

                                        disabled={readOnly}
                                        placeHolder={'Absence informed method'}
                                        // required
                                        // {...useInput(`employee`, 'isRequired')}
                                        options={choices ? choices.absence_informed_method : []}
                                        selectedOptionsProps={values.absence_informed_method}
                                        selectedOption={values.absence_informed_method}
                                        onChange={value =>
                                            handleDropDown({ absence_informed_method: value ? value : null })
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable

                                        disabled={readOnly}
                                        placeHolder={'Planned / Unplanned'}
                                        // required
                                        // {...useInput(`employee`, 'isRequired')}
                                        options={choices ? choices.absence_planned : []}
                                        selectedOptionsProps={values.absence_planned}
                                        selectedOption={values.absence_planned}
                                        onChange={value =>
                                            handleDropDown({ absence_planned: value ? value : null })
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable

                                        disabled={readOnly}
                                        placeHolder={'Paid / Unpaid / Paid at other rate'}
                                        // required
                                        // {...useInput(`employee`, 'isRequired')}
                                        options={choices ? choices.absence_paid : []}
                                        selectedOptionsProps={values.absence_paid}
                                        selectedOption={values.absence_paid}
                                        onChange={value =>
                                            handleDropDown({ absence_paid: value ? value : null })
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                                    <InputLabelTop
                                        disabled={readOnly}
                                        type="text"
                                        id="warning_message"
                                        label={'Notes'}
                                        onChange={(val) => handleNotes(val)}
                                        value={values.absence_notes}
                                    />
                                </FlexGrid3Item>

                            </FlexGrid3Container>
                        </div>

                    </>
                );
            default:
                return <Fragment />;
        }
    };

    const calculateEndValueProps = value => {
        let shiftname = filterShiftType && filterShiftType.find(shift => shift.id === values.shift_type) && filterShiftType.find(shift => shift.id === values.shift_type).name
        switch (shiftname) {
            case 'Availability':
            case 'Bank-Flexible':
                return 'isRequired';
            case 'Shift':
                return 'isRequired';
            default:
                return '';
        }
    };

    return (
        <div>
            <LineContainer className={'pl-2 pr-2 pt-2 pb-4'}>
                <WeekContainer>
                    <div className={'formContainer'}>
                        <div className="d-flex mt-2">
                            <div
                                className="w-100 mr-2"
                            >
                                <DropdownSearchable
                                    required
                                    {...useInput(`${[nameSection]}[${shiftIndex}].shift_type`, 'isRequired')}
                                    placeHolder={'Shift Type'}
                                    options={shiftType}
                                    selectedOption={
                                        values[nameSection][shiftIndex].shift_type
                                            ? values[nameSection][shiftIndex].shift_type
                                            : null
                                    }
                                    onChange={(value, name) =>
                                        handleDropDownS(
                                            { shift_type: value ? value : null }, name,
                                            'shift_type'
                                        )
                                    }
                                />
                            </div>
                            {/* <div className='pr-1'> */}
                            {switchTravelModeValue(
                                {
                                    ...useInput(`${[nameSection]}[${shiftIndex}].travel_mode`,
                                        calculateEndValueProps(
                                            values[nameSection][shiftIndex].type
                                        )
                                    ),
                                })}

                            {/* </div> */}

                        </div>
                        <FlexGrid3Container className="mt-2">
                            {switchPayRateValue(
                                {
                                    ...useInput(`${[nameSection]}[${shiftIndex}].regular_shift_pay_rate_id`, ''
                                    ),
                                })}

                            <FlexGrid3Item
                                className="pl-0 pr-1"
                                onClick={() => handleClick(1)}>
                                <TimeSelector
                                    isRequired={isFullDay ? false : true}
                                    disabled={isFullDay}
                                    data-error={timeError[shiftIndex]}
                                    errorMessage={timeErrorMessage}
                                    // data-error={data_error}
                                    id="start_time_shift_pattern_shift"
                                    labelTop={'Start time'}
                                    {...useInput(`${[nameSection]}[${shiftIndex}].start`, 'isRequired')}
                                    //added below code for time in 24hrs format 
                                    timeFormat={"HH:mm"}
                                    dateFormat={"HH:mm"}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item
                                className="pl-1 pr-0 pr-md-1"
                                onClick={() => handleClick(2)}>
                                <TimeSelector
                                    isRequired={isFullDay ? false : true}
                                    disabled={isFullDay}
                                    data-error={timeError[shiftIndex]}
                                    errorMessage={timeErrorMessage}
                                    // data-error={data_error}
                                    id="end_time_shift_pattern_shift"
                                    labelTop={'End time'}
                                    {...useInput(`${[nameSection]}[${shiftIndex}].end`, 'isRequired')}
                                    //added below code for time in 24hrs format 
                                    timeFormat={"HH:mm"}
                                    dateFormat={"HH:mm"}
                                />

                            </FlexGrid3Item>
                            <FlexGrid3Item
                                className="pl-1 pr-0 pr-md-1"

                            >
                                <DurationInput
                                    disabled={true}
                                    label_top={'Duration'}
                                    value={duration}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item
                                className="pl-0 pr-1 mt-2"
                                onClick={() => handleClick(2)}
                            >

                                <DropdownSearchable
                                 useInput={useInput(`${[nameSection]}[${shiftIndex}].pay_rate_card`)}
                                    placeHolder={'Pay Rates'}
                                    options={payRate}
                                    selectedOption={
                                        values[nameSection][shiftIndex].pay_rate_card
                                            ? values[nameSection][shiftIndex].pay_rate_card
                                            : null
                                    }
                                    onChange={value =>
                                        handleDropDown(
                                            { pay_rate_card: value ? value : null },
                                            'pay_rate_card'
                                        )
                                    }
                                />

                            </FlexGrid3Item>

                            <FlexGrid3Item
                                className="pl-0 pr-1 mt-2"
                            >
                                <TimeSelector
                                    id="break_start_time_shift_pattern_shift"
                                    labelTop={'Break Start time'}
                                    {...useInput(`${[nameSection]}[${shiftIndex}].break_start`)}
                                    //added below code for time in 24hrs format 
                                    timeFormat={"HH:mm"}
                                    dateFormat={"HH:mm"}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item
                                className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2"
                                onClick={() => handleClick(1)}
                            >
                                <TimeSelector
                                    id="break_end_time_shift_pattern_shift"
                                    labelTop={'Break End time'}
                                    {...useInput(`${[nameSection]}[${shiftIndex}].break_end`)}
                                    //added below code for time in 24hrs format 
                                    timeFormat={"HH:mm"}
                                    dateFormat={"HH:mm"}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
                                <InputLabelTop
                                    type="text"
                                    id="repeats_shift_pattern_shift"
                                    label={'Repeats'}
                                    onKeyPress={KeyPress.INTEGER}
                                    {...useInput(`${[nameSection]}[${shiftIndex}].repeat`, 'isInt')}
                                    maxlength={5}
                                />
                            </FlexGrid3Item>
                        </FlexGrid3Container>
                        <ContainerResponsive className="d-flex mt-3">
                            <ToggleButton
                                label="Full Day"
                                useInput={useInput(`${[nameSection]}[${shiftIndex}].full_day`)}
                                id="full_day"
                                onClick={handelFullDay}
                            />
                            <div className='ml-3'>
                                <ToggleButton
                                    label="Is Overnight"
                                    // useInput={useInput(`${[nameSection]}[${shiftIndex}].is_overnight`)} 
                                    useInput={useInput(`${[nameSection]}[${shiftIndex}].is_overnight`)}
                                    id="overnight"
                                // onClick={handelOverNight}
                                />
                            </div>
                        </ContainerResponsive>
                        {/* {switchAbsenceValue()} */}
                    </div>
                    <div className={'formWeek d-block'}>
                        <div
                            className={'pt-2 ml-md-2 ml-0 text-center'}
                            style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                            Day of the week
                        </div>
                        <WeekStyle>
                            {values[nameSection][shiftIndex] && values[nameSection][shiftIndex].daysofWeek &&
                                values[nameSection][shiftIndex].daysofWeek.map((itemWeek, indexWeek) => {

                                    return (
                                        <ButtonWeek
                                            isRequired
                                            key={itemWeek.day}
                                            disabled={!values[nameSection][shiftIndex].start || !values[nameSection][shiftIndex].end}
                                            add_background={itemWeek.selected}
                                            onClick={() => {
                                                selectedDay(itemWeek.day, indexWeek);
                                            }}>
                                            <div>
                                                <label>{itemWeek.day}</label>
                                            </div>
                                        </ButtonWeek>
                                    );
                                })}
                        </WeekStyle>
                        {shiftIndex != 0 &&
                            <div style={{ marginTop: "20px", paddingLeft: "80%" }}>
                                <IconWrapper
                                    onClick={() => {
                                        deleteShift(shiftIndex);
                                    }}
                                >
                                    <RiDeleteBinLine
                                        color="red"
                                        cursor='pointer'
                                        width='6rem'
                                    />
                                </IconWrapper>

                            </div>
                        }
                        {requireDayOfWeek.find(day => day === shiftIndex) + 1 ? (
                            <div
                                className={'text-center'}
                                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                                <ErrorMessage fontSize={'0.6rem'}>
                                    <label className={'mt-2'}>Is required</label>
                                </ErrorMessage>
                            </div>
                        ) : (
                            <Fragment />
                        )}
                    </div>

                </WeekContainer>

            </LineContainer>

        </div>
    );
}
export default Shift;