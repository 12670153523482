module.exports = {
  InvalidOrEmptyValues:
    'Some of the fields have invalid or empty values. Please review.',
  ERROR: 'Error',
  SAVE: 'Save',
  SAVED: 'Saved',
  FINISH: 'Finish',
  SkillAdded: 'The skill has already been added.',
  DetailsNotCreated: 'Personal Details have not been created.',
  InvalidRange: 'Invalid range',
  SERVER_ERROR: "Something went wrong. Please try again later."
};
