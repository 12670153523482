import React, { useState, useEffect } from 'react';
import { useForm } from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  getLeftButton,
  getRightButton,
  SpecializeCaseButton,
} from './IconsMethods';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

const ItemListStaffCheck = ({
  dataValue,
  handleSubmit,
  toEditId,
  toDeleteId,
  cancelEdit,
  removeNewService,
  leftButtonIcon,
  rightButtonIcon,
  enabledInput,
  tryReSubmit,
  labelInputTitle,
  addOneDropdown,
  specializeCase,
  specializedMethods,
  addOneInput,
  fieldAttributes,
  userStaff,
  update,
  create,
  deletePermission,
  addInput,
  setUserType
}) => {
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [buttonLeft, setButtonLeft] = useState(<div />);
  const [buttonRight, setButtonRight] = useState(<div />);
  const [specialButton, setSpecialButton] = useState(<div />);
  const [defaultChecks, setDefaultChecks] = useState(false)

  useEffect(() => {
    if (enabledInput !== dataValue.id) {
      assignValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledInput]);

  useEffect(() => {
    setButtonLeft(
      getLeftButton(
        leftButtonIcon,
        executeHandleSubmit,
        toEditId,
        dataValue,
        enabledInput,
        tryReSubmit,
      ),
    );

    setButtonRight(
      getRightButton(
        rightButtonIcon,
        dataValue,
        removeNewService,
        executeCancel,
        toDeleteId,
        enabledInput,
        specializeCase,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftButtonIcon, rightButtonIcon, enabledInput, values]);

  useEffect(() => {
    assignValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  useEffect(() => {
    setSpecialButton(
      SpecializeCaseButton(specializeCase, specializedMethods, dataValue, setUserType),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specializedMethods, values]);

  const assignValues = () => {
    setValues({
      name: dataValue.name || '',
      id: dataValue.id || '',
      disabled: true,
      is_staff: dataValue.is_staff,
      category: dataValue.category || '',
      user_type: dataValue.user_type || null
    });
  };

  const executeHandleSubmit = async () => {
    if (errors.length >= 1) {
      setCheckRequires(true);
      return;
    }
    const response = await handleSubmit(values);

    if (response) {
      dataValue.id = response.id;
      dataValue.name = response.name;
      dataValue.is_staff = response.is_staff
      if (addOneDropdown) {
        dataValue[addOneDropdown.value] = response[addOneDropdown.value];
      }
      if (addInput && addInput?.length) {
        addInput.map((input) => {
          dataValue[input.value] = response[input.value];
        })
      }
      assignValues();
    }
  };

  const executeCancel = () => {
    assignValues();
    cancelEdit();
  };

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
  };
  // Function for render new inputs 
  const renderAddInput = (props) => {
    if (addInput && addInput?.length) {
      let inputs = addInput.map((input) => {
        let isRequired = input.required ? 'isRequired' : ''
        if (input.type === "dropdown") {
          return <div className='w-100 ml-2'>
            <DropdownSearchable
              placeHolder={input.label}
              required={input.required || false}
              {...props(input.value, isRequired)}
              disabled={enabledInput !== dataValue.id}
              options={input.options || []}
              selectedOption={
                values[input.value] ? values[input.value] : null
              }
              onChange={value =>
                dropDownHandleSelect({ [input.value]: value ? value : null })
              }
            />
          </div>
        }
      })
      return inputs
    }
  }

  return (
    <div>
      <div className={'d-flex pl-3 pr-3'}>
        <div className={'w-100'}>
          <InputLabelTop
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            label={labelInputTitle}
            {...useInput('name', 'isRequired')}
            {...fieldAttributes}
          />
        </div>
        {/* Added user type dropdown */}
        {
          renderAddInput(useInput)
        }
        <input
          type="checkbox"
          disabled={enabledInput !== dataValue.id}
          id="roleCheck"
          className={'ml-2 mt-2'}
          checked={values.is_staff}
          {...useInput('is_staff')}
        />
        <label className="ml-1" for="roleCheck" > <small>Office </small></label>
        {!addOneDropdown ||
          !addOneDropdown.options ||
          addOneDropdown.options.length <= 0 ? null : (
          <AddOneDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addOneDropdown}
            specializeCase={specializeCase}
          />
        )}

        {!addOneInput ? null : (
          <AddOneInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addOneInput}
          />
        )}

        <div className={'ml-3'}>
          <div className="d-flex pt-1">

            <div className={'mr-2'}>{buttonLeft}</div>

            <div className={'mr-2'}>{buttonRight}</div>

            {specialButton}

          </div>
        </div>
      </div>
    </div>
  );
};

function AddOneDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  return (
    <div className={'w-100 ml-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`,
        )}
        disabled={enabledInput !== dataValue.id}
        options={valuesDropdown.options[valuesDropdown.choicesValue]}
        selectedOption={values[valuesDropdown.value]}
        onChange={value =>
          dropDownHandleSelect({ [valuesDropdown.value]: value ? value : null })
        }
      />
    </div>
  );
}

function AddOneInput({ useInput, enabledInput, dataValue, valuesInput }) {
  return (
    <div className={'w-100 ml-2'}>
      <InputLabelTop
        required
        disabled={enabledInput !== dataValue.id}
        type="text"
        label={valuesInput.label}
        {...useInput(
          valuesInput.value,
          `${valuesInput.required ? 'isRequired' : ''}`,
        )}
      />
    </div>
  );
}
export default ItemListStaffCheck;
