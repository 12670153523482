import React, {Fragment, useEffect} from 'react';
import Modal from 'react-bootstrap4-modal';
import {FaTimes} from 'react-icons/fa';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import Spinner from 'components/SharedComponents/Spinner';
import {
  CloseButtonWrapper,
  ModalDynamicContainer,
  FlexGrow,
  CloseButtonContainer,
  ModalTitle,
  LoadingContainer,
} from './styles';

function ModalDynamic({
  children,
  title,
  onClose,
  showModal,
  max_width = null,
  loadingMaxWidth= null,
  rowInfoData,
  photoRow = '',
  showRowInfo = false,
  minWidthTitle = '',
  name = '',
  lastName = '',
  loading = true,
  dateOfBirth = '',
}) {

  return (
    <Modal visible={showModal}>
      {loading ? (
        <LoadingContainer maxHeight={loadingMaxWidth}>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Fragment>
          <ModalDynamicContainer className="modal-header row-muted border-0">
            <FlexGrow>
              <ModalTitle MinWidth={minWidthTitle} className="modal-title">
                {title}
              </ModalTitle>
            </FlexGrow>

            <CloseButtonContainer>
              <CloseButtonWrapper>
                <FaTimes onClick={onClose} />
              </CloseButtonWrapper>
            </CloseButtonContainer>
          </ModalDynamicContainer>
          <ModalDynamicContainer className="modal-header row-muted border-0  d-flex  justify-content-center">
          {showRowInfo ? (
              <RowInfoModel
                name={name}
                lastName={lastName}
                photoRow={photoRow}
                rows={rowInfoData}
                dateOfBirth={dateOfBirth}
              />
            ) : (
              <></>
            )}
            </ModalDynamicContainer>

          {children}
        </Fragment>
      )}
      

    </Modal>
  );
}

export default ModalDynamic;
