import React, {useEffect, useState, useContext} from 'react';
import {
  ContainerTabStyled,
  Title,
  LinkTabStyled,
  LinkTab,
  TabStyled,
} from 'shared/styles/constants/tagsStyles';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import BillingSummary from './BillingSummary';
import BillingCompare from './BillingCompare';
import BillingDetails from './BillingDetails';
import {AppContext} from 'context';
import {
  FINANCE,
  BILLING,
  SUMMARY,
  COMPARE,
  DETAILS,
} from 'utils/constants/routes';
import {
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  FINANCE_BILL_SUMMARY,
  FINANCE_BILL_COMPARE,
  FINANCE_BILL_DETAILS,
} from '../../../../utils/constants/permissions';
import {getClientFundersList, getFundersList} from 'utils/api/ClientApi'
import BillingVisits from './BillingVisits';
import BillingTravel from './BillingTravel';
import BillingDistance from './BillingDistance';
import BillingExpanse from './BillingExpanse';

const TAB_BILLING_SUMMARY = FINANCE + BILLING + SUMMARY;
const TAB_BILLING_COMPARE = FINANCE + BILLING + COMPARE;
const TAB_BILLING_DETAILS = FINANCE + BILLING + DETAILS;
const FinanceBilling = props => {
  const [readPermissionBillSummary, setReadPermissionBillSummary] =
    useState(false);
  const [readPermissionBillCompare, setReadPermissionBillCompare] =
    useState(false);
  const [readPermissionBillDetails, setReadPermissionBillDetails] =
    useState(false);
    const [funderList,setFunderList]=useState([])
    const [tabSelectedDetails, setTabSelectedDetails] = useState(1);
  let history = useHistory();
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  useEffect(() => {
    const rolesPermissions = contextPermissions && contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    const roles = contextSubSectionPermissions && contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    if (contextIsSuperAdmin == true || contextIsAdmin == true) {
      setReadPermissionBillSummary(true);
      setReadPermissionBillCompare(true);
      setReadPermissionBillDetails(true);
    } else {
      setReadPermissionBillSummary(
        rolePermissions && rolePermissions[FINANCE_BILL_SUMMARY] && 
        rolePermissions[FINANCE_BILL_SUMMARY][WEB_PERMISSION]
        &&
        rolePermissions[FINANCE_BILL_SUMMARY][WEB_PERMISSION][WEB_READ_PERMISSION]
      );
      setReadPermissionBillCompare(
        rolePermissions && rolePermissions[FINANCE_BILL_COMPARE] && 
        rolePermissions[FINANCE_BILL_COMPARE][WEB_PERMISSION]
        &&
        rolePermissions[FINANCE_BILL_COMPARE][WEB_PERMISSION][WEB_READ_PERMISSION]
        
      );
      setReadPermissionBillDetails(
        rolePermissions && rolePermissions[FINANCE_BILL_DETAILS] && 
        rolePermissions[FINANCE_BILL_DETAILS][WEB_PERMISSION]
        &&
        rolePermissions[FINANCE_BILL_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION]
        
      );
    }
  }, [contextPermissions,contextSubSectionPermissions,contextIsAdmin,contextIsSuperAdmin]);

  

  const reviewLocation = url => {
    return url === history.location.pathname;
  };
  return (
    <div>
      <Title>Billing</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center">
          <div className={'d-flex firstRow'}>
            {readPermissionBillSummary && (
              <LinkTabStyled active={reviewLocation(TAB_BILLING_SUMMARY)}>
                <Link to={TAB_BILLING_SUMMARY} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_BILLING_SUMMARY)}>
                    Billing Summary
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
            {readPermissionBillDetails && (
              <LinkTabStyled active={reviewLocation(TAB_BILLING_DETAILS)}>
                <Link to={TAB_BILLING_DETAILS} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_BILLING_DETAILS)}>
                    Bill Details
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
            {readPermissionBillCompare && (
              <LinkTabStyled active={reviewLocation(TAB_BILLING_COMPARE)}>
                <Link to={TAB_BILLING_COMPARE} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_BILLING_COMPARE)}>
                    Bill Return
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
          </div>
        </ContainerTabStyled>
      </div>
      <Switch>
        <Route exact path={TAB_BILLING_SUMMARY}>
          <BillingSummary location={props.location} />
        </Route>
        <Route exact path={TAB_BILLING_COMPARE}>
          <BillingCompare location={props.location} />
        </Route>
        <Route exact path={TAB_BILLING_DETAILS}>
        <ContainerTabStyled className="text-center mb-4">
            <div className={'d-flex firstRow'}>
              <TabStyled
                active={tabSelectedDetails === 1}
                onClick={() => {
                  setTabSelectedDetails(1)
                }}>
                Overview
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 2}
                onClick={() => {
                  setTabSelectedDetails(2)
                }}>
                Visits
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 3}
                onClick={() => {
                  setTabSelectedDetails(3)
                }}>
                Travel
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 4}
                onClick={() => {
                  setTabSelectedDetails(4)
                }}>
                Distance
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 5}
                onClick={() => {
                  setTabSelectedDetails(5)
                }}>
                Bill Reimbursement
              </TabStyled>
            </div>
          </ContainerTabStyled>
          {tabSelectedDetails === 1 &&
                 <BillingDetails location={props.location}/>
                 }
                  {tabSelectedDetails === 2 &&
                 <BillingVisits location={props.location}/>
                 }
                  {tabSelectedDetails === 3 &&
                 <BillingTravel location={props.location}/>
                 }
                  {tabSelectedDetails === 4 &&
                 <BillingDistance location={props.location}/>
                 }
                  {tabSelectedDetails === 5 &&
                 <BillingExpanse location={props.location}/>
                 }
        </Route>
      </Switch>
    </div>
  );
};

export default FinanceBilling;
