import moment from 'moment'
import React from 'react'
import {
    InsightContainer,
    InsightBody,
    ToggleDiv
} from "./styles";
import { Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import { addToTriage, setMarkReview } from 'utils/api/DashboardApi';
import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

function RenderTimeLine({
    choices,
    item,
    toast,
    index,
    listOfInsites,
    setListOfInsights,
    setSeletedCard,
    setMarkReview }) {

    const [toggledisabled, setToggleDisabled] = useState({ status: false, id: "" })
    const getNameFromChoice = (id, key) => {
        if (!id) return "";

        let name = "";

        if (key === "client") {
            name = choices?.client_listing?.find(val => val.id == id)?.full_name || "";
        } else {
            const employee = choices?.employee_listing?.find(val => val.id == id)?.full_name;
            name = employee || choices?.user_list?.find(val => val.id == id)?.name || "";
        }

        return name;
    };






    const handleAddToTriage = async (item, index) => {
        const body = {
            "client": item.client,
            "description": item.description,
            "dateOfRecord": item.dateOfRecord,
            "title": item.title
        }
        try {
            const res = await addToTriage(body)
            if (res.message === "Triage has been created") {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res.message}`,
                });
            }

        } catch (error) {
        }
    }
    return (
        <InsightContainer
            markerColor={item.backgroundColor}
        >
            <div className="font-weight-bold">
                <p>{item.recordsOf}</p>
                <p>{item.dateOfRecord ? moment(item.dateOfRecord).format("DD-MM-YYYY") : ""}</p>
                <p>{item.dateOfRecord ? moment(item.dateOfRecord).format("HH:mm") : ""}</p>
            </div>
            <InsightBody backgroundColor={item.backgroundColor}>
                <div className='d-flex justify-content-between'>
                    <p className='font-weight-bold m-0'>{getNameFromChoice(item.client, "client")}</p>
                    {/* {toggledisabled.status && toggledisabled.id === item.id ? <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                    /> : <ToggleDiv>
                        <ToggleWrapper>
                            <Toggle
                                id={"checkbox" + index}
                                type="checkbox"
                                checked={item.triage}
                                disabled={item.triage}
                                onClick={() => {
                                    handleAddToTriage(item, index)
                                }}
                            />
                            <ToggleLabel htmlFor={"checkbox" + index} />
                        </ToggleWrapper>
                        <div className="toggle-label">
                            <p>Triage</p>
                        </div>
                    </ToggleDiv>} */}


                    <div class="insights-toggle dropdown">
                        <div
                            class="dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <BsThreeDotsVertical />
                        </div>
                        <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>

                            <li class="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleAddToTriage(item, index)
                                }}
                            >Add to Triage</li>

                            {(item.recordsOf == "Note" || item.recordsOf == "Med")
                                && <li class="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setMarkReview(true)
                                        setSeletedCard(item)
                                    }
                                    }
                                >Mark Review</li>}
                        </div>
                    </div>
                </div>
                <p className='m-0'>{item.employee && item.title ? getNameFromChoice(item.employee, "") + ` - ${item.title}` : item.title ? item.title : ""}</p>
                {item.status && <p>{item.status}</p>}
                <ul className='font-weight-light'>
                    <li>{item.description}</li>
                </ul>
            </InsightBody>
        </InsightContainer>
    )
}

export default RenderTimeLine