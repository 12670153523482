import React, { useState, useEffect, useContext } from 'react';
import RegularShifts from "components/DashboardComponents/Team/RegularShifts"
import ComponentDynamic from "components/DashboardComponents/ComponentDynamic"
import PersonalDetails from './PersonalDetails'
import JobDetails from './JobDetails'
import Compliance from './Compliance'
import Expenses from '../Expenses'
import Notes from './Notes'
import Skills from './Skills'
import EmployeeDetails from './EmployeeDetails'
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { TabStyledNew } from "../../../../shared/styles/constants/tagsStyles"
import { addRegularShift, addRegularShiftOverlap, setNewMultiShiftType } from 'utils/api/RotaShift'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';

import moment from 'moment'
import {
  setEmployee,
  getEmployees,
} from 'utils/api/EmployeeApi';
import { getChoices, getCompanyBranches } from 'utils/api/CoreApi';
import { getClients } from 'utils/api/ClientApi';
import _, { isArray } from 'lodash';
import {
  ShowTab,
  TabStyled,
  ShowTeamModalTab,
  ShowEmployeeNotesTab,
} from './styles';
import { getRoles } from '../../../../utils/api/UsersApi';
import { getAdmin, getSuperAdmin } from "../../../../utils/localStorage/token"
import { getSubSectionPermissions, getPermissions, getUserId, getEmployee } from '../../../../utils/localStorage/user';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  EMPLOYEE_PERSONAL_DETAILS,
  EMPLOYEE_JOB_DETAILS,
  EMPLOYEE_DETAILS,
  EMPLOYEE_PERSONAL_BASIC_DETAILS,
  COMPLIANCE,
  SKILLS,
  EMPLOYEE_ABSENCE,
  NOTES,
  REGULAR_SHIFTS,
  EMPLOYEE_FILE,
  EMPLOYEE_EXPENSES,
  EMPLOYEE_ABSENCE_OVERVIEW,
  EMPLOYEE_TRIAGE,
  EXPENSE_DETAILS,
  EXPENSE_AUTHORIZATION,
  EMPLOYEE_AVERAGE_HOURS,
  EMPLOYEE_HR_TASK,
  EMPLOYEE_CARE_NOTES,
  EMPLOYEE_RATES,
  EMPLOYEE_FORM_BUILDER
} from '../../../../utils/constants/permissions';
import { withRouter, useParams } from 'react-router';
import { AppContext } from "context"
import Files from './Files';
import { ModalTopContainer, ModalTabsContainer, StickyHeader, HeaderContent } from './ModalStyle';
import CareForum from './CareForum';
import SplitOverlapModal from '../RegularShifts/SplitOverlapModal';
import { useModal } from 'hooks/ModalHook';
import TriageModal from './TriageModal';
import AverageHours from '../EmployeeAverageHours';
import './styles.css'
import EmployeeHRTasks from '../EmployeeHRTasks';
import EmployeeCareNotes from '../EmployeeCareNotes';
import Rates from './Rates';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const tab4 = 4;
const tab5 = 5;
const tab6 = 6;
const tab7 = 7;
const tab8 = 8;
const tab9 = 9;
const tab10 = 10;
const tab11 = 11;
const tab12 = 12;
const tab13 = 13;
const tab14 = 14;
const tab15 = 15;

const edit_employee = 'Edit Employee';

const Team = (props) => {

  const {
    fullBind,
    Id,
    setModalValue = () => { },
    successCallback = () => { },
    updateSelfListOfEmployee,
    updateAllListOfEmployee,
    handleChangeChoices,
  } = props
  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
    useForm({});
  const [tabSelected, setTabSelected] = useState(tab1);
  const [reportsTo, setReportsTo] = useState([]);
  const [empId, setEmpId] = useState('')
  const [companyBranches, setCompanyBranches] = useState([]);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [nokPhoto, setNokPhoto] = useState(null);
  const [rowData, setRowData] = useState({})
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [isRequiredValue, setIsRequiredValue] = useState('');
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingModal, setLoadingModal] = useState(true);
  const [clientList, setClientList] = useState([]);
  //const [showSaveBtn, setShowSaveBtn] = useState(false);
  // const [rolesList, setRolesList] = useState([]);
  const [preferredClients, setPreferredClients] = useState([]);
  const [avoidClients, setAvoidClients] = useState([]);
  const [avoidClientsToAdd, setAvoidClientsToAdd] = useState([]);
  const [avoidClientsToDelete, setAvoidClientsToDelete] = useState([]);
  const [preferredClientsToAdd, setPreferredClientsToAdd] = useState([]);
  const [preferredClientsToDelete, setPreferredClientsToDelete] = useState([]);
  const [shiftModal, setShiftModal] = useState(false);
  const [makeUser, setMakeUser] = useState(false);
  const [errorResponse , setErrorResponse] = useState({})
  //Personal Details

  const [subSectionPersonalDetailsRead, setSubSectionPersonalDetailsRead] = useState(false);
  const [subSectionPersonalDetailsUpdate, setSubSectionPersonalDetailsUpdate] = useState(false);
  const [subSectionPersonalDetailsDelete, setSubSectionPersonalDetailsDelete] = useState(false);
  const [updatePermissionPersonalDetails, setUpdatePermissionPersonalDetails] = useState(false);
  const [createPermissionPersonalDetails, setCreatePermissionPersonalDetails] = useState(false);
  const [deletePermissionPersonalDetails, setDeletePermissionPersonalDetails] = useState(false);
  const [readPermissionPersonalDetails, setReadPermissionPersonalDetails] = useState(false);
  // Absence List
  const [subSectionAbsenceListUpdate, setSubSectionAbsenceListUpdate] = useState(false);
  const [subSectionAbsenceListDelete, setSubSectionAbsenceListDelete] = useState(false);
  const [updateAbsenceList, setUpdateAnsenceList] = useState(false);
  const [createPermissionAbsenceList, setCreatePermissionAbsenceList] = useState(false);
  const [deletePermissionAbsenceList, setDeletePermissionAbsenceList] = useState(false);
  const [readPermissionAbsenceList, setReadPermissionAbsenceList] = useState(false);
  //Job Details
  const [subSectionJoblDetailsCreate, setSubSectionJoblDetailsCreate] = useState(false);
  const [subSectionJoblDetailsRead, setSubSectionJoblDetailsRead] = useState(false);
  const [subSectionJoblDetailsUpdate, setSubSectionJoblDetailsUpdate] = useState(false);
  const [updatePermissionJoblDetails, setUpdatePermissionJoblDetails] = useState(false);
  const [createPermissionJoblDetails, setCreatePermissionJoblDetails] = useState(false);
  const [deletePermissionJoblDetails, setDeletePermissionJoblDetails] = useState(false);
  const [readPermissionJoblDetails, setReadPermissionJoblDetails] = useState(false);
  //Employee Details
  const [subSectionEmployeeDetailsCreate, setSubSectionEmployeeDetailsCreate] = useState(false);
  const [subSectionEmployeeDetailsRead, setSubSectionEmployeeDetailsRead] = useState(false);
  const [subSectionEmployeeDetailsUpdate, setSubSectionEmployeeDetailsUpdate] = useState(false);
  const [updatePermissionEmployeeDetails, setUpdatePermissionEmployeeDetails] = useState(false);
  const [createPermissionEmployeeDetails, setCreatePermissionEmployeeDetails] = useState(false);
  const [deletePermissionEmployeeDetails, setDeletePermissionEmployeeDetails] = useState(false);
  const [readPermissionEmployeeDetails, setReadPermissionEmployeeDetails] = useState(false);
  const [readPermissionCompliance, setReadPermissionCompliance] = useState(false);
  const [readPermissionSkills, setReadPermissionSkills] = useState(false);
  const [readPermissionEmployeeAbsence, setReadPermissionEmployeeAbsence] = useState(false)
  const [readPermissionNotes, setReadPermissionNotes] = useState(false);
  const [readPermissionRegularShifts, setReadPermissionRegularShifts] = useState(false);
  const [readPermissionEmployeeFile, setReadPermissionEmployeeFile] = useState(false);
  const [readPermissionEmployeeTriage, setReadPermissionEmployeeTriage] = useState(false);

  const [createPermissionCompliance, setCreatePermissionCompliance] = useState(false);
  const [createPermissionSkills, setCreatePermissionSkills] = useState(false);
  const [createPermissionNotes, setCreatePermissionNotes] = useState(false);
  const [createPermissionRegularShifts, setCreatePermissionRegularShifts] = useState(false);
  const [createPermissionEmployeeFile, setCreatePermissionEmployeeFile] = useState(false);
  const [updatePermissionCompliance, setUpdatePermissionCompliance] = useState(false);
  const [updatePermissionSkills, setUpdatePermissionSkills] = useState(false);
  const [updatePermissionNotes, setUpdatePermissionNotes] = useState(false);
  const [updatePermissionRegularShifts, setUpdatePermissionRegularShifts] = useState(false);
  const [updatePermissionEmployeeFile, setUpdatePermissionEmployeeFile] = useState(false);
  const [deletePermissionRegularShifts, setDeletePermissionRegularShifts] = useState(false);
  const [deletePermissionEmployeeFile, setDeletePermissionEmployeeFile] = useState(false);

  const [deletePermissionEmployeeExpensess, setDeletePermissionEmployeeExpensess] = useState(false);
  const [readPermissionEmployeeExpensess, setReadPermissionEmployeeExpensess] = useState(false);
  const [createPermissionEmployeeExpensess, setCreatePermissionEmployeeExpensess] = useState(false);
  const [updatePermissionEmployeeExpensess, setUpdatePermissionEmployeeExpensess] = useState(false);

  // Hr Task 
  const [readPermissionEmployeeHrTask, setReadPermissionEmployeeHrTask] = useState(false)
  const [createPermissionEmployeeHrTask, setCreatePermissionEmployeeHrTask] = useState(false)
  const [deletePermissionEmployeeHrTask, setDeletePermissionEmployeeHrTask] = useState(false)
  const [updatePermissionEmployeeHrTask, setUpdatePermissionEmployeeHrTask] = useState(false)

  //Average Hours permission
  const [readPermissionAverageHours, setReadPermissionAverageHours] = useState(false)

  // EMPLOYEE_CARE_NOTES &
  const [readPermissionEmployeeCareNotes, setReadPermissionEmployeeCareNotes] = useState(false)
  const [createPermissionEmployeeCareNotes, setCreatePermissionEmployeeCareNotes] = useState(false)
  const [deletePermissionEmployeeCareNotes, setDeletePermissionEmployeeCareNotes] = useState(false)
  const [updatePermissionEmployeeCareNotes, setUpdatePermissionEmployeeCareNotes] = useState(false)

  
  // Employee rates 
  const [readPermissionEmployeeRates, setReadPermissionEmployeeRates] = useState(false)
  const [createPermissionEmployeeRates, setCreatePermissionEmployeeRates] = useState(false)
  const [deletePermissionEmployeeRates, setDeletePermissionEmployeeRates] = useState(false)
  const [updatePermissionEmployeeRates, setUpdatePermissionEmployeeRates] = useState(false)


  // Employee rates 
  const [formsPermission, setFormsPermission] = useState({
    read: false,
    create: false,
    update: false,
    delete: false
  })

  const [newErrors, setNewErrors] = useState([])
  const [timeError, setTimeError] = useState([])
  const [jobDet, setJobDet] = useState('')
  const [compDet, setCompDet] = useState('')
  const [empDet, setEmpDet] = useState('')
  const [skillDet, setSkillDet] = useState('')
  const [regularDet, setRegularDet] = useState('')
  const [notesDet, setNotesDet] = useState('')
  const [rightPermission, setRightPermission] = useState({})

  const [photoById, setPhotoById] = useState();

  //overlap modal
  const [shiftOverlapData, setOverlapData] = useState([]);
  const [unassignedVisitList, setUnassignedVisitList] = useState([]);
  const [loadingOverlap, setLoadingOverlap] = useState([]);
  const [loadingProceed, setLoadingProceed] = useState([]);
  const [disableButton, setDisableButton] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [overlapMessage, setOverlapMessage] = useState('');
  const [currentModal, setCurrentModal] = useState(false);

  const {
    setValue: setOverlapModal,
    value: overlapModal,
    fullBind: fullBindOverlapModal,
  } = useModal(false);

  const { id: selectedEmpId } = useParams()
  const { contextChoices: choices, handleGetChoices, contextPermissions, contextSubscription, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, setContextChoices } = useContext(AppContext);
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterAdmin = contextIsAdmin;

  const userEmployee = getEmployee();

const toast = useRef()


  const updateErrors = (shiftIndex) => {
    let newError = errors.filter(error => !error.includes('regularShifts.shifts'))
    setFormErrors([...newErrors])
    // setNewErrors([...newError])
  }


  useEffect(() => {
    if (props.location.pathname === '/team/add/personalDetails' || props.location.pathname === `/team/edit/${selectedEmpId}/personalDetails`) {
      setTabSelected(tab1)
    }
    else if (props.location.pathname === '/team/add/jobDetails' || props.location.pathname === `/team/edit/${selectedEmpId}/jobDetails`) {
      setTabSelected(tab2)
    }
    else if (props.location.pathname === '/team/add/compliance' || props.location.pathname === `/team/edit/${selectedEmpId}/compliance`) {
      setTabSelected(tab3)
    }
    else if (props.location.pathname === '/team/add/employeeDetails' || props.location.pathname === `/team/edit/${selectedEmpId}/employeeDetails`) {
      setTabSelected(tab4)
    }
    else if (props.location.pathname === '/team/add/skills' || props.location.pathname === `/team/edit/${selectedEmpId}/skills`) {
      setTabSelected(tab5)
    }
    else if (props.location.pathname === '/team/add/regualarShift' || props.location.pathname === `/team/edit/${selectedEmpId}/regualarShift`) {
      setTabSelected(tab6)
    }
    else if (props.location.pathname === '/team/add/notes' || props.location.pathname === `/team/edit/${selectedEmpId}/notes`) {
      setTabSelected(tab7)
    }
    else if (props.location.pathname === '/team/add/files' || props.location.pathname === `/team/edit/${selectedEmpId}/files`) {
      setTabSelected(tab8)
    }
    else if (props.location.pathname === '/team/add/expenses' || props.location.pathname === `/team/edit/${selectedEmpId}/expenses`) {
      setTabSelected(tab9)
    }
    else if (props.location.pathname === '/team/add/careforum' || props.location.pathname === `/team/edit/${selectedEmpId}/careforum`) {
      setTabSelected(tab10)
    }
    else if (props.location.pathname === '/team/add/triage' || props.location.pathname === `/team/edit/${selectedEmpId}/triage`) {
      setTabSelected(tab11)
    }
    else if (props.location.pathname === `/team/edit/${selectedEmpId}/average-hours`) {
      setTabSelected(tab12)
    }
    else if (props.location.pathname === `/team/edit/${selectedEmpId}/hr_task`) {
      setTabSelected(tab13)
    }
    else if (props.location.pathname === `/team/edit/${selectedEmpId}/care_notes`) {
      setTabSelected(tab14)
    }else if (props.location.pathname === `/team/edit/${selectedEmpId}/rates`) {
      setTabSelected(tab15)
    }

  })
  useEffect(() => {


    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {

        // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        //added self delete
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];

        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];


      }
        // }
      );
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        //added self delete
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];

        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
      }

      );
    }
    setRightPermission({ ...rolePermissions })
    if (contextIsSuperAdmin) {
      debugger
      setCreatePermissionPersonalDetails(true);
      setUpdatePermissionPersonalDetails(true);
      setReadPermissionPersonalDetails(true);
      setDeletePermissionPersonalDetails(true);

      setSubSectionJoblDetailsCreate(true);
      setSubSectionJoblDetailsRead(true);
      setSubSectionJoblDetailsUpdate(true);
      setCreatePermissionJoblDetails(true);
      setUpdatePermissionJoblDetails(true);
      setReadPermissionJoblDetails(true);
      setDeletePermissionJoblDetails(true);
      setSubSectionEmployeeDetailsCreate(true);
      setSubSectionEmployeeDetailsRead(true);
      setSubSectionEmployeeDetailsUpdate(true);
      setCreatePermissionEmployeeDetails(true);
      setUpdatePermissionEmployeeDetails(true);
      setReadPermissionEmployeeDetails(true);
      setDeletePermissionEmployeeDetails(true);
      setReadPermissionCompliance(true);
      setReadPermissionSkills(true);
      setReadPermissionEmployeeAbsence(true)
      setReadPermissionNotes(true);
      setReadPermissionRegularShifts(true);
      setReadPermissionEmployeeFile(true);
      setReadPermissionEmployeeExpensess(true);
      setReadPermissionEmployeeTriage(true)
      setCreatePermissionCompliance(true);
      setCreatePermissionSkills(true);
      setCreatePermissionNotes(true);
      setCreatePermissionRegularShifts(true);
      setCreatePermissionEmployeeFile(true);
      setCreatePermissionEmployeeExpensess(true);
      setUpdatePermissionCompliance(true);
      setUpdatePermissionSkills(true);
      setUpdatePermissionNotes(true);
      setUpdatePermissionRegularShifts(true);
      setUpdatePermissionEmployeeFile(true);
      setUpdatePermissionEmployeeExpensess(true);
      setDeletePermissionRegularShifts(true);
      setDeletePermissionEmployeeExpensess(true)
      setDeletePermissionEmployeeFile(true)

      //Average Hours
      setReadPermissionAverageHours(true)
      //Hr Task
      setReadPermissionEmployeeHrTask(true)
      setCreatePermissionEmployeeHrTask(true)
      setDeletePermissionEmployeeHrTask(true)
      setUpdatePermissionEmployeeHrTask(true)
      //Employee care notes
      setReadPermissionEmployeeCareNotes(true)
      setCreatePermissionEmployeeCareNotes(true)
      setDeletePermissionEmployeeCareNotes(true)
      setUpdatePermissionEmployeeCareNotes(true)

      setReadPermissionEmployeeRates(true)
      setCreatePermissionEmployeeRates(true)
      setDeletePermissionEmployeeRates(true)
      setUpdatePermissionEmployeeRates(true)

      setFormsPermission({
        read: true,
        create: true,
        update: true,
        delete: true,
      })
    } else if (contextIsAdmin) {
      debugger
      setCreatePermissionPersonalDetails(true);
      setUpdatePermissionPersonalDetails(true);
      setReadPermissionPersonalDetails(contextSubscription?.[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION]);
      setDeletePermissionPersonalDetails(true);
      setSubSectionJoblDetailsCreate(true);
      setSubSectionJoblDetailsRead(true);
      setSubSectionJoblDetailsUpdate(true);
      setCreatePermissionJoblDetails(true);
      setUpdatePermissionJoblDetails(true);
      setReadPermissionJoblDetails(contextSubscription?.[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION]);
      setDeletePermissionJoblDetails(true);
      setSubSectionEmployeeDetailsCreate(true);
      setSubSectionEmployeeDetailsRead(contextSubscription?.[EMPLOYEE_DETAILS]?.[WEB_PERMISSION]);
      setSubSectionEmployeeDetailsUpdate(true);
      setCreatePermissionEmployeeDetails(true);
      setUpdatePermissionEmployeeDetails(true);
      setReadPermissionEmployeeDetails(contextSubscription?.[EMPLOYEE_DETAILS]?.[WEB_PERMISSION]);
      setDeletePermissionEmployeeDetails(true);
      setReadPermissionCompliance(contextSubscription?.[COMPLIANCE]?.[WEB_PERMISSION]);
      setReadPermissionSkills(contextSubscription?.[SKILLS]?.[WEB_PERMISSION]);
      setReadPermissionEmployeeAbsence(contextSubscription?.[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION])
      setReadPermissionNotes(contextSubscription?.[NOTES]?.[WEB_PERMISSION]);
      setReadPermissionRegularShifts(contextSubscription?.[REGULAR_SHIFTS]?.[WEB_PERMISSION]);
      setReadPermissionEmployeeFile(contextSubscription?.[EMPLOYEE_FILE]?.[WEB_PERMISSION]);
      setReadPermissionEmployeeExpensess(contextSubscription?.[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]);
      setReadPermissionEmployeeTriage(contextSubscription?.[EMPLOYEE_TRIAGE]?.[WEB_PERMISSION]);
      setCreatePermissionCompliance(true);
      setCreatePermissionSkills(true);
      setCreatePermissionNotes(true);
      setCreatePermissionRegularShifts(true);
      setCreatePermissionEmployeeFile(true);
      setCreatePermissionEmployeeExpensess(true);
      setUpdatePermissionCompliance(true);
      setUpdatePermissionSkills(true);
      setUpdatePermissionNotes(true);
      setUpdatePermissionRegularShifts(true);
      setUpdatePermissionEmployeeFile(true);
      setUpdatePermissionEmployeeExpensess(true);
      setDeletePermissionRegularShifts(true);
      setDeletePermissionEmployeeExpensess(true)
      setDeletePermissionEmployeeFile(true)

      //Average Hours
      setReadPermissionAverageHours(true)

      //Hr Task
      setReadPermissionEmployeeHrTask(contextSubscription?.[EMPLOYEE_HR_TASK][WEB_PERMISSION])
      setCreatePermissionEmployeeHrTask(true)
      setDeletePermissionEmployeeHrTask(true)
      setUpdatePermissionEmployeeHrTask(true)
      //Employee care notes
      setReadPermissionEmployeeCareNotes(contextSubscription?.[EMPLOYEE_CARE_NOTES][WEB_PERMISSION])
      setCreatePermissionEmployeeCareNotes(true)
      setDeletePermissionEmployeeCareNotes(true)
      setUpdatePermissionEmployeeCareNotes(true)
// EMPLOYEE_RATES
      setReadPermissionEmployeeRates(contextSubscription?.[EMPLOYEE_RATES][WEB_PERMISSION])
      setCreatePermissionEmployeeRates(true)
      setDeletePermissionEmployeeRates(true)
      setUpdatePermissionEmployeeRates(true)
      console.log(contextSubscription?.[EMPLOYEE_RATES]?.[WEB_PERMISSION],'EMPLOYEE_RATES')

      setFormsPermission({
        read: contextSubscription?.[EMPLOYEE_FORM_BUILDER][WEB_PERMISSION],
        create: true,
        update: true,
        delete: true,
      })
    }
    else {
      debugger
      if (rolePermissions[EMPLOYEE_PERSONAL_DETAILS] !== undefined) {
        setCreatePermissionPersonalDetails(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setUpdatePermissionPersonalDetails(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setReadPermissionPersonalDetails(contextSubscription?.[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION] &&
          (
            rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
        );
        setDeletePermissionPersonalDetails(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );


        // JOB  Details
        //innersection
        setSubSectionJoblDetailsCreate(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setSubSectionJoblDetailsRead(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );
        setSubSectionJoblDetailsUpdate(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        // main page
        setCreatePermissionJoblDetails(
          rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setUpdatePermissionJoblDetails(
          rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setReadPermissionJoblDetails(contextSubscription?.[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
        );
        setDeletePermissionJoblDetails(
          rolePermissions[EMPLOYEE_JOB_DETAILS]?.[WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]

        );

        // Employee Details
        //innersection
        setSubSectionEmployeeDetailsCreate(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setSubSectionEmployeeDetailsRead(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );
        setSubSectionEmployeeDetailsUpdate(
          rolePermissions[EMPLOYEE_PERSONAL_DETAILS]?.[WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        // main page
        setCreatePermissionEmployeeDetails(
          rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setUpdatePermissionEmployeeDetails(
          rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
        );

        setReadPermissionEmployeeDetails(contextSubscription?.[EMPLOYEE_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
        );
        setDeletePermissionEmployeeDetails(
          rolePermissions[EMPLOYEE_DETAILS]?.[WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
      }

      //Average Hours
      if (rolePermissions[EMPLOYEE_AVERAGE_HOURS] !== undefined) {
        setReadPermissionAverageHours(
          contextSubscription?.[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userEmployee == selectedEmpId))))
        )
      }

      setReadPermissionCompliance(contextSubscription?.[COMPLIANCE]?.[WEB_PERMISSION] &&
        (rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      )
      setReadPermissionSkills(contextSubscription?.[SKILLS]?.[WEB_PERMISSION] &&
        (rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );

      setReadPermissionEmployeeAbsence(contextSubscription?.[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION] &&
        (rolePermissions[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );
      setReadPermissionNotes(contextSubscription?.[NOTES]?.[WEB_PERMISSION] &&
        (rolePermissions[NOTES]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );
      setReadPermissionRegularShifts(contextSubscription?.[REGULAR_SHIFTS]?.[WEB_PERMISSION] &&
        (rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );
      setReadPermissionEmployeeFile(contextSubscription?.[EMPLOYEE_FILE]?.[WEB_PERMISSION] &&
        (rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );
      setReadPermissionEmployeeExpensess(contextSubscription?.[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION] &&
        (rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
      );
      setReadPermissionEmployeeTriage(contextSubscription?.[EMPLOYEE_TRIAGE]?.[WEB_PERMISSION] &&
        (rolePermissions[EMPLOYEE_TRIAGE]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[EMPLOYEE_TRIAGE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
            //  && (userEmployee == selectedEmpId)
          ))
      );
      setCreatePermissionCompliance(
        rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      )
      setCreatePermissionSkills(
        rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setCreatePermissionNotes(
        rolePermissions[NOTES]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setCreatePermissionRegularShifts(
        rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setCreatePermissionEmployeeFile(
        rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setCreatePermissionEmployeeExpensess(
        rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );

      setUpdatePermissionCompliance(
        rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[COMPLIANCE]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      )
      setUpdatePermissionSkills(
        rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[SKILLS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setUpdatePermissionNotes(
        rolePermissions[NOTES]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setUpdatePermissionRegularShifts(
        rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setUpdatePermissionEmployeeFile(
        rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setUpdatePermissionEmployeeExpensess(
        rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );

      setDeletePermissionRegularShifts(
        rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION] ||
        (rolePermissions[REGULAR_SHIFTS]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setDeletePermissionEmployeeFile(
        rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_FILE]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION] && (userEmployee == selectedEmpId))
      );
      setDeletePermissionEmployeeExpensess(
        rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_EXPENSES]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION] && (userEmployee == selectedEmpId))

      )
      //Hr Task
      setCreatePermissionEmployeeHrTask(
        rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
      );

      setUpdatePermissionEmployeeHrTask(
        rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
        (rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
      );

      setReadPermissionEmployeeHrTask(
        contextSubscription?.[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION] &&
        ((rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]) && (userEmployee == selectedEmpId)))
      );
      setDeletePermissionEmployeeHrTask(
        rolePermissions[EMPLOYEE_HR_TASK]?.[WEB_PERMISSION][
        WEB_DELETE_PERMISSION
        ]
      )



    // EMPLOYEE_CARE_NOTES
    //Employee care notes
    if (rolePermissions[EMPLOYEE_CARE_NOTES]) {
      const hasWebPermission = contextSubscription?.[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION];
      const isSameEmployee = userEmployee === selectedEmpId;

      const hasReadPermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION];
      const hasSelfReadPermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION];
      const shouldReadAccess = hasWebPermission && (hasReadPermission || (hasSelfReadPermission && isSameEmployee));

      setReadPermissionEmployeeCareNotes(shouldReadAccess)


      const hasCreatePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
      const hasSelfCreatePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
      const shouldCreateAccess = hasWebPermission && (hasCreatePermission || (hasSelfCreatePermission && isSameEmployee));

      setCreatePermissionEmployeeCareNotes(shouldCreateAccess)

      const hasUpdatePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
      const hasSelfUpdatePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
      const shouldUpdateAccess = hasWebPermission && (hasUpdatePermission || (hasSelfUpdatePermission && isSameEmployee));

      setUpdatePermissionEmployeeCareNotes(shouldUpdateAccess)

      const hasDeletePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
      const hasSelfDeletePermission = rolePermissions[EMPLOYEE_CARE_NOTES]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION]
      const shouldDeleteAccess = hasDeletePermission || (hasSelfDeletePermission && isSameEmployee);

      setDeletePermissionEmployeeCareNotes(shouldDeleteAccess)
    }
    //EMPLOYEE_RATES
    if (rolePermissions[EMPLOYEE_RATES]) {
      const hasWebPermission = contextSubscription?.[EMPLOYEE_RATES]?.[WEB_PERMISSION];
      const isSameEmployee = userEmployee === selectedEmpId;

      const hasReadPermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION];
      const hasSelfReadPermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION];
      const shouldReadAccess = hasWebPermission && (hasReadPermission || (hasSelfReadPermission && isSameEmployee));

      setReadPermissionEmployeeRates(shouldReadAccess)


      const hasCreatePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
      const hasSelfCreatePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
      const shouldCreateAccess = hasWebPermission && (hasCreatePermission || (hasSelfCreatePermission && isSameEmployee));

      setCreatePermissionEmployeeRates(shouldCreateAccess)

      const hasUpdatePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
      const hasSelfUpdatePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
      const shouldUpdateAccess = hasWebPermission && (hasUpdatePermission || (hasSelfUpdatePermission && isSameEmployee));

      setUpdatePermissionEmployeeRates(shouldUpdateAccess)

      const hasDeletePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
      const hasSelfDeletePermission = rolePermissions[EMPLOYEE_RATES]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION]
      const shouldDeleteAccess = hasDeletePermission || (hasSelfDeletePermission && isSameEmployee);

      setDeletePermissionEmployeeRates(shouldDeleteAccess)
    }


    if (rolePermissions[EMPLOYEE_FORM_BUILDER]) {
      const hasWebPermission = contextSubscription?.[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION];
      const isSameEmployee = userEmployee === selectedEmpId;

      const hasReadPermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION];
      const hasSelfReadPermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION];
      const hasCreatePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
      const hasSelfCreatePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
      const hasUpdatePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
      const hasSelfUpdatePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
      const hasDeletePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
      const hasSelfDeletePermission = rolePermissions[EMPLOYEE_FORM_BUILDER]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION]
      
      
      const shouldDeleteAccess = hasDeletePermission || (hasSelfDeletePermission && isSameEmployee);
      const shouldUpdateAccess = hasWebPermission && (hasUpdatePermission || (hasSelfUpdatePermission && isSameEmployee));
      const shouldCreateAccess = hasWebPermission && (hasCreatePermission || (hasSelfCreatePermission && isSameEmployee));
      const shouldReadAccess = hasWebPermission && (hasReadPermission || (hasSelfReadPermission && isSameEmployee));

      setFormsPermission({
        read: shouldReadAccess,
        create: shouldCreateAccess,
        update: shouldUpdateAccess,
        delete: shouldDeleteAccess,
      })
    }
    }


  }, [contextIsAdmin, contextIsSuperAdmin, contextPermissions, contextSubSectionPermissions, contextSubscription]);

  // useEffect(()=>{
  //   if(props.location.state && props.location.state.updateAllListOfEmployee){
  //     setShowSaveBtn(true);
  //   }else if( props.location.state && props.location.state.updateSelfListOfEmployee && (userEmployee === selectedEmpId)){
  //     setShowSaveBtn(true)
  //   }
  // },[])

  useEffect(() => {
    if (tabSelected === tab1) {
      setIsRequiredValue('');
    } else if (tabSelected === tab2 || tabSelected === tab4) {
      setIsRequiredValue('isRequired');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    if (choices.employee_listing) {
      loadReportsTo();
    }
    let clients = [];

    if (choices.client_listing) {
      clients = choices?.client_listing?.map(client => {
        return { id: client.id, name: client.full_name };
      });
    }
    setClientList(clients || []);
    setLoadingModal(false)

  }, [choices.employee_listing, choices.client_listing]);

  useEffect(() => {
    if (showError && errors.length === 0) {
      setShowError(false);
      setCheckRequires(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.length]);

  useEffect(() => {
    if (values.id) {
      setAddFullBind({ ...addFullBind, title: edit_employee });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  useEffect(() => {
    if (textStatus !== SAVE) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }
    if (values.make_user === undefined && values.role) {
      values.make_user = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!values.make_user) {
      let result = _.omit(values, ['role', 'role_id']);
      setValues(result);
      setMakeUser(false);
    } else {
      setMakeUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.make_user]);



  const loadReportsTo = async () => {
    let employees = [];
    // if (response.results) {
    employees = choices.employee_listing.map(employee => {
      return { id: employee.id, name: employee.full_name };
    });
    // }
    setReportsTo(employees || []);
  };

  // const loadCompanyBranches = async () => {
  //   const response = await getCompanyBranches();
  //   setCompanyBranches(response.results || []);
  // };

  // const loadRoles = async () => {
  //   const response = await getRoles(values);
  //   setRolesList(response.results || []);
  // };


  //   const loadEmployee = async () => {
  //     const response = await getEmployee(selectedEmpId);

  //     if (!response.skills) {
  //       response.skills = [];
  //     }
  // //     if(response.photo){
  // // setPhotoById(response.photo)
  // //     }
  //     if (
  //       response.preference &&
  //       response.preference.avoid_clients &&
  //       response.preference.avoid_clients.length > 0
  //     ) {
  //       setAvoidClients(response.preference.avoid_clients);
  //     } else {
  //       setAvoidClients([]);
  //     }
  //     if (
  //       response.preference &&
  //       response.preference.preferred_clients &&
  //       response.preference.preferred_clients.length > 0
  //     ) {
  //       setPreferredClients(response.preference.preferred_clients);
  //     } else {
  //       setPreferredClients([]);
  //     }
  //     if (response.employee_run_routes) {
  //     if(response.job_detail==null){
  //         response.job_detail={};
  //     }
  //       response.job_detail.run_routes = response.employee_run_routes.map(route => {
  //         if (tempChoices && tempChoices.run_routes && tempChoices.run_routes.find(item => item.id === route.run_routes) &&
  //           tempChoices.run_routes.find(item => item.id === route.run_routes).name) {
  //           return (
  //             {
  //               value: route.run_routes,
  //               label: tempChoices && tempChoices.run_routes && tempChoices.run_routes.find(item => item.id === route.run_routes).name
  //             }
  //           )
  //         }

  //       })
  //     }
  //     setValues({ ...values, ...response });
  //     setLoadingModal(false);
  //   };

  const isCurrentTab = tab => {

    return tabSelected === tab;
  };

  const handleOverlapSubmit = async (items, index, split) => {

    const body = {
      employee_shift_id_list: items.overlap_data && items.overlap_data.map((val) => (val.employee_shift_id)),
      start_date: items.shift_data.start_date,
      end_date: items.shift_data.end_date,
      is_absent: items.shift_data.is_absent,
      is_available: items.shift_data.is_available,
      is_overnight: items.shift_data.is_overnight,
      absence_ref: items.shift_data.absence_ref && ((items.shift_data.absence_ref == 'None') ? null : items.shift_data.absence_ref),
      regular_shift_detail_id: items.shift_data.regular_shift_detail_id,
      is_split: split,
      force_unassigned_visit: items.force_unassigned_visit
    }

    addRegularShiftOverlap(body)
      .then(res => {
        if (res.status) {
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          setErrorResponse({status:"success", response : res})
        }
      })
      .catch(() => { });

  };

  const handleSubmit = async (evt, getClientContracts, closeModal = true, triggerSave = true) => {
    evt.preventDefault();
    if (loadingSave) {
      return;
    }
    let body = {
      ...values,
    };
    
    const new_shift_names = values && values.regularShifts && values.regularShifts.shifts.map((val) => {
      if (val.new_shift_name) {
        return { name: val.new_shift_name }
      }
    }).filter(i => i)
    const response = await setNewMultiShiftType(new_shift_names);
    const shift_response = await getChoices('regular_shift_type').then((res_data) => {
      setContextChoices({ ...choices, ...res_data })
      return res_data.regular_shift_type
    });

    const travelRateAllData = values.regularShifts && values.regularShifts.travelRate && values.regularShifts.travelRate.map(travelratedata => {
      return {
        id: travelratedata.id,
        effective_date_from: travelratedata.effective_date_from,
        effective_date_to: travelratedata.effective_date_to,
        regular_shift_bill_rate_id: travelratedata.travel_rate,
        bill_rate_per_hour: travelratedata.travel_rate_per_hour,
      }
    });
    const valuesShifts = values.regularShifts;
    const valueshift = {
      ...valuesShifts, shifts: valuesShifts.shifts.map((item) => {
        let new_type = '';
        if (item.type === 'new_shift') {
          new_type = shift_response && shift_response.find((val) => val.name == item.new_shift_name).id
          delete item.new_shift_name
        }
        if (item.travel_mode) {
          return ({ 
            ...item, repeat_on: item.repeat_on,
             regular_shift_pay_rate_id: item.regular_shift_pay_rate_id,
              travel_mode: { travel_mode_id: item.travel_mode }, type: { type_id: item.type === "new_shift" ? new_type : item.type } })
        } else {
          return ({ 
            ...item, repeat_on: item.repeat_on, 
            regular_shift_pay_rate_id: item.regular_shift_pay_rate_id,
             type: { type_id: item.type === "new_shift" ? new_type : item.type } })
        }

      })
    }
    let tempId = empId ? empId : selectedEmpId
    const regularShiftData = {
      core: valuesShifts.core_id ? {
        branch_id : valueshift.branch_id,
        core_id: valuesShifts.core_id, name: valueshift.name, start_date: valueshift.start_date, end_date: valueshift.end_date ? valueshift.end_date : null, every: valueshift.every, repeat: valueshift.repeat, end_type: valueshift.end_type, end_value: valueshift.end_value, employee: { employee_id: tempId }
        , bill_details: travelRateAllData
      }
        : { branch_id : valueshift.branch_id, name: valueshift.name, start_date: valueshift.start_date, end_date: valueshift.end_date ? valueshift.end_date : null, every: valueshift.every, repeat: valueshift.repeat, end_type: valueshift.end_type, end_value: valueshift.end_value, employee: { employee_id: tempId }, bill_details: travelRateAllData },
      details: valueshift.shifts,
    }
    let value = []
    setLoadingSave(true)
    if (showForm) {
      addRegularShift(regularShiftData, tempId)
        .then(res => {
          if (res.statusCode === 400) {
            if (res.status === 'FAIL') {
              console.log(res,"resssss")
              setErrorResponse({status:"error", response: res})
              if (res.message.data) {
                const overlap = []
                if (res.message.data.length > 0) {
                  res.message.data.forEach((item) => {
                    const unassigned_data = []
                    item.overlap_data.forEach((val) => {
                      val.visit_unassigned_data.forEach((unassign) => {
                        unassigned_data.push(unassign)
                      })
                    })
                    overlap.push({
                      shift_data: item.shift_data,
                      employee_id: item.overlap_data && (item.overlap_data.length > 0) && item.overlap_data[0].employee_id,
                      unassigned_data: unassigned_data,
                      overlap_data: item.overlap_data,
                      show: false,
                      force_unassigned_visit: false
                    })
                  })
                }
                if (res?.message?.get_data) {
                  if (res.message.get_data.id) {
                    values.regularShifts.core_id = res.message.get_data.id;
                    // values.regularShifts.name = response.name;
                    // values.regularShifts.every = response.every;
                    // values.regularShifts.name = response.name;
                    // values.regularShifts.start_date = response.start_date;
                    // values.regularShifts.end_date = response.end_date;
                    // values.regularShifts.end_type = response.end_type;
                    // values.regularShifts.end_value = response.end_value;
                    // values.regularShifts.repeat = response.repeat;
                    // values.regularShifts.shifts = response.details_list.map((obj, index) => {
                    //   return {
                    //     id: obj.id,
                    //     type: obj.type.id,
                    //     travel_mode: obj.travel_mode && obj.travel_mode.id,
                    //     end_time: obj.end_time,
                    //     start_time: obj.start_time,
                    //     effective_date: obj.effective_date,
                    //     break_start_time: obj.break_start_time,
                    //     break_end_time: obj.break_end_time,
                    //     is_full_day: obj.is_full_day,
                    //     is_overnight: obj.is_overnight,
                    //     duration: obj.duration,
                    //     travel_time: obj.travel_time && obj.travel_time,
                    //     repeat_on: obj.repeat_on,
                    //     regular_shift_pay_rate_id: obj.regular_shift_pay_rate,
                    //   };
                    // });
                    // values.regularShifts.travelRate = response.bill_details.map(
                    //   (obj, index) => {
                    //     return {
                    //       id: obj.id,
                    //       effective_date_from: obj.effective_date_from,
                    //       effective_date_to: obj.effective_date_to,
                    //       travel_rate_per_hour: obj.bill_rate_per_hour,
                    //       travel_rate: obj.regular_shift_bill_rate,
                    //     };
                    //   }
                    // );

                    setValues({ ...values });
                  }
                }
                setOverlapData(overlap)
                setOverlapModal(true)
                setLoadingSave(false)
                setErrorMessage(res?.message && typeof res?.message  == "string" &&  res?.message )
                setShowError(true)

              } else {
                setLoadingSave(false)
                if (res.message) {
                  value = Object.values(res)[0];
                }
                throw new Error(value);
              }
            }
          } else {
            setLoadingSave(false)
            setShowError(false);
            onSuccess(res, getClientContracts, closeModal);
          }

        })
        .catch(onError);
    }


  };

  const onSuccess = async (response, getClientContracts, closeModal = false) => {
 
    setErrorResponse({status:"success", response: response})
    let shift_id = response?.data && response?.data?.shift_core_id && response.data.shift_core_id;
    setValues({
      regularShifts: { ...values.regularShifts, core_id: shift_id }


    });

    successCallback();
    if (closeModal) {
      setModalValue();
    }
    setShiftModal(false)
    getClientContracts()
    setShowForm(false);
    setLoadingSave(false);
    setLoadingFinish(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);

  };

  const onError = err => {
    setLoadingSave(false);
    setLoadingFinish(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage( err?.message && typeof err?.message  == "string" ? err.message:
      "Error in saving Regular Shift"
    );
    // setErrorMessage(
    //   err ? err?.message || InvalidOrEmptyValues : InvalidOrEmptyValues
    // );
  };


  return (
    <>
    <Toast ref={toast} />


      <ComponentDynamic
        loading={loadingModal}
      >
        <div className="pb-4 pl-2 pr-2">
            <StickyHeader>
            <RowInfoModel
            Center={true}
          name={rowData.first_name || rowData.full_name}
          lastName={rowData.last_name}
          photoRow={
            rowData.photo
              ? URL.createObjectURL(rowData.photo)
              : photoById
              ? photoById
              : ''
          }
          rows={[
            { label: 'Name:', value: rowData.full_name },
            {
              label: 'DOB:',
              value: rowData.date_of_birth
                ? `${moment(rowData.date_of_birth).format(
                    'DD-MM-YYYY'
                  )} (${moment().diff(rowData.date_of_birth, 'years')})`
                : '',
            },
          ]}
          // dateOfBirth={dateOfBirth}
        />
            <HeaderContent>
              <TabStyledNew
                style={{
                  width: 'fit-content',
                  paddingRight: '1rem',
                  paddingLeft: '1rem',
                }}
                active={isCurrentTab(tab1)}
                onClick={() => {
                  if (props.location.pathname.includes('/team/add')) {
                    props.history.push('/team/add/personalDetails')
                  } else {
                    props.history.push(`/team/edit/${selectedEmpId}/personalDetails`)
                  }

                }}>
                Personal Details
              </TabStyledNew>

              {readPermissionJoblDetails && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab2)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/jobDetails')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/jobDetails`)
                    }

                  }}>
                  Job Details
                </TabStyledNew>
              )}
              {readPermissionEmployeeRates && (
                  <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                    className="team-tabs"
                    active={isCurrentTab(tab15)}
                    onClick={() => { 
                      if (props.location.pathname.includes('/team/add') && empId) {
                        props.history.push(`/team/add/rates`)
                      } else if (props.location.pathname.includes('/team/edit')) {
                        props.history.push(`/team/edit/${selectedEmpId}/rates`)
                      }
                       }}>
                    Rates
                  </TabStyledNew>
                )}
              
              {readPermissionCompliance && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab3)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/compliance')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/compliance`)
                    }

                  }}>
                  Compliance
                </TabStyledNew>
              )}
              {readPermissionEmployeeDetails && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab4)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/employeeDetails')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/employeeDetails`)
                    }
                  }}>
                  Employee Details
                </TabStyledNew>
              )}
              {readPermissionSkills && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab5)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/skills')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/skills`)
                    }
                  }}>
                  Skills
                </TabStyledNew>
              )}
              {readPermissionRegularShifts && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab6)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/regualarShift')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/regualarShift`)
                    }
                  }}>
                  Regular Shifts
                </TabStyledNew>
              )}
              {readPermissionNotes && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab7)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/notes')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/notes`)
                    }
                  }}>
                  Notes
                </TabStyledNew>
              )}
              {readPermissionEmployeeFile && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab8)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/files')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/files`)
                    }
                  }}>
                  Files
                </TabStyledNew>
              )}
              {readPermissionEmployeeTriage && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab11)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/triage')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/triage`)
                    }
                  }}>
                  Triage
                </TabStyledNew>
              )}
              {readPermissionEmployeeExpensess && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab9)}
                  onClick={() => {
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/expenses')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/expenses`)
                    }
                  }}>
                  Expenses
                </TabStyledNew>
              )}
              {formsPermission.read && <TabStyledNew
                style={{
                  width: 'fit-content',
                  paddingRight: '1rem',
                  paddingLeft: '1rem',
                }}
                active={isCurrentTab(tab10)}
                onClick={() => {
                  if (props.location.pathname.includes('/team/add') && empId) {
                    props.history.push('/team/add/careforum')
                  } else if (props.location.pathname.includes('/team/edit')) {
                    props.history.push(`/team/edit/${selectedEmpId}/careforum`)
                  }
                }}>
                Forms
              </TabStyledNew>}

              {readPermissionAverageHours && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab12)}
                  onClick={() => { 
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/average-hours')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/average-hours`)
                    }
                  }}>
                  Average Hours
                </TabStyledNew>
              )}

              {readPermissionEmployeeHrTask && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab13)}
                  onClick={() => { 
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/hr_task')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/hr_task`)
                    }
                  }}>
                  QA Tasks
                </TabStyledNew>
              )}

              {readPermissionEmployeeCareNotes && (
                <TabStyledNew
                  style={{
                    width: 'fit-content',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  active={isCurrentTab(tab14)}
                  onClick={() => { 
                    if (props.location.pathname.includes('/team/add') && empId) {
                      props.history.push('/team/add/care_notes')
                    } else if (props.location.pathname.includes('/team/edit')) {
                      props.history.push(`/team/edit/${selectedEmpId}/care_notes`)
                    }
                  }}>
                  Employee Care Notes
                </TabStyledNew>
              )}
              </HeaderContent>
              </StickyHeader>

          <div className='team-content'>{/* {  (subSectionPersonalDetailsCreate ||  subSectionPersonalDetailsRead || readPermissionPersonalDetails || subSectionPersonalDetailsUpdate || createPermissionPersonalDetails || updatePermissionPersonalDetails)   &&   ( */}
            <ShowTeamModalTab show={isCurrentTab(tab1)} >
              <PersonalDetails
                choices={choices}
                clientList={clientList}
                //  rolesList={rolesList}
                selectedId={selectedEmpId}
                setEmpId={setEmpId}
                setRowData={setRowData}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                successCallback={successCallback}
                rightPermission={rightPermission}
                subSectionPersonalDetailsUpdate={subSectionPersonalDetailsUpdate}
                createPermissionPersonalDetails={createPermissionPersonalDetails}
                updatePermissionPersonalDetails={updatePermissionPersonalDetails}
                readPermissionPersonalDetails={readPermissionPersonalDetails}
                setModalValue={setModalValue}
                setPhotoById={setPhotoById}
                userEmployee={userEmployee}
                props={props}
                handleChangeChoices={handleChangeChoices}
                handleGetChoices={handleGetChoices}
              />
            </ShowTeamModalTab>
            {/* )} */}
            <ShowTeamModalTab show={isCurrentTab(tab2)}  >
              {
                tabSelected == tab2 &&
                <JobDetails
                  companyBranches={choices.branch}
                  choices={choices}
                  clientList={clientList}
                  reportsTo={reportsTo}
                  selectedId={selectedEmpId}
                  empId={empId}
                  successCallback={successCallback}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  contextSubscription={contextSubscription}
                  rightPermission={rightPermission}
                  createPermissionJoblDetails={createPermissionJoblDetails}
                  updatePermissionJoblDetails={updatePermissionJoblDetails}
                  readPermissionJoblDetails={readPermissionJoblDetails}
                  setJobDet={setJobDet}
                  jobDet={jobDet}
                  userEmployee={userEmployee}
                />
              }

            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab3)}  >
              {
                tabSelected == tab3 &&
                <Compliance
                  choices={choices}
                  selectedId={selectedEmpId}
                  empId={empId}
                  reportsTo={reportsTo}
                  compDet={compDet}
                  setCompDet={setCompDet}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  contextSubscription={contextSubscription}
                  rightPermission={rightPermission}
                  createPermissionCompliance={createPermissionCompliance}
                  updatePermissionCompliance={updatePermissionCompliance}
                  readPermissionCompliance={readPermissionCompliance}
                  userEmployee={userEmployee}
                />
              }
            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab4)}  >
              {
                tabSelected == tab4 &&
                <EmployeeDetails
                  choices={choices}
                  selectedId={selectedEmpId}
                  empId={empId}
                  successCallback={successCallback}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  contextSubscription={contextSubscription}
                  rightPermission={rightPermission}
                  createPermissionEmployeeDetails={createPermissionEmployeeDetails}
                  updatePermissionEmployeeDetails={updatePermissionEmployeeDetails}
                  readPermissionEmployeeDetails={readPermissionEmployeeDetails}
                  empDet={empDet}
                  setEmpDet={setEmpDet}
                  handleChangeChoices={handleChangeChoices}
                />
              }

            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab5)}  >
              {
                tabSelected == tab5 &&
                <Skills
                  userEmployee={userEmployee}
                />

              }

            </ShowTeamModalTab>
            <ShowTeamModalTab style={{ marginTop: "0" }} show={isCurrentTab(tab5)}  >
              {
                tabSelected == tab5 &&
                <Skills
                  choices={choices}
                  selectedId={selectedEmpId}
                  empId={empId}
                  skillDet={skillDet}
                  setSkillDet={setSkillDet}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  contextSubscription={contextSubscription}
                  rightPermission={rightPermission}
                  createPermissionSkills={createPermissionSkills}
                  updatePermissionSkills={updatePermissionSkills}
                  readPermissionSkills={readPermissionSkills}
                  userEmployee={userEmployee}
                />
              }

            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab6)} >
              {
                tabSelected == tab6 &&
                <RegularShifts
                  setErrorMessage={setErrorMessage}
                  isValid={isValid} ubmit
                  setLoadingSave={setLoadingSave}
                  setLoadingFinish={setLoadingFinish}
                  setShowForm={setShowForm}
                  showForm={showForm}
                  errorMessage={errorMessage}
                  setCheckRequires={setCheckRequires}
                  handleSubmit={handleSubmit}
                  errorResponse={errorResponse}
                  setErrorResponse={setErrorResponse}
                  textStatusMain={textStatus}
                  shiftModal={shiftModal}
                  setShiftModal={setShiftModal}
                  showError={showError}
                  loadingSave={loadingSave}
                  buttonIconMain={buttonIcon}
                  show
                  employeeId={Id}
                  selectedId={selectedEmpId}
                  empId={empId}
                  values={values}
                  setValues={setValues}
                  useInput={useInput}
                  choices={choices}
                  updateErrors={updateErrors}
                  errors={errors}
                  timeError={timeError}
                  setTimeError={setTimeError}
                  contextSubscription={contextSubscription}
                  createPermissionRegularShifts={createPermissionRegularShifts}
                  updatePermissionRegularShifts={updatePermissionRegularShifts}
                  readPermissionRegularShifts={readPermissionRegularShifts}
                  deletePermissionRegularShifts={deletePermissionRegularShifts}
                />
              }
            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab7)} >
              {
                tabSelected == tab7 &&
                <Notes
                  selectedId={selectedEmpId}
                  empId={empId}
                  notesDet={notesDet}
                  setNotesDet={setNotesDet}
                  contextSubscription={contextSubscription}
                  createPermissionNotes={createPermissionNotes}
                  updatePermissionNotes={updatePermissionNotes}
                  readPermissionNotes={readPermissionNotes}
                />
              }

            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab8)} >
              {
                tabSelected == tab8 &&
                <Files
                  selectedId={selectedEmpId}
                  empId={empId}
                  contextSubscription={contextSubscription}
                  createPermissionEmployeeFile={createPermissionEmployeeFile}
                  updatePermissionEmployeeFile={updatePermissionEmployeeFile}
                  readPermissionEmployeeFile={readPermissionEmployeeFile}
                  deletePermissionEmployeeFile={deletePermissionEmployeeFile}
                />
              }

            </ShowTeamModalTab>
            <ShowTeamModalTab show={isCurrentTab(tab11)} >
              {
                tabSelected == tab11 &&
                <TriageModal
                  selectedId={selectedEmpId}
                  empId={empId}
                  contextSubscription={contextSubscription}
                  createPermissionEmployeeFile={createPermissionEmployeeFile}
                  updatePermissionEmployeeFile={updatePermissionEmployeeFile}
                  readPermissionEmployeeFile={readPermissionEmployeeFile}
                  deletePermissionEmployeeFile={deletePermissionEmployeeFile}
                />
              }

            </ShowTeamModalTab>
            {readPermissionEmployeeExpensess && (
              <ShowTeamModalTab show={isCurrentTab(tab9)} >
                {
                  tabSelected == tab9 &&
                  <Expenses
                    choices={choices}
                    selectedEmpId={selectedEmpId}
                    empId={empId}
                    props={props}
                    createPermissionEmployeeExpensess={createPermissionEmployeeExpensess}
                    updatePermissionEmployeeExpensess={updatePermissionEmployeeExpensess}
                    readPermissionEmployeeExpensess={readPermissionEmployeeExpensess}
                    deletePermissionEmployeeExpensess={deletePermissionEmployeeExpensess}
                    contextSubscription={contextSubscription}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userEmployee={userEmployee}
                    rightPermission={rightPermission}
                  />
                }

              </ShowTeamModalTab>)}
            <ShowTeamModalTab show={isCurrentTab(tab10)} >
              {
                tabSelected == tab10 &&
                <CareForum
                  selectedEmpId={selectedEmpId}
                  contextChoices={choices}
                  name={rowData.full_name}
                  handleChangeChoices={handleChangeChoices}
                  allPermission={formsPermission}
                />
              }
            </ShowTeamModalTab>

            <ShowTeamModalTab show={isCurrentTab(tab12)} >
              {
                tabSelected == tab12 &&
                <AverageHours
                  selectedEmpId={selectedEmpId}
                  contextChoices={choices}
                />
              }

            </ShowTeamModalTab>

            <ShowTeamModalTab show={isCurrentTab(tab13)} >
              {
                tabSelected === tab13 &&
                <EmployeeHRTasks
                  selectedEmpId={selectedEmpId}
                  contextChoices={choices}
                  props={props}
                  readPermisstion={readPermissionEmployeeHrTask}
                  createPermisstion={createPermissionEmployeeHrTask}
                  deletePermisstion={deletePermissionEmployeeHrTask}
                  updatePermisstion={updatePermissionEmployeeHrTask}
                />
              }

            </ShowTeamModalTab>

            <ShowEmployeeNotesTab show={isCurrentTab(tab14)} >
              {
                tabSelected === tab14 &&
                <EmployeeCareNotes
                  selectedEmpId={selectedEmpId}
                  rowData={rowData}
                  props={props}
                  readPermission={readPermissionEmployeeCareNotes}
                  createPermission={createPermissionEmployeeCareNotes}
                  deletePermission={deletePermissionEmployeeCareNotes}
                  updatePermission={updatePermissionEmployeeCareNotes}
                />
              }

            </ShowEmployeeNotesTab>
            <ShowEmployeeNotesTab show={isCurrentTab(tab15)} >
              {
                tabSelected === tab15 &&
                <Rates
                  selectedId={selectedEmpId}
                  rowData={rowData}
                  props={props}
                  readPermission={readPermissionEmployeeRates}
                  createPermission={createPermissionEmployeeRates}
                  deletePermission={deletePermissionEmployeeRates}
                  updatePermission={updatePermissionEmployeeRates}
                  rightPermission={rightPermission}
                  filterAdmin={filterAdmin} 
                  filterSuperAdmin={filterSuperAdmin} 
                  contextSubscription={contextSubscription}
                />
              }
            </ShowEmployeeNotesTab>
                
           
          </div>
        </div>
        {!overlapModal ? null : (
          <SplitOverlapModal
            fullBind={fullBindOverlapModal}
            shiftOverlapData={shiftOverlapData}
            unassignedVisitList={unassignedVisitList}
            setOverlapData={setOverlapData}
            employees={choices.employee_listing}
            loadingOverlap={loadingOverlap}
            handleOverlapSubmit={handleOverlapSubmit}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            currentModal={currentModal}
            values={values}
          />
        )}
      </ComponentDynamic>
      {/* </ModalDynamic> */}
      {/* </ModalContainer> */}
    </>
  );
};

export default withRouter(Team);
