import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  FlexGrid3Container,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { AssignAllVisits, setShadowVisits } from "utils/api/SchedulerApi"
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import ToggleButton from 'components/SharedComponents/ToggleButton';

const AssignBulk = ({
  fullBind,
  employees = [],
  checkedRows = [],
  searchScheduler = () => {},
  closeAssignModal = () => {},
  actionType,
  choices,
  travelRate = [],
  setActionType = () => {},
  toast,
  rightClickSelectedId = "",
  setRightClickSeletcedId = () => {},
  setSelectedEvents = () => {}
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({validation: false});
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [visitFailData, setvisitFailData] = useState({});
  const [disableButton, setDisableButton] = useState([]);
  const [disableSave, setDisableSave] = useState(false)
  const alluseInp = {
    add_emp_hook: useInput('validation'),
}
console.log(values,"values")
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: actionType == 'Reassign' ?
        'Assign Visits' : actionType == 'shadow' ? 'Shadow Visits'
          : 'Observation Visits'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const handleSubmit = () => {
    
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setDisableSave(true)
    setErrorMessage('');
    let body = (actionType == 'Reassign') ? {
      visit_ids: rightClickSelectedId.length ? rightClickSelectedId.join(",") : checkedRows.join(","),
      employee_id: values.employee_id,
      validation: values.validation
    } : {
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows],
      employee_id: values.employee_id,
      travel_mode: values.travel_mode,
      travel_rate_card: values.travel_rate_card
    }
    actionType == 'Reassign' ?
      AssignAllVisits(body)
        .then(res => {
          if (res.statusCode == 400) {
            throw res.message
          }
          debugger
        let visitf = []
          if(!res.status && res.visitFail){
            
            checkedRows.forEach((item)=>{
              if(res.visitFail[item]){
                visitf.push({...res.visitFail[item], visit_id:item})
              }
            })
            setvisitFailData(visitf);
            setSaveButtonIcon(CROSS);
            setErrorMessage(res.message);
            setLoadingSave(false);
            setDisableSave(false)
          setTextStatus(ERROR);
          }else if(!res.status && !res.visitFail){
            setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(res.message)
          setDisableSave(false)
          }else{
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: `${res.message}`,
            });
            setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          closeAssignModal()
          setErrorMessage('')
          setDisableSave(false)
          setRightClickSeletcedId([]) 
        setSelectedEvents([])
          searchScheduler()
          }
          
          //setActionType('')
         
        })
        .catch(err => {
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(err)
          // closeAssignModal()
          
        })
      :
      actionType == 'shadow' ?
        setShadowVisits(body)
          .then(res => {
            if (res.statusCode == 400) {
              throw res.message
            }

            if (res.status == "True") {
              setSaveButtonIcon(CHECK);
              setTextStatus(SAVED);
              setLoadingSave(false);
              closeAssignModal()
              setErrorMessage('')
              setDisableSave(false)
              setRightClickSeletcedId([]) 
        setSelectedEvents([])
              //setActionType('')
              searchScheduler()
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `${res.message}`,
              });
            }
          })
          .catch(err => {
            setSaveButtonIcon(CROSS);
            setTextStatus(ERROR);
            setLoadingSave(false);
            setErrorMessage(err)
            setDisableSave(false)
            // closeAssignModal()
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: `${err}`,
            });
          })
        : setShadowVisits(body, 'Observation')
          .then(res => {
            if (res.statusCode == 400) {
              throw res.message
            }
            if (res.status == "True") {
              setSaveButtonIcon(CHECK);
              setTextStatus(SAVED);
              setLoadingSave(false);
              closeAssignModal()
              //setActionType('')
              setErrorMessage('')
              searchScheduler()
              setRightClickSeletcedId([]) 
        setSelectedEvents([])
              setDisableSave(false)
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `${res.message}`,
              });
            }
          })
          .catch(err => {
            setSaveButtonIcon(CROSS);
            setTextStatus(ERROR);
            setLoadingSave(false);
            setErrorMessage(err)
            setDisableSave(false)
            // closeAssignModal()
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: `${err}`,
            });
          })

  }

  const handleAction = (visit_temp, index) => {
    let body = (actionType == 'Reassign') ? {
      visit_ids: visit_temp ? visit_temp : '',
      employee_id: values.employee_id,
      validation: visit_temp ? false : values.validation
    } : {
      visit_ids: [...checkedRows],
      employee_id: values.employee_id,
      travel_mode: values.travel_mode,
      travel_rate_card: values.travel_rate_card
    }
      AssignAllVisits(body)
        .then(res => {
          if (res.statusCode == 400) {
            throw res.message
          }
        let visitf = []
          if(res.visitFail){
            checkedRows.forEach((item)=>{
              if(res.visitFail[item]){
                visitf.push({...res.visitFail[item], visit_id:item})
              }
            })
            setvisitFailData(visitf);
            console.log('visit', visitf)
          }else{
            setDisableButton([...disableButton, index + 1]);
          setErrorMessage('')
          }
          
          //setActionType('')
          searchScheduler()
        })
        .catch(err => {
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(err)
          // closeAssignModal()
        })
  }

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' pl-3 pr-3 pb-2'}>

        <FlexGrid3Container className={'w-100 mt-2'}>
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
               disabled={(visitFailData && visitFailData.length > 0)}
                required
                placeHolder={'Select Employee'}
                // width="145%"
                options={choices && choices?.employee_listing && [...choices?.employee_listing]}
                {...useInput(
                  `employee_id`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values?.employee_id : null
                }
                onChange={value =>
                  dropDownHandler(
                    { employee_id: value ? value : null },
                    'employee_id'
                 )
                }
              />
            </FlexGrid3Item>
            {(actionType === 'Reassign') &&
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
               <div className='d-flex'> 
                 <label style={{ fontSize: "0.875rem", marginRight: '7px', alignSelf: 'center' }}>Validation</label>
                <ToggleButton
                  label=" "
                  disabled={(visitFailData && visitFailData.length > 0)}
                  useInput={alluseInp.add_emp_hook}
                  id="test"
                // onClick={handelOverNight}
                /></div>
              </FlexGrid3Item>}
            {!(actionType === 'Reassign') && <div className='d-flex' style={{width:"672px"}} >
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
                <DropdownSearchable
               
                  required
                  placeHolder={'Travel method'}
                  //disabled={readOnly}
                  options={choices.travel_type}
                  selectedOption={values.travel_mode}
                  onChange={value =>
                    dropDownHandler({ travel_mode: value ? value : null }, 'travel_mode')
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item>
                <DropdownSearchable
                  //disabled={readOnly}
                  placeHolder={'Travel Rate'}
                  options={travelRate}
                  selectedOption={
                    values.travel_rate_card
                  }
                  onChange={value =>
                    dropDownHandler(
                      {
                        travel_rate_card: value ? value : null,
                      }, 'travel_rate_card'
                    )
                  }
                />
              </FlexGrid3Item>
            </div>}
          </FlexGrid3Container>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

           {!(visitFailData && visitFailData.length > 0) && <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ml-0 ml-md-3 mb-1'}>
                <PrimaryButtonForm minWidth="6rem"
                  onClick={handleSubmit}
                  disabled={
                    (actionType == 'shadow'
                      || actionType == "obvservation"
                      || actionType == 'Reassign')
                      ? disableSave : false}
                >
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>}
          </div>
            {visitFailData && visitFailData.length > 0 && visitFailData.map((item,index)=>{
              return(
                <div className='d-flex justify-content-between mt-1'>
                <div>{index+1}. {item.HEADING} -  {item.MESSAGE}</div>
                <div>
                <PrimaryButtonForm minWidth="6rem"
                 disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                  onClick={()=>handleAction(item.visit_id,index)}
                >
                  <span className={'mr-2'}>Force assign</span>
                </PrimaryButtonForm>
              </div>
                </div>
              )
            })}
          </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default AssignBulk;
