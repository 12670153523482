import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { SetActualBulkTime, SetBulkTimeRotaShiftWithOutShift, SetBulkTimeRotaShiftWithShift, SetReassignShift, SetRevertBulkClocked } from "utils/api/SchedulerApi"
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import moment from 'moment'
import EditEmployeeOverlapModal from './EditEmployeeOverlapModal';
import { useModal } from 'hooks/ModalHook';
import { seteEditEmployeeDetails, setSplitAvailability } from 'utils/api/RotaShift';
import { validateDate, validateTime } from 'shared/methods';
const ReassignShiftModal = ({
  fullBind,
  checkedRows,
  searchRotaShift,
  setAssignShift,
  employees,
  clearSelectedElId
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [shiftOverlapData, setOverlapData] = useState([])
  const [overlapMessage, setOverlapMessage] = useState('')
  const [loadingOverlap, setLoadingOverlap] = useState([])
  const [loadingProceed, setLoadingProceed] = useState([])
  const [disableButton, setDisableButton] = useState([])

  const {
    setValue: setEmpOverlapModal,
    value: empOverlapModal,
    fullBind: fullBindEmpOverlapModal,
  } = useModal(false);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Reassign Shift',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const handleTimeSelector = (e) => {
    values.actual_visit_time = moment(e.target.value).format("h:mm:ss")
    setValues({ ...values })
  }
  const handleSubmit = (force_submit, emp_id, detail, index) => {
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setErrorMessage('');
    let checkedShiftData = [];

    if (force_submit) {
      // detail.forEach((item) => {
        checkedShiftData.push(detail.detail_id)
      // })
    } else {
      checkedRows.forEach((item) => {
        checkedShiftData.push(item.id)
      })
    }

    let bodyShift = {
      employee_shift: [...checkedShiftData],
      employee: values.employee_id,
      ...forceUpdate
    }

    SetReassignShift(bodyShift)
      .then(res => {
        if ((res.status === 'FAIL') && res.message.shift_availabilities_dates) {
          setOverlapMessage(res.message.message)
          setOverlapData(res.message.shift_availabilities_dates)
          setLoadingSave(false);
          setEmpOverlapModal(true)
        } else {
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          setAssignShift(false)
          searchRotaShift()
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1)
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1])
          clearSelectedElId()
        }

      })
      .catch(err => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1)
        setLoadingProceed([...loadingArr]);
      })


  }


  const splitData = async (force_submit, employee_id, start_date, end_date, item, index) => {

    let detail_tem_id = [];

    item.details.forEach((val) => {
      detail_tem_id.push(val.detail_id);
    })

    let item_detail = {};
    for (let [key, value] of Object.entries(item.details_created)) {
      if(value === 'None'){
        item_detail[key] = null;
      }else {
        item_detail[key] = value;
      }
    }

    const availabilityValues = [];

    if (
      errors.length > 0 ||
      !item.new_employee_id ||
      (!item.start_date && !item.end_date) ||
      (!item.start_date_time && !item.end_date_time)
      // !validateDate(true, item.start_date, item.end_date) ||
      // !validateTime(
      //   true,
      //   item.start_date,
      //   item.end_date,
      //   item.start_date_time,
      //   item.end_date_time
      // )
    ) {
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    // setLoadingOverlap([...loadingOverlap, index + 1]);
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }

    let firstDayValue = start_date.split(" ")[0];
    // let firstDayValue = start_date[0].split(" ")[0];
    //changed the start  as its changed in splitOverlapModal.js
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    while (moment(firstDayValue).isSameOrBefore(end_date.split(" ")[0])) {
      // while (moment(firstDayValue).isSameOrBefore(end_date[0].split(" ")[0])) {
      //changed the end dtae as its changed in splitOverlapModal.js 
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(item.start_date_time, 'h:mma');
      const endTime = moment(item.end_date_time, 'h:mma');
      // const crossDays =
      // start_date.split(" ")[0]!== end_date.split(" ")[0] &&
      //   endTime.isSameOrBefore(beginningTime);

      // const start_date_format = createDateWithTime(
      //   firstDayValue,
      //   item.start + ':00'
      // );         
      const start_date_format = item.start_date_time
      const end_date_format = item.end_date_time
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: { ...item_detail
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];
      day_information.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });
      // const weeks = [...daysOfWeek]
      // const weekDays = weeks.filter(weeks => weeks.selected === true);
      //  const weekArray =[] ;
      //  weekDays.forEach(w=>{
      // weekArray.push(w.day);
      //  });
      //  const week_of_day = weekArray.join();

      let update_keys = {}

      if (item.idSelected) {
        update_keys = {
          update_detail: { ...my_days[0] },
          is_update: true,
        }
      } else {
        update_keys = {
          details: [...my_days],
        }
      }

      availabilityValues.push({

        // id: values.employee.map(employee=>employee.value),
        employee: employee_id,
        force: true,

        start_date: item.start_date_time,
        // start_date: start_date[0].split(" ")[0]  + " " + item.start + ":00",
        end_date: item.end_date_time,
        detail_id: [...detail_tem_id],
        // end_date: end_date[0].split(" ")[0] + " " + item.end + ":00",
        //changed the start +end dtae as its changed in splitOverlapModal.js
        travel_method: item.travel_method === "None" ? null : item.travel_method,
        travel_time: item.travel_time === "None" ? null : item.travel_time,
        shift_type: item.shift_type,
        shift_bill_rate: item.travel_rate === "None" ? null : item.travel_rate,
        is_overnight: item.is_overnight,
        ...update_keys
        // week_of_day:week_of_day
      });
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        return await setSplitAvailability(availabilities);
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await seteEditEmployeeDetails(details, availability_Id, item.detail_id);
      };
    };


    let savedAvailabilities = [];
    const resultAvailabilities = await Promise.all(
      availabilityValues.map(saveAvailability())
    )
      .then(result => {
        console.log(result, 75946)
        if (result[0].message) {
          //setIsDateAssigned(true);
          //assignMessageAvailableUnavailable(available, result);
          throw new Error();
        } else {
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
           let loadingArr = loadingOverlap.filter(ele => ele !== index + 1)
           setLoadingOverlap([...loadingArr]);
          setDisableButton([...disableButton, index + 1])
          return result;
        }
      })
      .catch(error => {
        setSaveButtonIcon('');
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1)
        setLoadingOverlap([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }

    savedAvailabilities.push(...resultAvailabilities);
    if (!values.idSelected) {
      savedAvailabilities.forEach((availability, index_availability) => {

        Promise.all(

          availabilityValues[index_availability].details.map(
            saveAvailabilitiesDetails(availability.id)
          )
        );
      });
    }
    setLoadingSave(false);
    searchRotaShift();
  };

  return (
    <ModalContainer max_width={'40%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' pl-3 pr-3 pb-3'}>
          <div className="mb-2">
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Select Employee'}
                // width="145%"
                options={employees}
                {...useInput(
                  `employee_id`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.employee_id : null
                }
                onChange={value =>
                  dropDownHandler(
                    { employee_id: value ? value : null },
                    'employee_id'
                  )
                }
              />
            </FlexGrid3Item>
          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem"
                  onClick={() => handleSubmit(false)}

                >
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
        {!empOverlapModal ? null : (
          <EditEmployeeOverlapModal
            fullBind={fullBindEmpOverlapModal}
            shiftOverlapData={shiftOverlapData}
            setOverlapData={setOverlapData}
            employees={employees}
            splitData={splitData}
            loadingOverlap={loadingOverlap}
            proccedData={handleSubmit}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            // currentModal={currentModal}
            values={values}
          />
        )}
      </ModalDynamic>
    </ModalContainer>
  );
}

export default ReassignShiftModal;
