import {
  EX_SMALL_COL,
    EX_SMALL_COL4,
    LARGE_COL,
    P_W_Zero,
    SMALL_COL,
    VERY_EX_SMALL_COL_CHECKBOX
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      id: 'checkbox',
      columnSelected: true,
      removeCusCol: true,
      className: 'grow-mobile',
      max_width: VERY_EX_SMALL_COL_CHECKBOX,
},
{
  label: 'Branch Name',
  name: 'client__full_name',
  status: 1,
  id: 'branch_name',
  columnSelected: true,
  custum_column: true,
  className: 'grow-mobile',
  max_width: LARGE_COL,
overlap: true
},
    {
        label: 'Client Name',
        name: 'client__full_name',
        status: 1,
        id: 'client_name',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      // {
      //   label: 'Client Status',
      //   name: 'client__full_name',
      //   status: 1,
      //   id: 'client_status',
      //   columnSelected: true,
      //   custum_column: true,
      //   className: 'grow-mobile',
      //   max_width: LARGE_COL,
      // overlap: true
      // },
    {
        label: 'Funder Name',
        name: 'funder_name',
        status: 1,
        id: 'funder_name',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Employee Name',
        name: 'employee__id',
        status: 0,
        id: 'employee_name',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
        overlap: true
      },
      {
        label: 'Service Category',
        name: 'visit__service_category__id',
        status: 0,
        id: 'service_category',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
  
      {
        label: 'Start Date',
        name: 'schedule_start_date',
        status: 1,
        id: 'start_date',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'End Date',
        name: 'schedule_end_date',
        status: 1,
        custum_column: true,
        id: 'end_date',
          columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
        overlap: true
      },
      {
        label: 'Schedule Start Time',
        name: 'schedule_start_time',
        status: 1,
        id: 'schedule_start_time',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Schedule End Time',
        name: 'schedule_end_time',
        status: 1,
        id: 'schedule_end_time',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
    {
      label: 'Schedule Duration(mm)',
      name: 'schedule_duration',
      status: 1,
      id: 'schedule_duration_hrs',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
        label: 'Actual Start Time',
        name: 'actual_start_time',
        status: 1,
        id: 'actual_start_time',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Actual End Time',
        name: 'actual_end_time',
        status: 1,
        id: 'actual_end_time',
        columnSelected: true,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
    {
      label: 'Actual Duration(mm)',
      name: 'actual_visit_duration',
      status: 1,
      id: 'actual_duration_hrs',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Rounded Duration(mm)',
      name: 'rounded_duration_hrs',
      status: 0,
      id: 'rounded_duration_hrs',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Banded Duration(mm)',
      name: 'banded_duration_hrs',
      status: 0,
      id: 'banded_duration_hrs',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Rounded Duration(hh)',
      name: 'rounded_duration_in_hrs_format',
      status: 0,
      id: 'rounded_duration_in_hrs_format',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Banded Duration(hh)',
      name: 'banded_duration_in_hrs_format',
      status: 0,
      id: 'banded_duration_in_hrs_format',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Status',
      name: 'visit_status',
      status: 0,
      id: 'visit_status',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Bill Pattern',
      name: 'bill_pattern',
      status: 0,
      id: 'bill_pattern',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'EVV Visit Confirmation',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'evv_visit_confirmation',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Funder Rate',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'funder_rate',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Funder Rate Card',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'funder_rate_card',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Scheduled Bill Rate',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'schedule_bill_rate',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Scheduled Bill Amt',
      name: 'visit__schedule_bill_amount',
      status: 1,
      id: 'schedule_bill_amount',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Actual Bill Rate',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'actual_bill_rate',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Actual Bill Amt',
      name: 'actual_bill_rate_amount',
      status: 1,
      id: 'actual_bill_amount',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Rounded Bill Amt',
      name: 'rounded_bill_amount',
      status: 1,
      id: 'rounded_bill_amount',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Banded Bill Amt',
      name: 'banded_bill_amount',
      status: 1,
      id: 'banded_bill_amount',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Bill Confirmation',
      name: 'Visit Billing Amt',
      status: 0,
      id: 'visit_bill_confirmation',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Cancelled Bill Percentage',
      name: 'cancelled_bill_percentage',
      status: 1,
      id: 'cancelled_bill_percentage',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
  ];

  export const headerColumnsSelector = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
        name: 'Client Name',
        id: 'client_name',
      },
    {
        name: 'Funder Name',
        id: 'funder_name',
      },
      {
        name: 'Employee Name',
        id: 'employee_name',
      },
      {
        name: 'Service Category',
        id: 'service_category',
      },
  
      {
        name: 'Start Date',
        id: 'start_date',
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
        name: 'Actual Start Time',
        id: 'actual_start_time',
      },
      {
        name: 'Actual End Time',
        id: 'actual_end_time',
      },
    {
      name: 'Actual Duration(mm)',
      id: 'actual_duration_hrs',
    },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Funder Rate',
      id: 'funder_rate',
    },
    {
      name: 'Funder Rate Card',
      id: 'funder_rate_card',
    },
    {
      name: 'Scheduled Bill Rate',
      id: 'schedule_bill_rate',
    },
    {
      name: 'Scheduled Bill Amt',
      id: 'schedule_bill_amount',
    },
    {
      name: 'Actual Bill Rate',
      id: 'actual_bill_rate',
    },
    {
      name: 'Actual Bill Amt',
      id: 'actual_bill_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'visit_bill_confirmation',
    }
  ];

  export const headerColumnsSelectorByDefault = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
        name: 'Client Name',
        id: 'client_name',
      },
    {
        name: 'Funder Name',
        id: 'funder_name',
      },
      {
        name: 'Employee Name',
        id: 'employee_name',
      },
      {
        name: 'Service Category',
        id: 'service_category',
      },
  
      {
        name: 'Start Date',
        id: 'start_date',
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
        name: 'Actual Start Time',
        id: 'actual_start_time',
      },
      {
        name: 'Actual End Time',
        id: 'actual_end_time',
      },
    {
      name: 'Actual Duration(min)',
      id: 'actual_duration_hrs',
    },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Funder Rate',
      id: 'funder_rate',
    },
    {
      name: 'Funder Rate Card',
      id: 'funder_rate_card',
    },
    {
      name: 'Scheduled Bill Rate',
      id: 'schedule_bill_rate',
    },
    {
      name: 'Scheduled Bill Amt',
      id: 'schedule_bill_amount',
    },
    {
      name: 'Actual Bill Rate',
      id: 'actual_bill_rate',
    },
    {
      name: 'Actual Bill Amt',
      id: 'actual_bill_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'visit_bill_confirmation',
    }
  ];


  