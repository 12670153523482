import React, { useState, useEffect } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumns';
import {
  P_W_Zero,
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  DayPill,
  ColsGrouper,

} from 'components/SharedComponents/Table/styles';
import _ from 'lodash'
import moment from 'moment'

const RotaListView = ({
  rotaEvents,
  setCheckedRows,
  checkedRows,
  setCheckedValue,
  checkedValue,
  choices,
  eventClickHandler,
  setShiftTypeChecked,
  shiftTypeChecked,
  displayMenu
}) => {
  console.log(rotaEvents, 75391)

  useEffect(()=>{
    setCheckedRows([])
    },[])

  const handleAllSelectChange = (valcheck) => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      rotaEvents.map((visit) => {
        checked.push(visit)
      })
    } else {
      rotaEvents.map((visit) => {
        checked.map((item, index) => {
          if (item.id === visit.id) {
            checked.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows(checked)
  }

  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      checked.push(visit)
    } else {
      checked.map((item, index) => {
        if (item.id === visit.id) {
          checked.splice(index, 1);
        }
      })
    }
    setCheckedRows(checked)

    //setClients(arr);
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el.id === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      rotaEvents.map((visit) => {
        if (!visitExists(visit.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleCheck = (visit) => {
    let check = false;
    if (checkedRows) {
      checkedRows.map((item) => {
        if (visit.id === item.id) {
          check = true
        }
      })
    }
    return check;
  }


  return (
  <>
          <div className='mt-5'>
          <ComponentDynamic
        loading={rotaEvents?false : true}>
        <Table
         headerColumns={headerColumns}
        handleAllSelectChange={handleAllSelectChange} 
        handleAllCheck={handleAllCheck}
           headerPadding={false} noEditOption={true} noNeed={false}>
           <div >
            {
              rotaEvents?.map((list,index)=>{
                    return (
                        <div onContextMenu={(e)=>{
                          e.preventDefault();
                          displayMenu(e, list.id, {x:0, y:0})
                        }}>
                        <Row bgColor Shadow>
                                <ColsGrouper
                                  className="d-flex"
                                >
                                     <Col
                                    max_width={EX_SMALL_COL4}
                                    Shrink={false}
                                    NoFlexGrow={true}
                                    reducePadding
                                    Capitalize
                                    Center
            
                                  >
                                    <input class="form-check-input" 
                                      type="checkbox" 
                                      checked={handleCheck(list)} 
                                      id="flexCheckDefault" 
                                      onChange={(val) => { handleSelectChange(val, list, index) }} 
                                    />
                                  </Col>
                                  <ColsGrouper
                    className="d-flex"
                    // onClick={() => {
                    //   eventClickHandler({id:visit.id}) 
                    // }}
                    >

                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.ListViewEmployeeName ?
                                list.ListViewEmployeeName
                                : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewShiftName ?
                                list.listViewShiftName
                                : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {/* {visit.start ? moment(visit.start).format('YYYY-MM-DD') : ''} */}
                              {list.listViewShiftType ? list.listViewShiftType : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewStartDate ? moment(list.listViewStartDate).format('YYYY-MM-DD') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewEndDate ? moment(list.listViewEndDate).format('YYYY-MM-DD') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewStartTime ? moment(list.listViewStartTime, 'HH:mm:ss').format('HH:mm') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewEndTime ? moment(list.listViewEndTime, 'HH:mm:ss').format('HH:mm') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.listViewTravelName ? list.listViewTravelName : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.actual_shift_start_time ? moment(list.actual_shift_start_time,'HH:mm:ss').format('HH:mm') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.actual_shift_end_time ? moment(list.actual_shift_end_time,'HH:mm:ss').format('HH:mm') : ''}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              overlap={true}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                            >
                              {list.shift_status_name ? list.shift_status_name : ''}
                            </Col>
                          </ColsGrouper>
                        </ColsGrouper>
                      </Row>
                    </div>
                  )
                })
              }
            </div>
          </Table>
        </ComponentDynamic>
      </div>
    </>
  )
}

export default RotaListView