import {
  LARGE_COL,
  SMALL_COL,
  P_W_Zero,
  VERY_EX_SMALL_COL_CHECKBOX
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    custum_column: true,
    id: 'checkbox',
    columnSelected: true,
    removeCusCol: true,
    className: 'grow-mobile',
    max_width: VERY_EX_SMALL_COL_CHECKBOX,
  },
  {
    label: 'Branch Name',
    name: 'branch_name',
    status: 0,
    custum_column: true,
    id: 'branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Name',
    name: 'employee_name',
    status: 0,
    custum_column: true,
    id: 'employee_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Status',
    name: 'employee_status',
    status: 0,
    custum_column: true,
    id: 'employee_status',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    custum_column: true,
    id: 'client_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  ,
  {
    label: 'Client Branch',
    name: 'client_branch_name',
    status: 0,
    custum_column: true,
    id: 'client_branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Service Category',
    name: 'service_category',
    status: 0,
    custum_column: true,
    id: 'service_category',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Status',
    name: 'visit_status',
    status: 0,
    custum_column: true,
    id: 'visit_status',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Start Date',
    name: 'strt_date',
    status: 0,
    custum_column: true,
    id: 'schedule_start_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'schedule_end_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Start Time',
    name: 'sch_start_time',
    status: 0,
    custum_column: true,
    id: 'schedule_start_time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch End Time',
    name: 'sch_end_time',
    status: 0,
    custum_column: true,
    id: 'schedule_end_time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Sch Hrs',
    name: 'sch_duration',
    status: 0,
    custum_column: true,
    id: 'schedule_duration_hrs',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Start Time',
    name: 'act_start_time',
    status: 0,
    custum_column: true,
    id: 'actual_start_time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual End Time',
    name: 'act_end_time',
    status: 0,
    custum_column: true,
    id: 'actual_end_time',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Hrs',
    name: 'act_duration',
    status: 0,
    custum_column: true,
    id: 'actual_duration_hrs',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
 
  {
    label: 'Rounded Duration(hh)',
    name: 'rounded_duration_hrs',
    status: 0,
    custum_column: true,
    id: 'rounded_duration_hrs',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'banded Duration(hh)',
    name: 'banded_duration_hrs',
    status: 0,
    custum_column: true,
    id: 'banded_duration_hrs',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Shift Id',
    name: 'visit_shift_id',
    status: 0,
    custum_column: true,
    id: 'visit_shift_id',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'PostCode From',
    name: 'post_code_from',
    status: 0,
    custum_column: true,
    id: 'post_code_from',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'PostCode To',
    name: 'post_code_to',
    status: 0,
    custum_column: true,
    id: 'post_code_to',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Pattern',
    name: 'pay_pattern',
    status: 0,
    id: 'pay_pattern',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'EVV Visit Confirmation',
    name: 'Visit Billing Amt',
    status: 0,
    custum_column: true,
    id: 'evv_visit_confirmation',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Employee Pay Rate Card',
    name: 'employee_pay_rate_card',
    status: 0,
    custum_column: true,
    id: 'employee_pay_rate_card',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Pay Rate',
    name: 'sch_visit_amt',
    status: 0,
    custum_column: true,
    id: 'schedule_pay_rate',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Pay Rate',
    name: 'act_visit_amt',
    status: 0,
    custum_column: true,
    id: 'actual_pay_rate',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Pay Amt',
    name: 'sch_gross_pay',
    status: 0,
    custum_column: true,
    id: 'schedule_pay_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Pay Amt',
    name: 'act_gross_pay',
    status: 0,
    custum_column: true,
    id: 'actual_pay_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Rounded Pay Amount',
    name: 'rounded_pay_amount',
    status: 0,
    custum_column: true,
    id: 'rounded_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Banded pay Amount',
    name: 'banded_pay_amount',
    status: 0,
    custum_column: true,
    id: 'banded_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Pay Confirmation',
    name: 'Visit Billing Amt',
    status: 0,
    custum_column: true,
    id: 'visit_pay_confirmation',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Cancelled Pay Percentage',
    name: 'cancelled_pay_percentage',
    status: 0,
    custum_column: true,
    id: 'cancelled_pay_percentage',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
];

export const headerColumnsSelector = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Hrs',
    id: 'actual_duration_hrs',
  },
  {
    name: 'EVV Visit Confirmation',
    id: 'evv_visit_confirmation',
  },
  {
    name: 'Employee Rate Card',
    id: 'employee_rate_card',
  },
  {
    name: 'Sch Pay Rate',
    id: 'schedule_pay_rate',
  },
  {
    name: 'Act Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Pay Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Pay Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Visit Pay Confirmation',
    id: 'visit_pay_confirmation',
  }
];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Hrs',
    id: 'actual_duration_hrs',
  },
  {
    name: 'EVV Visit Confirmation',
    id: 'evv_visit_confirmation',
  },
  {
    name: 'Employee Rate Card',
    id: 'employee_rate_card',
  },
  {
    name: 'Sch Pay Rate',
    id: 'schedule_pay_rate',
  },
  {
    name: 'Act Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Pay Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Pay Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Visit Pay Confirmation',
    id: 'visit_pay_confirmation',
  }
];
