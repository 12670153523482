import React, { useRef, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import ReactHtmlParser from 'react-html-parser';
import Model from 'react-body-highlighter';
import { attachImage } from 'utils/api/FormDataApi';
import { pickFile } from 'utils/files';
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles';
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GrTableAdd } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import { DOWNLOAD, INFO_ICON } from 'utils/constants/icons';
import { ItemTypes } from 'components/DashboardComponents/FormBuilder/ItemTypes';
import {
  CustomNumberInput,
  DropDown,
  DropDownForChoices,
  DropDownMultiSelectList,
  DropDownTableValue,
  setTag
} from './FormComponents';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';

// global utilities
const renderCols = counts => {
  switch (counts) {
    case '1':
      return '12';
    case '2':
      return '6';
    case '3':
      return '4';
    case '4':
      return '3';
    case '5':
      return '2';
    case '6':
      return '2';
    default:
      return '3';
  }
};
function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}
const styles = {
  sigImage: {
    backgroundSize: '200px 50px',
    width: '250px',
    height: '100px',
    backgroundColor: 'white',
    border: '1px solid grey',
    marginLeft: '20px',
  },
};

const ComponentRenderer = ({
  item,
  index,
  readOnly,
  jsonData,
  setJsonData,
  contextChoices,
  setSignature,
  setSignatureItem,
  setModalBodyMap,
  setPopUpData,
  setSearchValueRich,
  setOpenSignature,
  loadingExport
}) => {

  // table component utility
  const addNewRows = index => {
    let newData = jsonData.map((j_val, i) => {
      if (i === index) {
        j_val.rows = [...j_val.rows, {}];
      }
      return j_val;
    });
    setJsonData(newData);
  };
  // bodymap component utility
  const getBodyData = body_data => {
    const listOfBodyParts = [
      'trapezius',
      'upper-back',
      'lower-back',
      'chest',
      'biceps',
      'triceps',
      'forearm',
      'back-deltoids',
      'front-deltoids',
      'abs',
      'obliques',
      'adductor',
      'hamstring',
      'quadriceps',
      'abductors',
      'calves',
      'gluteal',
      'head',
      'neck',
      'knees',
      'left-soleus',
      'right-soleus',
    ];
    const muscleFromBody = body_data?.every(item =>
      listOfBodyParts.includes(item)
    );
    const data = [
      { name: '', muscles: muscleFromBody && body_data ? [...body_data] : [] },
    ];
    return data;
  };
  // config for jodit editor
  const config = useMemo(
    () => ({
      readonly: readOnly,
    }),
    []
  );
  // file upload component utility
  const handlePickFile = async (evt, index) => {
    const result = await pickFile(evt);
    const photoUrl = await attachImage({ file: result.file });
    if (photoUrl.message.file) {
      // setUploadedFile(photoUrl.message.file);
      const data = jsonData.map((j_val, newIndex) => {
        if (newIndex === index) {
          return {
            ...j_val,
            value: photoUrl.message.file,
            placeholder: result.file.name,
          };
        }
        return j_val;
      });
      setJsonData(data);
    }
  };

  const renderTableRow = (val, item, table_index, type, label) => {
    if (!val) return null;
    switch (val.type) {
      case ItemTypes.TextInput:
        return (
          <div className="w-100">
            {!loadingExport ?
              <input
                type="text"
                id="fname"
                name="fname"
                className="w-100"
                disabled={readOnly}
                value={type == 'table' ? val[val.column_name] : val.value}
                placeholder={val.placeholder}
                style={{
                  padding: 13,
                  borderRadius: 6,
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: '#c8c8c8',
                }}
                onChange={event => {
                  let json_data = jsonData.map(j_val => {
                    if (j_val.name == 'Table') {
                      let r_data =
                        j_val.rows &&
                        j_val.rows.map((r_val, index_r) => {
                          console.log(r_val, val, 'ttttt');
                          if (
                            index_r === table_index &&
                            val.label === r_val.label
                          ) {
                            r_val[val.column_name] = event.target.value;
                          }
                          return r_val;
                        });
                      let data = { ...j_val, rows: r_data };
                      return data;
                    } else {
                      let r_data =
                        j_val.cols &&
                        j_val.cols.map((r_val, index_r) => {
                          console.log(r_val, val, 'ttttt2');
                          if (
                            index_r === table_index &&
                            val.label === r_val.label &&
                            label === j_val.label
                          ) {
                            r_val.value = event.target.value;
                          }
                          return r_val;
                        });
                      let data = { ...j_val, cols: r_data };
                      return data;
                    }
                    // return j_val
                  });

                  setJsonData([...json_data]);
                }}
              />
              :
              <div style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                <div style={{ minHeight: "2.5rem", whiteSpace: "pre-wrap" }}>{type == 'table' ? val[val.column_name] : val?.value || val.placeholder}</div>
              </div>
            }
          </div>
        );
      case ItemTypes.Dropdown:
        console.log(ItemTypes.Dropdown,val, item,'dropdown')
        return (
          <div className="w-100">
            <DropDownTableValue
              disabled={readOnly}
              type={type}
              className="w-100"
              setJson={setJsonData}
              contextChoices={contextChoices}
              json={jsonData}
              item={val}
              rowData={item}
              minWidth={'100%'}
              index={table_index}
              label={label}
            />
          </div>
        );
      case ItemTypes.Heading:
        return <div className="w-100">{renderMainItemComponent(val)}</div>;
      case ItemTypes.numberInput:
        return (
          <div className="w-100">
            <CustomNumberInput
              // next line not in client
              disabled={readOnly}
              className="w-100"
              aria-label="Demo number input"
              placeholder={val.placeholder}
              value={type == 'table' ? val[val.column_name] : val.value}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val.rows &&
                      j_val.rows.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          console.log(r_val, val, item, 'ttttt');
                          r_val[val.column_name] = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val.cols &&
                      j_val.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          console.log(r_val, val, item, 'ttttt2');
                          r_val.value = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  // return j_val
                });

                setJsonData([...json_data]);
              }}
            />
          </div>
        );
      case ItemTypes.decimalInput:
        return (
          <div className="w-100">
            <input
              type="number"
              className="w-100"
              id="myInput"
              name="inputTable"
              disabled={readOnly}
              placeholder={val.placeholder}
              value={type == 'table' ? val[val.column_name] : val.value}
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val?.rows &&
                      j_val?.rows.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val[val.column_name] = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val?.cols &&
                      j_val?.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val.value = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  return j_val;
                });

                setJsonData([...json_data]);
              }}
            />
          </div>
        );
      case ItemTypes.multidropdown:
        return (
          <div className="w-100">
            {/* <DropDownTableOptions className='w-100' contextChoices={contextChoices} json={val} minWidth={'100%'} multiple={true} /> */}
            <DropDownTableValue
              disabled={readOnly}
              className="w-100"
              setJson={setJsonData}
              contextChoices={contextChoices}
              json={jsonData}
              item={val}
              rowData={item}
              minWidth={'100%'}
              index={table_index}
              multiple={true}
              type={type}
            />
          </div>
        );
      case ItemTypes.Switch:
        return (
          <div className="w-100">
            <FormControlLabel
              value="start"
              disabled={readOnly}
              control={<Switch disabled={readOnly} color="primary" />}
              label={
                <div>
                  <label
                    for="fname"
                    style={{ marginRight: 10, fontWeight: 'bold' }}>
                    {val.placeholder}:
                  </label>
                  {val.description && (
                    <p style={{ marginBottom: 8, fontSize: 12 }}>
                      {val.description}
                    </p>
                  )}
                </div>
              }
              style={{
                width: '100%',
                fontWeight: 'bold',
                justifyContent: 'flex-start',
              }}
              labelPlacement="top"
            />
          </div>
        );
      case ItemTypes.hyperLink:
        return (
          <div className="w-100">
            <p style={{ width: '100%', marginBottom: 8, fontSize: 12 }}>
              {renderMainItemComponent(val)}
            </p>
          </div>
        );
      case ItemTypes.radioGroup:
        return (
          <div className="w-100">
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              className="w-100">
              {val.options.map((val, index) => {
                return (
                  <FormControlLabel
                    disabled={readOnly}
                    key={index}
                    value={val.value}
                    control={<Radio />}
                    label={val.value}
                  />
                );
              })}
            </RadioGroup>
          </div>
        );
      case ItemTypes.checkbox:
        return (
          <div className="w-100">
            <FormGroup row className="w-100">
              {val.options.map((val, index) => {
                return (
                  <FormControlLabel
                    disabled={readOnly}
                    control={<Checkbox disabled={readOnly} />}
                    label={val.value}
                  />
                );
              })}
            </FormGroup>
          </div>
        );
      case ItemTypes.textArea:
        // conditional rendering because \n doesnt work during html2canvas
        return (
          <div className="w-100">
            {!loadingExport && 
            <TextField
              id="outlined-multiline-static"
              className="w-100"
              label={val.label}
              disabled={readOnly}
              multiline
              rows={val.html ? Number(val.html) : 2}
              value={type == 'table' ? val[val.column_name] : val?.value}
              placeholder={val.placeholder}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val.rows &&
                      j_val.rows.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val[val.column_name] = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val?.cols &&
                      j_val?.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val.value = event.target.value;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  return j_val;
                });

                setJsonData([...json_data]);
              }}
            // style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
            />}
            {
              loadingExport && <div style={{border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem"}}>
                  <div style={{minHeight: "2.5rem", whiteSpace: "pre-wrap"}}>{type == 'table' ? val[val.column_name] : val?.value || val.placeholder}</div>
                </div>
            }
          </div>
        );
      case ItemTypes.datePicker:
        let dateColumnValue =
          type == 'table' ? item[val.column_name] : val?.value;
        let dateValue =  isDateValid(dateColumnValue)
          ? new Date(dateColumnValue)
          : "";
          console.log(dateColumnValue,type,val,item, "tableee")
        return (
          <div className="formdata w-100">
            <ReactDatePicker
              dateFormat={val.format==="" ? undefined: val.format}
              showIcon
              disabled={readOnly}
              style={{
                width: '100%',
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              className="formDataDatePicker"
              icon="fa fa-calendar"
              label="Basic date picker"
              selected={dateValue}
              // selected={(item.value || val.column_name) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val.rows &&
                      j_val.rows.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val[val.column_name] = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val.cols &&
                      j_val.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val.value = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  // return j_val
                });

                setJsonData([...json_data]);
              }}
            />
          </div>
        );
      case ItemTypes.timePicker:
        let timeVolumnValue =
          type === 'table' ? item[val.column_name] : val?.value;
        let timeValue = isDateValid(timeVolumnValue)
          ? new Date(timeVolumnValue)
          : "";
        return (
          <div className="formdata w-100">
            <ReactDatePicker
              showIcon
              disabled={readOnly}
              style={{
                width: '100%',
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              showTimeSelect
              showTimeSelectOnly
              timeFormat={val.format==="" ? undefined: val.format}
              dateFormat={val.format==="" ? "p": val.format}
              className="formDataDatePicker"
              icon="fa fa-calendar"
              label="Basic date picker"
              selected={timeValue}
              // selected={(item.value || val.column_name) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val?.rows &&
                      j_val?.rows?.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val[val.column_name] = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val?.cols &&
                      j_val?.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val.value = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  // return j_val
                });

                setJsonData([...json_data]);
              }}
            />
          </div>
        );
      case ItemTypes.dateTime:
        let dateTimeColumnValue =
          type === 'table' ? item[val.column_name] : val?.value;
        let dateTimeValue = isDateValid(dateTimeColumnValue)
          ? new Date(dateTimeColumnValue)
          : "";
        let dateFormat = val.format;
        let timeFormat = "";
        if (val.format) {
          timeFormat = val.format.split(" ")[1];
          if (val.format.split(" ")[2])
            timeFormat = timeFormat+" "+val.format.split(" ")[2]
        } else timeFormat = undefined;
        return (
          <div className="w-100">
            <ReactDatePicker
              className="w-100"
              disabled={readOnly}
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              showTimeSelect
              dateFormat={val.format==="" ? "Pp": dateFormat}
              timeFormat={timeFormat}
              // value={(type == "table") ? item[val.column_name] : item.value}
              selected={dateTimeValue}
              // selected={(item[val.column_name] || item.value) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
              onChange={event => {
                let json_data = jsonData.map(j_val => {
                  if (j_val.name == 'Table') {
                    let r_data =
                      j_val.rows &&
                      j_val.rows.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val[val.column_name] = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, rows: r_data };
                    return data;
                  } else {
                    let r_data =
                      j_val.cols &&
                      j_val.cols.map((r_val, index_r) => {
                        if (
                          index_r === table_index &&
                          val.label === r_val.label
                        ) {
                          r_val.value = event;
                        }
                        return r_val;
                      });
                    let data = { ...j_val, cols: r_data };
                    return data;
                  }
                  // return j_val
                });

                setJsonData([...json_data]);
              }}
            />
          </div>
        );
      case ItemTypes.signature:
        return (
          <div className="w-100">
            <Button
              disabled={readOnly}
              onClick={() => {
                setOpenSignature(true);
                setSignatureItem({ item: val, index: table_index, childOf: type});
              }}
              variant={val.html || "outlined"}>
              {val.label || "Add signature"}
            </Button>
            {val.value ? (
              <img alt="sign" className={styles.sigImage} src={val.value} />
            ) : null}
          </div>
        );
      // these are not present in main renderer
      case 'Text':
        return (
          <div className="w-100">
            <label className="w-100" for="fname" style={{}}>
              {val.value}
            </label>
          </div>
        );
      case 'Choice Dropdown':
        return (
          <div className="w-100">
            <DropDownTableValue
              disabled={readOnly}
              type={type}
              className="w-100"
              setJson={setJsonData}
              contextChoices={contextChoices}
              json={jsonData}
              item={val}
              rowData={item}
              minWidth={'100%'}
              index={table_index}
            />
          </div>
        );
      // no cases were present here for richTextHtmlInput, richTextHtmlHeading, photoAttach, fileUpload, fileDownload, bodyMapDisplay, bodyMapInput
      default:
        break;
    }
  };

  const renderMainItemComponent = item => {
    switch (item.type) {
      case ItemTypes.Heading:
        return setTag({ tag: item.html, label: item.value });
      case ItemTypes.TextInput:
        if (!loadingExport) {
          return (
            <input
              disabled={readOnly}
              type="text"
              id="text1"
              name="text1"
              placeholder={item.placeholder}
              value={item.value}
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
                flex: 1,
              }}
              onChange={text => {
                const data = jsonData.map((val, newIndex) => {
                  if (newIndex === index) {
                    return {
                      ...val,
                      value: text.target.value,
                    };
                  }
                  return val;
                });
                setJsonData(data);
              }}
            />
          )
        } else {
          return (
            <div style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
              <div style={{ minHeight: "2.5rem", whiteSpace: "pre-wrap" }}>{item?.value || item.placeholder}</div>
            </div>
          )
        }
      case ItemTypes.numberInput:
        return (
          <CustomNumberInput
            // next line not in client
            disabled={readOnly}
            className="w-100"
            placeholder={item.placeholder}
            value={item.value}
            onChange={event => {
              const data = jsonData.map((j_val, newIndex) => {
                if (newIndex === index) {
                  return {
                    ...j_val,
                    value: event.target.value,
                  };
                }
                return j_val;
              });
              setJsonData(data);
            }}
          />
        );
      case ItemTypes.decimalInput:
        return (
          <input
            disabled={readOnly}
            type="number"
            className="w-100"
            // onkeydown="if(this.key==='.'){this.preventDefault();}"
            id="inputdecimal"
            name="inputDecimal"
            placeholder={item.placeholder}
            value={item.value}
            style={{
              padding: 13,
              borderRadius: 6,
              borderWidth: 0.5,
              borderStyle: 'solid',
              borderColor: '#c8c8c8',
            }}
            onChange={event => {
              const data = jsonData.map((j_val, newIndex) => {
                if (newIndex === index) {
                  return {
                    ...j_val,
                    value: event.target.value,
                  };
                }
                return j_val;
              });
              setJsonData(data);
            }}
          />
        );
      case ItemTypes.Dropdown:
        return (
          <DropDown
            disabled={readOnly}
            minWidth={'100%'}
            jsonData={jsonData}
            setJsonData={setJsonData}
            json={item}
            index={index}
          />
        );
      case ItemTypes.multidropdown:
        return (
          <DropDownMultiSelectList
            disabled={readOnly}
            minWidth={'100%'}
            jsonData={jsonData}
            setJsonData={setJsonData}
            json={item}
            index={index}
            multiple={true}
          />
        );
      case ItemTypes.Switch:
        return (
          <FormControlLabel
            value="start"
            disabled={readOnly}
            control={
              <Switch
                color="primary"
                checked={item?.value}
                disabled={readOnly}
                onChange={item => {
                  const data = jsonData.map((val, newIndex) => {
                    if (newIndex === index) {
                      return {
                        ...val,
                        value: item.target.checked,
                      };
                    }
                    return val;
                  });
                  setJsonData(data);
                }}
              />
            }
            label={''}
            style={{
              fontWeight: 'bold',
              justifyContent: 'left',
              marginLeft: '0px',
              marginTop: '5px',
            }}
            labelPlacement="start"
          />
        );
      case ItemTypes.hyperLink:
        return <a href={`${item.value}`}>{item.placeholder}</a>;
      case ItemTypes.button:
        return (
          <Button disabled={readOnly} variant={item.html}>
            {item.label}
          </Button>
        );
      case ItemTypes.radioGroup:
        return (
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            value={item?.value}
            onChange={nextValue => {
              console.log('radio', nextValue.target.value);
              const data = jsonData.map((val, newIndex) => {
                if (newIndex === index) {
                  return {
                    ...val,
                    value: nextValue.target.value,
                  };
                }
                return val;
              });
              setJsonData(data);
            }}>
            {item.options.map((val, index) => {
              return (
                <FormControlLabel
                  disabled={readOnly}
                  key={index}
                  value={val.value}
                  control={<Radio />}
                  label={val.value}
                />
              );
            })}
          </RadioGroup>
        );
      case ItemTypes.buttonGroup:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            {item.options.map((val, index) => {
              return (
                <Button
                  disabled={readOnly}
                  key={index}
                  variant={val.type}
                  style={{ marginRight: 5 }}>
                  {val.value}
                </Button>
              );
            })}
          </div>
        );
      case ItemTypes.checkbox:
        return (
          <FormGroup row>
            {item.options.map((val1, index2) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={readOnly}
                      checked={item.value.includes(val1.value) ? true : false}
                      key={index2}
                      onChange={text => {
                        const data = jsonData.map((val, newIndex) => {
                          if (newIndex === index) {
                            if (val.value.includes(val1.value)) {
                              const array = val.value;
                              const index_arr = array.indexOf(val1.value);
                              array.splice(index_arr, 1);
                              let final_val = array;
                              return {
                                ...val,
                                value: final_val,
                              };
                            } else {
                              return {
                                ...val,
                                value: val.value
                                  ? [...val.value, val1.value]
                                  : [val1.value],
                              };
                            }
                          }
                          return val;
                        });
                        setJsonData(data);
                      }}
                    />
                  }
                  label={val1.value}
                />
              );
            })}
          </FormGroup>
        );
      case ItemTypes.textArea:
        // conditional rendering because \n doesnt work during html2canvas
        if(!loadingExport){
        return (
          <TextField
            id="outlined-multiline-static"
            label={item.label}
            sx={{
              '& legend': { display: 'none' },
              '& .MuiInputLabel-shrink': {
                opacity: 0,
                transition: 'all 0.2s ease-in',
              },
            }}
            multiline
            rows={Number(item.html)}
            style={{ width: '100%' }}
            disabled={readOnly}
            placeholder={item.placeholder}
            value={item?.value}
            onChange={text => {
              const data = jsonData.map((val, newIndex) => {
                if (newIndex === index) {
                  return {
                    ...val,
                    value: text.target.value,
                  };
                }
                return val;
              });
              setJsonData(data);
            }}
          />
        )} else {
          return (
          <div style={{border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem"}}>
            <div style={{minHeight: "2.5rem", whiteSpace: "pre-wrap"}}>{item?.value || item.placeholder}</div>
          </div>
        )}
      case ItemTypes.table:
        return (
          <div style={{ border: '2px solid #fafafc' }}>
            <Table
              headerColumns={
                item.cols
                  ? item.cols.map(val => ({
                    label: val.column_name,
                    name: '',
                    status: 0,
                    className: 'grow-mobile',
                    max_width: LARGE_COL,
                  }))
                  : []
              }
              // callBackQuerySort={setQuerySort}
              headerPadding={false}
              handleAllCheck={() => { }}
              noEditOption
              noNeed={true}
              handleAllSelectChange={() => { }}
              noFade={true}>
              <div>
                {item.rows &&
                  item.rows.map((table_d, table_index) => {
                    return (
                      <div>
                        <Row bgColor>
                          <ColsGrouper className="d-flex">
                            {item.cols &&
                              item.cols.map((val, i) => {
                                return (
                                  <>
                                    <Col max_width={LARGE_COL} Center>
                                      {renderTableRow(
                                        val,
                                        table_d,
                                        table_index,
                                        'table',
                                        item.label
                                      )}
                                    </Col>
                                  </>
                                );
                              })}
                            <Col Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  // toDeleteClient(client.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>
                          </ColsGrouper>
                        </Row>

                        <div className="mb-1" />
                      </div>
                    );
                  })}
              </div>
            </Table>
            {!readOnly && (
              <PrimaryButtonForm
                minWidth="1rem"
                onClick={() => addNewRows(index)}>
                <FaPlus />
              </PrimaryButtonForm>
            )}
          </div>
        );
      case ItemTypes.section:
        return (
          <div className="row pt-2">
            {item.cols &&
              item.cols.map((sec_d, index) => {
                if (!sec_d) return null;
                return (
                  <div className={`col-${renderCols(item.sectionColsCount)}`}>
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      <div className="w-100 d-flex justify-content-between">
                        <label
                          for="fname"
                          style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                          {sec_d.label}
                          <span style={{ color: ERR_COLOR }}>
                            {sec_d.required && '*'}
                          </span>
                        </label>
                        {sec_d?.instruction && (
                          <Tooltip title={sec_d.instruction}>
                            <IconButton size={'small'}>{INFO_ICON}</IconButton>
                          </Tooltip>
                        )}
                      </div>
                      {sec_d.description && (
                        <p style={{ marginBottom: 8, fontSize: 12 }}>
                          {sec_d.description}
                        </p>
                      )}
                    </div>
                    <div className="w-100 my-1">
                      {renderTableRow(sec_d, sec_d, index, 'section', item.label)}
                    </div>
                    {/* {sec_d.sectionRowCount} */}
                  </div>
                );
              })}
          </div>
        );
      case ItemTypes.choices:
        return (
          <DropDownForChoices
            disabled={readOnly}
            jsonData={jsonData}
            setJsonData={setJsonData}
            json={item}
            index={index}
          />
        );
      case ItemTypes.datePicker:
        return (
          <div className="formdata w-100">
            <ReactDatePicker
              dateFormat={item.format==="" ? undefined: item.format}
              disabled={readOnly}
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              className="formDataDatePicker"
              selected={
                isDateValid(item?.value) ? new Date(item.value) : ""
              }
              onChange={event => {
                const data = jsonData.map((val, newIndex) => {
                  if (newIndex === index) {
                    return {
                      ...val,
                      value: event,
                    };
                  }
                  return val;
                });
                setJsonData(data);
              }}
              label="Basic date picker"
            />
          </div>
        );
      case ItemTypes.timePicker:
        return (
          <div className="formdata w-100">
            <ReactDatePicker
              timeFormat={item.format==="" ? undefined: item.format}
              dateFormat={item.format==="" ? "p": item.format}
              disabled={readOnly}
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              showTimeSelect
              showTimeSelectOnly
              className="formDataDatePicker"
              label="Basic date picker"
              selected={
                isDateValid(item?.value) ? new Date(item.value) : ""
              }
              onChange={event => {
                const data = jsonData.map((val, newIndex) => {
                  if (newIndex === index) {
                    return {
                      ...val,
                      value: event,
                    };
                  }
                  return val;
                });
                setJsonData(data);
              }}
            />
          </div>
        );
      case ItemTypes.dateTime:
        let dateFormat = item.format;
        let timeFormat = "";
        if (item.format) {
          timeFormat = item.format.split(" ")[1];
          if (item.format.split(" ")[2])
            timeFormat = timeFormat+" "+item.format.split(" ")[2]
        } else timeFormat = undefined
        return (
          <div className="formdata w-100">
            <ReactDatePicker
              style={{
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
              dateFormat={item.format==="" ? "Pp": dateFormat}
              timeFormat={timeFormat}
              showTimeSelect
              className="formDataDatePicker"
              selected={
                isDateValid(item?.value) ? new Date(item.value) : ""
              }
              onChange={event => {
                const data = jsonData.map((val, newIndex) => {
                  if (newIndex === index) {
                    return {
                      ...val,
                      value: event,
                    };
                  }
                  return val;
                });
                setJsonData(data);
              }}
            />
          </div>
        );
      case ItemTypes.signature:
        return (
          <div>
            {!readOnly && (
              <Button
                onClick={() => {
                  setOpenSignature(true);
                  setSignatureItem({
                    item: item,
                    index: index,
                    childOf: null
                  });
                }}
                variant={item.html || "outlined"}>
                {item.label}
              </Button>
            )}
            {/* <div>
                           <Icon>
                                  <RiDeleteBinLine />
                                </Icon> */}
            {item.value ? (
              <img className={styles.sigImage} src={item.value} />
            ) : null}
            {/* </div> */}
          </div>
        );
      case ItemTypes.richTextHtmlInput:
        return (
          <RichTextEditor
            value={item.value || ''}
            tabIndex={''} // tabIndex of textarea
            onChange={event => {
              const data = jsonData.map((val, newIndex) => {
                if (newIndex === index) {
                  return {
                    ...val,
                    value: event,
                  };
                }
                return val;
              });
              setJsonData(data);
            }}
            config={config}
          />
        );
      case ItemTypes.richTextHtmlHeading:
        return <div style={{ width: '100%' }}>{ReactHtmlParser(item.label)}</div>;
      case ItemTypes.photoAttach:
        return <img style={{ width: '100%', height: '100%' }} src={item.value} />;
      case ItemTypes.fileUpload:
        return (
          <div>
            <UploadPhotoContainer className="d-flex align-items-center mt-2">
              <UploadInput
                onInput={e => {
                  handlePickFile(e, index);
                }}
                onClick={event => {
                  event.target.value = null;
                }}
                type="file"
                // accept={'.png,.jpg,.jpeg'}
                id="upload-file"
                name="upload-file"
                placeholder={'Upload File'}
              />
              {!loadingExport && !readOnly && (
                <>
                  <FiUpload />
                  <label htmlFor="upload-file">Upload File</label>
                </>
              )}
            </UploadPhotoContainer>
            {item.placeholder && (
              <div className="copy-list mt-1 download- w-100">
                <a
                  className="copy-item "
                  style={{ justifyContent: 'left' }}
                  target="_blank"
                  href={item.value}>
                  <span className="mr-2">{DOWNLOAD}</span>
                  {item.placeholder}
                </a>
              </div>
            )}
          </div>
        );
      case ItemTypes.fileDownload:
        return (
          <div className="copy-list mt-1 download- w-100">
            <a
              className="copy-item "
              style={{ justifyContent: 'left' }}
              target="_blank"
              href={item.value}>
              <span className="mr-1">{DOWNLOAD}</span>
              {item.placeholder}
            </a>
          </div>
        );
      case ItemTypes.bodyMapDisplay:
        return (
          <>
            <input
              type="text"
              id="fname"
              name="fname"
              value={item?.title?.join(',')}
              placeholder={item.placeholder}
              style={{
                width: '100%',
                padding: 13,
                borderRadius: 6,
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c8c8c8',
              }}
            />

            <div className="d-flex justify-content-center w-100">
              {(item.view == 'front' || item.view == 'both') && (
                <Model
                  data={getBodyData(item.title)}
                  style={{ width: '16rem', padding: '1rem' }}
                  // onClick={handleClick}
                  type={'anterior'}
                />
              )}
              {(item.view == 'back' || item.view == 'both') && (
                <Model
                  data={getBodyData(item.title)}
                  style={{ width: '16rem', padding: '1rem' }}
                  // onClick={handleClick}
                  type={'posterior'}
                />
              )}
            </div>
          </>
        );
      case ItemTypes.bodyMapInput:
        return (
          <PrimaryButtonForm
            minWidth="6rem"
            onClick={() => setModalBodyMap(true)}>
            <FaPlus />
            Create body map
          </PrimaryButtonForm>
        );
      default:
        return null;
    }
  };

  // this is first step
  switch (item.type) {
    case ItemTypes.TextInput:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div style={{ flex: 1, marginBottom: '8px' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ fontSize: 12, padding: 0, margin: 0 }}>
                {item.description}
              </p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.numberInput:
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: item.description ? 'flex-start' : 'center',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label
              for="labelhead"
              style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.decimalInput:
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: item.description ? 'flex-start' : 'center',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.multidropdown:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label for="fname" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.hyperLink:
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: item.description ? 'flex-start' : 'center',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item?.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {
            <p style={{ marginBottom: 8, fontSize: 12 }}>
              {renderMainItemComponent(item)}
            </p>
          }
        </div>
      );
    case ItemTypes.Dropdown:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label
              for="dropdown"
              style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item?.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.table:
      return (
        <div className="w-100">
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.section:
      return (
        <div style={{ border: '2px solid #fafafc', width: '100%' }}>
          <div className="text-left ml-2">
            <label
              for="fname"
              style={{
                marginTop: 'auto',
                fontWeight: 'bold',
                fontSize: '20px',
              }}>
              {item.label}
            </label>
          </div>
          <div className="container">{renderMainItemComponent(item)}</div>
        </div>
      );
    case ItemTypes.Switch:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div style={{ flex: 1 }}>
            <div className="w-100 d-flex">
              <label
                for="toggle"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p
                style={{
                  marginBottom: 8,
                  fontSize: 12,
                  margin: 0,
                  padding: 0,
                }}>
                {item.description}
              </p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.button:
      return renderMainItemComponent(item);
    case ItemTypes.radioGroup:
      return (
        <FormControl
          style={{
            alignSelf: 'flex-start',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '8px',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="radio"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </FormControl>
      );
    case ItemTypes.buttonGroup:
      return renderMainItemComponent(item);
    case ItemTypes.checkbox:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '8px',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="cehckbox"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.textArea:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginLeft: '8px',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="textarea"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.choices:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="choices"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.datePicker:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label for="date" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.timePicker:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '8px',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label for="time" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.dateTime:
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '8px',
          }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="date_time"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.signature:
      return renderMainItemComponent(item);
    case ItemTypes.richTextHtmlInput:
      return (
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            <div className="w-100 d-flex justify-content-between">
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
              <Tooltip title={'Search'}>
                <IconButton
                  size={'small'}
                  onClick={() => {
                    setSearchValueRich(true);
                    setPopUpData(item);
                  }}>
                  <GrTableAdd />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.richTextHtmlHeading:
      return renderMainItemComponent(item);
    case ItemTypes.photoAttach:
      return (
        <div>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          <div>{renderMainItemComponent(item)}</div>
        </div>
      );
    case ItemTypes.fileUpload:
      return (
        <div>
          <div style={{ textAlign: 'left' }}>
            <div className="w-100 d-flex justify-content-between">
              <label
                for="fname"
                style={{ marginTop: 'auto', fontWeight: 'bold' }}>
                {item.label}
                <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
              </label>
              {item?.instruction && (
                <Tooltip title={item.instruction}>
                  <IconButton size={'small'}>{INFO_ICON}</IconButton>
                </Tooltip>
              )}
            </div>
            {item.description && (
              <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
            )}
          </div>
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.fileDownload:
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label for="fname" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {item.placeholder && renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.bodyMapDisplay:
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label for="fname" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    case ItemTypes.bodyMapInput:
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <div className="w-100 d-flex justify-content-between">
            <label for="fname" style={{ marginTop: 'auto', fontWeight: 'bold' }}>
              {item.label}
              <span style={{ color: ERR_COLOR }}>{item.required && '*'}</span>
            </label>
            {item?.instruction && (
              <Tooltip title={item.instruction}>
                <IconButton size={'small'}>{INFO_ICON}</IconButton>
              </Tooltip>
            )}
          </div>
          {item.description && (
            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
          )}
          {renderMainItemComponent(item)}
        </div>
      );
    // below is extra from dustbin
    case ItemTypes.Heading:
      return renderMainItemComponent(item);
    default:
      console.log("Shouldn't reach here ", item.type);
      return null;
  }
};

export default ComponentRenderer;
