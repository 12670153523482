import {
  LARGE_COL,
  SMALL_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
  P_W_Zero
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    id: 'checkbox',
    columnSelected: true,
    removeCusCol: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: VERY_EX_SMALL_COL_CHECKBOX,
  },
  {
    label: 'Branch Name',
    name: 'branch_name',
    status: 0,
    id: 'branch_name',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Name',
    name: 'employee_name',
    status: 0,
    id: 'employee_name',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Status',
    name: 'employee_status',
    status: 0,
    id: 'employee_status',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Shift Type',
    name: 'shift_type',
    status: 0,
    id: 'shift_type',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Type',
    name: 'pay_type',
    status: 0,
    id: 'pay_type',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Absence Type',
    name: 'absence_type',
    status: 0,
    custum_column: true,
    id: 'absence_type',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Status Id',
    name: 'visit_shift_id',
    status: 0,
    id: 'visit_shift_id',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Rate Card',
    name: 'employee_rate_card',
    status: 0,
    id: 'employee_rate_card',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Contracted Hours',
    name: 'cont_hours',
    status: 0,
    id: 'contracted_hours',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Service Category',
    name: 'service_category',
    status: 0,
    id: 'service_category',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    id: 'client_name',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Branch',
    name: 'client_branch_name',
    status: 0,
    id: 'client_branch_name',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Status',
    name: 'visit_status',
    status: 0,
    id: 'visit_status',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Start Date',
    name: 'strt_date',
    status: 0,
    id: 'start_date',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'end_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Start Time',
    name: 'sch_start_time',
    status: 0,
    id: 'schedule_start_time',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch End Time',
    name: 'sch_end_time',
    status: 0,
    id: 'schedule_end_time',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Sch Duration (hh)',
    name: 'sch_duration',
    status: 0,
    id: 'schedule_duration_hrs',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Duration(mm)',
    name: 'sch_duration',
    status: 0,
    id: 'schedule_duration',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Start Time',
    name: 'act_start_time',
    status: 0,
    id: 'actual_start_time',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual End Time',
    name: 'act_end_time',
    status: 0,
    id: 'actual_end_time',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Duration (hh)',
    name: 'act_duration',
    status: 0,
    id: 'actual_duration_hrs',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Duration (mm)',
    name: 'act_duration',
    status: 0,
    id: 'actual_duration',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Pattern',
    name: 'pay_pattern',
    status: 0,
    id: 'pay_pattern',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Pay Rate',
    name: 'schedule_pay_rate',
    status: 0,
    id: 'schedule_pay_rate',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Pay Rate',
    name: 'actual_pay_rate',
    status: 0,
    id: 'actual_pay_rate',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Pay Amt',
    name: 'sch_visit_amt',
    status: 0,
    id: 'schedule_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Pay Amt',
    name: 'act_visit_amt',
    status: 0,
    id: 'actual_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch manual Pay Amt',
    name: 'act_visit_amt',
    status: 0,
    id: 'schedule_manual_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act manual Pay Amt',
    name: 'act_visit_amt',
    status: 0,
    id: 'actual_manual_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Rounded Duration',
    name: 'act_duration',
    status: 0,
    id: 'rounded_duration',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Banded Duration',
    name: 'act_duration',
    status: 0,
    id: 'banded_duration',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Rounded Pay Amt',
    name: 'act_visit_amt',
    status: 0,
    id: 'rounded_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Banded Pay Amt',
    name: 'act_visit_amt',
    status: 0,
    id: 'banded_pay_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Code',
    name: 'travel_duration',
    status: 0,
    id: 'travel_code',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Mode',
    name: 'travel_duration',
    status: 0,
    id: 'travel_mode',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'PostCode From',
    name: 'travel_duration',
    status: 0,
    id: 'post_code_from',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'PostCode To',
    name: 'travel_duration',
    status: 0,
    id: 'post_code_to',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Rate Card',
    name: 'travel_rate_card',
    status: 0,
    id: 'travel_rate_card',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Time Pay Rate',
    name: 'travel_duration',
    status: 0,
    id: 'travel_time_pay_rate',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Mileage Rate',
    name: 'travel_duration',
    status: 0,
    id: 'mileage_rate',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Hrs(hh)',
    name: 'travel_duration',
    status: 0,
    id: 'travel_time_duration_hrs',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Hrs(mm)',
    name: 'travel_duration',
    status: 0,
    id: 'travel_time_duration',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Distance (miles)',
    name: 'travel_distance',
    status: 0,
    id: 'travel_distance_miles',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Amt',
    name: 'travel_amount',
    status: 0,
    id: 'travel_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Mileage Amt',
    name: 'mileage_amt',
    status: 0,
    id: 'mileage_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Rate Card',
    name: 'actual_travel_rate_card',
    status: 0,
    id: 'actual_travel_rate_card',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Time Pay Rate',
    name: 'actual_travel_time_pay_rate',
    status: 0,
    id: 'actual_travel_time_pay_rate',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Mileage Rate',
    name: 'actual_mileage_rate',
    status: 0,
    id: 'actual_mileage_rate',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Time Duration(mm)',
    name: 'actual_travel_time_duration',
    status: 0,
    id: 'actual_travel_time_duration',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Time Duration(hh)',
    name: 'actual_travel_time_duration_hrs',
    status: 0,
    id: 'actual_travel_time_duration_hrs',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Amt',
    name: 'actual_travel_amount',
    status: 0,
    id: 'actual_travel_amount',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Travel Distance Miles',
    name: 'actual_travel_distance_miles',
    status: 0,
    id: 'actual_travel_distance_miles',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actaul Mileage',
    name: 'actual_mileage_amount',
    status: 0,
    id: 'actual_mileage_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Expense Amt',
    name: 'absence_pay_amount',
    status: 0,
    id: 'expense_amount',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Pay Rate Card',
    name: 'actual_pay_rate_card',
    status: 0,
    id: 'actual_pay_rate_card',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Notes',
    name: 'clock_out_notes',
    status: 0,
    id: 'clock_out_notes',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Amount Diff',
    name: 'amount_diff',
    status: 0,
    id: 'amount_diff',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Duration Diff',
    name: 'duration_diff',
    status: 0,
    id: 'duration_diff',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Cancelled pay Percentage',
    name: 'cancelled_pay_percentage',
    status: 0,
    id: 'cancelled_pay_percentage',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Non-commute Scheduled Travel Amt',
    name: 'non_commute_schedule_travel_pay_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_pay_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Duration (Hrs)',
    name: 'non_commute_schedule_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Distance',
    name: 'non_commute_schedule_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Mileage Amt',
    name: 'non_commute_schedule_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Amt',
    name: 'non_commute_actual_travel_pay_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_pay_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Duration (Hrs)',
    name: 'non_commute_actual_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Distance',
    name: 'non_commute_actual_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Mileage Amt',
    name: 'non_commute_actual_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
];


export const headerColumnsSelector = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Contr acted Hours',
    id: 'contracted_hours',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Hrs',
    id: 'actual_duration_hrs',
  },
  {
    name: 'Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Visit Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Visit Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Travel Hrs',
    id: 'travel_time_duration_hrs',
  },
  {
    name: 'Travel Distance (miles)',
    id: 'travel_distance_miles',
  },
  {
    name: 'Travel Amt',
    id: 'travel_amount',
  },
  {
    name: 'Mileage Amt',
    id: 'mileage_amount',
  },
  {
    name: 'Absence Amt',
    id: 'absence_amount',
  },
  {
    name: 'Office Amt',
    id: 'office_amount',
  },
  {
    name: 'OnCall Amt',
    id: 'oncall_amount',
  },
  {
    name: 'Sch Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Amt',
    id: 'actual_pay_amount',
  }
];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Contr acted Hours',
    id: 'contracted_hours',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Hrs',
    id: 'actual_duration_hrs',
  },
  {
    name: 'Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Visit Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Visit Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Travel Hrs',
    id: 'travel_time_duration_hrs',
  },
  {
    name: 'Travel Distance (miles)',
    id: 'travel_distance_miles',
  },
  {
    name: 'Travel Amt',
    id: 'travel_amount',
  },
  {
    name: 'Mileage Amt',
    id: 'mileage_amount',
  },
  {
    name: 'Absence Amt',
    id: 'absence_amount',
  },
  {
    name: 'Office Amt',
    id: 'office_amount',
  },
  {
    name: 'OnCall Amt',
    id: 'oncall_amount',
  },
  {
    name: 'Sch Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Amt',
    id: 'actual_pay_amount',
  }
];

