import React, { useState, useEffect, Fragment } from 'react';
import { FiltersContainerRotaShifts, FiltersLeftGroup, FiltersTypesContainerRotaShifts, Title } from 'shared/styles/constants/tagsStyles';
import { withRouter, useParams } from 'react-router';
import { useForm } from 'hooks/FormHook';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { CANCELLED_VIST, GRAY_820, OBSERVATION_VISIT, PURPLE, SCHEDULER_ASSIGNED, SCHEDULER_UNASSIGNED, SHADOW_VISIT, TRAVEL_TIME_COLOR, WAIT_TIME_COLOR, WHITE } from 'shared/styles/constants/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PlannerCalendar from 'components/SharedComponents/PlannerCalendar';
import { getActiveEmp, getPlannerCompareSchVisits, getScheduler } from 'utils/api/SchedulerApi';
import moment from 'moment';
import TimelineCalendar from 'components/SharedComponents/TimelineCalendar';
import { AppContext, BranchItemsContext } from 'context';
import { useContext } from 'react';
import { SchedulerContainer } from '../styles';
import { getRotaShifts } from 'utils/api/RotaShift';
import queryString from 'query-string';
import { TYPE_AVAILABLE, TYPE_UNAVAILABLE } from 'utils/constants/rotaShift';
import { useMemo } from 'react';
import { BIKE_ICON, CAR_ICON, FOOT_ICON, High_PRIORITY, Home_ICON, LOW_PRIORITY, MEDIUM_PRIORITY } from 'utils/constants/icons';
import { extendMoment } from 'moment-range'
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';
import MultiSelectDropdown from 'components/SharedComponents/MultiSelectDropdown';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { scheduler_choices } from 'utils/choiceConstant';

let VISITS_ASSIGNED = 0;
let VISITS_UNASSIGNED = 0;
let TOTAL_VISITS = 0;

// let VISITS_ASSIGNED_PLANNED = 0;
// let VISITS_UNASSIGNED_PLANNED = 0;
// let TOTAL_VISITS_PLANNED = 0;

const Planner = (props) => {
  const { id: selectedId } = useParams();
  let query_params_data = queryString.parse(props.location.search)
  const { values, setValues, useInput, errors, setFormErrors, setCheckRequires } = useForm({});
  const history = useHistory()
  const [eventsPlanned, setPlannedEvents] = useState([]);
  const [eventsSchedular, setEventsSchedular] = useState([]);
  const [resourcesSchedular, setResourcesSchedular] = useState([]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const { plannerPropsData, contextChoices, contextChoicesMap, handleGetChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const [filterExcludeCheck, setFilterExcludeCheck] = useState({ client: false, employee: false });
  const [loading, setLoading] = useState(true)
  const [empData, setEmpData] = useState([]);
  const [evvVal, setEvvVal] = useState(null);
  const [employeeForFilter, setEmployeesForFilter] = useState([])
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [runRoutesclientFilter, setRunRoutesclientFilter] = useState([]);
  const [jobDepartmentFilter, setJobDepartmentFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [branchRunSchedularFilter, setBranchRunSchedularFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [runNameFilter, setRunNameFilter] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [clients, setClients] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [jobDepartment, setJobDepartment] = useState([]);
  const [branch, setBranch] = useState([]);
  const [employeeResource, setEmployeeResource] = useState('')
  const [employeeResourcePlanned, setEmployeeResourcePlanned] = useState('')
  const [scheduledData, setScheduledData] = useState([]);
  const [rotashiftData, setRotashiftData] = useState([]);
  const [plannerDataState, setPlannerDataState] = useState([]);
  const [decreaseWidthPlan, setDecreaseWidthPlan] = useState(false);
  const [decreaseWidthSch, setDecreaseWidthSch] = useState(false);
  const [employeeVisitData, setEmployeeVisitData] = useState([])
  const [employeeVisitDataPlanned, setEmployeeVisitDataPlanned] = useState([])

  const [waitHoursBetweenVisit, setWaitHoursBetweenVisit] = useState(0);
  const [waitHoursExcludeMoreThanTwo, setWaitHoursExcludeMoreThanTwo] = useState(0);
  const [waitHoursCapacity, setWaitHoursCapacity] = useState(0);

  const [waitHoursBetweenVisitPlanned, setWaitHoursBetweenVisitPlanned] = useState(0);
  const [waitHoursExcludeMoreThanTwoPlanned, setWaitHoursExcludeMoreThanTwoPlanned] = useState(0);
  const [waitHoursCapacityPlanned, setWaitHoursCapacityPlanned] = useState(0);

  useEffect(() => {
    handleGetChoices(scheduler_choices)
  }, [])
  const [timeCodes, setTimeCodes] = useState([
    { id: '111', name: "Visits" },
    { id: '222', name: 'Travel' },
    { id: '333', name: 'Wait' }
  ]);
  const [visitStatusData, setVisitStatusData] = useState([]);
  const [runNameData, setRunNameData] = useState([])
  const [serviceFilter, setServiceFilter] = useState([])

  const new_moment = extendMoment(moment);

  const hitApiAfterClear = (value) => {
    setClearAll(value)
  }

  const handleChangeFilterCheckbox = (e, dropdown_name) => {
    setFilterExcludeCheck({ ...filterExcludeCheck, [dropdown_name]: e.target.checked })
  }

  const employeeArray = () => {
    if (contextChoices.employee_listing && (contextChoices.employee_listing.length > 0)) {
      let temp = contextChoices.employee_listing.map((val) => ({ id: val.id, name: val.full_name, branch: val.branch }))
      return temp
    } else {
      return []
    }
  };

  const emp_arr = useMemo(() => employeeArray(), [contextChoices.employee_listing]);

  const getEmployeeActiveData = async () => {
    const date_val = moment(values.end_date).format('YYYY-MM-DD')
    const response = await getActiveEmp(date_val);
    if (response.data) {
      setEmpData(response.data || [])

      //activeEmployeeResourceFn(response.data)
    }
  }

  const activeEmployeeResourceFn = (res) => {
    let employeesResources = []
    res.forEach(employee => {
      if (values.branch && values.branch.length > 0) {
        let filtered_branch_data = []
        values.branch.forEach((a) => {
          const emp_branch = emp_arr.filter((item) => item.branch == a.id);
          filtered_branch_data = [...filtered_branch_data, ...emp_branch]
        })
        filtered_branch_data.forEach((val) => {
          if (val.id == employee.id) {
            employeesResources.push({
              id: employee.id,
              title: employee.full_name,
            })
          }
        })
      } else if (branchData && branchData.headerbranch && branchData.headerbranch.length > 0) {
        let filtered_branch_data = []
        branchData.headerbranch.forEach((a) => {
          const emp_branch = emp_arr.filter((item) => item.branch == a.id);
          filtered_branch_data = [...filtered_branch_data, ...emp_branch]
        })
        filtered_branch_data.forEach((val) => {
          if (val.id == employee.id) {
            employeesResources.push({
              id: employee.id,
              title: employee.full_name,
            })
          }
        })
      } else {
        employeesResources.push({
          id: employee.id,
          title: employee.full_name,
        })
      }
    });
    setResourcesSchedular([{ id: "0000", title: "Unassigned" }, ...employeesResources]);
  }

  useEffect(() => {
    let employees = [];
    if (contextChoices.employee_listing) {
      employees = contextChoices.employee_listing.map(employee => {
        return { id: employee.id, name: employee.full_name, branch: employee.branch };
      });


      setEmployeesForFilter([{ id: "none", name: "Unassigned" }, ...employees] || []);
    }

    let clients = [];

    if (contextChoices.client_listing) {
      clients = contextChoices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      });

      setClients(clients || []);
    }
    setVisitStatusData(contextChoices.visit_status && contextChoices.visit_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunRouteData(contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setJobDepartment(contextChoices.job_category && contextChoices.job_category.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setBranch(contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunNameData(contextChoices.run_view && contextChoices.run_view.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
  }, [contextChoices])

  useEffect(() => {
    if (empData && (empData.length > 0)) {
      activeEmployeeResourceFn(empData)
    }
  }, [branchData, emp_arr.length, empData.length])

  useEffect(() => {
    if (values.end_date) {
      getEmployeeActiveData()
    }
  }, [values.end_date])

  useEffect(() => {
    if (values.start_date && values.end_date) {
      if (plannerPropsData.events && (plannerPropsData.events.length > 0) && plannerPropsData.resources && (plannerPropsData.resources.length > 0)) {
        setEventsSchedular(plannerPropsData.events || [])
        setResourcesSchedular(plannerPropsData.resources || [])
        if (plannerPropsData.planned_filter) {
          setValues({ ...values, ...plannerPropsData.planned_filter })
        }
      }
      searchScheduler();
      getPlannedVisitFn()
    } else {
      values.start_date = query_params_data.start;
      values.end_date = query_params_data.end;
      setValues({ ...values })
    }
  }, [   
    values.start_date,
    values.end_date
  ])

  const visitStatusColourClient = (detail) => {
    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              return '#ffa500'
            } else if (detail.visit_status_name === 'Missed') {
              return '#ff3b3b'
            } else {
              if (detail.doubleUp) {
                return '#499937'
              } else {
                if (detail.is_pinned_employee) {
                  return '#00FF7F'
                } else {
                  return SCHEDULER_ASSIGNED
                }
              }
            }
          } else {
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }



  }

  const visitStatusColourTeam = (detail, checkDesire) => {

    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              let sch_start = new Date(detail.start_date);
              let act_start = new Date(detail.actual_visit_start_time);
              let differenceValue = (sch_start.getTime() - act_start.getTime()) / 1000;
              differenceValue /= 60;
              let result = Math.abs(Math.round(differenceValue))

              let hms = evvVal?.early;   // your input string
              let a = hms?.split(':'); // split it at the colons

              // Hours are worth 60 minutes.
              let minutes = a && ((+a[0]) * 60 + (+a[1]));

              if ((sch_start > act_start) && (result > minutes)) {
                return '#f0b54a'
              } else {
                return '#ffa500'
              }
            } else {
              if (detail.incompatibility) {
                return '#912929'
              } else {
                if (detail.visit_status_name === 'Missed') {
                  return '#ff3b3b'
                } else {
                  // Create date from input value
                  let inputDate = new Date(detail.start_date);
                  // Get today's date
                  let todaysDate = new Date();
                  // call setHours to take the time out of the comparison
                  let differenceValue = (todaysDate.getTime() - inputDate.getTime()) / 1000;
                  differenceValue /= 60;
                  let result = Math.abs(Math.round(differenceValue))
                  let hms = evvVal?.late;   // your input string
                  let a = hms?.split(':'); // split it at the colons

                  // Hours are worth 60 minutes.
                  let minutes = a && ((+a[0]) * 60 + (+a[1]));

                  if ((inputDate < todaysDate) && (result > minutes)) {
                    return '#f74a4a'
                  } else {
                    if (detail.doubleUp) {
                      return '#5aa7f2'
                    } else {
                      if (detail.is_pinned_employee) {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          return '#ADD8E6'
                        }
                      } else {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          return '#1E90FF'
                        }
                      }
                    }
                  }
                }
              }
            }

          } else {
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }
  }

  const rotashiftColor = (name) => {
    switch (name) {
      case "Absence":
        return '#343434'
      case "Unavailability":
        return '#828282'
      case "On Call":
        return '#FF8C00'
      case "Car - Driver - Shift":
        return '#e0b16e'
      case "Bank-Flexible":
        return '#de5285'
      default:
        return '#e89b2e'
    }
  }

  useEffect(() => {
    PlannedDataFn(plannerDataState, rotashiftData)
  }, [plannerDataState, rotashiftData, empData])

  useEffect(() => {
    updateSchData(scheduledData, rotashiftData, 'team')
  }, [scheduledData, rotashiftData, empData])

  const PlannedDataFn = (res_data, rota_data) => {
    console.log('shivate');
    let data = []
    let travel_data = []
    let shift_availability = {}
    let final_data = []
    let final_wait_data = []
    let wait_hr_capacity = []
    let visit_tooltip_data = empData.map((item) => {
      let obj = {
        employee_id: item.id,
        employee_name: item.full_name,
        contracted_no_hours: item.contractedHours,
        max_hour_per_week: item.maxHourPerWeek,
        actual_travel_miles: 0,
        duration: 0,
        no_visits: 0,
        remaining_contracted_desired_hrs: 0,
        remaining_desired_hrs: 0,
        schedule_travel_time: 0,
        total_visit_hours: 0
      }
      return obj;
    })

    res_data.map((detail) => {
      // let resData = employeeVisitData && employeeVisitData.find(det => det.employee_id === detail.employee_id)
      let checkDesire = 0;
      // if (resData && (resData.remaining_desired_hrs < 1)) {
      //   checkDesire = true;
      // } else {
      //   checkDesire = false;
      // }
      // BELOW TWO ARE NAN BECAUSE KEY DOESNT EXIST
      let visit_duration = ((new Date(`${detail.schedule_end_date} ${detail.schedule_end_time}`)).getTime() - (new Date(`${detail.schedule_start_date} ${detail.schedule_start_time}`)).getTime()) / 3600000;
      let travel_duration = ((new Date(detail.schedule_end_datetime)).getTime() - (new Date(detail.schedule_start_datetime)).getTime()) / 3600000;

      visit_tooltip_data = visit_tooltip_data.map((item) => {
        if (item.employee_id == detail.employee) {
          item.total_visit_hours = item.total_visit_hours + visit_duration;
          item.schedule_travel_time = item.schedule_travel_time + travel_duration;
          item.actual_travel_miles = item.actual_travel_miles + (detail.schedule_travel_miles ? detail.schedule_travel_miles : 0);
          item.no_visits = item.no_visits + 1
        }
        return item;
      })
      data.push({
        id: detail.id,
        resourceId: detail.employee ? detail.employee : "0000",
        typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
        unassigned: detail.employee ? false : true,
        title: detail.full_name ? detail.full_name : "Unassigned",
        backgroundColor: visitStatusColourTeam(detail, checkDesire),
        borderColor: visitStatusColourTeam(detail, checkDesire),
        textColor: detail.employee ? GRAY_820 : WHITE,
        start: (detail.schedule_start_date && detail.schedule_start_time) ? `${detail.schedule_start_date} ${detail.schedule_start_time}` : detail.start_datetime,
        end: (detail.schedule_end_date && detail.schedule_end_time) ? `${detail.schedule_end_date} ${detail.schedule_end_time}` : detail.end_datetime,
        client: detail.full_name,
        clientId: detail.client_id,
        employeeId: detail.employee,
        serviceId: detail.client_service_visit_id,
        employee_name: detail.employee_fullname,
        visit_status: detail.visit_status_name,
        run_view_id: detail.run_view_id,
        visit: true,
        icon: Home_ICON,
        address: detail.address,
        zipcode: detail.journey_end_zipcode,
        visit_type: detail.visit_type_name,
        orderEvent: 2,
        service_category: detail.service_category,
        gender: detail.gender ? contextChoices && contextChoices.gender && contextChoices.gender.find(gender => gender.id == detail.gender).name : null,
        priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
          || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
          || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
          : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
            || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
            ? LOW_PRIORITY : MEDIUM_PRIORITY,
         earliest_start_time: detail.earliest_start_time,
         latest_start_time: detail.latest_start_time,
        start_travel_time: detail.schedule_start_datetime,
        end_travel_time: detail.schedule_end_datetime,
        //run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
        doubleUp: detail.doubleUp,

      });
      // WILL NOT RUN BECAUSE KEY NOT EXISTS
      if (detail.employee && detail.schedule_start_datetime !== detail.schedule_end_datetime) {
        travel_data.push({
          id: detail.id,
          resourceId: detail.employee,
          typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
          title: detail.full_name,
          backgroundColor: TRAVEL_TIME_COLOR,
          borderColor: TRAVEL_TIME_COLOR,
          textColor: GRAY_820,
          start: detail.schedule_start_datetime,
          end: detail.schedule_end_datetime,
          client: detail.full_name,
          clientId: detail.client_id,
          employeeId: detail.employee,
          serviceId: detail.client_service_visit_id,
          openModal: true,
          traveltime: true,
          travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
          orderEvent: 1,
          travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
            : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON
        });
      }
      // WILL NOT RUN BECAUSE KEY NOT EXISTS
      if (detail.employee && detail.tolerance_durations && detail.tolerance_durations >= 60) {
        let toleranceEnd = detail.schedule_end_datetime && moment(detail.schedule_end_datetime.split("T")[0] + " " + detail.schedule_end_datetime.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
          .format('YYYY-MM-DDTHH:mm:ss');
        //                                  //console.log(detail.schedule_end_datetime+' -- '+toleranceEnd, 'toleranceEnd-1014');
        travel_data.push({
          id: detail.id,
          resourceId: detail.employee,
          typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
          title: detail.full_name,

          backgroundColor: '#FFFF00',
          borderColor: '#FFFF00',
          textColor: GRAY_820,
          start: detail.schedule_end_datetime,
          end: toleranceEnd,
          client: detail.full_name,
          clientId: detail.client_id,
          employeeId: detail.employee,
          serviceId: detail.client_service_visit_id,
          openModal: true,
          traveltime: true,
          travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
          orderEvent: 3,
          travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
            : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
          typeVisit: "run"
        });
      }
    })
    rota_data && rota_data.map(rotaShift => {
      const employe_name = rotaShift.employee && contextChoicesMap.employee_listing?.[rotaShift.employee]?.full_name
      if (rotaShift.schedule_travel_start_datetime && rotaShift.schedule_travel_end_datetime) {
        travel_data.push({
          id: rotaShift.id,
          resourceId: rotaShift.employee,
          typeEvent: rotaShift.is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
          title: employe_name,
          backgroundColor: TRAVEL_TIME_COLOR,
          borderColor: TRAVEL_TIME_COLOR,
          textColor: GRAY_820,
          start: rotaShift.schedule_travel_start_datetime,
          end: rotaShift.schedule_travel_end_datetime,
          client: rotaShift.full_name,
          clientId: rotaShift.client,
          employeeId: rotaShift.employee,
          serviceId: rotaShift.client_service_visit_id,
          openModal: true,
          traveltime: true,
          travel_mode_name: rotaShift.travel_mode_name,
          travel_code: rotaShift.travel_code && rotaShift.travel_code.toUpperCase(),
          travelMode: rotaShift.travel_mode_name === 'Walk' ? FOOT_ICON
            : (rotaShift.travel_mode_name === 'Scooter' || rotaShift.travel_mode_name === 'Motorbike' || rotaShift.travel_mode_name === 'Bike') ? BIKE_ICON : CAR_ICON,

        });
      }
      if (rotaShift.shift_type_name == 'Availability' || rotaShift.shift_type_name == 'Shift') {
        let rotaShiftsData = { start: rotaShift.start_date, end: rotaShift.end_date, zipcode: rotaShift.employee_zipcode }
        if (shift_availability[rotaShift.employee]) {
          shift_availability[rotaShift.employee].push({ ...rotaShiftsData })
        } else {
          shift_availability[rotaShift.employee] = [{ ...rotaShiftsData }]
        }
      }
      // else {
      //   final_wait_data.push({
      //     id: rotaShift.id,
      //     resourceId: rotaShift.employee,
      //     backgroundColor: rotashiftColor(rotaShift.shift_type_name),
      //     borderColor: rotashiftColor(rotaShift.shift_type_name),
      //     title: rotaShift.shift_type_name ? rotaShift.shift_type_name : '',
      //     textColor: rotaShift.is_available ? GRAY_820 : WHITE,
      //     start: rotaShift.start_date,
      //     end: rotaShift.end_date,
      //     openModal: true,
      //     waittime: true
      //   })
      // }
    });
    final_data = [...data, ...travel_data, ...final_wait_data]
    let wait_time_temp = [];

    if (data && data.length > 0) {
      let temp_filter_data = data.filter((item) => item.employeeId);
      let temp_obj = {}

      temp_filter_data.forEach((item) => {
        if (temp_obj[item.resourceId]) {
          temp_obj[item.resourceId].push({ ...item })
        } else {
          temp_obj[item.resourceId] = [{ ...item }]
        }
      })

      console.log()


      Object.keys(shift_availability).map((key) => {
        shift_availability[key].forEach((shiftData) => {
          const shiftRange = new_moment.range(shiftData.start, shiftData.end);

          if (temp_obj[key]) {

            let sortedVisits = [];
            let sortedVisitsByEnd = [];
            temp_obj[key].forEach((visit) => {
              const visitRange = new_moment.range(visit.start_travel_time, visit.end);
              if (shiftRange.overlaps(visitRange) && (visit.start_travel_time)) {
                sortedVisits.push(visit);
                sortedVisitsByEnd.push(visit);
              }
            })

            sortedVisits = sortedVisits && sortedVisits.sort((a, b) => new Date(a.start_travel_time) - new Date(b.start_travel_time));

            sortedVisitsByEnd = sortedVisitsByEnd && sortedVisitsByEnd.sort((a, b) => new Date(a.end) - new Date(b.end));

            if (sortedVisits.length > 0) {
              if ((new Date(shiftData.start)).getTime() < (new Date(sortedVisits[0].start_travel_time)).getTime()) {
                wait_time_temp.push({
                  resourceId: key,
                  backgroundColor: WAIT_TIME_COLOR,
                  borderColor: WAIT_TIME_COLOR,
                  title: `${shiftData.zipcode} - ${sortedVisits[0].zipcode}`,
                  textColor: GRAY_820,
                  start: shiftData.start,
                  end: sortedVisits[0].start_travel_time,
                  openModal: true,
                  waittime: true,
                });
              }

              if ((new Date(shiftData.end)).getTime() > (new Date(sortedVisits[sortedVisits.length - 1].end)).getTime()) {
                wait_time_temp.push({
                  resourceId: key,
                  backgroundColor: WAIT_TIME_COLOR,
                  borderColor: WAIT_TIME_COLOR,
                  title: `${sortedVisits[sortedVisits.length - 1].zipcode} - ${shiftData.zipcode}`,
                  textColor: GRAY_820,
                  start: sortedVisits[sortedVisits.length - 1].end,
                  end: shiftData.end,
                  openModal: true,
                  waittime: true,
                });
              }
            }

            let wait_gap_visits = []
            let wait_gap_visits_less_two = []

            sortedVisits.map((event, i) => {
              if (i != 0) {
                let a = new Date(sortedVisits[i - 1].end)
                let b = new Date(sortedVisitsByEnd[i - 1].end)
                let dateEnd = null;
                if (b >= a) {
                  dateEnd = new Date(sortedVisitsByEnd[i - 1].end)
                } else {
                  dateEnd = new Date(sortedVisits[i - 1].end)
                }

                let gap = (new Date(event.start_travel_time)).getTime() - (dateEnd).getTime();
                if (gap > 60000) {
                  console.log('gap', gap)
                  let gap_in_hr = gap / 3600000;
                  wait_gap_visits.push(gap_in_hr);
                  if (!(gap_in_hr > 2)) {
                    wait_gap_visits_less_two.push(gap_in_hr)
                  }


                  wait_time_temp.push({
                    resourceId: key,
                    backgroundColor: WAIT_TIME_COLOR,
                    borderColor: WAIT_TIME_COLOR,
                    title: `${sortedVisits[i - 1].zipcode} - ${sortedVisits[i].zipcode}`,
                    textColor: GRAY_820,
                    start: b >= a ? sortedVisitsByEnd[i - 1].end : sortedVisits[i - 1].end,
                    end: event.start_travel_time,
                    openModal: true,
                    waittime: true,
                  });
                }
              }
            })
            console.log('gap travel hrs', wait_gap_visits, wait_gap_visits_less_two)
            if (wait_gap_visits.length > 0) {
              let aHours = wait_gap_visits.reduce((total, visit) => total + visit);
              aHours % 0.1 === 0
                ? setWaitHoursBetweenVisitPlanned(aHours)
                : setWaitHoursBetweenVisitPlanned(Math.round(aHours * 100) / 100);
            } else {
              setWaitHoursBetweenVisitPlanned(0);
            }

            if (wait_gap_visits_less_two.length > 0) {
              let aHours = wait_gap_visits_less_two.reduce((total, visit) => total + visit);
              aHours % 0.1 === 0
                ? setWaitHoursExcludeMoreThanTwoPlanned(aHours)
                : setWaitHoursExcludeMoreThanTwoPlanned(Math.round(aHours * 100) / 100);
            } else {
              setWaitHoursExcludeMoreThanTwoPlanned(0);
            }

          }
          else {
           
            let gap_capacity_temp = (new Date(shiftData.end)).getTime() - (new Date(shiftData.start)).getTime();
            wait_hr_capacity.push(gap_capacity_temp)
            //console.log(shiftData, "0000000shiftData")
            wait_time_temp.push({
              resourceId: key,
              backgroundColor: WAIT_TIME_COLOR,
              borderColor: WAIT_TIME_COLOR,
              title: `${shiftData.zipcode} - ${shiftData.zipcode}`,
              textColor: GRAY_820,
              start: shiftData.start,
              end: shiftData.end,
              openModal: true,
              waittime: true,
            });
          }
          if (wait_hr_capacity.length > 0) {
            let aHours = wait_hr_capacity.reduce((total, visit) => total + visit);
            aHours % 0.1 === 0
              ? setWaitHoursCapacityPlanned(aHours)
              : setWaitHoursCapacityPlanned(Math.round(aHours * 100) / 100);
          } else {
            setWaitHoursCapacityPlanned(0);
          }
        })
      })

    }
    let blank_emp = []
    let emp_count = 0;
    empData.forEach((val) => {
      let checkEmp = final_data.findIndex((item) => item.resourceId == val.id)
      let checkEmpCount = final_data.findIndex((item) => ((item.resourceId == val.id) && item.visit))
      if (checkEmp == -1) {
        blank_emp.push({
          resourceId: val.id,
          title: '',
          backgroundColor: 'rgba(54, 25, 25, .00004)',
          borderColor: 'rgba(54, 25, 25, .00004)',
          start: values.start_date,
          //end: moment(values.start_date).add(60, 'seconds'),                   
          allDay: true,
          blank: true
        })
      }
      if (!(checkEmpCount == -1)) {
        emp_count = emp_count + 1
      }
    })

    setEmployeeResourcePlanned(emp_count);
    if (values.employees && (values.employees.length > 0)) {
      let add_filtered_emp = []
      values.employees.map((val) => {
        let checkEmpBlank = blank_emp.findIndex((item) => item.resourceId == val.id)
        let check_val = filterExcludeCheck.employee ? (checkEmpBlank == -1) : !(checkEmpBlank == -1);
        if (check_val) {
          add_filtered_emp.push({
            resourceId: val.id,
            title: '',
            backgroundColor: 'rgba(54, 25, 25, .00004)',
            borderColor: 'rgba(54, 25, 25, .00004)',
            start: values.start_date,
            //end: moment(values.start_date).add(60, 'seconds'),                   
            allDay: true,
            blank: true
          })
        }
      })
      final_data = [...final_data, ...wait_time_temp, ...add_filtered_emp]
    } else {
      final_data = [...final_data, ...wait_time_temp, ...blank_emp]
    }
    setEmployeeVisitDataPlanned(visit_tooltip_data)
    setPlannedEvents(final_data || [])
    setLoading(false)
  }


  const getPlannedVisitFn = () => {
    const start_date_val = moment(values.start_date).format('YYYY-MM-DD')
    const end_date_val = moment(values.end_date).format('YYYY-MM-DD')
    getPlannerCompareSchVisits(start_date_val, end_date_val, selectedId)
      .then((res) => {
        setPlannerDataState(res.data)
        //PlannedDataFn(res.data , rotashiftData)
      })
  }




  const updateSchData = (sch_data, rota_shift_data, view) => {
    let final_wait_data = []

    let shift_availability = {}
    let data = []
    TOTAL_VISITS = 0;
    let travel_data = []
    let runViewData = {};
    let wait_hr_capacity = []

    let visit_tooltip_data = empData.map((item) => {
      let obj = {
        employee_id: item.id,
        employee_name: item.full_name,
        contracted_no_hours: item.contractedHours,
        max_hour_per_week: item.maxHourPerWeek,
        actual_travel_miles: 0,
        duration: 0,
        no_visits: 0,
        remaining_contracted_desired_hrs: 0,
        remaining_desired_hrs: 0,
        schedule_travel_time: 0,
        total_visit_hours: 0
      }
      return obj;
    })


    sch_data && sch_data.map(scheduler => {
      TOTAL_VISITS = TOTAL_VISITS + scheduler.visits.length;
      let uniquie_visit_id = []
      scheduler.visits.map(detail => {
        if (!uniquie_visit_id.find(id => id === detail.id)) {
          uniquie_visit_id.push(detail.id)
          if (detail.unassigned != undefined) {
            if (!detail.unassigned) {
              VISITS_ASSIGNED += 1;
            } else {
              VISITS_UNASSIGNED += 1;
            }
          }
        }

        if (view === 'client') {
          data.push({
            id: detail.id,
            resourceId: scheduler.id,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            title: detail.employee_id
              ? detail.employee_fullname
              : detail.is_visit_cancelled
                ? 'Cancelled'
                : 'Unassigned',
            unassigned: detail.employee_id ? false : true,
            backgroundColor: visitStatusColourClient(detail),
            borderColor: visitStatusColourClient(detail),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            constraint: {
              resourceIds: [scheduler.id],
            },
            start: detail.start_date,
            end: detail.end_date,
            client: scheduler.full_name,
            clientId: scheduler.id && scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            gender: detail.gender ? contextChoices.gender.find(gender => gender.id == detail.gender).name : null,
            visit_priority: detail.visit_priority ? contextChoices.visit_priority.find(p => p.id == detail.visit_priority).name : null,
            clientView: true,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            service_category: detail.service_category,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
            doubleUp: detail.doubleUp,

          });
        }
        else if (view === 'team') {
          // let resData = employeeVisitData && employeeVisitData.find(det => det.employee_id === detail.employee_id)
          let checkDesire = 0;
          // if (resData && (resData.remaining_desired_hrs < 1)) {
          //   checkDesire = true;
          // } else {
          //   checkDesire = false;
          // }

          let visit_duration = ((new Date(detail.end_date)).getTime() - (new Date(detail.start_date)).getTime()) / 3600000;
          let travel_duration = ((new Date(detail.end_travel_time)).getTime() - (new Date(detail.start_travel_time)).getTime()) / 3600000;

          visit_tooltip_data = visit_tooltip_data.map((item) => {
            if (item.employee_id == detail.employee_id) {
              item.total_visit_hours = item.total_visit_hours + visit_duration;
              item.schedule_travel_time = item.schedule_travel_time + travel_duration;
              item.actual_travel_miles = item.actual_travel_miles + (detail.schedule_travel_miles ? detail.schedule_travel_miles : 0);
              item.no_visits = item.no_visits + 1
            }
            return item;
          })


          data.push({
            id: detail.id,
            resourceId: detail.employee_id ? detail.employee_id : "0000",
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            unassigned: detail.employee_id ? false : true,
            title: scheduler.full_name ? scheduler.full_name : "Unassigned",
            backgroundColor: visitStatusColourTeam(detail, checkDesire),
            borderColor: visitStatusColourTeam(detail, checkDesire),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            serviceId: detail.client_service_visit_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            run_view_id: detail.run_view_id,
            visit: true,
            icon: Home_ICON,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            orderEvent: 2,
            service_category: detail.service_category,
            gender: detail.gender ? contextChoices && contextChoices.gender && contextChoices.gender.find(gender => gender.id == detail.gender).name : null,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            start_travel_time: detail.start_travel_time,
            end_travel_time: detail.end_travel_time,
            run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
            doubleUp: detail.doubleUp,

          });
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 1,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = detail.end_travel_time && moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');
            //                                  //console.log(detail.end_travel_time+' -- '+toleranceEnd, 'toleranceEnd-1014');
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,

              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 3,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run"
            });
          }
        }
        else {


          //  if (detail.is_regular_visit) {
          let runviewid = detail.is_regular_visit
            ? detail.run_view_id ? detail.run_view_id : "001"
            : "000"
          if (runViewData[runviewid] == null) {
            runViewData[runviewid] = [];
          }
          if (detail.start_travel_time != detail.end_travel_time) {
            runViewData[runviewid].push({ start: detail.start_travel_time, end: detail.end_travel_time });
          }
          runViewData[runviewid].push({ start: detail.start_date, end: detail.end_date });

          data.push({
            id: detail.id,
            resourceId: detail.is_regular_visit ?
              detail.run_view_id ? detail.run_view_id : "000" : null,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            unassigned: detail.employee_id ? false : true,
            title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
            backgroundColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            borderColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            visit: true,
            typeVisit: "run",
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
          });
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,

              typeVisit: "run"
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');
            //                                                   //console.log(detail.end_travel_time+' -- '+toleranceEnd, 'toleranceEnd-1095');

            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run"
            });
          }
          //  }
        }

      });
    });

    if (view === 'run') {
      Object.keys(runViewData).map((key) => {
        if (runViewData[key] && runViewData[key].length > 1) {
          let sortedviews = runViewData[key].sort((a, b) => new Date(a.start) - new Date(b.start));
          sortedviews.map((event, i) => {
            if (i != 0) {
              let gap = (new Date(event.start)).getTime() - (new Date(sortedviews[i - 1].end)).getTime();
              if (gap > 60000 && (sortedviews[i - 1].end.split('T')[0] == event.start.split('T')[0])) {

                final_wait_data.push({
                  resourceId: key,
                  backgroundColor: WAIT_TIME_COLOR,
                  borderColor: WAIT_TIME_COLOR,
                  title: `${sortedviews[i - 1].zipcode} - ${sortedviews[i].zipcode}`,
                  textColor: GRAY_820,
                  start: sortedviews[i - 1].end,
                  end: event.start,
                  openModal: true,
                  waittime: true,
                  typeVisit: "run"
                });
              }
            }
          })
        }
      })
    }
    if (view === 'team') {
      rota_shift_data && rota_shift_data.map(rotaShift => {
        const employe_name = rotaShift.employee && contextChoicesMap.employee_listing?.[rotaShift.employee]?.full_name
        if (rotaShift.schedule_travel_start_datetime && rotaShift.schedule_travel_end_datetime) {
          travel_data.push({
            id: rotaShift.id,
            resourceId: rotaShift.employee,
            typeEvent: rotaShift.is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            title: employe_name,
            backgroundColor: TRAVEL_TIME_COLOR,
            borderColor: TRAVEL_TIME_COLOR,
            textColor: GRAY_820,
            start: rotaShift.schedule_travel_start_datetime,
            end: rotaShift.schedule_travel_end_datetime,
            client: rotaShift.full_name,
            clientId: rotaShift.client,
            employeeId: rotaShift.employee,
            serviceId: rotaShift.client_service_visit_id,
            openModal: true,
            traveltime: true,
            shiftTravel: true,
            travel_mode_name: rotaShift.travel_mode_name,
            travel_code: rotaShift.travel_code && rotaShift.travel_code.toUpperCase(),
            travelMode: rotaShift.travel_mode_name === 'Walk' ? FOOT_ICON
              : (rotaShift.travel_mode_name === 'Scooter' || rotaShift.travel_mode_name === 'Motorbike' || rotaShift.travel_mode_name === 'Bike') ? BIKE_ICON : CAR_ICON,

          });
        }
        if (rotaShift.shift_type_name == 'Availability' || rotaShift.shift_type_name == 'Shift') {
          let rotaShiftsData = { start: rotaShift.start_date, end: rotaShift.end_date, zipcode: rotaShift.employee_zipcode }
          if (shift_availability[rotaShift.employee]) {
            shift_availability[rotaShift.employee].push({ ...rotaShiftsData })
          } else {
            shift_availability[rotaShift.employee] = [{ ...rotaShiftsData }]
          }
        } else {
          final_wait_data.push({
            id: rotaShift.id,
            resourceId: rotaShift.employee,
            backgroundColor: rotashiftColor(rotaShift.shift_type_name),
            borderColor: rotashiftColor(rotaShift.shift_type_name),
            title: rotaShift.shift_type_name ? rotaShift.shift_type_name : '',
            textColor: rotaShift.is_available ? GRAY_820 : WHITE,
            start: rotaShift.start_date,
            end: rotaShift.end_date,
            openModal: true,
            waittime: true
          })
        }
      });
    }
    let final_data = view === "client" ? [...data] : [...data, ...travel_data, ...final_wait_data]

    let wait_time_temp = [];

    if (view === 'team') {
      if (data && data.length > 0) {
        let temp_filter_data = data.filter((item) => item.employeeId);
        let temp_obj = {}

        temp_filter_data.forEach((item) => {
          if (temp_obj[item.resourceId]) {
            temp_obj[item.resourceId].push({ ...item })
          } else {
            temp_obj[item.resourceId] = [{ ...item }]
          }
        })


        Object.keys(shift_availability).map((key) => {
          shift_availability[key].forEach((shiftData) => {
            const shiftRange = new_moment.range(shiftData.start, shiftData.end);

            if (temp_obj[key]) {

              let sortedVisits = [];
              let sortedVisitsByEnd = [];
              temp_obj[key].forEach((visit) => {
                const visitRange = new_moment.range(visit.start_travel_time, visit.end);
                if (shiftRange.overlaps(visitRange) && (visit.start_travel_time)) {
                  sortedVisits.push(visit);
                  sortedVisitsByEnd.push(visit);
                }
              })

              sortedVisits = sortedVisits && sortedVisits.sort((a, b) => new Date(a.start_travel_time) - new Date(b.start_travel_time));

              sortedVisitsByEnd = sortedVisitsByEnd && sortedVisitsByEnd.sort((a, b) => new Date(a.end) - new Date(b.end));

              if (sortedVisits.length > 0) {
                if ((new Date(shiftData.start)).getTime() < (new Date(sortedVisits[0].start_travel_time)).getTime()) {
                  wait_time_temp.push({
                    resourceId: key,
                    backgroundColor: WAIT_TIME_COLOR,
                    borderColor: WAIT_TIME_COLOR,
                    title: `${shiftData.zipcode} - ${sortedVisits[0].zipcode}`,
                    textColor: GRAY_820,
                    start: shiftData.start,
                    end: sortedVisits[0].start_travel_time,
                    openModal: true,
                    waittime: true,
                  });
                }

                if ((new Date(shiftData.end)).getTime() > (new Date(sortedVisits[sortedVisits.length - 1].end)).getTime()) {
                  wait_time_temp.push({
                    resourceId: key,
                    backgroundColor: WAIT_TIME_COLOR,
                    borderColor: WAIT_TIME_COLOR,
                    title: `${sortedVisits[sortedVisits.length - 1].zipcode} - ${shiftData.zipcode}`,
                    textColor: GRAY_820,
                    start: sortedVisits[sortedVisits.length - 1].end,
                    end: shiftData.end,
                    openModal: true,
                    waittime: true,
                  });
                }
              }

              let wait_gap_visits = []
              let wait_gap_visits_less_two = []

              sortedVisits.map((event, i) => {
                if (i != 0) {
                  let a = new Date(sortedVisits[i - 1].end)
                  let b = new Date(sortedVisitsByEnd[i - 1].end)
                  let dateEnd = null;
                  if (b >= a) {
                    dateEnd = new Date(sortedVisitsByEnd[i - 1].end)
                  } else {
                    dateEnd = new Date(sortedVisits[i - 1].end)
                  }

                  let gap = (new Date(event.start_travel_time)).getTime() - (dateEnd).getTime();
                  if (gap > 60000) {
                    console.log('gap', gap)
                    let gap_in_hr = gap / 3600000;
                    wait_gap_visits.push(gap_in_hr);
                    if (!(gap_in_hr > 2)) {
                      wait_gap_visits_less_two.push(gap_in_hr)
                    }


                    wait_time_temp.push({
                      resourceId: key,
                      backgroundColor: WAIT_TIME_COLOR,
                      borderColor: WAIT_TIME_COLOR,
                      title: `${sortedVisits[i - 1].zipcode} - ${sortedVisits[i].zipcode}`,
                      textColor: GRAY_820,
                      start: b >= a ? sortedVisitsByEnd[i - 1].end : sortedVisits[i - 1].end,
                      end: event.start_travel_time,
                      openModal: true,
                      waittime: true,
                    });
                  }
                }
              })
              console.log('gap travel hrs', wait_gap_visits, wait_gap_visits_less_two)
              if (wait_gap_visits.length > 0) {
                let aHours = wait_gap_visits.reduce((total, visit) => total + visit);
                aHours % 0.1 === 0
                  ? setWaitHoursBetweenVisit(aHours)
                  : setWaitHoursBetweenVisit(Math.round(aHours * 100) / 100);
              } else {
                setWaitHoursBetweenVisit(0);
              }

              if (wait_gap_visits_less_two.length > 0) {
                let aHours = wait_gap_visits_less_two.reduce((total, visit) => total + visit);
                aHours % 0.1 === 0
                  ? setWaitHoursExcludeMoreThanTwo(aHours)
                  : setWaitHoursExcludeMoreThanTwo(Math.round(aHours * 100) / 100);
              } else {
                setWaitHoursExcludeMoreThanTwo(0);
              }

            }
            else {
              let gap_capacity_temp = (new Date(shiftData.end)).getTime() - (new Date(shiftData.start)).getTime();
              wait_hr_capacity.push(gap_capacity_temp)
              //console.log(shiftData, "0000000shiftData")
              wait_time_temp.push({
                resourceId: key,
                backgroundColor: WAIT_TIME_COLOR,
                borderColor: WAIT_TIME_COLOR,
                title: `${shiftData.zipcode} - ${shiftData.zipcode}`,
                textColor: GRAY_820,
                start: shiftData.start,
                end: shiftData.end,
                openModal: true,
                waittime: true,
              });
            }
            if (wait_hr_capacity.length > 0) {
              let aHours = wait_hr_capacity.reduce((total, visit) => total + visit);
              aHours % 0.1 === 0
                ? setWaitHoursCapacity(aHours)
                : setWaitHoursCapacity(Math.round(aHours * 100) / 100);
            } else {
              setWaitHoursCapacity(0);
            }
          })
        })

      }
      let blank_emp = []
      let emp_count = 0;
      empData.forEach((val) => {
        let checkEmp = final_data.findIndex((item) => item.resourceId == val.id)
        let checkEmpCount = final_data.findIndex((item) => ((item.resourceId == val.id) && item.visit))
        if (checkEmp == -1) {
          blank_emp.push({
            resourceId: val.id,
            title: '',
            backgroundColor: 'rgba(54, 25, 25, .00004)',
            borderColor: 'rgba(54, 25, 25, .00004)',
            start: values.start_date,
            //end: moment(values.start_date).add(60, 'seconds'),                   
            allDay: true,
            blank: true
          })
        }
        if (!(checkEmpCount == -1)) {
          emp_count = emp_count + 1
        }
      })
      setEmployeeResource(emp_count);
      if (values.employees && (values.employees.length > 0)) {
        let add_filtered_emp = []
        values.employees.map((val) => {
          let checkEmpBlank = blank_emp.findIndex((item) => item.resourceId == val.id)
          let check_val = filterExcludeCheck.employee ? (checkEmpBlank == -1) : !(checkEmpBlank == -1);
          if (check_val) {
            add_filtered_emp.push({
              resourceId: val.id,
              title: '',
              backgroundColor: 'rgba(54, 25, 25, .00004)',
              borderColor: 'rgba(54, 25, 25, .00004)',
              start: values.start_date,
              //end: moment(values.start_date).add(60, 'seconds'),                   
              allDay: true,
              blank: true
            })
          }
        })
        final_data = [...final_data, ...wait_time_temp, ...add_filtered_emp]
      } else {
        final_data = [...final_data, ...wait_time_temp, ...blank_emp]
      }
    }
    setEmployeeVisitData(visit_tooltip_data)
    setEventsSchedular(final_data.flat());
    setLoading(false)
  }

  const searchScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEventsSchedular([]);
    TOTAL_VISITS = 0;

    //schedular api
    const response = await getScheduler({ ...branchData, ...values }, filterExcludeCheck, 800)
    setScheduledData(response)
    VISITS_ASSIGNED = 0;
    VISITS_UNASSIGNED = 0;

    const rota_res = await getRotaShifts({ ...branchData, ...values }, filterExcludeCheck, 800)
    setRotashiftData(rota_res)
    //updateSchData(response, rota_res, 'team')
  };

  const backToSch = () => {
    history.push({
      pathname: `/scheduler`,
      search: `?start=${query_params_data.start}&end=${query_params_data.end}`
      // state: {}
    })
  };
  const dropDownHandleSelect = (item) => {
    setValues({ ...values, ...item });
  };

  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }
  const setEmloyeefilter = (item) => {
    setEmployeesFilter([...item])
  }
  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  const setRunrouteclientfilter = (item) => {
    setRunRoutesclientFilter([...item])
  }
  const setJobdeptfilter = (item) => {
    setJobDepartmentFilter([...item])
  }

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }

  const setFilterBranchRun = (item) => {
    setBranchRunSchedularFilter([...item])
  }

  const setVisitStatusfilter = (item) => {
    setVisitStatusFilter([...item])
  }
  const handleRunNameFilter = (item) => {
    setRunNameFilter([...item])
  }

  const handleService = (item) => {
    setServiceFilter([...item])
  }


  console.log('planner', eventsPlanned, eventsSchedular)

  return (
    <>
      <SchedulerContainer loading={loading}>
        <div className='d-flex justify-content-between'>
          <Title>Comparison Calendars</Title>
          <div className='my-2'>
            <MdKeyboardArrowLeft
              style={{
                fontSize: '2rem',
                color: PURPLE,
                cursor: 'pointer'
              }}
              onClick={() => backToSch()} />
            <span>Back to schedular</span>
          </div>
        </div>
        {!decreaseWidthPlan && !decreaseWidthSch &&
        <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center justify-content-md-start px-0" style={{ flexWrap: 'wrap' }}>
          <FiltersContainerRotaShifts>
            <FiltersLeftGroup
              className={'d-flex justify-content-center justify-content-md-start mb-2'} style={{ flexWrap: 'wrap' }}>
              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={employeeForFilter ? employeeForFilter : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employees: items });
                  }}
                  itemsSelected={employeesFilter}
                  setSelectedFilter={setEmloyeefilter}
                  placeHolder={'Employees'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                  exclude={{
                    name: 'employee',
                    checked: filterExcludeCheck.employee || false,
                    handleChangeFilterCheckbox: handleChangeFilterCheckbox
                  }}
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={clients ? clients : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ client: items });
                  }}
                  itemsSelected={clientsFilter}
                  setSelectedFilter={setClientfilter}
                  placeHolder={'Clients'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                  exclude={{
                    name: 'client',
                    checked: filterExcludeCheck.client || false,
                    handleChangeFilterCheckbox: handleChangeFilterCheckbox
                  }}
                // filterExcludeCheck={filterExcludeCheck.client || false}
                // handleChangeFilterCheckbox={handleChangeFilterCheckbox}
                />
              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="mr-2">

                <CustomDropdown
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ runroutesclient: items });
                  }}
                  itemsSelected={runRoutesclientFilter}
                  setSelectedFilter={setRunrouteclientfilter}
                  placeHolder={'Client Group Area'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}

                />

              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="mr-2">

                <CustomDropdown
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ runroutesteam: items });
                  }}
                  itemsSelected={runRoutesFilter}
                  setSelectedFilter={setRunroutefilter}
                  placeHolder={'Team Group Area'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}

                />

              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={jobDepartment ? jobDepartment : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ jobDepartment: items });
                  }}
                  itemsSelected={jobDepartmentFilter}
                  setSelectedFilter={setJobdeptfilter}
                  placeHolder={' Job Department '}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={branch ? branch : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ branch: items });
                  }}
                  itemsSelected={branchFilter}
                  setSelectedFilter={setFilterBranch}
                  placeHolder={'Branch'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>


              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={visitStatusData ? visitStatusData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ visitStatus: items });
                  }}
                  itemsSelected={visitStatusFilter}
                  setSelectedFilter={setVisitStatusfilter}
                  placeHolder={'Visit Status'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={runNameData ? runNameData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ run_view_name: items });
                  }}
                  itemsSelected={runNameFilter}
                  setSelectedFilter={handleRunNameFilter}
                  placeHolder={'Run Name'}
                  isObject
                  allOption
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="mr-2">
                <CustomDropdown
                  items={contextChoices.service_category || []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ service_category: items });
                  }}
                  itemsSelected={serviceFilter}
                  setSelectedFilter={handleService}
                  placeHolder={'Service Category'}
                  isObject
                  allOption
                />
              </FiltersTypesContainerRotaShifts>
              <PrimaryButtonForm minWidth="6rem" className={'mr-1'} onClick={() => {
                activeEmployeeResourceFn(empData)
                searchScheduler()
              }}
              >
                <span className={'mr-1'}>Search</span>
              </PrimaryButtonForm>
            </FiltersLeftGroup>
          </FiltersContainerRotaShifts>

        </div>}
        <PlannerCalendar
          resourceAreaHeaderContent="Team"
          resources={[...resourcesSchedular]}
          events={[...eventsSchedular]}
          eventsPlanned={[...eventsPlanned]}
          setShiftType={setShiftType}
          shiftType={shiftType}
          values={values}
          employeeVisitData={employeeVisitData || []}
          employeeVisitDataPlanned={employeeVisitDataPlanned || []}
          setValues={setValues}
          eventClickHandler={data => {
          }}
          useInput={useInput}
          employeeResource={employeeResource}
          employeeResourcePlanned={employeeResourcePlanned}
          setDecreaseWidthSch={setDecreaseWidthSch}
          decreaseWidthSch={decreaseWidthSch}
          setDecreaseWidthPlan={setDecreaseWidthPlan}
          decreaseWidthPlan={decreaseWidthPlan}
          waitHoursBetweenVisit={waitHoursBetweenVisit}
          waitHoursExcludeMoreThanTwo={waitHoursExcludeMoreThanTwo}
          waitHoursCapacity={waitHoursCapacity}
          waitHoursBetweenVisitPlanned={waitHoursBetweenVisitPlanned}
          waitHoursExcludeMoreThanTwoPlanned={waitHoursExcludeMoreThanTwoPlanned}
          waitHoursCapacityPlanned={waitHoursCapacityPlanned}
        />
      </SchedulerContainer>
    </>
  );
}

export default Planner;
