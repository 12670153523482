import React, {useState, Fragment, useEffect, useRef, useContext} from 'react';
import {GrSearch} from 'react-icons/gr';
import {FaPlus} from 'react-icons/fa';
import {FloatingMobileButton} from 'shared/styles/buttons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  FiltersContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';
import {
  EmploymentTypesContainer,
  Header,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
  Title,
} from './styles';
import {useForm} from 'hooks/FormHook';
import {getEmployeesList} from 'utils/api/EmployeeApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import NewVisitCare from '../../ReportClient/ClientFilters/NewVisitCare';
import {reports_team_choices} from 'utils/choiceConstant';
import DateSelector from 'components/SharedComponents/DateSelector';
const TeamFilters = ({
  location,
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  tabClick,
}) => {
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput} = useForm({});
  const [employeesStatus, setEmployeesStatus] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [runRouteData, setRunRouteData] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [downloadDisable, SetDownloadDisable] = useState(false);
const  dateTo = useInput('complete_to')
const dateFrom = useInput('complete_from')
const DateFilterForSpecific =  ['Skill_and_Training','Competency','Qualification_and_Appreciation','employee_expense']
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeeStatusFilter, setEmployeeStatusFilter] = useState([]);
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState([]);
  const [jobTitleFilter, setJobTitleFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const {contextChoices, handleGetChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  useEffect(() => {
    handleGetChoices(reports_team_choices);
  }, []);

  useEffect(() => {
    setLoadingButton(false);
    SetDownloadDisable(false);
  }, [location.pathname]);


  

  const handleDownload = () => {
    setLoadingButton(true);
    SetDownloadDisable(true);
    let fileName = `report_team_${activeTab}_${moment().format('YYYY-MM-DD')}`;
    getEmployeesList({ ...branchData, ...values }, activeTab)
      .then(response => {
        if (response) {
          setLoadingButton(true);
          SetDownloadDisable(true);
        }
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        setTimeout(() => {
          setLoadingButton(false);
          SetDownloadDisable(false);
        }, 2000);
      })
      .catch(err => {});

    SetDownloadDisable(false);
  };

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setEmployeeStatus(
      contextChoices.employee_status &&
        contextChoices.employee_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmploymentTypes(
      contextChoices &&
        contextChoices.employment_type &&
        contextChoices.employment_type.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setJobTitles(
      contextChoices &&
        contextChoices.job_title &&
        contextChoices.job_title.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    // setEmployeeCategories(contextChoices && contextChoices.employee_category && contextChoices.employee_category.map(y=>{
    //   return{ id: y && y.id , name: y && y.name}
    // }  ));

    setEmployeesStatus(
      contextChoices &&
        contextChoices.employee_status &&
        contextChoices.employee_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const setFilterEmployeeStatus = item => {
    setEmployeeStatusFilter([...item]);
  };
  const setEmploymentType = item => {
    setEmploymentTypeFilter([...item]);
  };
  const setJobTitle = item => {
    setJobTitleFilter([...item]);
  };

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };
  console.log(activeTab,"QQQQQQ")
  return (
    <div>
      { DateFilterForSpecific.includes(activeTab) ? (
        <div>
          <Header>
            <FiltersContainer>
              <FiltersLeftGroup>
              
                <EmploymentTypesContainer className="mr-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...dateFrom}
                    />
                  </div>
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="mr-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      minDate={values.date_from}
                      {...dateTo}
                    />
                  </div>
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={employmentTypes ? employmentTypes : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employment_type: items});
                    }}
                    itemsSelected={employmentTypeFilter}
                    setSelectedFilter={setEmploymentType}
                    placeHolder={'Employment Type'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="mr-2">
                  {/* <Dropdown
          items={jobTitles}
          onSelect={item => {
            dropDownHandleSelect({job_title: item});
          }}
          placeHolder={'Job Title'}
          appendItem=""
          isObject
          allOption
        /> */}
                  <NoSelectAllDropdownFilter
                    items={jobTitles ? jobTitles : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({job_title: items});
                    }}
                    itemsSelected={jobTitleFilter}
                    setSelectedFilter={setJobTitle}
                    placeHolder={'Job Title'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({run_route: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className=" mr-2">
                  <NoSelectAllDropdownFilter
                    items={employeeStatus ? employeeStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={setFilterEmployeeStatus}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
            </FiltersContainer>
          </Header>
        </div>
      ):(
        <Header>
            <FiltersContainer>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={employmentTypes ? employmentTypes : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employment_type: items});
                    }}
                    itemsSelected={employmentTypeFilter}
                    setSelectedFilter={setEmploymentType}
                    placeHolder={'Employment Type'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="mr-2">
                  {/* <Dropdown
          items={jobTitles}
          onSelect={item => {
            dropDownHandleSelect({job_title: item});
          }}
          placeHolder={'Job Title'}
          appendItem=""
          isObject
          allOption
        /> */}
                  <NoSelectAllDropdownFilter
                    items={jobTitles ? jobTitles : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({job_title: items});
                    }}
                    itemsSelected={jobTitleFilter}
                    setSelectedFilter={setJobTitle}
                    placeHolder={'Job Title'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({run_route: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className=" mr-2">
                  <NoSelectAllDropdownFilter
                    items={employeeStatus ? employeeStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={setFilterEmployeeStatus}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
            </FiltersContainer>
          </Header>
      )}
      {loadingButton ? (
        <Fragment>
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
          />
          <span>Downloading...</span>
        </Fragment>
      ) : (
        <Fragment />
      )}
      <SearchAddContainer className="d-flex align-items-center">
        <FloatingMobileButton
          onClick={!downloadDisable && handleDownload}
          disabled={downloadDisable}>
          <label>Download List</label>
        </FloatingMobileButton>
      </SearchAddContainer>
    </div>
  );
};

export default TeamFilters;
