import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import {
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Dropdown from 'components/SharedComponents/Dropdown';
import Pagination from 'components/SharedComponents/Pagination';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import { getPageToSearchParam } from 'shared/methods';
import { useHistory, useLocation } from 'react-router-dom';
import { getVisits, deleteVisit, getDownloadFile } from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import moment from 'moment';
import Fade from 'react-reveal/Fade';
import { GrSearch } from 'react-icons/gr';
import VisitModal from './VisitModal/VisitModal';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getTimesArray, getTimesArrayINHHMMFormat, getCodeDaysArray, getDaysArray } from 'utils/Array';
import { debounce } from "lodash";
import MultiSelectDropdown from 'components/SharedComponents/MultiSelectDropdown';
import { DownloadButton } from 'shared/styles/buttons';
import { FaDownload } from 'react-icons/fa';
import { DOWNLOAD } from 'utils/constants/icons';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  PublishButton
} from 'shared/styles/constants/tagsStyles';
import { apiURL } from 'utils/env';
import { getToken, getTenant } from 'utils/localStorage/token';
import {
  CLIENT_VISITS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../utils/constants/permissions';
import { getCategoryPermission, getPermissions, getStaff, getClient, getEmployee } from '../../../utils/localStorage/user';
import { getAdmin, getSuperAdmin } from "../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { client_contract_visit } from 'utils/choiceConstant';

const headerColumns = [
  {
    label: 'FULL NAME',
    name: 'Client_Service__Client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'SERVICE CATEGORY',
    name: 'service_category__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'CLIENT  CATEGORY',
    name: 'client_service__service_funder__category__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'START DATE',
    name: 'start_date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'END DATE',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'START TIME - END TIME',
    name: 'preferred_start_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'DURATION',
    name: 'duration',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: SMALL_COL,
  },
  //  no. employees coumn added below
  {
    label: 'EMPLOYEES',
    name: 'no_employees',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'VISIT DAYS',
    name: 'visit_days',
    status: 0,
    className: 'grow-mobile visits-custom-header sm-hidden visit-days',
    max_width: LARGE_COL,
  },
];

const timesArray = getTimesArray();
const timesArrayInHrsMin = getTimesArrayINHHMMFormat();
const daysArray = getDaysArray();
const codeDaysArray = getCodeDaysArray();

function ClientVisits(props) {
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({ ordering: 'Client_Service__Client__full_name' });
  const { contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [visitContracts, setVisitContracts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [choices, setChoices] = useState({ service_category: [] });
  const [clientId, setClientId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [timeSelected, setTimeSelected] = useState('');
  const [visitHours, setVisitHours] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numVisits, setNumVisits] = useState(0);
  const mountedRef = useRef(true);
  const [downloadDisable, SetDownloadDisable] = useState(false)
  const [summaryValues, setSummaryValues] = useState({});
  const [clients, setClients] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  //for runroute+branch filters below 
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [daysFilter, setDaysFilter] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [tempChoices, setTempChoices] = useState();
  const [arrayDaysData, setArrayDaysData] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([]);
  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [funderCategoryData, setFunderCategoryData] = useState([]);
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('')
  const [numberValue, setNumberValue] = useState();
  const { setValue: setModalValue, value: modalValue, fullBind } = useModal(
    false,
  );

  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const history = useHistory();
  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const { handleGetChoices } = useContext(AppContext)

   
  useEffect(()=>{
    handleGetChoices(client_contract_visit)
  },[])

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])


  useEffect(() => {
    setArrayDaysData(daysArray.filter(w => w.id !== ''))
  }, [])

  useEffect(() => {
    if (values.preferred_start_time && values.preferred_end_time) {
      let start_time = moment(values.preferred_start_time, 'HH:mm:ss');
      let end_time = moment(values.preferred_end_time, 'HH:mm:ss');

      if (start_time.isAfter(end_time) && timeSelected === 1) {
        values.preferred_start_time = values.preferred_end_time;
        start_time = end_time;
      }

      if (end_time.isBefore(start_time) && timeSelected === 2) {
        values.preferred_end_time = values.preferred_start_time;
        end_time = start_time;
      }
    }

    // timesArray.forEach(timeValue => {
    //   if (timeSelected === 1 && timeValue.id === values.preferred_start_time) {
    //     values.start_time = timeValue.name;
    //   } else if (
    //     timeSelected === 2 &&
    //     timeValue.id === values.preferred_end_time
    //   ) {
    //     values.end_time = timeValue.name;
    //   }
    // });

    timesArrayInHrsMin.forEach(timeValue => {
      if (timeSelected === 1 && timeValue.id === values.preferred_start_time) {
        values.start_time = timeValue.name;
      } else if (
        timeSelected === 2 &&
        timeValue.id === values.preferred_end_time
      ) {
        values.end_time = timeValue.name;
      }
    });

    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.preferred_start_time, values.preferred_end_time]);

  const handleClick = timeSelected => {
    setTimeSelected(timeSelected);
  };

  useEffect(() => {
    setTempChoices(contextChoices || []);
    setRunRouteData(contextChoices && contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setBranch((contextChoices && contextChoices.run_routes && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))

    setServiceCategory(contextChoices && contextChoices.service_category && contextChoices.service_category.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setClientStatus(contextChoices && contextChoices.client_status && contextChoices.client_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setFunderCategoryData((contextChoices && contextChoices.funder_source && contextChoices.funder_source.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))

    setClients((contextChoices && contextChoices.client_listing && contextChoices.client_listing.map(y => {
      return { id: y && y.id, name: y && y.full_name }
    })))

  }, [contextChoices])


  useEffect(() => {

    if (values.repeat_on || values.runroutes || values.branch || values.service_category || values.client_status || values.funder_source || values.preferred_start_time || values.preferred_end_time || values.client) {
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: "/visits",
        search: stringified,
      });
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.repeat_on,
    values.runroutes,
    values.branch,
    values.service_category,
    values.client_status,
    values.funder_source,
    values.preferred_start_time,
    values.preferred_end_time,
    values.client,
    branchData,
    values.limitperpage,
  ]);

  useEffect(() => {
    if (values.ordering || props.location) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.location.search,
    values.ordering,
  ]);

  useEffect(() => {
    const roles = contextPermissions;
    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === CLIENT_VISITS) {
          rolePermissions[CLIENT_VISITS] = {};
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION] = {};
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });
      if (filterAdmin == true || filterSuperAdmin == true) {
        setCreatePermissions(true);
        setDeletePermissions(true);
        setUpdatePermissions(true);
      }
      else {
        setCreatePermissions(
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[CLIENT_VISITS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      // setCurrentPage,
    );

    getVisits({ ...branchData, ...values, ...pageToSearch }, values.limitperpage).then(response => {
      if (!mountedRef.current) return null;
      if (response) {
        setLoadingModal(false);
      }
      //  const  clientData =response.results;
      //  setClients(clientData && clientData.map((obj)=>{
      //   return({ id: obj.client_service && obj.client_service.client && obj.client_service.client.id , name : obj.client_service && obj.client_service.client && obj.client_service.client.full_name
      //   })
      // }))

      setVisitContracts(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
      setNumVisits(Math.ceil(response.visits))
      setNumClients(Math.ceil(response.clients))
      setVisitHours(Math.round(((response.hours) + Number.EPSILON) * 100) / 100)

    });
  };

  const hitSearch = (value) => {
    values.search = value
    search();
  }

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d)
    }
  }

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value)
    debounceOnchange.current(e.target.value)
  };


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = (visitId, clientId, serviceId) => {
    setIdSelected(visitId);
    setClientId(clientId);
    setServiceId(serviceId);
    setModalValue(true);
  };

  const getDaysSelected = visits => {
    // get the string days and make them arrays
    const days = visits.split(',');

    return days;
  };

  const getTimeVisit = visit => {
    const time =
      moment(visit.preferred_start_time, 'HH:mm').format('HH:mm:ss') +
      ' - ' +
      moment(visit.preferred_end_time, 'HH:mm').format('HH:mm:ss');
    return time;
  };

  const isDaySelected = (name, daysSelected) => {
    let result = daysSelected.indexOf(name);

    return result > -1;
  };


  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
  };

  const setArrayDaysfilter = (item) => {
    setDaysFilter([...item])
  }

  const setServicefilter = (item) => {
    setServiceCategoryFilter([...item])
  }
  const filterClientStatus = (item) => {
    setClientStatusFilter([...item])
  }
  const filterFunder = (item) => {
    setFunderCategoryFilter([...item])
  }
  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }
  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }

  const getServiceCategory = id => {
    if (id) {
      let serviceName = contextChoices?.service_category?.find(servicedata => servicedata.id == id)?.name;
      if (serviceName) {
        return serviceName;
      } else {
        return 'Unspecified';
      }
    } else {
      return 'Unspecified';
    }
  };

  // below  client category
  const getClientCategory = data => {
    if (!data) {
      return 'Unspecified';
    }
    return data.name;
  }

  // below for  no. of employees
  const calculateNumEmployees = id => {
    if (!id) {
      return '0';
    }
    else {
      return id;
    }

  };


  const calculateDuration = duration => {
    if (duration && typeof duration === 'string') {
      const res = duration.split(':').reduce((acc, time) => 60 * acc + +time);

      const d = Number(res);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);

      const hDisplay = h > 0 ? h + 'h' : '';
      const mDisplay = m > 0 ? m + 'm' : '';

      return hDisplay + ' ' + mDisplay;
    } else {
      return duration;
    }
  };

  const handleDeleteVisit = async () => {
    try {
      await deleteVisit(idSelected);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      search();
      setIdSelected('');
    }
  };

  const toDeleteVisit = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDownload = () => {
    let fileName = "Visit_" + moment().format("YYYY-MM-DD")
    SetDownloadDisable(true)
    getDownloadFile('ClientServiceVisitAllViewSet', { ...values }).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        console.log(url);
        a.href = url;
        a.download = fileName;
        a.click();
        SetDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        SetDownloadDisable(false)
      })
  }

  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }

  // const multiselect = () => {
  //   const pageToSearch = getPageToSearchParam(
  //     props.location.search,
  //     // setCurrentPage,
  //   );
  //   getVisits({...values, ...pageToSearch}, PAGE_SIZE_RENDER).then(response => {
  //     if (!mountedRef.current) return null; 
  //   setVisitContracts(response.results || []);
  //   setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
  //   setNumVisits(Math.ceil(response.visits))
  //   setNumClients(Math.ceil(response.clients))
  //   setVisitHours(Math.round(  (  (response.hours)  + Number.EPSILON) *100  )/100)

  //   });
  // };


  //  useEffect(() => {
  //   multiselect();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   values.client,
  // ]);

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }



  return (
    <div>
      <Title>Contract visits</Title>

      <HeaderVisits>
        <div className="d-flex align-items-center">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <Dropdown

                    // items={timesArray}
                    items={timesArrayInHrsMin}
                    onSelect={item => {
                      dropDownHandleSelect({ preferred_start_time: item });
                      handleClick(1);
                    }}
                    placeHolder={'Start time'}
                    value={values.start_time || ''}
                    appendItem=""
                    isObject
                    allOption
                  />

                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <Dropdown
                    // items={timesArray}
                    items={timesArrayInHrsMin}
                    onSelect={item => {
                      dropDownHandleSelect({ preferred_end_time: item });
                      handleClick(2);
                    }}
                    value={values.end_time || ''}
                    placeHolder={'End time'}
                    appendItem=""
                    isObject
                    allOption
                  />

                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={arrayDaysData ? arrayDaysData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ repeat_on: items });
                    }}
                    itemsSelected={daysFilter}
                    setSelectedFilter={setArrayDaysfilter}
                    placeHolder={'Day'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={serviceCategory ? serviceCategory : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ service_category: items });
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={setServicefilter}
                    placeHolder={'Service Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={clientStatus ? clientStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_status: items });
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={filterClientStatus}
                    placeHolder={'Client Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={funderCategoryData ? funderCategoryData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ funder_source: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={filterFunder}
                    placeHolder={'Funder  Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                {/* added client name filter */}
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={clients ? clients : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client: items });
                    }}
                    itemsSelected={clientsFilter}
                    setSelectedFilter={setClientfilter}
                    placeHolder={'Client Name '}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              {/* added branch +runroute filters below */}
              <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    // placeHolder={'Runroute'}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>

            </div>
          </FiltersContainer>
        </div>

        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(true);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}       
                onChange={(e) => {
                  keyPressed(e)
                }
                }
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>
        </SearchAddContainer>
      </HeaderVisits>

      <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">

        <div className="data-container">
          <div className="mr-2">
            <p>{'Visits: ' + numVisits} </p>
          </div>
          <div className="mr-2">
            <p>{'Clients: ' + numClients}</p>
          </div>
          <p>{'Hours: ' + visitHours}</p>
        </div>
        {/* // for download  */}
        <PublishButton className=" ml-auto pl-2 mr-4" disable={downloadDisable} onClick={!downloadDisable && handleDownload}>
          {DOWNLOAD}
        </PublishButton>
      </DataContainer>

      <ComponentDynamic loading={loadingModal} >
        <Table
          headerColumns={headerColumns}
          headerPadding={false}
          noEditOption
          fontSize="0.75rem"
          callBackQuerySort={setQuerySort}
        >
          <div>
            {visitContracts.map((visit, index) => {
              const endDate = visit.end_date
                ? moment(visit.end_date).format('DD-MM-YYYY')
                : 'Unset';
              const startDate = visit.start_date
                ? moment(visit.start_date).format('DD-MM-YYYY')
                : 'Unset';

              const daysSelected = getDaysSelected(visit.repeat_on);

              return (
                <div key={index}>
                  <Row bgColor Shadow>
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      overlap={true}
                      reducePadding
                      Capitalize
                      Center>
                      {visit.client_data
                        ? visit.client_data.full_name
                          ? visit.client_data.full_name
                          : ''
                        : ''}
                    </Col>
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      overlap={true}
                      reducePadding
                      Capitalize
                      Center>
                      {getServiceCategory(visit.service_category)}
                    </Col>
                    {/* added below  client category */}
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      overlap={true}
                      reducePadding
                      Capitalize
                      Center>
                      {getClientCategory(visit.client_service.client_service_funder_category)}
                      {/* {clientChoices} */}

                    </Col>
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      overlap={true}
                      reducePadding
                      Capitalize
                      Center>
                      {startDate}
                    </Col>
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      {endDate}
                    </Col>
                    {/* START AND END TIME */}
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      {getTimeVisit(visit) || 'Unset'}
                    </Col>
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={SMALL_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center
                      className="sm-hidden">
                      {calculateDuration(visit.duration)}
                    </Col>
                    {/* below column for no. of employees */}
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={SMALL_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center
                      className="sm-hidden">
                      {calculateNumEmployees(visit.no_employees)}
                    </Col>

                    {/* DAYS */}
                    <Col
                      onClick={() => {
                        handleRowClick(
                          visit.id,
                          visit.client_service.client.id,
                          visit.client_service.id,
                        );
                      }}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center
                      className="visits-custom-row sm-hidden visit-days">
                      {codeDaysArray.map(day => {
                        return (
                          <DayPill
                            key={`day_pill_` + day.name}
                            Active={isDaySelected(day.name, daysSelected)}>
                            <label>{day.name}</label>
                          </DayPill>
                        );
                      })}
                    </Col>
                    {(deletePermission && (userClient === 'null') && (userStaff == true)) && (
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteVisit(visit.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                    )}
                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
            <div className="mt-3">
              <Pagination
                // setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                values={values}
                dropDownHandleSelect={dropDownHandleSelectPagination}
                number={PAGE_SIZE_RENDER}
                dataLength={visitContracts.length}
              />
            </div>
          </div>
        </Table>
      </ComponentDynamic>
      {!modalValue ? null : (
        <VisitModal
          clientId={clientId}
          serviceId={serviceId}
          visitId={idSelected}
          photo=""
          clientName=""
          contractRef=""
          fullBind={fullBind}
          choices={contextChoices}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this visit? \n DELETING the visit will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the visit status to discard instead to retain database records.'
        }
        onOk={handleDeleteVisit}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
}

export default ClientVisits;
