import {
  EX_SMALL_COL,
  EX_SMALL_COL4,
  LARGE_COL,
  SMALL_COL,
  VERY_EX_SMALL_COL_CHECKBOX
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    custum_column: true,
    removeCusCol: true,
    id: 'checkbox',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: VERY_EX_SMALL_COL_CHECKBOX,
},
  {
    label: 'Branch Name',
    name: 'branch_name',
    status: 1,
    custum_column: true,
    id: 'branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Client Name',
    name: 'client__full_name',
    status: 1,
    custum_column: true,
    id: 'client_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Name',
    name: 'funder_name',
    status: 1,
    custum_column: true,
    id: 'funder_name',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Employee Name',
    name: 'employee_name',
    status: 1,
    custum_column: true,
    id: 'employee_name',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Status',
    name: 'ecececef',
    status: 1,
    custum_column: true,
    id: 'visit_status',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Bill Pattern',
    name: 'bill_pattern',
    status: 0,
    id: 'bill_pattern',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Type',
    name: 'ececedc',
    status: 1,
    custum_column: true,
    id: 'visit_type',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Id',
    name: 'vcxrvxrv',
    status: 1,
    custum_column: true,
    id: 'visit_id',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Service Category',
    name: 'xrvrv',
    status: 1,
    custum_column: true,
    id: 'service_category',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Client Status',
    name: 'rxvrxvr',
    status: 1,
    custum_column: true,
    id: 'client_status',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Category Name',
    name: 'fecfece',
    status: 1,
    custum_column: true,
    id: 'category_name',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Start Date',
    name: 'schedule_start_date',
    status: 1,
    custum_column: true,
    id: 'start_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'End Date',
    name: 'schedule_end_date',
    status: 1,
    custum_column: true,
    id: 'end_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Start Time',
    name: 'schedule_start_time',
    status: 1,
    custum_column: true,
    id: 'schedule_start_time',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule End Time',
    name: 'schedule_end_time',
    status: 1,
    custum_column: true,
    id: 'schedule_end_time',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Duration(mm)',
    name: 'schedule_duration',
    status: 1,
    custum_column: true,
    id: 'schedule_duration_min',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Duration(hh)',
    name: 'schedule_duration',
    status: 1,
    custum_column: true,
    id: 'schedule_duration_hrs',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Start Time',
    name: 'actual_start_time',
    status: 1,
    custum_column: true,
    id: 'actual_start_time',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual End Time',
    name: 'actual_end_time',
    status: 1,
    custum_column: true,
    id: 'actual_end_time',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Duration(hh)',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'actual_duration_hrs',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Duration(mm)',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'actual_duration_min',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Rounded Duration(mm)',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'rounded_duration_hrs',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Banded Duration(mm)',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'banded_duration_hrs',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Rounded Duration(hh)',
    name: 'rounded_duration_in_hrs_format',
    status: 0,
    id: 'rounded_duration_in_hrs_format',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Banded Duration(hh)',
    name: 'banded_duration_in_hrs_format',
    status: 0,
    id: 'banded_duration_in_hrs_format',
    columnSelected: true,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Rate Card',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'funder_rate_card',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Rate',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'funder_rate',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Amount',
    name: 'actual_visit_duration',
    status: 1,
    custum_column: true,
    id: 'funder_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Contract Visit Amt',
    name: 'visit__contract_bill_amount',
    status: 1,
    custum_column: true,
    id: 'contract_visit_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Adhoc Visit Amt',
    name: 'schedule_adhoc_visit_bill_amount',
    status: 1,
    custum_column: true,
    id: 'adhoc_visit_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Scheduled Billing Amt',
    name: 'visit__schedule_bill_amount',
    status: 1,
    custum_column: true,
    id: 'schedule_billing_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Scheduled Manual Billing Amt',
    name: 'visit__manual_bill_amount',
    status: 1,
    custum_column: true,
    id: 'schedule_manual_billing_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Bill Amt',
    name: 'actual_bill_rate_amount',
    status: 1,
    custum_column: true,
    id: 'actual_billing_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Manual Amt',
    name: 'actual_manual_bill_rate_amount',
    status: 1,
    custum_column: true,
    id: 'actual_manual_billing_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Rounded Billing Amount',
    name: 'rounded_billing_amount',
    status: 1,
    custum_column: true,
    id: 'rounded_billing_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Banded Billing Amount',
    name: 'banded_billing_amount',
    status: 1,
    custum_column: true,
    id: 'banded_billing_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Travel Bill Rate Card',
    name: 'funder_travel_bill_rate_card',
    status: 1,
    custum_column: true,
    id: 'funder_travel_bill_rate_card',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'funder Travel Bill Rate Per Hour',
    name: 'funder_travel_bill_rate_per_hour',
    status: 1,
    custum_column: true,
    id: 'funder_travel_bill_rate_per_hour',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Travel Bill Amount',
    name: 'funder_travel_bill_amount',
    status: 1,
    custum_column: true,
    id: 'funder_travel_bill_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder Travel Bill Mileage Per Mile',
    name: 'funder_travel_bill_mileage_per_mile',
    status: 1,
    custum_column: true,
    id: 'funder_travel_bill_mileage_per_mile',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Funder travel Bill Mileage Amount',
    name: 'funder_travel_bill_mileage_amount',
    status: 1,
    custum_column: true,
    id: 'funder_travel_bill_mileage_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Travel Bill Rate Card',
    name: 'schedule_travel_bill_rate_card',
    status: 1,
    custum_column: true,
    id: 'schedule_travel_bill_rate_card',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Travel Bill Rate Per Hour',
    name: 'schedule_travel_bill_rate_per_hour',
    status: 1,
    custum_column: true,
    id: 'schedule_travel_bill_rate_per_hour',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Travel Bill Amount',
    name: 'schedule_travel_bill_amount',
    status: 1,
    custum_column: true,
    id: 'schedule_travel_bill_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Travel Bill Mileage Per Mile',
    name: 'schedule_travel_bill_mileage_per_mile',
    status: 1,
    custum_column: true,
    id: 'schedule_travel_bill_mileage_per_mile',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Schedule Travel Bill Mileage Amount',
    name: 'schedule_travel_bill_mileage_amount',
    status: 1,
    custum_column: true,
    id: 'schedule_travel_bill_mileage_amount',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  // {
  //   label: 'Visit Travel Bill Rate Card',
  //   name: 'visit_travel_bill_rate_card',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_travel_bill_rate_card',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  // {
  //   label: 'Visit Travel Bill Rate Per Hour',
  //   name: 'visit_travel_bill_rate_per_hour',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_travel_bill_rate_per_hour',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  // {
  //   label: 'Visit Travel Bill Amount',
  //   name: 'visit_travel_bill_amount',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_travel_bill_amount',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  // {
  //   label: 'Visit Travel Bill Mileage Per Mile',
  //   name: 'visit_travel_bill_mileage_per_mile',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_travel_bill_mileage_per_mile',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  // {
  //   label: 'Visit Travel Bill Mileage Amount',
  //   name: 'visit_travel_bill_mileage_amount',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_travel_bill_mileage_amount',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  // {
  //   label: 'Visit Rate Card',
  //   name: 'visit_rate_card',
  //   status: 1,
  //   custum_column: true,
  //   id: 'visit_rate_card',
  //     columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  {
    label: 'Schedular Rate Card',
    name: 'schedule_rate_card',
    status: 1,
    custum_column: true,
    id: 'schedule_rate_card',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Rate Card',
    name: 'actual_rate_card',
    status: 1,
    custum_column: true,
    id: 'actual_rate_card',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Clock Out Notes',
    name: 'clock_out_notes',
    status: 1,
    custum_column: true,
    id: 'clock_out_notes',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Duration Diff',
    name: 'visit_duration_diff',
    status: 1,
    custum_column: true,
    id: 'visit_duration_diff',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Amount Diff',
    name: 'visit_amount_diff',
    status: 1,
    custum_column: true,
    id: 'visit_amount_diff',
      columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Cancelled Bill Percentage',
    name: 'cancelled_bill_percentage',
    status: 1,
    custum_column: true,
    id: 'cancelled_bill_percentage',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
 
  {
    label: 'Non-commute Scheduled Travel Amt',
    name: 'non_commute_schedule_travel_bill_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_bill_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Duration (Hrs)',
    name: 'non_commute_schedule_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Distance',
    name: 'non_commute_schedule_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Mileage Amt',
    name: 'non_commute_schedule_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Amt',
    name: 'non_commute_actual_travel_bill_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_bill_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Duration (Hrs)',
    name: 'non_commute_actual_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Distance',
    name: 'non_commute_actual_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Mileage Amt',
    name: 'non_commute_actual_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },


];



export const headerColumnsSelector = [
  {
    name: 'Branch Name',
    id: 'branch_name',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Funder Name',
    id: 'funder_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },
  {
    name: 'Visit Type',
    id: 'visit_type',
  },
  {
    name: 'Visit Id',
    id: 'visit_id',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Client Status',
    id: 'client_status',
  },
  {
    name: 'Category Name',
    id: 'category_name',
  },
  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Schedule Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Schedule End Time',
    id: 'schedule_end_time',
  },
  {
    name: 'Schedule Duration(mm)',
    id: 'schedule_duration_min',
  },
  {
    name: 'Schedule Duration(hh)',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Duration(hh)',
    id: 'actual_duration_hrs',
  },
  {
    name: 'Actual Duration(mm)',
    id: 'actual_duration_min',
  },
  {
    name: 'Rounded Duration(mm)',
    id: 'rounded_duration_min',
  },
  {
    name: 'Banded Duration(hh)',
    id: 'banded_duration_hrs',
  },
  {
    name: 'Funder Rate Card',
    id: 'funder_rate_card',
  },
  {
    name: 'Funder Rate',
    id: 'funder_rate',
  },
  {
    name: 'Funder Amount',
    id: 'funder_amount',
  },
  {
    name: 'Contract Visit Amt',
    id: 'contract_visit_amount',
  },
  {
    name: 'Adhoc Visit Amt',
    id: 'adhoc_visit_amount',
  },
  {
    name: 'Scheduled Billing Amt',
    id: 'schedule_billing_amount',
  },
  {
    name: 'Scheduled  Manual Billing Amt',
    id: 'schedule_manual_billing_amount',
  },
  {
    name: 'Actual Bill Amt',
    id: 'actual_billing_amount',
  },
  {
    name: 'Actual Manual Amt',
    id: 'actual_manual_billing_amount',
  },
  {
    name: 'Rounded Billing Amount',
    id: 'rounded_billing_amount',
  },
  {
    name: 'Banded Billing Amount',
    id: 'banded_billing_amount',
  },
  {
    name: 'Funder Travel Bill Rate Card',
    id: 'funder_travel_bill_rate_card',
  },
  {
    name: 'funder Travel Bill Rate Per Hour',
    id: 'funder_travel_bill_rate_per_hour',
  },
  {
    name: 'Funder Travel Bill Amount',
    id: 'funder_travel_bill_amount',
  },
  {
    name: 'Funder Travel Bill Mileage Per Mile',
    id: 'funder_travel_bill_mileage_per_mile',
  },
  {
    name: 'Funder travel Bill Mileage Amount',
    id: 'funder_travel_bill_mileage_amount',
  },
  {
    name: 'Schedule Travel Bill Rate Card',
    id: 'schedule_travel_bill_rate_card',
  },
  {
    name: 'Schedule Travel Bill Rate Per Hour',
    id: 'schedule_travel_bill_rate_per_hour',
  },
  {
    name: 'Schedule Travel Bill Amount',
    id: 'schedule_travel_bill_amount',
  },
  {
    name: 'Schedule Travel Bill Mileage Per Mile',
    id: 'schedule_travel_bill_mileage_per_mile',
  },
  {
    name: 'Schedule Travel Bill Mileage Amount',
    id: 'schedule_travel_bill_mileage_amount',
  },
  {
    name: 'Visit Travel Bill Rate Card',
    id: 'visit_travel_bill_rate_card',
  },
  {
    name: 'Visit Travel Bill Rate Per Hour',
    id: 'visit_travel_bill_rate_per_hour',
  },
  {
    name: 'Visit Travel Bill Amount',
    id: 'visit_travel_bill_amount',
  },
  {
    name: 'Visit Travel Bill Mileage Per Mile',
    id: 'visit_travel_bill_mileage_per_mile',
  },
  {
    name: 'Visit Travel Bill Mileage Amount',
    id: 'visit_travel_bill_mileage_amount',
  },
  {
    name: 'Cancelled Bill Percentage',
    id: 'cancelled_bill_percentage',
  },
  {
    name: 'Employee Name',
    id: 'employee_name',
  }



];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Branch Name',
    id: 'branch_name',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Funder Name',
    id: 'funder_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },
  {
    name: 'Visit Type',
    id: 'visit_type',
  },
  {
    name: 'Visit Id',
    id: 'visit_id',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Client Status',
    id: 'client_status',
  },
  {
    name: 'Category Name',
    id: 'category_name',
  },
  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Schedule Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Schedule End Time',
    id: 'schedule_end_time',
  },
  {
    name: 'Schedule Duration(mm)',
    id: 'schedule_duration_min',
  },
  {
    name: 'Schedule Duration(hh)',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Actual Start Time',
    id: 'actual_start_time',
  },
  {
    name: 'Actual End Time',
    id: 'actual_end_time',
  },
  {
    name: 'Actual Duration(hh)',
    id: 'actual_duration_hrs',
  },
  {
    name: 'Actual Duration(mm)',
    id: 'actual_duration_min',
  },
  {
    name: 'Rounded Duration(mm)',
    id: 'rounded_duration_min',
  },
  {
    name: 'Banded Duration(hh)',
    id: 'banded_duration_hrs',
  },
  {
    name: 'Funder Rate Card',
    id: 'funder_rate_card',
  },
  {
    name: 'Funder Rate',
    id: 'funder_rate',
  },
  {
    name: 'Funder Amount',
    id: 'funder_amount',
  },
  {
    name: 'Contract Visit Amt',
    id: 'contract_visit_amount',
  },
  {
    name: 'Adhoc Visit Amt',
    id: 'adhoc_visit_amount',
  },
  {
    name: 'Scheduled Billing Amt',
    id: 'schedule_billing_amount',
  },
  {
    name: 'Scheduled  Manual Billing Amt',
    id: 'schedule_manual_billing_amount',
  },
  {
    name: 'Actual Bill Amt',
    id: 'actual_billing_amount',
  },
  {
    name: 'Actual Manual Amt',
    id: 'actual_manual_billing_amount',
  },
  {
    name: 'Rounded Billing Amount',
    id: 'rounded_billing_amount',
  },
  {
    name: 'Banded Billing Amount',
    id: 'banded_billing_amount',
  },
  {
    name: 'Funder Travel Bill Rate Card',
    id: 'funder_travel_bill_rate_card',
  },
  {
    name: 'funder Travel Bill Rate Per Hour',
    id: 'funder_travel_bill_rate_per_hour',
  },
  {
    name: 'Funder Travel Bill Amount',
    id: 'funder_travel_bill_amount',
  },
  {
    name: 'Funder Travel Bill Mileage Per Mile',
    id: 'funder_travel_bill_mileage_per_mile',
  },
  {
    name: 'Funder travel Bill Mileage Amount',
    id: 'funder_travel_bill_mileage_amount',
  },
  {
    name: 'Schedule Travel Bill Rate Card',
    id: 'schedule_travel_bill_rate_card',
  },
  {
    name: 'Schedule Travel Bill Rate Per Hour',
    id: 'schedule_travel_bill_rate_per_hour',
  },
  {
    name: 'Schedule Travel Bill Amount',
    id: 'schedule_travel_bill_amount',
  },
  {
    name: 'Schedule Travel Bill Mileage Per Mile',
    id: 'schedule_travel_bill_mileage_per_mile',
  },
  {
    name: 'Schedule Travel Bill Mileage Amount',
    id: 'schedule_travel_bill_mileage_amount',
  },
  {
    name: 'Visit Travel Bill Rate Card',
    id: 'visit_travel_bill_rate_card',
  },
  {
    name: 'Visit Travel Bill Rate Per Hour',
    id: 'visit_travel_bill_rate_per_hour',
  },
  {
    name: 'Visit Travel Bill Amount',
    id: 'visit_travel_bill_amount',
  },
  {
    name: 'Visit Travel Bill Mileage Per Mile',
    id: 'visit_travel_bill_mileage_per_mile',
  },
  {
    name: 'Visit Travel Bill Mileage Amount',
    id: 'visit_travel_bill_mileage_amount',
  },
  {
    name: 'Cancelled Bill Percentage',
    id: 'cancelled_bill_percentage',
  },
  {
    name: 'Employee Name',
    id: 'employee_name',
  }



];