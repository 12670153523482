import React,{useState,Fragment,useEffect} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    FlexGrid3Container,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {setBulklVisitsTime} from "utils/api/SchedulerApi"
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
const BulkSetVisits = ({
    fullBind,
    checkedRows,
    searchScheduler,
    setBulkSetVisits,
    rightClickSelectedId,
    setRightClickSeletcedId,
    setSelectedEvents,
    clearSelectedElId
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Set Visits',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);

      const handleCheck=(e)=>{
        values.unassign_visits=e.target.checked
        setValues({...values})
      }

      const handleSubmit=()=>{
        if(errors.length>0 || loadingSave){
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues);
            return
          }
          setLoadingSave(true)
          setErrorMessage('');
          let body={
            visit_ids: rightClickSelectedId.length ? rightClickSelectedId.join(",") : checkedRows.join(","),
            start_time:values.start_time,
            end_time:values.end_time,
            earliest_start_time:values.earliest_start_time,
            latest_start_time:values.latest_start_time,
            unassign_visits:values.unassign_visits?values.unassign_visits:false
        }
        setBulklVisitsTime(body)
        .then(res=>{
            setSaveButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            searchScheduler()
            setBulkSetVisits(false)
            setRightClickSeletcedId([])
            setSelectedEvents([])
            clearSelectedElId()
        })
        .catch(err=>{
            setSaveButtonIcon(CROSS);
            setTextStatus(ERROR);
            setLoadingSave(false);
            
          })
      }

    return (
        <ModalContainer max_width={'40%'}>
        <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
              <div className={' pl-3 pr-3 pb-3'}>
    <div className="mb-2">
    <div className='d-flex'>
    <div className="pr-1 pb-2 mb-2">
    <TimeSelector
    isRequired
    labelTop={'Start Time'}
    {...useInput(`start_time`, 'isRequired')}
              />
        </div>

        <div className="pb-2 mb-2">
        <TimeSelector
        isRequired
        labelTop={'End Time'}
        {...useInput(`end_time`, 'isRequired')}
              />
            </div>
            </div>
            <div className='d-flex'>
            <div className="pr-1 pb-2 mb-2">
    <TimeSelector
                labelTop={'Earliest Start Time'}
                {...useInput(`earliest_start_time`)}
              />
        </div>
        <div className="pb-2 mb-2">
        <TimeSelector
                labelTop={'Latest Start Time'}
                {...useInput(`latest_start_time`)}
              />
            </div>
            </div>
            <FlexGrid3Item className="pr-1 pb-2">
                <div className='d-flex'>
            <input
              type="checkbox"
              onChange={(e) => {
                handleCheck(e)
              }}
            />
            <label style={{marginTop:"-5px"}} className='ml-2'>Unassign Visits</label>
            </div>
            </FlexGrid3Item>

    </div>
    <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
        </ModalDynamic>
        </ModalContainer>
   
    );
}

export default BulkSetVisits;
