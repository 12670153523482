import React, {useState, useEffect, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import {headerColumns} from 'components/DashboardComponents/Alerts/headerColumnList';

import ToggleButton from 'components/SharedComponents/ToggleButton';
import {PrimaryButton} from 'shared/styles/buttons';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {MdEdit} from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  CloseButtonWrapper,
  SearchIcon,
} from 'shared/styles/constants/tagsStyles';
import {TIMES} from 'utils/constants/icons';

import {
  Header,
  SearchAddContainer,
  SearchContainer,
  HeaderLgBreak,
  Title,
  DropdownTypesContainer,
  HeaderVisits,
  FiltersLeftGroup,
  FiltersContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';

import {
  LARGE_COL,
  EX_SMALL_COL4,
  SMALL_COL,
  EX_LARGE_COL,
  EX_SMALL_COL,
  EX_VERY_LARGE_COL,
} from 'shared/styles/constants/columns';
import {useForm} from 'hooks/FormHook';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext, BranchItemsContext} from 'context';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {getClientVisit, getSettingEvvList} from 'utils/api/AlertsApi';
import {
  settingEvv_Listing_choices,
  setting_evvListing_choices,
} from 'utils/choiceConstant';
import SettingsEvv from '.';
import {GrSearch} from 'react-icons/gr';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {Fade} from 'react-reveal';
import {EmploymentTypesContainer} from 'components/DashboardComponents/Navbar/styles';

const SettingEvvList = props => {
  const {values, setValues, useInput} = useForm({
    ordering: 'branch__name',
  });
  const [clientVisitList, setClientVisitList] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const history = useHistory();
  const [branchFilter, setBranchFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [branchID, setBranchID] = useState('');

  const {branchData} = useContext(BranchItemsContext);

  useEffect(() => {
    handleGetChoices(settingEvv_Listing_choices);
  }, []);

  const [countCheck, setCountCheck] = useState([]);

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const headerColumns = [
    {
      label: 'Branch Name',
      name: 'client__full_name',
      status: 1,
      className: 'grow-mobile',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'HandBack Auto Approval',
      name: 'triage_created_by__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'Swap Auto Approval ',
      name: 'triage_created_by__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'Offer Visit Auto Approval ',
      name: 'triage_type__name',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
  ];

  const [loadingModal, setLoadingModal] = useState(true);
  const {contextChoices, handleGetChoices} = useContext(AppContext);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;

    let date = new Date().toJSON().slice(0, 10);
    values.date_from = date;
    values.date_to = date;
    setValues({...values});
    loadClientVisit();
  }, []);
  useEffect(() => {
    loadClientVisit();
  }, [
    props?.location.search ||
      values.ordering ||
      values.search ||
      values?.limitperpage,
  ]);
  useEffect(() => {
    handleGetChoices(setting_evvListing_choices);
  }, []);

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  useEffect(() => {
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);
  const loadClientVisit = () => {
    const pageToSearch = getPageToSearchParam(
      props?.location?.search,
      setCurrentPage
    );
    let id = props?.selectedClientId ? props.selectedClientId : props.cliId;
    getSettingEvvList(
      {id, ...branchData, ...values, ...pageToSearch},
      values.limitperpage
    ).then(response => {
      if (response) {
        setLoadingModal(false);

        setCountCheck(response.count);
      }
      const newArr = response.results.map(item => {
        let newTemp = item.boolean_data.map(y => {
          return {
            is_handBack: y.is_handBack,
            is_offer_approval: y.is_offer_approval,
            is_swap: y.is_swap,
            branch: item.branch,
            id: item.id,
          };
        });
        return newTemp;
      });
      let newData = newArr.flat();
      setClientVisitList(newData);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleAllSelectChange = e => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      clientVisitList.map(triageData => {
        values.checkbox_alerdata.push({
          ...triageData,
          checkbox_rowId: triageData.id,
        });
      });
    } else {
      values.checkbox_alerdata = [];
    }
    setValues({...values});
  };
  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = id => {
      return values.checkbox_alerdata.some(function (el) {
        return el.checkbox_rowId === id;
      });
    };
    if (values.checkbox_alerdata && values.checkbox_alerdata.length > 0) {
      clientVisitList.map(triageData => {
        if (!alertCheckBoxExists(triageData.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props?.location?.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: props.location.pathname,
      search: stringified,
    });
    values.limitperpage = item.limitperpage;
    setValues({...values});
  };

  const getBranchName = ID => {
    if (ID && contextChoices.branch) {
      return contextChoices.branch.find(item => item.id == ID)?.name;
    } else {
      return null;
    }
  };
  const handleRowClick = async (name, id, index) => {
    let newArr = [];

    clientVisitList.forEach(item => {
      if (item.id === id) {
        item.is_offer_approval = !item.is_offer_approval;
      }
      newArr.push({...item});
    });
    setClientVisitList([...newArr]);
  };
  const hitSearch = value => {
    values.search = value;
    loadClientVisit();
  };
  const handleRowClickEdit = ID => {
    setIdSelected(ID);
    let selectedBranchId = clientVisitList.find(item => item.id === ID)?.branch;
    setBranchID(selectedBranchId);
    setModalValue(true);
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };

  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };
  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };
  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const handleSearch = item => {
    if (values.branch__id__in) {
      loadClientVisit();
    }
  };
  return (
    <div>
      <Title>Setting Evv List </Title>
      <Header>
        <FiltersContainer style={{marginBottom: '4px'}}>
          <FiltersLeftGroup>
            <EmploymentTypesContainer className=" mr-2">
              <NoSelectAllDropdownFilter
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({branch__id__in: items});
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={setFilterBranch}
                placeHolder={'Branch'}
                isObject
                allOption
              />
            </EmploymentTypesContainer>
          </FiltersLeftGroup>
          <PrimaryButton
            type="button"
            onClick={handleSearch}
            style={{marginLeft: '4px'}}>
            <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
          </PrimaryButton>
        </FiltersContainer>

        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}
                onChange={e => {
                  keyPressed(e);
                }}
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>
        </SearchAddContainer>
      </Header>
      {countCheck !== 0 ? (
        <div>
          <ComponentDynamic loading={loadingModal}>
            <Table
              headerColumns={headerColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}>
              <div>
                {clientVisitList &&
                  clientVisitList.map((list, index) => {
                    return (
                      <div>
                        <Row bgColor>
                          <Col max_width={LARGE_COL} Center overlap={true}>
                            {getBranchName(list.branch && list.branch)}
                          </Col>
                          <Col max_width={LARGE_COL} Center overlap={true}>
                            <ToggleButton
                              disabled={true}
                              status={list.is_handBack}
                              id="is_handBack"
                            />
                          </Col>
                          <Col max_width={LARGE_COL} Center overlap={true}>
                            <ToggleButton status={list.is_swap} id="is_swap" />
                          </Col>
                          <Col max_width={LARGE_COL} Center overlap={true}>
                            <ToggleButton
                              disabled={true}
                              status={list.is_offer_approval}
                              max_width
                              id="list.is_offer_approval"
                            />
                          </Col>
                          <Col
                            className="sm-hidden"
                            Center
                            Icon
                            Purple
                            max_width={LARGE_COL}
                            style={{display: 'flex', justifyContent: 'right'}}>
                            <IconWrapper
                              onClick={() => {
                                handleRowClickEdit(list.id);
                              }}>
                              <MdEdit />
                            </IconWrapper>
                          </Col>
                        </Row>

                        <div className="mb-1" />
                      </div>
                    );
                  })}
                <div className="mt-3">
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={clientVisitList.length}
                  />
                </div>
              </div>
            </Table>
          </ComponentDynamic>
          {modalValue && (
            <SettingsEvv
              fullBind={fullBind}
              idSelected={idSelected}
              setTitle={setTitle}
              branchID={branchID}
              loadClientVisit={loadClientVisit}
            />
          )}
        </div>
      ) : (
        <TitleSection>No data found...</TitleSection>
      )}
    </div>
  );
};

export default SettingEvvList;
