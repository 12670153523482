import React, {useState, useEffect, useRef, useContext} from 'react';
import {GrSearch} from 'react-icons/gr';
import {FaPlus} from 'react-icons/fa';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {DropdownTypesContainer} from 'shared/styles/constants/tagsStyles';
import {Header, SearchAddContainer} from './styles';
import {getClientTypes} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import {getClientsList} from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import NewVisitCare from './NewVisitCare';
import DailyWeaklyFilter from './DailyWeaklyFiter';
import {Fragment} from 'react';
import TaskFilter from './TaskFilter';
import { reports_client_choices } from 'utils/choiceConstant';
import ServiceContract from './ServiceContract';
const ClientFilters = ({
  location,
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  tabClick,
}) => {
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput} = useForm({});
  const [runRouteData, setRunRouteData] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [clientTypeFilter, setClientTypeFilter] = useState([]);
  const {contextChoices,handleGetChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const startDateValue = useInput('start_date');
  const endDateValue = useInput('end_date');
  useEffect(() => {
    handleGetChoices(reports_client_choices,)
    
  }, [])

  useEffect(() => { 
    setLoadingButton(false);
    SetDownloadDisable(false);
  },[location.pathname])

  
  const handleDownload = () => {
    setLoadingButton(true);
    SetDownloadDisable(true);
    let fileName =
      'report_client_' + activeTab + '_' + moment().format('YYYY-MM-DD');
    getClientsList({...branchData, ...values}, activeTab)
      .then(response => {
        if (response) {
          setLoadingButton(true);
          SetDownloadDisable(true);
        }
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        setTimeout(() => {
          setLoadingButton(false);
          SetDownloadDisable(false);

        }, 2000);
      })
      .catch(err => {});
    SetDownloadDisable(false);
  };

  useEffect(() => {
    loadClientTypes();
  }, []);

  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      setClientTypes(response.results);
    });
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setClientStatus(
      contextChoices.client_status &&
        contextChoices.client_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);

  const filterClientStatus = item => {
    setClientStatusFilter([...item]);
  };
  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const filterClientType = item => {
    setClientTypeFilter([...item]);
  };

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };
  console.log(tabClick,"tabClick")
  return (
    <div>
      {tabClick === 'Client_Care_Notes' ? (
        <NewVisitCare tabClick={tabClick} />
      ) : tabClick === 'ClientTaskReport' ? (
        <TaskFilter tabClick={tabClick} />
      ) : tabClick === 'ServiceContract' ?
        <ServiceContract tabClick={tabClick} />
        : (
          <div>
            <Header>
              <div className="d-flex ">
                <DropdownTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={clientTypes ? clientTypes : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_type: items });
                    }}
                    itemsSelected={clientTypeFilter}
                    setSelectedFilter={filterClientType}
                    placeHolder={'Client type'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={clientStatus ? clientStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_status: items });
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={filterClientStatus}
                    placeHolder={'Client Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="mr-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </div>
          </Header>

          {loadingButton ? (
            <Fragment>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
              <span>Downloading...</span>
            </Fragment>
          ) : (
            <Fragment />
          )}
          <SearchAddContainer className="d-flex align-items-center">
            <FloatingMobileButton
              onClick={!downloadDisable && handleDownload}
              disabled={downloadDisable}>
              <label>Download List</label>
            </FloatingMobileButton>
          </SearchAddContainer>
        </div>
      )}
    </div>
  );
};

export default ClientFilters;
