import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import ModalInformation from 'components/SharedComponents/ModalInformation';
import TimelineCalendar from 'components/SharedComponents/TimelineCalendar';
import Dropdown from 'components/SharedComponents/Dropdown';
import MultiSelectDropdown from 'components/SharedComponents/MultiSelectDropdown';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import Alert from 'react-popup-alert'
import CalendarListView from 'components/SharedComponents/CalendarListView'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { COPY_VISITS, REVERT_CLOCKED, SCHEDULER_LOCATION_VIEW, SCHEDULER_SETTINGS } from '../../../utils/constants/permissions';
import {
  PrimaryButtonForm,
  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { getEmployee, getClient, } from "../../../utils/localStorage/token"
import { getShiftDetailById, saveAddOffer, saveBulkRun } from 'utils/api/RegularVisitApi'
import {
  SCHEDULER_ASSIGNED,
  SCHEDULER_UNASSIGNED,
  WHITE,
  GRAY_820,
  TRAVEL_TIME_COLOR,
  WAIT_TIME_COLOR,
  CANCELLED_VIST,
  BREAK_WAIT_COLOR,
  PURPLE,
  SHADOW_VISIT,
  OBSERVATION_VISIT,
  NEW_DOUBLE_UP,
  DOUBLE_UP_MISSED
} from 'shared/styles/constants/colors';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import _ from 'lodash';
import { createdHandback, editCancelledVisit, getClientFundersList, getFunderDetails } from 'utils/api/ClientApi';
import { TYPE_AVAILABLE, TYPE_SHIFT, TYPE_UNAVAILABLE } from 'utils/constants/rotaShift';
import { getClients, getPayRates } from 'utils/api/ClientApi';
import { getEmployees } from 'utils/api/EmployeeApi';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {
  getScheduler,
  getVisitDetail,
  setUpdateVisit,
  runScheduler,
  getSchedulerOptaplanner,
  deleteSchedulerOptaplanner,
  deleteAllVisits,
  getWaitTime,
  cancelVisitSave,
  getClientInfoById,
  saveAdhocVisit,
  getEmployeeVisitData,
  getClientVisitData,
  getPublishVisit,
  getDownloadSchedulerFile,
  deleteVisit,
  UnassignAllVisits,
  duplicateVisits,
  setShadowVisits,
  undoCancelVisit,
  setBulklVisitsTime,
  revertVisits,
  publishOrUnpublishVisits,
  getPinnedEmp,
  setUnpinnedEmp,
  setRevertPublish,
  DragAndDropVisits,
  setDetach,
  getActiveEmp,
  getEarlyLate,
  getLocationList,
  getcommHrs,
  getPrefEmpData,
  getAdhocServiceAddress,
  getEmployeesRunRouteWise,
  copyVisits
} from 'utils/api/SchedulerApi';
import {
  Title,
  FiltersContainerRotaShifts,
  FiltersLeftGroupRotaShifts,
  FiltersTypesContainerRotaShifts,
  ButtonsModals,
  DataContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import {
  SchedulerContainer,
  MessageOptaPlanner,
  ButtonsContainer,
  ToggleDiv,
  ButtonDiv,
} from './styles';
import {
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  EVERY_WEEK,
} from 'utils/constants/serviceVisits';
import AddVisitModal from './Modals/AddVisit';
import CancelVisitModal from "./Modals/CancelVisit"
import AdjustVisitModal from "./Modals/AdjustVisit"
import AdhocVisitModal from './Modals/AdhocVisit';
import ActualEdits from './Modals/ActualEdits';
import { PLAY, ERROR_OUTLINE, COPY } from 'utils/constants/icons';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { AssignAllVisits } from "utils/api/SchedulerApi"
import moment from 'moment';
import { getPermissions, getEmail } from '../../../utils/localStorage/user';
import { getSuperAdmin } from "../../../utils/localStorage/token"
import {
  SCHEDULER_DAILY_VIEW,
  SCHEDULER_WEEKLY_VIEW,
  SCHEDULER_CLIENT_VIEW,
  SCHEDULER_TEAM_VIEW,
  SCHEDULER_RUN_VIEW,
  SCHEDULER_LIST_VIEW,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_UPDATE_PERMISSION, SELF_WEB_DELETE_PERMISSION
} from '../../../utils/constants/permissions';
import messages, {
  ERROR,
  InvalidOrEmptyValues,
  InvalidRange,
  SAVE,
  SAVED,
  SERVER_ERROR,
} from 'utils/constants/messages';
import "./popup.css";
import {
  CHECK, CROSS, FLOPPY, Home_ICON, BIKE_ICON, CAR_ICON, FOOT_ICON,
  High_PRIORITY, LOW_PRIORITY, MEDIUM_PRIORITY
} from 'utils/constants/icons';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import Spinner from 'components/SharedComponents/Spinner';
import AssignBulkVisits from "./Modals/AssignBulk"
import BulkSetVisits from "./Modals/BulkSetVisits"
import AddRegularVisit from './Modals/AddRegularVisit';
import { KeyPress } from 'utils/constants/regularExpressions';
import { getSettingEvv, setRunName } from 'utils/api/SettingsApi';
import FunderName from './Modals/FunderName';
import { useHistory, useLocation } from 'react-router-dom';
import { getAvailabilities, getRotaShifts, getSchedulerRotaShifts } from 'utils/api/RotaShift';
import RevertClocked from './Modals/RevertClocked';
import ConfirmRunSchedular from 'components/SharedComponents/ConfirmRunSchedular';
import BulkRunName from './Modals/BulkRunName';
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';
import AssignRate from './Modals/AssignRate';
import { extendMoment } from 'moment-range'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import CopySchedular from './Modals/CopySchedular';
import { scheduler_choices } from 'utils/choiceConstant';
import PlannerModal from './Planner/Modal/PlannerModal';
import PlannerAssign from './Modals/PlannerAssign';
import AttachMedication from './Modals/AttachMedication';
import queryString from 'query-string';
import { CALENDAR_DAILY, CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { AddAvailability, AddUnavailability } from '../RotaShifts/ButtonsModals';
import RotaShiftsActions from 'components/SharedComponents/RotaShiftsActions';
import { getLocationById } from 'utils/api/LocationApi';
import UpdateTravel from './Modals/UpdateTravel';
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import './schedular.css'
import ContextMenu, { useContextMenu } from '../../SharedComponents/ContextMenu/ContextMenu';
import { capitalize, minutesToTime, roundToTwoDecimals, timeToMinutes } from 'shared/methods';

import { APIErrorContainer, NoPermissionContainer } from '../Team/AddModal/styles';
import { MdOutlineRefresh } from 'react-icons/md';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const SHIFT_VALUES = 'shiftValues';

const VISITS_VALUES = 'visitValues';
const ADDRESS_VALUES = 'addressValues';
const EMPLOYEE_VALUES = 'employee';
const ADHOC_VIIST_VALUES = 'adhocValues'
const CANCEL_VISIT_VALUES = 'cancelValues'
// let VISITS_ASSIGNED = 0;
// let VISITS_PUBLISHED = 0;
// let VISITS_UNASSIGNED = 0;
// let VISITS_UNPUBLISHED = 0;
let TOTAL_VISITS = 0;
let allowSchedulerCalls = false
let intervalNeeded = false


const Scheduler = (props) => {
  let query_params_data = queryString.parse(props.location.search);
  const new_moment = extendMoment(moment);
  const [readPermissionRevertClocked, setReadPermissionRevertClocked] =
    useState(false);
  const [createPermissionRevertClocked, setCreatePermissionRevertClocked] =
    useState(false);
  const [updatePermissionRevertClocked, setUpdatePermissionRevertClocked] =
    useState(false);
  const [deletePermissionRevertClocked, setDeletePermissionRevertClocked] =
    useState(false);
  const { values, setValues, useInput, errors, setFormErrors, setCheckRequires } = useForm({
    is_location: false,
    doubleUp: "Both"
  });
  const [clients, setClients] = useState([]);
  const [plannedFilter, setPlannedFilter] = useState({});
  const [evvSettingData, setEvvSettingData] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [resourcesEmployees, setResourcesEmployees] = useState([]);
  const [resourcesRunroutes, setResourcesRunroutes] = useState([
    { id: '000', title: 'None' },
    { id: '001', title: 'Blank' },
  ]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeForFilter, setEmployeesForFilter] = useState([])
  const [timeCodes, setTimeCodes] = useState([
    { id: '111', name: "Visits" },
    { id: '222', name: 'Travel' },
    { id: '333', name: 'Wait' }
  ]);
  const [employeeVisitData, setEmployeeVisitData] = useState([])
  const [clinetVisitData, setClientVisitData] = useState([])
  const [choices, setChoices] = useState({ client_status: [] });
  const [buttonIconP] = useState(PLAY);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [loadingOptaPlanner, setLoadingOptaPlanner] = useState(false);
  const [loadingCopySch, setLoadingCopySch] = useState(false);

  const [optaplannerStatus, setOptaplannerStatus] = useState([
    { state: '', tries: 0 },
  ]);
  const [timeStatus, setTimeStatus] = useState('');
  const [downloadDisable, SetDownloadDisable] = useState(false)
  const [caller, setCaller] = useState(0);
  const [runningDate, setRunningDate] = useState('');
  const [lockButton, setLockButton] = useState(false);
  //switch between team and client view

  const [resourcesClients, setResourcesClients] = useState([]);
  const [resourcesLocations, setResourcesLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSplitMode, setIsSplitMode] = useState(false);
  const [empAvailableToggle, setEmpAvailableToggle] = useState(true);
  const [regularMode, setRegularMode] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [changeVisitDetails, setChangeVisitDetails] = useState({});
  const [showDeleteVisitsModal, setShowDeleteVisitsModal] = useState(false);
  const [showChangeVisitsModal, setShowChangeVisitsModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [assignedHours, setAssignedHours] = useState(0);
  // const [travelHours, setTravelHours] = useState(0);
  // const [travelModeHours, setTravelModeHours] = useState({});
  // const [travelHoursClientToClient, setTravelHoursClientToClient] = useState(0);
  // const [travelHoursClientToHome, setTravelHoursClientToHome] = useState(0);
  // const [travelHoursClientToOffice, setTravelHoursClientToOffice] = useState(0);
  // const [travelHoursOfficeToClient, setTravelHoursOfficeToClient] = useState(0);
  // const [travelHoursHomeToClient, setTravelHoursHomeToClient] = useState(0);
  // const [travelHoursHomeToOffice, setTravelHoursHomeToOffice] = useState(0);
  // const [travelHoursOfficeToOffice, setTravelHoursOfficeToOffice] = useState(0);
  const [waitHours, setWaitHours] = useState(0);
  const [waitHoursBetweenVisit, setWaitHoursBetweenVisit] = useState(0);
  const [waitHoursCapacity, setWaitHoursCapacity] = useState(0);

  const [waitHoursExcludeMoreThanTwo, setWaitHoursExcludeMoreThanTwo] = useState(0);
  // const [unassignedHours, setUnassignedHours] = useState(0);
  // const [publishedHours, setPublishedHours] = useState(0);
  // const [unPublishedHours, setUnPublishedHours] = useState(0);
  const [changeVisit, setChangeVisit] = useState({});
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [timeCodeFilter, setTimeCodeFilter] = useState([])
  const [finalTimeCode, setFinalTimeCode] = useState([])
  const [clientdata, setClientData] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [runRoutesclientFilter, setRunRoutesclientFilter] = useState([]);
  const [jobDepartment, setJobDepartment] = useState([]);
  const [jobDepartmentFilter, setJobDepartmentFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [branchRunSchedularFilter, setBranchRunSchedularFilter] = useState([]);
  const [visitStatusData, setVisitStatusData] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [runNameFilter, setRunNameFilter] = useState([]);
  const [serviceFilter, setServiceFilter] = useState([])
  const [serviceCategoryTypeFilter, setServiceCategoryTypeFilter] = useState([])
  const [schedularVisitId, setSchedularVisitId] = useState();
  const [iSPublished, setISPublished] = useState();
  const [scoreDataId, setScoreDataId] = useState();
  const [clientSelectedId, setclientSelectedId] = useState();
  const [idToDelete, setIdToDelete] = useState('');
  const [idSelected, setIdSelected] = useState('');
  const [clientResource, setClientResource] = useState('')
  const [employeeResource, setEmployeeResource] = useState('')
  const [clearAll, setClearAll] = useState(false);
  const { setplannerData, contextChoices, contextChoicesMap, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextSubscription, contextSubSectionPermissions, handleGetChoices ,loadingChoices, handleChangeChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [schedulerData, setSchedulerData] = useState([])
  const [rotaData, setRotaData] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingCount, setLoadingCount] = useState(0)
  const [allowSchedulerCalls, setAllowSchedulerCalls] = useState(false)
  const [loadingModal, setLoadingModal] = useState(true)
  const [pinnedData, setPinnedData] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedRowsEmp, setCheckedRowsEmp] = useState([])
  const [listView, setListView] = useState(false)
  const [checkedValue, setCheckedValue] = useState(true)
  const [runNameData, setRunNameData] = useState([])
  const [employeeListing, setEmployeeListing] = useState([])
  const [shadowVisit, setShadowVisit] = useState(false)
  const [checkedRunRadio, setcheckedRunRadio] = useState('All');
  const [checkedFreezeVisits, setcheckedFreezeVisits] = useState([]);
  const [planOnlyToggle, setPlanOnlyToggle] = useState(true);

  const [checkedDelete, setcheckedDelete] = useState('all');
  const [checkedRunRegular, setcheckedRunRegular] = useState(false);
  const [considerTimeRes, setconsiderTimeRes] = useState(false);
  const [considerEmpRota, setconsiderEmpRota] = useState(true);
  const [overrideWindow, setOverrideWindow] = useState(false);
  const [overrideWindowMinutes, setOverrideWindowMinutes] = useState(0);
  const [undoCancel, setUndoCancel] = useState(false)
  const [actionType, setActionType] = useState('')
  const [dataValues, setDataValues] = useState([]);
  const [loadingpas, setLodingPas] = useState(null)
  const [pinnedDayId, setPinnedDayId] = useState('');
  const [travelRate, setTravelRate] = useState([]);
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [evvVal, setEvvVal] = useState(null);
  const [preferredData, setPreferredData] = useState({});
  const [disableDeletePopupConfirm, setDisableDeletePopupConfirm] = useState(false)
  const [newRun, setNewRun] = useState(false);
  const [funderList, setFunderList] = useState([])
  const [funderNameFilter, setFunderNameFilter] = useState([])
  const [payTypeNameFilter, setPayTypeNameFilter] = useState([])
  const [doubleUpFilter, setDoubleUpFilter] = useState([])
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])
  const [loadingNew, setLoadingNew] = useState(false);  //loader visit popup

  //rotashift hooks
  const [errorTime, setErrorTime] = useState({});
  const [dateMessage, setDateMessage] = useState('Is required');
  const [timeMessage, setTimeMessage] = useState('Is required');
  const [isDateAssigned, setIsDateAssigned] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [disableButton, setDisableButton] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [detailId, setDetailId] = useState('');
  const [employeeTempOnPopup, setEmployeeTempOnPopup] = useState([]);
  const [shiftTempOnPopup, setShiftTempOnPopup] = useState('');
  const [shiftTypePopup, setShiftTypePopup] = useState('');
  // const [shiftTypeChecked, setShiftTypeChecked] = useState([]);
  const [availabilityDetailsDays, setAvailabilityDetailsDays] = useState();
  const [employeeDisable, setEmployeeDisable] = useState(true);
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [invalidOvernight, setInvalidOvernight] = useState(false);
  const [TravelData, setTravelData] = useState([]);
  const [listOfLocations, setListOfLocations] = useState([])
  const [locationDropdownItem, setLocationDropdownItem] = useState([])
  const [locationsFilter, setLocationsFilter] = useState([]);
  const [rightClickSelectedId, setRightClickSeletcedId] = useState([])
  const [compactViewToggle, setCompactViewToggle] = useState(false);
  const [apiError, setApiError] = useState({ status: false, message: "" })
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isMultiEventSelect, setIsMultiEventSelect] = useState(false)

  const {
    setValue: setAddUnavailability,
    value: addUnavailability,
    fullBind: fullBindAddUnavailability,
  } = useModal(false);
  const {
    setValue: setAddAvailability,
    value: addAvailability,
    fullBind: fullBindAddAvailability,
  } = useModal(false);

  const [availabilitiesEmployeeId, setAvailabilitiesEmployeeId] = useState([]);
  const [availabilitiesEmployeeFullName, setAvailabilitiesEmployeeFullName] =
    useState([]);
  const [availabilitiesEmployeeIdName, setAvailabilitiesEmployeeIdName] =
    useState([]);

  const [filterExcludeCheck, setFilterExcludeCheck] = useState({ client: false, employee: false, location: false });
  const [readPermission, setReadPermissions] = useState(false);
  const [readSelf, setReadSelfPermissions] = useState(false)
  const [copyVisitPermission, setCopyVisitPermission] = useState(false)
  const filterEmployee = getEmployee()
  const filterClient = getClient()
  const filterStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const [calendarView, setCalendarView] = useState('');
  const [empData, setEmpData] = useState([]);
  const [commHrsData, setCommHrsData] = useState([]);
  const [deleteVisitDisabled, setDeleteVisitDisabled] = useState(false);

  const toast = useRef()
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [decreaseWidth, setDecreaseWidth] = useState(true);

  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', dayValue: 1, selected: false },
    { day: 'TUE', dayValue: 2, selected: false },
    { day: 'WED', dayValue: 3, selected: false },
    { day: 'THU', dayValue: 4, selected: false },
    { day: 'FRI', dayValue: 5, selected: false },
    { day: 'SAT', dayValue: 6, selected: false },
    { day: 'SUN', dayValue: 7, selected: false },
  ]);

  // const TRAVEL_CODE = {
  //   CLIENT_CLIENT: 'CLIENT-CLIENT',
  //   CLIENT_OFFICE: 'CLIENT-OFFICE',
  //   OFFICE_CLIENT: 'OFFICE-CLIENT',
  //   CLIENT_HOME: 'CLIENT-HOME',
  //   HOME_CLIENT: 'HOME-CLIENT',
  //   HOME_OFFICE: 'HOME-OFFICE',
  //   OFFICE_OFFICE: 'OFFICE-OFFICE'
  // }

  const travelCodes = ['CLIENT-CLIENT', 'CLIENT-OFFICE', 'OFFICE-CLIENT', 'CLIENT-HOME', 'HOME-CLIENT', 'HOME-OFFICE', 'OFFICE-OFFICE'];


  // const [travelHoursClientToClientShift, setTravelHoursClientToClientShift] = useState(0);
  // const [travelHoursClientToOfficeShift, setTravelHoursClientToOfficeShift] = useState(0);
  // const [travelHoursOfficeToClientShift, setTravelHoursOfficeToClientShift] = useState(0);
  // const [travelHoursClientToHomeShift, setTravelHoursClientToHomeShift] = useState(0);
  // const [travelHoursHomeToClientShift, setTravelHoursHomeToClientShift] = useState(0);
  // const [travelHoursHomeToOfficeShift, setTravelHoursHomeToOfficeShift] = useState(0);
  // const [travelHoursOfficeToOfficeShift, setTravelHoursOfficeToOfficeShift] = useState(0);


  const [visitScreenData, setVisitScreenData] = useState();



  const [requireDayOfWeek, setRequireDayOfWeek] = useState(false);
  const history = useHistory();

  //permissions

  const [viewPermissions, setViewPermissions] = useState({});
  const [clientDropdownId, setClientDropdownId] = useState();
  const [disablePublishButton, setDisablePublishButton] = useState(false)
  const [disableUnPublishButton, setDisableUnPublishButton] = useState(false)
  let optaStatus = ''
  const mountedRef = useRef(true);
  let addCaller = null
  if (!values[VISITS_VALUES]) {
    values[VISITS_VALUES] = {};
  }
  if (!values[ADHOC_VIIST_VALUES]) {
    values[ADHOC_VIIST_VALUES] = {};
  }
  if (!values[CANCEL_VISIT_VALUES]) {
    values[CANCEL_VISIT_VALUES] = {};
  }
  const {
    setValue: setAddVisit,
    value: addVisit,
    fullBind: fullBindAddVisit,
  } = useModal(false);
  const {
    setValue: setAddRegularVisit,
    value: addRegularVisit,
    fullBind: fullBindAddRegularVisit,
  } = useModal(false);
  const {
    setValue: setAddBulkRunName,
    value: addBulkRunName,
    fullBind: fullBindAddBulkRunName,
  } = useModal(false);
  const {
    setValue: setCancelVisit,
    value: cancelVisit,
    fullBind: fullBindCancelVisit,
  } = useModal(false);
  const {
    setValue: setAssignVisits,
    value: assignVisits,
    fullBind: fullBindAssignVisit,
  } = useModal(false);
  const {
    setValue: setFunderName,
    value: funderName,
    fullBind: fullBindFunderName,
  } = useModal(false);
  const {
    setValue: setAssignRate,
    value: assignRate,
    fullBind: fullBindAssignRate,
  } = useModal(false);
  const {
    setValue: setAssignMed,
    value: assignMed,
    fullBind: fullBindAssignMed,
  } = useModal(false);
  const {
    setValue: setAdjustVisits,
    value: adjustVisits,
    fullBind: fullBindAdjustVisit,
  } = useModal(false);
  const {
    setValue: setBulkSetVisits,
    value: bulkSetVisits,
    fullBind: fullBindBulkSetVisit,
  } = useModal(false);
  const {
    setValue: setActualEditsVisits,
    value: actualEditsVisits,
    fullBind: fullBindActualEditsVisit,
  } = useModal(false);
  const {
    setValue: setPlannerSchVisits,
    value: plannerSchVisits,
    fullBind: fullBindPlannerSchVisit,
  } = useModal(false);
  const {
    setValue: setPlannerSchAssign,
    value: plannerSchAssign,
    fullBind: fullBindPlannerSchAssign,
  } = useModal(false);
  const {
    setValue: setModalRevertClockedValue,
    value: revertClocked,
    fullBind: fullBindRevertClocked,
  } = useModal(false);
  const {
    setValue: setAdhocVisit,
    value: adhocVisit,
    fullBind: fullBindAdhocVisit,
  } = useModal(false);
  const {
    setValue: setCopySch,
    value: copySch,
    fullBind: fullBindCopySch,
  } = useModal(false);
  const { setValue: setOptaPlannerMessage, bind: bindOptaPlannerMessage } =
    useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
  const { setValue: setModalDragAndDropValue, bind: bindModalDragAndDrop } = useModal(false);
  const { setValue: setModalWarningValue, bind: bindModalWarning } = useModal(false);
  const { setValue: setModalUnassignValue, bind: bindModalUnassign } = useModal(false);
  const { setValue: setModalRevertValue, bind: bindModalRevert } = useModal(false);
  const { setValue: setModalPublishOrUnpublishValue, bind: bindModalPublishOrUnpublish } = useModal(false);
  const { setValue: setModalConfirmRegularVisit, bind: bindModalConfirmRegularVisit } = useModal(false);
  const { setValue: setModalDuplicateValue, bind: bindModalDuplicate } = useModal(false);
  const { setValue: setModalRunSchedule, bind: bindModalRunSchedule } = useModal(false);
  const { setValue: setModalPinnedEmp, bind: bindModalPinnedEmp } = useModal(false);
  const { setValue: setModalDeleteVisits, bind: bindModalDeleteVisits } = useModal(false);
  const { setValue: setModalDetachRun, bind: bindModalDetachRun } = useModal(false);
  const { setValue: setModalHandback, bind: bindModalHandback } = useModal(false);
  const { setValue: setModalUpdateTravel, value: valueUpdateTravelModal, bind: bindModalUpdateTravel } = useModal(false);
  const { setValue: setModalCopyVisit, value: valueCopyVisitModal, bind: bindModalCopyVisit } = useModal(false);

  useEffect(() => {
    console.log(new Date(), "1111111");

     handleDefaultPayTypeFilter()
    handleGetChoices(scheduler_choices)
    getListOfLocations()
    getEvvValidation()
    getPreferredEmp()
    getPayRates().then(res => {
      setTravelData(res || []);
    });
    getOptaplannerStatus()

    // Cleanup function to set mountedRef.current to false
    return () => {
      mountedRef.current = false;
    };
    console.log(new Date(), "1111111");
  }, [])

  useEffect(() => {
    console.log(new Date(), "333333");
    loadChoices();
    console.log(new Date(), "333333");
  }, [contextChoices, listOfLocations])
  // useEffect(() => {
  //   return () => {
  //     mountedRef.current = false;
  //   };
  // }, []);

  const getEvvValidation = async () => {
    const response = await getEarlyLate()
    if (response.data) {
      const data_travel = response.data.ClockValidationCheck[0]
      setEvvSettingData({ ...data_travel });
      setEvvVal(response.data.VisitTimeClassified[0])
    }
  }

  const getPreferredEmp = async () => {
    const response = await getPrefEmpData()
    if (response) {
      setPreferredData(response)
    }
  }

  useEffect(() => {
    console.log(new Date(), "22222");
    if (values.start_date && values.end_date) {
      funderLists();
      getClientCommissionedHours()
    }
    console.log(new Date(), "22222");
  }, [values.start_date, values.end_date])

  const funderLists = () => {
    let start = moment(values.start_date).format('YYYY-MM-DD')
    let end = moment(values.end_date).format('YYYY-MM-DD')

    getClientFundersList({ date_from: start, date_to: end })
      .then(res => {
        if (res.data && (res.data.length > 0)) {
          let newFunders = res.data.map(funder => {
            return {
              id: funder.id,
              is_client: funder.is_client,
              name: funder.name
            }
          })
          setFunderList([...newFunders])
        }
      })
  }

  // useEffect(() => {
  //   getEvvValidation()
  //   getPreferredEmp()
  // }, [])

  // useEffect(() => {
  //   getPayRates().then(res => {
  //     setTravelData(res || []);
  //   });
  // }, []);



  useEffect(() => {
    console.log(new Date(), "44444");
    if (!(viewPermissions === {})) {
      if (viewPermissions.client || viewPermissions.clientSelf) {
        if (filterClient !== 'null') {
          setCalendarView('client')
        } else {
          setCalendarView('team')
        }
      } else if (viewPermissions.team || viewPermissions.teamSelf) {
        setCalendarView('team')
      } else if (viewPermissions?.location || viewPermissions?.locationSelf) {
        setCalendarView('location')
      } else if (viewPermissions.run || viewPermissions.runSelf) {
        setCalendarView('run')
      } else if (viewPermissions.list || viewPermissions.listSelf) {
        setListView(true)
      }
    }
    console.log(new Date(), "44444");
  }, [viewPermissions, filterClient])


  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }
  const setEmloyeefilter = (item) => {
    setEmployeesFilter([...item])
  }
  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  const setRunrouteclientfilter = (item) => {
    setRunRoutesclientFilter([...item])
  }
  const setJobdeptfilter = (item) => {
    setJobDepartmentFilter([...item])
  }

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }

  const setFilterBranchRun = (item) => {
    setBranchRunSchedularFilter([...item])
  }

  const setVisitStatusfilter = (item) => {
    setVisitStatusFilter([...item])
  }
  const handleRunNameFilter = (item) => {
    setRunNameFilter([...item])
  }
  const handleService = (item) => {
    setServiceFilter([...item])
  }
  const handleServiceCategoryType = (item) => {
    setServiceCategoryTypeFilter([...item])
  }
  const handleSelectChange = (e, visit) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.id])
      setCheckedRowsEmp([...checkedRowsEmp, visit.employee_name])
    } else {
      let index = checkedRows.indexOf(visit.id)
      let indexEmp = checkedRowsEmp.indexOf(visit.employee_name)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
      if (indexEmp != -1) {
        let tempCheckedRowsEmp = [...checkedRowsEmp]
        tempCheckedRowsEmp.splice(indexEmp, 1)
        setCheckedRowsEmp([...tempCheckedRowsEmp])
      }



    }
  }

  // useEffect(() => {
  //   values.shiftType = shiftType;
  //   setValues({ ...values });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [shiftType]);

  // useEffect(() => {
  //   if (shiftType !== '' && calendarView !== '' && allowSchedulerCalls) {
  //     getVisitData(calendarView)
  //   }
  // }, [
  //   //allowSchedulerCalls,
  //   values.employees,
  //   values.client,
  //  // values.availability,
  //  // shiftType,
  //   values.runRouteData,
  //   values.jobDepartment,
  //   // values.runroutes,
  //   values.runroutesclient,
  //   values.runroutesteam,
  //   values.branch,
  //   values.visitStatus,
  //   values.run_view_name,
  //   branchData,
  // ]);


  // useEffect(() => {
  //   if (values.start_date, (employeeListing.length > 0)) {
  //     getEmployeeActiveData()
  //   }
  // }, [values.start_date, employeeListing.length, branchData])
  // const getRunRouteEmployee = async () => {
  //   if (values.runroutesteam && values.runroutesteam.length) {
  //     const response = await getEmployeesRunRouteWise(values)
  //     console.log(new Date(),response.data, 'getEmployeesRunRouteWise')
  //     if (response.data) {
  //       let formatEmp = response.data.map(emp => ({ id: emp.employee_id, full_name: emp.employee_name }))
  //       setEmpData(formatEmp || [])
  //       updateEmployeeResource(formatEmp)
  //     }
  //   } else {
  //     getEmployeeActiveData()
  //   }
  // }

  // const getEmployeeActiveData = async () => {
  // const date_val = moment(values.end_date).format('YYYY-MM-DD')
  // const view = (shiftType === CALENDAR_WEEKLY) ? true : false;
  // const response = await getActiveEmp(date_val, view);
  // if (response.data) {
  //   setEmpData(response.data || [])
  //   updateEmployeeResource(response.data)
  // }
  // }

  const getClientCommissionedHours = async () => {
    const date_val_start = moment(values.end_date).format('YYYY-MM-DD')
    const date_val_end = moment(values.end_date).format('YYYY-MM-DD')
    let body = {
      start_date: date_val_start,
      end_date: date_val_end
    }
    const response = await getcommHrs(body);
    if (response) {
      setCommHrsData(response)
    }
  }

  const updateEmployeeResource = (res) => {
    let employeesResources = []
    // res.forEach(employee => {
    //   if (values.branch && values.branch.length > 0) {
    //     let filtered_branch_data = []
    //     values.branch.forEach((a) => {
    //       const emp_branch = employeeListing?.filter((item) => item.branch == a.id);
    //       filtered_branch_data = [...filtered_branch_data, ...emp_branch]
    //     })
    //     filtered_branch_data.forEach((val) => {
    //       if (val.id == employee.id) {
    //         employeesResources.push({
    //           id: employee.id,
    //           title: employee.full_name,
    //         })
    //       }
    //     })
    //   } else if (branchData && branchData.headerbranch && branchData.headerbranch.length > 0) {
    //     let filtered_branch_data = []
    //     branchData.headerbranch.forEach((a) => {
    //       const emp_branch = employeeListing?.filter((item) => item.branch == a.id);
    //       filtered_branch_data = [...filtered_branch_data, ...emp_branch]
    //     })
    //     filtered_branch_data.forEach((val) => {
    //       if (val.id == employee.id) {
    //         employeesResources.push({
    //           id: employee.id,
    //           title: employee.full_name,
    //         })
    //       }
    //     })
    //   } else {
    //     employeesResources.push({
    //       id: employee.id,
    //       title: employee.full_name,
    //     })
    //   }
    // });

    if (!readPermission && readSelf && !contextIsAdmin && !contextIsSuperAdmin) {
      employeesResources = res?.filter((selfPer) => selfPer.id == filterEmployee)
    } else {
      employeesResources = [...res]
    }
    let emp = employeesResources && employeesResources.map(item => ({ id: item.id, title: item.full_name }))
    setResourcesEmployees([{ id: "0000", title: "Unassigned" }, ...emp]);
  }

  //  useEffect(() => {
  //   if (shiftType !== '' && calendarView !== '' && allowSchedulerCalls) {
  //     getVisitData(calendarView)
  //   }
  // }, [
  //   allowSchedulerCalls,
  //   values.availability,
  //   shiftType,
  //   branchData,
  // ]);

  // useEffect(()=>{
  //   if(employeeVisitData && employeeVisitData.length > 0){
  //     updateSchData(schedulerData, rotaData, calendarView)
  //   }
  // },[employeeVisitData])

  // useEffect(() => {
  //   if (values.end_date, shiftType) {
  //     getEmployeeActiveData()
  //   }
  // }, [values.end_date, shiftType])

  // useEffect(() => {
  //   if (values.end_date, values.start_date) {
  //     getClientCommissionedHours()
  //   }
  // }, [values.end_date])


  useEffect(() => {
    if (readPermission || readSelf || contextIsAdmin || contextIsSuperAdmin) {
      updateEmployeeResource(empData)
    }
    console.log(new Date(), "55555");
  }, [empData, readPermission, readSelf, contextIsAdmin])

  useEffect(() => {
    console.log(new Date(), "666666");
    if (query_params_data.start) {
      values.start_date = query_params_data.start;
      values.end_date = query_params_data.end;
      values.choose_date = query_params_data.start;
      setValues({ ...values })
    }
    console.log(new Date(), "666666");
  }, [query_params_data.start])

  useEffect(() => {
    if (shiftType !== '' && calendarView !== '' && allowSchedulerCalls && evvVal ) {
      //   if (!empData || (empData.length == 0)) {
      //     getEmployeeActiveData()
      //  } else {
      //  debugger
      //  if(empData && empData.length > 0){
      searchScheduler();
      //  }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.start_date,
    values.loadPayType,
    allowSchedulerCalls,
    values.availability,
    shiftType,
    branchData,
    employeeListing?.length,
    // empData.length,
    evvVal,
  ]);




  useEffect(() => {
    console.log(new Date(), "7777777");

    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {

        // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];


      }
        // }
      );
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
      }

      );
    }
    if (contextIsSuperAdmin) {
      setViewPermissions({
        dailySelf: true,
        weeklySelf: true,
        clientSelf: true,
        teamSelf: true,
        listSelf: true,
        runSelf: true,
        daily: true,
        weekly: true,
        client: true,
        team: true,
        list: true,
        run: true,
        location: true
      })
      setReadPermissions(true);
      setReadSelfPermissions(true);
      setCopyVisitPermission(true)
      //added during optimization
      setReadPermissionRevertClocked(true)
      setCreatePermissionRevertClocked(true)
      setUpdatePermissionRevertClocked(true)
      setDeletePermissionRevertClocked(true)
    } else if (contextIsAdmin) {
      setViewPermissions({
        dailySelf: true,
        weeklySelf: true,
        clientSelf: true,
        teamSelf: true,
        listSelf: true,
        runSelf: true,
        daily: true,
        weekly: true,
        client: true,
        team: true,
        list: true,
        run: true,
        location: true
      })
      setReadPermissions(true);
      setReadSelfPermissions(true);
      setCopyVisitPermission(true)

      //added during optimization
      setReadPermissionRevertClocked(contextSubscription?.[REVERT_CLOCKED]?.[WEB_PERMISSION]);
      setCreatePermissionRevertClocked(true)
      setUpdatePermissionRevertClocked(true)
      setDeletePermissionRevertClocked(true)
    }
    else {
      setViewPermissions({
        dailySelf: rolePermissions[SCHEDULER_DAILY_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        weeklySelf: rolePermissions[SCHEDULER_WEEKLY_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        clientSelf: rolePermissions[SCHEDULER_CLIENT_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        teamSelf: rolePermissions[SCHEDULER_TEAM_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        listSelf: rolePermissions[SCHEDULER_LIST_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        runSelf: rolePermissions[SCHEDULER_RUN_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],
        locationSelf: rolePermissions[SCHEDULER_LOCATION_VIEW]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION],

        daily: rolePermissions[SCHEDULER_DAILY_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        weekly: rolePermissions[SCHEDULER_WEEKLY_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        client: rolePermissions[SCHEDULER_CLIENT_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        team: rolePermissions[SCHEDULER_TEAM_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        list: rolePermissions[SCHEDULER_LIST_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        run: rolePermissions[SCHEDULER_RUN_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION],
        location: rolePermissions[SCHEDULER_LOCATION_VIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
      })
      setReadPermissions(
        rolePermissions[SCHEDULER_SETTINGS][WEB_PERMISSION][
        WEB_READ_PERMISSION
        ]
      );
      setReadSelfPermissions(
        rolePermissions[SCHEDULER_SETTINGS][WEB_PERMISSION][
        SELF_WEB_READ_PERMISSION
        ]
      );

      if (rolePermissions[COPY_VISITS] !== undefined) {
        setCopyVisitPermission(
          rolePermissions[COPY_VISITS][WEB_PERMISSION][WEB_CREATE_PERMISSION]
          || rolePermissions[COPY_VISITS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
      }
    
      //added during optimization
      if (rolePermissions[REVERT_CLOCKED] !== undefined) {
        setReadPermissionRevertClocked(
          contextSubscription?.[REVERT_CLOCKED]?.[WEB_PERMISSION] &&
          rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
        );
        setUpdatePermissionRevertClocked(
          contextSubscription?.[REVERT_CLOCKED]?.[WEB_PERMISSION] &&
          rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
        );
        setCreatePermissionRevertClocked(
          contextSubscription?.[REVERT_CLOCKED]?.[WEB_PERMISSION] &&
          rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
        );
        setDeletePermissionRevertClocked(
          contextSubscription?.[REVERT_CLOCKED]?.[WEB_PERMISSION] &&
          rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
          (rolePermissions[REVERT_CLOCKED][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
        );

      }
    }
    console.log(new Date(), "7777777");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSubscription, contextChoices, contextIsAdmin, contextIsSuperAdmin, contextSubSectionPermissions, contextPermissions]);





  const handleDownlaod = () => {

    let fileName = "Schedular_" + moment(values.start_date).format('YYYY-MM-DD') + "_" + moment(values.end_date).format('YYYY-MM-DD')
    SetDownloadDisable(true)
    getDownloadSchedulerFile(values).then(response => {
      response && response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        SetDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        SetDownloadDisable(false)
      })
  }

  const handleSaveAdhocVist = async (timeError) => {
    if (values.adhocValues.full_day) {
      values.adhocValues.preferred_start_time = '00:00';
      values.adhocValues.preferred_end_time = '23:59:59';
    }
    let errorValue = values.adhocValues.full_day ? false : errors.length > 0;
    if (errorValue || !values.adhocValues.client ||
      !values.adhocValues.service_category ||
      (!values.adhocValues.preferred_start_time && !values.adhocValues.preferred_end_time) ||
      timeError
    ) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true);
    let employee_arr = values.adhocValues.employee_required.map(emp => {
      let skill_arr = emp.skills && emp.skills.map((skill) => skill.value);
      let competency_arr = emp.competency && emp.competency.map((data) => data.value);
      // let specific_group_ids = emp.specific_groups?.map(obj => {
      //   return { specific_group_id: obj.value }
      // })
      return (
        {
          client_service_visits_requirement: {
            no_of_employees: emp.no_of_employees,
            start_time: emp.start_time ? emp.start_time : null,
            end_time: emp.end_time ? emp.end_time : null,
            duration: emp.duration !== 0 ? moment.utc(emp.duration * 1000).format('HH:mm') : null,
            preferred_gender: emp.preferred_gender ? emp.preferred_gender : null,
            skill_level_id: emp.skill_level,
            // pay_rates: emp.pay_rates
          },
          // specific_groups: specific_group_ids,
          client_service_visits_requirement_competency: {
            competency: competency_arr,
          },
          client_service_visits_requirement_skill: {
            skill: skill_arr
          }
        }
      )
    })

    let plannedtask = values.adhocValues.planned_task && values.adhocValues.planned_task.length ?
      values.adhocValues.planned_task.map(item => ({
        effective_from: item.effective_from || null,
        effective_to: item.effective_to || null,
        ...(item.id && { id: item.id }),
        tasks: item.tasks && item.tasks.length ?
          item.tasks.map((task, index) => ({
            support_level: task.support_level !== undefined ? task.support_level : "",
            care_task: task.care_task_data !== undefined ? task.care_task_data : "",
            care_category: task.care_category !== undefined ? task.care_category : "",
            task_type: task.task_type !== undefined ? task.task_type : "",
            message: task.message !== undefined ? task.message : "",
            ordered: Number(index + 1),
            is_essential: task.is_essential !== undefined ? task.is_essential : false,
            ...(task.id && { id: task.id }),
          })) : []
      })) : [];


    console.log(values.adhocValues.medication, "medication")
    let medication = values.adhocValues.medication
      && values.adhocValues.medication.length
      && values.adhocValues.medication.map((item, medIndex) => ({
        ...(item.id && { id: item.id }),
        effective_from: item.effective_from || null,
        effective_to: item.effective_to || null,

        doseTime: {
          ...(item.dose_id && { id: item.dose_id }),
          medication_dose_timing: item.doseTime || null,
          prescriptions: [item.prescriptions] || []
        }

      }))


    const body = {
      client_service_visit: {
        time_critical: values.adhocValues.time_critical ? values.adhocValues.time_critical : false,
        preferred_start_time: values.adhocValues.preferred_start_time + ":00",
        preferred_end_time: values.adhocValues.full_day ? values.adhocValues.preferred_end_time : values.adhocValues.preferred_end_time + ":00",
        service_category_id: values.adhocValues.service_category,
        duration: values.adhocValues.full ? '23:59:59' : moment.utc(values.adhocValues.duration * 1000).format('HH:mm'),
        visit_type_id: values.adhocValues.visit_type,
        visit_priority_id: values.adhocValues.visit_priority,
        skill_level_id: values.adhocValues.job_title,
        start_date: values.adhocValues.start_date,
        end_date: values.adhocValues.end_date,
        manualAssigned: values.manualAssigned,
        is_overnight: values.adhocValues.is_overnight ? values.adhocValues.is_overnight : false,
        type_id: values.adhocValues.client_type,
        service_destination_id: values.adhocValues.service_destination_id && values.adhocValues.service_destination_id,
        client_id: values.adhocValues.client,
        service_address_id: values.adhocValues.selected_service_address,
        notes: values.adhocValues.notes,
        // bill_rates: values.adhocValues.bill_rates,
      },

      funder_data: {
        self_funded: values.adhocValues.self_funded == 1 ? true : false,
        funder: values.adhocValues.funder ? values.adhocValues.funder : null,
        category: values.adhocValues.category,
      },

      grouping: employee_arr,
      planned_task: plannedtask,
      medication: medication

    }
    await saveAdhocVisit(body)
      .then(res => {
        setSaveButtonIcon(CHECK);
        setErrorMessage('');
        setTextStatus(SAVED);
        setLoadingSave(false);
        searchScheduler()
        setAdhocVisit(!adhocVisit);
      })
      .catch(error => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setErrorMessage("Error in saving Visit")
        setLoadingSave(false);
      });
  }

  const PlannerClick = id => {
    setplannerData(resourcesEmployees, events, plannedFilter)
    const start_d = moment(values.start_date).format('YYYY-MM-DD')
    const end_d = moment(values.end_date).format('YYYY-MM-DD')
    history.push({
      pathname: `/scheduler/planner/${id}`,
      search: `?start=${start_d}&end=${end_d}`,
      // state: {
      //   // resources : resourcesEmployees,
      //   // events: events
      // }
    })
  };

  const changeCalendar = (view) => {
    setCalendarView(view)
    calenderChange(view)
    setIsMultiEventSelect(false)
    setSelectedEvents([])
    switch (view) {
      case "client":
      case "location":
      case "team":
      case "run":
        setListView(false)
        break;
      case "list":
        setListView(true);
    }
    // getVisitData(view)
  }

  useEffect(() => {
    console.log(new Date(), "13333");
    if (calendarView && listOfLocations && schedulerData && rotaData && loadingCount >= 3) {
      updateSchData(schedulerData, rotaData, calendarView)
      console.log(new Date(), "13333");
    }
  }, [calendarView, listOfLocations, rotaData, schedulerData])

  const updateSchData = (sch_data, rota_shift_data, view) => {
    // console.log(new Date(),calendarView, "calendarView")
    let final_wait_data = []
    // console.log(new Date(),'current in schedulr start', new Date())
    // let shift_availability = {}
    let data = []
    let locationData = []
    TOTAL_VISITS = 0;
    let travel_data = []
    let shift_data = []
    let runViewData = {};
    let allEmployeeData = []
    // let wait_gap_visits = []
    // let wait_gap_visits_less_two = []
    // let wait_hr_capacity = []
    // let locationresources = []
    // let activeEmpMap = {}
    let visit_tooltip_data = [];

    // visit_tooltip_data= empData.map((item) => {
    //   activeEmpMap[item.id] = item
    //   let obj = {
    //     employee_id: item.id,
    //     employee_name: item.full_name,
    //     contracted_no_hours: item.contractedHours,
    //     max_hour_per_week: item.maxHourPerWeek,
    //     actual_travel_miles: 0,
    //     duration: 0,
    //     no_visits: 0,
    //     remaining_contracted_desired_hrs: 0,
    //     remaining_desired_hrs: 0,
    //     schedule_travel_time: 0,
    //     total_visit_hours: 0,
    //     default_travel: item.travel_mode,
    //     phone_number: item.phone_number
    //   }
    //   return obj;
    // })

    // let doubleUpVisits = {}
    // let allassignedEmployees = []
    // sch_data && sch_data.forEach(scheduler => {
    // scheduler.visits.forEach(detail => {


    // if (detail.employee_id && !allassignedEmployees?.includes(detail.employee_id)) {
    //   allassignedEmployees.push(detail.employee_id)
    // }


    // if (doubleUpVisits.hasOwnProperty(`${detail.client_service_visit_id}${detail.visit_start_date}${detail.visit_end_date}`)) {
    //     doubleUpVisits[`${detail.client_service_visit_id}${detail.visit_start_date}${detail.visit_end_date}`] += 1
    //   } else {
    //     doubleUpVisits[`${detail.client_service_visit_id}${detail.visit_start_date}${detail.visit_end_date}`] = 1
    //   }
    // })
    // })


    // allassignedEmployees && allassignedEmployees.map(id => {
    //   const data = activeEmpMap && activeEmpMap[id]
    //   if (!data) {
    //     let match = contextChoicesMap?.employee_listing && contextChoicesMap?.employee_listing[id]
    //     let obj = {
    //       id: match && match?.id,
    //       full_name: match && match?.full_name,
    //       contractedHours: null,
    //       maxHourPerWeek: null,
    //       travel_mode: null,
    //       phone_number: null,
    //     };
    //     setEmpData([...empData, obj])
    //   }
    // })

    // console.log(new Date(),allassignedEmployees, "doubleUpVisits")


    sch_data.length && sch_data.forEach(scheduler => {

      TOTAL_VISITS = TOTAL_VISITS + scheduler.visits.length;
      // let uniquie_visit_id = []

      scheduler.visits.forEach(detail => {
        // if (!uniquie_visit_id.find(id => id === detail.id)) {
        //   uniquie_visit_id.push(detail.id)
        //   if (detail.unassigned != undefined) {

        //     if (!detail.unassigned) {
        //       // VISITS_ASSIGNED += 1;
        //     } else {
        //       VISITS_UNASSIGNED += 1;
        //     }
        //     if (detail.is_published) {
        //       VISITS_PUBLISHED += 1
        //     } else {
        //       VISITS_UNPUBLISHED += 1
        //     }
        //   }

        // }

        let client_gp_area_str = ''
        let emp_gp_area_str = ''


        if (scheduler && scheduler.client_group_area && (scheduler.client_group_area.length > 0)) {
          // scheduler.client_group_area.forEach((cga) => {
          //   // let obj_n = runRouteData?.find((rn) => rn.id == cga).name;
          //   let obj_n = contextChoicesMap?.run_routes && contextChoicesMap?.run_routes[cga]?.name
          //   if (obj_n) {
          //     client_gp_area_str = client_gp_area_str + obj_n
          //   }
          // })

          client_gp_area_str = scheduler?.client_group_area?.map(item => item?.name)?.join();
        }


        if (detail && detail.employee_group_area && (detail.employee_group_area.length > 0)) {
          // scheduler.employee_group_area.forEach((cga) => {
          //   // let obj_n = runRouteData?.find((rn) => rn.id == cga).name;
          //   let obj_n = contextChoicesMap?.run_routes && contextChoicesMap?.run_routes[cga]?.name
          //   if (obj_n) {
          //     emp_gp_area_str = emp_gp_area_str + obj_n
          //   }
          // })

          emp_gp_area_str = detail?.employee_group_area?.map(item => item.name)?.join();;

        }
        console.log(client_gp_area_str, emp_gp_area_str, "client_gp_area_str");
        let sch_duration_cal = ((new Date(detail.end_date)).getTime() - (new Date(detail.start_date)).getTime()) / 3600000

        if (view === 'client' && !scheduler.is_location) {
          data.push({
            id: detail.id,
            resourceId: scheduler.id,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            title: detail.employee_id
              ? detail.employee_fullname
              : detail.is_visit_cancelled
                ? 'Cancelled'
                : 'Unassigned',
            unassigned: detail.employee_id ? false : true,
            backgroundColor: visitStatusColourClient(detail, !!detail.doubleUp),
            borderColor: visitStatusColourClient(detail, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            constraint: {
              resourceIds: [scheduler.id],
            },
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id && scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            gender: detail.gender ? contextChoicesMap.gender[detail.gender] && contextChoicesMap.gender[detail.gender]?.name : null,
            visit_priority: detail.visit_priority ? contextChoicesMap?.visit_priority[detail.visit_priority] && contextChoicesMap?.visit_priority[detail.visit_priority]?.name : null,
            clientView: true,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
            doubleUp: !!detail.doubleUp,

            visit: true
          });
        }
        else if (view === 'team') {
          let resData = employeeVisitData && employeeVisitData.find(det => det.employee_id === detail.employee_id)
          let checkDesire = 0;
          if (resData && (resData.remaining_desired_hrs < 0)) {
            checkDesire = true;
          } else {
            checkDesire = false;
          }

          // let visit_duration = ((new Date(detail.end_date)).getTime() - (new Date(detail.start_date)).getTime()) / 3600000;
          // let travel_duration = ((new Date(detail.end_travel_time)).getTime() - (new Date(detail.start_travel_time)).getTime()) / 3600000;

          // visit_tooltip_data = visit_tooltip_data.map((item) => {
          //   if (item.employee_id == detail.employee_id) {
          //     item.total_visit_hours = item.total_visit_hours + visit_duration;
          //     item.schedule_travel_time = item.schedule_travel_time + travel_duration;
          //     item.actual_travel_miles = item.actual_travel_miles + (detail.schedule_travel_miles ? detail.schedule_travel_miles : 0);
          //     item.no_visits = item.no_visits + 1
          //   }
          //   return item;
          // })


          data.push({
            id: detail.id,
            resourceId: detail.employee_id ? detail.employee_id : "0000",
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            unassigned: detail.employee_id ? false : true,
            title: scheduler.full_name ? scheduler.full_name : "Unassigned",
            backgroundColor: visitStatusColourTeam(detail, checkDesire, !!detail.doubleUp),
            borderColor: visitStatusColourTeam(detail, checkDesire, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            serviceId: detail.client_service_visit_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            run_view_id: detail.run_view_id,
            visit: true,
            icon: Home_ICON,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            orderEvent: 2,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            gender: detail.gender ? contextChoicesMap.gender && contextChoicesMap.gender[detail.gender]?.name : null,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            start_travel_time: detail.start_travel_time,
            end_travel_time: detail.end_travel_time,
            run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
            doubleUp: !!detail.doubleUp,
          });

          if (detail.actual_visit_start_time && detail.actual_visit_end_time) {
            data.push({
              id: detail.id,
              resourceId: detail.employee_id ? detail.employee_id : "0000",
              isRegular: detail.is_pinned_employee,
              assigned_by: detail.assigned_by ? detail.assigned_by : '',
              is_published: detail.is_published,
              title: scheduler.full_name ? scheduler.full_name : "Unassigned",
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              textColor: 'white',
              start: detail.actual_visit_start_time,
              end: detail.actual_visit_end_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              client_group_area: client_gp_area_str,
              employee_group_area: emp_gp_area_str,
              serviceId: detail.client_service_visit_id,
              employee_name: detail.employee_fullname,
              visit_status: detail.visit_status_name,
              actual_visit_end_time: detail.actual_visit_end_time,
              actual_visit_start_time: detail.actual_visit_start_time,
              actual_visit_duration: detail.actual_visit_duration,
              schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
              run_view_id: detail.run_view_id,
              address: detail.address,
              zipcode: detail.zip_code,
              visit_type: detail.visit_type_name,
              service_category: detail.service_category,
              service_category_type: capitalizeText(detail.service_category_type),
              gender: detail.gender ? choices && contextChoicesMap.gender && contextChoicesMap.gender[detail.gender].name : null,
              earliest_start_time: detail.earliest_start_time,
              latest_start_time: detail.latest_start_time,
              start_travel_time: detail.start_travel_time,
              end_travel_time: detail.end_travel_time,
              run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
              doubleUp: !!detail.doubleUp,
              orderEvent: 5,
              clocked: true,
            });
          }
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 1,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = detail.end_travel_time && moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,

              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 3,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run",
              tolerance: true
            });
          }
        }
        else if (view === 'location' && scheduler.is_location) {
          locationData.push({
            id: detail.id,
            resourceId: scheduler.id,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            title: detail.employee_id
              ? detail.employee_fullname
              : detail.is_visit_cancelled
                ? 'Cancelled'
                : 'Unassigned',
            unassigned: detail.employee_id ? false : true,
            backgroundColor: visitStatusColourClient(detail, !!detail.doubleUp),
            borderColor: visitStatusColourClient(detail, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            constraint: {
              resourceIds: [scheduler.id],
            },
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id && scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            gender: detail.gender ? contextChoicesMap?.gender[detail?.gender].name : null,
            visit_priority: detail?.visit_priority ? contextChoicesMap?.visit_priority[detail.visit_priority]?.name : null,
            clientView: true,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
            doubleUp: !!detail.doubleUp,
            visit: true
          });
        }
        else {

          //  if (detail.is_regular_visit) {
          let runviewid = detail.is_regular_visit
            ? detail.run_view_id ? detail.run_view_id : "001"
            : "000"
          if (runViewData[runviewid] == null) {
            runViewData[runviewid] = [];
          }
          if (detail.start_travel_time != detail.end_travel_time) {
            runViewData[runviewid].push({ start: detail.start_travel_time, end: detail.end_travel_time });
          }
          runViewData[runviewid].push({ start: detail.start_date, end: detail.end_date });
          data.push({
            id: detail.id,
            resourceId: detail.is_regular_visit ?
              detail.run_view_id ? detail.run_view_id : "000" : null,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            unassigned: detail.employee_id ? false : true,
            title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
            backgroundColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            borderColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            visit: true,
            typeVisit: "run",
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            service_category: detail.service_category,
          });
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              travel_mode_name: detail.travel_mode,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,

              typeVisit: "run"
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');

            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              travel_mode_name: detail.travel_mode,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run",
              tolerance: true
            });
          }
          //  }
        }

      });
    });
    // if (view === 'run') {
    //   Object.keys(runViewData).forEach((key) => {
    //     if (runViewData[key] && runViewData[key].length > 1) {
    //       let sortedviews = runViewData[key].sort((a, b) => new Date(a.start) - new Date(b.start));
    //       sortedviews.forEach((event, i) => {
    //         if (i != 0) {
    //           let gap = (new Date(event.start)).getTime() - (new Date(sortedviews[i - 1].end)).getTime();
    //           if (gap > 60000 && (sortedviews[i - 1].end.split('T')[0] == event.start.split('T')[0])) {

    //             final_wait_data.push({
    //               resourceId: key,
    //               backgroundColor: WAIT_TIME_COLOR,
    //               borderColor: WAIT_TIME_COLOR,
    //               title: `${sortedviews[i - 1].zipcode} - ${sortedviews[i].zipcode}`,
    //               textColor: GRAY_820,
    //               start: sortedviews[i - 1].end,
    //               end: event.start,
    //               openModal: true,
    //               waittime: true,
    //               typeVisit: "run"
    //             });
    //           }
    //         }
    //       })
    //     }
    //   })
    // }
    if (view === 'team') {
      rota_shift_data.length && rota_shift_data.forEach(rotaShift => {
        let emp = {
          id: rotaShift?.id,
          full_name: rotaShift?.employeeFullName,
          contractedHours: rotaShift?.employeeContractedHours,
          maxHourPerWeek: rotaShift?.employeeMaxHoursPerWeek,
          travel_mode: rotaShift?.employeeTravelMode,
          phone_number: rotaShift?.employeeMobileNo,
        };


        let employeeToolTip = {
          employee_id: rotaShift?.id,
          employee_name: rotaShift?.employeeFullName || 0,
          total_visits: rotaShift?.noOfVisits || 0,
          visits_hrs: rotaShift?.totalVisitHours || 0,
          contracted_hrs: rotaShift?.contractedNoOfHours || 0,
          desired_hrs: rotaShift?.employeeMaxHoursPerWeek || 0,
          travel_hrs: rotaShift?.totalTravelHours || 0,
          travel_distance: rotaShift?.totalTravelDistance || 0,
          wait_hrs: rotaShift.duration || 0,
          remain_desired_wk_hrs: rotaShift?.remainDesiredWorkingHours || 0,
          remain_contrd_wk_hrs: rotaShift?.remainContractedWorkingHours || 0,
          default_travel_mode: rotaShift?.employeeTravelMode || "N/A",
          phone_number: rotaShift?.employeeMobileNo || 0,
        }
        visit_tooltip_data.push(employeeToolTip)
        allEmployeeData.push(emp)

        // const employe_name = rotaShift.employee && contextChoicesMap.employee_listing?.[rotaShift.employee]?.full_name
        if (rotaShift.shifts && rotaShift.shifts.length) {
          rotaShift.shifts.map(shift => {
            if (shift.schedule_travel_start_datetime && shift.schedule_travel_end_datetime) {
              travel_data.push({
                id: shift.id,
                groupId: shift.id,
                resourceId: shift.employee,
                typeEvent: shift.is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
                title: rotaShift?.employeeFullName,
                backgroundColor: TRAVEL_TIME_COLOR,
                borderColor: TRAVEL_TIME_COLOR,
                textColor: GRAY_820,
                start: shift.schedule_travel_start_datetime,
                end: shift.schedule_travel_end_datetime,
                client: shift.full_name,
                clientId: shift.client,
                employeeId: shift.employee,
                serviceId: shift.client_service_visit_id,
                openModal: true,
                traveltime: true,
                shiftTravel: true,
                travel_mode_name: shift.travelModeName,
                travel_code: shift.travel_code && shift.travel_code.toUpperCase(),
                travelMode: shift.travelModeName === 'Walk' ? FOOT_ICON
                  : (shift.travel_mode_name === 'Scooter' || shift.travel_mode_name === 'Motorbike' || shift.travel_mode_name === 'Bike') ? BIKE_ICON : CAR_ICON,

              });
            }
            // "id": "f8e85a0a-13c3-49a1-9a76-830f7ff14cda",
            // "start_date": "2024-08-30T06:00:00",
            // "end_date": "2024-08-30T06:30:00",
            // "actual_shift_start_time": null,
            // "travel_code": null,
            // "actual_shift_end_time": null,
            // "is_absent": false,
            // "shift_type": "ca671dec-3b26-16d0-72bd-2f0b9a86ffbd",
            // "is_available": true,
            // "employee": "90bb5325-e9b5-4808-ba08-ad0ba0bcb104",
            // "schedule_travel_start_datetime": null,
            // "schedule_travel_end_datetime": null,
            // "shiftStatusName": "Not Started",
            // "travelModeName": "Bus",
            // "shiftTypeName": "Availability",
            // "payTypeName": "Shift",
            // "employeeAbsenceType": null,
            // "employeeAbsenceTypeColour": null
console.log(shift,"shift")
shift_data.push({
              id: shift?.id,
              groupId: shift?.id,
              resourceId: shift?.employee,
              typeEvent: shift?.is_available
                ? TYPE_AVAILABLE
                : TYPE_UNAVAILABLE,
              backgroundColor: rotashiftColor(shift?.shiftTypeName),
              borderColor: rotashiftColor(shift?.shiftTypeName),
              title:
                !shift?.regular_shift_name
                  ? (shift?.is_available
                    ? shift?.shiftTypeName
                    : (shift?.is_absent
                      ? 'Absence'
                      : 'Unavailable'))
                  : shift?.regular_shift_name,
              textColor: shift?.is_available ? GRAY_820 : WHITE,
              start: shift?.start_date,
              end: shift?.end_date,
              shift_type: shift?.shift_type,
              actual_shift_start_time: shift?.actual_shift_start_time,
              actual_shift_end_time: shift?.actual_shift_end_time,
              shift_status_name: shift?.shiftStatusName,
              travelMode: shift?.is_available
                ? shift?.travelModeName === 'Walk'
                  ? FOOT_ICON
                  : shift?.travelModeName === 'Bike' ||
                    shift?.travelModeName === 'Scooter' ||
                    shift?.travelModeName === 'Motarbike'
                    ? BIKE_ICON
                    : shift?.travelModeName === 'Car'
                      ? CAR_ICON
                      : ''
                : '',
              type: 'regularShift'
              // openModal: true,
              // waittime: true
            })
          })
        }
        // if (rotaShift.shift_type_name == 'Availability' || rotaShift.pay_type_name == 'Shift') {
        //   // debugger
        //   let rotaShiftsData = { start: rotaShift.start_date, end: rotaShift.end_date, zipCode: rotaShift.employee_zipcode }
        //   if (!values.pay_type || !values.pay_type.length) {
        //     if (shift_availability[rotaShift.employee]) {
        //       shift_availability[rotaShift.employee].push({ ...rotaShiftsData })
        //     } else {
        //       shift_availability[rotaShift.employee] = [{ ...rotaShiftsData }]
        //     }
        //   } else {
        //     let match = values?.pay_type?.find(type => type.name === rotaShift.pay_type_name);
        //     if (match) {
        //       if (shift_availability[rotaShift.employee]) {
        //         shift_availability[rotaShift.employee].push({ ...rotaShiftsData })
        //       } else {
        //         shift_availability[rotaShift.employee] = [{ ...rotaShiftsData }]
        //       }
        //     }
        //   }

        // } else {
        //   if (!values.pay_type || !values.pay_type.length) {
        //     final_wait_data.push({
        //       id: rotaShift.id,
        //       groupId: rotaShift.id,
        //       resourceId: rotaShift.employee,
        //       typeEvent: rotaShift.is_available
        //         ? TYPE_AVAILABLE
        //         : TYPE_UNAVAILABLE,
        //       backgroundColor: rotashiftColor(rotaShift.shift_type_name),
        //       borderColor: rotashiftColor(rotaShift.shift_type_name),
        //       title:
        //         !rotaShift.regular_shift_name
        //           ? (rotaShift.is_available
        //             ? rotaShift.shift_type_name
        //             : (rotaShift.is_absent
        //               ? 'Absence'
        //               : 'Unavailable'))
        //           : rotaShift.regular_shift_name,
        //       textColor: rotaShift.is_available ? GRAY_820 : WHITE,
        //       start: rotaShift.start_date,
        //       end: rotaShift.end_date,
        //       shift_type: rotaShift.shift_type,
        //       actual_shift_start_time: rotaShift.actual_shift_start_time,
        //       actual_shift_end_time: rotaShift.actual_shift_end_time,
        //       shift_status_name: rotaShift.shift_status_name,
        //       travelMode: rotaShift.is_available
        //         ? rotaShift.travel_mode_name === 'Walk'
        //           ? FOOT_ICON
        //           : rotaShift.travel_mode_name === 'Bike' ||
        //             rotaShift.travel_mode_name === 'Scooter' ||
        //             rotaShift.travel_mode_name === 'Motarbike'
        //             ? BIKE_ICON
        //             : rotaShift.travel_mode_name === 'Car'
        //               ? CAR_ICON
        //               : ''
        //         : '',
        //       type: 'regularShift'
        //       // openModal: true,
        //       // waittime: true
        //     })
        //   } else {
        //     let show = false
        //     let match = values?.pay_type?.find(type => type.name === rotaShift.pay_type_name);
        //     if (match) {
        //       show = true;
        //     }
        //     // switch (rotaShift.pay_type_name) {
        //     //   case "Absence":
        //     //     show = false
        //     //     break;
        //     //   case "Unavailability":
        //     //     show = false
        //     //     break;
        //     //   case "On Call":
        //     //     show = true
        //     //     break;
        //     //   case "Car - Driver - Shift":
        //     //     show = false
        //     //     break;
        //     //   case "Bank-Flexible":
        //     //     show = false
        //     //     break;
        //     //   default:
        //     //     show = true
        //     // }
        //     if (show) {
        //       final_wait_data.push({
        //         id: rotaShift.id,
        //         groupId: rotaShift.id,
        //         resourceId: rotaShift.employee,
        //         typeEvent: rotaShift.is_available
        //           ? TYPE_AVAILABLE
        //           : TYPE_UNAVAILABLE,
        //         backgroundColor: rotashiftColor(rotaShift.shift_type_name),
        //         borderColor: rotashiftColor(rotaShift.shift_type_name),
        //         title:
        //           !rotaShift.regular_shift_name
        //             ? (rotaShift.is_available
        //               ? rotaShift.shift_type_name
        //               : (rotaShift.is_absent
        //                 ? 'Absence'
        //                 : 'Unavailable'))
        //             : rotaShift.regular_shift_name,
        //         textColor: rotaShift.is_available ? GRAY_820 : WHITE,
        //         start: rotaShift.start_date,
        //         end: rotaShift.end_date,
        //         shift_type: rotaShift.shift_type,
        //         actual_shift_start_time: rotaShift.actual_shift_start_time,
        //         actual_shift_end_time: rotaShift.actual_shift_end_time,
        //         shift_status_name: rotaShift.shift_status_name,
        //         travelMode: rotaShift.is_available
        //           ? rotaShift.travel_mode_name === 'Walk'
        //             ? FOOT_ICON
        //             : rotaShift.travel_mode_name === 'Bike' ||
        //               rotaShift.travel_mode_name === 'Scooter' ||
        //               rotaShift.travel_mode_name === 'Motarbike'
        //               ? BIKE_ICON
        //               : rotaShift.travel_mode_name === 'Car'
        //                 ? CAR_ICON
        //                 : ''
        //           : '',
        //         type: 'regularShift'
        //         // openModal: true,
        //         // waittime: true
        //       })
        //     }
        //   }
        // }
      });
    }


    let final_data = (view === "client") ? [...data] :
      (view === 'location') ? [...locationData] :
        [...data, ...travel_data, ...final_wait_data, ...shift_data, ...locationData]
    let wait_time_temp = [];
    // debugger
    if (view === 'team') {

      let temp_filter_data = data ? data.filter((item) => item.employeeId) : [];
      let temp_obj = {}

      temp_filter_data.forEach((item) => {
        if (temp_obj[item.resourceId]) {
          temp_obj[item.resourceId].push({ ...item })
        } else {
          temp_obj[item.resourceId] = [{ ...item }]
        }
      })
      // Object.keys(shift_availability).forEach((key) => {
      //   shift_availability[key].forEach((shiftData) => {
      //     const shiftRange = new_moment.range(shiftData.start, shiftData.end);

      //     let sortedVisits = [];
      //     let sortedVisitsByEnd = [];
      //     temp_obj && temp_obj[key] && temp_obj[key].forEach((visit) => {
      //       const visitRange = new_moment.range(visit.start_travel_time, visit.end);
      //       if (shiftRange.overlaps(visitRange) && (visit.start_travel_time)) {
      //         sortedVisits.push(visit);
      //         sortedVisitsByEnd.push(visit);
      //       }
      //     })

      //     if (temp_obj[key] && sortedVisits && (sortedVisits.length > 0)) {

      //       // let sortedVisits = [];
      //       // let sortedVisitsByEnd = [];
      //       // temp_obj[key].forEach((visit) => {
      //       //   const visitRange = new_moment.range(visit.start_travel_time, visit.end);
      //       //   if (shiftRange.overlaps(visitRange) && (visit.start_travel_time)) {
      //       //     sortedVisits.push(visit);
      //       //     sortedVisitsByEnd.push(visit);
      //       //   }
      //       // })

      //       sortedVisits = sortedVisits && sortedVisits.sort((a, b) => new Date(a.start_travel_time) - new Date(b.start_travel_time));

      //       sortedVisitsByEnd = sortedVisitsByEnd && sortedVisitsByEnd.sort((a, b) => new Date(a.end) - new Date(b.end));

      //       if (sortedVisits.length > 0) {
      //         if ((new Date(shiftData.start)).getTime() < (new Date(sortedVisits[0].start_travel_time)).getTime()) {
      //           let gap_capacity = (new Date(sortedVisits[0].start_travel_time)).getTime() - (new Date(shiftData.start)).getTime();
      //           wait_hr_capacity.push(gap_capacity)

      //           wait_time_temp.push({
      //             resourceId: key,
      //             backgroundColor: WAIT_TIME_COLOR,
      //             borderColor: WAIT_TIME_COLOR,
      //             title: `${shiftData.zipCode} - ${sortedVisits[0].zipcode}`,
      //             textColor: GRAY_820,
      //             start: shiftData.start,
      //             end: sortedVisits[0].start_travel_time,
      //             orderEvent: 4,
      //             openModal: true,
      //             waittime: true,
      //           });
      //         }

      //         if ((new Date(shiftData.end)).getTime() > (new Date(sortedVisits[sortedVisits.length - 1].end)).getTime()) {
      //           let gap_capacity_temp = (new Date(shiftData.end)).getTime() - (new Date(sortedVisits[sortedVisits.length - 1].end)).getTime();
      //           wait_hr_capacity.push(gap_capacity_temp)
      //           wait_time_temp.push({
      //             resourceId: key,
      //             backgroundColor: WAIT_TIME_COLOR,
      //             borderColor: WAIT_TIME_COLOR,
      //             title: `${sortedVisits[sortedVisits.length - 1].zipcode} - ${shiftData.zipCode}`,
      //             textColor: GRAY_820,
      //             start: sortedVisits[sortedVisits.length - 1].end,
      //             end: shiftData.end,
      //             orderEvent: 4,
      //             openModal: true,
      //             waittime: true,
      //           });
      //         }
      //       }



      //       // if((sortedVisits.length > 0) && sortedVisits[0].start)

      //       sortedVisits.forEach((event, i) => {
      //         if (i != 0) {
      //           let a = new Date(sortedVisits[i - 1].end)
      //           let b = new Date(sortedVisitsByEnd[i - 1].end)
      //           let dateEnd = null;
      //           if (b >= a) {
      //             dateEnd = new Date(sortedVisitsByEnd[i - 1].end)
      //           } else {
      //             dateEnd = new Date(sortedVisits[i - 1].end)
      //           }

      //           let gap = (new Date(event.start_travel_time)).getTime() - (dateEnd).getTime();
      //           if (gap > 60000) {
      //             let gap_in_hr = gap;
      //             if ((gap_in_hr > (2 * 3600000))) {
      //               wait_gap_visits_less_two.push(gap_in_hr)
      //             } else {
      //               wait_gap_visits.push(gap_in_hr);
      //             }


      //             wait_time_temp.push({
      //               resourceId: key,
      //               backgroundColor: WAIT_TIME_COLOR,
      //               borderColor: WAIT_TIME_COLOR,
      //               title: `${sortedVisits[i - 1].zipcode} - ${sortedVisits[i].zipcode}`,
      //               textColor: GRAY_820,
      //               start: b >= a ? sortedVisitsByEnd[i - 1].end : sortedVisits[i - 1].end,
      //               end: event.start_travel_time,
      //               orderEvent: 4,
      //               openModal: true,
      //               waittime: true,
      //             });
      //           }
      //         }
      //       })
      //       if (wait_gap_visits.length > 0) {
      //         let aHours = wait_gap_visits.reduce((total, visit) => total + visit);
      //         aHours % 0.1 === 0
      //           ? setWaitHoursBetweenVisit(aHours)
      //           : setWaitHoursBetweenVisit(Math.round(aHours * 100) / 100);
      //       } else {
      //         setWaitHoursBetweenVisit(0);
      //       }

      //       if (wait_gap_visits_less_two.length > 0) {
      //         let aHours = wait_gap_visits_less_two.reduce((total, visit) => total + visit);
      //         aHours % 0.1 === 0
      //           ? setWaitHoursExcludeMoreThanTwo(aHours)
      //           : setWaitHoursExcludeMoreThanTwo(Math.round(aHours * 100) / 100);
      //       } else {
      //         setWaitHoursExcludeMoreThanTwo(0);
      //       }

      //     }
      //     else {
      //       let gap_capacity_temp = (new Date(shiftData.end)).getTime() - (new Date(shiftData.start)).getTime();
      //       wait_hr_capacity.push(gap_capacity_temp)
      //       wait_time_temp.push({
      //         resourceId: key,
      //         backgroundColor: WAIT_TIME_COLOR,
      //         borderColor: WAIT_TIME_COLOR,
      //         title: `${shiftData.zipCode} - ${shiftData.zipCode}`,
      //         textColor: GRAY_820,
      //         start: shiftData.start,
      //         end: shiftData.end,
      //         orderEvent: 4,
      //         openModal: true,
      //         waittime: true,
      //       });
      //     }
      //     // if (wait_hr_capacity.length > 0) {
      //     //   let aHours = wait_hr_capacity.reduce((total, visit) => total + visit);
      //     //   aHours % 0.1 === 0
      //     //     ? setWaitHoursCapacity(aHours)
      //     //     : setWaitHoursCapacity(Math.round(aHours * 100) / 100);
      //     // } else {
      //     //   setWaitHoursCapacity(0);
      //     // }
      //   })
      // })

      let blank_emp = []
      // let emp_count = 0;
      allEmployeeData.forEach((val) => {
        let checkEmp = final_data.findIndex((item) => item.resourceId == val.id)
        // let checkEmpCount = final_data.findIndex((item) => ((item.resourceId == val.id) && item.visit))
        if ((checkEmp == -1)) {
          blank_emp.push({
            resourceId: val.id,
            title: '',
            backgroundColor: 'rgba(54, 25, 25, .00004)',
            borderColor: 'rgba(54, 25, 25, .00004)',
            start: values.start_date,
            //end: moment(values.start_date).add(60, 'seconds'),                   
            allDay: true,
            blank: true
          })
        }
        // if (!(checkEmpCount == -1)) {
        //   emp_count = emp_count + 1
        // }
      })

      // setEmployeeResource(emp_count);
      if (values.employees && (values.employees.length > 0)) {
        let add_filtered_emp = []
        values.employees.forEach((val) => {
          let checkEmpBlank = blank_emp.findIndex((item) => item.resourceId == val.id)
          let check_val = filterExcludeCheck.employee ? (checkEmpBlank == -1) : !(checkEmpBlank == -1);
          if (check_val) {
            add_filtered_emp.push({
              resourceId: val.id,
              title: '',
              backgroundColor: 'rgba(54, 25, 25, .00004)',
              borderColor: 'rgba(54, 25, 25, .00004)',
              start: values.start_date,
              //end: moment(values.start_date).add(60, 'seconds'),                   
              allDay: true,
              blank: true
            })
          }
        })
        final_data = [...final_data, ...wait_time_temp, ...add_filtered_emp]
      } else {
        final_data = [...final_data, ...wait_time_temp, ...blank_emp]
      }
    }
    setEmpData(allEmployeeData)
    updateEmployeeResource(allEmployeeData)
    setEmployeeVisitData(visit_tooltip_data)
    setEvents(final_data.flat());
    setTotalEvents(final_data.flat())

    setLoading(false)
  }

  // useEffect(() => {
  //   if (caller === 0 && intervalNeeded) {
  //     if (!mountedRef.current) return null;
  //     setCallerInterval();
  //   }
  // }, [caller, intervalNeeded]);

  // useEffect(() => {
  //   return () => {
  //     clearInterval(caller);
  //   };
  // }, [caller]);



  // useEffect(() => {
  //   if (caller !== 0) {
  //     clearInterval(caller);
  //     setCaller(0);
  //   }
  // }, [values.start_date, values.end_date]);


  // useEffect(() => {
  //   console.log(new Date(),"88888888");

  //   if (caller === 0 && intervalNeeded) {
  //     if (!mountedRef.current) return;
  //     setCallerInterval();
  //   }

  //   if (caller !== 0) {
  //     clearInterval(caller);
  //     setCaller(0);
  //   }

  //   return () => {
  //     clearInterval(caller);
  //   };
  //   console.log(new Date(),"88888888");

  // }, [caller, intervalNeeded, values.start_date, values.end_date]);

  useEffect(() => {
    if (caller !== 0) {
      clearInterval(caller);
      setCaller(0);
    } else if (caller === 0 && intervalNeeded) {
      if (!mountedRef.current) return null;
      setCallerInterval();
    }

    return () => {
      clearInterval(caller);
    };
  }, [intervalNeeded, values.start_date, values.end_date, mountedRef.current]);




  // useEffect(() => {
  //   getOptaplannerStatus()
  // }, [])


  const handleDragAndDrop = () => {
    if (changeVisitDetails.id) {
      let body = {
        visit_id: changeVisit.event._def.publicId,
        start_date: moment.utc(changeVisit.event._instance.range.start).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment.utc(changeVisit.event._instance.range.end).format("YYYY-MM-DD HH:mm:ss"),
        employee_id: changeVisit.event._def.resourceIds[0],
        force: true,
        // visitScreenData: visitScreenData?.visitScreenData
      }
      if (!loading) {
        setLoading(true)
      }
      DragAndDropVisits(body, { ...branchData, ...values }, filterExcludeCheck,)
        .then(res => {
          if (res.status == "False") {
            if (res.message.force == "False") {
              toast.current.show({ severity: 'error', summary: 'Failed', detail: res.message });
            } else {
              setModalDragAndDropValue(true);
            }
          } else {
            setModalDragAndDropValue(false);
            let new_data = [];

            res.visitSuccess && res.visitSuccess.map(scheduler => {
              new_data.push(scheduler)
            });


            let filtered_events = [...schedulerData]

            const removeFirst = (target) => {
              filtered_events = filtered_events.map((sch) => {
                let temp_visits = []
                sch.visits.forEach((f) => {
                  if (!(f.id === target.id)) {
                    temp_visits.push(f)
                  }
                });
                return { ...sch, visits: temp_visits || [] }
              })
            };

            new_data.forEach((item) => {
              item.visits.forEach((sub) => {
                removeFirst(sub)
              })
            })

            let data = calculateTotalCounts(res?.calculationsData?.visits, res?.calculationsData?.shifts)
            setVisitScreenData(data)

            const sch_data = [...filtered_events, ...new_data]
            setSchedulerData(sch_data)
            // updateSchData(sch_data, rotaData, calendarView)
          }
          setLoading(false)
        }).catch((err) => {
          setLoading(false)
        })
    }
  }
  console.log(visitScreenData,"visitScreenData")
  const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  useEffect(() => {

    if (changeVisitDetails.id && changeVisit?.event?._def?.extendedProps?.visit) {
      console.log(new Date(), "9999999 changeVisit");
      dragAndDropFn()
    }


    if (changeVisitDetails.id && changeVisit.event._def.extendedProps.employeeId === null) {
      console.log(new Date(), "9999999 setUpdateVisit");
      setUpdateVisit(changeVisitDetails);
    }

    if (changeVisitDetails.id && changeVisit?.event?._def?.extendedProps?.shiftTravel) {
      console.log(changeVisitDetails, changeVisit.event._def.resourceIds[0], changeVisit?.event?._def?.extendedProps?.employeeId, "9999999");
      if (changeVisit.event._def.resourceIds[0] === changeVisit?.event?._def?.extendedProps?.employeeId) {
        shiftDragAndDrop()
      } else {
        // toast.current.show({ severity: 'error', summary: 'Failed', detail: "Shift cannot be swapped." });
      }
    }



  }, [changeVisitDetails]);

  const dragAndDropFn = async () => {
    let body = {
      visit_id: changeVisit.event._def.publicId,
      start_date: moment.utc(changeVisit.event._instance.range.start).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment.utc(changeVisit.event._instance.range.end).format("YYYY-MM-DD HH:mm:ss"),
      employee_id: changeVisit.event._def.resourceIds[0],
      // visitScreenData: visitScreenData?.visitScreenData
    }
    if (!loading) {
      setLoading(true)
    }
    // setLoading(true)
    const res = await DragAndDropVisits(body, { ...branchData, ...values }, filterExcludeCheck,)
    // .then(res => {
    setLoading(false)
    if (res.status == "False") {
      if (res.force == "False") {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: res.message });
      } else {
        setModalDragAndDropValue(true);
      }
    } else {
      let new_data = [];

      res.visitSuccess && res.visitSuccess.map(scheduler => {
        new_data.push(scheduler)
      });


      let filtered_events = [...schedulerData]

      const removeFirst = (target) => {
        filtered_events = filtered_events.map((sch) => {
          let temp_visits = []
          sch.visits.forEach((f) => {
            if (!(f.id === target.id)) {
              temp_visits.push(f)
            }
          });
          return { ...sch, visits: temp_visits || [] }
        })
      };

      new_data.forEach((item) => {
        item.visits.forEach((sub) => {
          removeFirst(sub)
        })
      })

      let data = calculateTotalCounts(res?.calculationsData?.visits, res?.calculationsData?.shifts)
      setVisitScreenData(data)

      const sch_data = [...filtered_events, ...new_data]
      setSchedulerData(sch_data)
      // updateSchData(sch_data, rotaData, calendarView)
    }

    // }).catch((err) => {
    //   setLoading(false)
    // })
  }

  const shiftDragAndDrop = () => {
    let body = {
      // visit_id: changeVisit.event._def.publicId,
      start_date: moment.utc(changeVisit.event._instance.range.start).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment.utc(changeVisit.event._instance.range.end).format("YYYY-MM-DD HH:mm:ss"),
      // employee_id: changeVisit.event._def.resourceIds[0],
      // visitScreenData: visitScreenData?.visitScreenData
    }
    try {
      console.log(body, "shift drag and drop")
    } catch (error) {

    }
  }

  // useEffect(()=>{
  //   const loadSettingEvv = async () => {
  //     await getSettingEvv().then(response => {
  //       if (response) {
  //         const data = response.ClockValidationCheck[0]
  //         setEvvSettingData({...data});
  //       }
  //     });
  //   };
  //   loadSettingEvv()
  // },[])

  //Commented during optimization
  // const travelShiftCounts = useMemo(() => {
  //   let TravelCountsShift = {}
  //   if (contextChoices.travel_type) {
  //     contextChoices.travel_type.forEach((val) => {
  //       let travelHoursCheckMode = events
  //         .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && (event.travel_mode_name == val.name))
  //         .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //       if (travelHoursCheckMode.length > 0) {
  //         let aHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
  //         if (aHours % 0.1 === 0) {
  //           TravelCountsShift[val.name] = aHours;
  //         } else {
  //           TravelCountsShift[val.name] = Math.round(aHours * 100) / 100;
  //         }
  //       } else {
  //         TravelCountsShift[val.name] = 0;
  //       }
  //     })
  //   }
  //   return TravelCountsShift || {}
  // }, [contextChoices.travel_type, events])

  //Commented during optimization
  // const travelHoursClientToClientShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursClientToOfficeShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursOfficeToClientShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursClientToHomeShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursHomeToClientShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-CLIENT')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursHomeToOfficeShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'HOME-OFFICE')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])

  // const travelHoursOfficeToOfficeShift = useMemo(() => {
  //   // client to client
  //   let valuesTravel = 0
  //   let travelHoursCheck2 = events
  //     .filter(event => event && event.traveltime && !event.blank && event.shiftTravel && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
  //     .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //   if (travelHoursCheck2.length > 0) {
  //     let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //     aHours % 0.1 === 0
  //       ? valuesTravel = aHours
  //       : valuesTravel = Math.round(aHours * 100) / 100;
  //   } else {
  //     valuesTravel = 0
  //   }
  //   return valuesTravel || 0
  // }, [contextChoices.travel_type, events])


  //Optimized travel shift calculation
  // const travelShiftCounts = useMemo(() => {
  //   let TravelCountsShift = {};
  //   if (contextChoices.travel_type) {
  //     contextChoices.travel_type.forEach(val => {
  //       let travelHoursCheckMode = events
  //         .filter(event =>
  //           event && event.traveltime && !event.blank && event.shiftTravel && event.travel_mode_name === val.name
  //         )
  //         .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //       if (travelHoursCheckMode.length > 0) {
  //         let totalHours = travelHoursCheckMode.reduce((total, visit) => total + visit);
  //         TravelCountsShift[val.name] = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       } else {
  //         TravelCountsShift[val.name] = 0;
  //       }
  //     });
  //   }
  //   return TravelCountsShift || {};
  // }, [contextChoices.travel_type, events]);



  // const calculateTravelHoursShift = (travelCode) => {
  //   let CLIENT_OFFICE = [];
  //   let CLIENT_CLIENT = []
  //   let OFFICE_CLIENT = []
  //   let CLIENT_HOME = []
  //   let HOME_CLIENT = []
  //   let HOME_OFFICE = []
  //   let OFFICE_OFFICE = []

  //   events && events.forEach(event => {
  //     if (event && event.traveltime && !event.blank && event.shiftTravel) {
  //       let diff = ((new Date(event.end) - new Date(event.start)) / 3600000);
  //       if (event.travel_code === TRAVEL_CODE.CLIENT_CLIENT) {
  //         CLIENT_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.CLIENT_OFFICE) {
  //         CLIENT_OFFICE.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.OFFICE_CLIENT) {
  //         OFFICE_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.CLIENT_HOME) {
  //         CLIENT_HOME.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.HOME_CLIENT) {
  //         HOME_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.HOME_OFFICE) {
  //         HOME_OFFICE.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.OFFICE_OFFICE) {
  //         OFFICE_OFFICE.push(diff)
  //       }
  //     }
  //   }    )


  //   if (CLIENT_OFFICE.length > 0) {
  //     let totalHours = CLIENT_OFFICE.reduce((total, visit) => total + visit, 0);
  //     let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursClientToOfficeShift(valuesTravel)
  //   }
  //   if (CLIENT_CLIENT.length > 0) {
  //     let totalHours = CLIENT_CLIENT.reduce((total, visit) => total + visit, 0);
  //     let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursClientToClientShift(valuesTravel)
  //   }
  //   if (OFFICE_CLIENT.length > 0) {
  //     let totalHours = OFFICE_CLIENT.reduce((total, visit) => total + visit, 0);
  //     let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursOfficeToClientShift(valuesTravel)
  //   }

  //   if (CLIENT_HOME.length > 0) {
  //     let totalHours = CLIENT_HOME.reduce((total, visit) => total + visit, 0);
  //     let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursClientToHomeShift(valuesTravel)
  //   }
  //   if (HOME_CLIENT.length > 0) {
  //     let totalHours = HOME_CLIENT.reduce((total, visit) => total + visit, 0);
  //     let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursHomeToClientShift(valuesTravel)
  //   }
  //   if (HOME_OFFICE.length > 0) {
  //     let totalHours = HOME_OFFICE.reduce((total, visit) => total + visit, 0);
  //     let  valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursHomeToOfficeShift(valuesTravel)
  //   }
  //   if (OFFICE_OFFICE.length > 0) {
  //     let totalHours = OFFICE_OFFICE.reduce((total, visit) => total + visit, 0);
  //     let  valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //     setTravelHoursOfficeToOfficeShift(valuesTravel)
  //   }

  // }; useEffect(() => {
  //   calculateTravelHoursShift()
  // }, [events])


  //  Optimise travel hours
  // const calculateAndSetTravelHours = () => {
  //     let CLIENT_OFFICE = [];
  //     let CLIENT_CLIENT = []
  //     let OFFICE_CLIENT = []
  //     let CLIENT_HOME = []
  //     let HOME_CLIENT = []
  //     let HOME_OFFICE = []
  //     let OFFICE_OFFICE = []

  //     events.forEach((event) => {
  //       if(event && event.traveltime && !event.blank){
  //        let diff = ((new Date(event.end) - new Date(event.start)) / 3600000);
  //       if (event.travel_code === TRAVEL_CODE.CLIENT_CLIENT) {
  //         CLIENT_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.CLIENT_OFFICE) {
  //         CLIENT_OFFICE.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.OFFICE_CLIENT) {
  //         OFFICE_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.CLIENT_HOME) {
  //         CLIENT_HOME.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.HOME_CLIENT) {
  //         HOME_CLIENT.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.HOME_OFFICE) {
  //         HOME_OFFICE.push(diff)
  //       }
  //       else if (event.travel_code === TRAVEL_CODE.OFFICE_OFFICE) {
  //         OFFICE_OFFICE.push(diff)
  //       }
  //         }
  //     })
  //     if (CLIENT_OFFICE.length > 0) {
  //       let totalHours = CLIENT_OFFICE.reduce((total, visit) => total + visit, 0);
  //       let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursClientToOffice(valuesTravel)
  //     }
  //     if (CLIENT_CLIENT.length > 0) {
  //       let totalHours = CLIENT_CLIENT.reduce((total, visit) => total + visit, 0);
  //       let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursClientToClient(valuesTravel)
  //     }
  //     if (OFFICE_CLIENT.length > 0) {
  //       let totalHours = OFFICE_CLIENT.reduce((total, visit) => total + visit, 0);
  //       let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursOfficeToClient(valuesTravel)
  //     }

  //     if (CLIENT_HOME.length > 0) {
  //       let totalHours = CLIENT_HOME.reduce((total, visit) => total + visit, 0);
  //       let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursClientToHome(valuesTravel)
  //     }
  //     if (HOME_CLIENT.length > 0) {
  //       let totalHours = HOME_CLIENT.reduce((total, visit) => total + visit, 0);
  //       let valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursHomeToClient(valuesTravel)
  //     }
  //     if (HOME_OFFICE.length > 0) {
  //       let totalHours = HOME_OFFICE.reduce((total, visit) => total + visit, 0);
  //       let  valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursHomeToOffice(valuesTravel)
  //     }
  //     if (OFFICE_OFFICE.length > 0) {
  //       let totalHours = OFFICE_OFFICE.reduce((total, visit) => total + visit, 0);
  //       let  valuesTravel = totalHours % 0.1 === 0 ? totalHours : Math.round(totalHours * 100) / 100;
  //       setTravelHoursOfficeToOffice(valuesTravel)
  //     }

  //   // if (travelHoursCheck.length > 0) {
  //   //   let totalHours = travelHoursCheck.reduce((total, visit) => total + visit);
  //   //   totalHours % 0.1 === 0
  //   //     ? setTravelHours(totalHours)
  //   //     : setTravelHours(Math.round(totalHours * 100) / 100);
  //   // } else {
  //   //   setTravelHours(0);
  //   // }
  // };

  // useEffect(() => {
  //   if (events) {

  // let uniquie_visit_id = []
  // VISITS_ASSIGNED = 0
  // VISITS_UNASSIGNED = 0
  // VISITS_PUBLISHED = 0
  // VISITS_UNPUBLISHED = 0
  // events.map(detail => {
  // if (!uniquie_visit_id.find(id => id === detail.id)) {
  //   uniquie_visit_id.push(detail.id)
  //   if (detail.unassigned != undefined) {
  //     if (!detail.unassigned) {
  //       VISITS_ASSIGNED += 1;
  //     } else {
  //       VISITS_UNASSIGNED += 1;
  //     }
  //     if (detail.is_published) {
  //       VISITS_PUBLISHED += 1
  //     } else {
  //       VISITS_UNPUBLISHED += 1
  //     }
  //   }
  // }
  // })
  // let unHoursCheck = events
  //   .filter(event => event && event.unassigned)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (unHoursCheck.length > 0) {
  //   let unHours = unHoursCheck.reduce((total, visit) => total + visit);
  //   unHours % 0.1 === 0
  //     ? setUnassignedHours(unHours)
  //     : setUnassignedHours(Math.round(unHours * 100) / 100);
  // } else {
  //   setUnassignedHours(0);
  // }

  // let publishedHoursCheck = events
  //   .filter(event => event && event.visit && event.is_published)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (publishedHoursCheck.length > 0) {
  //   let unHours = publishedHoursCheck.reduce((total, visit) => total + visit);
  //   unHours % 0.1 === 0
  //     ? setPublishedHours(unHours)
  //     : setPublishedHours(Math.round(unHours * 100) / 100);
  // } else {
  //   setPublishedHours(0);
  // }

  // let unPublishedHoursCheck = events
  //   .filter(event => event && event.visit && !event.is_published)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (unPublishedHoursCheck.length > 0) {
  //   let unHours = unPublishedHoursCheck.reduce((total, visit) => total + visit);
  //   unHours % 0.1 === 0
  //     ? setUnPublishedHours(unHours)
  //     : setUnPublishedHours(Math.round(unHours * 100) / 100);
  // } else {
  //   setUnPublishedHours(0);
  // }

  // let aHoursCheck = events
  //   .filter(event => event && event.visit && !event.unassigned && !event.traveltime && !event.waittime && !event.blank)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (aHoursCheck.length > 0) {
  //   let aHours = aHoursCheck.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setAssignedHours(aHours)
  //     : setAssignedHours(Math.round(aHours * 100) / 100);
  // } else {
  //   setAssignedHours(0);
  // }

  // let tavel_code_array = []
  // if (evvSettingData.client_to_client) {
  //   tavel_code_array.push('CLIENT-CLIENT')
  // }
  // if (evvSettingData.client_to_home) {
  //   tavel_code_array.push('CLIENT-HOME')
  // }
  // if (evvSettingData.client_to_office) {
  //   tavel_code_array.push('CLIENT-OFFICE')
  // }
  // if (evvSettingData.office_to_client) {
  //   tavel_code_array.push('OFFICE-CLIENT')
  // }
  // if (evvSettingData.home_to_client) {
  //   tavel_code_array.push('HOME-CLIENT')
  // }
  // if (evvSettingData.home_to_office) {
  //   tavel_code_array.push('HOME-OFFICE')
  // }
  // if (evvSettingData.office_to_office) {
  //   tavel_code_array.push('OFFICE-OFFICE')
  // }

  // if (contextChoices.travel_type) {
  //   const newTravelModeHours = {};
  //   contextChoices.travel_type.forEach((val) => {
  //     let travelHoursCheckMode = events
  //       .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.visitTravel && (event.travel_mode_name == val.name))
  //       .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  //     let aHours = travelHoursCheckMode.length > 0 ? travelHoursCheckMode.reduce((total, visit) => total + visit) : 0;
  //     newTravelModeHours[val.name] = aHours % 0.1 === 0 ? aHours : Math.round(aHours * 100) / 100;
  //   })
  //   setTravelModeHours(newTravelModeHours);
  // }

  // && tavel_code_array.some((val) => val == event.travel_code)
  // let travelHoursCheck1 = events
  //   .filter(event => event && event.traveltime && !event.blank)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);



  // if (travelHoursCheck1.length > 0) {
  //   let aHours = travelHoursCheck1.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHours(aHours)
  //     : setTravelHours(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHours(0);
  // }

  // // client to client
  // let travelHoursCheck2 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-CLIENT')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck2.length > 0) {
  //   let aHours = travelHoursCheck2.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursClientToClient(aHours)
  //     : setTravelHoursClientToClient(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursClientToClient(0);
  // }

  // // client to home
  // let travelHoursCheck3 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-CLIENT')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck3.length > 0) {
  //   let aHours = travelHoursCheck3.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursClientToHome(aHours)
  //     : setTravelHoursClientToHome(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursClientToHome(0);
  // }

  // // client to office
  // let travelHoursCheck4 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'CLIENT-OFFICE')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck4.length > 0) {
  //   let aHours = travelHoursCheck4.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursClientToOffice(aHours)
  //     : setTravelHoursClientToOffice(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursClientToOffice(0);
  // }

  // //office to client
  // let travelHoursCheck5 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-CLIENT')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck5.length > 0) {
  //   let aHours = travelHoursCheck5.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursOfficeToClient(aHours)
  //     : setTravelHoursOfficeToClient(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursOfficeToClient(0);
  // }

  // //home to client 
  // let travelHoursCheck6 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-CLIENT')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck6.length > 0) {
  //   let aHours = travelHoursCheck6.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursHomeToClient(aHours)
  //     : setTravelHoursHomeToClient(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursHomeToClient(0);
  // }

  // //home to office
  // let travelHoursCheck7 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'HOME-OFFICE')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck7.length > 0) {
  //   let aHours = travelHoursCheck7.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursHomeToOffice(aHours)
  //     : setTravelHoursHomeToOffice(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursHomeToOffice(0);
  // }

  // //office to office
  // let travelHoursCheck8 = events
  //   .filter(event => event && event.traveltime && !event.blank && event.travel_code && event.travel_code === 'OFFICE-OFFICE')
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

  // if (travelHoursCheck8.length > 0) {
  //   let aHours = travelHoursCheck8.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setTravelHoursOfficeToOffice(aHours)
  //     : setTravelHoursOfficeToOffice(Math.round(aHours * 100) / 100);
  // } else {
  //   setTravelHoursOfficeToOffice(0);
  // }



  // Trave hr calculation fun
  // calculateAndSetTravelHours()


  // Commented wait hr calculation

  // let waitHoursCheck = events
  //   .filter(event => event && event.waittime && !event.blank)
  //   .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);
  // if (waitHoursCheck.length > 0) {
  //   let aHours = waitHoursCheck.reduce((total, visit) => total + visit);
  //   aHours % 0.1 === 0
  //     ? setWaitHours(aHours)
  //     : setWaitHours(Math.round(aHours * 100) / 100);
  // } else {
  //   setWaitHours(0);
  // }
  //   }
  // }, [events, evvSettingData, contextChoices.travel_type]);



  const setCallerInterval = () => {
    addCaller = setInterval(() => {
      getOptaplannerStatus();
    }, 20000);

    setCaller(addCaller);
  };

  const closeAssignModal = () => {
    clearSelectedElId()
    setModalUnassignValue(false)
    setAssignVisits(false)
  }

  const handleDuplicateVisits = () => {
    let body = {
      visit_ids: rightClickSelectedId.length ? rightClickSelectedId : [...checkedRows]
    }
    duplicateVisits(body)
      .then(res => {
        searchScheduler()
        setRightClickSeletcedId([])
        setSelectedEvents([]);
      })
  }

  const handleRevertVisits = () => {
    let body = {
      visit_ids: rightClickSelectedId.length ? rightClickSelectedId : checkedRows.length > 0 ? [...checkedRows] : [schedularVisitId]
    }
    revertVisits(body)
      .then(res => {
        searchScheduler()
        setModalRevertValue(false)
        setRightClickSeletcedId([])
        setSelectedEvents([])
        clearSelectedElId();
      })
  }

  const handleCopyVisits = async () => {


    try {
      let body = {
        visit_ids: rightClickSelectedId.length ? [rightClickSelectedId].flat() : checkedRows.length > 0 ? [...checkedRows] : [schedularVisitId]
      }
      const res = await copyVisits(body)
      setModalCopyVisit(false)
      if (res.status == "True") {
        setRightClickSeletcedId([])
        setCheckedRows([])
        searchScheduler()
        toast.current.show({
          severity: 'success',
          summary: '',
          detail: res.message
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'Something went wrong!'
        });
      }
    } catch (error) {
      setRightClickSeletcedId([])
      setCheckedRows([])
      setModalCopyVisit(false)
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Something went wrong!'
      });
    }
  }

  const handlePublishOrUnpublishVisits = (iSPublished) => {
    let body = {
      visit_ids: [schedularVisitId],
      status: iSPublished ? false : true,
    }
    publishOrUnpublishVisits(body)
      .then(res => {
        searchScheduler()
        setModalPublishOrUnpublishValue(false)
      })
  }

  const handleUndoCancel = () => {
    let body = {
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows]
    }
    undoCancelVisit(body)
      .then(res => {
        searchScheduler()
        setRightClickSeletcedId([])
        setSelectedEvents([])
      })
  }
  const handleShadowVisits = (observKey) => {
    let body = {
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows]
    }
    setShadowVisits(body, observKey)
      .then(res => {
        searchScheduler()
        setRightClickSeletcedId([])
        setSelectedEvents([])
      })
  }

  // const getVisitData = (view) => {
  //   if (view === 'team') {
  //     getEmployeeVisitData(values)
  //       .then(res => {
  //         if (res.data) {
  //           setEmployeeVisitData(res.data)
  //           setcheckVisitData(true)
  //         }
  //       })
  //   }
  //   else if (view === 'client') {
  //     getClientVisitData(values)
  //       .then(res => {
  //         if (res) {
  //           setClientVisitData(res)
  //           setcheckVisitData(true)
  //         }
  //       })
  //   } else {
  //     setcheckVisitData(true)
  //   }

  // }

  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }
  const onShowAlert = (type, text) => {
    setAlert({
      type: type,
      text: text,
      show: true
    })
  }

  const dropDownHandleCancelVisit = (item, nameItem) => {
    values[CANCEL_VISIT_VALUES][nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const loadChoices = () => {
    // let defaultSeletedPayType = contextChoices?.pay_type && contextChoices?.pay_type.filter(p => p?.name !== "Absence" && p?.name !== "Unavailability")
    // values.pay_type = defaultSeletedPayType
    // setValues({ ...values })
    // setPayTypeNameFilter(defaultSeletedPayType)

    setChoices(contextChoices && contextChoices || {});

    setVisitStatusData(contextChoices.visit_status && contextChoices.visit_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunRouteData(contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setJobDepartment(contextChoices.job_category && contextChoices.job_category.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setBranch(contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunNameData(contextChoices.run_view && contextChoices.run_view.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setEmployeeListing(contextChoices.employee_listing && contextChoices.employee_listing.map((val) => {
      return {
        id: val.id, name: val.full_name, branch: val.branch

      }
    })
    )
    let tempResource = contextChoices && contextChoices.run_view && contextChoices.run_view.map(y => {
      return { id: y && y.id, title: y && y.name }
    })
    if (tempResource) {
      setResourcesRunroutes([...tempResource, ...resourcesRunroutes])
    }

    setBillRate(contextChoices?.rate_card_list_by_type?.['Bill Rates'] || []);
    setPayRate(contextChoices?.rate_card_list_by_type?.['Pay Rates'] || []);
    setTravelRate(contextChoices?.rate_card_list_by_type?.['Travel Rates'] || []);


    let employees = [];
    let employeesResources = [];
    if (contextChoices.employee_listing) {
      employees = contextChoices.employee_listing.map(employee => {
        return { id: employee.id, name: employee.full_name, branch: employee.branch };
      });


      setEmployees(employees || []);
      setEmployeesForFilter([{ id: "none", name: "Unassigned" }, ...employees] || []);
    }

    let clients = [];
    let clientsResources = [];

    if (contextChoices.client_listing) {
      clients = contextChoices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      });

      clientsResources = contextChoices.client_listing.map(client => {
        return {
          id: client.id,
          title: client.full_name,
        };
      });
      setResourcesClients(clientsResources);
      setClients(clients || []);
    }
    // Location view resources

    const locationresources = listOfLocations.map((item) => ({
      id: item.id,
      title: item.full_name
    }))
    setResourcesLocations(locationresources)
    setLocations(locationresources.map(loc => ({ ...loc, name: loc.title })))
  };




  const handleUnAssignVisits = async () => {
    let body = {
      visit_ids: rightClickSelectedId.length ? rightClickSelectedId.join(",") : checkedRows.join(",")
    }
    let response = await UnassignAllVisits(body)
    searchScheduler()
    setRightClickSeletcedId([])
    setSelectedEvents([])
  }

  const dropDownHandleSelect = (item) => {

    if (item.run_name_view === 'new') {
      setNewRun(true);
    } else {
      setNewRun(false);
    }
    if (item.client) {
      item.client = item.client.length === (clients ? clients : 0).length || item.client.length === 0 ? [] : item.client
    }
    // if (item.branch) {
    //   item.branch = item.branch.length === (branch ? branch : 0).length || item.branch.length === 0 ? [] : item.branch
    // }
    if (item.branch_for_dropdown) {
      item.branch_run = item.branch_for_dropdown
      item.branch_for_dropdown = item.branch_for_dropdown
    }

    if (item.branch_for_delete) {
      item.branch_for_delete_names = item.branch_for_delete.map((item) => (item.label))
    }

    if (item.jobDepartment) {
      item.jobDepartment = item.jobDepartment.length === (jobDepartment ? jobDepartment : 0).length || item.jobDepartment.length === 0 ? [] : item.jobDepartment
    }

    if (item.visitStatus) {
      item.visitStatus = item.visitStatus.length === (visitStatusData ? visitStatusData : 0).length || item.visitStatus.length === 0 ? [] : item.visitStatus
    }


    setValues({ ...values, ...item });
  };
  const handleTimeCodeFilter = item => {

    if (item.length === 0 || item.length === 3) {
      if (events) {
        let tempEvents = totalEvents.filter(item => item.traveltime || item.visit || item.waittime || item.blank)
        setEvents([...tempEvents])
      }
      setFinalTimeCode(item)
    }
    else {
      let tempFinalCode = [...finalTimeCode]
      let filterCode = tempFinalCode && tempFinalCode.find(event => event.id === item[0].id)
      if (filterCode) {
        tempFinalCode = tempFinalCode && tempFinalCode.filter(event => event.id !== filterCode.id)
      } else {
        tempFinalCode = [...tempFinalCode, { ...item[0] }]
      }
      setFinalTimeCode(tempFinalCode)
      let tempevents = []
      tempFinalCode && tempFinalCode.forEach(code => {

        if (events && code) {
          if (code.id === '111') {
            let visitEvents = totalEvents.filter(event => event.visit)
            tempevents = [...tempevents, ...visitEvents]
          }
          if (code.id === '222') {
            let visitEvents = totalEvents.filter(event => event.traveltime)
            tempevents = [...tempevents, ...visitEvents]
          }
          if (code.id === '333') {
            let visitEvents = totalEvents.filter(event => event.waittime)
            tempevents = [...tempevents, ...visitEvents]
          }
        }

      })
      setEvents([...tempevents])

    }

  }



  const handleVisitFilter = (item) => {

    if (item.visits === 'Assigned') {
      let tempEvents = totalEvents.filter(event => event.title !== 'Unassigned' && event.title !== 'Cancelled')
      const uniqueClients = [...new Set(tempEvents.map(item => item.resourceId))];
      setClientResource(uniqueClients.length)
      setEvents([...tempEvents])
    }
    if (item.visits === 'Unassigned') {
      let tempEvents = totalEvents.filter(event => event.title.endsWith('Unassigned'))
      const uniqueClients = [...new Set(tempEvents.map(item => item.resourceId))];
      setClientResource(uniqueClients.length)
      setEvents([...tempEvents])
    }

    if (item.visits === 'Cancelled') {
      let tempEvents = totalEvents.filter(event => event.title === 'Cancelled')
      const uniqueClients = [...new Set(tempEvents.map(item => item.resourceId))];
      setClientResource(uniqueClients.length)
      setEvents([...tempEvents])
    }
    if (item.visits === 'Regularvisit') {
      let tempEvents = totalEvents.filter(event => event.isRegular)
      const uniqueClients = [...new Set(tempEvents.map(item => item.resourceId))];
      setClientResource(uniqueClients.length)
      setEvents([...tempEvents])
    }

    if (item.visits === 'All') {
      const uniqueClients = [...new Set(totalEvents.map(item => item.resourceId))];
      setClientResource(uniqueClients.length)
      setEvents([...totalEvents])
    }
  }
  const dropDownHandleVisit = (item, nameItem) => {
    if (item[nameItem] == 1) {
      let id = values[VISITS_VALUES].client
      values[VISITS_VALUES].name = contextChoicesMap?.client_listing[id] && contextChoicesMap?.client_listing[id].full_name;
    }

    if (nameItem == 'funder') {
      values[VISITS_VALUES][nameItem] = item[nameItem];
      getFunderDetails(item[nameItem])
        .then((res) => {
          if (res) {
            values[VISITS_VALUES].category = res.funder_category && res.funder_category;
            setValues({ ...values });
          }
        })
      setValues({ ...values });
    }


    values[VISITS_VALUES][nameItem] = item[nameItem];
    setValues({ ...values });
  };


  const dropDownHandleAdhocVisit = (item, nameItem) => {

    if (item[nameItem] == 1) {
      let id = values[ADHOC_VIIST_VALUES].client
      values[ADHOC_VIIST_VALUES].name = contextChoicesMap?.client_listing[id] && contextChoicesMap?.client_listing[id].full_name;
    }

    if (nameItem == 'funder') {
      values[ADHOC_VIIST_VALUES][nameItem] = item[nameItem];
      getFunderDetails(item[nameItem])
        .then((res) => {
          if (res) {
            values[ADHOC_VIIST_VALUES].category = res.funder_category && res.funder_category;
            setValues({ ...values });
          }
        })
      setValues({ ...values });
    }

    values[ADHOC_VIIST_VALUES][nameItem] = item[nameItem];
    setValues({ ...values });
  };



  const dropdownHandleAdhocEmployee = (item, nameItem, indexEmployee) => {
    values[ADHOC_VIIST_VALUES].employee_required[indexEmployee][nameItem] = item[nameItem]

    setValues({ ...values })
  }


  const dropdownHandleAdhocEmployeeSkill = (item, indexEmployee) => {
    if (item.skill) {
      values[ADHOC_VIIST_VALUES].employee_required[indexEmployee].skills = item.skill
    }

    if (item.competency) {
      const reviewCompetency =
        values[ADHOC_VIIST_VALUES].employee_required;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.competency) {
          employeeElement.competency = [];
        }

      });

      values[ADHOC_VIIST_VALUES].employee_required[
        indexEmployee
      ].competency = item.competency;

    }
    if (item.specific_groups) {
      const reviewCompetency =
        values[ADHOC_VIIST_VALUES].employee_required;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.specific_groups) {
          employeeElement.specific_groups = [];
        }
      });

      values[ADHOC_VIIST_VALUES].employee_required[indexEmployee].specific_groups = item.specific_groups;
    }


    setValues({ ...values })

  }


  const dropdownHandleReplicaEmployee = (item, nameItem) => {
    values[VISITS_VALUES].req_replica[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const dropdownHandleReplicaEmployeeSkill = (item) => {

    if (item.skill) {
      values[VISITS_VALUES].skill_replica = item.skill
    }

    if (item.competency) {
      values[VISITS_VALUES].competency = item.competency;
    }

    if (item.specific_groups) {
      values[VISITS_VALUES].specific_groups = item.specific_groups;
    }
    setValues({ ...values })
  }

  const dropDownHandleEmployee = (item, nameItem) => {
    if (!values[EMPLOYEE_VALUES]) {
      values[EMPLOYEE_VALUES] = {};
    }
    if (item.id === null) {
      values[EMPLOYEE_VALUES] = null;
    } else {
      values[EMPLOYEE_VALUES] = item[nameItem];
    }
    setValues({ ...values });
  };

  const dropDownHandleClient = async (item, nameItem, isLocation) => {

    if (nameItem === 'client' || isLocation) {
      let id = item[nameItem]
      try {
        const res = await getAdhocServiceAddress(id, isLocation ? "location" : "client")
        values[ADHOC_VIIST_VALUES].service_address = res
        values[ADHOC_VIIST_VALUES][nameItem] = item[nameItem];
        setValues({ ...values });
      } catch (error) {
        console.error("Error: fetching service address API", error)
      }
    }
    else {
      values[ADHOC_VIIST_VALUES].service_address = ""
      values[ADHOC_VIIST_VALUES][nameItem] = item[nameItem];
      setValues({ ...values });
    }
  };

  const dropDownHandleSkill = (item) => {
    values[ADHOC_VIIST_VALUES].skills = item.skill;
    setValues({ ...values });
  };

  const visitStatusColourClient = (detail, doubleUp) => {
    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              return '#ffa500'
            } else if (detail.visit_status_name === 'Missed') {
              if (detail.doubleUp || doubleUp) {
                return DOUBLE_UP_MISSED
              } else {
                return '#ff3b3b'
              }
            } else {
              if (detail.doubleUp || doubleUp) {
                // return '#CF9FFF'
                return NEW_DOUBLE_UP
              } else {
                if (detail.is_pinned_employee) {
                  return '#00FF7F'
                } else {
                  return SCHEDULER_ASSIGNED
                }
              }
            }
          } else {
            if (detail.doubleUp || doubleUp) {
              return '#ee9498'
            }
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }



  }

  const visitStatusColourTeam = (detail, checkDesire, doubleUp) => {
    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              let sch_start = new Date(detail.start_date);
              let act_start = new Date(detail.actual_visit_start_time);
              let differenceValue = (sch_start.getTime() - act_start.getTime()) / 1000;
              differenceValue /= 60;
              let result = Math.abs(Math.round(differenceValue))

              let hms = evvVal.early;   // your input string
              let a = hms.split(':'); // split it at the colons

              // Hours are worth 60 minutes.
              let minutes = (+a[0]) * 60 + (+a[1]);
              if ((sch_start > act_start) && (result > minutes)) {
                return '#f0b54a'
              } else {
                return '#ffa500'
              }
            } else {
              if (detail.incompatibility) {
                return '#912929'
              } else {
                if (detail.visit_status_name === 'Missed') {
                  if (detail.doubleUp || doubleUp) {
                    return DOUBLE_UP_MISSED
                  } else {
                    return '#ff3b3b'
                  }
                } else {
                  // Create date from input value
                  // let inputDate = new Date(detail.start_date);
                  // // Get today's date
                  // let todaysDate = new Date();
                  // // call setHours to take the time out of the comparison
                  // let differenceValue = (todaysDate.getTime() - inputDate.getTime()) / 1000;
                  // differenceValue /= 60;
                  // let result = Math.abs(Math.round(differenceValue))
                  // let hms = evvVal.late;   // your input string
                  // let a = hms.split(':'); // split it at the colons

                  // // Hours are worth 60 minutes.
                  // let minutes = (+a[0]) * 60 + (+a[1]);

                  // if ((inputDate < todaysDate) && (result > minutes)) {
                  //   return '#f74a4a'
                  // } else {
                  if (detail.visit_type_id && contextChoicesMap.visit_type && contextChoicesMap.visit_type[detail.visit_type_id] && contextChoicesMap.visit_type[detail.visit_type_id].color_code) {
                    return contextChoicesMap.visit_type[detail.visit_type_id].color_code
                  } else {
                    if (detail.doubleUp || doubleUp) {
                      // return '#5aa7f2'
                      return NEW_DOUBLE_UP;
                    } else {
                      if (detail.is_pinned_employee) {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          return '#ADD8E6'
                        }
                      } else {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          let startDate = new Date(detail.start_date)
                          let endDate = new Date(detail.end_date)
                          const isPassedTime = moment().isAfter(detail.start_date);
                          if (isPassedTime) {
                            return "#FF0000";
                          } else {

                            return '#1E90FF'
                          }

                        }
                      }
                    }
                  }

                  // }
                }
              }
            }

          } else {
            if (detail.doubleUp || doubleUp) {
              return '#ee9498'
            }
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }
  }

  const rotashiftColor = (name) => {
    switch (name) {
      case "Absence":
        return '#343434'
      case "Unavailability":
        return '#828282'
      case "On Call":
        return '#FF8C00'
      case "Car - Driver - Shift":
        return '#e0b16e'
      case "Bank-Flexible":
        return '#de5285'
      default:
        return '#e89b2e'
    }
  }

  const handleDownlaodPdf = () => {
    const input = document.querySelector('#my-cal-pdf');
    if (input) {
      // Apply CSS styles to the element
      input.classList.add('pdf-calendar-align');
    }
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
        let position = 0;
        let pageHeight = 210;
        let imgProps = pdf.getImageProperties(imgData);
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        var heightLeft = pdfHeight;
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - pdfHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;
        }
        const current_date = moment().format('DD-MM-YYY')
        pdf.save(`sch_${current_date}`);
      });
  };

  const calenderChange = async (view) => {
    if (!loading) {
      setLoading(true)
    }
    setEvents([]);
    // VISITS_ASSIGNED = 0;
    // VISITS_PUBLISHED = 0;
    // VISITS_UNASSIGNED = 0;
    // VISITS_UNPUBLISHED = 0;
    if (!mountedRef.current) return null;
    // if (view === 'client') {
    //   setClientResource(schedulerData.length)
    // }
    updateSchData(schedulerData, rotaData, view)
  }

  const handleDefaultPayTypeFilter = async () => {
    const { pay_type } = await handleChangeChoices('pay_type') || {};
    let defaultSeletedPayType = pay_type && pay_type.filter(p => p?.name !== "Absence" && p?.name !== "Unavailability")
    values.pay_type = defaultSeletedPayType
    setValues({ ...values, loadPayType: true })
    setPayTypeNameFilter(defaultSeletedPayType)

    return defaultSeletedPayType
  }

  const searchScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEvents([]);
    setCheckedValue(false)
    TOTAL_VISITS = 0;


    try {
      const [schedulerResponse, rotaResponse] = await Promise.all(
        [
          getScheduler({ ...branchData, ...values }, filterExcludeCheck, 800),
          getSchedulerRotaShifts({ ...branchData, ...values }, filterExcludeCheck, 800)
        ]);

      if (schedulerResponse.hasOwnProperty("status") && schedulerResponse.status == "FAIL") {
        throw schedulerResponse
      }
      if (rotaResponse.hasOwnProperty("status") && rotaResponse.status == "FAIL") {
        throw rotaResponse
      }

      if (schedulerResponse && rotaResponse) {
        setApiError({ status: false, message: "" })
        //  VISITS_ASSIGNED = 0;
        // VISITS_PUBLISHED = 0;
        // VISITS_UNASSIGNED = 0;
        // VISITS_UNPUBLISHED = 0;
        if (!mountedRef.current) return null;

        setLoadingCount(prev => prev + 1)
        if (schedulerResponse.data)
          setSchedulerData(schedulerResponse.data || [])
        setLoadingCount(prev => prev + 1)
        if (rotaResponse.data)
          setRotaData(rotaResponse.data || [])
        // updateSchData(schedulerResponse.data, rotaResponse.data, calendarView)
        let data = calculateTotalCounts(schedulerResponse?.visitScreenData, rotaResponse?.shiftScreenData)
        setVisitScreenData(data)

      }
    } catch (error) {
      console.error("Error: fetching schduler data", error)
      toast.current.show({ severity: 'error', summary: 'Failed', detail: error.message });
      setApiError({ status: true, message: SERVER_ERROR })
      setLoadingCount(3)
      setLoading(false)
    }
    // //schedular api
    // const response = await getScheduler({ ...branchData, ...values }, filterExcludeCheck, 800)
    // VISITS_ASSIGNED = 0;
    // VISITS_PUBLISHED = 0;
    // VISITS_UNASSIGNED = 0;
    // VISITS_UNPUBLISHED = 0;
    // if (!mountedRef.current) return null;
    // if (calendarView === 'client') {
    //   setClientResource(response && response.length)
    // }
    // setLoadingCount(prev => prev + 1)
    // setSchedulerData(response)

    // //rotashift api
    // const rota_res = await getSchedulerRotaShifts({ ...branchData, ...values }, filterExcludeCheck, 800)
    // setLoadingCount(prev => prev + 1)
    // setRotaData(rota_res)
    // //updateSchData(response, rota_res, calendarView)
  };

  function calculateTotalCounts(schduler, rotaShift) {
    const client = schduler && schduler?.client && schduler?.client;
    const location = schduler?.location && schduler?.location;

    const clientTravel = client?.travelHours && client?.travelHours;
    const locationTravel = location.travelHours && location.travelHours;
    const shiftTravel = rotaShift?.travelHours && rotaShift?.travelHours;

    if (calendarView === 'client')
      setClientResource(client?.employee)

    const totalEmployee = client?.employee + location?.employee;

    const totalVisitAssigned = client?.visitAssigned + location?.visitAssigned;

    const totalAssignedVisits = client?.assignedVisits + location?.assignedVisits;

    const totalUnassignedVisits =
      client?.unassignedVisits + location?.unassignedVisits;

    const totalPublishVisits = client?.publishVisits + location?.publishVisits;
    const totalUnpublishVisits =
      client?.unpublishVisits + location?.unpublishVisits;


    const totalAssignedHours = roundToTwoDecimals(client?.assignedHours + location?.assignedHours);
    const totalUnassignedHours = roundToTwoDecimals(client?.unassignedHours + location?.unassignedHours)

    const totalPublishHours = roundToTwoDecimals(client?.publishHours + location?.publishHours)
    const totalUnpublishHours = roundToTwoDecimals(client?.unpublishHours + location?.unpublishHours)

    // Travel hours 
    const clientTravelCode = {}
    const locationTravelCode = {}
    const shiftTravelCode = {}

    let totalTravelMinutes = 0;

    travelCodes.forEach(item => {
      totalTravelMinutes += locationTravel[item] + clientTravel[item] + shiftTravel[item];

      clientTravelCode[item] = roundToTwoDecimals(clientTravel[item] || 0)
      locationTravelCode[item] = roundToTwoDecimals(locationTravel[item] || 0)
      shiftTravelCode[item] = roundToTwoDecimals(shiftTravel[item] || 0)

    });

    const totalTravelHours = roundToTwoDecimals(totalTravelMinutes)


    const clientTravelMode = {}
    const locationTravelMode = {}
    const shiftTravelMode = {}


    let a = contextChoices && contextChoices?.travel_type
      && contextChoices?.travel_type?.forEach(mode => {

        clientTravelMode[mode?.name] = roundToTwoDecimals(clientTravel[mode?.name] || 0)
        locationTravelMode[mode?.name] = roundToTwoDecimals(locationTravel[mode?.name] || 0)
        shiftTravelMode[mode?.name] = roundToTwoDecimals(shiftTravel[mode?.name] || 0)
      })
    console.log(new Date(), {
      clientTravelCode,
      locationTravelCode,
      shiftTravelCode,

      clientTravelMode,
      locationTravelMode,
      shiftTravelMode,
    }, "hower data")
    return {
      totalEmployee,
      totalAssignedHours,
      totalAssignedVisits,
      totalVisitAssigned,
      totalUnassignedHours,
      totalUnassignedVisits,
      totalPublishHours,
      totalPublishVisits,
      totalUnpublishHours,
      totalUnpublishVisits,
      clinetData: client,
      locationData: location,
      totalTravelHours,

      clientTravelCode,
      clientTravelMode,

      locationTravelCode,
      locationTravelMode,

      shiftTravelCode,
      shiftTravelMode,

      visitScreenData: schduler,
      shiftScreenData: rotaShift,
      totalLocation: location?.location || 0,
      totalClient: client?.client || 0,
    };
  }

  const handleAddVisitModal = () => {
    setAddVisit(!addVisit);
  };
  const handleAddRegularModal = () => {
    values.run_name = null;
    values.run_name_view = null;
    setNewRun(false)
    setValues({ ...values });
    setAddRegularVisit(!addRegularVisit);
  };
  const handleAddRunNameModal = () => {
    values.run_name = null;
    values.run_name_view = null;
    setNewRun(false)
    setValues({ ...values });
    setAddBulkRunName(!addBulkRunName);
  };

  const getPinnedData = (value) => {
    const getEmployeeWeekDay = (day) => {
      switch (day) {
        case "Monday":
          return 'MON'
        case "Tuesday":
          return 'TUE'
        case "Wednesday":
          return 'WED'
        case "Thursday":
          return 'THU'
        case "Friday":
          return 'FRI'
        case "Saturday":
          return 'SAT'
        case "Sunday":
          return 'SUN'
        default:
          break;
      }
    }
    let body = {
      employee_id: value.employee.id,
      requirement_id: value.visitValues.client_service_visit_requirement.id,
      client_service_visit_id: value.visitValues.id,
      each_repeat_on: getEmployeeWeekDay(moment(values.regular_start_date).format('dddd'))
    }
    getPinnedEmp(body)
      .then((res) => {
        setPinnedData(res.data)
      })
      .catch(() => { })
  }


  const cancelVisitReset = () => {
    values[CANCEL_VISIT_VALUES] = { employee_full_name: values[CANCEL_VISIT_VALUES].employee_full_name }
    setSaveButtonIcon(FLOPPY)
    setCheckRequires(false)
    setTextStatus(SAVE);
    setErrorMessage("");
  }
  const handleCancelVisit = (type) => {
    if (type == "multi") {
      cancelVisitReset()
      setCancelVisit(!cancelVisit)
    }
    else {
      cancelVisitReset()
      handleAddVisitModal()
      setCancelVisit(!cancelVisit)
    }


  }

  const handleDeleteVisit = (type) => {
    if (type == 'multi') {
      setModalDeleteValue(true);
    } else {
      setModalDeleteValue(true);
      setIdToDelete(values.visit_id);
    }

  };

  const handleSchedulerRevert = () => {
    setModalRevertValue(true)
    handleAddVisitModal()
  }

  const handleSchedulerPublishOrUnpublish = () => {
    setModalPublishOrUnpublishValue(true)
    handleAddVisitModal()
  }

  const handleDeleteTheVisit = async () => {
    if (deleteVisitDisabled) return;
    setDeleteVisitDisabled(true)
    let deletedIds = checkedRows.length > 0 ? [...checkedRows] : [idToDelete]
    let body = {
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : deletedIds
    }
    try {
      const res = await deleteVisit(body)
      if (res.status == "True") {
        setModalDeleteValue(false);
        toast.current.show({ severity: 'success', summary: '', detail: res.message });
        setRightClickSeletcedId([])
        setSelectedEvents([])
        clearSelectedElId();
        searchScheduler();
        setTimeout(() =>  {
          setDeleteVisitDisabled(false)
        }, 200)
        
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
        setDeleteVisitDisabled(false)
      }
    } catch (error) {

      setDeleteVisitDisabled(false)
    }

  }

  const updateCancelVisit = (value) => {
    if (errors.length > 0) {
      // setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    let visit_data = {
      employee_id: values[CANCEL_VISIT_VALUES].employee_id,
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : checkedRows.length > 0 ? [...checkedRows] : [values.visit_id],
      visit_cancellation_reason: values[CANCEL_VISIT_VALUES].cancellation_reason,
      visit_cancellation_notes: value && value,
      visit_cancellation_notification_method: values[CANCEL_VISIT_VALUES].notification_cancellation,
      visit_cancelled_datetime: values[CANCEL_VISIT_VALUES].cancel_date + "T" + values[CANCEL_VISIT_VALUES].time_of_cancel,
      client_to_be_billed: values[CANCEL_VISIT_VALUES].client_to_be_billed,
      staff_to_be_billed: values[CANCEL_VISIT_VALUES].staff_to_be_paid,
      is_fixed_cancel_amount: values[CANCEL_VISIT_VALUES].is_fixed_cancel_amount ? true : false
    }
    setErrorMessage('');
    editCancelledVisit(visit_data)
      .then(res => {
        setSaveButtonIcon(CHECK);
        setTextStatus(SAVED);
        setLoadingSave(false);
        setCancelVisit(false)
        searchScheduler()

      })
      .catch(error => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
      });
  }

  const saveCancelVisit = (value) => {
    if (errors.length > 0) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    let visit_data = {
      visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : checkedRows.length > 0 ? [...checkedRows] : [values.visit_id],
      visit_cancellation_reason: values[CANCEL_VISIT_VALUES].cancellation_reason,
      visit_cancellation_notes: value && value,
      visit_cancellation_notification_method: values[CANCEL_VISIT_VALUES].notification_cancellation,
      visit_cancelled_datetime: values[CANCEL_VISIT_VALUES].cancel_date + "T" + values[CANCEL_VISIT_VALUES].time_of_cancel + ":00",
      client_to_be_billed: values[CANCEL_VISIT_VALUES].client_to_be_billed,
      staff_to_be_billed: values[CANCEL_VISIT_VALUES].staff_to_be_paid,
      is_fixed_cancel_amount: values[CANCEL_VISIT_VALUES].is_fixed_cancel_amount ? true : false
    }
    setErrorMessage('');
    cancelVisitSave(visit_data)
      .then(res => {
        setSaveButtonIcon(CHECK);
        setTextStatus(SAVED);
        setLoadingSave(false);
        setCancelVisit(false)
        searchScheduler()
        setRightClickSeletcedId([])
        setSelectedEvents([])
        clearSelectedElId();
      })
      .catch(error => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
      });
  }
  const resetValues = () => {
    values[ADHOC_VIIST_VALUES] = {}
    values.user_type = null
    setErrorMessage('');
    setCheckRequires(false);
    setLoadingSave(false);

    const lengthError = errors.length;
    errors.splice(0, lengthError);
  };

  const handleAdhocVisitModal = () => {
    resetValues()
    setTextStatus(SAVE);
    setSaveButtonIcon(FLOPPY);
    setAdhocVisit(!adhocVisit);
  };

  const handleConfirmRegularVisitPopup = () => {
    setModalConfirmRegularVisit(false);
    handleAddRegularModal();
  }

  const resetValuesRota = () => {
    values.idSelected = '';
    values.shiftAvailabilityId = '';
    values.availabilitiesDetails = '';
    values.shiftId = '';
    values.shiftDetails = '';
    values.employee = '';
    values.start_day = '';
    values.end_day = '';
    values.name = '';
    values.start = '';
    values.shift_type = '';
    values.travel_rate = '';
    values.regular_shift_pay_rate_id = '';
    values.travel_time = null;
    values.comment = '';
    values.end = '';
    values.shift = '';
    values.pattern = '';
    values.full_day = false;
    values.travel_method = '';
    values.travel_mode = '';
    values.is_overnight = false;
    values.absence_informed_method = '';
    values.absence_paid = '';
    values.absence_planned = '';
    values.absence_reason = '';
    values.absence_type = '';
    values.absence_notes = '';
    values.sickness_reason = '';
    // values.start_date = '';
    // values.end_date = '';
    values.is_absent = false;
    setDaysOfWeek([
      { day: 'MON', dayValue: 1, selected: false },
      { day: 'TUE', dayValue: 2, selected: false },
      { day: 'WED', dayValue: 3, selected: false },
      { day: 'THU', dayValue: 4, selected: false },
      { day: 'FRI', dayValue: 5, selected: false },
      { day: 'SAT', dayValue: 6, selected: false },
      { day: 'SUN', dayValue: 7, selected: false },
    ]);
    setErrorMessage('');
    setCheckRequires(false);
    setDateError(false);
    setTimeError(false);
    setLoadingSave(false);
    setAssignedEmployees([]);
    setSelectedEmployees([]);
    setIsDateAssigned(false);
    setDisableButton([]);
    setInvalidOvernight(false);
    const lengthError = errors.length;
    errors.splice(0, lengthError);
  };

  const handleAddUnavailability = () => {
    resetValues();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setAddUnavailability(!addUnavailability);
  };

  const handleAddAvailability = () => {
    resetValuesRota();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setAddAvailability(!addAvailability);
  };

  const eventClickHandler = async ({ id, groupId, extendedProps }) => {
    // debugger
    if ((extendedProps?.type == 'regularShift') && (calendarView === 'team')) {
      let shifts;
      let selectedShiftDetail;
      let availabilities;
      let selectedAvailabilityDetail;
      setShiftTypePopup(extendedProps.typeEvent);
      setDetailId(id);
      const getAvailabilitiesData = async () => {
        availabilities = await getAvailabilities(groupId, id);
        values.travel_method = availabilities.travel_method;
        // search the selected availability in the array of details
        // selectedAvailabilityDetail =availabilities.details &&  availabilities.details.find(
        //   detail => detail.id === id
        // );
        selectedAvailabilityDetail = availabilities;
        setAvailabilitiesEmployeeIdName(availabilities.employee_details.first_name);
        setAvailabilitiesEmployeeId(availabilities.employee);
        setAvailabilitiesEmployeeFullName(availabilities.employee_details.full_name);
      };

      const getShiftData = async () => {
        shifts = await getAvailabilities(groupId, id);
        //   search the selected shift in the array of details
        //   if (shifts.details != null) {
        //     selectedShiftDetail = shifts.details.find(detail => detail.id === id);
        //   }
        selectedShiftDetail = shifts;
        setAvailabilitiesEmployeeIdName(shifts.employee_details.first_name);
        setAvailabilitiesEmployeeId(shifts.employee);
        setAvailabilitiesEmployeeFullName(shifts.employee_details.full_name);
      };

      const loadAvailabilitySelectedData = () => {
        values.employee = availabilities.employee
        setEmployeeTempOnPopup(availabilities.employee)
        values.days =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.start_date, 'YYYY-MM-DD').format(
            'dddd'
          );
        setAvailabilityDetailsDays(values.days);
        values.idSelected = id;
        values.shiftAvailabilityId = groupId;
        values.travel_method = availabilities.travel_mode;
        values.is_overnight = availabilities.is_overnight;
        values.availabilitiesDetails = availabilities.details;
        values.travel_time = availabilities.travel_time;
        values.shift_type = availabilities.shift_type;
        let shift_name = contextChoicesMap?.regular_shift_type[availabilities.shift_type]?.name
        setShiftTempOnPopup(shift_name)
        if (availabilities.shift_type) {
          const result = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === shift_name);
          if (result) {
            setEmployeeDisable(true)
          } else {
            setEmployeeDisable(false)
          }
        }
        values.travel_rate = availabilities.shift_bill_rate;
        values.comment = availabilities.comment;
        // values.regular_shift_pay_rate_id = availabilities.shift_availability_pay_rate
        values.regular_shift_pay_rate_id = availabilities.pay_rate_card;
        values.start_day =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.start_date).format('YYYY-MM-DD');
        values.end_day =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.end_date).format('YYYY-MM-DD');
        values.start =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.start_date).format('HH:mm');
        values.end =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.end_date).format('HH:mm');
        values.is_absent =
          selectedAvailabilityDetail && selectedAvailabilityDetail.is_absent;
        values.absence_type =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_type;
        values.absence_reason =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_reason;
        values.sickness_reason =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.sickness_reason;
        values.absence_informed_method =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_informed_method;
        values.absence_planned =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_planned;
        values.absence_paid =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_paid;
        values.absence_notes =
          selectedAvailabilityDetail &&
          selectedAvailabilityDetail.is_absent &&
          selectedAvailabilityDetail.absence_notes;
          values.branch_id = selectedAvailabilityDetail &&
           selectedAvailabilityDetail?.branch &&
            selectedAvailabilityDetail?.branch
        setValues({ ...values });
      };

      const loadShiftSelectedData = () => {
        values.employee = {
          value: selectedShiftDetail.employee.id,
          label: selectedShiftDetail.employee.full_name,
        };
        values.days =
          selectedAvailabilityDetail &&
          moment(selectedAvailabilityDetail.start_date, 'YYYY-MM-DD').format(
            'dddd'
          );
        setAvailabilityDetailsDays(values.days);
        values.idSelected = id;
        values.shiftAvailabilityId = groupId;
        values.travel_method = selectedShiftDetail.travel_mode.id;
        values.is_overnight = selectedShiftDetail.is_overnight;
        values.availabilitiesDetails = selectedShiftDetail.details;
        values.travel_time = selectedShiftDetail.travel_time;
        values.shift_type = selectedShiftDetail.shift_type;
        values.travel_rate = selectedShiftDetail.shift_bill_rate;
        values.comment = selectedShiftDetail.comment;
        // values.regular_shift_pay_rate_id = selectedShiftDetail.shift_availability_pay_rate
        values.regular_shift_pay_rate_id = selectedShiftDetail.pay_rate_card;
        values.start_day =
          selectedShiftDetail &&
          moment(selectedShiftDetail.start_date).format('YYYY-MM-DD');
        values.end_day =
          selectedShiftDetail &&
          moment(selectedShiftDetail.end_date).format('YYYY-MM-DD');
        values.start =
          selectedShiftDetail &&
          moment(selectedShiftDetail.start_date).format('HH:mm');
        values.end =
          selectedShiftDetail &&
          moment(selectedShiftDetail.end_date).format('HH:mm');

        setValues({ ...values });
      };

      switch (extendedProps.typeEvent) {
        case TYPE_SHIFT:
          await getShiftData();
          // handleAssignShiftModal();
          handleAddAvailability();
          loadShiftSelectedData();
          break;
        case TYPE_AVAILABLE:
          await getAvailabilitiesData();
          handleAddAvailability();
          loadAvailabilitySelectedData();
          break;
        case TYPE_UNAVAILABLE:
          await getAvailabilitiesData();
          handleAddUnavailability();
          loadAvailabilitySelectedData();
          break;
        default:
      }
    }
    // else if (calendarView === 'location') {
    //   return;
    // }
    else {
      setclientSelectedId(extendedProps?.clientId && extendedProps?.clientId)
      setScoreDataId(id);
      setSaveButtonIcon(FLOPPY);
      setTextStatus(SAVE);
      setLoadingNew(true)
      if (!((calendarView === 'team') && regularMode)) {
        handleAddVisitModal();
      }
      const res = await getVisitDetail(id);
      setISPublished(res ? res.is_published : null)
      setSchedularVisitId(res.id)
      if ((calendarView === 'team') && regularMode) {
        if (res.employee) {
          let employees = null;
          getShiftDetailById(res.employee)
            .then((resD) => {
              if (resD && resD.message === "Success") {
                if (resD.data) {
                  let len = resD.data.length;
                  for (let i = 0; i < len; i++) {
                    let format = 'YYYY-MM-DD HH:mm:ss';
                    let reg_start_date = res.start_date.split('T')[0] + 'T' + resD.data[i].start_time
                    let reg_end_date = res.end_date.split('T')[0] + 'T' + resD.data[i].end_time
                    let checkStart = (res.start_date === reg_start_date) || moment(res.start_date, format).isBetween(moment(reg_start_date, format), moment(reg_end_date, format));
                    let checkEnd = (res.end_date === reg_end_date) || moment(res.end_date, format).isBetween(moment(reg_start_date, format), moment(reg_end_date, format));
                    if (checkStart && checkEnd) {
                      employees = resD.data[i].id;
                      values.specific = 'employee';
                      values.currentDay = moment(res.start_date).format('dddd');
                      values.shift = resD.data[i].value;
                      values.regular_start_date = res.start_date.split('T')[0]
                      values.every = EVERY_WEEK;
                      values.end_type = END_TYPE_NEVER;
                      values.selectedPinned = null;
                      getPinnedData(values)
                      handleAddRegularModal();
                      setValues({ ...values })
                      break;
                    }
                    if (i == len - 1) {
                      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No shift assigned to this visit' });
                    }
                  }
                }
              } else {
                setModalConfirmRegularVisit(true)
              }
            })
            .catch((error) => { })
        } else {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'First assigned the visit to employee' });
        }
      }
      //  else {
      //   handleAddVisitModal();
      // }
      if (res && res.client_service_visit.employee_required) {

        res.client_service_visit.employee_required.map((emp, empIndex) => {
          if (emp.skills) {
            emp.skills.map((skill, skillIndex) => {
              let name = contextChoicesMap?.skill && contextChoicesMap?.skill[skill.skill]?.name
              res.client_service_visit.employee_required[empIndex].skills[skillIndex] = { value: skill.skill, label: name, skill_id: skill.id }
            })
          }
        })

        values[VISITS_VALUES] = res.client_service_visit;

      } else {
        values[VISITS_VALUES] = res.client_service_visit;
      }
      values.contracted_data = { ...res.contract_start_date, ...res.contract_end_date }
      values[VISITS_VALUES].visit_status = res.visit_status && res.visit_status;
      values[VISITS_VALUES].type = res.client_type;
      values[VISITS_VALUES].notes = res.notes;
      values[VISITS_VALUES].service_category = res.service_category;
      values[VISITS_VALUES].visit_type = res.visit_type;
      values[VISITS_VALUES].visit_priority = res.visit_priority;
      values[VISITS_VALUES].time_critical = res.time_critical;
      values[VISITS_VALUES].manualAssigned = res.manualAssigned;
      values[VISITS_VALUES].manualAssigned = res.manualAssigned;
      values[VISITS_VALUES].visit_start_date = res.start_date && res.start_date.split('T')[0]
      values[VISITS_VALUES].visit_end_date = res.end_date && res.end_date.split('T')[0]
      values[VISITS_VALUES].visit_end_time = res.end_date && res.end_date.split('T')[1]
      values[VISITS_VALUES].visit_start_time = res.start_date && res.start_date.split('T')[1]
      values[VISITS_VALUES].earliest_start_time = res.earliest_start_time
      values[VISITS_VALUES].latest_start_time = res.latest_start_time
      values[VISITS_VALUES].client_service_visit_requirement = res.client_service_visit_requirement
      values[ADDRESS_VALUES] = res.client_service_visit_address;
      values[EMPLOYEE_VALUES] = res.employee;
      values.employeeAuxData = res.employee;
      values.visit_id = res.id;

      values[VISITS_VALUES].evv_data = res.evv_data ? res.evv_data : {}
      if (res.evv_data) {
        values[VISITS_VALUES].evv_data.id = res.evv_data ? res.evv_data.id : ''
        values[VISITS_VALUES].evv_data.duration = (res.evv_data && res.evv_data.duration) ? (res.evv_data.duration * 60) : '';

        values[VISITS_VALUES].evv_data.actual_start_time = res.evv_data && res.evv_data.actual_start_time && res.evv_data.actual_start_time.split('T')[1]

        values[VISITS_VALUES].evv_data.actual_end_time = res.evv_data && res.evv_data.actual_end_time && res.evv_data.actual_end_time.split('T')[1]
        values[VISITS_VALUES].evv_data.schedule_start_time = res.evv_data && res.evv_data.schedule_start_time
        values[VISITS_VALUES].evv_data.travel_mode = res.evv_data && res.evv_data.travel_mode
        values[VISITS_VALUES].evv_data.schedule_end_time = res.evv_data && res.evv_data.schedule_end_time
        values[VISITS_VALUES].evv_data.travel_mode = res.evv_data && res.evv_data.travel_mode

        let visitDataStartTime = res.evv_data && res.evv_data.actual_visit_start_time && res.evv_data.actual_visit_start_time.split('T')
        let visitDataEndTime = res.evv_data && res.evv_data.actual_visit_end_time && res.evv_data.actual_visit_end_time.split('T')

        if (visitDataStartTime && visitDataStartTime.length > 0) {
          values[VISITS_VALUES].evv_data.actual_visit_start_time = visitDataStartTime[1]
          values[VISITS_VALUES].evv_data.actual_start_date = visitDataStartTime[0]
        }

        if (visitDataEndTime && visitDataEndTime.length > 0) {
          values[VISITS_VALUES].evv_data.actual_visit_end_time = visitDataEndTime[1]
          values[VISITS_VALUES].evv_data.actual_end_date = visitDataEndTime[0]
        }
      }
      values[CANCEL_VISIT_VALUES].employee_full_name = res.evv_data && (res.evv_data.employee_obj ? res.evv_data.employee_obj.full_name : '')
      values[CANCEL_VISIT_VALUES].employee_id = res.evv_data ?
        (res.evv_data.employee_obj ? res.evv_data.employee_obj.id : null) : null;
      values[VISITS_VALUES].visit_start_date = res.start_date && res.start_date.split('T')[0]
      values[VISITS_VALUES].visit_end_date = res.end_date && res.end_date.split('T')[0]
      values[VISITS_VALUES].visit_start_time = res.start_date && res.start_date.split('T')[1]
      values[VISITS_VALUES].visit_end_time = res.end_date && res.end_date.split('T')[1]
      values[VISITS_VALUES].is_published = res.is_published
      values[VISITS_VALUES].score = res.score
      values[VISITS_VALUES].visit_number = res.visit_number
      values[VISITS_VALUES].start_date = res.start_date
      values[VISITS_VALUES].competency = res?.client_service_visit_requirement?.competency.map(data => {
        return { competency_id: data.id, value: data.competency, label: contextChoicesMap?.competency_name[data?.competency]?.name }
      });
      values[VISITS_VALUES].specific_groups = res?.client_service_visit_requirement?.client_task?.map(obj => {
        return { value: obj.specific_group, label: obj.specific_group_name, client_task: obj.id }
      })
      // end_date added
      values[VISITS_VALUES].end_date = res.end_date
      values.is_visit_cancelled = res.is_visit_cancelled
      values.visit_cancellation_reason = res.visit_cancellation_reason
      values[VISITS_VALUES].req_replica = res.req_replica && res.req_replica.req_replica && res.req_replica.req_replica
      values[VISITS_VALUES].service_destination = res.req_replica && res.req_replica.req_replica && res.req_replica.req_replica.service_destination
      values[VISITS_VALUES].skill_replica = res.req_replica && res.req_replica.skill_replica && res.req_replica.skill_replica.map(y => {
        return {
          skill_id: y && y.id,
          value: y && y.skill,
          label: contextChoicesMap?.skill && contextChoicesMap.skill[y.skill]?.name
        }
      })

      values[VISITS_VALUES].funder = res.req_replica && res.req_replica.req_replica && res.req_replica.req_replica.funder;
      values[VISITS_VALUES].category = res.req_replica && res.req_replica.req_replica && res.req_replica.req_replica.category;
      values[VISITS_VALUES].self_funded = res.req_replica.req_replica.self_funded ? 1 : 2;

      if (res.is_visit_cancelled) {
        values[CANCEL_VISIT_VALUES].cancellation_reason = res.visit_cancellation_reason ? res.visit_cancellation_reason : null
        values[CANCEL_VISIT_VALUES].cancel_date = res.visit_cancelled_datetime ? res.visit_cancelled_datetime.split('T')[0] : null
        values[CANCEL_VISIT_VALUES].cancel_note = res.visit_cancellation_notes ? res.visit_cancellation_notes : ""
        values[CANCEL_VISIT_VALUES].notification_cancellation = res.visit_cancellation_notification_method ? res.visit_cancellation_notification_method : null
        values[CANCEL_VISIT_VALUES].time_of_cancel = res.visit_cancelled_datetime ? res.visit_cancelled_datetime.split('T')[1] : null
        values[CANCEL_VISIT_VALUES].staff_to_be_paid = res.staff_to_be_billed ? res.staff_to_be_billed : null
        values[CANCEL_VISIT_VALUES].client_to_be_billed = res.client_to_be_billed ? res.client_to_be_billed : null
      }

      setValues({ ...values });



      let clientdata_detail = {}
      if (res?.client_service_visit?.client_data) {
        clientdata_detail = {
          full_name: res?.client_service_visit?.client_data?.full_name,
          date_of_birth: res?.client_service_visit?.client_data?.date_of_birth,
          photo: res?.client_service_visit?.client_data?.photo,
        }
      } else if (res?.client_service_visit?.location_data) {
        clientdata_detail = {
          location: true,
          full_name: res?.client_service_visit?.location_data?.full_name,
          branch_name: res?.client_service_visit?.location_data?.branch_name
        }
      }

      setClientData(clientdata_detail);
      setLoadingModal(false);
      setLoadingNew(false)



    }

  };
  const dropDownHandleRegVisit = (item, nameItem) => {

    values[nameItem] = item[nameItem]
    setValues({ ...values });

  };

  const getOptaplannerStatus = () => {
    if (!mountedRef.current) {
      clearInterval(caller);
    }

    getSchedulerOptaplanner(values.start_date, values.end_date)
      .then(response => {
        setAllowSchedulerCalls(true)
        if (!mountedRef.current) return null;
        if (response && response.length >= 1 && !response.error) {
          if (response[0].state === 'TERMINATED') {
            if (optaStatus === 'SOLVING' || optaStatus === 'STARTING') {
              searchScheduler()
              // setTimeout(() => {
              //   updateEmployeeResource(empData)
              // }, 2000);
              //  getVisitData(calendarView)
              optaStatus = ''
            } else {
              //  getVisitData(calendarView)
              clearInterval(addCaller);
            }
            setLoadingOptaPlanner(false)
            clearInterval(addCaller);
          } else {
            optaStatus = response[0].state
            intervalNeeded = true
            setLoadingOptaPlanner(true)
            //setLoading(false)
          }
          setOptaplannerStatus(response);

          setTimeStatus(response[0].startAt);
        } else {
          setOptaplannerStatus([{ state: '', tries: 0 }]);
          clearInterval(addCaller);
          // getVisitData(calendarView)
        }
      })
      .catch(error => { })
      .finally(() => {
      });
  };

  const deletePinnedEmp = (id) => {
    if (!values.selectedPinned) {
      setPinnedDayId(id)
      values.selectedPinned = [id];
      setValues({ ...values });
    } else if (values.selectedPinned.length < 1) {
      setPinnedDayId(id)
      values.selectedPinned = [id];
      setValues({ ...values });
    }
    setModalPinnedEmp(true)
  }

  const setDeletePin = () => {
    setUnpinnedEmp(values.selectedPinned)
      .then((res) => {
        setModalPinnedEmp(false);
        setAddRegularVisit(false);
        searchScheduler();
      })
  }
  const setDetachRuns = () => {
    setDetach(rightClickSelectedId.length ? [...rightClickSelectedId.length] : checkedRows)
      .then((res) => {
        setModalDetachRun(false);
        searchScheduler();
        setRightClickSeletcedId([])
        setSelectedEvents([])
        clearSelectedElId();
      })
  }
  const setHandback = () => {
    let tempArray = []
    checkedRows.forEach((item) => {
      if (item !== null && item !== undefined && item !== "") {
        tempArray.push(item)
      }
    })
    createdHandback(rightClickSelectedId.length ? [...rightClickSelectedId] : tempArray).then((res) => {
      setModalHandback(false)
      searchScheduler();
      setRightClickSeletcedId([])
      setSelectedEvents([])
      clearSelectedElId();
    })
  }
  const turnOffScheduler = (start_date, end_date) => {
    setLockButton(true);
    deleteSchedulerOptaplanner(start_date, end_date)
      .then(() => {
        if (!mountedRef.current) return null;

        getOptaplannerStatus();
      })
      .catch(error => { })
      .finally(() => {
        setLockButton(false);
      });
  };

  const runSchedulerOptaPlanner = (regularVisit) => {

    if (loadingOptaPlanner) {
      setLoadingOptaPlanner(false);
      turnOffScheduler(values.start_date, values.end_date);
      return;
    }



    setLoadingOptaPlanner(true);
    setLockButton(true);
    let branch_filter = values.branch_run;

    runScheduler(values.start_date, values.end_date, branch_filter, values, checkedRunRegular, checkedRunRadio, considerTimeRes, checkedFreezeVisits, planOnlyToggle, considerEmpRota, overrideWindow, overrideWindowMinutes)
      .then(() => {
        getOptaplannerStatus();
        setCallerInterval()
        setRunningDate(values.start_date);
        setTimeout(() => {
          updateEmployeeResource(empData)
        }, 2000);
      })
      .catch(err => {
        setLoadingOptaPlanner(false);
        searchScheduler()
        setTimeout(() => {
          updateEmployeeResource(empData)
        }, 2000);
      })
      .finally(() => {
        setLockButton(false);
        setTimeout(() => {
          updateEmployeeResource(empData)
        }, 2000);
      });
  };

  const okInformationMessage = () => {
    setOptaPlannerMessage(false);
  };

  const showDeleteAllVisits = () => {

    setShowDeleteVisitsModal(true);

  };

  const showPlannerVisitsFn = () => {
    setPlannerSchVisits(true);

  };

  const showPlannerAssign = () => {
    setPlannerSchAssign(true);
  }

  const publishVisits = () => {
    setDisablePublishButton(true)
    getPublishVisit(values)
      .then(res => {
        if (res.statusCode == 200) {
          toast.current.show({
            severity: 'success',
            summary: '',
            detail: res.message
          });
          searchScheduler();
          setDisablePublishButton(false)
        } else {
          toast.current.show({
            severity: 'error',
            summary: '', detail:
              res ? res?.message : "Something went wrong!"
          });
        }

      }).catch(err => {
        toast.current.show({
          severity: 'error',
          summary: '', detail:
            err ? err?.message : "Something went wrong!"
        });

      })
  }

  const UnPublishVisits = () => {
    setDisableUnPublishButton(true)
    //BE-python needs value in "False"format  in api query params 
    getPublishVisit(values, "False")
      .then(res => {
        if (res.statusCode == 200) {
          toast.current.show({
            severity: 'success',
            summary: '',
            detail: res.message
          });
          searchScheduler();
          setDisableUnPublishButton(false)
        } else {
          toast.current.show({
            severity: 'error',
            summary: '', detail:
              res ? res?.message : "Something went wrong!"
          });
        }

      }).catch(err => {
        toast.current.show({
          severity: 'error',
          summary: '', detail:
            err ? err?.message : "Something went wrong!"
        });

      })
  }

  const confirmCancelAllVisits = async () => {

    let branch_id = ''
    if (values.branch_for_delete && values.branch_for_delete.length > 0) {
      setLodingPas(true)
      let temp = values.branch_for_delete.map((item) => (item.value))
      branch_id = temp.join(',')
    }
    setDisableDeletePopupConfirm(true)
    try {
      await deleteAllVisits(values.start_date, values.end_date, branch_id, checkedDelete);
      setLodingPas(false)
      setShowDeleteVisitsModal(false);
      setDisableDeletePopupConfirm(false)
      setModalDeleteVisits(false)
      searchScheduler();
      getOptaplannerStatus();
    } catch (error) {
      setDisableDeletePopupConfirm(false)
      setOptaPlannerMessage(true);
    }
  };

  const hitApiAfterClear = (value) => {
    setClearAll(value)
  }

  const actualEditFn = () => {
    let checkNull = true;
    checkedRowsEmp.map((val) => {
      if (!val) {
        checkNull = false;
      }
    })

    if (checkNull) {
      setActualEditsVisits(!actualEditsVisits)
    } else {
      setModalWarningValue(true)
    }

  }

  const revertClockedFn = () => {
    let checkNull = true;
    checkedRowsEmp.map((val) => {
      if (!val) {
        checkNull = false;
      }
    })

    if (checkNull) {
      setModalRevertClockedValue(!revertClocked)
    } else {
      setModalWarningValue(true)
    }

  }

  const addOfferVisits = () => {
    let body = {
      visits: rightClickSelectedId.length ? [...rightClickSelectedId] : (checkedRows && checkedRows.length >= 1) ? [...checkedRows] : [values.visit_id]
    }
    saveAddOffer(body)
      .then(res => {
        if (res.status == 'SUCCESS') {
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          searchScheduler();
          clearSelectedElId();
          setRightClickSeletcedId([])
          setSelectedEvents([])
          //props.handleChangeChoices()
        } else {
          toast.current.show({ severity: 'error', summary: '', detail: res.message });
        }

      })
      .catch(error => {
      });
  }

  // const handleSaveVisitRun = () => {

  //   let allGood = false
  //   if (values.specific === 'employee') {
  //     if (!values.start_date || !values.every || !values.end_type) {
  //       setCheckRequires(true)
  //       setErrorMessage(InvalidOrEmptyValues);
  //       return
  //     }
  //     if (values.end_type === 'ON' || values.end_type === 'AFTER') {
  //       if (!values.end_value) {
  //         setCheckRequires(true)
  //         setErrorMessage(InvalidOrEmptyValues);
  //         return
  //       }
  //     }
  //   }
  //   if (allGood) {
  //     return
  //   }
  //   setCheckRequires(false)
  //   setErrorMessage('');
  //   setLoadingSave(true);
  //   const getEmployeeWeekDay = (day) => {
  //     if (day === 'Monday')
  //       return 'MON'
  //     if (day === 'Tuesday')
  //       return 'TUE'
  //     if (day === 'Wednesday')
  //       return 'WED'
  //     if (day === 'Thursday')
  //       return 'THU'
  //     if (day === 'Friday')
  //       return 'FRI'
  //     if (day === 'Saturday')
  //       return 'SAT'
  //     if (day === 'Sunday')
  //       return 'SUN'
  //   }
  //   let emp_day_det = [{
  //     client: values.visitValues.client,
  //     repeat_on: values.currentDay ? getEmployeeWeekDay(values.currentDay) : '',
  //     requirement: values.visitValues.client_service_visit_requirement ? values.visitValues.client_service_visit_requirement.id : null,
  //     client_service_visit: values.visitValues.id,
  //     start_time: values.visitValues.evv_data.schedule_start_time,
  //     end_time: values.visitValues.evv_data.schedule_end_time,
  //   }
  //   ]

  //   let emp_day_data = emp_day_det.map((item) => {
  //     if (item) {
  //       return item
  //     }
  //   })
  //   if (newRun) {
  //     setRunName({ id: '', name: values.run_name })
  //       .then((res) => {
  //         props.handleChangeChoices()
  //         let body = {
  //           employee: values.visitValues?.evv_data?.employee_obj?.id,
  //           start_date: values.regular_start_date,
  //           end_date: values.regular_end_date,
  //           shift: values.shift,
  //           every: values.every,
  //           repeat: values.repeat,
  //           end_type: values.end_type,
  //           end_value: values.end_value,
  //           specific: "employee",
  //           run_name_view: res.id ? res.id : null,
  //           employee_day_detail: [...emp_day_data],
  //           is_deleted: false
  //         }
  //         saveRegularVisit(body)
  //           .then(res => {
  //             if (res.id) {
  //               setSaveButtonIcon(CHECK);
  //               setTextStatus(SAVED);
  //               setLoadingSave(false);
  //               setAddRegularVisit(false);
  //               searchScheduler();
  //             } else {
  //               setSaveButtonIcon(CROSS);
  //               setTextStatus(ERROR);
  //               setErrorMessage("Error in saving Visit")
  //               setLoadingSave(false);
  //             }

  //           })
  //           .catch(error => {
  //           });
  //       })
  //   } else {
  //     let body = {
  //       employee: values.visitValues?.evv_data?.employee_obj?.id,
  //       start_date: values.regular_start_date,
  //       end_date: values.regular_end_date,
  //       shift: values.shift,
  //       every: values.every,
  //       repeat: values.repeat,
  //       end_type: values.end_type,
  //       end_value: values.end_value,
  //       specific: "employee",
  //       run_name_view: values.run_name_view ? values.run_name_view : null,
  //       employee_day_detail: [...emp_day_data],
  //       is_deleted: false
  //     }
  //     saveRegularVisit(body)
  //       .then(res => {
  //         if (res.id) {
  //           setSaveButtonIcon(CHECK);
  //           setTextStatus(SAVED);
  //           setLoadingSave(false);
  //           setAddRegularVisit(false);
  //           searchScheduler();
  //         } else {
  //           setSaveButtonIcon(CROSS);
  //           setTextStatus(ERROR);
  //           setErrorMessage("Error in saving Visit")
  //           setLoadingSave(false);
  //         }

  //       })
  //       .catch(error => {
  //       });
  //   }


  // }

  const handleSaveBulkRun = () => {
    let allGood = false
    if (values.specific === 'employee') {
      if (!values.start_date || !values.every || !values.end_type) {
        setCheckRequires(true)
        setErrorMessage(InvalidOrEmptyValues);
        return
      }
      if (values.end_type === 'ON' || values.end_type === 'AFTER') {
        if (!values.end_value) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
      }
    }
    if (allGood) {
      return
    }
    setCheckRequires(false)
    setErrorMessage('');
    setLoadingSave(true);
    if (newRun) {
      setRunName({ name: values.run_name })
        .then((res) => {
          let body = {
            employee: values.add_emp ? values.employee : null,
            start_date: values.regular_start_date,
            end_date: values.regular_end_date,
            is_employee: values.add_emp ? false : true,
            repeat_on: values.repeat_on && values.repeat_on.join(','),
            // shift: values.shift,
            every: values.every,
            repeat: values.repeat,
            end_type: values.end_type,
            end_value: values.end_value,
            // specific: "employee",
            run_name_view: res?.data?.id ? res?.data?.id : null,
            // employee_day_detail: [...emp_day_data],
            is_deleted: false,
            visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : (checkedRows && checkedRows.length >= 1) ? [...checkedRows] : [values.visit_id]
          }
          console.log(body, "bodybody")
          saveBulkRun(body)
            .then(res => {
              if (res.status === 'Success') {
                toast.current.show({ severity: 'success', summary: '', detail: res.message });
                setSaveButtonIcon(CHECK);
                setTextStatus(SAVED);
                setLoadingSave(false);
                setAddRegularVisit(false);
                searchScheduler();
                setRightClickSeletcedId([])
                setSelectedEvents([])
                clearSelectedElId();
                //props.handleChangeChoices()
              } else {
                setSaveButtonIcon(CROSS);
                setTextStatus(ERROR);
                setErrorMessage("Error in saving Visit")
                setLoadingSave(false);
              }

            })
            .catch(error => {
            });
        })
    } else {
      let body = {
        employee: values.add_emp ? values.employee : null,
        start_date: values.regular_start_date,
        end_date: values.regular_end_date,
        // shift: values.shift,
        is_employee: values.add_emp ? false : true,
        repeat_on: values.repeat_on && values.repeat_on.join(','),
        every: values.every,
        repeat: values.repeat,
        end_type: values.end_type,
        end_value: values.end_value,
        specific: "run",
        run_name_view: values.run_name_view ? values.run_name_view : null,
        // employee_day_detail: [...emp_day_data],
        is_deleted: false,
        visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : (checkedRows && checkedRows.length >= 1) ? [...checkedRows] : [values.visit_id]
      }
      saveBulkRun(body)
        .then(res => {
          if (res.status === 'Success') {
            toast.current.show({ severity: 'success', summary: '', detail: res.message });
            setSaveButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            setAddRegularVisit(false);
            searchScheduler();
            setRightClickSeletcedId([])
            setSelectedEvents([])
            clearSelectedElId();
          } else {
            setSaveButtonIcon(CROSS);
            setTextStatus(ERROR);
            setErrorMessage("Error in saving Visit")
            setLoadingSave(false);
          }

        })
        .catch(error => {
        });
    }


  }

  const PublishListData = (status) => {
    let checkNull = true;
    checkedRowsEmp.map((val) => {
      if (!val) {
        checkNull = false;
      }
    })

    if (checkNull) {
      let body = {
        visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows],
        status: status
      }
      setRevertPublish(body)
        .then((res) => {
          if (res.status === 'True') {
            toast.current.show({ severity: 'success', summary: '', detail: res.message });
            setRightClickSeletcedId([])
            setSelectedEvents([])
            clearSelectedElId();
          }
        })
        .catch(() => { })
    } else {
      setModalWarningValue(true)
    }

  }

  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: '#6E6BFA',
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#6E6BFA"
  //   },
  // }));

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA', // Change this to the desired arrow color
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6E6BFA", // Change this to the desired background color
      color: '#ffffff', // Text color inside the tooltip
      fontSize: '0.875rem', // Font size of the tooltip text
      padding: '10px 15px', // Padding inside the tooltip
      borderRadius: '8px', // Rounded corners
      boxShadow: theme.shadows[3], // Add shadow for depth
      maxWidth: '500px', // Maximum width to prevent overflow
      [`@media (max-width: 600px)`]: {
        fontSize: '0.75rem', // Smaller font size for small screens
        maxWidth: '90vw', // Adapt width for small screens
      },
    },
  }));


  const calculateEndValueProps = value => {
    switch (value) {
      case END_TYPE_ON:
        return 'isRequired';
      case END_TYPE_AFTER:
        return 'isInt, isRequired';
      default:
        return '';
    }
  };

  const switchEndValueInput = props => {
    // render correct input type depending of the end_type selected
    switch (values.end_type) {
      case END_TYPE_ON:
        // check if current value has date format
        const validDate = props.value
          ? moment(props.value, 'YYYY-MM-DD', true).isValid()
          : true;
        // if current value has date format then send props
        const dateProps = validDate ? props : {};
        return (
          <DateSelector
            isRequired
            labelTop={'End value'}
            {...dateProps}
          />
        );
      case END_TYPE_AFTER:
        return (
          <InputLabelTop
            required
            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
            label={'End value'}
            type="text"
            id={`end_value`}
            {...props}
          />
        );
      case END_TYPE_NEVER:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };



  useEffect(() => {
    console.log(new Date(), "100000");

    if (!addVisit) {
      history.push({
        pathname: "/scheduler",
        search: query_params_data.start ? props.location.search : ''
      });
    }
    console.log(new Date(), "100000");

  }, [addVisit])

  const handleRunRadio = (val, type) => {
    if (type == 'consider_visit') {
      setcheckedRunRadio(val.playOnDropdownValue);
    } else {
      setcheckedFreezeVisits(val.playOnDropdownValue || [])
    }


  }

  const handleRunDelete = (val) => {

    setcheckedDelete(val);


  }

  function onClickRunSchdular() {
    setOverrideWindow(false)
    setOverrideWindowMinutes(0)
    setconsiderTimeRes(true)
    setcheckedRunRegular(false)
    setcheckedFreezeVisits([])
    setPlanOnlyToggle(true)
    setModalRunSchedule(true)
    values.branch_run = '';
    values.branch_for_dropdown = ''
    setValues({ ...values })
  }

  const handleChangeFilterCheckbox = (e, dropdown_name) => {
    setFilterExcludeCheck({ ...filterExcludeCheck, [dropdown_name]: e.target.checked })
  }

  const getListOfLocations = async () => {
    try {
      const res = await getLocationList()
      if (res?.status) {
        setListOfLocations(res?.message || [])
        const data = res?.message?.map(item => ({ ...item, name: item.full_name }))
        setLocationDropdownItem(data || [])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCount(prev => prev + 1)
    }
  }

  const serviceCategorytypeOptions = [
    { id: "client", name: "Client" },
    { id: "location", name: "Location" },
  ]

  const { selectedElId, displayMenu, clearSelectedElId, encloseModalBind } = useContextMenu(decreaseWidth)

  const getMenuItems = () => {
    if (!selectedElId) return []
    let { event } = selectedElId;
    let publicId = isMultiEventSelect && selectedEvents.length ? selectedEvents : [event?._def?.publicId];
    return ([
      {
        name: 'Reassign Visits',
        onClick: function () {
          setAssignVisits(!assignVisits);
          setActionType('Reassign');
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Unassign Visits',
        onClick: function () {
          setUndoCancel(false);
          setModalUnassignValue(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Duplicate Visits',
        onClick: function () {
          setShadowVisit(false);
          setModalDuplicateValue(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Shadow Visits',
        onClick: function () {
          setAssignVisits(!assignVisits);
          setActionType('shadow');
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Observations Visits',
        onClick: function () {
          setAssignVisits(!assignVisits);
          setActionType('obvservation');
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Adjust Visits',
        onClick: function () {
          setAdjustVisits(!adjustVisits);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Set Visits Time',
        onClick: function () {
          setBulkSetVisits(!bulkSetVisits);
          setRightClickSeletcedId(publicId);
        },
      },

      {
        name: 'Edit Actuals',
        onClick: function () {
          actualEditFn();
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Cancel Visits',
        onClick: function () {
          handleCancelVisit('multi');
          setRightClickSeletcedId(publicId);
        },
      },

      {
        name: 'Undo Cancel',
        onClick: function () {
          setUndoCancel(true);
          setModalUnassignValue(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Delete Visits',
        onClick: function () {
          handleDeleteVisit('multi');
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Revert Visits',
        onClick: function () {
          setModalRevertValue(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Revert Clocked',
        onClick: function () {
          revertClockedFn(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Publish',
        onClick: function () {
          PublishListData(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'UnPublish',
        onClick: function () {
          PublishListData(false);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Run Name',
        onClick: function () {
          handleAddRunNameModal();
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Rate Card',
        onClick: function () {
          setAssignRate(!assignRate);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Funder Name',
        onClick: function () {
          setFunderName(!funderName);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Add Offer',
        onClick: function () {
          addOfferVisits();
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Detach All Run',
        onClick: function () {
          setModalDetachRun(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Created Handback',
        onClick: function () {
          setModalHandback(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Update Travel',
        onClick: function () {
          setModalUpdateTravel(true);
          setRightClickSeletcedId(publicId);
        },
      },
      {
        name: 'Copy Visit',
        premission: copyVisitPermission,
        onClick: function () {
          setModalCopyVisit(true);
          setRightClickSeletcedId(publicId);
        }, 
      },
    ])
  };
  //handleCopyVisits

  const rightClickHandler = (arge) => {
    arge.el.addEventListener("contextmenu", (e) => displayMenu(e, arge))
  }
  const removeRightClickHandler = (arge) => {
    arge.el.removeEventListener("contextmenu", (e) => displayMenu(e, arge))
  }


  // const handleSelect = (selectInfo) => {
  //   const { startStr, endStr, resource } = selectInfo;
  //   console.log(selectInfo, "selectInfo")
  //   const selected = events.filter(event =>
  //     event.resourceId === resource.id &&
  //     dayjs(event.start).isBetween(dayjs(startStr), dayjs(endStr), null, '[)') &&
  //     dayjs(event.end).isBetween(dayjs(startStr), dayjs(endStr), null, '(]')
  //   );

  //   setSelectedEvents(selected.map(event => event.id));
  // };

  const handleEventClick = (clickInfo) => {
    const eventId = clickInfo.id;
    setSelectedEvents((prevSelectedEvents) => {
      if (prevSelectedEvents.includes(eventId)) {
        return prevSelectedEvents.filter(id => id !== eventId);
      } else {
        return [...prevSelectedEvents, eventId];
      }
    });
  };

  const handleMultiSelectEventToggle = () => {
    setIsMultiEventSelect(!isMultiEventSelect)
    setSelectedEvents([])
  }


  console.log(selectedEvents, rightClickSelectedId, 'selectInfo selectedEvents')
  console.log(payTypeNameFilter,values.pay_type, "payTypeNameFilter")
  return (
    <>
      <ContextMenu menuItems={getMenuItems()} />
      {
        loading &&
        <Spinner
          spinnerTop={"70%"}
          textTop={'68%'}
        />
      }
      <Toast ref={toast} position="top-right" />
      {/* {
        apiError.status ? 
        <APIErrorContainer>{SERVER_ERROR + " "} <span onClick={() => window.location.reload()}><MdOutlineRefresh style={{height:'1.4rem', width:"1.4rem"}} color={PURPLE} /></span></APIErrorContainer>
        : */}
      <SchedulerContainer loading={loading} height={decreaseWidth ? '100%' : "auto"}>


        {decreaseWidth &&
          <>
            <Title>Scheduler</Title>
            <div className={'mt-2'}>
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center justify-content-md-start px-0" style={{ flexWrap: 'wrap' }}>
                <FiltersContainerRotaShifts>
                  <FiltersLeftGroup
                    className={
                      'd-flex justify-content-center justify-content-md-start mb-2'
                    }
                    style={{ flexWrap: 'wrap' }}>

                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={clients ? clients : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ client: items });
                        }}
                        itemsSelected={clientsFilter}
                        setSelectedFilter={setClientfilter}
                        placeHolder={'Clients'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                        exclude={{
                          name: 'client',
                          checked: filterExcludeCheck.client || false,
                          handleChangeFilterCheckbox: handleChangeFilterCheckbox
                        }}
                      // filterExcludeCheck={filterExcludeCheck.client || false}
                      // handleChangeFilterCheckbox={handleChangeFilterCheckbox}
                      />
                    </FiltersTypesContainerRotaShifts>



                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={locationDropdownItem ? locationDropdownItem : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ location: items });
                        }}
                        itemsSelected={locationsFilter}
                        setSelectedFilter={setLocationsFilter}
                        placeHolder={'Location'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                        exclude={{
                          name: 'location',
                          checked: filterExcludeCheck.location || false,
                          handleChangeFilterCheckbox: handleChangeFilterCheckbox
                        }}
                      // filterExcludeCheck={filterExcludeCheck.client || false}
                      // handleChangeFilterCheckbox={handleChangeFilterCheckbox}
                      />
                    </FiltersTypesContainerRotaShifts>



                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <NoSelectAllDropdownFilter
                        items={funderList ? funderList : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ funder_name: items });
                        }}
                        itemsSelected={funderNameFilter}
                        setSelectedFilter={(items) => setFunderNameFilter([...items])}
                        placeHolder={'Funder Name'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>



                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <NoSelectAllDropdownFilter
                        items={(contextChoices.funder_source && contextChoices.funder_source.length > 0) ? [{ id: 'none', name: 'None' }, ...contextChoices.funder_source] : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ funder_category: items });
                        }}
                        itemsSelected={funderCategoryFilter}
                        setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                        placeHolder={'Funder Category'}
                        isObject
                        allOption

                      />
                    </FiltersTypesContainerRotaShifts>



                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={runRouteData ? runRouteData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ runroutesclient: items });
                        }}
                        itemsSelected={runRoutesclientFilter}
                        setSelectedFilter={setRunrouteclientfilter}
                        placeHolder={'Client Group Area'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                      />
                    </FiltersTypesContainerRotaShifts>


                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={contextChoices.service_category || []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ service_category: items });
                        }}
                        itemsSelected={serviceFilter}
                        setSelectedFilter={handleService}
                        placeHolder={'Service Category'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>



                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={serviceCategorytypeOptions || []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ service_category_type: items });
                        }}
                        itemsSelected={serviceCategoryTypeFilter}
                        setSelectedFilter={handleServiceCategoryType}
                        placeHolder={'Service Category Type'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>




                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={employeeForFilter ? employeeForFilter : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ employees: items });
                        }}
                        itemsSelected={employeesFilter}
                        setSelectedFilter={setEmloyeefilter}
                        placeHolder={'Employees'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                        exclude={{
                          name: 'employee',
                          checked: filterExcludeCheck.employee || false,
                          handleChangeFilterCheckbox: handleChangeFilterCheckbox
                        }}
                      />
                    </FiltersTypesContainerRotaShifts>

                    <FiltersTypesContainerRotaShifts className="mr-2">

                      <CustomDropdown
                        items={runRouteData ? runRouteData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ runroutesteam: items });
                        }}
                        itemsSelected={runRoutesFilter}
                        setSelectedFilter={setRunroutefilter}
                        placeHolder={'Team Group Area'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}

                      />
                    </FiltersTypesContainerRotaShifts>


                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={jobDepartment ? jobDepartment : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ jobDepartment: items });
                        }}
                        itemsSelected={jobDepartmentFilter}
                        setSelectedFilter={setJobdeptfilter}
                        placeHolder={' Job Department '}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                      />
                    </FiltersTypesContainerRotaShifts>

                    {(calendarView === 'client' || listView) &&
                      <FiltersLeftGroupRotaShifts>
                        <FiltersTypesContainerRotaShifts  >
                          <Dropdown
                            items={[
                              { id: 'All', name: 'All' },
                              { id: 'Assigned', name: 'Assigned' },
                              { id: 'Unassigned', name: 'Unassigned' },
                              { id: 'Cancelled', name: 'Cancelled' },
                              { id: 'Regularvisit', name: 'Regular Visit' },
                            ]}
                            onSelect={item => {
                              handleVisitFilter({ visits: item });
                            }}
                            placeHolder={'Scheduled Status'}
                            isObject
                            allOption
                          />
                        </FiltersTypesContainerRotaShifts>
                      </FiltersLeftGroupRotaShifts>
                    }
                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={branch ? branch : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ branch: items });
                        }}
                        itemsSelected={branchFilter}
                        setSelectedFilter={setFilterBranch}
                        placeHolder={'Branch'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                      />
                    </FiltersTypesContainerRotaShifts>

                    {calendarView === 'team' &&
                      <FiltersTypesContainerRotaShifts className="mr-2">
                        <MultiSelectDropdown
                          items={timeCodes ? timeCodes : []}
                          setItemsSelected={items => {
                            handleTimeCodeFilter(items)
                          }}
                          // itemsSelected={timeCodeFilter}
                          placeHolder={'Time code'}
                          isObject
                          allOption
                        />
                      </FiltersTypesContainerRotaShifts>
                    }


                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={visitStatusData ? visitStatusData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ visitStatus: items });
                        }}
                        itemsSelected={visitStatusFilter}
                        setSelectedFilter={setVisitStatusfilter}
                        placeHolder={'Visit Status'}
                        isObject
                        allOption
                        hitApiAfterClear={hitApiAfterClear}
                      />
                    </FiltersTypesContainerRotaShifts>

                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={runNameData ? runNameData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ run_view_name: items });
                        }}
                        itemsSelected={runNameFilter}
                        setSelectedFilter={handleRunNameFilter}
                        placeHolder={'Run Name'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>




                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <DropdownSearchable
                        typeFilter={true}
                        placeHolder={'Visit Publish'}
                        options={
                          [
                            { id: 'publish', name: 'Published Visits' },
                            { id: 'unpublish', name: 'UnPublished Visits' }
                          ]
                        }
                        selectedOption={values.visit_publish}
                        onChange={value =>
                          dropDownHandleSelect({
                            visit_publish: value ? value : null,
                          })
                        }
                      />
                    </FiltersTypesContainerRotaShifts>

                    <FiltersTypesContainerRotaShifts className="ml-1 mr-1">
                      <NoSelectAllDropdownFilter
                        // className='ml-3'
                        // maxHeight='1rem'
                        items={contextChoices && contextChoices.pay_type ? contextChoices.pay_type : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ pay_type: items });
                        }}
                        itemsSelected={payTypeNameFilter}
                        setSelectedFilter={(items) => setPayTypeNameFilter([...items])}
                        placeHolder={'Shift Category'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>
                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <DropdownSearchable
                        typeFilter={true}
                        placeHolder={'Double Up'}
                        options={[
                          { id: "On", name: "Double Up" },
                          { id: "Off", name: "Non-Double Up" },
                          { id: "Both", name: "Both" },
                        ]}
                        onChange={value =>
                          dropDownHandleSelect({
                            doubleUp: value ? value : null,
                          })
                        }
                        selectedOption={values.doubleUp}
                      />
                    </FiltersTypesContainerRotaShifts>
                    <PrimaryButtonForm minWidth="6rem" className={'mr-1'} onClick={() => {
                      setVisitScreenData({})
                      searchScheduler()
                      // getRunRouteEmployee()
                      // setTimeout(() => {
                      //   updateEmployeeResource(empData)
                      // }, 2000);
                      setPlannedFilter(values || {})
                    }}
                    >
                      <span className={'mr-1'}>Search</span>
                    </PrimaryButtonForm>
                  </FiltersLeftGroup>
                </FiltersContainerRotaShifts>

              </div>
              <div className='d-xl-flex justify-content-md-between align-items-center'>
                {/* <div className='col-12 col-md-3 col-lg-4 col-xl-6 p-0'> */}
                <ToggleDiv className='mb-0 justify-content-center justify-content-md-start'>

                  <ToggleWrapper>
                    <Toggle
                      id="checkbox4"
                      type="checkbox"
                      checked={compactViewToggle}
                      onClick={() => {
                        setCompactViewToggle(!compactViewToggle)
                      }}
                    />
                    <ToggleLabel htmlFor="checkbox4" />
                  </ToggleWrapper>
                  <div className="toggle-label ml-2 mr-2">
                    Compact View
                  </div>
                  <ToggleWrapper>
                    <Toggle
                      id="checkbox"
                      type="checkbox"
                      checked={editMode}
                      onClick={(e) => {
                        editMode ? setEditMode(false) : setEditMode(true);
                      }}
                    />
                    <ToggleLabel htmlFor="checkbox" />
                  </ToggleWrapper>
                  <div className="toggle-label ml-2 mr-2">
                    Edit Mode
                  </div>
                  {(calendarView === 'team') &&
                    <>
                      <ToggleWrapper>
                        <Toggle
                          id="checkbox2"
                          type="checkbox"
                          onClick={() => {
                            regularMode ? setRegularMode(false) : setRegularMode(true);
                          }}
                        />
                        <ToggleLabel htmlFor="checkbox2" />
                      </ToggleWrapper>
                      <div className="toggle-label ml-2 mr-2">
                        Regular Visit
                      </div>
                    </>
                  }
                  <ToggleWrapper>
                    <Toggle
                      id="checkbox3"
                      type="checkbox"
                      onClick={() => {
                        isSplitMode ? setIsSplitMode(false) : setIsSplitMode(true);
                      }}
                    />
                    <ToggleLabel htmlFor="checkbox3" />
                  </ToggleWrapper>
                  <div className="toggle-label ml-2 mr-2">
                    Split Calendar
                  </div>
                  {/* <ToggleWrapper>
                    <Toggle
                      id="checkbox4"
                      type="checkbox"
                      checked={empAvailableToggle}
                      onClick={() => {
                        if (empAvailableToggle) {
                          setEmpAvailableToggle(false)
                        } else {
                          setEmpAvailableToggle(true)
                        }
                      }}
                    />
                    <ToggleLabel htmlFor="checkbox4" />
                  </ToggleWrapper> */}
                  {/* <div className="toggle-label ml-2">Available Emp</div> */}

                </ToggleDiv>
                {/* </div>
           <div className='col-12 col-md-9 col-lg-8 col-xl-6 p-0'> */}
                <ButtonsModals className="d-lg-flex justify-content-sm-center justify-content-md-end">
                  <div className={'d-flex justify-content-center'}>
                    {/* <PrimaryButtonForm disabled={false} minWidth="6rem" className={'mr-1'} onClick={handleDownlaodPdf}
                    >
                      <span className={'mr-1'}>Export to Pdf</span>
                    </PrimaryButtonForm> */}
                    <PrimaryButtonForm disabled={disableUnPublishButton} minWidth="6rem" className={'mr-1'} onClick={UnPublishVisits}
                    >
                      <span className={'mr-1'}>UnPublish</span>
                    </PrimaryButtonForm>

                    <PrimaryButtonForm disabled={disablePublishButton} minWidth="6rem" className={'mr-1'} onClick={publishVisits}>
                      <span className={'mr-1'}>Publish</span>
                    </PrimaryButtonForm>
                    <PrimaryButtonForm minWidth="6rem" className={'mr-1'} onClick={handleAdhocVisitModal}>
                      <span className={'mr-1'}>Add Adhoc</span>
                    </PrimaryButtonForm>
                    {shiftType == CALENDAR_DAILY &&
                      <PrimaryButtonForm
                        minWidth="7rem"
                        onClick={() => setCopySch(true)}>
                        <span className={'pr-2 pl-2'}>
                          {loadingCopySch ? 'Copying' : 'Copy Sch'}
                        </span>
                        {!loadingCopySch ? (
                          COPY
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                          />
                        )}
                      </PrimaryButtonForm>}
                  </div>
                  {shiftType == CALENDAR_DAILY &&
                    <div
                      className={
                        'd-flex justify-content-center justify-content-md-end'
                      }>
                      <PrimaryButtonForm
                        className={'ml-1'}
                        minWidth="7rem"
                        disabled={lockButton}
                        onClick={loadingOptaPlanner ? runSchedulerOptaPlanner : () => onClickRunSchdular()}>
                        <span className={'pr-2 pl-2'}>
                          {loadingOptaPlanner ? 'Stop Sch' : 'Run Sch'}
                        </span>
                        {!loadingOptaPlanner ? (
                          buttonIconP
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                          />
                        )}
                      </PrimaryButtonForm>
                    </div>
                  }
                </ButtonsModals>
                {/* </div> */}
              </div>

            </div>
            <div className="d-flex flex-wrap justify-content-between lg:justify-content-start lg:flex-nowrap">
              <ButtonsContainer calendarView={calendarView} listView={listView} style={{ alignSelf: 'center' }}>
                <div className={'calendarView'}>
                  {(viewPermissions.client || viewPermissions.clientSelf) &&
                    <button
                      className={'clientView'}
                      onClick={() => {
                        changeCalendar('client')
                      }}>
                      Client View
                    </button>}
                  {(viewPermissions.team || viewPermissions.teamSelf) &&
                    <button
                      className={'carerView'}
                      onClick={() => {
                        changeCalendar('team')
                      }
                      }>
                      Team View
                    </button>}
                  {(viewPermissions?.location || viewPermissions?.locationSelf) &&
                    // <button style={{ marginRight: '14%' }}
                    <button
                      className={'locationView'}
                      onClick={() => {
                        changeCalendar('location')
                        setListView(false)
                      }}>
                      Location View
                    </button>}
                  {(viewPermissions.run || viewPermissions.runSelf) &&
                    <button
                      className={'runView'}
                      onClick={() => {
                        changeCalendar('run')
                      }}>
                      Run View
                    </button>}
                  {(viewPermissions.list || viewPermissions.listSelf) &&
                    // <button style={{ marginRight: '14%' }}
                    <button
                      className={'listView'}
                      onClick={() => {
                        changeCalendar('list')
                        setListView(true)
                      }}>
                      List View
                    </button>}

                </div>


              </ButtonsContainer>
              <DataContainer className="my-0">
                {(checkedRows.length > 0 && listView && (
                  <div class="dropdown mr-3">
                    <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Action
                    </PrimaryButtonForm>
                    <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>

                      <a class="dropdown-item"
                        onClick={() => {
                          setAssignVisits(!assignVisits)
                          setActionType('Reassign')
                        }
                        }
                      >Reassign Visits</a>
                      <a class="dropdown-item"
                        onClick={() => {
                          setUndoCancel(false)
                          setModalUnassignValue(true)
                        }}
                      >Unassign Visits</a>
                      <a class="dropdown-item"
                        onClick={() => {
                          setShadowVisit(false)
                          setModalDuplicateValue(true)
                        }}
                      >Duplicate Visits</a>
                      <a class="dropdown-item"
                        onClick={() => {
                          setAssignVisits(!assignVisits)
                          setActionType('shadow')
                        }}
                      >Shadow Visits</a>
                      <a class="dropdown-item"
                        onClick={() => {
                          setAssignVisits(!assignVisits)
                          setActionType('obvservation')
                        }}
                      >Observations Visits</a>
                      <a class="dropdown-item"
                        onClick={() => setAdjustVisits(!adjustVisits)}
                      >Adjust Visits</a>
                      <a class="dropdown-item"
                        onClick={() => setBulkSetVisits(!bulkSetVisits)}
                      >Set Visits Time</a>
                      <a class="dropdown-item"
                        onClick={() => actualEditFn()}
                      >Edit Actuals</a>
                      <a class="dropdown-item"
                        onClick={() => handleCancelVisit('multi')}
                      >Cancel Visits</a>
                      <a class="dropdown-item"
                        onClick={() => {
                          setUndoCancel(true)
                          setModalUnassignValue(true)
                        }}
                      >Undo Cancel</a>
                      <a class="dropdown-item"
                        onClick={() => handleDeleteVisit('multi')}
                      >Delete Visits</a>
                      <a class="dropdown-item"
                        onClick={() => setModalRevertValue(true)}
                      >Revert Visits</a>

                      {readPermissionRevertClocked && (
                        <a class="dropdown-item"
                          onClick={() => revertClockedFn(true)}
                        >Revert Clocked</a>

                      )}

                      <a class="dropdown-item"
                        onClick={() => PublishListData(true)}
                      >Publish</a>
                      <a class="dropdown-item"
                        onClick={() => PublishListData(false)}
                      >UnPublish</a>
                      <a class="dropdown-item"
                        onClick={() => handleAddRunNameModal()}
                      >Run Name</a>
                      <a class="dropdown-item"
                        onClick={() => setAssignRate(!assignRate)}
                      >Rate Card</a>
                      <a class="dropdown-item"
                        onClick={() => setFunderName(!funderName)}
                      >Funder Name</a>
                      <a class="dropdown-item"
                        onClick={() => addOfferVisits()}
                      >Add Offer</a>
                      <a class="dropdown-item"
                        onClick={() => setModalDetachRun(true)}
                      >Detach All Run</a>
                      <a class="dropdown-item"
                        onClick={() => setModalHandback(true)}
                      >Created Handback</a>
                      {/* <a class="dropdown-item"
                    onClick={() => setAssignMed(true)}
                  >Attach Medication</a>
                  <a class="dropdown-item"
                    onClick={() => setModalHandback(true)}
                  >Detach Medication</a> */}
                      <a class="dropdown-item"
                        onClick={() => setModalUpdateTravel(true)}
                      >Update Travel</a>
                    {copyVisitPermission &&
                      <a class="dropdown-item"
                        onClick={() => setModalCopyVisit(true)}
                      >Copy Visits</a>}
                    </div>
                  </div>
                ))}
                <div className={`data-container data-container-schedule my-0`}>
                  {calendarView === 'client' &&
                    <div className="data mr-2">
                      <p className="fontBold">Clients</p>
                      <div className="contents">
                        <div className="sub-contents">
                          <p>Count</p>
                          <p>{visitScreenData?.totalClient}</p>
                        </div>
                      </div>
                    </div>
                  }
                  {calendarView === 'location' &&
                    <div className="data mr-2">
                      <p className="fontBold">Locations</p>
                      <div className="contents">
                        <div className="sub-contents">
                          <p>Count</p>
                          <p>{visitScreenData?.totalLocation}</p>
                        </div>
                      </div>
                    </div>
                  }
                  {calendarView === 'team' &&
                    <div className="data mr-2">
                      <p className="fontBold">Employees</p>
                      <div className="contents">
                        <div className="sub-contents">
                          <p>Count</p>
                          <p>{visitScreenData?.totalEmployee}</p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="data">
                    <p className="fontBold">Assigned</p>
                    <div className="contents">
                      <div className="sub-contents mr-2">
                        <p>Hours</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.assignedHours}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.assignedHours}</p> :
                            <p>{visitScreenData?.totalAssignedHours}</p>
                        }
                      </div>
                      <div className="sub-contents">
                        <p>Visits</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.assignedVisits}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.assignedVisits}</p> :
                            <p>{visitScreenData?.totalAssignedVisits}</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="data leftPadding">
                    <p className="fontBold">Unassigned</p>
                    <div className="contents">
                      <div className="sub-contents mr-2">
                        <p>Hours</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.unassignedHours}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.unassignedHours}</p> :
                            <p>{visitScreenData?.totalUnassignedHours}</p>
                        }
                      </div>
                      <div className="sub-contents">
                        <p>Visits</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.unassignedVisits}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.unassignedVisits}</p> :
                            <p>{visitScreenData?.totalUnassignedVisits}</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="data leftPadding">
                    <p className="fontBold">Published</p>
                    <div className="contents">
                      <div className="sub-contents mr-2">
                        <p>Hours</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.publishHours}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.publishHours}</p> :
                            <p>{visitScreenData?.totalPublishHours}</p>
                        }
                      </div>
                      <div className="sub-contents">
                        <p>Visits</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.publishVisits}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.publishVisits}</p> :
                            <p>{visitScreenData?.totalPublishVisits}</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="data leftPadding">
                    <p className="fontBold">UnPublished</p>
                    <div className="contents">
                      <div className="sub-contents mr-2">
                        <p>Hours</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.unpublishHours}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.unpublishHours}</p> :
                            <p>{visitScreenData?.totalUnassignedHours}</p>
                        }
                      </div>
                      <div className="sub-contents">
                        <p>Visits</p>
                        {calendarView === 'clinet' ?
                          <p>{visitScreenData?.clinetData?.unpublishVisits}</p> :
                          calendarView === 'location' ?
                            <p>{visitScreenData?.locationData?.unpublishVisits}</p> :
                            <p>{visitScreenData?.totalUnpublishVisits}</p>
                        }
                      </div>
                    </div>
                  </div>
                  {calendarView === 'team' &&
                    <did className="travel-tooltrip leftPadding">
                      {/* <div className="data leftPadding">
                    <p className="fontBold">Travel</p>
                    <div className="contents">
                      <div className="sub-contents mr-2">
                        <p>Hours</p>
                        <p>{travelHours}</p>
                      </div>
                    </div>
                  </div> */}
                      <BootstrapTooltip class title={
                        <>
                          <div className='d-flex'>
                            <div style={{ width: '50%', marginRight: '5px' }}>
                              <h6>Client Visits</h6>
                              {/* Travel Code */}
                              {visitScreenData?.clientTravelCode && Object?.keys(visitScreenData?.clientTravelCode)?.map(item => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData.clientTravelCode[item]}
                                    </div>
                                  </div>
                                )
                              })
                              }
                              {/* Travel Mode */}
                              {visitScreenData?.clientTravelMode && Object.keys(visitScreenData?.clientTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.clientTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div style={{ width: '50%', marginRight: '5px' }}>
                              <h6>Location Visits</h6>
                              {/* Travel Code */}
                              {visitScreenData?.locationTravelCode && Object.keys(visitScreenData?.locationTravelCode).map((item, i) => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div >
                                      <span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData?.locationTravelCode[item]}
                                    </div>
                                  </div>)
                              })}

                              {/* <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                                  {travelHoursClientToHome}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                                  {travelHoursClientToOffice}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                                  {travelHoursOfficeToClient}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                                  {travelHoursHomeToClient}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                                  {travelHoursHomeToOffice}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                                  {travelHoursOfficeToOffice}
                                </div>
                              </div> */}


                              {/* Travel Mode */}
                              {visitScreenData?.locationTravelMode && Object.keys(visitScreenData?.locationTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.locationTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div style={{ width: '50%' }}>
                              <h6>Shifts</h6>
                              {/* Travel Code */}
                              {visitScreenData?.shiftTravelCode && Object.keys(visitScreenData?.shiftTravelCode).map(item => {
                                return (
                                  <div style={{ display: "flex" }}>
                                    <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                      {visitScreenData?.shiftTravelCode[item]}
                                    </div>
                                  </div>
                                )
                              })}

                              {/* <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Client To Client: '}</span>
                                  {travelHoursClientToClientShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Client To Home: '}</span>
                                  {travelHoursClientToHomeShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Client To Office: '}</span>
                                  {travelHoursClientToOfficeShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Office To Client: '}</span>
                                  {travelHoursOfficeToClientShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Home To Client: '}</span>
                                  {travelHoursHomeToClientShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Home To Office: '}</span>
                                  {travelHoursHomeToOfficeShift}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div ><span style={{ fontWeight: "bold" }}>{'Office To Office: '}</span>
                                  {travelHoursOfficeToOfficeShift}
                                </div>
                              </div> */}
                              {/* Travel Mode */}
                              {visitScreenData?.shiftTravelMode && Object.keys(visitScreenData?.shiftTravelMode).map((item, i) => (
                                <div style={{ display: "flex" }}>
                                  <div ><span style={{ fontWeight: "bold" }}>{`${capitalize(item)}: `}</span>
                                    {visitScreenData?.shiftTravelMode[item]}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      }>
                        <div className="data leftPadding">
                          <p className="fontBold">Travel</p>
                          <div className="contents">
                            <div className="sub-contents mr-2">
                              <p>Hours</p>
                              <p>{visitScreenData?.totalTravelHours}</p>
                            </div>
                          </div>
                        </div>
                      </BootstrapTooltip>
                      {/* <BootstrapTooltip
                        title={
                          <>
                            <div style={{ display: 'flex' }}>
                              <div>
                                <span style={{ fontWeight: 'bold' }}>
                                  {
                                    'Wait Hrs before first and after last visit (Capacity): '
                                  }
                                </span>
                                {waitHoursCapacity &&
                                  (waitHoursCapacity / 3600000).toFixed(2)}
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Between Visit: '}</span>
                                {waitHoursBetweenVisit && (waitHoursBetweenVisit / 3600000).toFixed(2)}
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div ><span style={{ fontWeight: "bold" }}>{'Wait Hrs Exclude More Than Two: '}</span>
                                {waitHoursExcludeMoreThanTwo && (waitHoursExcludeMoreThanTwo / 3600000).toFixed(2)}
                              </div>
                            </div>
                          </>}>
                        <div className="data leftPadding">
                          <p className="fontBold">Wait</p>
                          <div className="contents">
                            <div className="sub-contents mr-2">
                              <p>Hours</p>
                              <p>{waitHours}</p>
                            </div>
                          </div>
                        </div>
                      </BootstrapTooltip> */}


                    </did>
                  }

                </div>
              </DataContainer>
            </div>
          </>
        }


        <Alert
          header={'STATUS'}
          btnText={'Close'}
          text={alert.text}
          type={alert.type}
          show={alert.show}
          onClosePress={onCloseAlert}
          pressCloseOnOutsideClick={true}
          showBorderBottom={true}
          alertStyles={{}}
          headerStyles={{}}
          textStyles={{}}
          buttonStyles={{}}
        />
        {listView ?
          <CalendarListView
            events={events}
            handleSelectChange={handleSelectChange}
            setCheckedRows={setCheckedRows}
            checkedRows={checkedRows}
            setCheckedValue={setCheckedValue}
            checkedValue={checkedValue}
            choices={choices}
            eventClickHandler={eventClickHandler}
            displayMenu={displayMenu}
          />
          :
          <TimelineCalendar
            calendarView={calendarView}
            setCalendarView={setCalendarView}
            editMode={editMode}
            isSplitMode={isSplitMode}
            regularMode={regularMode}
            showProgressSection={true}
            resourceAreaHeaderContent={
              calendarView === 'client' ? 'Clients' :
                calendarView === 'team' ? 'Team' :
                  calendarView === 'location' ? 'Locations' :
                    'Run Name'
            }
            resources={
              calendarView === 'client' ? resourcesClients :
                calendarView === 'team' ? resourcesEmployees :
                  calendarView === 'location' ? resourcesLocations :
                    resourcesRunroutes
            }
            events={events}
            employeeVisitData={employeeVisitData}
            clinetVisitData={clinetVisitData}
            setShiftType={setShiftType}
            shiftType={shiftType}
            values={values}
            setValues={setValues}
            eventClickHandler={data => {
              if (isMultiEventSelect) {
                handleEventClick(data)
              } else {
                eventClickHandler(data);
              }
            }}
            optaplannerStatus={optaplannerStatus}
            visitsAssigned={visitScreenData?.totalVisitAssigned}
            visitsUnassigned={visitScreenData?.totalUnassignedVisits}
            loadingOptaPlanner={loadingOptaPlanner}
            timeStatus={timeStatus}
            totalVisits={TOTAL_VISITS}
            getOptaplannerStatus={getOptaplannerStatus}
            runningDate={runningDate}
            setChangeVisitDetails={setChangeVisitDetails}
            showDeleteAllVisits={showDeleteAllVisits}
            showPlannerVisitsFn={showPlannerVisitsFn}
            showPlannerAssign={showPlannerAssign}
            setChangeVisit={setChangeVisit}
            handleDownlaod={handleDownlaod}
            downloadDisable={downloadDisable}
            setLoading={setLoading}
            setAllowSchedulerCalls={setAllowSchedulerCalls}
            useInput={useInput}
            // getEmployeeActiveData={getEmployeeActiveData}
            setDecreaseWidth={setDecreaseWidth}
            decreaseWidth={decreaseWidth}
            query_params_data={query_params_data}
            schedulerData={schedulerData}
            commHrsData={commHrsData}
            preferredData={preferredData}
            rightClickHandler={(args) => rightClickHandler(args)}
            removeRightClickHandler={(args) => removeRightClickHandler(args)}
            compactViewToggle={compactViewToggle}
            // selectable={false}
            // handleSelect={handleSelect}
            enableMultiVisitSelection={true}
            handleMultiSelectEventToggle={handleMultiSelectEventToggle}
            isMultiEventSelect={isMultiEventSelect}
            selectedEvents={selectedEvents}
          />

        }

        {addVisit && (
          <AddVisitModal
            props={props}
            readOnly={true}
            fullBind={() => encloseModalBind(fullBindAddVisit)}
            setAddVisit={setAddVisit}
            toast={toast}
            dropDownHandleVisit={dropDownHandleVisit}
            dropDownHandleEmployee={dropDownHandleEmployee}
            useInput={useInput}
            values={values}
            nameSection={VISITS_VALUES}
            choices={choices}
            setValues={setValues}
            assignedEmployee={
              values.employeeAuxData ? values.employeeAuxData : null
            }
            employees={employees}
            handleAddVisitModal={handleAddVisitModal}
            searchScheduler={searchScheduler}
            handleCancelVisit={handleCancelVisit}
            handleDeleteVisit={handleDeleteVisit}
            handleSchedulerRevert={handleSchedulerRevert}
            handleSchedulerPublishOrUnpublish={handleSchedulerPublishOrUnpublish}
            onShowAlert={onShowAlert}
            clientdata={clientdata}
            editMode={editMode}
            schedularVisitId={schedularVisitId}
            dropdownHandleAdhocEmployeeSkill={dropdownHandleAdhocEmployeeSkill}
            dropdownHandleAdhocEmployee={dropdownHandleAdhocEmployee}
            dropdownHandleReplicaEmployee={dropdownHandleReplicaEmployee}
            dropdownHandleReplicaEmployeeSkill={dropdownHandleReplicaEmployeeSkill}
            scoreDataId={scoreDataId}
            clientSelectedId={clientSelectedId}
            loadingModal={loadingModal}
            iSPublished={iSPublished}
            setLoadingNew={setLoadingNew}
            loadingNew={loadingNew}
            //cancel props added
            fullBindCancel={fullBindCancelVisit}
            errorMessage={errorMessage}
            nameSectionCancel={CANCEL_VISIT_VALUES}
            saveButtonIcon={saveButtonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            saveCancelVisit={saveCancelVisit}
            updateCancelVisit={updateCancelVisit}
            dropDownHandleCancelVisit={dropDownHandleCancelVisit}
            listView={listView}
            clearSelectedElId={clearSelectedElId}
          />
        )}
        {addRegularVisit && (
          <AddRegularVisit
            newRun={newRun}
            useInput={useInput}
            values={values}
            setValues={setValues}
            fullBind={fullBindAddRegularVisit}
            loadingModal={loadingModal}
            setLoadingModal={setLoadingModal}
            errorMessage={errorMessage}
            dataValues={dataValues}
            textStatus={textStatus}
            choices={choices}
            loadingSave={loadingSave}
            saveButtonIcon={saveButtonIcon}
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            requireDayOfWeek={requireDayOfWeek}
            calculateEndValueProps={calculateEndValueProps}
            switchEndValueInput={switchEndValueInput}
            dropDownHandleRegVisit={dropDownHandleRegVisit}
            dropDownHandleSelect={dropDownHandleSelect}
            handleSaveVisitRun={handleSaveBulkRun}
            pinnedData={pinnedData}
            deletePinnedEmp={deletePinnedEmp}
            employees={employees}
            contextChoicesMap={contextChoicesMap}
          />
        )}

        <RotaShiftsActions
          readOnly={false}
          travelRate={travelRate}
          fullBind={fullBindAddAvailability}
          useInput={useInput}
          employees={employees}
          values={values}
          errorMessage={errorMessage}
          buttonIcon={buttonIcon}
          loadingSave={loadingSave}
          textStatus={textStatus}
          dateMessage={dateMessage}
          timeMessage={timeMessage}
          dateError={dateError}
          timeError={timeError}
          choices={choices}
          isDateAssigned={isDateAssigned}
          // typeOfMessage={typeOfMessage}
          //added new props below
          shiftIndex={0}
          daysOfWeek={daysOfWeek}
          setDaysOfWeek={setDaysOfWeek}
          requireDayOfWeek={requireDayOfWeek}
          setValues={setValues}
          filterEmployee={filterEmployee}
          filterClient={filterClient}
          filterSuperAdmin={filterSuperAdmin}
          filterAdmin={filterAdmin}
          invalidOvernight={invalidOvernight}
          setInvalidOvernight={setInvalidOvernight}
          availabilitiesEmployeeId={availabilitiesEmployeeId}
          availabilitiesEmployeeFullName={availabilitiesEmployeeFullName}
          availabilitiesEmployeeIdName={availabilitiesEmployeeIdName}
          availabilityDetailsDays={availabilityDetailsDays}
          detailId={detailId}
          // createAvailable={createAvailable}
          // createSelfAvailable={createSelfAvailable}
          shiftTypePopup={shiftTypePopup}
          employeeDisable={employeeDisable}
          setEmployeeDisable={setEmployeeDisable}
          fullBindAddAvailability={fullBindAddAvailability}
          fullBindAddUnavailability={fullBindAddUnavailability}
          setAddAvailability={setAddAvailability}
          setAddUnavailability={setAddUnavailability}
          addAvailability={addAvailability}
          addUnavailability={addUnavailability}
          TravelData={TravelData}
          shiftTempOnPopup={shiftTempOnPopup}
          employeeTempOnPopup={employeeTempOnPopup}
          reloadData={searchScheduler}
          errors={errors}
          setCheckRequires={setCheckRequires}
          setErrorMessage={setErrorMessage}
          setLoadingSave={setLoadingSave}
          setTextStatus={setTextStatus}
          setDisableButton={setDisableButton}
          disableButton={disableButton}
          setIsDateAssigned={setIsDateAssigned}
          setSelectedEmployees={setSelectedEmployees}
          handleAddUnavailability={handleAddUnavailability}
          handleAddAvailability={handleAddAvailability}
        />

        {/* {!addUnavailability
          ? null
          : filterClient === 'null' && (
            <AddUnavailability
              readOnly={values.idSelected ? true : false}
              dropDownHandleSelect={dropDownHandleSelect}
              fullBind={fullBindAddUnavailability}
              useInput={useInput}
              employees={employees}
              choices={choices}
              values={values}
              errorTime={errorTime}
              splitAvailability={()=>{}}
              addNewAvailability={()=>{}}
              deleteAvailabilityDetail={()=>{}}
              errorMessage={errorMessage}
              buttonIcon={buttonIcon}
              loadingSave={loadingSave}
              textStatus={textStatus}
              dateMessage={dateMessage}
              timeMessage={timeMessage}
              dateError={dateError}
              timeError={timeError}
              closeModal={() => {
                setAddUnavailability(false);
              }}
              isDateAssigned={isDateAssigned}
              // typeOfMessage={typeOfMessage}
              //added new props below
              shiftIndex={0}
              daysOfWeek={daysOfWeek}
              setDaysOfWeek={setDaysOfWeek}
              requireDayOfWeek={requireDayOfWeek}
              nameSection={`${SHIFT_VALUES}`}
              setValues={setValues}
              filterEmployee={filterEmployee}
              filterClient={filterClient}
              filterSuperAdmin={filterSuperAdmin}
              filterAdmin={filterAdmin}
              invalidOvernight={invalidOvernight}
              setInvalidOvernight={setInvalidOvernight}
              handleDropAbsenceType={()=>{}}
              employeesHandleSelect={()=>{}}
              availabilityDetailsDays={availabilityDetailsDays}
              // createUnAvailable={createUnAvailable}
              // createSelfUnAvailable={createSelfUnAvailable}
            />
          )} */}
        {addBulkRunName && (
          <BulkRunName
            newRun={newRun}
            useInput={useInput}
            values={values}
            setValues={setValues}
            fullBind={() => encloseModalBind(fullBindAddBulkRunName)}
            loadingModal={loadingModal}
            setLoadingModal={setLoadingModal}
            errorMessage={errorMessage}
            dataValues={dataValues}
            textStatus={textStatus}
            choices={choices}
            loadingSave={loadingSave}
            saveButtonIcon={saveButtonIcon}
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            requireDayOfWeek={requireDayOfWeek}
            calculateEndValueProps={calculateEndValueProps}
            switchEndValueInput={switchEndValueInput}
            dropDownHandleRegVisit={dropDownHandleRegVisit}
            dropDownHandleSelect={dropDownHandleSelect}
            handleSaveBulkRun={handleSaveBulkRun}
            pinnedData={pinnedData}
            deletePinnedEmp={deletePinnedEmp}
            employees={employees}
          />
        )}
        {cancelVisit && (
          <CancelVisitModal
            fullBind={() => encloseModalBind(fullBindCancelVisit)}
            useInput={useInput}
            errorMessage={errorMessage}
            choices={choices}
            nameSection={CANCEL_VISIT_VALUES}
            setValues={setValues}
            values={values}
            saveButtonIcon={saveButtonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            saveCancelVisit={saveCancelVisit}
            dropDownHandleCancelVisit={dropDownHandleCancelVisit}
            listView={listView}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            rightClickSelectedId={rightClickSelectedId}
          />
        )}
        {!adhocVisit ? null : (
          <AdhocVisitModal
            readOnly={true}
            editMode={editMode}
            fullBind={fullBindAdhocVisit}
            dropDownHandleAdhocVisit={dropDownHandleAdhocVisit}
            dropDownHandleClient={dropDownHandleClient}
            useInput={useInput}
            values={values}
            nameSection={ADHOC_VIIST_VALUES}
            choices={choices}
            setValues={setValues}
            employees={employees}
            clients={clients}
            handleAddVisitModal={handleAddVisitModal}
            dropdownHandleAdhocEmployee={dropdownHandleAdhocEmployee}
            searchScheduler={searchScheduler}
            dropDownHandleSkill={dropDownHandleSkill}
            dropdownHandleAdhocEmployeeSkill={dropdownHandleAdhocEmployeeSkill}
            handleSaveAdhocVist={handleSaveAdhocVist}
            setCheckRequires={setCheckRequires}
            errorMessage={errorMessage}
            saveButtonIcon={saveButtonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            setFormErrors={setFormErrors}
            errors={errors}
            clientDropdownId={clientDropdownId}
            locations={locations}
          />
        )}
        {
          assignVisits &&
          <AssignBulkVisits
            fullBind={() => encloseModalBind(fullBindAssignVisit)}
            employees={employees}
            checkedRows={checkedRows}
            choices={choices}
            travelRate={travelRate}
            closeAssignModal={closeAssignModal}
            searchScheduler={searchScheduler}
            actionType={actionType}
            setActionType={setActionType}
            setAssignVisits={setAssignVisits}
            toast={toast}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
          />
        }

        {
          adjustVisits &&
          <AdjustVisitModal
            fullBind={() => encloseModalBind(fullBindAdjustVisit)}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setAdjustVisits={setAdjustVisits}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            clearSelectedElId={clearSelectedElId}
          />
        }
        {
          bulkSetVisits &&
          <BulkSetVisits
            fullBind={() => encloseModalBind(fullBindBulkSetVisit)}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setBulkSetVisits={setBulkSetVisits}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            clearSelectedElId={clearSelectedElId}
          />
        }
        {
          funderName &&
          <FunderName
            fullBind={() => encloseModalBind(fullBindFunderName)}
            employees={employees}
            checkedRows={checkedRows}
            choices={choices}
            travelRate={travelRate}
            closeAssignModal={closeAssignModal}
            searchScheduler={searchScheduler}
            actionType={actionType}
            setActionType={setActionType}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
          />
        }
        {
          actualEditsVisits &&
          <ActualEdits
            fullBind={() => encloseModalBind(fullBindActualEditsVisit)}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setActualEditsVisits={setActualEditsVisits}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            rightClickSelectedId={rightClickSelectedId}
            clearSelectedElId={clearSelectedElId}
          />
        }
        {
          plannerSchAssign &&
          <PlannerAssign
            fullBind={fullBindPlannerSchAssign}
            searchScheduler={searchScheduler}
            setPlannerSchAssign={setPlannerSchAssign}
            branch={branch}
            sch_values={values}
          />
        }
        {
          assignRate &&
          <AssignRate
            fullBind={() => encloseModalBind(fullBindAssignRate)}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setAssignRate={setAssignRate}
            travelRate={travelRate}
            payRate={payRate}
            billRate={billRate}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            clearSelectedElId={clearSelectedElId}
          />
        }
        {
          assignMed &&
          <AttachMedication
            fullBind={fullBindAssignMed}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setAssignMed={setAssignMed}
            contextChoices={contextChoices}
          />
        }
        {
          revertClocked &&
          <RevertClocked
            updatePermissionRevertClocked={updatePermissionRevertClocked}
            createPermissionRevertClocked={createPermissionRevertClocked}
            fullBind={() => encloseModalBind(fullBindRevertClocked)}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setModalRevertClockedValue={setModalRevertClockedValue}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            rightClickSelectedId={rightClickSelectedId}
            clearSelectedElId={clearSelectedElId}
          />
        }

        {
          copySch &&
          <CopySchedular
            fullBind={fullBindCopySch}
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            setCopySch={setCopySch}
            shiftType={shiftType}
            sch_start={values.start_date}
            sch_end={values.end_date}
            toast={toast}
            branch={branch}
            setLoadingCopySch={setLoadingCopySch}
          />
        }
        {
          plannerSchVisits &&
          <PlannerModal
            fullBind={fullBindPlannerSchVisit}
            values={values}
            PlannerClick={PlannerClick}
          />
        }

        {valueUpdateTravelModal &&
          <UpdateTravel
            checkedRows={checkedRows}
            searchScheduler={searchScheduler}
            closeAssignModal={closeAssignModal}
            actionType={actionType}
            choices={choices}
            fullBind={() => encloseModalBind(bindModalUpdateTravel)}
            setModalUpdateTravel={setModalUpdateTravel}
            toast={toast}
            rightClickSelectedId={rightClickSelectedId}
            setRightClickSeletcedId={setRightClickSeletcedId}
            setSelectedEvents={setSelectedEvents}
            clearSelectedElId={clearSelectedElId}
          />
        }
        {/* The modal can be show one or two buttons (onOk or onCancel) */}
        <ModalInformation
          type={'warning'}
          title={'Warning'}
          okTitle={'ACCEPT'}
          icon={ERROR_OUTLINE}
          hideOnCancel={true}
          onOk={okInformationMessage}
          {...bindOptaPlannerMessage}>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              We had a problem synchronizing the data, please try again later.
            </div>
          </MessageOptaPlanner>
        </ModalInformation>

        <ModalInformation
          type={'warning'}
          title={'Clear scheduler data'}
          okTitle={'NO, CANCEL'}
          onCancelTitle={'YES, PROCEED'}
          icon={ERROR_OUTLINE}
          hideOnCancel={false}
          onCancel={() => {
            if (values.branch_for_delete && values.branch_for_delete.length > 0) {
              setModalDeleteVisits(true)
            } else {
              toast.current.show({ severity: 'error', summary: '', detail: 'Branch field is mandatory' });
            }

          }}
          onOk={() => {
            setShowDeleteVisitsModal(false);

          }}
          showModal={showDeleteVisitsModal}>

          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              This action will clear all visits on the schedule . All visits will
              be set to Unassigned.
            </div>
            <div className={'mt-4'}>Consider Visits for Delete</div>
            <div className='text-left' style={{ width: '40%', margin: 'auto' }}>
              <ComponentDynamic loading={loadingpas} >
                <form >

                  <div class="form-check">
                    <input class="form-check-input" onClick={(val) => handleRunDelete('unpublished')} type="radio" value='unpublished' name="flexRadioDefault" id="flexRadioDefault1" checked={(checkedDelete === 'unpublished') ? true : false} />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Unpublished
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" onClick={(val) => handleRunDelete('system_generated')} type="radio" value='system_generated' name="flexRadioDefault" id="flexRadioDefault2" checked={(checkedDelete === 'system_generated') ? true : false} />
                    <label class="form-check-label" for="flexRadioDefault2">
                      System Generated
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" onClick={(val) => handleRunDelete('regular_visits')} type="radio" value='regular_visits' name="flexRadioDefault" id="flexRadioDefault3" checked={(checkedDelete === 'regular_visits') ? true : false} />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Regular Visits
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" onClick={(val) => handleRunDelete('manuals')} type="radio" value='manuals' name="flexRadioDefault" id="flexRadioDefault5" checked={(checkedDelete === 'manuals') ? true : false} />
                    <label class="form-check-label" for="flexRadioDefault5">
                      Manuals
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" onClick={(val) => handleRunDelete('all')} type="radio" value='all' name="flexRadioDefault" id="flexRadioDefault4" checked={(checkedDelete === 'all') ? true : false} />
                    <label class="form-check-label" for="flexRadioDefault4">
                      All
                    </label>
                  </div>
                </form>

              </ComponentDynamic>

            </div>
            <div className='text-left mt-2'>
              <DropdownSearchable
                isMulti={true}
                required={true}
                placeHolder={'Branch'}
                options={branch ? branch : []}
                selectedOptionsProps={
                  values
                    ? values.branch_for_delete
                      ? values.branch_for_delete
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect({ branch_for_delete: value })
                }
              />
            </div>
          </MessageOptaPlanner>
        </ModalInformation>

        <ConfirmDecision
          isDisable={disableDeletePopupConfirm}
          type="confirm"
          title="Confirm"
          body={
            `Are you sure you want to unassigned ${checkedDelete} visits from ${values.branch_for_delete_names ? values.branch_for_delete_names.join() : ''} branch?`
          }
          onOk={() => {
            confirmCancelAllVisits()
          }
          }
          onNo={
            () => {
              setModalDeleteVisits(false)
              setShowDeleteVisitsModal(false);

            }
          }
          {...bindModalDeleteVisits}
        />

        <ConfirmDecision
          isDisable={disableDeletePopupConfirm}
          type="confirm"
          title="Confirm"
          body={`Are you sure you want to copy this visit`}
          onOk={() => {
            handleCopyVisits()
          }
          }
          onNo={
            () => {
              setModalCopyVisit(false)

            }
          }
          {...bindModalCopyVisit}
        />

        <ModalDecision
          type="warning"
          title="Warning"
          body={
            'Are you sure you want to change this visit? \n CHANGING the visit will unassign the scheduled carer.'
          }
          subBody={
            'TIP (Recommended) - after changing the visit rerun the scheduler or manually assign a new carer by clicking on the visit, choosing the new carer in the employee dropdown and saving.'
          }
          showModal={showChangeVisitsModal}
          onOk={() => {
            setUpdateVisit(changeVisitDetails);
            setShowChangeVisitsModal(false);

            let changeEvents = events.map(visit => {
              if (visit.id === changeVisitDetails.id) {
                visit.title = 'Unassigned';
                visit.unassigned = true;
                visit.backgroundColor = SCHEDULER_UNASSIGNED;
                visit.borderColor = SCHEDULER_UNASSIGNED;
                visit.textColor = WHITE;
                visit.employeeId = null;
                visit.start = moment(
                  changeVisitDetails.start_date._d
                ).toISOString();
                visit.end = moment(changeVisitDetails.end_date._d).toISOString();

                if (calendarView === 'team') {
                  visit.resourceId = null;
                }

                return visit;
              }

              return visit;
            });

            setEvents(changeEvents);
          }}
          onCancel={() => {
            setShowChangeVisitsModal(false);
            // changeVisit.revert();
          }}
          onClose={() => setModalRunSchedule(false)}
          okTitle={'SAVE'}
        />

        <ModalDecision
          type="delete"
          title="Warning"
          isdisabled={deleteVisitDisabled}
          body={
            checkedRows.length > 0 ? 'Are you sure you want to delete these visit ? ' : 'Are you sure you want to delete this visit ? '
          }
          subBody={'This cannot be undone. Proceed to delete with extreme caution.'}
          onOk={handleDeleteTheVisit}
          onCancel={() => {
            setIdSelected('');
            clearSelectedElId();
          }}
          okTitle={'DELETE'}
          {...bindModalDelete}
        />

        <ModalDecision
          type="warning"
          title="Warning"
          body={
            'This action creates an overlapping visit and is not recommended.'
          }
          subBody={'Are you sure you wish to assign this visit? '}
          onOk={handleDragAndDrop}
          onCancel={() => {
            setModalDragAndDropValue(false);
          }}
          okTitle={'Confirm'}
          {...bindModalDragAndDrop}
        />

        <ModalDecision
          type="warning"
          title="Warning"
          body={
            'Employee must be assigned for this process to complete.'
          }
          onOk={handleDeleteTheVisit}
          onCancel={() => {
            setIdSelected('');
          }}
          hideOnOk={true}
          onCancelTitle={'Close'}
          okTitle={'DELETE'}
          {...bindModalWarning}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            undoCancel ? 'Are you sure you want to Undo Cancel for these visits ? ' : 'Are you sure you want to Unassign these visits ? '
          }
          onOk={() => {
            undoCancel ? handleUndoCancel() : handleUnAssignVisits()
            setModalUnassignValue(false)
            clearSelectedElId();
          }
          }
          onNo={
            () => {
              setModalUnassignValue(false)
              clearSelectedElId();
            }
          }
          {...bindModalUnassign}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Employee Does not have any regular shift. Are you sure you want to pin employee ?'
          }
          onOk={() => {
            handleConfirmRegularVisitPopup()
          }
          }
          onNo={
            () => {
              setModalConfirmRegularVisit(false)
            }
          }
          {...bindModalConfirmRegularVisit}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to Revert  these visits'
          }
          onOk={() => {
            handleRevertVisits()
          }
          }
          onNo={
            () => {
              setModalRevertValue(false)
              clearSelectedElId();
            }
          }
          {...bindModalRevert}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to Revert  these visits'
          }
          onOk={() => {
            handleRevertVisits()
          }
          }
          onNo={
            () => {
              setModalRevertValue(false)
            }
          }
          {...bindModalRevert}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            iSPublished ? 'Are you sure you want to unPublish  these visits'
              : 'Are you sure you want to Publish  these visits'
          }
          onOk={() => {
            handlePublishOrUnpublishVisits(iSPublished)
          }
          }
          onNo={
            () => {
              setModalPublishOrUnpublishValue(false)
            }
          }
          {...bindModalPublishOrUnpublish}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Employee Does not have any regular shift. Are you sure you want to pin employee ?'
          }
          onOk={() => {
            handleConfirmRegularVisitPopup()
          }
          }
          onNo={
            () => {
              setModalConfirmRegularVisit(false)
            }
          }
          {...bindModalConfirmRegularVisit}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            shadowVisit ? 'Are you sure you want to shadow these visits ? ' : 'Are you sure you want to duplicate these visits ? '
          }
          onOk={() => {
            shadowVisit ? handleShadowVisits() : handleDuplicateVisits()

            setModalDuplicateValue(false)
            clearSelectedElId();
          }
          }
          onNo={
            () => {
              setModalDuplicateValue(false)
              clearSelectedElId();
            }
          }
          {...bindModalDuplicate}
        />

        <ConfirmRunSchedular
          type="confirm"
          title="Confirm"
          body={
            'Consider Visits for Scheduling'
          }
          subBody={
            'Include Regular-Visits and Preferred Run'
          }
          setcheckedRunRegular={setcheckedRunRegular}
          values={values}
          setconsiderTimeRes={setconsiderTimeRes}
          setconsiderEmpRota={setconsiderEmpRota}
          considerEmpRota={considerEmpRota}
          considerTimeRes={considerTimeRes}
          checkedRunRegular={checkedRunRegular}
          checkedRunRadio={checkedRunRadio}
          checkedFreezeVisits={checkedFreezeVisits}
          overrideWindow={overrideWindow}
          overrideWindowMinutes={overrideWindowMinutes}
          handleRunRadio={handleRunRadio}
          branch={branch}
          dropDownHandleSelect={dropDownHandleSelect}
          setFilterBranchRun={setFilterBranchRun}
          branchRunSchedularFilter={branchRunSchedularFilter}
          setPlanOnlyToggle={setPlanOnlyToggle}
          setOverrideWindow={setOverrideWindow}
          setOverrideWindowMinutes={setOverrideWindowMinutes}
          planOnlyToggle={planOnlyToggle}
          onOk={() => {
            if (!values.branch_run) {
              toast.current.show({ severity: 'error', summary: '', detail: 'Branch field is mandatory' });
            } else {
              if (values.branch_run.length < 1) {
                toast.current.show({ severity: 'error', summary: '', detail: 'Branch field is mandatory' });
              } else {
                runSchedulerOptaPlanner(false)
                setModalRunSchedule(false)
              }

            }

          }
          }
          // onNo={
          //   () => {
          //     runSchedulerOptaPlanner(false)
          //     setModalRunSchedule(false)
          //   }
          // }
          {...bindModalRunSchedule}
        />

        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to delete this pinned employee? '
          }
          onOk={() => {
            setDeletePin()
          }
          }
          onNo={
            () => {
              setModalPinnedEmp(false)
            }
          }
          {...bindModalPinnedEmp}
        />


        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to detach all runs? '
          }
          onOk={() => {
            setDetachRuns()
          }
          }
          onNo={
            () => {
              setModalDetachRun(false)
              clearSelectedElId();
            }
          }
          {...bindModalDetachRun}
        />
        <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to Create all Handback? '
          }
          onOk={() => {
            setHandback()
          }
          }
          onNo={
            () => {
              setModalHandback(false)
              clearSelectedElId();
            }
          }
          {...bindModalHandback}
        />
      </SchedulerContainer>
      {/* } */}
    </>
  );
};

export default Scheduler;