import React, {useState, useEffect, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import AddModal from 'components/DashboardComponents/Alerts/AddModal';
import {RiDeleteBinLine} from 'react-icons/ri';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import {MdEdit} from 'react-icons/md';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';

import {deleteCategoryFormBuilder} from 'utils/api/EmployeeApi';
import {Title} from 'shared/styles/constants/tagsStyles';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {
  LARGE_COL,
  EX_SMALL_COL4,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
  SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {FloatingMobileButton, PrimaryButtonForm} from 'shared/styles/buttons';
import {FaPlus} from 'react-icons/fa';
import {useHistory, useLocation} from 'react-router-dom';
import ShowFormModal from './showFormModal';
import FormViewer from '../formViewer/formViewer';
import {get} from 'utils/api/Api';
import {useModal} from 'hooks/ModalHook';
import AttachClientEmp from '../Modals/AttachClientEmp';
import moment from 'moment';
import {Header} from 'components/DashboardComponents/Team/styles';
import {getToken, getTenant} from 'utils/localStorage/token';
import {apiURL} from 'utils/env';
import {useForm} from 'hooks/FormHook';
import {getFormBuilder} from 'utils/api/EmployeeApi';
import {
  SearchIcon,
  SearchAddContainer,
} from 'components/DashboardComponents/Team/styles';
import {GrSearch} from 'react-icons/gr';
import {SearchContainer} from 'components/DashboardComponents/Team/styles';
import {Fade} from 'react-reveal';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';

const SettingsFormBuilders = ({
  props,
  categoryCreatePermission,
  categoryDeletePermission,
  categoryUpdatePermission,
  categoryReadPermission,
}) => {
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);
  const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
  };

  const baseUrl = getBaseUrl();
  const [loadingModal, setLoadingModal] = useState(false);
  const [forms, setForms] = useState([]);
  const [createdForms, setCreatedForms] = useState([]);
  const [showForm, setShowForm] = useState({name: '', visible: false});
  const [idSelected, setIdSelected] = useState('');
  const [attachTypeEmp, setAttachTypeEmp] = useState(false);
  const [formName, setFormName] = useState({});
  const {values, setValues, useInput} = useForm({});

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [showSearch, setShowSearch] = useState(false);
  useEffect(() => {
    getData();
  }, [
    values?.limitperpage,
    props?.location?.search,
    props?.props?.location?.search,
    values?.ordering,
  ]);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const {pathname} = useLocation();
  const categoryBuilder =
    pathname === '/settings/setting-form-builder/setting-form-builder-category';

  const headerColumns = [
    {
      label: categoryBuilder ? 'Category Name' : 'Form Name',
      name: 'triage_created_by__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },

    {
      label: 'Created at',
      name: 'created_at',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
  ];

  const {
    setValue: setModalValueAttach,
    value: modalValueAttach,
    setTitle: setAttachTitle,
    fullBind: fullBindAttach,
  } = useModal(false);

  const deleteForm = async url => {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + getToken(),
      },
      body: {},
    });
    const json = await response.json();
    return json;
  };
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const getData = () => {
    const pageToSearch = getPageToSearchParam(
      props?.location?.search || props?.props?.location?.search,
      setCurrentPage
    );
    getFormBuilder(values, categoryBuilder, pageToSearch)
      .then(res => {
        setForms(res.results);
        setLoadingModal(false);
        setPages(Math.ceil(res.count && res.count / values.limitperpage));
      })
      .catch(error => {
        setLoadingModal(false);
      });
  };

  useEffect(() => {
    getData();
  }, [values.limitperpage, props?.props?.location?.search]);

  const handleAttachClick = (id, type) => {
    setIdSelected(id);
    setModalValueAttach(true);
    if (type === 'employee') {
      setAttachTypeEmp(true);
    } else {
      setAttachTypeEmp(false);
    }
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({...values});
  };
  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(props.props);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/form-builder',
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };

  const hitSearch = value => {
    values.search = value;
    getData();
  };

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };
  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteCategoryForm = async () => {
    await deleteCategoryFormBuilder(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');
    getData();
  };
  return (
    <div>
      {categoryReadPermission && (
        <div>
          <Header>
            <Title>
              {categoryBuilder ? 'Category Builder' : 'Form Builder'}
            </Title>
            <div></div>

            <SearchAddContainer className="d-flex align-items-center">
              <SearchIcon
                onClick={() => {
                  setShowSearch(!showSearch);
                }}>
                <GrSearch />
              </SearchIcon>

              <SearchContainer show={showSearch}>
                <Fade collapse when={showSearch}>
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    id="search"
                    label={'Search'}
                    onChange={e => {
                      keyPressed(e);
                    }}
                    value={searchKeyword}
                  />
                </Fade>
              </SearchContainer>
              {categoryCreatePermission && (
                <FloatingMobileButton
                  onClick={() => {
                    if (categoryBuilder) {
                      history.push({
                        pathname:
                          '/settings/setting-form-builder/setting-form-builder-category/add-category',
                        state: {editMode: false, detail: []},
                      });
                    } else {
                      history.push({
                        pathname: '/add-form',
                        state: {editMode: false, detail: []},
                      });
                    }
                  }}>
                  <FaPlus />
                  <label>{categoryBuilder ? 'NEW CATEGORY' : 'NEW FORM'}</label>
                </FloatingMobileButton>
              )}
            </SearchAddContainer>
          </Header>
          <ComponentDynamic loading={loadingModal}>
            {forms?.length === 0 ? (
              <h6 style={{textAlign: 'center', marginTop: 50}}>
                No data found
              </h6>
            ) : (
              <Table
                headerColumns={headerColumns}
                // callBackQuerySort={setQuerySort}
                headerPadding={false}
                handleAllCheck={() => {}}
                noNeed={true}
                handleAllSelectChange={() => {}}>
                <div>
                  {forms &&
                    forms.map((alertsData, index) => {
                      return (
                        <div>
                          <Row bgColor>
                            <ColsGrouper className="d-flex">
                              <Col
                                max_width={EX_LARGE_COL}
                                Center
                                onClick={() => {
                                  setCreatedForms(() => {
                                    if (categoryBuilder) {
                                      return JSON.parse(alertsData.json_data);
                                    } else {
                                      return JSON.parse(alertsData.jsonData);
                                    }
                                  });
                                  setShowForm({
                                    name: alertsData.name,
                                    visible: true,
                                  });
                                }}
                                // className="sm-hidden"
                                overlap={true}>
                                {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                {alertsData.name}
                              </Col>

                              <Col
                                max_width={EX_LARGE_COL}
                                Center
                                onClick={() => {
                                  setCreatedForms(() => {
                                    if (categoryBuilder) {
                                      return JSON.parse(alertsData.json_data);
                                    } else {
                                      return JSON.parse(alertsData.jsonData);
                                    }
                                  });
                                  setShowForm({
                                    name: alertsData.name,
                                    visible: true,
                                  });
                                }}
                                overlap={true}>
                                <label style={{marginLeft: '5rem'}}>
                                  {alertsData.updated_at
                                    ? moment(alertsData.updated_at).format(
                                        'YYYY-MM-DD HH:mm'
                                      )
                                    : moment(alertsData.created_at).format(
                                        'YYYY-MM-DD HH:mm'
                                      )}
                                </label>
                              </Col>
                            </ColsGrouper>
                            <Col
                              className="sm-hidden"
                              Center
                              Shrink
                              NoFlexGrow
                              Purple>
                              {categoryUpdatePermission && (
                                <IconWrapper
                                  onClick={() => {
                                    if (categoryBuilder) {
                                      history.push({
                                        pathname:
                                          '/settings/setting-form-builder/setting-form-builder-category/add-category',
                                        state: {
                                          editMode: true,
                                          detail: alertsData,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: '/add-form',
                                        state: {
                                          editMode: true,
                                          detail: alertsData,
                                        },
                                      });
                                    }
                                  }}>
                                  <MdEdit />
                                </IconWrapper>
                              )}
                            </Col>

                            <Col className="sm-hidden" Center Shrink NoFlexGrow>
                              {categoryDeletePermission && (
                                <IconWrapper
                                  onClick={() => {
                                    toDeleteClient(alertsData.id);
                                  }}>
                                  <RiDeleteBinLine />
                                </IconWrapper>
                              )}
                            </Col>
                          </Row>

                          <div className="mb-1" />
                        </div>
                      );
                    })}
                  <div className="mt-3">
                    <Pagination
                      totalPages={pages}
                      currentPage={currentPage}
                      values={values}
                      dropDownHandleSelect={dropDownHandleSelectPagination}
                      number={PAGE_SIZE_RENDER}
                      dataLength={forms?.length}
                      limitperpage={values.limitperpage}
                    />
                  </div>
                </div>
              </Table>
            )}
          </ComponentDynamic>

          {modalValueAttach && (
            <AttachClientEmp
              idSelected={idSelected}
              setModalValue={setModalValueAttach}
              fullBind={fullBindAttach}
              setTitle={setAttachTitle}
              attachTypeEmp={attachTypeEmp}
              //loadBranches={loadBranches}
            />
          )}

          <ShowFormModal
            children={<FormViewer lastDroppedItem={createdForms} title={showForm.name}/>}
            title={showForm.name}
            onClose={() => {
              setShowForm({name: '', visible: false});
            }}
            showModal={showForm.visible}
            max_width={'70%'}
            minWidthTitle="8.5rem"
            loading={false}
            data={[]}
          />
          <ModalDecision
            type="delete"
            title="Warning"
            body={
              'Are you sure you want to delete this Category Builder? \n DELETING the Category Builder will remove ALL records from database.'
            }
            onOk={handleDeleteCategoryForm}
            onCancel={() => {
              setIdSelected('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsFormBuilders;
