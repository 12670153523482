import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  getLeftButton,
  getRightButton,
  SpecializeCaseButton,
} from './IconsMethods';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PURPLE, SOFT_RED, GRAY_300 } from 'shared/styles/constants/colors';
import { Toast } from 'primereact/toast';
import { ON_EDIT } from 'utils/constants/settingsStates';
import { IconWrapper } from 'shared/styles/constants/tagsStyles';
import { RiDeleteBinLine, RiSaveLine } from 'react-icons/ri';
import { MdEdit, MdCheck, MdClose } from 'react-icons/md';
import InputColorPicker from '../InputColorPicker';
import DateSelector from '../DateSelector';
import { isDateValid } from 'shared/methods';
import DDMMDateSelector from '../DateSelector/DDMMDateSelector';
import {HideWeekDays} from "./styles"

const ItemList = ({
  dataValue,
  handleSubmit,
  toEditId,
  toDeleteId,
  cancelEdit,
  removeNewService,
  leftButtonIcon,
  rightButtonIcon,
  enabledInput,
  tryReSubmit,
  labelInputTitle,
  addOneDropdown,
  addCheckBox,
  addSecondDropdown,
  specializeCase,
  specializedMethods,
  addOneInput,
  addSecondInput,
  fieldAttributes,
  userStaff,
  update,
  create,
  deletePermission,

  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  createPrioritySettings,
  addColorPickerInput,
  addInput,
  isEditable
}) => {
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [buttonLeft, setButtonLeft] = useState(<div />);
  const [buttonRight, setButtonRight] = useState(<div />);
  const [specialButton, setSpecialButton] = useState(<div />);
  const [defaultChecks, setDefaultChecks] = useState(false);
  const [editButton, setEditButton] = useState(<div />);
  const toast = useRef();
  const mendatoryData = [
    'Availability',
    'Unavailability',
    'Absence',
    'Shift',
    'On Call',
    'Completed',
    'In Progress',
    'Not Started',
    'Car - Driver - Shift',
    'Joining Calendar',
    'Financial Calendar'
  ];
  useEffect(() => {
    if (enabledInput !== dataValue.id) {
      assignValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledInput]);

  useEffect(() => {
    setButtonLeft(
      getLeftButton(
        leftButtonIcon,
        executeHandleSubmit,
        toEditId,
        dataValue,
        enabledInput,
        // tryReSubmit,
        values
      )
    );

    setEditButton(
      getLeftButton(
        leftButtonIcon,
        //  disabled={true}
        toEditId,
        dataValue,
        enabledInput,
        tryReSubmit,
        values
      )
    );
    setButtonRight(
      getRightButton(
        rightButtonIcon,
        dataValue,
        removeNewService,
        executeCancel,
        toDeleteId,
        enabledInput,
        specializeCase,
        values
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftButtonIcon, rightButtonIcon, enabledInput, values]);

  useEffect(() => {
    assignValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  useEffect(() => {
    setSpecialButton(
      SpecializeCaseButton(specializeCase, specializedMethods, dataValue)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specializedMethods, values]);

  const assignValues = () => {
    addOneDropdown && addOneDropdown.careCategory
      ? addSecondDropdown
        ? setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          care_category:
            dataValue.task_type &&
            dataValue.task_type.care_category &&
            (typeof dataValue.task_type.care_category === 'object'
              ? dataValue.task_type.care_category.id
              : dataValue.task_type.care_category),
          task_type:
            dataValue.task_type &&
            (typeof dataValue.task_type === 'object'
              ? dataValue.task_type.id
              : dataValue.task_type),
          feedback: dataValue.feedback,
          colour_code: dataValue.colour_code || '',
          color_code: dataValue.color_code || '',
          client_to_be_billed: dataValue.client_to_be_billed,
          staff_to_be_paid: dataValue.staff_to_be_paid,
          count_deduction: dataValue.count_deduction,
          reapplication_frequency: dataValue.reapplication_frequency,
          reminder_warning: dataValue.reminder_warning,
          category_type: dataValue.category_type
        })
        : setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          care_category:
            dataValue.care_category &&
            (typeof dataValue.care_category === 'object'
              ? dataValue.care_category.id
              : dataValue.care_category),
          feedback: dataValue.feedback,
          colour_code: dataValue.colour_code || '',
          color_code: dataValue.color_code || '',
          pay_type: dataValue.pay_type || null,
          client_to_be_billed: dataValue.client_to_be_billed,
          staff_to_be_paid: dataValue.staff_to_be_paid,
          count_deduction: dataValue.count_deduction,
          reapplication_frequency: dataValue.reapplication_frequency,
          reminder_warning: dataValue.reminder_warning

        })
      : addSecondInput
        ? setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          absence_type: dataValue.absence_type || '',
          user_type_category: dataValue.user_type_category || '',
          colour_code: dataValue.colour_code || '',
          color_code: dataValue.color_code || '',
          final_price: dataValue.final_price,
          pay_type: dataValue.pay_type || null,
          client_to_be_billed: dataValue.client_to_be_billed,
          staff_to_be_paid: dataValue.staff_to_be_paid,
          count_deduction: dataValue.count_deduction,
          reapplication_frequency: dataValue.reapplication_frequency,
          reminder_warning: dataValue.reminder_warning
        })
        : setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          absence_type: dataValue.absence_type || '',
          user_type_category: dataValue.user_type_category || '',
          colour_code: dataValue.colour_code || '',
          color_code: dataValue.color_code || '',
          pay_type: dataValue.pay_type || null,
          client_to_be_billed: dataValue.client_to_be_billed,
          staff_to_be_paid: dataValue.staff_to_be_paid,
          count_deduction: dataValue.count_deduction,
          reapplication_frequency: dataValue.reapplication_frequency,
          reminder_warning: dataValue.reminder_warning,
          category_type: dataValue.category_type,
          start_date: dataValue.start_date || null,
          end_date: dataValue.end_date || null
        });
  };

  const executeHandleSubmit = async () => {
    if (errors.length >= 1) {
      setCheckRequires(true);
      return;
    }
    const response = await handleSubmit(values);

    if (response) {
      dataValue.id = response.id;
      dataValue.name = response.name;
      dataValue.is_staff = response.is_staff;
      if (addOneDropdown) {
        if (addSecondDropdown) {
          dataValue[addSecondDropdown.value] =
            response[addSecondDropdown.value];
        } else {
          dataValue[addOneDropdown.value] = response[addOneDropdown.value];
        }
      }
      assignValues();
    }
  };
  const executeCancel = () => {
    assignValues();
    cancelEdit();
  };

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
  };

  const handleCheckBox = val => {
    if (!(leftButtonIcon === ON_EDIT))
      if (val.target.checked) {
        values[addCheckBox.value] = true;
      } else {
        values[addCheckBox.value] = false;
      }
    setValues({ ...values });
  };

  const renderInput1 = props => {
    if (!(addOneDropdown && addOneDropdown.careCategory)) {
      return (
        <div className={'w-100'}>
          <InputLabelTop
            required
            disabled={((enabledInput !== dataValue.id) || mendatoryData.includes(values?.name))}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };

  const renderInput2 = props => {
    if (addOneDropdown && addOneDropdown.careCategory) {
      return (
        <div className={'w-100 ml-2'}>
          <InputLabelTop
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };
  const renderInput3 = props => {
    if (addSecondInput) {
      return (
        <div className={'w-100 ml-2'}>
          <InputLabelTop
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            label={addSecondInput.label}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };

  const renderInput = props => {
    if (addInput && addInput.length) {
      let fields = addInput.map((input) => {
        let isRequired = input.required ? 'isRequired' : ''
        let width = input.maxWidth ? input.maxWidth : ""
        if (input.type === 'text' || input.type === 'number') {
          return (
            <div className={'w-100 ml-2'} style={{ maxWidth: width }}>
              <InputLabelTop
                required={input.required || false}
                disabled={enabledInput !== dataValue.id}
                type={input.type}
                onKeyPress={input.onKeyPress}
                label={input.label}
                {...props(input.value, isRequired)}
                {...fieldAttributes}
              />
            </div>)
        }
        if (input.type === 'color_picker') {
          return (
            <div className={'w-100 ml-2'} style={{ maxWidth: width }}>
              <InputColorPicker
                required={input.required || false}
                disabled={enabledInput !== dataValue.id}
                type="text"
                id="colour_code"
                label={input.label}
                value={'#808080'}
                {...props(input.value, isRequired)}
              />
            </div>
          )
        }
        if (input.type === "dropdown") {
          return <div className='w-100 ml-2'>
            <DropdownSearchable
              placeHolder={input.label}
              required={input.required || false}
              {...props(input.value, isRequired)}
              disabled={enabledInput !== dataValue.id}
              options={input.options || []}
              selectedOption={
                values[input.value] ? values[input.value] : null
              }
              onChange={value =>
                dropDownHandleSelect({ [input.value]: value ? value : null })
              }
            />
          </div>
        }
        if (input.type === 'date') {
          return (
            <HideWeekDays>
            <div className={'w-100 ml-2'} style={{ maxWidth: width }}>
                  <DDMMDateSelector
                  format={input.format}
                  labelTop={input.label}
                  minDate={input.value === "end_date" ? values.start_date : input.value === "start_date" && `${new Date().getFullYear()}-01-01`}
                  maxDate={`${new Date().getFullYear()}-12-31`}
                  {...props(input.value, isRequired)}
                  isRequired={input.required || false}
                  disabled={enabledInput !== dataValue.id || mendatoryData.includes(values?.name)}
                />
            </div>
            </HideWeekDays>
          )
        }
      })
      return fields
    }
  }
  const handleToast = async event => {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: 'You can not edit this Data',
    });
  };
  const handleDeleteToast = async event => {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: 'You can not delete this Data',
    });
  };
console.log(values,"values")

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <div className={'d-flex pl-3 pr-3'}>
        {renderInput1({ ...useInput('name', 'isRequired') })}
        {!addOneDropdown ||
          !addOneDropdown.options ||
          addOneDropdown.options.length <= 0 ? null : (
          <AddOneDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addOneDropdown}
            specializeCase={specializeCase}
          />
        )}
        {!addSecondDropdown ||
          !addSecondDropdown.options ||
          addSecondDropdown.options.length <= 0 ? null : (
          <AddTwoDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addSecondDropdown}
            specializeCase={specializeCase}
          />
        )}
        {renderInput2({ ...useInput('name', 'isRequired') })}
        {renderInput3({ ...useInput('final_price') })}
        {/* {renderColorPickerInput(useInput)} */}
        {renderInput(useInput)}
        {!addOneInput ? null : (
          <AddOneInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addOneInput}
          />
        )}
        {/* {!addSecondInput ? null : (
          <AddSecondInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addSecondInput}
          />
        )} */}

        <div className={'ml-3'}>
          <>
            <div className="d-flex pt-1">
              {addCheckBox && (
                <div className={'d-flex align-items-center pr-4'}>
                  <p className="pr-2">Feedback?</p>
                  <input
                    type="checkbox"
                    checked={values[addCheckBox.value]}
                    id="flexCheckDefault"
                    onChange={val => {
                      handleCheckBox(val);
                    }}
                  />
                </div>
              )}
              {/* {filterAdmin ||filterSuperAdmin  || update&& ( */}

              {/* {mendatoryData.includes(values?.name) ? (
                <div className={'mr-2'}>
                  {' '}
                  <IconWrapper>
                    <MdEdit color={PURPLE} onClick={() => handleToast()} />
                  </IconWrapper>
                </div>
              ) : ( */}
              {isEditable && mendatoryData.includes(values?.name)
                ? <div className={'mr-2'}>
                  <IconWrapper onClick={handleToast}>
                    <MdEdit color={PURPLE} />
                  </IconWrapper></div>
                : <div className={'mr-2'}>{buttonLeft}</div>}
              {/* )} */}
              {/* ) } */}

              {/* )}  */}

              {/* commnented pbelow , ermissions of company>>priority delete  icon beacuse its also used in setting>>team -delete  icon  . beacuse of priority delete  icon permission . delete  icon wasnt visble in setting>> team  */}

              {/* {filterAdmin || filterSuperAdmin || deletePermission && ( */}
              {
                mendatoryData.includes(values?.name) ? (
                  <div>
                    {' '}
                    <IconWrapper>
                      <RiDeleteBinLine
                        color={SOFT_RED}
                        onClick={() => handleDeleteToast()}
                      />
                    </IconWrapper>
                  </div>
                ) : (
                  <div>{buttonRight}</div>
                )
                // /* )} */}
              }
              {/* {filterAdmin ||filterSuperAdmin && ( */}

              {specialButton}
              {/* //  )} */}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

function AddOneDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  return (
    <div className={'w-100 ml-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={valuesDropdown.options[valuesDropdown.choicesValue]}
        selectedOption={
          values[valuesDropdown.value]
            ? values[valuesDropdown.value]
            : values.absence_type || values.user_type_category
        }
        onChange={value =>
          dropDownHandleSelect({ [valuesDropdown.value]: value ? value : null })
        }
      />
    </div>
  );
}

function AddTwoDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  const taskTypeOptions = item => {
    if (item && values.care_category) {
      let options = item.filter(val => {
        if (val.care_category.id === values.care_category) {
          return { id: val.id, name: val.name };
        }
      });
      return options || [];
    } else {
      return [];
    }
  };
  return (
    <div className={'w-100 ml-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={taskTypeOptions(
          valuesDropdown.options[valuesDropdown.choicesValue]
        )}
        selectedOption={values[valuesDropdown.value]}
        onChange={value =>
          dropDownHandleSelect({ [valuesDropdown.value]: value ? value : null })
        }
      />
    </div>
  );
}

function AddOneInput({ useInput, enabledInput, dataValue, valuesInput }) {
  return (
    <div className={'w-100 ml-2'}>
      <InputLabelTop
        required={valuesInput.required}
        type="number"
        label={valuesInput.label}
        {...useInput(
          valuesInput.value,
          `${valuesInput.required ? 'isRequired' : ''}`
        )}
      />
    </div>
  );
}

// function AddSecondInput({useInput, enabledInput, dataValue, valuesInput}) {
//   debugger
//   return (
//     <div className={'w-100 ml-2'}>
//       <InputLabelTop
//         required
//         disabled={enabledInput !== dataValue.id}
//         type="text"
//         label={valuesInput.label}
//         {...useInput(
//           valuesInput.value,
//           `${valuesInput.required ? 'isRequired' : ''}`,
//         )}
//       />
//     </div>
//   );
// }
export default ItemList;
