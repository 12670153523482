import React, { useState, useEffect, Fragment } from 'react';
import LineContainer from 'components/SharedComponents/LineContainer';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  WeekContainer,
  WeekStyle,
  ButtonWeek,
  ErrorMessage,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { getChoices } from '../../../../utils/api/CoreApi';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DurationInput from 'components/SharedComponents/DurationInput';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import moment from 'moment';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ContainerResponsive } from '../../../DashboardComponents/Team/AddModal/styles';
import { InvalidRange } from 'utils/constants/messages';
import {
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { getAttachedRateCard, getRegularShiftById } from '../../../../utils/api/RotaShift'
import Conditiontravelmode from "./conditionTravelMode"
import { getDynamicPayRate } from 'utils/api/SchedulerApi';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { ErrorLabel } from 'components/SharedComponents/UpwardListDropdown/style';
const Shift = ({
  useInput,
  payRate,
  nameSection,
  setValues,
  values,
  choices,
  requireDayOfWeek,
  shiftIndex,
  deleteShift,
  setTimeError = () => { },
  timeError = [],
  shift,
  idSelected,
  setIdSelected,
  modalValue,
  TravelData,
  readOnly = false,
  handleDropAbsenceType,
  deletePermissionRegularShifts, editMode, selectedIdEmp, rateCards
}) => {
  const [durationValue, setDurationValue] = useState('');
  const [breakDurationValue, setBreakDurationValue] = useState('');
  const [timeSelected, setTimeSelected] = useState('');
  const [isFullDay, setIsFullDay] = useState(false);
  const [isOverNight, setIsOverNight] = useState(false);
  const [timeErrorMessage, setTimeErrorMessage] = useState('')
  const [shiftType, setShiftType] = useState([]);
  const [shiftTypeName, setShiftTypeName] = useState('');
  const [daysAdded, setDaysAdded] = useState([]);
  const [absence, setAbsence] = useState('')
  const [absenceType, setAbsenceType] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', selected: false },
    { day: 'TUE', selected: false },
    { day: 'WED', selected: false },
    { day: 'THU', selected: false },
    { day: 'FRI', selected: false },
    { day: 'SAT', selected: false },
    { day: 'SUN', selected: false },
  ]);
  const PAY_RATES = 'Pay Rates';
  const ON_CALL = 'On Call Rates';
  const OFFICE = 'Office Rates';
  const SHIFT = 'Shift Rates';
  const WAIT = 'Wait Rates';
  const TRAVEL_RATES = 'Travel Rates';
  const ABSENCE = 'Absence Rates';

  useEffect(() => {
    if (choices.regular_shift_type)
      setShiftType([{ id: 'new_shift', name: '+ New Shift' }, ...choices.regular_shift_type]);
  }, [choices.regular_shift_type])

  useEffect(() => {
    if (choices.absence_type && choices.absence_reason && values.absence_type) {
      let arr = choices.absence_reason.filter(val => val.absence_type === values.absence_type);
      setAbsenceType(arr);
    }
  }, [values.absence_type, choices])

  useEffect(() => {
    const reviewErrors = timeError;
    if (values.regularShifts.shifts[shiftIndex].is_overnight) {
      const start_time = moment(values.regularShifts.shifts[shiftIndex].start_time, 'h:mma')
      const end_time = moment(values.regularShifts.shifts[shiftIndex].end_time, 'h:mma')
      if (!end_time.isBefore(start_time)) {
        reviewErrors[shiftIndex] = true;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage(InvalidRange);
      } else {
        reviewErrors[shiftIndex] = false;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage('');
      }
    } else {
      const start_time = moment(values.regularShifts.shifts[shiftIndex].start_time, 'h:mma')
      const end_time = moment(values.regularShifts.shifts[shiftIndex].end_time, 'h:mma')
      if (end_time.isBefore(start_time)) {
        reviewErrors[shiftIndex] = true;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage(InvalidRange);
      } else {
        reviewErrors[shiftIndex] = false;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage('');
      }
      // reviewErrors[index] = false;
      // setTimeError([...reviewErrors]);
      // setTimeErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.regularShifts.shifts[shiftIndex].start_time,
  values.regularShifts.shifts[shiftIndex].end_time,
  values.regularShifts.shifts[shiftIndex].is_overnight]);

  useEffect(() => {
    if (values.regularShifts.shifts[shiftIndex].start_time && values.regularShifts.shifts[shiftIndex].end_time) {
      let start = moment(values.regularShifts.shifts[shiftIndex].start_time, 'HH:mm:ss a');
      let end = moment(values.regularShifts.shifts[shiftIndex].end_time, 'HH:mm:ss a');
      if (end > start) {
        setDurationValue(end.diff(start, 'seconds'));
        // values.regularShifts.shifts[shiftIndex].duration = `${hourFormat(end.diff(start, 'seconds'))}`;
        // setValues({ ...values });

      } else {
        let add_date = end.add(1, 'days')
        setDurationValue(add_date.diff(start, 'seconds'));
        // values.regularShifts.shifts[shiftIndex].duration = `${moment.duration(add_date.diff(start, 'seconds') * 100).hours()}`
      }

    } else {
      setDurationValue(0);
      // values.regularShifts.shifts[shiftIndex].duration = `0`
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.regularShifts.shifts[shiftIndex].start_time, values.regularShifts.shifts[shiftIndex].end_time]);

  const hourFormat = (num) => {
    var hours = Math.floor(num / 3600);
    var minutes = Math.floor((num - (hours * 3600)) / 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    return hours + ':' + minutes;
  }


  useEffect(() => {
    if (values.regularShifts.shifts[shiftIndex].break_start_time && values.regularShifts.shifts[shiftIndex].break_end_time) {
      let start = moment(values.regularShifts.shifts[shiftIndex].break_start_time, 'HH:mm:ss a');
      let end = moment(values.regularShifts.shifts[shiftIndex].break_end_time, 'HH:mm:ss a');
      if (end > start) {
        setBreakDurationValue(end.diff(start, 'seconds'));

      } else {
        let add_date = end.add(1, 'days')
        setBreakDurationValue(add_date.diff(start, 'seconds'));
      }

    } else {
      setBreakDurationValue(0);
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.regularShifts.shifts[shiftIndex].break_start_time, values.regularShifts.shifts[shiftIndex].break_end_time]);

  /*  useEffect(() => {
 
     values.regularShifts.shifts[shiftIndex].is_full_day = false;
     values.regularShifts.shifts[shiftIndex].is_overnight = false;
     values.regularShifts.shifts[shiftIndex].break_start_time = null;
     values.regularShifts.shifts[shiftIndex].break_end_time = null;
     values.regularShifts.shifts[shiftIndex].repeat_on= ' ';
     setValues({ ...values });
   }, []) */


  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
    if (!isFullDay) {
      values.regularShifts.shifts[shiftIndex].start_time = '00:00';
      values.regularShifts.shifts[shiftIndex].end_time = '23:59:59';
      values.regularShifts.shifts[shiftIndex].is_overnight = false;
      setIsOverNight(false);
    }
    values.regularShifts.shifts[shiftIndex].is_full_day = !isFullDay;
    setValues({ ...values });
  };

  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
    if (!isOverNight) {
      values.regularShifts.shifts[shiftIndex].is_full_day = false;
      setIsFullDay(false)
    }
    values.regularShifts.shifts[shiftIndex].is_overnight = !isOverNight;
    setValues({ ...values });
  }

  const handleClick = timeSelected => {
    setTimeSelected(null)
    setTimeSelected(timeSelected);
  };

  const handleDropDown = (item, nameItem) => {
    values.regularShifts.shifts[shiftIndex][nameItem] = item[nameItem];
    setValues({ ...values });

  };
  const getPayRateCards = async () => {
    let shift_type = shiftType && shiftType.find(shift => shift.id === values?.regularShifts?.shifts[shiftIndex].type)
    if (!values.regularShifts.branch_id || !shift_type || !values?.regularShifts?.start_date) {
      return;
    }
  
    try {
      const body = {
        employeeId: selectedIdEmp,
        branch: values.regularShifts.branch_id,
        pay_type: shift_type?.pay_type_name,
        start_date: values?.regularShifts?.start_date,
        end_date: values?.regularShifts?.end_date
      }
      const res = await getAttachedRateCard(body)
      if(res.status == true){
      values.regularShifts.shifts[shiftIndex].applicable_pay_rate_cards = res.data.rate_card
      setValues({...values})
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getPayRateCards()
  }, [shiftType,values.regularShifts.branch_id, values.regularShifts.shifts[shiftIndex].type, values?.regularShifts?.start_date, values?.regularShifts?.end_date])

  const handleDropDownS = (item, name, nameItem) => {
    if (nameItem === 'type') {
      // autoFillPayRate()
      values.regularShifts.shifts[shiftIndex].regular_shift_pay_rate_id = ''
    }
    values.regularShifts.shifts[shiftIndex][nameItem] = item[nameItem];
    setValues({ ...values });
    setShiftTypeName(name);
  };
  // const selectedDay = (day, indexWeek) => {

  //   values.regularShifts.shifts[shiftIndex].repeat_on[indexWeek].selected = !values.regularShifts.shifts[shiftIndex].repeat_on[indexWeek].selected;

  //   // setDaysOfWeek(daysOfWeek);
  //   setValues({...values})
  //   if (! values.regularShifts.shifts[shiftIndex].details_list) {
  //     values.regularShifts.shifts[shiftIndex].details_list = [];
  //   }

  //   values.regularShifts  &&  values.regularShifts.shifts[shiftIndex].details_list.forEach(detail => {
  //     if (detail.day_of_week - 1 === indexWeek) {
  //       detail.selected = values[nameSection][shiftIndex].daysofWeek[indexWeek].selected;
  //     }
  //   });

  //   // const addNew = values[nameSection][shiftIndex].details.find(
  //   //   detail => detail.day_of_week === values[nameSection][shiftIndex].daysofWeek[indexWeek].dayValue
  //   // );

  // //   // if (!addNew) {
  // //   //   values[nameSection][shiftIndex].details.push({
  // //   //     start:values.shiftValues[shiftIndex].start,
  // //   //     end:values.shiftValues[shiftIndex].end,
  // //   //     selected: true,
  // //   //     day_of_week: values[nameSection][shiftIndex].daysofWeek[indexWeek].dayValue,
  // //   //     is_available: values.shiftValues.is_available
  // //   //       ? values.shiftValues.is_available
  // //   //       : false,
  // //   //     id: '',

  // //   //   });
  //   // }
  //   setValues({...values});

  // };





  const selectedDay = (day, indexWeek) => {
    if (readOnly) {
      return;
    }

    const removeValue = daysAdded.indexOf(day);
    const weekDays = daysOfWeek;
    const addedDays = daysAdded;

    if (removeValue > -1) {
      daysAdded.splice(removeValue, 1);

      weekDays[indexWeek].selected = false;

      values.regularShifts.shifts[shiftIndex].repeat_on = addedDays.join();

      setDaysOfWeek(weekDays);
      setDaysAdded(daysAdded);
      setValues({ ...values });
      return;
    }

    addedDays.push(day);

    var addUniqs = addedDays.filter(function (item, indexUniq) {
      return addedDays.indexOf(item) === indexUniq;
    });

    weekDays[indexWeek].selected = true;

    values.regularShifts.shifts[shiftIndex].repeat_on = addedDays.join();

    setDaysOfWeek(weekDays);
    setDaysAdded(addUniqs);
    setValues({ ...values });
  };



  const handleAbsenceType = (value, label) => {
    setAbsence(label);
    handleDropAbsenceType({ absence_type: value ? value : null })
  }

  useEffect(() => {
    //if (idSelected) {
    const initial = [
      { day: 'MON', selected: false },
      { day: 'TUE', selected: false },
      { day: 'WED', selected: false },
      { day: 'THU', selected: false },
      { day: 'FRI', selected: false },
      { day: 'SAT', selected: false },
      { day: 'SUN', selected: false },
    ]
    const auxArray = [];
    const tempDays = [];
    if (values.regularShifts.shifts[shiftIndex] && values.regularShifts.shifts[shiftIndex].repeat_on) {
      const reviewStringVelue =
        String(values.regularShifts.shifts[shiftIndex].repeat_on).split(',');
      reviewStringVelue.forEach(dayValue => {
        daysOfWeek.forEach(dayItem => {
          if (dayItem.day.toString() === dayValue.toString()) {
            dayItem.selected = true;
            auxArray.push(dayValue.toString());

          }
        });
      });
    }
    var addUniqs = auxArray.filter(function (item, indexUniq) {
      return auxArray.indexOf(item) === indexUniq;
    });
    setDaysAdded(addUniqs);

    if (auxArray.length < 1) {
      setDaysOfWeek(initial)
    } else {
      initial.forEach((item) => {
        if (addUniqs.includes(item.day)) {
          item.selected = true;
          tempDays.push(item)
        } else {
          item.selected = false;
          tempDays.push(item)
        }
      })
      setDaysOfWeek(tempDays)
    }
    // setDaysOfWeek(addUniqs)
    // }
  }, [values.regularShifts.shifts[shiftIndex]])

  const handleTravelTime = (e) => {
    values.regularShifts.shifts[shiftIndex].travel_time = moment(e.target.value, "h:mm:ss A").format("HH:mm")
    setValues({ ...values })
  }

  const handleNotes = (e) => {
    values.regularShifts.shifts[shiftIndex].absence_notes = e.target.value;
    setValues({ ...values });
  }

  const switchTravelModeValue = (props, isRequired) => {
    let shift_type = shiftType && shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type) &&
      shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type).name
    switch (shift_type) {
      case 'Unavailability':
      case 'Absence':
      case 'Bank-Flexible':
      case 'On Call':
        return <Fragment />;
      default:
        return (
          <>
            <div className='w-100 mr-2'>
              <DropdownSearchable
                {...props}
                required={isRequired ? true : false}
                placeHolder={'Travel Method'}
                options={choices.travel_type}
                selectedOption={
                  values.regularShifts.shifts[shiftIndex].travel_mode
                    ? values.regularShifts.shifts[shiftIndex].travel_mode
                    : null
                }
                onChange={value =>
                  handleDropDown(
                    { travel_mode: value ? value : null },
                    'travel_mode'
                  )
                }
              />
            </div>
            <div className='w-100'
            >
              <TimeSelector
                id="extra_travel_time"
                timeFormat={"HH:mm"}
                dateFormat={"HH:mm"}
                placeholderText={'hh:mm'}
                locale={"sv-sv"}
                timeCaption={'Duration'}
                labelTop={'Extra Travel Time'}
                value={values.regularShifts.shifts[shiftIndex].travel_time}
                onChange={handleTravelTime}
              // {...useInput(`regularShifts.shifts[${shiftIndex}].travel_time`)}
              />
            </div>
          </>
        );
    }
  };


  const getRateCardOptionBranchWise = (shiftType, branchId) => {
    console.log({ shiftType, branchId }, "option");
    if (!shiftType || !branchId) return [];

    const rateCardData = rateCards.filter(el => el.rate_card_type !== "Bill Rates");
    const lowerShiftType = shiftType.toLowerCase();

    switch (true) {
      case lowerShiftType.includes("unavailability") || lowerShiftType.includes("absence"):
        return rateCardData.filter(data => data.rate_card_type.toLowerCase().includes("absence") && data.branch.includes(branchId));
      case lowerShiftType.includes("on call"):
        return rateCardData.filter(data => data.rate_card_type.toLowerCase().includes("on call") && data.branch.includes(branchId));
      case lowerShiftType.includes("travel"):
        return rateCardData.filter(data => data.rate_card_type.toLowerCase().includes("travel") && data.branch.includes(branchId));
      case lowerShiftType.includes("visits"):
        return rateCardData.filter(data => data.rate_card_type.toLowerCase().includes("pay rates") && data.branch.includes(branchId));
      case lowerShiftType.includes("availability") || lowerShiftType.includes("shift"):
        return rateCardData.filter(data => data.rate_card_type.toLowerCase().includes("shift") && data.branch.includes(branchId));
      case lowerShiftType.includes("office"):
        return rateCardData.filter(data =>
          data.rate_card_type.toLowerCase().includes("office")
          && !data.rate_card_type.toLowerCase().includes("absence")
          && !data.rate_card_type.toLowerCase().includes("on call")
          && !data.rate_card_type.toLowerCase().includes("travel")
          && !data.rate_card_type.toLowerCase().includes("pay rates")
          && !data.rate_card_type.toLowerCase().includes("shift")
          && data.branch.includes(branchId));
      default:
        return rateCardData.filter(data =>
          !data.rate_card_type.toLowerCase().includes("office")
          && !data.rate_card_type.toLowerCase().includes("absence")
          && !data.rate_card_type.toLowerCase().includes("on call")
          && !data.rate_card_type.toLowerCase().includes("travel")
          && !data.rate_card_type.toLowerCase().includes("pay rates")
          && !data.rate_card_type.toLowerCase().includes("shift")
          && data.branch.includes(branchId));
    }
  };


  const getPayRateOptions = (option, branchId) => {
    let arr = rateCards.filter(data => data.branch.includes(branchId) && data.rate_card_type !== "Bill Rates") || [];
    return arr;
  }
  const switchPayRateValue = props => {
    let shift_type = shiftType && shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type) &&
      shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type).name
    switch (shift_type) {
      // case 'Shift':
      // case 'Availability':
      // case 'Bank-Flexible':
      // case 'Unavailability':
      //   return <Fragment />;
      case 'On Call':
        debugger
        return (
          <>
            <FlexGrid3Item
              className="pl-0 pr-1"
            >
              <DropdownSearchable
                {...props}
                placeHolder={'Pay Rate'}
                options={getRateCardOptionBranchWise(shift_type, values.regularShifts?.branch_id)}
                selectedOption={
                  values.regularShifts.shifts[shiftIndex].regular_shift_pay_rate_id
                    ? values.regularShifts.shifts[shiftIndex].regular_shift_pay_rate_id
                    : null
                }
                onChange={value =>
                  handleDropDown(
                    { regular_shift_pay_rate_id: value ? value : null },
                    'regular_shift_pay_rate_id'
                  )
                }
              />
            </FlexGrid3Item>
          </>
        );
      default:
        debugger
        return (
          <>
            <FlexGrid3Item
              className="pl-0 pr-1"
            >
              <DropdownSearchable
                {...props}
                placeHolder={'Pay Rate'}
                options={getRateCardOptionBranchWise(shift_type, values.regularShifts?.branch_id)}
                selectedOption={
                  values.regularShifts.shifts[shiftIndex].regular_shift_pay_rate_id
                    ? values.regularShifts.shifts[shiftIndex].regular_shift_pay_rate_id
                    : null
                }
                onChange={value =>
                  handleDropDown(
                    { regular_shift_pay_rate_id: value ? value : null },
                    'regular_shift_pay_rate_id'
                  )
                }
              />
            </FlexGrid3Item>
          </>
        );
    }
  };
  console.log(TravelData, "options")
  const switchAbsenceValue = props => {
    let shift_type = shiftType && shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type) &&
      shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type).name
    switch (shift_type) {
      case 'Absence':
        return (
          <>
            <div>
              <FlexGrid3Container className="mt-2">
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable

                    disabled={readOnly}
                    placeHolder={'Absence Type'}
                    options={choices ? choices.absence_type : []}
                    selectedOptionsProps={values.absence_type}
                    selectedOption={values.absence_type}
                    onChange={(value, label) =>
                      handleAbsenceType(value, label)
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable

                    disabled={readOnly || !values.absence_type}
                    placeHolder={'Absence Reason'}
                    options={absenceType ? absenceType : []}
                    selectedOptionsProps={values.absence_reason}
                    selectedOption={values.absence_reason}
                    onChange={value =>
                      handleDropDown({ absence_reason: value ? value : null })
                    }
                  />
                </FlexGrid3Item>
                {absence === "Sickness" || (values.absence_type &&
                  choices.absence_type.find(type => type.id === values.absence_type) &&
                  choices.absence_type.find(type => type.id === values.absence_type).name === 'Sickness'
                )
                  ?
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                    <DropdownSearchable

                      disabled={readOnly}
                      placeHolder={'Sickness Reason'}
                      options={choices ? choices.sickness_reason : []}
                      selectedOptionsProps={values.sickness_reason}
                      selectedOption={values.sickness_reason}
                      onChange={value =>
                        handleDropDown({ sickness_reason: value ? value : null })
                      }
                    />
                  </FlexGrid3Item>
                  :
                  null
                }

              </FlexGrid3Container>
              <FlexGrid3Container className="mt-2">
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable

                    disabled={readOnly}
                    placeHolder={'Absence informed method'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={choices ? choices.absence_informed_method : []}
                    selectedOptionsProps={values.absence_informed_method}
                    selectedOption={values.absence_informed_method}
                    onChange={value =>
                      handleDropDown({ absence_informed_method: value ? value : null })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable

                    disabled={readOnly}
                    placeHolder={'Planned / Unplanned'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={choices ? choices.absence_planned : []}
                    selectedOptionsProps={values.absence_planned}
                    selectedOption={values.absence_planned}
                    onChange={value =>
                      handleDropDown({ absence_planned: value ? value : null })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable

                    disabled={readOnly}
                    placeHolder={'Paid / Unpaid / Paid at other rate'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={choices ? choices.absence_paid : []}
                    selectedOptionsProps={values.absence_paid}
                    selectedOption={values.absence_paid}
                    onChange={value =>
                      handleDropDown({ absence_paid: value ? value : null })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <InputLabelTop
                    disabled={readOnly}
                    type="text"
                    id="warning_message"
                    label={'Notes'}
                    onChange={(val) => handleNotes(val)}
                    value={values.absence_notes}
                  />
                </FlexGrid3Item>


              </FlexGrid3Container>
            </div>

          </>
        );
      default:
        return <Fragment />;
    }
  };

  const calculateEndValueProps = value => {
    let shiftname = shiftType && shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type) && shiftType.find(shift => shift.id === values.regularShifts.shifts[shiftIndex].type).name
    switch (shiftname) {
      case 'Availability':
        return 'isRequired';
      case 'Shift':
        return 'isRequired';
      default:
        return '';
    }
  };

  const switchShiftNameInput = props => {

    if (values.regularShifts.shifts[shiftIndex].type === "new_shift") {

      return (
        <div className="w-100 mr-2" style={{ marginTop: "10px" }}>
          <InputLabelTop
            required
            readOnly={false}
            type="text"
            id="regular_shift_name"
            label={'Shift Name'}
            {...props}
            maxlength={100}
          />
        </div>
      )
    } else {
      return (
        <Fragment />
      )
    }
  };

  const getRateCardName = (id) => {
    let match = rateCards && rateCards.find(el => el.id === id)

    return match
      ? match.name.charAt(0).toUpperCase() + match.name.slice(1)

      : ""
  }
  console.log(shiftType, values.regularShifts.shifts[shiftIndex].type, "shiftType")
  return (
    <div>
      <LineContainer className={'pl-2 pr-2 pt-2 pb-4'}>
        <WeekContainer>
          <div className={'formContainer'}>
            <div className="d-flex mt-2">
              <div
                className="w-100 mr-2"
              >
                <DropdownSearchable
                  disabled={!editMode}
                  required
                  {...useInput(`regularShifts.shifts[${shiftIndex}].type`, 'isRequired')}
                  placeHolder={'Shift Type'}
                  options={shiftType && shiftType.filter(shift => shift.name !== 'Absence')}
                  //  options={shiftType && shiftType}
                  selectedOption={
                    values.regularShifts.shifts[shiftIndex].type
                      ? values.regularShifts.shifts[shiftIndex].type
                      : null
                  }
                  onChange={(value, name) =>
                    handleDropDownS(
                      { type: value ? value : null }, name,
                      'type'
                    )
                  }
                />
                {switchShiftNameInput({ ...useInput(`regularShifts.shifts[${shiftIndex}].new_shift_name`) })}
              </div>
              {/* <div className='pr-1'> */}
              {switchTravelModeValue(
                {
                  ...useInput(`regularShifts.shifts[${shiftIndex}].travel_mode`,
                    calculateEndValueProps(
                      values.regularShifts.shifts[shiftIndex].type
                    )
                  ),
                }, calculateEndValueProps(
                  values.regularShifts.shifts[shiftIndex].type
                ))}

              {/* </div> */}


            </div>
            <FlexGrid3Container className="mt-2">
              {/* {switchPayRateValue(
                {
                  ...useInput(`regularShifts.shifts[${shiftIndex}].regular_shift_pay_rate_id`, ''
                  ),
                })} */}

              <FlexGrid3Item
                className="pr-1"
                onClick={() => handleClick(1)}
              >
                <TimeSelector

                  isRequired={values.regularShifts.shifts[shiftIndex].is_full_day ? false : true}
                  disabled={!editMode}
                  data-error={timeError[shiftIndex]}
                  errorMessage={timeErrorMessage}
                  // data-error={data_error}  , 'isRequired'
                  id="start_time_regular_shift"
                  labelTop={'Start time'}
                  {...useInput(`regularShifts.shifts[${shiftIndex}].start_time`, 'isRequired')}
                  //added below code  for time in 24hrs format 
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pl-1 pr-0 pr-md-1"
                onClick={() => handleClick(2)}
              >
                <TimeSelector
                  isRequired={values.regularShifts.shifts[shiftIndex].is_full_day ? false : true}
                  disabled={!editMode}
                  data-error={timeError[shiftIndex]}
                  errorMessage={timeErrorMessage}
                  // data-error={data_error} 'isRequired'
                  id="end_time_shift_pattern_shift"
                  labelTop={'End time'}
                  {...useInput(`regularShifts.shifts[${shiftIndex}].end_time`, 'isRequired')}
                  //added below code  for time in 24hrs format 
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pl-0 pr-1  mt-2"
              >
                <DateSelector
                  disabled={!editMode}
                  //   disabled={readOnly}
                  labelTop={'Effective date'}
                  {...useInput(`regularShifts.shifts[${shiftIndex}].effective_date`)}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pl-0 pr-1 mt-2"
              >
                <DurationInput

                  disabled={!editMode}
                  label_top={'Duration'}
                  value={durationValue}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pr-1 mt-2"
                onClick={() => handleClick(1)}
              >
                <TimeSelector
                  disabled={!editMode}
                  // isRequired={isFullDay ? false : true}
                  // disabled={isFullDay}
                  // data-error={data_error}
                  id="break_start_time_shift_pattern_shift"
                  labelTop={'Break Start time'}
                  {...useInput(`regularShifts.shifts[${shiftIndex}].break_start_time`)}
                  //added below code  for time in 24hrs format 
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2"
                onClick={() => handleClick(2)}
              >
                <TimeSelector
                  disabled={!editMode}
                  id="break_end_time_shift_pattern_shift"
                  labelTop={'Break End time'}
                  {...useInput(`regularShifts.shifts[${shiftIndex}].break_end_time`)}
                  //added below code  for time in 24hrs format 
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
                <DurationInput

                  disabled={!editMode}
                  label_top={'Break Duration'}
                  value={breakDurationValue}
                />
              </FlexGrid3Item>

              {/* <ContainerResponsive className="d-flex mt-3"> */}
              <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
                <div className='ml-3'>
                  <ToggleButton
                    disabled={!editMode}
                    label="Full Day"
                    useInput={useInput(`regularShifts.shifts[${shiftIndex}].is_full_day`)}
                    // useInput = {useInput(`regularShifts.shifts[${shiftIndex}].is_full_day`)}
                    id="is_full_day"
                    onClick={handelFullDay}
                  />
                </div>
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
                <div className='ml-3'>
                  <ToggleButton
                    disabled={!editMode}
                    label="Is Overnight"
                    // useInput={useInput(`${[nameSection]}[${shiftIndex}].is_overnight`)} 
                    useInput={useInput(`regularShifts.shifts[${shiftIndex}].is_overnight`)}
                    id="is_overnight"
                    onClick={handelOverNight}
                  />
                </div>
              </FlexGrid3Item>
            </FlexGrid3Container>
            { <div className='mt-2'>
              <TitleSection style={{ fontWeight: '450' }}>Pay Rate Cards</TitleSection>
              {values.regularShifts.shifts[shiftIndex]
                && values.regularShifts.shifts[shiftIndex]?.applicable_pay_rate_cards?.length ?
                <div>
                  <ol>{values.regularShifts.shifts[shiftIndex].applicable_pay_rate_cards.map(el => {
                    return (<li>{`${getRateCardName(el.rate_card_id)} - (${el.start_date} - ${el.end_date ?el.end_date : "Unset"})`}</li>)
                  })} 
                  </ol>
                </div>
                :
                <ErrorLabel className='ml-2'>
                  Please attach rate card for this employee.
                </ErrorLabel>}
            </div>}
            {/* </ContainerResponsive> */}
            {/* {switchAbsenceValue()}  */}
          </div>
          <div className={'formWeek d-block'}>
            <div
              className={'pt-2 ml-md-2 ml-0 text-center'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              Day of the week
            </div>

            {/* <WeekStyle   >
              {values.regularShifts.shifts && 
              values.regularShifts.shifts[shiftIndex].repeat_on.map((itemWeek, indexWeek) => {
                return (
                  <ButtonWeek
                
                    key={itemWeek.day}
                    disabled={!values.regularShifts.shifts[shiftIndex].start_time || !values.regularShifts.shifts[shiftIndex].end_time}
                    add_background={itemWeek.selected}
                    onClick={() => {
                     selectedDay(itemWeek.day, indexWeek);
                    }}>
                    <div>
                      <label>{itemWeek.day}</label>
                    </div>
                  </ButtonWeek>
                );
              })}
            </WeekStyle> */}

            {/* new code for week day s  below start */}

            <WeekStyle daysAdded={daysAdded}>
              {daysOfWeek.map((itemWeek, indexWeek) => {
                return (
                  <ButtonWeek
                    disabled={!editMode}
                    key={itemWeek.day}
                    add_background={itemWeek.selected}
                    //  disabled={!values.regularShifts.shifts[shiftIndex].start_time || !values.regularShifts.shifts[shiftIndex].end_time}
                    onClick={() => {
                      selectedDay(itemWeek.day, indexWeek);
                    }}>
                    <div>
                      <label>{itemWeek.day}</label>
                    </div>
                  </ButtonWeek>
                );
              })}
            </WeekStyle>

            {/* new code for week day s  above   end  */}


            <div style={{ marginTop: "90px", paddingLeft: "80%" }}>
              {deletePermissionRegularShifts && <IconWrapper
                onClick={() => {
                  deleteShift(shiftIndex);
                }}
              >
                <RiDeleteBinLine
                  disabled={!editMode}
                  color="red"
                  cursor='pointer'
                  width='10rem'
                />
              </IconWrapper>}

            </div>

            {/* {requireDayOfWeek.find(day=>day===shiftIndex) ? (
              <div
                className={'text-center'}
                style={{fontSize: '0.6rem', fontWeight: 'bold'}}>
                <ErrorMessage fontSize={'0.6rem'}>
                  <label className={'mt-2'}>Is required</label>
                </ErrorMessage>
              </div>
            ) : (
              <Fragment />
            )} */}
          </div>


        </WeekContainer>

      </LineContainer>

    </div>
  );
}

export default Shift;
