import React, { useState } from 'react';
import { ContextMenuContainer, BackdropContextMenu } from "./styles"

// Get selectedElId and displayMenu fn from this hook
export const useContextMenu = () => {
  const [selectedElId, setSelectedElId] = useState(null)

  // attach this as eventListener
  function displayMenu(e, arge, correctionFactor = { x: 0, y: 0 }) {
    e.preventDefault();
    const contextMenu = document.querySelector("#custom-context-menu")
    const contextMenuBackdrop = document.querySelector("#custom-context-menu-backdrop")
    if (!contextMenu) return false;
    setSelectedElId(arge)
    // check if context menu will extend the page on bottom
    if (contextMenu.offsetHeight + e.clientY + 100 > window.innerHeight) {
      contextMenu.style.bottom = "0px";
      contextMenu.style.top = "auto";
    }
    else {
      contextMenu.style.bottom = "auto"
      contextMenu.style.top = `${e.clientY + correctionFactor.y}px`;
    }
    // check if context menu will extend the page on right
    if (contextMenu.offsetHeight + e.clientX + 100 > window.innerWidth) {
      contextMenu.style.right = "0px";
      contextMenu.style.left = "auto";
    }
    else {
      contextMenu.style.left = `${e.clientX + correctionFactor.x}px`;
      contextMenu.style.right = "auto";
    }
    // display context menu with its backdrop and lock body scroll
    contextMenu.style.display = "block";
    contextMenuBackdrop.style.display = "block";
    document.body.style.overflowY = "hidden";
  }
  // use to clear selectedElId whenever its scope is done
  const clearSelectedElId = () => {
    console.log("cleared")
    setSelectedElId(null)

    const contextMenu = document.querySelector("#custom-context-menu")
    const contextMenuBackdrop = document.querySelector("#custom-context-menu-backdrop")
    // hide contextmenu with its backdrop and unlock body scroll again
    if (contextMenu)
      contextMenu.style.display = "none";
    if (contextMenuBackdrop)
      contextMenuBackdrop.style.display = "none";
    document.body.style.overflowY = "auto";

  }
  // this adds clearSelectedElId fn to the modal binds so the selectedElId is cleared when the user closes/cancels the modal opened via contextmenu
  const encloseModalBind = (myBind) => {
    return {
      ...myBind,
      onClose: () => {
        myBind.onClose();
        clearSelectedElId()
      },
      onCancel: () => {
        myBind.onCancel();
        clearSelectedElId()
      }
    }
  }

  return {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  }
}

// Add this to your component
const ContextMenu = ({ menuItems }) => {
  const { clearSelectedElId } = useContextMenu()
  return (
    <>
      <ContextMenuContainer id="custom-context-menu">
        <ul>
          {menuItems.map(item =>
            (!item.hasOwnProperty('premission') || item.premission) && (
              <li
                className={item.classNames ? item.classNames : ""}
                onClick={e => {
                  e.preventDefault();
                  item.onClick();
                  document.querySelector('#custom-context-menu').style.display =
                    'none';
                }}>
                {item.name}
              </li>
            )
           )}
        </ul>
      </ContextMenuContainer>
      <BackdropContextMenu
        id="custom-context-menu-backdrop"
        onMouseDown={() => {
          // clear selectElId when clicked outside of context-menu
          clearSelectedElId();
        }}
      />
    </>
  );
};

export default ContextMenu;
