export const scheduler_choices = ['pay_type', 'visit_type','visit_status', 'run_routes', 'job_category', 'branch', 'run_view', 'rate_card_list_by_type', 'employee_listing', 'client_listing', 'service_category', 'gender', 'visit_priority', 'travel_type', 'skill', 'competency_name', 'client_type', 'visit_type', 'service_destination', 'funder_source', 'cancellation_reason', 'job_title', 'user_list', 'care_category', 'care_task', 'task_type', 'task_status', 'notification', 'regular_shift_type'];
export const rota_shift_choices = ["rate_card_list_by_type", 'regular_shift_type', 'employee_listing', 'run_routes', 'job_category', 'branch', 'travel_type', 'run_view', 'absence_type', 'absence_reason', 'sickness_reason', 'absence_informed_method', 'absence_planned', 'absence_paid', 'pay_type', 'branch'];
export const list_of_client_choices = ['client_status', 'run_routes', 'branch', 'client_type'];
export const personal_detail_client_choices = ['client_status','run_routes', 'branch', 'country', 'referral_status', 'roles', 'alert_privacy', 'title', 'pronoun_category', 'gender', 'client_status', 'marital_status', 'agebanding', 'client_type'];
export const client_preferences_choices = ['employee_listing', 'gender', 'skill', 'competency_name', 'nationality', 'ethnicity', 'religion', 'language'];
export const important_contact_choices = ['roleList', 'roles', 'title', 'nok_relationship', 'care_contact_type', 'contact_status'];
export const client_health_details_choices = ['all_medical_condition', 'allergy_source', 'dnacpr', 'tep_in_place', 'infection_control', 'care_level', 'specific_communication_needs', 'rota_rag', 'client_vra', 'client_group', 'care_needs_category', 'care_dependency_level', 'regulated_services', 'user_type_category', 'living_circumstances', 'client_mobility', 'disability', 'impairment_sensory_loss', 'user_type_description', 'allergy_type', 'severity', 'sign_symptoms', 'country', 'vaccination_exemption_type', 'flu_vaccination']
export const client_service_contract_choices = ['client_listing', 'medication_dose_timing', 'care_task', 'group_task', 'skill', 'competency_name', 'funder_source', 'service_destination', 'Invoice_type', 'bill_rate_model', 'billing_duration_type', 'billing_duration_source', 'bill_time_unit', 'Billing_patterns', 'Billing_terms', 'Payment_method', 'pay_rate_model', 'pay_duration_type', 'pay_duration_source', 'pay_time_unit', 'client_type', 'service_category', 'visit_type', 'care_group', 'visit_priority', 'ppe_category', 'service_destination', 'job_title', 'skill', 'competency_name', 'gender', 'branch', 'country', 'Invoice_type', 'core_services'];
export const client_triage_choices = ['client_listing', 'employee_listing', 'alert_severity', 'alert_status', 'alert_type', 'alert_label', 'user_list', 'alert_privacy', 'user_list'];
export const client_task_modal = ['care_task', 'task_type', 'care_category'];
export const client_visit_care_notes = ['employee_listing', 'alert_label', 'alert_privacy', 'user_list', 'visit_type', 'service_category', 'medication_review_status','client_listing'];
export const visit_task_choices = ['care_task', 'task_type', 'care_category', 'support_level', 'group_task']
export const client_Planned_task = ['care_task', 'group_task', 'task_type', 'care_category', 'support_level'];
export const client_med_pres_choices = ['care_task', 'group_task', 'medication_name', 'medication_type', 'expiry_medication_reason', 'medication_route', 'medication_form', 'medication_assistance', 'administration_reason', 'administration_type', 'medication_dose', 'medication_status', 'medication_dose_timing', 'medication_review_status', 'funder_source', 'branch', 'country', 'Invoice_type', 'Billing_patterns', 'Billing_terms', 'Payment_method', 'bill_rate_model', 'billing_duration_type', 'billing_duration_source', 'bill_time_unit'];
export const client_med_chart_choices = ['client_listing', 'employee_listing', 'medication_dose', 'medication_status'];
export const client_med_stock_audit = ['medication_name', 'user_list', 'funder_source', 'branch', 'country', 'Invoice_type', 'Billing_patterns', 'Billing_terms', 'Payment_method', 'bill_rate_model', 'billing_duration_type', 'billing_duration_source', 'bill_time_unit']
export const team_absence_overview_choices = ['medication_type','branch',"employee_status" ,'absence_reason', 'absence_type', 'absence_paid', 'absence_planned', 'absence_time_unit', 'employee_listing','calendar_type']
export const team_history_overview_choices = ['absence_reason', 'absence_type', 'absence_paid', 'absence_planned', 'absence_time_unit', 'employee_listing', 'branch', 'run_routes', 'absence_status','user_list']

export const client_contract_visit = ['run_routes', 'branch', 'service_category', 'client_status', 'funder_source', 'client_listing', 'skill', 'client_type', 'care_group', 'visit_type', 'visit_priority', 'ppe_category', 'service_destination', 'job_title', 'skill', 'competency_name', 'gender']
export const client_cancelled_visits_choices = ['run_routes', 'branch', 'client_listing', 'employee_listing', 'cancellation_reason', 'notification']
export const client_offer_visits = ['run_routes', 'branch', 'client_listing', 'employee_listing', 'cancellation_reason', 'notification']
export const client_hand_bag_choices = ['branch', 'employee_listing'];
export const setting_evvListing_choices = ['branch', 'employee_listing']
export const client_swap_visit_choices = ['branch', 'employee_listing', 'regular_shift_type'];
export const calendar_visit_audit = ['branch', 'employee_listing', 'client_listing', 'visit_status'];
////employee_status,run_routes,branch,employment_type,job_title,employee_category
// client_status,run_routes,branch,client_type
export const list_of_employees_choices = ['employee_status', 'run_routes', 'branch', 'employment_type', 'job_title', 'employee_category']
export const personal_detail_emp_choices = ['employee_listing','roles', 'alert_privacy', 'contact_method', 'title', 'gender', 'pronoun_category', 'employee_status', 'marital_status', 'branch', 'client_listing'];
export const job_detail_emp_choices = ['Billing_patterns', 'employee_listing', 'job_category', 'job_level', 'job_title', 'job_role', 'nurse_type', 'job_role_specialist_area', 'employee_category', 'employment_type', 'skill_banding', 'health_safety_role', 'run_routes', 'job_title', 'probation_term', 'recruitment_source', 'applicant_source', 'recruitment_decision', 'employer_notice_period', 'leaver_reason', 'gender', 'run_view', 'run_view', 'religion', 'travel_type', 'animal', 'client_listing','calendar_type'];
export const compliance_emp_choices = ['reference_status', 'visa_status', 'country', 'application_type', 'country', 'vaccination_exemption_type', 'flu_vaccination', 'country']
export const employee_details_choices = ['all_medical_condition', 'allergy_source', 'title', 'nok_relationship', 'nationality', 'country', 'ethnicity', 'ethnicity_description', 'religion', 'common_core_confirmation', 'language', 'disability', 'allergy_type', 'severity', 'sign_symptoms'];
export const skills_emp_choices = ['care_certificate', 'common_core_confirmation', 'social_care_qualification', 'accredited_training', 'skill_category', 'skill', 'training_type', 'skill_status', 'competency_name', 'qualification_level', 'qualification_type', 'qualification_status', 'qualification_name']
export const regular_shift_emp_choices = ['employee_listing', 'regular_shift_type', 'absence_type', 'absence_reason', 'travel_type', 'sickness_reason', 'absence_informed_method', 'absence_planned', 'absence_paid', 'branch'];
export const emp_triage_choices = ['client_listing', 'employee_listing', 'alert_severity', 'alert_status', 'alert_type', 'alert_label', 'user_list', 'alert_privacy', 'user_list'];
export const emp_expenses_choices = ['pay_adjustment_type', 'pay_adjustment', "client_listing"];
export const emp_hr_task_choices = ['hr_task'];

export const client_expense_choices = ['pay_adjustment_type', 'pay_adjustment', 'employee_listing']
export const team_route_mapping_choices = ['client_listing', 'run_routes', 'branch', 'run_view'];
export const client_task_choices = ['user_list','branch', 'employee_listing', 'care_category', 'care_task', 'task_type', 'task_status', 'support_level'];

export const bradford_choices = ['employee_listing', 'branch']


export const calendar_regular_visit_choices = ['client_listing', 'employee_listing', 'job_title', 'gender', 'run_view', 'branch', 'skill', 'competency_name'];
export const calendar_medication_choices = ['medication_dose_timing', 'client_listing', 'employee_listing', 'medication_dose', 'medication_type', 'medication_name', 'medication_status', 'medication_route', 'medication_form', 'medication_assistance', 'administration_reason', 'administration_type', 'medication_error_type', 'medication_review_status']
export const calendar_evv_choices = ['clock_out_reason', 'visit_priority', 'service_category', 'visit_type', 'visit_status', 'run_routes', 'branch', 'client_listing', 'employee_listing', 'clock_out_reason', 'visit_priority', 'verificationStatus'];
export const calendar_esv_choices = ['visit_priority', 'visit_type', 'service_category', 'shift_status', 'run_routes', 'branch', 'client_listing', 'employee_listing', 'verificationStatus'];
export const calendar_shift_pattern = ['regular_shift_type', 'absence_reason', 'absence_type', 'travel_type', 'sickness_reason', 'absence_informed_method', 'absence_planned', 'absence_paid'];

export const setting_company_branch_choices = ['company', 'branch', "client_listing", "employee_listing"];
export const setting_calendar_choices = ['date_format', 'time_format'];
export const setting_client_funder_choices = ['funder_source', 'branch', 'service_destination', 'Invoice_type', 'bill_rate_model', 'billing_duration_type', 'billing_duration_source', 'bill_time_unit', 'Billing_patterns', 'Billing_terms', 'Payment_method', 'pay_rate_model', 'pay_duration_type', 'pay_duration_source', 'pay_time_unit'];
export const setting_client_health_choices = ['user_type_category']
export const setting_client_important_contact_choices = ['care_contact_type', 'contact_status', 'title']
export const setting_team_choices = ['absence_type', 'skill_category', 'branch', 'competency_name', 'skill', 'pay_type'];
export const setting_user_choices = ['roles', 'user_type']
export const setting_company_public_holiday = ['country', 'rate_code', 'region', 'branch']
export const calender_esv_shift = ['regular_shift_type', 'travel_type']
export const setting_evv_choices = ['travel_type', 'visit_status', 'branch', 'travelAttributes'];
export const setting_esv_choices = ['travel_type', 'shift_status', 'regular_shift_type', 'travelAttributes'];
export const settingEvv_Listing_choices = ['travel_type', 'visit_status', 'branch',];

export const setting_finance_choices = ['Invoice_type', 'Billing_terms', 'bill_time_unit', 'Payment_method', 'Billing_patterns', 'regular_shift_type']
export const setting_rate_card_choices = ['travel_type', 'branch', 'service_category', 'rate_card_type', 'regular_shift_type', 'wages_rate_sources', 'country', 'rate_code', 'region', 'absence_type', 'absence_paid', 'bill_time_unit','pay_type']

export const setting_organization_choices = ['bill_time_unit']
export const setting_algo = ['job_category']
export const formdata_choice = ['assessment']


//

export const dashboard_choices = ['kpi', "client_listing", "employee_listing", 'branch'];
export const triage_choices = ["client_listing", "employee_listing", "alert_severity", "alert_status", "alert_type", "alert_label", "user_list", "alert_privacy", "user_list", 'alert_task_status', 'alert_task']
export const add_triage_choices = ['user_list']
export const insights_choices = ["branch","client_listing",'employee_listing','user_list',"medication_review_status"]
export const form_builder_choices = ['employee_listing', 'client_listing']
export const attachClientEmp_choices = ['employee_listing', 'client_listing']
export const reports_team_choices = ['run_routes', 'employee_status', 'branch', 'employment_type', 'job_title']
export const team_rota_choices = ['branch']
export const reports_client_choices = ['run_routes', 'client_status', 'branch']
export const client_rota_choices = ['branch']
export const reports_clientTask_choices = ['client_listing', 'branch'];
export const reports_service_contract = ['client_listing', 'branch']
export const reports_kpi_choices = ['kpi', 'branch']
export const bill_summary_choices = ['Billing_patterns', 'employee_listing', 'employee_status', 'client_listing', 'client_listing', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'employee_status', 'travel_type', 'client_status', 'funder_source']
export const finance_billDetails_choices = ['Billing_patterns', 'employee_listing', 'employee_status', 'client_listing', 'client_listing', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'employee_status', 'travel_type', 'client_status', 'funder_source']
export const bill_compare_choices = ['kpi', 'employee_listing', 'employee_status', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category']
export const billing_visit_choices = ['employee_listing', 'employee_status', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type']
export const billing_travel_choices = ['employee_listing', 'employee_status', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'travel_type']
export const billing_payrollDetails_choices = ['regular_shift_type',"absence_status",'Billing_patterns', 'employee_listing', 'employee_status', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'travel_type', 'travelCode']
export const billing_payrollVisit_choices = ['Billing_patterns', 'employee_listing', 'employee_status', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'travel_type', 'travelCode', 'employee_listing', 'employee_status']
export const BRAD_FORD_CHOICES = ['branch', 'absence_type']
export const billing_payrollsummary_choices = ['Billing_patterns', 'employee_listing', 'client_listing', 'client_status', 'funder_source', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'travel_type', 'travelCode']


export const finance_billSummary_choices = ['employee_listing', 'client_listing', 'client_listing', 'visit_status', 'service_category', 'branch', 'rate_card_list_by_type', 'employee_status', 'travel_type', 'client_status', 'funder_source']
export const billing_payroll_choices = ['client_listing', 'employee_status', 'visit_status', 'client_status', 'service_category', 'branch']

export const email_setting_choices = ['user_list', 'branch']
// Client body map
export const client_body_map_choices = ['user_list']

export const visit_wise_expense_choices = ['pay_adjustment_type', 'pay_adjustment', 'employee_listing']

export const employee_average_hours_choices = ['kpi','branch']
export const branch_setting_absence_overview = ['visit_status', 'absence_reason', 'absence_type', 'absence_paid', 'absence_planned', 'absence_time_unit', 'service_category',"calendar_type"]
export const team_skill_matrix_choices = ['employee_listing', 'skill', 'hr_task', 'employee_listing', 'branch', 'skill_status', 'skill_category']
export const team_competency_matrix_chioces = ['competency_name', 'employee_listing', 'branch']

export const invoice_module = ['client_listing']

export const event_task_choices = ['branch', 'hr_task']
export const repeate_task_choices = ['hr_task', 'employee_listing', 'client_listing']

export const employee_hr_task_matrix = ['hr_task', 'employee_listing', 'branch']
export const client_hr_task_matrix = ['hr_task', 'client_listing', 'branch']
export const payroll_non_commute_choice = ['user_list']
export const company_license = ['company']
export const company_setting_choices = ['company', 'branch']
export const location_details_choices = ['branch', "client_status", 'alert_privacy', 'run_routes', 'contact_method']
export const list_of_location_choices = ["client_status", 'run_routes','branch','core_services']
export const employee_care_notes = ['employee_listing', 'alert_label', 'alert_privacy', 'user_list', 'service_category'];
export const privacy_setting_choices = ['alert_privacy']
export const visit_popup_care_notes =  ['employee_listing', 'alert_label', 'alert_privacy', 'user_list', 'visit_type', 'service_category', 'medication_review_status'];
export const shift_rate_card = ['pay_type','rate_card_type', 'wages_rate_sources','regular_shift_type']
export const employee_rates_choices = ['employee_listing', 'job_category', 'job_title', 'job_role',  'run_routes', 'job_title', 'travel_type', 'client_listing','branch'];
export const settings_accrual_leaves_choices = ['accrual_hours_type']
export const visit_care_forms_choices = ['form_builder_list','user_list']