import {
  EX_SMALL_COL4,
  LARGE_COL,
  P_W_Zero,
  SMALL_COL,
  VERY_EX_SMALL_COL_CHECKBOX
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    checkbox: true,
    custum_column: false,
    columnSelected: false,
    removeCusCol:true,
    name: 'checkbox',
    label: 1,
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Employee Branch',
    name: 'branch_name',
    status: 0,
    custum_column: true,
    id: 'branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Shift Branch',
    name: 'shift_branch',
    status: 0,
    custum_column: true,
    id: 'shift_branch',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  
  {
    label: 'Emp Name',
    name: 'employee_name',
    status: 0,
    custum_column: true,
    id: 'employee_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Shift Type',
    name: 'shift_type',
    status: 0,
    custum_column: true,
    id: 'shift_type',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Type',
    name: 'pay_type',
    status: 0,
    custum_column: true,
    id: 'pay_type',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Absence Type',
    name: 'absence_type',
    status: 0,
    custum_column: true,
    id: 'absence_type',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start Date',
    name: 'start_date',
    status: 0,
    custum_column: true,
    id: 'start_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'end_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Start Time',
    name: 'schedule_start_date',
    status: 0,
    custum_column: true,
    id: 'schedule_start_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch End Time',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'schedule_end_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Sch Duration',
    name: 'schedule_duration_hrs',
    status: 0,
    custum_column: true,
    id: 'schedule_duration_hrs',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Actual Start Time',
  //   name: 'act_start_time',
  //   status: 0,
  //   custum_column: true,
  //   id: 'actual_start_time',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'Actual End Time',
  //   name: 'act_end_time',
  //   status: 0,
  //   custum_column: true,
  //   id: 'actual_end_time',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'Actual Hrs',
  //   name: 'act_duration',
  //   status: 0,
  //   custum_column: true,
  //   id: 'actual_duration_hrs',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },

  {
    label: 'Absence Status',
    name: 'shift_status',
    status: 0,
    custum_column: true,
    id: 'shift_status',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Shift Id',
    name: 'shift_id',
    status: 0,
    custum_column: true,
    id: 'shift_id',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Pattern',
    name: 'pay_pattern',
    status: 0,
    id: 'pay_pattern',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  // {
  //   label: 'ESV Shift Confirmation',
  //   name: 'esv_shift_confirmation',
  //   status: 0,
  //   custum_column: true,
  //   id: 'esv_shift_confirmation',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  //   overlap: true
  // },
  {
    label: 'Employee Rate Card',
    name: 'employee_rate_card',
    status: 0,
    custum_column: true,
    id: 'employee_rate_card',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Pay Rate',
    name: 'schedule_pay_rate',
    status: 0,
    custum_column: true,
    id: 'schedule_pay_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Act Pay Rate',
  //   name: 'actual_pay_rate',
  //   status: 0,
  //   custum_column: true,
  //   id: 'actual_pay_rate',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Sch Pay Amt',
    name: 'schedule_pay_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_pay_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Act Pay Amt',
  //   name: 'actual_pay_amount',
  //   status: 0,
  //   custum_column: true,
  //   id: 'actual_pay_amount',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Absence Pay Confirmation',
    name: 'shift_pay_confirmation',
    status: 0,
    custum_column: true,
    id: 'shift_pay_confirmation',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Cancelled Pay Percentage',
  //   name: 'cancelled_pay_percentage',
  //   status: 0,
  //   custum_column: true,
  //   id: 'cancelled_pay_percentage',
  //   columnSelected: false,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
];


export const headerColumnsSelector = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Shift Type',
    id: 'shift_type',
  },
  {
    name: 'Absence Type',
    id: 'absence_type',
  },
  {
    name: 'Absence Paid/Unpaid',
    id: 'absence_paid_unpaid',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Employee Rate Card',
    id: 'employee_rate_card',
  },
  {
    name: 'Sch Pay Rate',
    id: 'schedule_pay_rate',
  },
  {
    name: 'Act Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Pay Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Pay Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Sch Manual Pay Amt',
    id: 'schedule_manual_pay_amount',
  },
  {
    name: 'Act Manual Pay Amt',
    id: 'actual_manual_pay_amount',
  },
  {
    name: 'Absence Pay Confirmation',
    id: 'absence_pay_confirmation',
  }
];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Shift Type',
    id: 'shift_type',
  },
  {
    name: 'Absence Type',
    id: 'absence_type',
  },
  {
    name: 'Absence Paid/Unpaid',
    id: 'absence_paid_unpaid',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Employee Rate Card',
    id: 'employee_rate_card',
  },
  {
    name: 'Sch Pay Rate',
    id: 'schedule_pay_rate',
  },
  {
    name: 'Act Pay Rate',
    id: 'actual_pay_rate',
  },
  {
    name: 'Sch Pay Amt',
    id: 'schedule_pay_amount',
  },
  {
    name: 'Act Pay Amt',
    id: 'actual_pay_amount',
  },
  {
    name: 'Sch Manual Pay Amt',
    id: 'schedule_manual_pay_amount',
  },
  {
    name: 'Act Manual Pay Amt',
    id: 'actual_manual_pay_amount',
  },
  {
    name: 'Absence Pay Confirmation',
    id: 'absence_pay_confirmation',
  }
];
