import styled from 'styled-components';
import {AnimationStyle} from 'shared/styles/buttons';
import {
  ERR_COLOR,
  ERR_COLOR_800,
  PURPLE,
  PURPLE_800,
  PURPLE_GRAY_500,
  WHITE,
} from 'shared/styles/constants/colors';

export const CancelButton = styled.button`
  ${AnimationStyle}

  border-radius: 30px;
  background-color: ${PURPLE_GRAY_500};
  color: ${WHITE};
  padding: 0.5rem 2rem;
  font-weight: 500;

  &:hover {
    background-color: ${WHITE};
    color: ${PURPLE_GRAY_500};
  }
`;

export const Body = styled.div`
  white-space: pre-line;
`;

export const SubBody = styled.div`
  white-space: pre-line;
  font-size: 0.875rem;
  margin-top: 1rem;
`;

const handleColorType = type => {
  switch (type) {
    case 'delete':
      return ERR_COLOR;
    default:
      return PURPLE;
  }
};

const handleColorTypeHover = type => {
  switch (type) {
    case 'delete':
      return ERR_COLOR_800;
    default:
      return PURPLE_800;
  }
};

export const PrimaryButtonModal = styled.button`
  ${AnimationStyle}
  color: ${WHITE};
  border: 0;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  font-weight: 500;
  background-color: ${({ typeButton }) => handleColorType(typeButton)};
  cursor: pointer;

  &:hover {
    background-color: ${({ typeButton }) => handleColorTypeHover(typeButton)};
  }
  
  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${PURPLE_GRAY_500};
    cursor: not-allowed;
  }

  svg {
    font-size: 1.2rem;
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
`;

