import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorMessage, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { useForm } from 'hooks/FormHook';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { deleteClientFormBuilder, deleteClientTadks, editcareForumClient, getClientFormBuilder, getClientTasks, setClientCareForms } from 'utils/api/ClientApi';
import { useModal } from 'hooks/ModalHook';
import { getPageToSearchParam } from 'shared/methods';
import { PrimaryButtonForm } from 'shared/styles/buttons';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import { Toast } from 'primereact/toast';

import {
  EX_LARGE_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';

import { FloatingMobileButton } from 'shared/styles/buttons';
import { HeaderVisits } from 'shared/styles/constants/tagsStyles';
import { AppContext } from 'context';
import TaskPopUp from './TaskPopUp';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import moment from 'moment';
import { PURPLE, SOFT_RED } from 'shared/styles/constants/colors';
import FormViewer from "components/SharedComponents/FormViewer"
import ShowFormModal from './showFormModal/showFormModal';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { BsFolderPlus } from 'react-icons/bs';
import AddCareForm from './AddCareForm';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

export const headerColumns = [
  {
    label: 'Form Name',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start Date',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Created By',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Linked Form Visit',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
];

const CareForum = ({
  // cliId,
  selectedClientId,
  contextChoices,
  location, 
  name,
  handleChangeChoices,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission,
  contextChoicesMap
}) => {
  const { handleGetChoices } = useContext(AppContext);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({
    validation: false,
    limitperpage: PAGE_SIZE_RENDER,
    ordering: 'requirement_visit_wise__visit__start_date'
  });
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const { setValue: setModalAddFormValue, bind: fullBindAddFormModal, value: modalAddFromValue } =
    useModal(false);

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const toast = useRef();
  const history = useHistory();
  const [taskData, setTaskData] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [simplePagination] = useState(true);
  const [createdForms, setCreatedForms] = useState([])
  const [showForm, setShowForm] = useState({ name: "", visible: false, id: null})

  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [errorMessage, setErrorMessage] = useState("");
  const [textStatus, setTextStatus] = useState(SAVE);
  const [formChoices, setFormChoices] = useState([])
  

  const [formData, setFormData] = useState(null)
  const [showError, setShowError] = useState(false);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  useEffect(() => {
    handleGetChoices(['form_builder_list',"user_list"])
  },[])
  useEffect(() => {
    loadCareForum()
  }, [
    history.location.search,
    values.limitperpage,
    values.ordering]);

  const loadCareForum = () => {
    values.client_id = selectedClientId;
    setValues({ ...values });
    const pageToSearch = getPageToSearchParam(
      history.location.search,
      setCurrentPage
    );

    getClientFormBuilder({ ...values, ...pageToSearch }).then(res => {
      // setTaskData(res.data);
      if (res.results) {
        setTaskData(res.results || []);
        setPages(Math.ceil(res.count / values.limitperpage));
      }
    });


    setValues({ ...values });
  }

  useEffect(() => {
    setFormChoices(contextChoices?.form_builder_list)
  },[contextChoices?.form_builder_list])

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  const toDeleteBranch = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteBranch = async () => {
    let response = await deleteClientFormBuilder(idSelected);

    if (response.status === 'SUCCESS') {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      loadCareForum()
      setModalDeleteValue(false);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
      setModalDeleteValue(false);
      setIdSelected('');
    }
  };

  const editClientform = async () => {

    let validationError = false;

    createdForms.forEach((form_val) => {
      if (form_val.required) {
        if (!form_val.value) {
          validationError = true
        }
      }
    })
    if (validationError) {
      setShowError(validationError)
      return
    }

    setShowError(false)



    const body = {
      "id": formData?.id,
      "start_date": formData?.start_date,
      "end_date": formData?.end_date,
      "client": selectedClientId,
      "form": formData?.form,
      "name": formData?.name,
      "json_data": JSON.stringify(createdForms)
    }

    await editcareForumClient(body, formData?.id)
      .then((resp) => {
        if (resp.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${resp.message}`,
          });
          setShowForm({ name: "", visible: false })
          loadCareForum()
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${resp.message}`,
          });
        }
      }).catch((err) => {
      })
  }

  const addNewCareForm = () => {
    values.form = null
    values.client_service_visit_date = ""
    values.end_date = ""
    values.visit = null
    setValues({ ...values })
    setModalAddFormValue(true)
  }
  
  const handleSubmit = async () => {
    if (loadingSave) return;

    if (errors.length) {
      setErrorMessage(InvalidOrEmptyValues)
      setCheckRequires(true)
      setShowError(true)
      return;
    }
    setLoadingSave(true)

    try {
      const body = {
        "form": values.form,
        "start_date": `${values.client_service_visit_date} 00:00`,
        "end_date": `${values.end_date} 00:00`,
        "visit": values.visit,
        "client": selectedClientId,
        "name": values?.form_name
      }
      const res = await setClientCareForms(body)
      if(res.status == true){
        onSuccess(res)
      }else{
        let val = typeof res.message == "string" ? res.message 
        : typeof res.message == "object" ? Object.values(res.message)[0][0] : ""
        throw  val
      }
    
    } catch (error) {
     
      onError(error)
    }
  }

  const onSuccess = () => {
    loadCareForum()
    setModalAddFormValue(false)
    setButtonIcon(CHECK)
    setErrorMessage("")
    setCheckRequires(false)
    setTextStatus(SAVED)
    setLoadingSave(false)
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `Success`,
    });
  }

  const onError = (error) => {
    setButtonIcon(CROSS)
    setErrorMessage(error || 'Something went wrong!')
    setTextStatus(ERROR)
    setLoadingSave(false)
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: error || 'Something went wrong!',
    });
  }

  const getNameFormChoice = (id) => {
    if (!id) return;
    const match = contextChoices && contextChoices.user_list && contextChoices.user_list.find(user => user.id == id);
    return match ? match.name : ""
  }

  return (
    <div>
      {readPermission ? ( <>
      <Toast ref={toast} position="top-right" />
      <div className={'d-flex justify-content-end text-primary font-weight-bold'}>
        <div className='mt-4 mb-2'>
          {createPermission && <PrimaryButtonForm
            style={{ minWidth: "6rem" }}
            // minWidth="12rem"
            onClick={addNewCareForm}>
            <BsFolderPlus size={18} />
            <span className={'ml-2'}>Care Form</span>
          </PrimaryButtonForm>}
        </div>
      </div>
      <Table
        headerColumns={headerColumns}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem">
        <div>
          {contextChoices &&
            taskData.map((item, index) => {
              return (
                <div key={index}>
                  <Row bgColor>
                    <ColsGrouper
                      className="d-flex"
                    >
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className=""
                        overlap={true}>
                        {item.name}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className=""
                        overlap={true}>
                        {item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden"
                        overlap={true}>
                        {item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden"
                        overlap={true}>
                        {getNameFormChoice(item.created_by)}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden"
                        overlap={true}>
                        {item?.visit ? "YES": "NO"}
                      </Col>
                    </ColsGrouper>
                    <Col className="" Center Shrink NoFlexGrow>
                      <Col className="" Center Icon Purple>
                        <IconWrapper
                          onClick={() => {
                            setCreatedForms(() => {
                              return JSON.parse(item.json_data)
                            })
                            setShowForm({ name: item.name, visible: true , id: item.id})
                            setFormData(item)
                          }}
                        >
                          <MdEdit />
                        </IconWrapper>
                      </Col>
                      {deletePermission && <IconWrapper
                        onClick={() => {
                          toDeleteBranch(item.id);
                        }}>
                        <RiDeleteBinLine />
                      </IconWrapper>}
                    </Col>

                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
          <div className="mt-3">
            <Pagination
              totalPages={pages}
              currentPage={currentPage}
              values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={taskData.length}
            />
          </div>
        </div>
      </Table>
      <div
        className={'d-flex justify-content-center justify-content-md-end'}>
        <div>
          <PrimaryButtonForm
            bgcolor="PURPLE_GRAY_500"
            minWidth="6rem"
            className={'mr-2'}
            onClick={() => {
              history.push('/client')
            }}>
            <span className={'mr-2'}>{'Cancel'}</span>
          </PrimaryButtonForm>
        </div>
      </div>
      </>) : 
      <NoPermissionContainer>
        You don't have permission to access the information.
      </NoPermissionContainer>
      }

      <ShowFormModal
        children={
          <>
            <FormViewer jsonData={createdForms} contextChoices={contextChoices} setJsonData={setCreatedForms} name={name} title={showForm.name} handleChangeChoices={handleChangeChoices} />
            <div style={{ alignSelf: 'flex-end' }} className='d-flex mb-2 mr-2'>
              <div className={'mb-3 mr-2'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{InvalidOrEmptyValues}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <PrimaryButtonForm
                minWidth="6rem"
                onClick={editClientform}
                disabled={showForm.id ? !updatePermission : !createPermission}
              >
                <span className={'mr-2'}>Save</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </>
        }
        title={showForm.name}
        onClose={() => {
          setShowForm({ name: "", visible: false })
        }}
        showModal={showForm.visible}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}
        data={[]}
      />
      
      
      {modalAddFromValue &&
        <AddCareForm
          selectedClientId={selectedClientId}
          fullBind={fullBindAddFormModal}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          textStatus={textStatus}
          loadingSave={loadingSave}
          buttonIcon={buttonIcon}
          values={values}
          useInput={useInput}
          setValues={setValues}
          contextChoices={contextChoices}
          formChoices={formChoices}
          contextChoicesMap={contextChoicesMap}
        />}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to unassign this form?'
        }
        subBody={
          'TIP (Recommended) - you could change the task to discard instead to retain database records.'
        }
        onOk={handleDeleteBranch}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default CareForum;
