import React, {useState, useEffect, useContext} from 'react';
import {EX_SMALL_COL4, LARGE_COL, SMALL_COL} from 'shared/styles/constants/columns';
import {headerColumns, headerColumnsSelector, headerColumnsSelectorByDefault} from './TableHeaders/BillingDetailsHeaderDistance';
import {PrimaryButton, PrimaryButtonForm} from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import {useForm} from 'hooks/FormHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {getBillingDetails, billDetailExport, bulkActionPostBillingDetails, bulkActionPostInvocie} from 'utils/api/finance';
import Pagination from 'components/SharedComponents/Pagination';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {AppContext, BranchItemsContext} from 'context';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import {Row, Col} from 'components/SharedComponents/Table/styles';
import {useHistory, useLocation} from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getPayRates } from 'utils/api/ClientApi';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useModal } from 'hooks/ModalHook';
import AssignBulk from './Modal/AssignBulk';
import { getClientFundersList } from 'utils/api/ClientApi';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';

const BillingDistance = props => {
  const [detailData, setDetailData] = useState([]);
  const {values, setValues, useInput, errors, isValid, setCheckRequires} =
    useForm({});
  const [pages, setPages] = useState(0);

  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [funderSourceFilter, setFunderSourceFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([]);
  const [EvvConfirmationFilter, setEvvConfirmationFilter] = useState([]);
  const [FunderRateCardFilter, setFunderRateCardFilter] = useState([]);
  const [VisitBillConfirmationFilter, setVisitBillConfirmationFilter] = useState([]);
  const [EmployeeStatusFilter, setEmployeeStatusFilter] = useState([]);
  const [EmployeeFilter, setEmployeeFilter] = useState([]);
  const [TravelModeFilter, setTravelModeFilter] = useState([]);
  const [TravelCodeFilter, setTravelCodeFilter] = useState([]);
  const [VisitTravelBillConfirmationFilter, setVisitTravelBillConfirmationFilter] = useState([]);

  const [ActualDuration, setActualDuration] = useState(0);
  const [clientFilter,setClientFilter]=useState([])
  const [adhocAmount, setAdhocAmount] = useState(0);
  const [funderNameFilter, setFunderNameFilter] = useState([]);
  const [scheduleAmount, setScheduleAmount] = useState(0);
  const [scheduleDuration, setScheduleDuration] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalExport, setLoadingModalExport] = useState(false);

  const [branchFilter, setBranchFilter] = useState([]);
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const {contextChoices: choices} = useContext(AppContext);
  const history = useHistory();
  const [funderList,setFunderList]=useState([])
  const [bulkActionKeys, setBulkActionKeys] = useState({})
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])
  const [billPatternFilter, setbillPatternFilter] = useState([])
  const headerSelectedData = headerColumns.map((val)=>(val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumns.filter((val)=>val.columnSelected));
  const { setValue: setModalBulkConfirmation, bind: bindModalBulkConfirmation } = useModal(false);
  const toast = useRef()
  const {
    setValue: setActionPopup,
    value: ActionPopup,
    fullBind: fullBindActionPopup,
  } = useModal(false);

  const { branchData } = useContext(BranchItemsContext);

  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
    values.limitperpage,
    props.location.search,
    values.ordering
  ]);

  useEffect(()=>{
    const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/finance/billing/details',
        search: stringified,
      });
  },[])
  useEffect(() => {
    getPayRates()
      .then(res => {
        setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates') || []);
        setBillRate(res.filter(data => data.rate_card_type == 'Bill Rates') || []);
        setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
      })
  }, [])

  const funderLists=()=>{
    getClientFundersList(values)
    .then(res=>{
      if(res.data && (res.data.length > 0)){
       let newFunders=res.data.map(funder=>{
         return {
           id:funder.id,
           is_client: funder.is_client,
           name:funder.name
         }
       })
       setFunderList([{id:'none', name: 'None'}, ...newFunders])
      }
    })
  }

  useEffect(()=>{
    if(values.date_from && values.date_to){
      funderLists();
    }
  },[values.date_from, values.date_to])

  const [clientOptions,setClientOptions]=useState([])
    useEffect(()=>{
      if(choices.client_listing){
        let temp = choices.client_listing.map(client => {
          return { id: client.id, name: client.full_name };
        })
        setClientOptions(temp);
      }
    },[choices.client_listing])
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, []);


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/finance/billing/details',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    // setValues({...values});
    setValues({...values, ...item});
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  const handleClick = (search) => {
    let pageToSearch = {}
    if(search){
      pageToSearch = {offset : 0}
      setCurrentPage(1);
    }else {
      pageToSearch = getPageToSearchParam(props.location.search);
    }
    if (!values.date_from || !values.date_to) {
      return;
    } else {
      setLoadingModal(true)

      getBillingDetails({...values, ...pageToSearch,...branchData}).then(res => {
        if (res) {
          setLoadingModal(false);
        }
        setDetailData(res.results);
        setActualDuration(res.total_actual_duration ? (res.total_actual_duration/60)?.toFixed(2) : 0);
        setAdhocAmount(res.total_actual_billing_amount?.toFixed(2));
        setScheduleAmount(res.total_schedule_billing_amount?.toFixed(2));
        setScheduleDuration(res.total_schedule_duration ? (res.total_schedule_duration/3600)?.toFixed(2) : 0);
        setPages(Math.ceil(res.count / values.limitperpage));
      });
    }
  };
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleExport = () => {
    setLoadingModalExport(true)
    const pageToSearch = getPageToSearchParam(props.location.search);
    billDetailExport({...values, ...pageToSearch,...branchData},{is_distance_bill : true}, {column_selector: runTableOptionData}).then(response => {
      setLoadingModalExport(false)
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `Bill_details_${values.date_from}_${values.date_to}`;
        a.click();
      });
    });
  };
  const dropDownHandleSelect = item => {
    if (item.visit__client_service_visit__client__id__in) {
      item.visit__client_service_visit__client__id__in = item.visit__client_service_visit__client__id__in.length === (clientOptions ? clientOptions : 0).length || item.visit__client_service_visit__client__id__in.length === 0 ? [] : item.visit__client_service_visit__client__id__in
    }
    setValues({...values, ...item});
  };

  const handleSelectChange = (e, visit) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.visit_id])
    } else {
      let index = checkedRows.indexOf(visit.visit_id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const handleAllCheck=()=>{
    let checkAll = true;
  
      const visitExists=(id)=>{
        return checkedRows.some(function(el) {
          return el === id;
        });
      }
      if (checkedRows && checkedRows.length > 0) {
        detailData.map((visit)=>{
            if(!visitExists(visit.visit_id)){
              checkAll = false
            }
          })
      }else{
        checkAll = false;
      }
      return checkAll;
  }
  
  const handleAllSelectChange=(e)=>{
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      detailData.map((visit)=>{
        checkedRows.push(visit.visit_id)
      })
    } else {
      detailData.map((visit)=>{
        checkedRows.map((item, index) => {
          if (item === visit.visit_id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }

  const handleSubmit = () => {
    let body = {
      visit_list: selectedElId?[selectedElId]:checkedRows,
    }
    bulkActionPostBillingDetails(body, bulkActionKeys.apiKey)
      .then(res => {
        if(res.status){
          setCheckedRows([])
          clearSelectedElId();
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          handleClick()
          
        }
      })
      .catch(err => {
      })



  }

  const handleSubmitInvoice = () => {
    let body = {
      visit_list: selectedElId?[selectedElId]:checkedRows,
      invoice_type: 'distance_invoicing'
    }
    bulkActionPostInvocie(body)
      .then(res => {
        if(res.status){
          setCheckedRows([])
          clearSelectedElId()
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          handleClick()
          
        }
      })
      .catch(err => {
        clearSelectedElId()
      })



  }

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };

  const {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  } = useContextMenu()
  const billingDistanceMenu = [
    {
      name: "Finance Distance Bill Confirmation",
      onClick: () => {
        setModalBulkConfirmation(true);
        setBulkActionKeys({ apiKey: 'distance_bill_confirmation', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
      }
    },
    {
      name: "Finance Schedule Manual Travel Distance Bill Amount",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: 'schedule_manual_travel_distance_bill_amount', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
      }
    },
    {
      name: "Finance Actual Manual Travel Distance Bill Amount",
      onClick: () => {
        setActionPopup(true);
        setBulkActionKeys({ apiKey: 'actual_manual_travel_distance_bill_amount', title: 'Finance Actual Manual Travel Distance Bill Amount' })
      }
    },
    {
      name: "Distance Ready for Invoice",
      onClick: () => {
        handleSubmitInvoice()
      }
    }
  ]

  return (
    <div>
      <ContextMenu menuItems={billingDistanceMenu} />
      <Toast ref={toast} position="top-right" />
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'} style={{flexWrap:'wrap', gap: "0.5rem" }}>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.client_status ? choices.client_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({client_status: items});
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={items =>
                      setClientStatusFilter([...items])
                    }
                    placeHolder={'Client Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={clientOptions ? clientOptions: []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ visit__client_service_visit__client__id__in: items });
                      }}
                      itemsSelected={clientFilter}
                      setSelectedFilter={(items)=>setClientFilter([...items])}
                      placeHolder={'Clients'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
               
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={funderList ? funderList : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({funder_name: items});
                    }}
                    itemsSelected={funderNameFilter}
                    setSelectedFilter={items => setFunderNameFilter([...items])}
                    placeHolder={'Funder Name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source ? ([{id:'none', name: 'None'}, ...choices.funder_source] || []) : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__client_service_visit__client_service__service_funders__category__id__in: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                    placeHolder={'Funder Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.visit_status ? choices.visit_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({visit_status: items});
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={items =>
                      setVisitStatusFilter([...items])
                    }
                    placeHolder={'Visit Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      choices.service_category ? [{id:'none', name: 'None'}, ...choices.service_category] : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        visit__service_category__id__in: items,
                      });
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={items =>
                      setServiceCategoryFilter([...items])
                    }
                    placeHolder={'Service Category'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      [{id: 'CONFIRMED', name: 'CONFIRMED'},
                      {id: 'NOT CONFIRMED', name: 'NOT CONFIRMED'},
                      {id: 'AUTO CONFIRMED', name: 'AUTO CONFIRMED'}]
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        evv_confirmation_status__in: items,
                      });
                    }}
                    itemsSelected={EvvConfirmationFilter}
                    setSelectedFilter={items =>
                      setEvvConfirmationFilter([...items])
                    }
                    placeHolder={'EVV Confirmation Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      [{id: 'CONFIRMED', name: 'CONFIRMED'},
                      {id: 'NOT CONFIRMED', name: 'NOT CONFIRMED'}]
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        visit__bill_confirmation__in: items,
                      });
                    }}
                    itemsSelected={VisitBillConfirmationFilter}
                    setSelectedFilter={items =>
                      setVisitBillConfirmationFilter([...items])
                    }
                    placeHolder={'Visit Bill Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
               
              
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      choices.travel_type ? choices.travel_type : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        travel_mode__in: items,
                      });
                    }}
                    itemsSelected={TravelModeFilter}
                    setSelectedFilter={items =>
                      setTravelModeFilter([...items])
                    }
                    placeHolder={'Travel Mode'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'Home-Office', name: 'Home Office'},
                      {id: 'Client-Office', name: 'Client Office'},
                      {id: 'Office-Office', name: 'Office Office'}
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        travel_code__in: items,
                      });
                    }}
                    itemsSelected={TravelCodeFilter}
                    setSelectedFilter={items =>
                      setTravelCodeFilter([...items])
                    }
                    placeHolder={'Travel Code'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.Billing_patterns ? choices.Billing_patterns : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ bill_pattern: items });
                    }}
                    itemsSelected={billPatternFilter}
                    setSelectedFilter={(items) => setbillPatternFilter([...items])}
                    placeHolder={'Bill Pattern'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <PrimaryButtonForm onClick={()=>handleClick(true)} disabled={loadingModal || loadingModalExport}>
                 <div className='d-flex'>
                 <span className={'ml-2 mr-2 font-weight-bold'}>Search</span>
                  {/* {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )} */}
                 </div>
                </PrimaryButtonForm>
                {detailData.length > 0 && (
                  <PrimaryButton
                    type="button"
                    className="ml-2"
                    onClick={handleExport} disabled={loadingModalExport || loadingModal}>
                    <span className={'ml-2 mr-2 font-weight-bold'}>Export</span>
                  {!loadingModalExport ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )}
                  </PrimaryButton>
                )}
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      <ComponentDynamic loading={loadingModal} >

      {detailData.length > 0 && (
        <>
          <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-md-end">
          {(checkedRows.length > 0) &&
                <div class="dropdown mr-3">
                  <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className={'mr-2'}>Action</span>
                  </PrimaryButtonForm>
                  <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item"
                      onClick={() => {
                        setModalBulkConfirmation(true);
                        setBulkActionKeys({ apiKey: 'distance_bill_confirmation', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Distance Bill Confirmation
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'schedule_manual_travel_distance_bill_amount', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Schedule Manual Travel Distance Bill Amount
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'actual_manual_travel_distance_bill_amount', title: 'Finance Actual Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Actual Manual Travel Distance Bill Amount
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        handleSubmitInvoice()
                      }
                      }>
                      Distance Ready for Invoice
                    </a>
                  </div>
                </div>
              }
               <div className='d-flex justify-content-between'>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownColumnSelector
                    items={headerColumns ? headerColumns.filter((item) => !item.removeCusCol) : []}
                    setItemsSelected={items => {
                      dropDownHandleSelectTable({ tableDropdown: items });
                    }}
                    itemsSelected={dropdownArraySelected}
                    setSelectedFilter={(item) => setDropdownArraySelected([...item])}
                    placeHolder={'Column'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
            <div className="data-container">
              <div className="mr-2">
                <p>{'Sch Hrs: ' + scheduleDuration}</p>
              </div>

              <div className="mr-2">
                <p>{'Actual Hrs: ' + ActualDuration} </p>
              </div>
              <div className="mr-2">
                <p>{'Sch Amt: ' + scheduleAmount}</p>
              </div>
              <div className="mr-2">
                <p>{'Actual Amt: ' + adhocAmount} </p>
              </div>
            </div>
            </div>
          </DataContainer>
          <>
          <div style={{overflowX: 'scroll', width:'100%'}}>
            <div style={{width:'max-content', minWidth:'-webkit-fill-available'}}>
              <Table
                headerColumns={headerColumns}
                headerPadding={true}
                noEditOption={true}
                noNeed={false}
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}
                handleAllCheck={handleAllCheck} 
                handleAllSelectChange={handleAllSelectChange}
                values={runTableOptionData}
                >
                {detailData.map((det, index) => {
                  return (
                    <Row bgColor onContextMenu={(e)=>{
                      e.preventDefault();
                      displayMenu(e, det.visit_id, {x:0, y:0});
                    }}>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("checkbox")) && 'd-none'}
                        max_width={EX_SMALL_COL4}
                        Shrink={false}
                        NoFlexGrow={true}
                        reducePadding
                        Capitalize
                        Center

                      >
                        <input class="form-check-input" type="checkbox"
                          checked={checkedRows.includes(det.visit_id)}
                          id="flexCheckDefault"
                          onChange={(e) => { handleSelectChange(e, det) }}
                        />

                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("branch_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.client__branch__name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("client_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.client_name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("employee_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.employee_name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("service_category")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.service_category}
                        </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("start_date")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.schedule_start_date}
                      </Col>
                      <Col
                      overlap={true}
                      className={!(runTableOptionData && runTableOptionData.includes("end_date")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.end_date}
                      </Col>

                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_start_time")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.schedule_start_time
                          ? det.schedule_start_time.split('.')[0]
                          : ''}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_end_time")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.schedule_end_time
                          ? det.schedule_end_time.split('.')[0]
                          : ''}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_duration_hrs")) && 'd-none'}
                       max_width={LARGE_COL}>{det.schedule_duration}</Col>
                       <Col
                      className={!(runTableOptionData && runTableOptionData.includes("travel_code")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.travel_code
                          ? det.travel_code
                          : ''}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("travel_mode")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.travel_mode
                          ? det.travel_mode
                          : ''}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("post_code_from")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.journey_start_zipcode
                          ? det.journey_start_zipcode
                          : ''}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("post_code_to")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.journey_end_zipcode}
                      </Col>

                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("visit_status")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__visit_status__name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("bill_pattern")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.bill_pattern}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("evv_visit_confirmation")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.evv_confirmation_status}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("travel_rate_card")) && 'd-none'}
                       max_width={LARGE_COL}>{det.visit__visit_travel_pay_rate_card}</Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_travel_distance_miles")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__schedule_mileage_rate_per_mile}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_mileage_rate")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__schedule_mileage_rate_per_mile}
                      </Col>

                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("actual_travel_distance_miles")) && 'd-none'}
                       max_width={LARGE_COL}>{det.visit__visit_mileage_rate_per_mile}</Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("actual_mileage_rate")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__visit_mileage_rate_per_mile}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("schedule_mileage_amount")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__schedule_mileage_amount}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("actual_mileage_amount")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__visit_mileage_amount}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("visit_bill_confirmation")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__bill_confirmation ? 'Confirmed' : 'Non Confirmed'}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("distance_bill_confirmation")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__distance_bill_confirmation ? 'Confirmed' : 'Non Confirmed'}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("cancelled_bill_percentage")) && 'd-none'}
                       max_width={LARGE_COL}>
                        {det.visit__client_to_be_billed}
                      </Col>
                      <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_schedule_travel_bill_amount")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_schedule_travel_bill_amount}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_schedule_travel_duration")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_schedule_travel_duration}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_schedule_travel_miles")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_schedule_travel_miles}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_schedule_travel_miles_amount")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_schedule_travel_miles_amount}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_actual_travel_bill_amount")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_actual_travel_bill_amount}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_actual_travel_duration")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_actual_travel_duration}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_actual_travel_miles")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_actual_travel_miles}
                          </Col>
                          <Col
                            className={!(runTableOptionData && runTableOptionData.includes("non_commute_actual_travel_miles_amount")) && 'd-none'}
                            max_width={LARGE_COL}>
                            {det.non_commute_schedule_travel_miles_amount}
                          </Col>
                    </Row>
                  );
                })}
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={detailData.length}
                  />
                </div>
              </Table>
              </div>
            </div>
          </>
        </>
      )}
                  </ComponentDynamic>
                  {
        ActionPopup &&
        <AssignBulk
          fullBind={()=>encloseModalBind(fullBindActionPopup)}
          checkedRows={selectedElId?[selectedElId]:checkedRows}
          bulkActionKeys={bulkActionKeys}
          handleClick={handleClick}
          setActionPopup={setActionPopup}
          billRate={billRate}
          payRate={payRate}
          travelRate={travelRate}
          setCheckedRows={setCheckedRows}
          clearSelectedElId={clearSelectedElId}
        />
      }

     <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            `Are you sure you want to ${bulkActionKeys.title} ? `
          }
          onOk={() => {
            handleSubmit()
            setModalBulkConfirmation(false)
          }
          }
          onNo={
            () => {
              setModalBulkConfirmation(false);
              clearSelectedElId();
            }
          }
          {...encloseModalBind(bindModalBulkConfirmation)}
        />
    </div>
  );
};

export default BillingDistance;
