import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { InvalidRange } from 'utils/constants/messages';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  WeekContainer,
  WeekStyle,
  ButtonWeek,
  Title,
  HeaderVisits,
} from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import { dateStringToDate, twentyFourTimeToTwelve } from 'shared/methods';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { getTableData } from 'utils/api/RotaShift';
import { Row, Col, ColsGrouper } from 'components/SharedComponents/Table/styles';
import {
  AvailableExistingUnavailable,
  UnavailableExistingAvailable,
  AvailableExistingShift,
} from '../RotaValidations';
import {
  AVAILABLE_EXISTING_UNAVAILABLE,
  UNAVAILABLE_EXISTING_AVAILABLE,
  AVAILABLE_EXISTING_SHIFT,
} from 'utils/constants/rotaShift';
import PropTypes from 'prop-types';
import { ContainerResponsive, ShowTab } from '../../Team/AddModal/styles';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import moment from 'moment';
import { getPayRates } from 'utils/api/ClientApi';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { setShifts } from 'utils/api/SettingsApi';
import { AppContext } from 'context';
import { divide } from 'lodash';
import { getDynamicPayRate } from 'utils/api/SchedulerApi';
const headerColumns = [
  {
    label: 'Clock in Time',
    name: 'clock_in_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock in Location',
    name: 'clock_in_location',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Time',
    name: 'clock_out_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Location',
    name: 'clock_out_location',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Action',
    name: 'action',
    status: 0,
    className: 'grow-mobile  ',
    max_width: SMALL_COL,
  },
];
const AddAvailability = ({
  readOnly,
  fullBind,
  travelRate,
  dropDownHandleRate,
  dropDownHandleSelect,
  employees,
  useInput,
  values,
  addNewAvailability,
  splitAvailability,
  deleteAvailabilityDetail,
  errorMessage,
  buttonIcon,
  loadingSave,
  textStatus,
  dateMessage,
  timeMessage,
  dateError,
  // invalidOvernight,
  choices,
  isDateAssigned,
  closeModal,
  typeOfMessage,
  // new props passed  below
  nameSection,
  setValues,
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  filterEmployee,
  filterClient,
  userStaff,
  filterSuperAdmin,
  filterAdmin,
  invalidOvernight,
  setInvalidOvernight,
  availabilitiesEmployeeFullName,
  availabilitiesEmployeeId,
  availabilitiesEmployeeIdName,
  employeesHandleSelect,
  availabilityDetailsDays,
  detailId,
  createAvailable,
  createSelfAvailable,
  handleShift,
  handleChangeChoices,
  shiftTypePopup,
  employeeDisable
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [isFullDay, setIsFullDay] = useState(false);
  const [isOverNight, setIsOverNight] = useState(false);
  const [filterShiftType, setFilterShiftType] = useState([]);
  const [invalidOvernightMessage, setInvalidOvernightMessage] = useState('');
  const [TravelData, setTravelData] = useState([]);
  const [data, setData] = useState([]);
  const [shiftNames, setShiftNames] = useState([]);
  const [dataValues, setDataValues] = useState([]);
  const { contextChoices } = useContext(AppContext);
  const [saveDisable, SetSaveDisable] = useState(false);
  const [rateCardOption, setRateCardOption] = useState([])
  const toast = useRef();

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: shiftTypePopup === 1 ? 'Shift' : (values.idSelected ? 'Edit Availability' : 'Add Availability'),
    });
  }, [fullBind]);

  useEffect(() => {
    if (values.idSelected) {
      loadTableData();
    }
  }, [values.idSelected]);

  useEffect(() => {
    getPayRates().then(res => {
      setTravelData(res.map(el => ({ ...el, branch: el.branch.length ? el.branch.map(x => x.branch_id) : [] })));
    });
  }, []);

  useEffect(() => {
    if (choices) setFilterShiftType(choices.regular_shift_type);

    let shiftValues = [];
    if (choices && choices.regular_shift_type) {
      shiftValues = choices.regular_shift_type.map(y => {
        return { id: y && y.id, name: y && y.name };
      });
      shiftValues.unshift({ id: 'none', name: 'None' });
    }
    setFilterShiftType(shiftValues || []);

    let shiftName = [];
    let shiftNameNotNew = [];
    if (choices && choices.regular_shift_type) {
      shiftName = choices.regular_shift_type.filter(item => {

        if (item.name !== 'Absence' && item.name !== 'Unavailability') {
          return { id: item.id, name: item.name };
        }


      });
      shiftNameNotNew = choices.run_view.map(item => {
        return { id: item.id, name: item.name };
      });
    }
    shiftName.unshift({ id: 'new_shift', name: '+ New Shift' });

    setDataValues(shiftName || values.type);
  }, [choices]);

  useEffect(() => {
    if (values.is_overnight) {
      const start_time = moment(values.start, 'h:mma');
      const end_time = moment(values.end, 'h:mma');
      if (!end_time.isBefore(start_time)) {
        setInvalidOvernight(true);
        setInvalidOvernightMessage(InvalidRange);
      } else {
        setInvalidOvernight(false);
        setInvalidOvernightMessage('');
      }
    } else {
      const start_time = moment(values.start, 'h:mma');
      const end_time = moment(values.end, 'h:mma');
      if (end_time.isBefore(start_time)) {
        setInvalidOvernight(true);
        setInvalidOvernightMessage(InvalidRange);
      } else {
        setInvalidOvernight(false);
        setInvalidOvernightMessage('');
      }
    }
  }, [values.start, values.end, values.is_overnight, choices]);

  useEffect(() => {
    if (values.full_day) {
      values.start = '00:00';
      values.end = '23:59:59';
      setValues({ ...values })
    }
  }, [values.full_day])

  useEffect(() => {
    if (values.start && values.end && (values.start == '00:00') && (values.end == '23:59')) {
      setIsFullDay(true);
      values.full_day = true;
      setValues({ ...values })
    }
  }, [values.start, values.end])

  const addValidationMessage = () => {
    if (isDateAssigned) {
      switch (typeOfMessage) {
        case AVAILABLE_EXISTING_SHIFT:
          return (
            <AvailableExistingShift
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        case AVAILABLE_EXISTING_UNAVAILABLE:
          return (
            <AvailableExistingUnavailable
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        case UNAVAILABLE_EXISTING_AVAILABLE:
          return (
            <UnavailableExistingAvailable
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        default:
          return <Fragment />;
      }
    }
  };

  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
    setIsOverNight(false);
    values.is_overnight = false;
    setValues({ ...values });
  };

  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
    setIsFullDay(false);
    values.full_day = false;
    setValues({ ...values });
  };

  const beforeCallApi = async () => {

    //SetSaveDisable(true);
    if (values.shift_type === 'new_shift') {
      let body = {
        name: values.name,
        pay_type: values.pay_type
      };

      let res = await setShifts(body);

      if (res.status === 'FAIL') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: res.message,
        });
        SetSaveDisable(false);
      } else if (res.status === 'True') {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data,
        });
      }
    }

    let response = await addNewAvailability(true);
    //setValues([])
  };

  const beforeCallApiOnEdit = async () => {

    // SetSaveDisable(true);
    if (values.shift_type === 'new_shift') {
      let body = {
        name: values.name,
        pay_type: values.pay_type
      };

      let res = await setShifts(body);

      if (res.status === 'FAIL') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: res.message,
        });
        // SetSaveDisable(false);
      } else if (res.status === 'True') {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data,
        });
      }
    }

    let response = await addNewAvailability(true);
    //setValues([])
  };

  const handleTravelTime = e => {
    values.travel_time = e.target.value
      ? moment(e.target.value, 'h:mm:ss A').format('HH:mm')
      : null;
    setValues({ ...values });
  };
  const selectedDay = (day, indexWeek) => {
    daysOfWeek[indexWeek].selected = !daysOfWeek[indexWeek].selected;

    setDaysOfWeek(daysOfWeek);

    if (!values.details) {
      values.details = [];
    }

    values.details.forEach(detail => {
      if (detail.week_of_day - 1 === indexWeek) {
        detail.selected = daysOfWeek[indexWeek].selected;
      }
    });

    const addNew = values.details.find(
      detail => detail.week_of_day === daysOfWeek[indexWeek].dayValue
    );

    if (!addNew) {
      values.details.push({
        ...values.shiftValues,
        selected: true,
        week_of_day: daysOfWeek[indexWeek].dayValue,
        is_available: values.shiftValues.is_available
          ? values.shiftValues.is_available
          : false,
        id: '',
      });
    }

    setValues({ ...values });
  };

  const getPayRateOptions = (shiftType, branch_id) => {
    if(!shiftType || !branch_id) return [];
    debugger
    let data = dataValues && dataValues?.find(el => el.id === shiftType)
    let pay_type = data && choices?.pay_type && choices?.pay_type?.find(el => el.id === data?.pay_type)
    switch (pay_type?.name) {
      case 'Absence' || 'Unavailability':
        return TravelData && TravelData?.filter(el => el.rate_card_type === "Absence Rates" && el?.branch?.includes(branch_id))
      case 'Office':
        return TravelData && TravelData?.filter(el => el.rate_card_type === "Office Rates" && el?.branch?.includes(branch_id))
      case 'On Call':
        return TravelData && TravelData?.filter(el => el.rate_card_type === "On Call Rates" && el?.branch?.includes(branch_id))
      case 'Visit':
        return TravelData && TravelData?.filter(el => el.rate_card_type === "Pay Rates" && el?.branch?.includes(branch_id))
      case 'Shift':
        return TravelData && TravelData?.filter(el => el.rate_card_type === "Shift Rates" && el?.branch?.includes(branch_id))
     default:
        return TravelData.filter(data => data.rate_card_type !== "Bill Rates" && data?.branch?.includes(branch_id));;
    }
  };
  // Absence
  // Office
  // On Call
  // Visit
  // Shift
  // Unavailability

  const switchShiftNameInput = props => {
    if (values.shift_type === 'new_shift') {
      return (
        <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
          <InputLabelTop
            required
            {...props}
            readOnly={false}
            type="text"
            id="name"
            label={'Shift Name'}
            {...props}
            maxlength={100}
          />
        </FlexGrid3Item>
      );
    } else {
      return <Fragment />;
    }
  };
  const loadTableData = async () => {
    const response = await getTableData(detailId, false);
    setData(response.data || []);
  };
  const switchTravelModeValue = props => {
    let shift_type =
      filterShiftType &&
      filterShiftType.find(shift => shift.id === values.shift_type) &&
      filterShiftType.find(shift => shift.id === values.shift_type).name;
    switch (shift_type) {
      case 'Availability':
      case 'Bank-Flexible':
        return (
          <>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Travel method'}
                disabled={readOnly}
                options={choices.travel_type}
                selectedOption={values.travel_method}
                onChange={value =>
                  dropDownHandleSelect({ travel_method: value ? value : null })
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pr-0 pr-md-1 mb-2">
              <TimeSelector
                id="extra_travel_time"
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
                labelTop={'Extra Travel Time'}
                value={values.travel_time}
                onChange={handleTravelTime}
              />
            </FlexGrid3Item>
            <FlexGrid3Item>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Travel Rate'}
                options={travelRate}
                selectedOption={values.travel_rate}
                onChange={value =>
                  dropDownHandleRate({
                    travel_rate: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item>
          </>
        );

      case 'Unavailability':
        return <Fragment />;
      case 'Absence':
        return <Fragment />;
      default:
        return (
          <>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
              <DropdownSearchable
                placeHolder={'Travel method'}
                disabled={readOnly}
                options={choices.travel_type}
                selectedOption={values.travel_method}
                onChange={value =>
                  dropDownHandleSelect({ travel_method: value ? value : null })
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
              <TimeSelector
                id="extra_travel_time"
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
                labelTop={'Extra Travel Time'}
                value={values.travel_time}
                onChange={handleTravelTime}
              //  {...useInput(`travel_time`)}
              />
            </FlexGrid3Item>
            <FlexGrid3Item>
              <DropdownSearchable
                placeHolder={'Travel Rate'}
                options={travelRate}
                selectedOption={values.travel_rate}
                onChange={value =>
                  dropDownHandleRate({
                    travel_rate: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item>
          </>
        );
    }
  };

  const switchPayRateValue = props => {
    let shift_type =
      choices.regular_shift_type &&
      choices.regular_shift_type?.find(shift => shift.id === values?.shift_type)
   if(!shift_type) return;
      console.log(shift_type,"shift_type")
    switch (shift_type?.name) {
      // case 'Shift':
      //   return <Fragment />;
      // case 'Availability':
      //   return <Fragment />;
      // case 'Bank-Flexible':
      //   return <Fragment />;

      // case 'Unavailability':
      //   return <Fragment />;
      case 'On Call':
        return (
          <>
            <FlexGrid3Item className="pl-0 pr-1">
              <DropdownSearchable
                // required
                {...props}
                placeHolder={'Pay Rate'}
                options={getPayRateOptions(values.shift_type,values.branch_id)}
                selectedOption={
                  values.regular_shift_pay_rate_id
                    ? values.regular_shift_pay_rate_id
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect({
                    regular_shift_pay_rate_id: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item>
          </>
        );
      default:
        return (
          <>
            <FlexGrid3Item className="pl-0 pr-1">
              <DropdownSearchable
                {...props}
                placeHolder={'Pay Rate'}
                options={getPayRateOptions(values.shift_type, values.branch_id)}
                selectedOption={
                  values.regular_shift_pay_rate_id
                    ? values.regular_shift_pay_rate_id
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect({
                    regular_shift_pay_rate_id: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item>
          </>
        );
    }
  };

  const calculateEndValueProps = value => {
    let shiftname =
      filterShiftType &&
      filterShiftType.find(shift => shift.id === values.shift_type) &&
      filterShiftType.find(shift => shift.id === values.shift_type).name;
    switch (shiftname) {
      case 'Availability':
      case 'Bank-Flexible':
        return 'isRequired';
      default:
        return '';
    }
  };

  const eventValues = {
    clock_count: [],
  };
  const payTypeInput = useInput(`pay_type`, values.shift_type === 'new_shift' ? 'isRequired' : "")
  const branch_hook = useInput(`branch_id`, 'isRequired')
  const employee_hook = useInput(`employee`, 'isRequired')
  console.log(values, 'values');

  const autoFillPayRate = async () => {
    if (values.idSelected || !values.branch_id || !values.shift_type || !values?.start_day || values?.employee?.length > 1) {
      // values.regular_shift_pay_rate_id = ""
      // setValues({...values})
      return;
    }
    try {
      // let shift_type = dataValues && dataValues.find(shift => shift.id === values.shift_type) &&
      // dataValues.find(shift => shift.id === values.shift_type).name
   
      const body = {
        employeeId: values.employee[0]?.id,
        branch: values.branch_id,
        shiftType: values.shift_type,
        start_date: values?.start_day
      }
      const res = await getDynamicPayRate(body)
      if(res.results && res?.results[0]){
      let pay_rate = res.results[0].rate_card;
      values.regular_shift_pay_rate_id = pay_rate
      setValues({...values})
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    autoFillPayRate()
  }, [values.employee[0], values.shift_type, values?.start_day])


  return (
    <ModalContainer max_width={'60%'}>
      <Toast ref={toast} position="top-right" />
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className={'pt-2 pl-3 pr-3 pb-3'}>
          {(
            <FlexGrid3Item className="mb-2 d-flex">
              <div className={'w-100 mr-2'}>
              <DropdownSearchable
                isMulti={values.idSelected ? false : true}
                placeHolder={'Select Employees'}
                required
                {...employee_hook}
                disabled={values.idSelected ? (employeeDisable || !createAvailable ) : false}
                options={employees}
                selectedOptionsProps={values.employee}
                selectedOption={values.employee}
                onChange={value =>
                  employeesHandleSelect({ employee: value ? value : null })
                }
              />
              </div>
              <div className={'w-100'}>
              <DropdownSearchable
                placeHolder={'Select Branch'}
                required
                {...branch_hook}
                disabled={values.idSelected ? (employeeDisable || !createAvailable ) : false}
                options={choices?.branch && choices?.branch}
                selectedOption={values?.branch_id}
                onChange={value =>
                  employeesHandleSelect({ branch_id: value ? value : null })
                }
              />
              </div>
            </FlexGrid3Item>
          )}
          <FlexGrid3Container className="mt-2">
            <FlexGrid3Item className="pl-0 pr-1 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Shift Type'}
                {...useInput(`shift_type`, 'isRequired')}
                disabled={readOnly}
                options={dataValues}
                selectedOption={values.shift_type}
                onChange={(value, name) =>
                  dropDownHandleSelect(
                    { shift_type: value ? value : null },
                    name,
                    'type'
                  )
                }
              />
            </FlexGrid3Item>
            {switchShiftNameInput({
              ...useInput(`name`, (values.shift_type === 'new_shift') ? 'isRequired' : ''),
            })}
            {
              values.shift_type === 'new_shift' && (
                <FlexGrid3Item className="pl-0 pr-1 mb-2">
                  <DropdownSearchable
                    required={(values.shift_type === 'new_shift')}
                    placeHolder={'Pay Type'}
                    {...payTypeInput}
                    disabled={readOnly}
                    options={choices.pay_type || []}
                    selectedOption={values.pay_type}
                    onChange={(value, name) =>
                      dropDownHandleSelect(
                        { pay_type: value ? value : null },
                        name
                      )
                    }
                  />
                </FlexGrid3Item>
              )
            }
            <FlexGrid3Item className="pl-0 pr-1">
              <DateSelector
                isRequired
                disabled={values.idSelected ? true : false}
                errorMessage={dateMessage}
                labelTop="Start day"
                data-error={dateError}
                {...useInput('start_day', 'isRequired')}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-0 pr-0 pr-md-1">
              <DateSelector
                isRequired
                disabled={values.idSelected ? true : false}
                errorMessage={dateMessage}
                labelTop="End day"
                data-error={dateError}
                {...useInput('end_day', 'isRequired')}
              />
            </FlexGrid3Item>

            {switchTravelModeValue({
              ...useInput(
                `travel_method`,
                calculateEndValueProps(values.shift_type)
              ),
            })}
            {switchPayRateValue({
              ...useInput(`regular_shift_pay_rate_id`, ''),
            })}

            <FlexGrid3Item
              className={`pl-1 pr-0 pr-md-1 ${values.shift_type &&
                values.shift_type != '2079a68d-eb9b-42de-8f94-bc398abd166c'
                ? ''
                : ''
                }`}>
              <TimeSelector
                isRequired={isFullDay ? false : true}
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="Start time"
                {...useInput('start', 'isRequired')}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pr-1">
              <TimeSelector
                isRequired={isFullDay ? false : true}
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="End time"
                {...useInput('end', 'isRequired')}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>


            {!values.idSelected && (
              <div className={'formWeek d-block mr-3 mb-1'}>
                <div
                  className={'pt-2 ml-md-1 ml-0 text-center'}
                  style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                  Day of the week
                </div>

                <WeekStyle>
                  {daysOfWeek.map((itemWeek, indexWeek) => {
                    return (
                      <ButtonWeek
                        key={itemWeek.day}
                        add_background={itemWeek.selected}
                        onClick={() => {
                          selectedDay(itemWeek.day, indexWeek);
                        }}>
                        <div>
                          <label>{itemWeek.day}</label>
                        </div>
                      </ButtonWeek>
                    );
                  })}
                </WeekStyle>

                {requireDayOfWeek ? (
                  <div
                    className={'text-center'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    <ErrorMessage fontSize={'0.6rem'}>
                      <label className={'mt-2'}>Is required</label>
                    </ErrorMessage>
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            )}
            <ContainerResponsive className="d-flex align-items-center mt-1">
              <div className="pr-2">
                <ToggleButton
                  label="Is Overnight"
                  disabled={readOnly}
                  useInput={useInput('is_overnight')}
                  id="overnigth"
                  onClick={handelOverNight}
                />
              </div>

              <div className="">
                <ToggleButton
                  label="Full Day"
                  useInput={useInput('full_day')}
                  id="full_day"
                  onClick={handelFullDay}
                />
              </div>
            </ContainerResponsive>

            {/* week days end here */}
          </FlexGrid3Container>



          <div className="w-100 mt-1">
            <InputTextArea
              type="text"
              placeholder="Comment"
              maxlength={500}
              {...useInput('comment')}
            />
          </div>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2'}>
                {values.idSelected ? (
                  <>
                    <PrimaryButtonForm
                      className='mr-1'
                      bgcolor="ERR_COLOR"
                      minWidth="9rem"
                      onClick={() => {
                        deleteAvailabilityDetail();
                      }}>
                      <span className={'mr-2'}>{'DELETE'}</span>
                      {/* {!loadingSave ? ( */}
                      <RiDeleteBinLine />
                      {/* ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )} */}
                    </PrimaryButtonForm>
                    <PrimaryButtonForm
                      minWidth="9rem"
                      disabled={isDateAssigned}
                      onClick={!saveDisable && beforeCallApiOnEdit}>
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave || isDateAssigned ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </>
                ) : (
                  <PrimaryButtonForm
                    minWidth="9rem"
                    disable={saveDisable}
                    onClick={!saveDisable && beforeCallApi}>
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave || isDateAssigned ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                )}
              </div>
            </div>
          </div>
        </div>
        {data.length > 0 ? (
          <div>
            <div className={'ml-1 mr-1 mb-1 mt-1'}>
              <Title>Time and location data</Title>
            </div>

            <Table
              headerColumns={headerColumns}
              headerPadding={false}
              noEditOption
              fontSize="0.75rem">
              <div>
                {data?.map((list, index) => {
                  const clockInDate = list.clock_in_date
                    ? list.clock_in_date
                    : '';
                  const clockInTime = list.clock_in_time
                    ? list.clock_in_time
                    : '';
                  const clockInTimeAndDate = clockInDate + ' , ' + clockInTime;

                  const clockOutDate = list.clock_out_date
                    ? list.clock_out_date
                    : '';
                  const clockOutTime = list.clock_out_time
                    ? list.clock_out_time
                    : '';
                  const clockOutTimeAndDate =
                    clockOutDate + ', ' + clockOutTime;

                  return (
                    <div key={index}>
                      <Row bgColor Shadow NoShadow>
                        <ColsGrouper className="d-flex">
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center>
                            {clockInTimeAndDate}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center>
                            {list.actual_start_location
                              ? list.actual_start_location
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center>
                            {clockOutTimeAndDate}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          // Left
                          >
                            {list.actual_end_location
                              ? list.actual_end_location
                              : ''}
                          </Col>
                          <Col
                            max_width={MEDIUM_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={'ml-0 mr-0 pl-0 pr-0'}
                          // Left
                          >
                            {list && list.type && list.type}
                          </Col>
                        </ColsGrouper>
                      </Row>
                      <div className=" mb-1" />
                    </div>
                  );
                })}
              </div>
            </Table>
          </div>
        ) : null}
      </ModalDynamic>
    </ModalContainer>
  );
};

AddAvailability.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  addNewAvailability: PropTypes.func.isRequired,
  deleteAvailabilityDetail: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  dateMessage: PropTypes.string.isRequired,
  timeMessage: PropTypes.string.isRequired,
  dateError: PropTypes.bool.isRequired,
  invalidOvernight: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  //new props added
  nameSection: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  daysOfWeek: PropTypes.array.isRequired,
  setDaysOfWeek: PropTypes.func.isRequired,
  requireDayOfWeek: PropTypes.bool.isRequired,
};

export default AddAvailability;
