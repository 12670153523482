import { LARGE_COL, EX_SMALL_COL4, SMALL_COL, MEDIUM_COL, VERY_EX_SMALL_COL_CHECKBOX, EX_LARGE_COL, EX_VERY_LARGE_COL, EX_SMALL_COL, COL_CONTAIN_WORD, CHECK_BOX } from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    checkbox: true,
    name: 'checkbox',
    label: 1,
    status: 0,
    className: 'grow-mobile',
    max_width: CHECK_BOX,
  },
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0 pl-1',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Updated At',
    name: 'updated_at',
    status: 0,
    className: 'grow-mobile',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'Date',
    name: 'visit__start_date',
    status: 0,
    className: 'grow-mobile',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'Time',
    name: 'visit__start_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'Employee Name',
    name: 'care_notes_created_by__name',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'category',
    name: 'visit__service_cavisit__service_category_idtegory_id',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Visit Type',
    name: 'visit__visit_type_id',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: SMALL_COL,
  },

  // {
  //   label: 'Visit Care Notes',
  //   name: 'care_notes',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden md-hidden',
  //   max_width: EX_VERY_LARGE_COL,
  // },
  {
    label: 'Privacy',
    name: 'notes_privacy',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Labels',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: 'Reviewed By',
    name: 'reviewed_by__name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Review Date',
    name: 'review_date_time',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: SMALL_COL,
  },
  

 
  // {
  //   label: '',
  //   name: '',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: EX_SMALL_COL4,
  // },

];
