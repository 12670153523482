import React from 'react';
import update from 'immutability-helper';
import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { Box } from './Box.js';
import { Dustbin } from './Dustbin.js';
import { ItemTypes } from './ItemTypes.js';
import { TiArrowBackOutline } from 'react-icons/ti';

import Backdrop from '@mui/material/Backdrop';
import MatBox from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { HTML_NAMES } from './constants.js';
import { inital, UI_FEILDS } from './Initials.js';
import TextInput from './components/textInput.js';
import RichTextHtmlInput from './components/richTextHtmlInput.js';
import Heading from './components/Heading.js';
import Dropdown from './components/Dropdown.js';
import { ButtonGroup, TextField } from '@mui/material';
import SwitchComp from './components/SwitchComp.js';
import ButtonComponent from './components/buttonComponent.js';
import RadioGroups from './components/radioGroups.js';
import ButtonGroups from './components/buttonGroup.js';
import CheckboxComponent from './components/checkbox.js';
import Textarea from './components/textArea.js';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FloatingMobileButton,
  PrimaryButtonForm,
} from 'shared/styles/buttons.js';
import { FaPlus } from 'react-icons/fa';
import ChoicesComp from './components/choicesComponent.js';
import { get } from 'utils/api/Api.js';
import DatePickerComp from './components/datePick.js';
import TimePickerComp from './components/timePick.js';
import DateTimePickerComp from './components/dateTimePick.js';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import SignatureComponent from './components/signatureComponent.js';
import SignatureCanvas from 'react-signature-canvas';
import { getToken, getTenant } from '../../../utils/localStorage/token';
import { apiURL } from '../../../utils/env';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { AppContext } from 'context.js';
import { form_builder_choices } from 'utils/choiceConstant/index.js';
import { useContext } from 'react';
import TableData from './components/tableData.js';
import MultiDropdown from './components/MultiDropdown.js';
import NumberInput from './components/NumberInput.js';
import DecimalInput from './components/DecimalInput.js';
import HyperLinkComp from './components/HyperLinkComp.js';
import SectionCom from './components/SectionCom.js';
import RichTextHtmlHeading from './components/richTextHtmlHeading.js';
import InputLabelTop from 'components/SharedComponents/InputLabelTop/index.js';
import PhotoAttachCom from './components/photoAttachCom.js';
import FileUploadCom from './components/fileUploadCom.js';
import FileDownloadCom from './components/fileDownloadCom.js';
import BodyMapComponent from './components/bodyMapComponent.js';
import BodyMapDisplayComponent from './components/bodyMapDisplayComponent.js';
import BodyMapModal from './BodyMapModal/index.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const Container = memo(function Container({ handleChangeChoices }) {
  const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
  };
  const baseUrl = getBaseUrl();
  const acceptValue = [
    ItemTypes.Dropdown,
    ItemTypes.Heading,
    ItemTypes.Switch,
    ItemTypes.TextInput,
    ItemTypes.numberInput,
    ItemTypes.decimalInput,
    ItemTypes.button,
    ItemTypes.radioGroup,
    ItemTypes.buttonGroup,
    ItemTypes.checkbox,
    ItemTypes.textArea,
    ItemTypes.datePicker,
    ItemTypes.timePicker,
    ItemTypes.dateTime,
    ItemTypes.signature,
    ItemTypes.table,
    ItemTypes.multidropdown,
    ItemTypes.hyperLink,
    ItemTypes.section,
    ItemTypes.richTextHtmlInput,
    ItemTypes.richTextHtmlHeading,
    ItemTypes.photoAttach,
    ItemTypes.fileUpload,
    ItemTypes.fileDownload,
    ItemTypes.bodyMapDisplay,
    ItemTypes.bodyMapInput,
    ItemTypes.choices,
    ItemTypes.category
  ];
  const [dustbins, setDustbins] = useState([
    {
      accepts: acceptValue,
      lastDroppedItem: [],
    },
  ]);
  const { setValue: setModalWarn, bind: bindModalWarn } = useModal(false);
  const [showUiComponent, setShowUiComponent] = useState(false);
  const [showChoiceComponent, setShowChoicesComponent] = useState(false);
  const [showCategoryComponent, setShowCategoryComponent] = useState(false);

  const [formName, setFormName] = useState('');
  const [editId, setEditId] = useState(null);
  const [addItem, setAddItem] = useState('');
  const [json, setJson] = useState(inital);
  const [open, setOpen] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [error, setError] = useState(false);
  const [choiceLimit, setchoiceLimit] = useState(20);
  const [choiceCount, setchoiceCount] = useState(0);
  const [categoryLimit, setcategoryLimit] = useState(20);
  const [categoryCount, setcategoryCount] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [choiceList, setChoiceList] = useState([]);
  const { contextChoices } = useContext(AppContext);
  const [orderChange, setOrderChange] = useState(null);
  const {
    setValue: setModalBodyMap,
    value: modalBodyMap,
    fullBind: fullBindBodyMap,
  } = useModal(false);

  const handleOpen = (item, ondrop) => {

    if (item.type === 'category') {
      let data = dustbins.map(val => {
        return {
          ...val,
          lastDroppedItem: [...val.lastDroppedItem, ...JSON.parse(item.html)],
        };
      });
      setDustbins(data);
    } else {
      if (ondrop) {
        let maxVal = 0;
        dustbins.forEach(val => {
          if (val.lastDroppedItem.length == 0) {
            maxVal = 0;
          } else {
            maxVal = Math.max(...val.lastDroppedItem.map(o => o.id))
          }
        });
        setAddItem({ ...item, id: maxVal + 1 });
      } else {
        // console.log(dustbins, item, 'drop item before')
        setAddItem({ ...item });
      }
      setOpen(true);
    }
  };

  // useEffect(()=>{
  //   handleChangeChoices()
  // },[])

  // not used since choiceList is not used
  useEffect(() => {
    let tempArr = []
    Object.keys(contextChoices).forEach((key) => {
      tempArr.push({ id: key, name: key })
    })
    setChoiceList(tempArr)
  }, [contextChoices])

  const handleCloseSignatureModal = () => setOpenSignature(false);
  const handleClose = () => setOpen(false);
  const { state, pathname } = useLocation();
  // const state = { detail: { json_data: JSON.stringify(dummy), jsonData: JSON.stringify(dummy) } };
  // const { pathname } = useLocation();
  const categoryBuilder =
    pathname ===
    '/settings/setting-form-builder/setting-form-builder-category/add-category';

  const history = useHistory();

  const [boxes] = useState(UI_FEILDS);
  const [choiceBoxes, setChoiceBoxes] = useState([]);
  const [categoriesBoxes, setCategoriesBoxes] = useState([]);
  const {
    handleGetChoices
  } = useContext(AppContext);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]); // this is NOT used anywhere where it can affect anything

  const handleDrop = useCallback(
    (index, item) => {
      // console.log('drop item', item)

      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      handleOpen({ ...item, index }, 'ondrop');
    },
    [droppedBoxNames, dustbins]
  );

  useEffect(() => {
    handleGetChoices(form_builder_choices);
  }, []);


  let keys = [];
  let CategoryKeys = [];
  function customFormatter (item, sampleItem, isChildComponent = false) {
    const tempItem = {
      ...sampleItem,
      id: item.id,
      order: item.position,
      required: item.required ?? false,
      readOnly: item.readOnly ?? false,
      description: item.instructions,
      label: item.label,  // check for break here
    }
    // stop if it is child component and we're NOT parsing over childComponents (to handle case when parent item isn't declared yet)
    if (!isChildComponent && item.parentComponentId) {
      return;
    }
    if (item.type.toLowerCase() === "section") {
      tempItem.sectionColsCount = "1";
      tempItem.type = HTML_NAMES.section;
    } else if (item.type.toLowerCase() === "label") {
      tempItem.type = HTML_NAMES.richTextHtmlHeading;
    } else if (item.type.toLowerCase() === "textbox") {
      tempItem.type = HTML_NAMES.textInput;
    } else if (item.type.toLowerCase() === "decimal") {
      tempItem.type = HTML_NAMES.decimalInput;
    } 
    else if (["radio", "check", "dropdown", "multi-select"].includes(item.type.toLowerCase())) {
      if(item.type.toLowerCase() === "radio") {
        tempItem.type = HTML_NAMES.radioGroup;
      } else if(item.type.toLowerCase() === "check") {
        tempItem.type = HTML_NAMES.checkbox;
      } else if(item.type.toLowerCase() === "dropdown") {
        tempItem.type = HTML_NAMES.dropdown;
      } else if(item.type.toLowerCase() === "multi-select") {
        tempItem.type = HTML_NAMES.multidropdown;
      }
      let isTypeDropdown = tempItem.type === HTML_NAMES.dropdown || tempItem.type === HTML_NAMES.multidropdown;
      const options = item.values.split("\n");
      const optionList = options.filter(s => s).map(s => {
        if(isTypeDropdown)
          return { label: s}
        else return { value: s }
      });
      tempItem.options = optionList;
    }
     else if (item.type.toLowerCase() === "textarea") {
      tempItem.type = HTML_NAMES.textArea;
      tempItem.numberOfRows=2
    } else if (item.type.toLowerCase() === "date") {
      tempItem.type = HTML_NAMES.datePicker;
    } else if (item.type.toLowerCase() === "time") {
      tempItem.type = HTML_NAMES.timePicker;
    } else if (item.type.toLowerCase() === "signature") {
      tempItem.type = HTML_NAMES.signature;
    } else  if (item.type.toLowerCase() === "table") {
      tempItem.type = HTML_NAMES.table
    } else if (item.type.toLowerCase() === "photo") {
      tempItem.type = HTML_NAMES.fileUpload
    }
    return tempItem;
  }
  function handleDifferentFormat (parsedData) {
      const formName = parsedData.name;
      setFormName(formName);
      const headerName = parsedData.header;
      const instructions = parsedData.instructions;
      const sampleItem = {
        id: "",
        type: "",
        html: "",
        label: "",
        value: "",
        sectionColsCount: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        manual_options: "",
        index: "",
        description: "",
        instruction: ""
      }
      const HeadingItem = {
        ...sampleItem,
        id: "1",
        html: "h3",
        value: headerName,
        type: "Heading text"
      }
      const instructionsItem = {
        ...sampleItem,
        id: "2",
        html: "h6",
        value: instructions,
        type: "Heading text",
      }
      const tempItems = []
      // filter out formComponents with parentComponentId
      const childComponents = [];
      parsedData.formComponents.forEach((item) => {
        if(item.parentComponentId) {
          childComponents.push(item);
          return;
        }
        let x = customFormatter(item, sampleItem, false);
        if(x)
          tempItems.push(x)
      })
      // handle child components
      childComponents.forEach((item) => {
        // push the child component to the parent component
        const parentIndex = tempItems.findIndex(i => i.id === item.parentComponentId);
        if (parentIndex !== -1) {
            // push it in parent in key cols
            const parentItem = tempItems[parentIndex];
            if (parentItem.type === "Section" || parentItem.type === "Table") {
              const childItem = customFormatter(item, sampleItem, true);
              if (childItem) {
                if (!parentItem.cols) {
                  parentItem.cols = [];
                }
                if(parentItem.type === "Section")
                  parentItem.cols.push(childItem);
                else {
                  if (!parentItem.rows) {
                    parentItem.rows = [];
                    parentItem.rows.push({})
                  }
                  parentItem.cols.push({
                    ...childItem,
                    column_name: childItem.label
                  })
                }
              }
            }
          }
      })
      const parseableOutput = [
        HeadingItem,
        instructionsItem,
        ...tempItems
      ]
      return parseableOutput;
  }
  
  // gets only choices
  useEffect(() => {
    const getChoiceAndCategory = async () => {
      await get(`${baseUrl}/core/form-builder/?limit=${choiceLimit}&offset=0`)
        .then(res => {
          setchoiceCount(res.count || 0);
          setChoiceBoxes(
            res.results.map((val, index) => {
              keys.push(val.choice_key || '');
              return {
                id: `${val.choice_key || ''}${index}`,
                name: val.choice_key,
                type: 'Choices',
                // type: val.choice_key ? val.choice_key : '',
                html: null,
                value: '',
                placeholder: '',
                required: '',
                order: '',
                options: [],
                choices: val.name ? val.name : '',
                is_multi: false
              };
            })
          );
        })
        .catch(err => console.log(err));
      // if Edit page, then we got form data via location.state
      if (state.detail && (state.detail.json_data || state.detail.jsonData)) {
        setEditId(state.editMode ? state.detail.id : null);
        setFormName(state.editMode ? state.detail.name : '');
        setDustbins([
          {
            accepts: [...acceptValue, ...keys, ...CategoryKeys],
            lastDroppedItem: categoryBuilder
              ? JSON.parse(state.detail.json_data)
              : JSON.parse(state.detail.jsonData),
          },
        ]);
      } else {
        setFormName('');
        setEditId(null);
        setDustbins([
          {
            accepts: [...acceptValue, ...keys, ...CategoryKeys],
            lastDroppedItem: [],
          },
        ]);
      }
    };
    getChoiceAndCategory();
  }, [choiceLimit]);
  // gets only categories (if NOT add-category page)
  useEffect(() => {
    const getChoiceAndCategory = async () => {
      if (!categoryBuilder) {
        await get(
          `${baseUrl}/form-builder/form/category/?limit=${categoryLimit}&offset=0`
        )
          .then(res => {
            setcategoryCount(res.count || 0);
            setCategoriesBoxes(
              res.results.map((val, index) => {
                CategoryKeys.push(val.name);
                return {
                  id: '',
                  name: val.name,
                  type: 'category',
                  html: val.json_data || '',
                  value: '',
                  placeholder: '',
                  required: '',
                  order: '',
                  options: [],
                  choices: '',
                };
              })
            );
          })
          .catch(err => console.log(err));
      }
      // if Edit page, then we got form data via location.state
      if (state.detail && (state.detail.json_data || state.detail.jsonData)) {
        setEditId(state.editMode ? state.detail.id : null);
        setFormName(state.editMode ? state.detail.name : '');
        setDustbins([
          {
            accepts: [...acceptValue, ...CategoryKeys, ...keys],
            lastDroppedItem: categoryBuilder
              ? JSON.parse(state.detail.json_data)
              : JSON.parse(state.detail.jsonData),
          },
        ]);
      } else {
        setFormName('');
        setEditId(null);
        setDustbins([
          {
            accepts: [...acceptValue, ...CategoryKeys, ...keys],
            lastDroppedItem: [],
          },
        ]);
      }
    };
    getChoiceAndCategory();
  }, [categoryLimit]);
  // duplicate
  const post = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Token ' + getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
  };
  // duplicate
  const patch = async (url, body) => {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: 'Token ' + getToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
  };
  // handle add, edit form and add-category on the basis of dustbins[0].lastDropedItem
  const onSave = () => {
    if (formName.trim().length === 0) {
      alert("Form name can't be empty");
      return;
    }
    let data;
    if (categoryBuilder) {
      console.log(dustbins[0].lastDroppedItem, "dustbins")
      data = {
        name: formName,
        json_data: JSON.stringify(dustbins[0].lastDroppedItem),
      };
    } else {
      data = {
        name: formName,
        jsonData: JSON.stringify(dustbins[0].lastDroppedItem),
      };
    }
    console.log(data, "data")
    if (editId) {
      patch(
        categoryBuilder
          ? `${baseUrl}/form-builder/form/category/${editId}/`
          : `${baseUrl}/form-builder/${editId}/?limit=20&offset=0`,
        data
      )
        .then(res => {
          setFormName('');
          history.goBack();
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
        })
        .catch(error => {
          setButtonIcon('');
          setButtonIcon(CROSS);
          setTextStatus(ERROR);
        });
    } else {
      post(
        categoryBuilder
          ? `${baseUrl}/form-builder/form/category/`
          : `${baseUrl}/form-builder/?limit=20&offset=0`,
        data
      )
        .then(res => {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setFormName('');
          history.goBack();
        })
        .catch(error => {
          setButtonIcon('');
          setButtonIcon(CROSS);
          setTextStatus(ERROR);
        });
    }
  };
  // util function to check if the item is already present in the dustbins
  const matchId = (addItem) => {
    let checkEdit = false;
    dustbins.forEach((val, indexInn) => {
      if (indexInn === addItem.index) {
        checkEdit = val.lastDroppedItem.find((val_f) => val_f.id == addItem.id)
      }
    });
    return checkEdit
  }

  const setDustbinsData = () => {
    if (addItem.type === HTML_NAMES.textInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.textInput.label,
            placeholder: json.textInput.placeholder,
            order: val.lastDroppedItem.length,
            description: json.textInput.description,
            instruction: json.textInput.instruction,
            required: json.textInput.required,
            readOnly: json.textInput.readOnly,
            showCondition: json.textInput.showCondition,
            ruleExpression: json.textInput.ruleExpression,
          };
          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj,
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.numberInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.numberInput.label,
          //       placeholder: json.numberInput.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.numberInput.description,
          //       instruction: json.numberInput.instruction,
          //       required: json.numberInput.required,
          //       readOnly: json.numberInput.readOnly,
          //       showCondition: json.numberInput.showCondition,
          //       ruleExpression: json.numberInput.ruleExpression,
          //       evaluateCondition: json.numberInput.evaluateCondition,
          //       evaluateExpression: json.numberInput.evaluateExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.numberInput.label,
            placeholder: json.numberInput.placeholder,
            order: val.lastDroppedItem.length,
            description: json.numberInput.description,
            instruction: json.numberInput.instruction,
            required: json.numberInput.required,
            readOnly: json.numberInput.readOnly,
            showCondition: json.numberInput.showCondition,
            ruleExpression: json.numberInput.ruleExpression,
            evaluateCondition: json.numberInput.evaluateCondition,
            evaluateExpression: json.numberInput.evaluateExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.decimalInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.decimalInput.label,
          //       placeholder: json.decimalInput.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.decimalInput.description,
          //       instruction: json.decimalInput.instruction,
          //       required: json.decimalInput.required,
          //       readOnly: json.decimalInput.readOnly,
          //       showCondition: json.decimalInput.showCondition,
          //       ruleExpression: json.decimalInput.ruleExpression,
          //       evaluateCondition: json.decimalInput.evaluateCondition,
          //       evaluateExpression: json.decimalInput.evaluateExpression,
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.decimalInput.label,
            placeholder: json.decimalInput.placeholder,
            order: val.lastDroppedItem.length,
            description: json.decimalInput.description,
            instruction: json.decimalInput.instruction,
            required: json.decimalInput.required,
            readOnly: json.decimalInput.readOnly,
            showCondition: json.decimalInput.showCondition,
            ruleExpression: json.decimalInput.ruleExpression,
            evaluateCondition: json.decimalInput.evaluateCondition,
            evaluateExpression: json.decimalInput.evaluateExpression,
          }
          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.headingText) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       html: json.headingText.tag,
          //       value: json.headingText.label,
          //       order: val.lastDroppedItem.length,
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            html: json.headingText.tag,
            value: json.headingText.label,
            order: val.lastDroppedItem.length,
          }
          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.dropdown) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       options: json.dropdown.options,
          //       label: json.dropdown.label,
          //       order: val.lastDroppedItem.length,
          //       description: json.dropdown.description,
          //       instruction: json.dropdown.instruction,
          //       required: json.dropdown.required,
          //       readOnly: json.dropdown.readOnly,
          //       showCondition: json.dropdown.showCondition,
          //       ruleExpression: json.dropdown.ruleExpression,
          //     },
          //   ],
          // };
          let droppedData = []
          const addedObj = {
            ...addItem,
            options: json.dropdown.options,
            label: json.dropdown.label,
            order: val.lastDroppedItem.length,
            description: json.dropdown.description,
            instruction: json.dropdown.instruction,
            required: json.dropdown.required,
            readOnly: json.dropdown.readOnly,
            showCondition: json.dropdown.showCondition,
            ruleExpression: json.dropdown.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }

          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }

        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.multidropdown) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       options: json.multidropdown.options,
          //       label: json.multidropdown.label,
          //       order: val.lastDroppedItem.length,
          //       value: json.multidropdown.value,
          //       description: json.multidropdown.description,
          //       instruction: json.multidropdown.instruction,
          //       required: json.multidropdown.required,
          //       readOnly: json.multidropdown.readOnly,
          //       showCondition: json.multidropdown.showCondition,
          //       ruleExpression: json.multidropdown.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.multidropdown.options,
            label: json.multidropdown.label,
            order: val.lastDroppedItem.length,
            value: json.multidropdown.value,
            description: json.multidropdown.description,
            instruction: json.multidropdown.instruction,
            required: json.multidropdown.required,
            readOnly: json.multidropdown.readOnly,
            showCondition: json.multidropdown.showCondition,
            ruleExpression: json.multidropdown.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.switch) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.switch.label,
          //       order: val.lastDroppedItem.length,
          //       description: json.switch.description,
          //       instruction: json.switch.instruction,
          //       required: json.switch.required,
          //       readOnly: json.switch.readOnly,
          //       showCondition: json.switch.showCondition,
          //       ruleExpression: json.switch.ruleExpression,
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.switch.label,
            order: val.lastDroppedItem.length,
            description: json.switch.description,
            instruction: json.switch.instruction,
            required: json.switch.required,
            readOnly: json.switch.readOnly,
            showCondition: json.switch.showCondition,
            ruleExpression: json.switch.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.hyperLink) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.hyperLink.label,
          //       order: val.lastDroppedItem.length,
          //       placeholder: json.hyperLink.placeholder,
          //       value: json.hyperLink.value,
          //       description: json.hyperLink.description,
          //       instruction: json.hyperLink.instruction,
          //       required: json.hyperLink.required,
          //       readOnly: json.hyperLink.readOnly,
          //       showCondition: json.hyperLink.showCondition,
          //       ruleExpression: json.hyperLink.ruleExpression,
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.hyperLink.label,
            order: val.lastDroppedItem.length,
            placeholder: json.hyperLink.placeholder,
            value: json.hyperLink.value,
            description: json.hyperLink.description,
            instruction: json.hyperLink.instruction,
            required: json.hyperLink.required,
            readOnly: json.hyperLink.readOnly,
            showCondition: json.hyperLink.showCondition,
            ruleExpression: json.hyperLink.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.button) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.button.label,
          //       html: json.button.type,
          //       order: val.lastDroppedItem.length,
          //       description: json.button.description,
          //       instruction: json.button.instruction,
          //       required: json.button.required,
          //       readOnly: json.button.readOnly,
          //       showCondition: json.button.showCondition,
          //       ruleExpression: json.button.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.button.label,
            html: json.button.type,
            order: val.lastDroppedItem.length,
            description: json.button.description,
            instruction: json.button.instruction,
            required: json.button.required,
            readOnly: json.button.readOnly,
            showCondition: json.button.showCondition,
            ruleExpression: json.button.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.radioGroup) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       options: json.radioGroup.options,
          //       label: json.radioGroup.label,
          //       order: val.lastDroppedItem.length,
          //       description: json.radioGroup.description,
          //       instruction: json.radioGroup.instruction,
          //       required: json.radioGroup.required,
          //       readOnly: json.radioGroup.readOnly,
          //       showCondition: json.radioGroup.showCondition,
          //       ruleExpression: json.radioGroup.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.radioGroup.options,
            label: json.radioGroup.label,
            order: val.lastDroppedItem.length,
            description: json.radioGroup.description,
            instruction: json.radioGroup.instruction,
            required: json.radioGroup.required,
            readOnly: json.radioGroup.readOnly,
            showCondition: json.radioGroup.showCondition,
            ruleExpression: json.radioGroup.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.buttonGroup) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       options: json.buttonGroup.options,
          //       label: json.buttonGroup.label,
          //       order: val.lastDroppedItem.length,
          //       description: json.buttonGroup.description,
          //       instruction: json.buttonGroup.instruction,
          //       required: json.buttonGroup.required,
          //       readOnly: json.buttonGroup.readOnly,
          //       showCondition: json.buttonGroup.showCondition,
          //       ruleExpression: json.buttonGroup.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.buttonGroup.options,
            label: json.buttonGroup.label,
            order: val.lastDroppedItem.length,
            description: json.buttonGroup.description,
            instruction: json.buttonGroup.instruction,
            required: json.buttonGroup.required,
            readOnly: json.buttonGroup.readOnly,
            showCondition: json.buttonGroup.showCondition,
            ruleExpression: json.buttonGroup.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.checkbox) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       options: json.checkbox.options,
          //       label: json.checkbox.label,
          //       order: val.lastDroppedItem.length,
          //       description: json.checkbox.description,
          //       instruction: json.checkbox.instruction,
          //       required: json.checkbox.required,
          //       readOnly: json.checkbox.readOnly,
          //       showCondition: json.checkbox.showCondition,
          //       ruleExpression: json.checkbox.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.checkbox.options,
            label: json.checkbox.label,
            order: val.lastDroppedItem.length,
            description: json.checkbox.description,
            instruction: json.checkbox.instruction,
            required: json.checkbox.required,
            readOnly: json.checkbox.readOnly,
            showCondition: json.checkbox.showCondition,
            ruleExpression: json.checkbox.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.textArea) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.textArea.label,
          //       placeholder: json.textArea.placeholder,
          //       html: json.textArea.numberOfRows,
          //       order: val.lastDroppedItem.length,
          //       description: json.textArea.description,
          //       instruction: json.textArea.instruction,
          //       required: json.textArea.required,
          //       readOnly: json.textArea.readOnly,
          //       showCondition: json.textArea.showCondition,
          //       ruleExpression: json.textArea.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.textArea.label,
            placeholder: json.textArea.placeholder,
            numberOfRows: json.textArea.numberOfRows,
            order: val.lastDroppedItem.length,
            description: json.textArea.description,
            instruction: json.textArea.instruction,
            required: json.textArea.required,
            readOnly: json.textArea.readOnly,
            showCondition: json.textArea.showCondition,
            ruleExpression: json.textArea.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.table) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.table.label,
          //       placeholder: json.table.placeholder,
          //       html: json.table.numberOfRows,
          //       order: val.lastDroppedItem.length,
          //       cols: json.table.cols,
          //       rows: json.table.rows,
          //       description: json.table.description,
          //       instruction: json.table.instruction,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.table.label,
            placeholder: json.table.placeholder,
            html: json.table.numberOfRows,
            order: val.lastDroppedItem.length,
            cols: json.table.cols,
            rows: json.table.rows,
            description: json.table.description,
            instruction: json.table.instruction,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.section) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.section.label,
          //       placeholder: json.section.placeholder,
          //       html: json.section.numberOfRows,
          //       order: val.lastDroppedItem.length,
          //       sectionColsCount: json.section.sectionColsCount,
          //       cols: json.section.cols,
          //       rows: json.section.rows,
          //       showCondition: json.section.showCondition,
          //       ruleExpression: json.section.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.section.label,
            placeholder: json.section.placeholder,
            html: json.section.numberOfRows,
            order: val.lastDroppedItem.length,
            sectionColsCount: json.section.sectionColsCount,
            cols: json.section.cols,
            rows: json.section.rows,
            showCondition: json.section.showCondition,
            ruleExpression: json.section.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.richTextHtmlInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.richTextHtmlInput.label,
          //       placeholder: json.richTextHtmlInput.placeholder,
          //       order: val.lastDroppedItem.length,
          //       cols: json.richTextHtmlInput.cols,
          //       description: json.richTextHtmlInput.description,
          //       instruction: json.richTextHtmlInput.instruction,
          //       required: json.richTextHtmlInput.required,
          //       readOnly: json.richTextHtmlInput.readOnly,
          //       showCondition: json.richTextHtmlInput.showCondition,
          //       ruleExpression: json.richTextHtmlInput.ruleExpression,
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.richTextHtmlInput.label,
            placeholder: json.richTextHtmlInput.placeholder,
            order: val.lastDroppedItem.length,
            cols: json.richTextHtmlInput.cols,
            description: json.richTextHtmlInput.description,
            instruction: json.richTextHtmlInput.instruction,
            required: json.richTextHtmlInput.required,
            readOnly: json.richTextHtmlInput.readOnly,
            showCondition: json.richTextHtmlInput.showCondition,
            ruleExpression: json.richTextHtmlInput.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.richTextHtmlHeading) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.richTextHtmlHeading.label,
          //       placeholder: json.richTextHtmlHeading.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.richTextHtmlHeading.description,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.richTextHtmlHeading.label,
            placeholder: json.richTextHtmlHeading.placeholder,
            order: val.lastDroppedItem.length,
            description: json.richTextHtmlHeading.description,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.choices) {
      if (!contextChoices[addItem.choices]) {
        handleChangeChoices(addItem.choices)
      }
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: [
        //     ...val.lastDroppedItem,
        //     {
        //       ...addItem,
        //       label: json.choices.label,
        //       order: val.lastDroppedItem.length,
        //       description: json.choices.description,
        //       instruction: json.choices.instruction,
        //       required: json.choices.required,
        //       readOnly: json.choices.readOnly,
        //       is_multi: json.choices.is_multi,
        //       showCondition: json.choices.showCondition,
        //       ruleExpression: json.choices.ruleExpression,
        //     },
        //   ],
        // };

        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.choices.label,
          order: val.lastDroppedItem.length,
          description: json.choices.description,
          instruction: json.choices.instruction,
          required: json.choices.required,
          readOnly: json.choices.readOnly,
          is_multi: json.choices.is_multi,
          showCondition: json.choices.showCondition,
          ruleExpression: json.choices.ruleExpression,
        }
        if (orderChange) {
          droppedData = [...val.lastDroppedItem]
          droppedData.splice(Number(orderChange) - 1, 0, addedObj)
        } else {
          droppedData = [
            ...val.lastDroppedItem,
            addedObj
          ]
        }
        return {
          ...val,
          lastDroppedItem: droppedData
        }

      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.datePicker) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: [
        //     ...val.lastDroppedItem,
        //     {
        //       ...addItem,
        //       label: json.datePicker.label,
        //       order: val.lastDroppedItem.length,
        //       description: json.datePicker.description,
        //       instruction: json.datePicker.instruction,
        //       required: json.datePicker.required,
        //       readOnly: json.datePicker.readOnly,
        //       showCondition: json.datePicker.showCondition,
        //       ruleExpression: json.datePicker.ruleExpression,
        //     },
        //   ],
        // };

        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.datePicker.label,
          order: val.lastDroppedItem.length,
          description: json.datePicker.description,
          instruction: json.datePicker.instruction,
          required: json.datePicker.required,
          readOnly: json.datePicker.readOnly,
          showCondition: json.datePicker.showCondition,
          ruleExpression: json.datePicker.ruleExpression,
          format: json.datePicker.format
        }

        if (orderChange) {
          droppedData = [...val.lastDroppedItem]
          droppedData.splice(Number(orderChange) - 1, 0, addedObj)
        } else {
          droppedData = [
            ...val.lastDroppedItem,
            addedObj
          ]
        }
        return {
          ...val,
          lastDroppedItem: droppedData
        }
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.timePicker) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: [
        //     ...val.lastDroppedItem,
        //     {
        //       ...addItem,
        //       label: json.timePicker.label,
        //       order: val.lastDroppedItem.length,
        //       description: json.timePicker.description,
        //       instruction: json.timePicker.instruction,
        //       required: json.timePicker.required,
        //       readOnly: json.timePicker.readOnly,
        //       showCondition: json.timePicker.showCondition,
        //       ruleExpression: json.timePicker.ruleExpression,
        //     },
        //   ],
        // };

        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.timePicker.label,
          order: val.lastDroppedItem.length,
          description: json.timePicker.description,
          instruction: json.timePicker.instruction,
          required: json.timePicker.required,
          readOnly: json.timePicker.readOnly,
          showCondition: json.timePicker.showCondition,
          ruleExpression: json.timePicker.ruleExpression,
          format: json.timePicker.format
        }

        if (orderChange) {
          droppedData = [...val.lastDroppedItem]
          droppedData.splice(Number(orderChange) - 1, 0, addedObj)
        } else {
          droppedData = [
            ...val.lastDroppedItem,
            addedObj
          ]
        }
        return {
          ...val,
          lastDroppedItem: droppedData
        }
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.dateTime) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: [
        //     ...val.lastDroppedItem,
        //     {
        //       ...addItem,
        //       label: json.dateTimePicker.label,
        //       order: val.lastDroppedItem.length,
        //       description: json.dateTimePicker.description,
        //       instruction: json.dateTimePicker.instruction,
        //       required: json.dateTimePicker.required,
        //       readOnly: json.dateTimePicker.readOnly,
        //       showCondition: json.dateTimePicker.showCondition,
        //       ruleExpression: json.dateTimePicker.ruleExpression,
        //     },
        //   ],
        // };
        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.dateTimePicker.label,
          order: val.lastDroppedItem.length,
          description: json.dateTimePicker.description,
          instruction: json.dateTimePicker.instruction,
          required: json.dateTimePicker.required,
          readOnly: json.dateTimePicker.readOnly,
          showCondition: json.dateTimePicker.showCondition,
          ruleExpression: json.dateTimePicker.ruleExpression,
          format: json.dateTimePicker.format
        }

        if (orderChange) {
          droppedData = [...val.lastDroppedItem]
          droppedData.splice(Number(orderChange) - 1, 0, addedObj)
        } else {
          droppedData = [
            ...val.lastDroppedItem,
            addedObj
          ]
        }
        return {
          ...val,
          lastDroppedItem: droppedData
        }
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.signature) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.signature.label,
          //       html: json.signature.type,
          //       order: val.lastDroppedItem.length,
          //       description: json.signature.description,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.signature.label,
            html: json.signature.type,
            order: val.lastDroppedItem.length,
            description: json.signature.description,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.photoAttach) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.photoAttach.label,
          //       placeholder: json.photoAttach.placeholder,
          //       order: val.lastDroppedItem.length,
          //       file: json.photoAttach.file,
          //       description: json.photoAttach.description,
          //       instruction: json.photoAttach.instruction,
          //       required: json.photoAttach.required,
          //       readOnly: json.photoAttach.readOnly,
          //       showCondition: json.photoAttach.showCondition,
          //       ruleExpression: json.photoAttach.ruleExpression,
          //       value: json.photoAttach.value,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.photoAttach.label,
            placeholder: json.photoAttach.placeholder,
            order: val.lastDroppedItem.length,
            file: json.photoAttach.file,
            description: json.photoAttach.description,
            instruction: json.photoAttach.instruction,
            required: json.photoAttach.required,
            readOnly: json.photoAttach.readOnly,
            showCondition: json.photoAttach.showCondition,
            ruleExpression: json.photoAttach.ruleExpression,
            value: json.photoAttach.value,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.fileUpload) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.fileUpload.label,
          //       placeholder: json.fileUpload.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.fileUpload.description,
          //       instruction: json.fileUpload.instruction,
          //       required: json.fileUpload.required,
          //       readOnly: json.fileUpload.readOnly,
          //       showCondition: json.fileUpload.showCondition,
          //       ruleExpression: json.fileUpload.ruleExpression,
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.fileUpload.label,
            placeholder: json.fileUpload.placeholder,
            order: val.lastDroppedItem.length,
            description: json.fileUpload.description,
            instruction: json.fileUpload.instruction,
            required: json.fileUpload.required,
            readOnly: json.fileUpload.readOnly,
            showCondition: json.fileUpload.showCondition,
            ruleExpression: json.fileUpload.ruleExpression,
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.bodyMapDisplay) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.bodyMapDisplay.label,
          //       placeholder: json.bodyMapDisplay.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.bodyMapDisplay.description,
          //       instruction: json.bodyMapDisplay.instruction,
          //       required: json.bodyMapDisplay.required,
          //       readOnly: json.bodyMapDisplay.readOnly,
          //       showCondition: json.bodyMapDisplay.showCondition,
          //       ruleExpression: json.bodyMapDisplay.ruleExpression,
          //       value: json.bodyMapDisplay.value,
          //       title: json.bodyMapDisplay.title,
          //       view: json.bodyMapDisplay.view
          //     },
          //   ],
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.bodyMapDisplay.label,
            placeholder: json.bodyMapDisplay.placeholder,
            order: val.lastDroppedItem.length,
            description: json.bodyMapDisplay.description,
            instruction: json.bodyMapDisplay.instruction,
            required: json.bodyMapDisplay.required,
            readOnly: json.bodyMapDisplay.readOnly,
            showCondition: json.bodyMapDisplay.showCondition,
            ruleExpression: json.bodyMapDisplay.ruleExpression,
            value: json.bodyMapDisplay.value,
            title: json.bodyMapDisplay.title,
            view: json.bodyMapDisplay.view
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.bodyMapInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.bodyMapInput.label,
          //       placeholder: json.bodyMapInput.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.bodyMapInput.description,
          //       instruction: json.bodyMapInput.instruction,
          //       required: json.bodyMapInput.required,
          //       readOnly: json.bodyMapInput.readOnly,
          //       showCondition: json.bodyMapInput.showCondition,
          //       ruleExpression: json.bodyMapInput.ruleExpression,
          //       value: json.bodyMapInput.value
          //     },
          //   ],
          // };

          let droppedData = [];
          let addedObj = {
            ...addItem,
            label: json.bodyMapInput.label,
            placeholder: json.bodyMapInput.placeholder,
            order: val.lastDroppedItem.length,
            description: json.bodyMapInput.description,
            instruction: json.bodyMapInput.instruction,
            required: json.bodyMapInput.required,
            readOnly: json.bodyMapInput.readOnly,
            showCondition: json.bodyMapInput.showCondition,
            ruleExpression: json.bodyMapInput.ruleExpression,
            value: json.bodyMapInput.value,
          };
          if (orderChange) {
            droppedData = [...val.lastDroppedItem];
            droppedData.splice(Number(orderChange) - 1, 0, addedObj);
          } else {
            droppedData = [...val.lastDroppedItem, addedObj];
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.fileDownload) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: [
          //     ...val.lastDroppedItem,
          //     {
          //       ...addItem,
          //       label: json.fileDownload.label,
          //       placeholder: json.fileDownload.placeholder,
          //       order: val.lastDroppedItem.length,
          //       description: json.fileDownload.description,
          //       instruction: json.fileDownload.instruction,
          //       required: json.fileDownload.required,
          //       readOnly: json.fileDownload.readOnly,
          //       showCondition: json.fileDownload.showCondition,
          //       ruleExpression: json.fileDownload.ruleExpression,
          //       value: json.fileDownload.value
          //     },
          //   ],
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.fileDownload.label,
            placeholder: json.fileDownload.placeholder,
            order: val.lastDroppedItem.length,
            description: json.fileDownload.description,
            instruction: json.fileDownload.instruction,
            required: json.fileDownload.required,
            readOnly: json.fileDownload.readOnly,
            showCondition: json.fileDownload.showCondition,
            ruleExpression: json.fileDownload.ruleExpression,
            value: json.fileDownload.value
          }

          if (orderChange) {
            droppedData = [...val.lastDroppedItem]
            droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          } else {
            droppedData = [
              ...val.lastDroppedItem,
              addedObj
            ]
          }
          return {
            ...val,
            lastDroppedItem: droppedData
          }

        }
        return val;
      });
      setDustbins(data);
    }
  };

  const editDustbinsData = () => {
    console.log("edit is called")
    if (addItem.type === HTML_NAMES.textInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.textInput.label,
          //         placeholder: json.textInput.placeholder,
          //         description: json.textInput.description,
          //         instruction: json.textInput.instruction,
          //         required: json.textInput.required,
          //         readOnly: json.textInput.readOnly,
          //         showCondition: json.textInput.showCondition,
          //         ruleExpression: json.textInput.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.textInput.label,
            placeholder: json.textInput.placeholder,
            order: val.lastDroppedItem.length,
            description: json.textInput.description,
            instruction: json.textInput.instruction,
            required: json.textInput.required,
            readOnly: json.textInput.readOnly,
            showCondition: json.textInput.showCondition,
            ruleExpression: json.textInput.ruleExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.textInput.label,
                  placeholder: json.textInput.placeholder,
                  description: json.textInput.description,
                  instruction: json.textInput.instruction,
                  required: json.textInput.required,
                  readOnly: json.textInput.readOnly,
                  showCondition: json.textInput.showCondition,
                  ruleExpression: json.textInput.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.numberInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.numberInput.label,
          //         placeholder: json.numberInput.placeholder,
          //         description: json.numberInput.description,
          //         instruction: json.numberInput.instruction,
          //         required: json.numberInput.required,
          //         readOnly: json.numberInput.readOnly,
          //         showCondition: json.numberInput.showCondition,
          //         ruleExpression: json.numberInput.ruleExpression,
          //         evaluateCondition: json.numberInput.evaluateCondition,
          //         evaluateExpression: json.numberInput.evaluateExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.numberInput.label,
            placeholder: json.numberInput.placeholder,
            description: json.numberInput.description,
            instruction: json.numberInput.instruction,
            required: json.numberInput.required,
            readOnly: json.numberInput.readOnly,
            showCondition: json.numberInput.showCondition,
            ruleExpression: json.numberInput.ruleExpression,
            evaluateCondition: json.numberInput.evaluateCondition,
            evaluateExpression: json.numberInput.evaluateExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.numberInput.label,
                  placeholder: json.numberInput.placeholder,
                  description: json.numberInput.description,
                  instruction: json.numberInput.instruction,
                  required: json.numberInput.required,
                  readOnly: json.numberInput.readOnly,
                  showCondition: json.numberInput.showCondition,
                  ruleExpression: json.numberInput.ruleExpression,
                  evaluateCondition: json.numberInput.evaluateCondition,
                  evaluateExpression: json.numberInput.evaluateExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]

          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.decimalInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.decimalInput.label,
          //         placeholder: json.decimalInput.placeholder,
          //         description: json.decimalInput.description,
          //         instruction: json.decimalInput.instruction,
          //         required: json.decimalInput.required,
          //         readOnly: json.decimalInput.readOnly,
          //         showCondition: json.decimalInput.showCondition,
          //         ruleExpression: json.decimalInput.ruleExpression,
          //         evaluateCondition: json.decimalInput.evaluateCondition,
          //         evaluateExpression: json.decimalInput.evaluateExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.decimalInput.label,
            placeholder: json.decimalInput.placeholder,
            description: json.decimalInput.description,
            instruction: json.decimalInput.instruction,
            required: json.decimalInput.required,
            readOnly: json.decimalInput.readOnly,
            showCondition: json.decimalInput.showCondition,
            ruleExpression: json.decimalInput.ruleExpression,
            evaluateCondition: json.decimalInput.evaluateCondition,
            evaluateExpression: json.decimalInput.evaluateExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.decimalInput.label,
                  placeholder: json.decimalInput.placeholder,
                  description: json.decimalInput.description,
                  instruction: json.decimalInput.instruction,
                  required: json.decimalInput.required,
                  readOnly: json.decimalInput.readOnly,
                  showCondition: json.decimalInput.showCondition,
                  ruleExpression: json.decimalInput.ruleExpression,
                  evaluateCondition: json.decimalInput.evaluateCondition,
                  evaluateExpression: json.decimalInput.evaluateExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.headingText) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         html: json.headingText.tag,
          //         value: json.headingText.label,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            html: json.headingText.tag,
            value: json.headingText.label,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  html: json.headingText.tag,
                  value: json.headingText.label,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.dropdown) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         options: json.dropdown.options,
          //         label: json.dropdown.label,
          //         description: json.dropdown.description,
          //         instruction: json.dropdown.instruction,
          //         required: json.dropdown.required,
          //         readOnly: json.dropdown.readOnly,
          //         showCondition: json.dropdown.showCondition,
          //         ruleExpression: json.dropdown.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.dropdown.options,
            label: json.dropdown.label,
            description: json.dropdown.description,
            instruction: json.dropdown.instruction,
            required: json.dropdown.required,
            readOnly: json.dropdown.readOnly,
            showCondition: json.dropdown.showCondition,
            ruleExpression: json.dropdown.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  options: json.dropdown.options,
                  label: json.dropdown.label,
                  description: json.dropdown.description,
                  instruction: json.dropdown.instruction,
                  required: json.dropdown.required,
                  readOnly: json.dropdown.readOnly,
                  showCondition: json.dropdown.showCondition,
                  ruleExpression: json.dropdown.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.multidropdown) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         options: json.multidropdown.options,
          //         label: json.multidropdown.label,
          //         description: json.multidropdown.description,
          //         value: json.multidropdown.value,
          //         instruction: json.multidropdown.instruction,
          //         required: json.multidropdown.required,
          //         readOnly: json.multidropdown.readOnly,
          //         showCondition: json.multidropdown.showCondition,
          //         ruleExpression: json.multidropdown.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.multidropdown.options,
            label: json.multidropdown.label,
            description: json.multidropdown.description,
            value: json.multidropdown.value,
            instruction: json.multidropdown.instruction,
            required: json.multidropdown.required,
            readOnly: json.multidropdown.readOnly,
            showCondition: json.multidropdown.showCondition,
            ruleExpression: json.multidropdown.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  options: json.multidropdown.options,
                  label: json.multidropdown.label,
                  description: json.multidropdown.description,
                  value: json.multidropdown.value,
                  instruction: json.multidropdown.instruction,
                  required: json.multidropdown.required,
                  readOnly: json.multidropdown.readOnly,
                  showCondition: json.multidropdown.showCondition,
                  ruleExpression: json.multidropdown.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.switch) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.switch.label,
          //         description: json.switch.description,
          //         instruction: json.switch.instruction,
          //         required: json.switch.required,
          //         readOnly: json.switch.readOnly,
          //         showCondition: json.switch.showCondition,
          //         ruleExpression: json.switch.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.switch.label,
            description: json.switch.description,
            instruction: json.switch.instruction,
            required: json.switch.required,
            readOnly: json.switch.readOnly,
            showCondition: json.switch.showCondition,
            ruleExpression: json.switch.ruleExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.switch.label,
                  description: json.switch.description,
                  instruction: json.switch.instruction,
                  required: json.switch.required,
                  readOnly: json.switch.readOnly,
                  showCondition: json.switch.showCondition,
                  ruleExpression: json.switch.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]

          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.hyperLink) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.hyperLink.label,
          //         value: json.hyperLink.value,
          //         placeholder: json.hyperLink.placeholder,
          //         description: json.hyperLink.description,
          //         instruction: json.hyperLink.instruction,
          //         required: json.hyperLink.required,
          //         readOnly: json.hyperLink.readOnly,
          //         showCondition: json.hyperLink.showCondition,
          //         ruleExpression: json.hyperLink.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.hyperLink.label,
            value: json.hyperLink.value,
            placeholder: json.hyperLink.placeholder,
            description: json.hyperLink.description,
            instruction: json.hyperLink.instruction,
            required: json.hyperLink.required,
            readOnly: json.hyperLink.readOnly,
            showCondition: json.hyperLink.showCondition,
            ruleExpression: json.hyperLink.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.hyperLink.label,
                  value: json.hyperLink.value,
                  placeholder: json.hyperLink.placeholder,
                  description: json.hyperLink.description,
                  instruction: json.hyperLink.instruction,
                  required: json.hyperLink.required,
                  readOnly: json.hyperLink.readOnly,
                  showCondition: json.hyperLink.showCondition,
                  ruleExpression: json.hyperLink.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.button) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,

          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.button.label,
          //         html: json.button.type,
          //         description: json.button.description,
          //         instruction: json.button.instruction,
          //         required: json.button.required,
          //         readOnly: json.button.readOnly,
          //         showCondition: json.button.showCondition,
          //         ruleExpression: json.button.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.button.label,
            html: json.button.type,
            description: json.button.description,
            instruction: json.button.instruction,
            required: json.button.required,
            readOnly: json.button.readOnly,
            showCondition: json.button.showCondition,
            ruleExpression: json.button.ruleExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.button.label,
                  html: json.button.type,
                  description: json.button.description,
                  instruction: json.button.instruction,
                  required: json.button.required,
                  readOnly: json.button.readOnly,
                  showCondition: json.button.showCondition,
                  ruleExpression: json.button.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.radioGroup) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         options: json.radioGroup.options,
          //         label: json.radioGroup.label,
          //         description: json.radioGroup.description,
          //         instruction: json.radioGroup.instruction,
          //         required: json.radioGroup.required,
          //         readOnly: json.radioGroup.readOnly,
          //         showCondition: json.radioGroup.showCondition,
          //         ruleExpression: json.radioGroup.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.radioGroup.options,
            label: json.radioGroup.label,
            description: json.radioGroup.description,
            instruction: json.radioGroup.instruction,
            required: json.radioGroup.required,
            readOnly: json.radioGroup.readOnly,
            showCondition: json.radioGroup.showCondition,
            ruleExpression: json.radioGroup.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  options: json.radioGroup.options,
                  label: json.radioGroup.label,
                  description: json.radioGroup.description,
                  instruction: json.radioGroup.instruction,
                  required: json.radioGroup.required,
                  readOnly: json.radioGroup.readOnly,
                  showCondition: json.radioGroup.showCondition,
                  ruleExpression: json.radioGroup.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.buttonGroup) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         options: json.buttonGroup.options,
          //         label: json.buttonGroup.label,
          //         description: json.buttonGroup.description,
          //         instruction: json.buttonGroup.instruction,
          //         required: json.buttonGroup.required,
          //         readOnly: json.buttonGroup.readOnly,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.buttonGroup.options,
            label: json.buttonGroup.label,
            description: json.buttonGroup.description,
            instruction: json.buttonGroup.instruction,
            required: json.buttonGroup.required,
            readOnly: json.buttonGroup.readOnly,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  options: json.buttonGroup.options,
                  label: json.buttonGroup.label,
                  description: json.buttonGroup.description,
                  instruction: json.buttonGroup.instruction,
                  required: json.buttonGroup.required,
                  readOnly: json.buttonGroup.readOnly,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.checkbox) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         options: json.checkbox.options,
          //         label: json.checkbox.label,
          //         description: json.checkbox.description,
          //         instruction: json.checkbox.instruction,
          //         required: json.checkbox.required,
          //         readOnly: json.checkbox.readOnly,
          //         showCondition: json.checkbox.showCondition,
          //         ruleExpression: json.checkbox.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            options: json.checkbox.options,
            label: json.checkbox.label,
            description: json.checkbox.description,
            instruction: json.checkbox.instruction,
            required: json.checkbox.required,
            readOnly: json.checkbox.readOnly,
            showCondition: json.checkbox.showCondition,
            ruleExpression: json.checkbox.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  options: json.checkbox.options,
                  label: json.checkbox.label,
                  description: json.checkbox.description,
                  instruction: json.checkbox.instruction,
                  required: json.checkbox.required,
                  readOnly: json.checkbox.readOnly,
                  showCondition: json.checkbox.showCondition,
                  ruleExpression: json.checkbox.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.textArea) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.textArea.label,
          //         placeholder: json.textArea.placeholder,
          //         html: json.textArea.numberOfRows,
          //         description: json.textArea.description,
          //         instruction: json.textArea.instruction,
          //         required: json.textArea.required,
          //         readOnly: json.textArea.readOnly,
          //         showCondition: json.textArea.showCondition,
          //         ruleExpression: json.textArea.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.textArea.label,
            placeholder: json.textArea.placeholder,
            html: json.textArea.numberOfRows,
            description: json.textArea.description,
            instruction: json.textArea.instruction,
            required: json.textArea.required,
            readOnly: json.textArea.readOnly,
            showCondition: json.textArea.showCondition,
            ruleExpression: json.textArea.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.textArea.label,
                  placeholder: json.textArea.placeholder,
                  html: json.textArea.numberOfRows,
                  description: json.textArea.description,
                  instruction: json.textArea.instruction,
                  required: json.textArea.required,
                  readOnly: json.textArea.readOnly,
                  showCondition: json.textArea.showCondition,
                  ruleExpression: json.textArea.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.table) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.table.label,
          //         placeholder: json.table.placeholder,
          //         html: json.table.numberOfRows,
          //         cols: json.table.cols,
          //         rows: json.table.rows,
          //         description: json.table.description,
          //         instruction: json.table.instruction,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.table.label,
            placeholder: json.table.placeholder,
            html: json.table.numberOfRows,
            cols: json.table.cols,
            rows: json.table.rows,
            description: json.table.description,
            instruction: json.table.instruction,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.table.label,
                  placeholder: json.table.placeholder,
                  html: json.table.numberOfRows,
                  cols: json.table.cols,
                  rows: json.table.rows,
                  description: json.table.description,
                  instruction: json.table.instruction,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.section) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.section.label,
          //         placeholder: json.section.placeholder,
          //         sectionColsCount: json.section.sectionColsCount,
          //         cols: json.section.cols,
          //         rows: json.section.rows,
          //         showCondition: json.section.showCondition,
          //         ruleExpression: json.section.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.section.label,
            placeholder: json.section.placeholder,
            sectionColsCount: json.section.sectionColsCount,
            cols: json.section.cols,
            rows: json.section.rows,
            showCondition: json.section.showCondition,
            ruleExpression: json.section.ruleExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.section.label,
                  placeholder: json.section.placeholder,
                  sectionColsCount: json.section.sectionColsCount,
                  cols: json.section.cols,
                  rows: json.section.rows,
                  showCondition: json.section.showCondition,
                  ruleExpression: json.section.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.choices) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
        //     if (value.id === addItem.id) {
        //       return {
        //         ...value,
        //         label: json.choices.label,
        //         description: json.choices.description,
        //         instruction: json.choices.instruction,
        //         required: json.choices.required,
        //         readOnly: json.choices.readOnly,
        //         is_multi: json.choices.is_multi,
        //         showCondition: json.choices.showCondition,
        //         ruleExpression: json.choices.ruleExpression,
        //       };
        //     }
        //     return value;
        //   }),
        // };
        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.choices.label,
          description: json.choices.description,
          instruction: json.choices.instruction,
          required: json.choices.required,
          readOnly: json.choices.readOnly,
          is_multi: json.choices.is_multi,
          showCondition: json.choices.showCondition,
          ruleExpression: json.choices.ruleExpression,
        }
        if (orderChange) {
          // droppedData = [...val.lastDroppedItem]
          // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          droppedData = [...val.lastDroppedItem];
          const newIndex = Number(orderChange) - 1;
          if (newIndex >= 0 && newIndex <= droppedData.length) {
            const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
            if (existingIndex !== -1) {
              droppedData.splice(existingIndex, 1);
            }
            droppedData.splice(newIndex, 0, addedObj);
          } else {
            droppedData.push(addedObj);
          }
        } else {
          let noOrderdata = val.lastDroppedItem.map((value, index) => {
            if (value.id === addItem.id) {
              return {
                ...value,
                label: json.choices.label,
                description: json.choices.description,
                instruction: json.choices.instruction,
                required: json.choices.required,
                readOnly: json.choices.readOnly,
                is_multi: json.choices.is_multi,
                showCondition: json.choices.showCondition,
                ruleExpression: json.choices.ruleExpression,
              };
            }
            return value;
          })
          droppedData = [...noOrderdata]
        }
        return {
          ...val,
          lastDroppedItem: droppedData,
        };
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.datePicker) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
        //     if (value.id === addItem.id) {
        //       return {
        //         ...value,
        //         label: json.datePicker.label,
        //         description: json.datePicker.description,
        //         instruction: json.datePicker.instruction,
        //         required: json.datePicker.required,
        //         readOnly: json.datePicker.readOnly,
        //         showCondition: json.datePicker.showCondition,
        //         ruleExpression: json.datePicker.ruleExpression,
        //       };
        //     }
        //     return value;
        //   }),
        // };
        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.datePicker.label,
          description: json.datePicker.description,
          instruction: json.datePicker.instruction,
          required: json.datePicker.required,
          readOnly: json.datePicker.readOnly,
          showCondition: json.datePicker.showCondition,
          ruleExpression: json.datePicker.ruleExpression,
          format: json.datePicker.format
        }
        if (orderChange) {
          // droppedData = [...val.lastDroppedItem]
          // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          droppedData = [...val.lastDroppedItem];
          const newIndex = Number(orderChange) - 1;
          if (newIndex >= 0 && newIndex <= droppedData.length) {
            const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
            if (existingIndex !== -1) {
              droppedData.splice(existingIndex, 1);
            }
            droppedData.splice(newIndex, 0, addedObj);
          } else {
            droppedData.push(addedObj);
          }
        } else {
          let noOrderdata = val.lastDroppedItem.map((value, index) => {
            if (value.id === addItem.id) {
              return {
                ...value,
                label: json.datePicker.label,
                description: json.datePicker.description,
                instruction: json.datePicker.instruction,
                required: json.datePicker.required,
                readOnly: json.datePicker.readOnly,
                showCondition: json.datePicker.showCondition,
                ruleExpression: json.datePicker.ruleExpression,
                format: json.datePicker.format
              };
            }
            return value;
          })
          droppedData = [...noOrderdata]
        }
        return {
          ...val,
          lastDroppedItem: droppedData,
        };
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.timePicker) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,

        //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
        //     if (value.id === addItem.id) {
        //       return {
        //         ...value,
        //         label: json.timePicker.label,
        //         description: json.timePicker.description,
        //         instruction: json.timePicker.instruction,
        //         required: json.timePicker.required,
        //         readOnly: json.timePicker.readOnly,
        //         showCondition: json.timePicker.showCondition,
        //         ruleExpression: json.timePicker.ruleExpression,
        //       };
        //     }
        //     return value;
        //   }),
        // };

        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.timePicker.label,
          description: json.timePicker.description,
          instruction: json.timePicker.instruction,
          required: json.timePicker.required,
          readOnly: json.timePicker.readOnly,
          showCondition: json.timePicker.showCondition,
          ruleExpression: json.timePicker.ruleExpression,
          format: json.timePicker.format
        }
        if (orderChange) {
          // droppedData = [...val.lastDroppedItem]
          // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          droppedData = [...val.lastDroppedItem];
          const newIndex = Number(orderChange) - 1;
          if (newIndex >= 0 && newIndex <= droppedData.length) {
            const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
            if (existingIndex !== -1) {
              droppedData.splice(existingIndex, 1);
            }
            droppedData.splice(newIndex, 0, addedObj);
          } else {
            droppedData.push(addedObj);
          }
        } else {
          let noOrderdata = val.lastDroppedItem.map((value, index) => {
            if (value.id === addItem.id) {
              return {
                ...value,
                label: json.timePicker.label,
                description: json.timePicker.description,
                instruction: json.timePicker.instruction,
                required: json.timePicker.required,
                readOnly: json.timePicker.readOnly,
                showCondition: json.timePicker.showCondition,
                ruleExpression: json.timePicker.ruleExpression,
                format: json.timePicker.format
              };
            }
            return value;
          })
          droppedData = [...noOrderdata]
        }
        return {
          ...val,
          lastDroppedItem: droppedData,
        };
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.dateTime) {
      let data = dustbins.map(val => {
        // return {
        //   ...val,
        //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
        //     if (value.id === addItem.id) {
        //       return {
        //         ...value,
        //         label: json.dateTimePicker.label,
        //         description: json.dateTimePicker.description,
        //         instruction: json.dateTimePicker.instruction,
        //         required: json.dateTimePicker.required,
        //         readOnly: json.dateTimePicker.readOnly,
        //         showCondition: json.dateTimePicker.showCondition,
        //         ruleExpression: json.dateTimePicker.ruleExpression,
        //       };
        //     }
        //     return value;
        //   }),
        // };
        let droppedData = []
        let addedObj = {
          ...addItem,
          label: json.dateTimePicker.label,
          description: json.dateTimePicker.description,
          instruction: json.dateTimePicker.instruction,
          required: json.dateTimePicker.required,
          readOnly: json.dateTimePicker.readOnly,
          showCondition: json.dateTimePicker.showCondition,
          ruleExpression: json.dateTimePicker.ruleExpression,
          format: json.dateTimePicker.format
        }
        if (orderChange) {
          // droppedData = [...val.lastDroppedItem]
          // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
          droppedData = [...val.lastDroppedItem];
          const newIndex = Number(orderChange) - 1;
          if (newIndex >= 0 && newIndex <= droppedData.length) {
            const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
            if (existingIndex !== -1) {
              droppedData.splice(existingIndex, 1);
            }
            droppedData.splice(newIndex, 0, addedObj);
          } else {
            droppedData.push(addedObj);
          }
        } else {
          let noOrderdata = val.lastDroppedItem.map((value, index) => {
            if (value.id === addItem.id) {
              return {
                ...value,
                label: json.dateTimePicker.label,
                description: json.dateTimePicker.description,
                instruction: json.dateTimePicker.instruction,
                required: json.dateTimePicker.required,
                readOnly: json.dateTimePicker.readOnly,
                showCondition: json.dateTimePicker.showCondition,
                ruleExpression: json.dateTimePicker.ruleExpression,
                format: json.dateTimePicker.format
              };
            }
            return value;
          })
          droppedData = [...noOrderdata]
        }
        return {
          ...val,
          lastDroppedItem: droppedData,
        };
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.signature) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,

          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.signature.label,
          //         html: json.signature.type,
          //         description: json.signature.description,

          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.signature.label,
            html: json.signature.type,
            description: json.signature.description,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.signature.label,
                  html: json.signature.type,
                  description: json.signature.description,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.richTextHtmlInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.richTextHtmlInput.label,
          //         cols: json.richTextHtmlInput.cols,
          //         placeholder: json.richTextHtmlInput.placeholder,
          //         description: json.richTextHtmlInput.description,
          //         instruction: json.richTextHtmlInput.instruction,
          //         required: json.richTextHtmlInput.required,
          //         readOnly: json.richTextHtmlInput.readOnly,
          //         showCondition: json.richTextHtmlInput.showCondition,
          //         ruleExpression: json.richTextHtmlInput.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.richTextHtmlInput.label,
            cols: json.richTextHtmlInput.cols,
            placeholder: json.richTextHtmlInput.placeholder,
            description: json.richTextHtmlInput.description,
            instruction: json.richTextHtmlInput.instruction,
            required: json.richTextHtmlInput.required,
            readOnly: json.richTextHtmlInput.readOnly,
            showCondition: json.richTextHtmlInput.showCondition,
            ruleExpression: json.richTextHtmlInput.ruleExpression,
          }

          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.richTextHtmlInput.label,
                  cols: json.richTextHtmlInput.cols,
                  placeholder: json.richTextHtmlInput.placeholder,
                  description: json.richTextHtmlInput.description,
                  instruction: json.richTextHtmlInput.instruction,
                  required: json.richTextHtmlInput.required,
                  readOnly: json.richTextHtmlInput.readOnly,
                  showCondition: json.richTextHtmlInput.showCondition,
                  ruleExpression: json.richTextHtmlInput.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }

          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    }
    else if (addItem.type === HTML_NAMES.richTextHtmlHeading) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.richTextHtmlHeading.label,
          //         placeholder: json.richTextHtmlHeading.placeholder,
          //         description: json.richTextHtmlHeading.description,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.richTextHtmlHeading.label,
            placeholder: json.richTextHtmlHeading.placeholder,
            description: json.richTextHtmlHeading.description,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.richTextHtmlHeading.label,
                  placeholder: json.richTextHtmlHeading.placeholder,
                  description: json.richTextHtmlHeading.description,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.photoAttach) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.photoAttach.label,
          //         file: json.photoAttach.file,
          //         placeholder: json.photoAttach.placeholder,
          //         description: json.photoAttach.description,
          //         instruction: json.photoAttach.instruction,
          //         required: json.photoAttach.required,
          //         readOnly: json.photoAttach.readOnly,
          //         showCondition: json.photoAttach.showCondition,
          //         ruleExpression: json.photoAttach.ruleExpression,
          //         value: json.photoAttach.value
          //       };
          //     }
          //     return value;
          //   }),
          // };

          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.photoAttach.label,
            file: json.photoAttach.file,
            placeholder: json.photoAttach.placeholder,
            description: json.photoAttach.description,
            instruction: json.photoAttach.instruction,
            required: json.photoAttach.required,
            readOnly: json.photoAttach.readOnly,
            showCondition: json.photoAttach.showCondition,
            ruleExpression: json.photoAttach.ruleExpression,
            value: json.photoAttach.value
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.photoAttach.label,
                  file: json.photoAttach.file,
                  placeholder: json.photoAttach.placeholder,
                  description: json.photoAttach.description,
                  instruction: json.photoAttach.instruction,
                  required: json.photoAttach.required,
                  readOnly: json.photoAttach.readOnly,
                  showCondition: json.photoAttach.showCondition,
                  ruleExpression: json.photoAttach.ruleExpression,
                  value: json.photoAttach.value
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.fileUpload) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.fileUpload.label,
          //         placeholder: json.fileUpload.placeholder,
          //         description: json.fileUpload.description,
          //         instruction: json.fileUpload.instruction,
          //         required: json.fileUpload.required,
          //         readOnly: json.fileUpload.readOnly,
          //         showCondition: json.fileUpload.showCondition,
          //         ruleExpression: json.fileUpload.ruleExpression,
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.fileUpload.label,
            placeholder: json.fileUpload.placeholder,
            description: json.fileUpload.description,
            instruction: json.fileUpload.instruction,
            required: json.fileUpload.required,
            readOnly: json.fileUpload.readOnly,
            showCondition: json.fileUpload.showCondition,
            ruleExpression: json.fileUpload.ruleExpression,
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.fileUpload.label,
                  placeholder: json.fileUpload.placeholder,
                  description: json.fileUpload.description,
                  instruction: json.fileUpload.instruction,
                  required: json.fileUpload.required,
                  readOnly: json.fileUpload.readOnly,
                  showCondition: json.fileUpload.showCondition,
                  ruleExpression: json.fileUpload.ruleExpression,
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.fileDownload) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.fileDownload.label,
          //         placeholder: json.fileDownload.placeholder,
          //         description: json.fileDownload.description,
          //         instruction: json.fileDownload.instruction,
          //         required: json.fileDownload.required,
          //         readOnly: json.fileDownload.readOnly,
          //         showCondition: json.fileDownload.showCondition,
          //         ruleExpression: json.fileDownload.ruleExpression,
          //         value: json.fileDownload.value
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.fileDownload.label,
            placeholder: json.fileDownload.placeholder,
            description: json.fileDownload.description,
            instruction: json.fileDownload.instruction,
            required: json.fileDownload.required,
            readOnly: json.fileDownload.readOnly,
            showCondition: json.fileDownload.showCondition,
            ruleExpression: json.fileDownload.ruleExpression,
            value: json.fileDownload.value
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.fileDownload.label,
                  placeholder: json.fileDownload.placeholder,
                  description: json.fileDownload.description,
                  instruction: json.fileDownload.instruction,
                  required: json.fileDownload.required,
                  readOnly: json.fileDownload.readOnly,
                  showCondition: json.fileDownload.showCondition,
                  ruleExpression: json.fileDownload.ruleExpression,
                  value: json.fileDownload.value
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.bodyMapDisplay) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.bodyMapDisplay.label,
          //         placeholder: json.bodyMapDisplay.placeholder,
          //         description: json.bodyMapDisplay.description,
          //         instruction: json.bodyMapDisplay.instruction,
          //         required: json.bodyMapDisplay.required,
          //         readOnly: json.bodyMapDisplay.readOnly,
          //         showCondition: json.bodyMapDisplay.showCondition,
          //         ruleExpression: json.bodyMapDisplay.ruleExpression,
          //         value: json.bodyMapDisplay.value,
          //         title: json.bodyMapDisplay.title,
          //         view: json.bodyMapDisplay.view
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.bodyMapDisplay.label,
            placeholder: json.bodyMapDisplay.placeholder,
            description: json.bodyMapDisplay.description,
            instruction: json.bodyMapDisplay.instruction,
            required: json.bodyMapDisplay.required,
            readOnly: json.bodyMapDisplay.readOnly,
            showCondition: json.bodyMapDisplay.showCondition,
            ruleExpression: json.bodyMapDisplay.ruleExpression,
            value: json.bodyMapDisplay.value,
            title: json.bodyMapDisplay.title,
            view: json.bodyMapDisplay.view
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.bodyMapDisplay.label,
                  placeholder: json.bodyMapDisplay.placeholder,
                  description: json.bodyMapDisplay.description,
                  instruction: json.bodyMapDisplay.instruction,
                  required: json.bodyMapDisplay.required,
                  readOnly: json.bodyMapDisplay.readOnly,
                  showCondition: json.bodyMapDisplay.showCondition,
                  ruleExpression: json.bodyMapDisplay.ruleExpression,
                  value: json.bodyMapDisplay.value,
                  title: json.bodyMapDisplay.title,
                  view: json.bodyMapDisplay.view
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    } else if (addItem.type === HTML_NAMES.bodyMapInput) {
      let data = dustbins.map((val, indexInn) => {
        if (indexInn === addItem.index) {
          // return {
          //   ...val,
          //   lastDroppedItem: val.lastDroppedItem.map((value, index) => {
          //     if (value.id === addItem.id) {
          //       return {
          //         ...value,
          //         label: json.bodyMapInput.label,
          //         placeholder: json.bodyMapInput.placeholder,
          //         description: json.bodyMapInput.description,
          //         instruction: json.bodyMapInput.instruction,
          //         required: json.bodyMapInput.required,
          //         readOnly: json.bodyMapInput.readOnly,
          //         showCondition: json.bodyMapInput.showCondition,
          //         ruleExpression: json.bodyMapInput.ruleExpression,
          //         value: json.bodyMapInput.value
          //       };
          //     }
          //     return value;
          //   }),
          // };
          let droppedData = []
          let addedObj = {
            ...addItem,
            label: json.bodyMapInput.label,
            placeholder: json.bodyMapInput.placeholder,
            description: json.bodyMapInput.description,
            instruction: json.bodyMapInput.instruction,
            required: json.bodyMapInput.required,
            readOnly: json.bodyMapInput.readOnly,
            showCondition: json.bodyMapInput.showCondition,
            ruleExpression: json.bodyMapInput.ruleExpression,
            value: json.bodyMapInput.value
          }
          if (orderChange) {
            // droppedData = [...val.lastDroppedItem]
            // droppedData.splice(Number(orderChange) - 1, 0, addedObj)
            droppedData = [...val.lastDroppedItem];
            const newIndex = Number(orderChange) - 1;
            if (newIndex >= 0 && newIndex <= droppedData.length) {
              const existingIndex = droppedData.findIndex(item => item.id === addedObj.id);
              if (existingIndex !== -1) {
                droppedData.splice(existingIndex, 1);
              }
              droppedData.splice(newIndex, 0, addedObj);
            } else {
              droppedData.push(addedObj);
            }
          } else {
            let noOrderdata = val.lastDroppedItem.map((value, index) => {
              if (value.id === addItem.id) {
                return {
                  ...value,
                  label: json.bodyMapInput.label,
                  placeholder: json.bodyMapInput.placeholder,
                  description: json.bodyMapInput.description,
                  instruction: json.bodyMapInput.instruction,
                  required: json.bodyMapInput.required,
                  readOnly: json.bodyMapInput.readOnly,
                  showCondition: json.bodyMapInput.showCondition,
                  ruleExpression: json.bodyMapInput.ruleExpression,
                  value: json.bodyMapInput.value
                };
              }
              return value;
            })
            droppedData = [...noOrderdata]
          }
          return {
            ...val,
            lastDroppedItem: droppedData,
          };
        }
        return val;
      });
      setDustbins(data);
    }
  };
  // validations checked via addItem.type === HTML_NAMES._____
  const validations = () => {
    if (addItem.type === HTML_NAMES.textInput) {
      if (
        json.textInput.label.trim().length === 0
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.numberInput) {
      if (
        json.numberInput.label.trim().length === 0
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.decimalInput) {
      if (
        json.decimalInput.label.trim().length === 0
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.headingText) {
      if (json.headingText.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.dropdown) {
      if (
        json.dropdown.label.trim().length === 0 ||
        json.dropdown.options.length === 0 ||
        !json.dropdown.options.every(val => val.label)
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.multidropdown) {
      if (
        json.multidropdown.label.trim().length === 0 ||
        json.multidropdown.options.length === 0 ||
        !json.multidropdown.options.every(val => val.label)
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.switch) {
      if (json.switch.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.hyperLink) {
      if (json.hyperLink.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.button) {
      return true;
    } else if (addItem.type === HTML_NAMES.radioGroup) {
      if (
        json.radioGroup.label.trim().length === 0 ||
        json.radioGroup.options.length === 0 ||
        !json.radioGroup.options.every(val => val.value)
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.buttonGroup) {
      return true;
    } else if (addItem.type === HTML_NAMES.checkbox) {
      if (
        json.checkbox.label.trim().length === 0 ||
        json.checkbox.options.length === 0 ||
        !json.checkbox.options.every(val => val.value)
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.textArea) {
      if (
        json.textArea.label.trim().length === 0 ||
        !(Number(json.textArea.numberOfRows) > 0)
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.table) {
      if (
        json.table.cols.length < 1
      ) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.section) {
      if (json.section.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.choices) {
      if (json.choices.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.datePicker) {
      if (json.datePicker.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.timePicker) {
      if (json.timePicker.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.dateTime) {
      if (json.dateTimePicker.label.trim().length === 0) {
        return false;
      }
      return true;
    } else if (addItem.type === HTML_NAMES.signature) {
      return true;
    } else if (addItem.type === HTML_NAMES.richTextHtmlInput) {
      if (
        json.richTextHtmlInput.label.trim().length === 0
      ) {
        return false;
      }
      return true;
    }
    else if (addItem.type === HTML_NAMES.richTextHtmlHeading) {
      return true
    }
    else if (addItem.type === HTML_NAMES.photoAttach) {
      return true
    }
    else if (addItem.type === HTML_NAMES.fileUpload) {
      return true
    }
    else if (addItem.type === HTML_NAMES.fileDownload) {
      return true
    }
    else if (addItem.type === HTML_NAMES.bodyMapDisplay) {
      // if (json.bodyMapDisplay.label.trim().length === 0) {
      //   return false;
      // }
      return true;
    }
    else if (addItem.type === HTML_NAMES.bodyMapInput) {
      if (json.bodyMapInput.label.trim().length === 0) {
        return false;
      }
      return true;
    }
  };
  const handleWarn = () => {
    setModalWarn(false);
    history.push('/form-builder');
  };
  const showWarn = () => {
    setModalWarn(true);
  };

  const inputFile = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleChange = (event) => {
    const uploadedFile = event.target.files[0];
    setErrorMessage(null);

    if (!uploadedFile || uploadedFile.type !== 'application/json') {
      setErrorMessage('Please select a valid JSON file.');
      return;
    }

    const reader = new FileReader();
    reader.readAsText(uploadedFile);
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        setEditId(null);
        setDustbins([
          {
            accepts: [...acceptValue, ...CategoryKeys, ...keys],
            lastDroppedItem: handleDifferentFormat(jsonData),
          },
        ]);
      } catch (error) {
          let userMessage;
          if (error instanceof SyntaxError) {
            let errorPosition = error.message.match(/\d+/) // Extract line/column number
            errorPosition = errorPosition!=null && Array.isArray(errorPosition) && errorPosition[0];
            if(errorPosition)
              userMessage = `Invalid JSON format. Error at line ${errorPosition}.`;
            else userMessage = "Invalid JSON format."
          } else {
            userMessage = 'An error occurred while processing the JSON file.';
            }
          console.error('Error parsing JSON:', error); // Log the detailed error
          setErrorMessage(userMessage);
      }
    };
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          padding: 15,
          justifyContent: 'flex-end',
        }}>
        <TextField
          id="outlined-basic"
          label={categoryBuilder ? 'Category name' : 'Form name'}
          variant="outlined"
          size="small"
          value={formName}
          onChange={event => {
            setFormName(event.target.value);
          }}
        />

        <div className={'ml-0 ml-md-3 mr-2'}>
          <PrimaryButtonForm
            bgcolor="PURPLE_GRAY_500"
            minWidth="6rem"
            className={'mr-2'}
            onClick={showWarn}>
            <span className={'mr-2'}>{'Cancel'}</span>
          </PrimaryButtonForm>
          <PrimaryButtonForm
            bgcolor="PURPLE_GRAY_500"
            minWidth="6rem"
            onClick={onSave}>
            <span className={'mr-2'}>{textStatus}</span>
            {!loadingSave ? (
              buttonIcon
            ) : (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
          </PrimaryButtonForm>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flexBasis: '30%' }}>
          <div
            onClick={() => {
              setShowUiComponent(!showUiComponent);
              setShowChoicesComponent(false);
              setShowCategoryComponent(false);
            }}
            style={{
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingRight: 15,
              justifyContent: 'space-between',
            }}>
            UI Components{' '}
            {showUiComponent ? <GoTriangleUp /> : <GoTriangleDown />}{' '}
          </div>

          {showUiComponent && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {boxes.map(
                (
                  {
                    name,
                    type,
                    html,
                    value,
                    placeholder,
                    required,
                    order,
                    options,
                    choices,
                    id,
                  },
                  index
                ) => (
                  <div style={{ flexBasis: '49%' }}>
                    <Box
                      name={name}
                      id={id}
                      type={type}
                      html={html}
                      key={index}
                      value={value}
                      placeholder={placeholder}
                      required={required}
                      order={order}
                      options={options}
                    />
                  </div>
                )
              )}
            </div>
          )}

          {/* <p style={{ marginBottom: 15 }}>Choices</p> */}
          <div
            onClick={() => {
              setShowChoicesComponent(!showChoiceComponent);
              setShowUiComponent(false);
              setShowCategoryComponent(false);
            }}
            style={{
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingRight: 15,
              justifyContent: 'space-between',
            }}>
            Choices{' '}
            {showChoiceComponent ? <GoTriangleUp /> : <GoTriangleDown />}{' '}
          </div>
          {showChoiceComponent && (
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {choiceBoxes.map(
                  (
                    {
                      name,
                      type,
                      html,
                      value,
                      placeholder,
                      required,
                      order,
                      options,
                      choices,
                      id,
                    },
                    index
                  ) => (
                    <div style={{ flexBasis: '49%' }}>
                      <Box
                        name={name}
                        id={id}
                        type={type}
                        html={html}
                        key={index}
                        value={value}
                        placeholder={placeholder}
                        required={required}
                        order={order}
                        options={options}
                        choices={choices}
                      />
                    </div>
                  )
                )}
              </div>
              {choiceCount > 20 && (
                <div style={{ margin: '10px 18px', textAlign: 'center' }}>
                  <PrimaryButtonForm
                    style={{
                      fontSize: 12,
                      minWidth: '20px',
                      padding: '5px 9px',
                    }}
                    width="84px"
                    className={'mr-1 mt-2'}
                    onClick={() => {
                      choiceBoxes &&
                        choiceBoxes.length &&
                        choiceCount === choiceBoxes.length
                        ? setchoiceLimit(20)
                        : setchoiceLimit(choiceLimit + 5);
                    }}>
                    <span className={'mr-1'}>
                      {choiceBoxes &&
                        choiceBoxes.length &&
                        choiceCount === choiceBoxes.length
                        ? 'SHOW LESS'
                        : 'LOAD MORE'}
                    </span>
                  </PrimaryButtonForm>
                </div>
              )}
            </div>
          )}

          {!categoryBuilder && (
            <>
              <div
                onClick={() => {
                  setShowCategoryComponent(!showCategoryComponent);
                  setShowChoicesComponent(false);
                  setShowUiComponent(false);
                }}
                style={{
                  marginBottom: 15,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: 15,
                  justifyContent: 'space-between',
                }}>
                Form Category{' '}
                {showCategoryComponent ? <GoTriangleUp /> : <GoTriangleDown />}{' '}
              </div>
              {showCategoryComponent && (
                <div>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', height: '20%' }}>
                    {categoriesBoxes.map(
                      (
                        {
                          name,
                          type,
                          html,
                          value,
                          placeholder,
                          required,
                          order,
                          options,
                          choices,
                          id,
                        },
                        index
                      ) => (
                        <div style={{ flexBasis: '49%' }}>
                          <Box
                            name={name}
                            id={id}
                            type={type}
                            html={html}
                            key={index}
                            value={value}
                            placeholder={placeholder}
                            required={required}
                            order={order}
                            options={options}
                            choices={choices}
                          />
                        </div>
                      )
                    )}
                  </div>
                  {categoryCount > 20 && (
                    <div style={{ margin: '10px 18px', textAlign: 'center' }}>
                      <PrimaryButtonForm
                        style={{
                          fontSize: 12,
                          minWidth: '20px',
                          padding: '5px 9px',
                        }}
                        width="84px"
                        className={'mr-1 mt-2'}
                        onClick={() => {
                          categoriesBoxes &&
                            categoriesBoxes.length &&
                            categoryCount === categoriesBoxes.length
                            ? setcategoryLimit(20)
                            : setcategoryLimit(categoryLimit + 5);
                        }}>
                        <span className={'mr-1'}>
                          {categoriesBoxes &&
                            categoriesBoxes.length &&
                            categoryCount === categoriesBoxes.length
                            ? 'SHOW LESS'
                            : 'LOAD MORE'}
                        </span>
                      </PrimaryButtonForm>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {
            !state.editMode && !categoryBuilder &&
            <div>
              <input type='file' id='file' ref={inputFile} style={{display: 'none'}} 
                accept=".json" onChange={handleChange}
              />
              <PrimaryButtonForm
                onClick={()=>inputFile.current.click()}
                minWidth="9rem">
                  Upload existing form
              </PrimaryButtonForm>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
          }
          <div style={{ paddingTop: '30px' }}>
            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >Expression Instructions</label>
            <p>{`Case 1 : If you want to apply equals check
Expression : Field1 == Yes`}
            </p>
            <p>
              {`Case 2 : If you want to apply not equals check
Expression : Field1 != Yes`}
            </p>
            <p>
              {`Case 3 : If you want to apply less than equals to check
Expression : Field1 <= 5`}
            </p>
            <p>
              {` Case 4 : If you want to apply more than equals to check
Expression : Field1 >= 5`}
            </p>
            <p>
              {`Case 5 : If you want to apply less than check
Expression : Field1 < 5`}
            </p>
            <p>
              {`Case 6 : If you want to apply more than check
Expression : Field1 > 5`}
            </p>
            <p>
              {`Case 7 : If you want to apply && operator check
Expression : Field1 > 5 && Field1 < 20` }
            </p>
            <p>
              {`Case 8 : If you want to apply || operator check
Expression : Field1 > 5 || Field1 < 20`}
            </p>
          </div>
        </div>
        <div style={{ flexBasis: '70%' }}>
          {dustbins.map(({ accepts, lastDroppedItem }, index) => {
            return (
              <Dustbin
                accept={accepts}
                lastDroppedItem={lastDroppedItem}
                onDrop={item => {
                  setOrderChange(null)
                  setJson({
                    textInput: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    numberInput: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    decimalInput: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    headingText: {
                      label: "",
                      tag: "h1",
                      description: ""
                    },
                    dropdown: {
                      label: "",
                      options: [],
                      description: "",
                      instruction: ""
                    },
                    multidropdown: {
                      label: "",
                      options: [],
                      description: "",
                      instruction: ""
                    },
                    switch: {
                      label: "",
                      value: false,
                      description: "",
                      instruction: ""
                    },
                    hyperLink: {
                      label: "",
                      value: '',
                      description: "",
                      instruction: ""
                    },
                    button: {
                      label: "",
                      type: "",
                      description: "",
                      instruction: ""
                    },
                    radioGroup: {
                      label: "",
                      options: [],
                      description: "",
                      instruction: ""
                    },
                    buttonGroup: {
                      label: "",
                      options: [],
                      description: "",
                      instruction: ""
                    },
                    checkbox: {
                      label: "",
                      options: [],
                      description: ""
                    },
                    textArea: {
                      label: "",
                      placeholder: "",
                      numberOfRows: 2,
                      description: "",
                      instruction: ""
                    },
                    choices: {
                      label: "",
                      description: "",
                      instruction: "",
                      is_multi: false
                    },
                    datePicker: {
                      label: "",
                      value: false,
                      description: "",
                      instruction: "",
                      format: ""
                    },
                    timePicker: {
                      label: "",
                      value: false,
                      description: "",
                      format: ""
                    },
                    dateTimePicker: {
                      label: "",
                      value: false,
                      description: "",
                      instruction: "",
                      format: ""
                    },
                    signature: {
                      label: "",
                      type: "",
                      description: "",
                      instruction: ""
                    },
                    table: {
                      cols: [{}],
                      rows: [{}],
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    section: {
                      cols: [{ id: 1 }],
                      rows: [{}],
                      sectionColsCount: 1,
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    richTextHtmlInput: {
                      cols: [{}],
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    richTextHtmlHeading: {
                      cols: [{}],
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: ""
                    },
                    fileUpload: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: "",
                      showCondition: false,
                      ruleExpression: ""
                    },
                    fileDownload: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: "",
                      showCondition: false,
                      ruleExpression: ""
                    },
                    bodyMapDisplay: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: "",
                      showCondition: false,
                      ruleExpression: ""
                    },
                    bodyMapInput: {
                      label: "",
                      placeholder: "",
                      description: "",
                      instruction: "",
                      showCondition: false,
                      ruleExpression: ""
                    }
                  })
                  handleDrop(index, item);

                }}
                setDustbins={setDustbins}
                dustbins={dustbins}
                key={index}
                setJson={setJson}
                json={json}
                handleOpen={handleOpen}
                setOpenSignature={setOpenSignature}
                setModalBodyMap={setModalBodyMap}
                contextChoices={contextChoices}
              />
            );
          })}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        disableEnforceFocus
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={open}>
          <MatBox sx={{ ...style, maxHeight: '80vh', overflow: 'scroll' }}>
            <div style={{ marginBottom: 20 }}>
              <h6>{addItem.type}</h6>
              <InputLabelTop
                style={{ paddingTop: 0 }}
                readOnly={true}
                type="text"
                id=""
                label={''}
                value={`Field${addItem.id}`}
                maxlength={100}
              />
              <InputLabelTop
                style={{ paddingTop: 0 }}
                readOnly={false}
                type="number"
                id=""
                label={'Order'}
                value={orderChange}
                maxlength={100}
                onChange={(e) => {
                  setOrderChange(e.target.value)
                }}
              />
            </div>
            <div>
              {addItem.type === HTML_NAMES.textInput && (
                <TextInput setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.numberInput && (
                <NumberInput setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.decimalInput && (
                <DecimalInput setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.headingText && (
                <Heading setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.dropdown && (
                <Dropdown setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.multidropdown && (
                <MultiDropdown setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.switch && (
                <SwitchComp setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.hyperLink && (
                <HyperLinkComp setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.button && (
                <ButtonComponent setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.radioGroup && (
                <RadioGroups setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.buttonGroup && (
                <ButtonGroups setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.checkbox && (
                <CheckboxComponent setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.textArea && (
                <Textarea setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.choices && (
                <ChoicesComp
                  setJson={setJson}
                  json={json}
                  name={addItem.name}
                />
              )}

              {addItem.type === HTML_NAMES.datePicker && (
                <DatePickerComp setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.timePicker && (
                <TimePickerComp setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.dateTime && (
                <DateTimePickerComp setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.signature && (
                <SignatureComponent setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.table && (
                <TableData
                  setJson={setJson}
                  json={json}
                  choiceList={choiceBoxes}
                  contextChoices={contextChoices}
                  InputFields={[{ name: 'Label' }, ...UI_FEILDS, { name: 'Choice Dropdown' }]}
                  setOpenSignature={setOpenSignature}
                />
              )}
              {addItem.type === HTML_NAMES.section && (
                <SectionCom
                  setJson={setJson}
                  json={json}
                  choiceList={choiceBoxes}
                  contextChoices={contextChoices}
                  InputFields={[{ name: 'Label' }, ...UI_FEILDS, { name: 'Choice Dropdown' }]}
                  setOpenSignature={setOpenSignature}
                />
              )}

              {addItem.type === HTML_NAMES.richTextHtmlInput && (
                <RichTextHtmlInput setJson={setJson} json={json} />
              )}

              {addItem.type === HTML_NAMES.richTextHtmlHeading && (
                <RichTextHtmlHeading setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.photoAttach && (
                <PhotoAttachCom setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.fileUpload && (
                <FileUploadCom setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.fileDownload && (
                <FileDownloadCom setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.bodyMapInput && (
                <BodyMapComponent setJson={setJson} json={json} />
              )}
              {addItem.type === HTML_NAMES.bodyMapDisplay && (
                <BodyMapDisplayComponent setJson={setJson} json={json} />
              )}
            </div>
            {error && (
              <p style={{ color: 'red' }}>Please fill all required feilds *</p>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setError(false);
                  handleClose();
                  setAddItem('');
                  setJson(inital);
                }}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (validations()) {
                    if (!matchId(addItem)) {
                      setDustbinsData();
                    } else {
                      editDustbinsData();
                    }
                    setError(false);
                    handleClose();
                    setAddItem('');
                    // setJson(inital);
                  } else {
                    setError(true);
                  }
                }}>
                Save
              </Button>
            </div>
          </MatBox>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSignature}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={openSignature}>
          <MatBox sx={{ ...style, maxHeight: '80vh', overflow: 'scroll' }}>
            <div style={{ marginBottom: 20 }}>
              <h6>Signature</h6>
            </div>
            <div>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
              />
              ,
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => {
                  handleCloseSignatureModal();
                }}>
                Close
              </Button>
            </div>
          </MatBox>
        </Fade>
      </Modal>
      {!modalBodyMap ? null : (
        <BodyMapModal
          fullBind={fullBindBodyMap}
          setModalBodyMap={setModalBodyMap}
        />
      )}
      <ModalDecision
        title="Warning"
        body={
          'Are you sure you want to Cancel this Form Builder? \n Canceling the Form Builder will remove ALL records from database.'
        }
        onOk={handleWarn}
        onCancel={() => {
          setModalWarn(false);
        }}
        okTitle={'Yes'}
        {...bindModalWarn}
      />
    </div>
  );
});

export default Container;
