import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DurationInput from 'components/SharedComponents/DurationInput';
import AddNewEmployee from 'components/SharedComponents/FormSections/Service/AddNewEmployee';
import Serviceadhocemployee from 'components/SharedComponents/FormSections/Service/ServiceAdhocEmployee';
import ServiceContractDetails from 'components/SharedComponents/FormSections/Service/ServiceContractDetails';
import ServiceReplicaEmployee from 'components/SharedComponents/FormSections/Service/ServiceReplicaEmployee';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import React from 'react'
import { Fragment } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ErrorMessage, FlexGrid3Container, FlexGrid3Item, MessageSpan, TitleSection } from 'shared/styles/constants/tagsStyles';
import { FLOPPY, PLUS } from 'utils/constants/icons';
import VisitCancelation from './VisitCancelation';
import { useState } from 'react';
import { useEffect } from 'react';

function VisitTabContent({
    editMode,
    employees,
    values,
    dropDownHandleEmployee,
    choices,
    setValues,
    useInput,
    nameSection,
    dropDownHandleVisit,
    readOnly,
    dropdownHandleReplicaEmployee,
    createAddressFormat,
    dropdownHandleReplicaEmployeeSkill,
    clientSelectedId,
    clientdata,
    funderList,
    toggleVisitView,
    showServiceContractDetails,
    fullBind,
    assignedEmployee,
    handleAddVisitModal,
    searchScheduler,
    handleCancelVisit,
    handleDeleteVisit,
    onShowAlert,
    dropdownHandleAdhocEmployeeSkill,
    dropdownHandleAdhocEmployee,
    dropDownHandleSkillNewEmployee,
    dropDownHandleEmployeeNewEmployee,
    dropDownHandleQuantityNewEmployee,
    dropDownHandleGenderNewEmployee,
    addNewVisitEmployee,
    deleteVisitEmployeeNewEmployee,
    skillAddedMessage,
    errorMessage,
    handleSchedulerPublishOrUnpublish,
    iSPublished,
    handleSchedulerRevert,
    loadingSave,
    handleSaveEmployee,
    showQuestion,
    employeeName,
    forceTrueupdateEmployee,
    //cancel props
    fullBindCancel,
    nameSectionCancel,
    saveButtonIcon,
    textStatus,
    updateCancelVisit,
    dropDownHandleCancelVisit,
    listView,
    isLocation
}) { 
    const [textAreaValue, setTextAreaValue] = useState('')
    useEffect(() => {
        setTextAreaValue(values[nameSectionCancel].cancel_note)
    }, [values[nameSectionCancel].cancel_note])

    // useEffect(() => {
    //     const find = choices?.service_category.find(item => item.id === values[nameSection].service_category)
    //     if (find && find.category_type === 'location') {
    //         values.is_location = true
    //     } else {
    //         values.is_location = false
    //     }
    //     setValues({ ...values })
    // }, [choices.service_category, values[nameSection].service_category])

    // const handleToggleChange = () => {
    //     values.is_location = !values.is_location
    //     setValues({ ...values })
    // }
    return (
        <div className={' pl-3 pr-3 pb-3'}>
            {/* <FlexGrid3Item className="pl-0 pr-1 d-flex">
                <label
                    style={{
                        fontSize: '0.875rem',
                        marginRight: '7px',
                        alignSelf: 'center',
                    }}>
                    Client
                </label>
                <ToggleButton
                    label="Location"
                    disabled={true}
                    useInput={is_location_hook}
                    id="is_location"
                    onClick={handleToggleChange}
                />
            </FlexGrid3Item> */}
            <div className={'mb-4'}>
                <DropdownSearchable
                    placeHolder={'Employee assigned'}
                    // disabled={values.is_visit_cancelled}
                    disabled={!editMode || values.is_visit_cancelled}
                    options={employees}
                    selectedOption={values.employee ? values.employee : null}
                    onChange={value =>
                        dropDownHandleEmployee({ id: value ? value : null }, 'id')
                    }
                />
            </div>
            {values.is_visit_cancelled && (
                <div className='mb-4'>
                    <VisitCancelation
                        fullBind={fullBind}
                        errorMessage={errorMessage}
                        // textStatus={textStatus}
                        // saveButtonIcon={saveButtonIcon}
                        loadingSave={loadingSave}
                        useInput={useInput}
                        // saveCancelVisit={saveCancelVisit}
                        nameSection={nameSectionCancel}
                        values={values}
                        setValues={setValues}
                        dropDownHandleCancelVisit={dropDownHandleCancelVisit}
                        choices={choices}
                        listView={listView}
                        textAreaValue={textAreaValue}
                        setTextAreaValue={setTextAreaValue}
                    />
                </div>
            )}
            <>
                <FlexGrid3Container className="mt-4 mb-1">
                    <FlexGrid3Item className="pr-2">
                        <DropdownSearchable
                            disabled={!editMode || values.is_visit_cancelled}
                            placeHolder={'Client type'}
                            options={choices.client_type}
                            selectedOption={
                                values[nameSection] ? values[nameSection].type : null
                            }
                            onChange={value =>
                                dropDownHandleVisit({ type: value ? value : null }, 'type')
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pr-2">
                        <DropdownSearchable
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            placeHolder={'Service category'}
                            options={choices.service_category.filter(item => isLocation ? item.category_type === 'location' : item.category_type === 'client')}
                            // options={choices.service_category}
                            selectedOption={
                                values[nameSection]
                                    ? values[nameSection].service_category
                                    : null
                            }
                            onChange={value =>
                                dropDownHandleVisit(
                                    { service_category: value ? value : null },
                                    'service_category'
                                )
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="">
                        <DropdownSearchable
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            placeHolder={'Visit type'}
                            options={choices.visit_type}
                            selectedOption={
                                values[nameSection]
                                    ? values[nameSection].visit_type
                                    : null
                            }
                            onChange={value =>
                                dropDownHandleVisit(
                                    { visit_type: value ? value : null },
                                    'visit_type'
                                )
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="mt-2 pr-2">
                        <DropdownSearchable
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            placeHolder={'Visit priority'}
                            options={choices.visit_priority}
                            selectedOption={
                                values[nameSection]
                                    ? values[nameSection].visit_priority
                                    : null
                            }
                            onChange={value =>
                                dropDownHandleVisit(
                                    { visit_priority: value ? value : null },
                                    'visit_priority'
                                )
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="mt-2 pr-2">
                        <DropdownSearchable
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            placeHolder={'Service Destination'}
                            options={choices.service_destination}
                            selectedOption={
                                values[nameSection]
                                    ? values[nameSection].service_destination
                                    : null
                            }
                            onChange={value =>
                                dropDownHandleVisit(
                                    { service_destination: value ? value : null },
                                    'service_destination'
                                )
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="mt-2">
                        <DropdownSearchable
                            disabled={true}
                            // disabled={!editMode}
                            placeHolder={'Visit status'}
                            options={choices.visit_status}
                            selectedOption={
                                values[nameSection]
                                    ? values[nameSection].visit_status
                                    : null
                            }
                            onChange={value =>
                                dropDownHandleVisit(
                                    { visit_status: value ? value : null },
                                    'visit_status'
                                )
                            }
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pr-0 pl-1 pt-1 pb-1">
                        <ToggleButton
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            label="Time critical"
                            useInput={useInput(`${[nameSection]}.time_critical`)}
                            id="time_critical"
                        />
                    </FlexGrid3Item>

                    <FlexGrid3Item className="pr-0 pl-1 pt-1 pb-1"></FlexGrid3Item>
                    <FlexGrid3Item className="pr-0 pl-1 pt-1 pb-1">
                        <ToggleButton
                            // disabled={readOnly}
                            disabled={!editMode || values.is_visit_cancelled}
                            label="Manual Assign"
                            useInput={useInput(`${[nameSection]}.manualAssigned`)}
                            id="manual_assign"
                        />
                    </FlexGrid3Item>

                    <FlexGrid3Item className="pr-0 pl-1 pt-1 pb-1"></FlexGrid3Item>
                </FlexGrid3Container>
            </>

            {/* Contracted Start Date & End Date & start time & End Time */}
            <div className="d-flex mt-2 mb-4">
                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={true}
                        labelTop="Contracted Start Date"
                        // data-error={dateError}
                        // {...useInput(`contracted_data.contract_start_date`)}
                        value={values && values?.contracted_data && values?.contracted_data?.contract_start_date}
                    />
                </div>

                <div className="w-100 mr-2">

                    <TimeSelector
                        disabled={true}
                        labelTop={'Contracted Start Time'}
                        {...useInput(`contracted_data.contract_start_time`)}
                        value={values && values?.contracted_data && values?.contracted_data?.contract_start_time}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>
                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={true}
                        labelTop="Contracted End Date"
                        // data-error={dateError}
                        value={values?.contracted_data?.contract_end_date}
                        {...useInput(`contracted_data.contract_end_date`)}
                    />
                </div>

                <div className="w-100">
                    <TimeSelector
                        disabled={true}
                        labelTop={'Contracted End Time'}
                        {...useInput(`contracted_data.contract_end_time`)}
                        value={values?.contracted_data?.contract_end_time}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>
            </div>


            <div className="d-flex">
                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop="Schedule Start Date"
                        // data-error={dateError}
                        {...useInput(`${[nameSection]}.evv_data.schedule_start_date`)}
                    />
                </div>

                <div className="w-100 mr-2">
                    <TimeSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop={'Schedule start time'}
                        {...useInput(`${[nameSection]}.evv_data.schedule_start_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>

                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop="Schedule End Date"
                        // data-error={dateError}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                        {...useInput(`${[nameSection]}.evv_data.schedule_end_date`)}
                    />
                </div>
                <div className="w-100">
                    <TimeSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop={'Schedule end time'}
                        {...useInput(`${[nameSection]}.evv_data.schedule_end_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>
            </div>{' '}
            <div className="d-flex mt-2">
                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop="Travel start date"
                        // data-error={dateError}
                        // timeFormat={'HH:mm'}
                        // dateFormat={'HH:mm'}
                        {...useInput(`${[nameSection]}.travel_start_dates`)}
                    // {...useInput(`${[nameSection]}.evv_data.schedule_start_datetime`)}
                    />
                </div>
                <div className="w-100 mr-2">
                    <TimeSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop={'Travel start time'}
                        {...useInput(`${[nameSection]}.travel_start_datetime`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>

                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop="Travel end date"
                        // data-error={dateError}
                        // timeFormat={'HH:mm'}
                        // dateFormat={'HH:mm'}
                        {...useInput(`${[nameSection]}.travel_end_dates`)}
                    />
                </div>
                <div className="w-100">
                    <TimeSelector
                        disabled={!editMode || !values.visitValues.manualAssigned || values.is_visit_cancelled}
                        labelTop={'Travel end time'}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                        {...useInput(`${[nameSection]}.travel_end_datetime`)}
                    //added below code  for time in 24hrs format
                    />
                </div>
            </div>{' '}
            <div className="d-flex mt-2">
                <div className="w-100 mr-2" >
                    <DateSelector
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop="Visit Start Date"
                        // data-error={dateError}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                        {...useInput(`${[nameSection]}.visit_start_date`)}
                    />
                </div>
                <div className="w-100 mr-2">
                    <TimeSelector
                        //  disabled={values[nameSection].evv_data.visit?false:true}
                        // disabled={editMode?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop={'Visit start time'}
                        {...useInput(`${[nameSection]}.visit_start_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>

                <div className="w-100 mr-2">
                    <DateSelector
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop="Visit End Date"
                        // data-error={dateError}
                        {...useInput(`${[nameSection]}.visit_end_date`)}
                    />
                </div>
                <div className="w-100">
                    <TimeSelector
                        // disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop={'Visit end time'}
                        {...useInput(`${[nameSection]}.visit_end_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>
            </div>
            {/* <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pb-2 pb-md-0 pt-md-1">
                <TimeSelector
                    // disabled={values[nameSection].evv_data.visit?false:true}
                    disabled={!editMode}
                    labelTop={'Visit end time'}
                    {...useInput(`${[nameSection]}.visit_end_time`)}
                    //added below code  for time in 24hrs format
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                />
            </FlexGrid3Item> */}
            <div className="d-flex mt-2">
                <div className="w-100 mr-2" >
                    <TimeSelector
                        // disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop={'Earliest start time'}
                        {...useInput(`${[nameSection]}.earliest_start_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </div>
                <div className="w-100 mr-2" >              <TimeSelector
                    // disabled={values[nameSection].evv_data.visit?false:true}
                    disabled={!editMode || values.is_visit_cancelled}
                    labelTop={'Latest start time'}
                    {...useInput(`${[nameSection]}.latest_start_time`)}
                    //added below code  for time in 24hrs format
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                />
                </div>
                <div className="w-100 mr-2">
                    <DropdownSearchable
                        disabled={readOnly || values.is_visit_cancelled}
                        placeHolder={'Travel Method'}
                        options={choices.travel_type}
                        selectedOption={
                            values[nameSection].travel_mode
                                ? values[nameSection].travel_mode
                                : null
                        }
                        {...useInput(`${[nameSection]}.travel_mode`)}
                        onChange={value =>
                            dropDownHandleVisit(
                                { travel_mode: value ? value : null },
                                'travel_mode'
                            )
                        }
                    />
                </div>
                <div className="w-100">
                    <DurationInput
                        disabled={true}
                        label_top={'Duration (clocked)'}
                        {...useInput(
                            `${[nameSection]}.evv_data.actual_clocked_duration`
                        )}
                    />
                </div>
            </div>
            {/* vssit  end  */}
            <FlexGrid3Container className={'w-100 mt-2 '}>
                {/* <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0 mb-2 pt-md-1">
      <TimeSelector
        disabled={readOnly}
        labelTop={'Schedule start time'}
        {...useInput(`${[nameSection]}.evv_data.schedule_start_time`)}
        //added below code  for time in 24hrs format
        timeFormat={'HH:mm'}
        dateFormat={'HH:mm'}
      />
    </FlexGrid3Item> */}

                {/* new data for schedular added  start */}
                {/* <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0  pt-md-1  ">
                    <DurationInputInput
                        disabled={true}
                        // disabled={!editMode}
                        label_top={'Schedule duration'}
                        {...useInput(`${[nameSection]}.evv_data.duration`)}
                    />
                </FlexGrid3Item> */}



                {/* new data for schedular added  start */}
                {/* <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0  pt-md-1  ">
                   
                </FlexGrid3Item> */}

                {/* new data non-editablestarts */}
                <FlexGrid3Item className="w-100 pr-2">
                    <TimeSelector
                        disabled={readOnly || values.is_visit_cancelled}
                        labelTop={' Start Time (Clock-In)'}
                        {...useInput(`${[nameSection]}.evv_data.actual_start_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item className=" w-100 pr-2 ">
                    <TimeSelector
                        disabled={readOnly || values.is_visit_cancelled}
                        labelTop={' End Time (Clock-out)'}
                        {...useInput(`${[nameSection]}.evv_data.actual_end_time`)}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </FlexGrid3Item>
                {/* <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0 mb-2 pt-md-1 ">
                  
                </FlexGrid3Item> */}
                {/* new data non-editable ends */}

                <FlexGrid3Item className="w-100">
                    <DateSelector
                        //  disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop="Actual Start Date"
                        // data-error={dateError}
                        {...useInput(`${[nameSection]}.evv_data.actual_start_date`)} visit_start_date
                    />
                </FlexGrid3Item>
                <FlexGrid3Item className="mt-2 pr-2">
                    <DateSelector
                        //  disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop="Actual End Date"
                        // data-error={dateError}
                        {...useInput(`${[nameSection]}.evv_data.actual_end_date`)}
                    />
                </FlexGrid3Item>

                <FlexGrid3Item className="mt-2 pr-2">
                    <TimeSelector
                        // disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop={' Actual Start Time'}
                        {...useInput(
                            `${[nameSection]}.evv_data.actual_visit_start_time`
                        )}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </FlexGrid3Item>

                <FlexGrid3Item className="mt-2">
                    <TimeSelector
                        // disabled={values[nameSection].evv_data.visit?false:true}
                        disabled={!editMode || values.is_visit_cancelled}
                        labelTop={' Actual End Time'}
                        {...useInput(
                            `${[nameSection]}.evv_data.actual_visit_end_time`
                        )}
                        //added below code  for time in 24hrs format
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item className="w-100 pr-2 mt-2">
                    <DurationInput
                        disabled={true}
                        label_top={'Actual Duration'}
                        {...useInput(
                            `${[nameSection]}.evv_data.actual_visit_duration`
                        )}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item className="w-100 pr-2 mt-2">
                    <TimeSelector
                        disabled={readOnly || values.is_visit_cancelled}
                        // disabled={!editMode}
                        labelTop={' Rounded Actual Duration'}
                        {...useInput(
                            `${[nameSection]}.evv_data.rounded_actual_duration`
                        )}
                    />
                </FlexGrid3Item>
            </FlexGrid3Container>
            <div className="mb-2 mt-2">
                <FlexGrid3Item>
                    <InputLabelTop
                        disabled={!editMode || values.is_visit_cancelled}
                        type="text"
                        // id="warning_message"
                        // label={'Actual Time Notes'}
                        // value={values[nameSection].evv_data.warning_message}
                        id={`${[nameSection]}.evv_data.warning_message`}
                        placeholder={'Actual Time Notes'}
                        {...useInput(`${[nameSection]}.evv_data.warning_message`)}
                    />
                </FlexGrid3Item>
            </div>
            <div className="mt-3 mb-2">
                <FlexGrid3Item>
                    <InputLabelTop
                        disabled={readOnly || values.is_visit_cancelled}
                        type="text"
                        id="scheduler_service"
                        label={'Service address'}
                        value={createAddressFormat(values.addressValues)}
                    />
                </FlexGrid3Item>
            </div>
            <div className="mt-3 mb-2">
                <FlexGrid3Item>
                    {/* <p  className="ml-2"><small>Service-notes</small></p> */}
                    <InputTextArea
                        height={'3.5rem'}
                        disabled={!editMode || values.is_visit_cancelled}
                        label={'Service notes'}
                        type="text"
                        id={`${[nameSection]}_notes`}
                        {...useInput(`${[nameSection]}.notes`)}
                        placeholder={' Service - notes   '}
                    />
                </FlexGrid3Item>
            </div>
            {/* below for service contarct empliyees */}
            <div className="mt-2 mb-2">
                <ServiceReplicaEmployee
                    values={values}
                    nameSection={'visitValues'}
                    choices={choices}
                    useInput={useInput}
                    setValues={setValues}
                    readOnly={true}
                    editMode={editMode || values.is_visit_cancelled}
                    dropdownHandleReplicaEmployee={dropdownHandleReplicaEmployee}
                    dropdownHandleReplicaEmployeeSkill={
                        dropdownHandleReplicaEmployeeSkill
                    }
                    clientDropdownId={clientSelectedId}
                />
            </div>
            <>
                <div className={'mt-1 mb-2'}>
                    <TitleSection>Funder</TitleSection>
                    <FlexGrid3Container className="d-flex pb-2">
                        <FlexGrid3Item className="pr-1">
                            <DropdownSearchable
                                disabled={!editMode || values.is_visit_cancelled}
                                placeHolder={'Is Self Funded'}
                                options={[
                                    { id: 1, name: 'Yes' },
                                    { id: 2, name: 'No' },
                                ]}
                                selectedOption={
                                    values[nameSection].self_funded
                                        ? values[nameSection].self_funded
                                        : null
                                }
                                onChange={value =>
                                    dropDownHandleVisit(
                                        { self_funded: value ? value : null },
                                        'self_funded'
                                    )
                                }
                            />
                        </FlexGrid3Item>
                        {values[nameSection] &&
                            values[nameSection].self_funded &&
                            values[nameSection].self_funded == 1 && (
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <InputLabelTop
                                        disabled={editMode || values.is_visit_cancelled}
                                        type="text"
                                        // id="funder_name"
                                        label={'Funder name'}
                                        // onChange={changeFunderName}

                                        value={clientdata.full_name}
                                        maxlength={255}
                                    />
                                </FlexGrid3Item>
                            )}

                        {values[nameSection] &&
                            values[nameSection].self_funded &&
                            values[nameSection].self_funded == 2 && (
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable
                                        disabled={!editMode || values.is_visit_cancelled}
                                        placeHolder={'Funder Name'}
                                        options={funderList}
                                        selectedOption={
                                            values[nameSection].funder
                                                ? values[nameSection].funder
                                                : null
                                        }
                                        onChange={value =>
                                            dropDownHandleVisit(
                                                { funder: value ? value : null },
                                                'funder'
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                            )}

                        <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                            <DropdownSearchable
                                disabled={!editMode || values.is_visit_cancelled}
                                placeHolder={'Client / Funder category'}
                                options={choices.funder_source}
                                selectedOption={
                                    values[nameSection].category
                                        ? values[nameSection].category
                                        : null
                                }
                                onChange={value =>
                                    dropDownHandleVisit(
                                        { category: value ? value : null },
                                        'category'
                                    )
                                }
                            />
                        </FlexGrid3Item>
                    </FlexGrid3Container>
                </div>
            </>
            <>
                <div
                    onClick={toggleVisitView}
                    className="d-flex mt-2 "
                    style={{ cursor: 'pointer' }}>
                    <div>
                        <TitleSection style={{ cursor: 'pointer' }}>
                            Service Contract Details
                        </TitleSection>
                    </div>
                    {showServiceContractDetails ? (
                        <div>
                            <IoIosArrowDown
                                style={{
                                    color: '#6200f9',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            {' '}
                            <IoIosArrowUp
                                style={{
                                    color: '#6200f9',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    )}
                </div>
                {showServiceContractDetails ? (
                    <div>
                        <ServiceContractDetails
                            readOnly={readOnly || values.is_visit_cancelled}
                            fullBind={fullBind}
                            dropDownHandleVisit={dropDownHandleVisit}
                            dropDownHandleEmployee={dropDownHandleEmployee}
                            values={values}
                            setValues={setValues}
                            useInput={useInput}
                            nameSection={nameSection}
                            choices={choices}
                            assignedEmployee={assignedEmployee}
                            employees={employees}
                            handleAddVisitModal={handleAddVisitModal}
                            searchScheduler={searchScheduler}
                            handleCancelVisit={handleCancelVisit}
                            handleDeleteVisit={handleDeleteVisit}
                            onShowAlert={onShowAlert}
                            clientdata={clientdata}
                            dropdownHandleReplicaEmployee={
                                dropdownHandleReplicaEmployee
                            }
                            dropdownHandleReplicaEmployeeSkill={
                                dropdownHandleReplicaEmployeeSkill
                            }
                        />

                        <div className="mt-2 mb-2">
                            {values.visitValues.employee_required &&
                                values.visitValues.employee_required.length >= 1 &&
                                values.visitValues.employee_required.map(
                                    (data, indexEmployee) => {
                                        return (
                                            <Serviceadhocemployee
                                                values={values}
                                                nameSection={'visitValues'}
                                                indexEmployee={indexEmployee}
                                                choices={choices}
                                                useInput={useInput}
                                                setValues={setValues}
                                                readOnly={true}
                                                editMode={editMode || values.is_visit_cancelled}
                                                dropdownHandleAdhocEmployeeSkill={
                                                    dropdownHandleAdhocEmployeeSkill
                                                }
                                                dropdownHandleAdhocEmployee={
                                                    dropdownHandleAdhocEmployee
                                                }
                                            />
                                        );
                                    }
                                )}
                        </div>
                    </div>
                ) : null}
            </>
            {/* below for add new employee button component */}
            <div className="mt-2 mb-2">
                {values.visitValues.new_requirement &&
                    values.visitValues.new_requirement.length >= 1 &&
                    values.visitValues.new_requirement.map(
                        (data, indexEmployee) => {
                            return (
                                <AddNewEmployee
                                    readOnly={true}
                                    editMode={!editMode || values.is_visit_cancelled}
                                    indexEmployee={indexEmployee}
                                    data={data}
                                    setValues={setValues}
                                    useInput={useInput}
                                    nameSection={'visitValues'}
                                    values={values}
                                    dropDownHandleSkill={dropDownHandleSkillNewEmployee}
                                    dropDownHandleEmployee={
                                        dropDownHandleEmployeeNewEmployee
                                    }
                                    dropDownHandleQuantity={
                                        dropDownHandleQuantityNewEmployee
                                    }
                                    dropDownHandleGender={dropDownHandleGenderNewEmployee}
                                    choices={choices}
                                    addNewVisitEmployee={addNewVisitEmployee}
                                    deleteVisitEmployee={deleteVisitEmployeeNewEmployee}
                                    addedSkillMessage={skillAddedMessage}
                                    clientDropdownId={clientSelectedId}
                                />
                            );
                        }
                    )}
            </div>
            <>
                {editMode ? (
                    <div
                        className={
                            'd-flex justify-content-center justify-content-md-start'
                        }>
                        <div className={'mt-3'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={addNewVisitEmployee}>
                                <span>{PLUS} Add New Employee</span>
                            </PrimaryButtonForm>
                        </div>
                    </div>
                ) : null}
            </>
            <div
                className={
                    'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
                }>
                <div className={'mb-3'}>
                    {errorMessage ? (
                        <ErrorMessage>
                            <label className={'mt-2'}>{errorMessage}</label>
                        </ErrorMessage>
                    ) : (
                        <Fragment />
                    )}
                </div>

                <div
                    className={
                        'd-flex justify-content-center justify-content-md-end'
                    }>
                    <div className={'ml-0 ml-md-3'}>
                        <PrimaryButtonForm
                            minWidth="6rem"
                            onClick={handleSchedulerPublishOrUnpublish}>
                            {iSPublished ? (
                                <span className={'mr-2'}>UnPublish</span>
                            ) : (
                                <span className={'mr-2'}>Publish</span>
                            )}
                        </PrimaryButtonForm>
                    </div>
                    <div className={'ml-0 ml-md-3'}>
                        <PrimaryButtonForm
                            minWidth="6rem"
                            onClick={handleSchedulerRevert}>
                            <span className={'mr-2'}>Revert Visit</span>
                        </PrimaryButtonForm>
                    </div>
                    <div className={'ml-0 ml-md-3'}>
                        <PrimaryButtonForm
                            minWidth="6rem"
                            onClick={handleDeleteVisit}>
                            <span className={'mr-2'}>Delete Visit</span>
                        </PrimaryButtonForm>
                    </div>
                    {!values.is_visit_cancelled ? (
                        <>
                            <div className={'ml-0 ml-md-3'}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    onClick={handleCancelVisit}>
                                    <span className={'mr-2'}>Cancel Visit</span>
                                </PrimaryButtonForm>
                            </div>
                            <div className={'ml-0 ml-md-3'}>
                                <PrimaryButtonForm
                                    disabled={loadingSave}
                                    minWidth="6rem"
                                    onClick={handleSaveEmployee}>
                                    <span className={'mr-2'}>SAVE</span>
                                    {!loadingSave ? (
                                        FLOPPY
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </>
                    ) : (
                        <div className={'d-flex justify-content-center justify-content-md-end'}>
                            <div className={'ml-0 ml-md-3'}>
                                <PrimaryButtonForm minWidth="6rem" onClick={() => updateCancelVisit(textAreaValue)}>
                                    <span className={'mr-2'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        saveButtonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* {values.is_visit_cancelled && (
                <p
                    style={{
                        marginTop: '15px',
                        marginBottom: '10px',
                        textAlign: 'center',
                        color: 'red',
                    }}>
                    {choices.cancellation_reason && choices.cancellation_reason.find(choice => choice.id === values.visit_cancellation_reason) &&
                        choices.cancellation_reason.find(choice => choice.id === values.visit_cancellation_reason).name
                    }
                </p>
            )} */}
            {
                showQuestion ? (
                    <div className={'d-block justify-content-center mt-3 mb-3'}>
                        <div
                            className={'d-flex justify-content-center text-align-center'}>
                            {values.employee ? (
                                <label>
                                    This visit will be assigned to
                                    <MessageSpan> {employeeName()}, </MessageSpan>
                                    <span className={'font-weight-bold'}>
                                        Would you like to proceed?
                                    </span>
                                </label>
                            ) : (
                                <label>
                                    This visit will be unassigned,{' '}
                                    <span className={'font-weight-bold'}>
                                        Would you like to proceed?
                                    </span>
                                </label>
                            )}
                        </div>
                        <div
                            className={
                                'd-block d-md-flex align-items-center justify-content-center mt-2'
                            }>
                            <div
                                className={
                                    'mr-0 mr-md-4 mt-2 mt-md-0 d-flex justify-content-center'
                                }>
                                <PrimaryButtonForm
                                    bgcolor={'PURPLE_GRAY_500'}
                                    minWidth="6rem"
                                    onClick={handleAddVisitModal}>
                                    <span className={'pl-4 pr-4'}>NO, CANCEL</span>
                                </PrimaryButtonForm>
                            </div>
                            <div
                                className={
                                    'mt-3 mt-md-0 ml-0 ml-md-2 d-flex justify-content-center'
                                }>
                                {/* <PrimaryButtonForm minWidth="6rem" onClick={updateEmployee}>
          <span className={'mr-2 pl-3 pr-3'}>YES, PROCEED</span>
        </PrimaryButtonForm> */}
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    onClick={forceTrueupdateEmployee}>
                                    <span className={'mr-2 pl-3 pr-3'}>YES, PROCEED</span>
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Fragment />
                )
            }
        </div >
    )
}

export default VisitTabContent